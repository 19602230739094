import { useEffect, useState } from 'react';
import { IInitialization } from '../../architecture/interfaces/select/HandlerInitialization';
import { ISelectItem } from '../../architecture/interfaces/select/ISelectItem';

export function useSelectListHandler({
  triggerLabel,
  items,
  selectHandler,
  inputValidator,
  closeOnSelect,
  openUpwards,
  maxHeight = 250,
}: IInitialization) {
  const [open, setOpen] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [inErrorState, setInErrorState] = useState(false);

  const filteredItems = items.filter((item) =>
    item.title.toLowerCase().includes(inputFilter.toLowerCase())
  );

  const findActiveIndex =
    filteredItems.findIndex((item) => item.title === triggerLabel) ?? 0;

  const [activeIndex, setActiveIndex] = useState(0);

  const activeItem = filteredItems[activeIndex];

  const [shouldOpenUpwards, setShouldOpenUpwards] = useState(false);

  useEffect(() => {
    setActiveIndex(findActiveIndex);
  }, [triggerLabel]);

  const handleDropdownOpen = () => {
    if (openUpwards) {
      setShouldOpenUpwards(true);
    } else {
      const activeElementPos = document.activeElement?.getBoundingClientRect();
      const isAtBottom =
        (activeElementPos?.bottom ?? 0) + maxHeight >= window.innerHeight;

      setShouldOpenUpwards(isAtBottom);
    }

    setOpen(true);
  };

  const handleInputChange = (value: string) => {
    validateInput(value);
    setInputFilter(value);
  };

  const validateInput = (value: string) => {
    if (inputValidator) {
      const hasError = inputValidator(value);
      setInErrorState(hasError);
    }
  };

  const handleItemClick = (item?: ISelectItem) => {
    item && selectHandler(item.title, item.id);

    if (closeOnSelect) resetDropdownState();
  };

  const resetDropdownState = () => {
    setOpen(false);
    setInputFilter('');
    validateInput('');
    setShouldOpenUpwards(false);
    setActiveIndex(findActiveIndex);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowUp') return handleMoveUp();
    if (event.key === 'ArrowDown') return handleMoveDown();

    if (event.key === 'Enter' && !inErrorState) {
      handleItemClick(activeItem);
      filteredItems.length === 1 ? setActiveIndex(0) : handleMoveUp();
      setInputFilter('');
      return;
    }

    return;
  };

  const handleMoveDown = () => {
    if (filteredItems.length === 1) return setActiveIndex(0);

    const newIndex = activeIndex === filteredItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(newIndex);
  };

  const handleMoveUp = () => {
    if (filteredItems.length === 1) return setActiveIndex(0);

    const newIndex = activeIndex === 0 ? filteredItems.length - 1 : activeIndex - 1;
    setActiveIndex(newIndex);
  };

  return {
    activeItem,
    filteredItems,
    inputFilter,
    open,
    shouldOpenUpwards,
    inErrorState,
    handleDropdownOpen,
    resetDropdownState,
    handleInputChange,
    handleKeyDown,
    handleItemClick,
  };
}
