import { observer } from 'mobx-react-lite';
import React, { useState, useContext } from 'react';
import rootStore from '../../../../../stores/rootStore';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import { MediaTypes } from '../../../../../architecture/enums/MediaType';
import { KnowledgebaseEntry } from '../../../../../models/Knowledgebase/KnowledgebaseEntry';
import Modal from '../../../../common/modal/Modal';
import MediaFileSelector from '../../../../common/modal/templates/MediaFileSelector';
import { Media } from '../../../../../models/Media/Media';
import MediaFile from './MediaFile';

interface IProps {
  mediaType: MediaTypes;
  entry: KnowledgebaseEntry;
  mediaFiles?: Media[];
}

const Files: React.FC<IProps> = ({ mediaType, entry, mediaFiles }) => {
  const { uiStore } = useContext(rootStore);
  const [openMediaFileSelector, setOpenMediaFileSelector] = useState(false);
  const getIcon = useGetIcon();

  const resetModal = () => {
    setOpenMediaFileSelector(false);
  };

  return (
    <div className='attachment-library'>
      <div className='grid repeat-4'>
        {mediaFiles?.map((media, key) => (
          <MediaFile key={key} media={media} index={key} />
        ))}

        <div
          className='library-item library-item-add'
          onClick={() => {
            setOpenMediaFileSelector(true);
          }}
        >
          <i className={getIcon('add')}></i>
        </div>
      </div>
      <Modal
        manuallyTriggered={openMediaFileSelector}
        closeHandler={resetModal}
        openConnectedModal
      >
        <MediaFileSelector mediaType={mediaType} item={entry} resetModal={resetModal} />
      </Modal>
    </div>
  );
};

export default observer(Files);
