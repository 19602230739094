import { Exclude, Expose } from 'class-transformer';
import IPathElement, {
  PathElementTypes,
} from '../../../architecture/interfaces/IPathElement';
import { ListElement } from './ListElement';

export abstract class PathElement implements IPathElement {
  @Expose()
  key: string | number | null;

  @Exclude()
  abstract type: PathElementTypes;

  constructor(key: string | number | null) {
    this.key = key;
  }

  static getStringifiedPathOf(elements: PathElement[]) {
    const base = elements.map((item) => item.key);

    const listElement = elements.find(
      (elem) => elem.type === PathElementTypes.ListElement
    ) as ListElement;

    if (listElement?.nestedProperties) {
      listElement.nestedProperties.forEach((prop) => base.push(prop));
    }

    return base.join('.');
  }
}
