import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { SubQuestionGroup } from '../../../../../../../architecture/enums/SubQuestionKey';
import { GroupedMultiQuestionNode } from '../../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedMultiQuestionNode';
import { SideBarEditor } from '../../../../../../layout/sideBarEditor/SideBarEditor';
import GroupSubQuestionInserter from '../inserters/GroupSubQuestionInserter';
import SubQuestion from '../SubQuestion';

interface IProps {
  dialogNode: GroupedMultiQuestionNode;
}
const GroupedSubQuestionsSection: React.FC<IProps> = ({ dialogNode }) => {
  const [activeSubQuestionId, setActiveSubQuestionId] = useState<
    SubQuestionGroup | undefined
  >();
  const activeSubQuestion = activeSubQuestionId
    ? dialogNode.getSubQuestionByKey(activeSubQuestionId)
    : null;

  return (
    <div className='multi-ctx-section-container'>
      <GroupSubQuestionInserter
        groups={dialogNode.allowedSubQuestionKeys}
        canAddSubQuestions={dialogNode.isPrefixValid}
        isKeyStored={dialogNode.isKeyStored.bind(dialogNode)}
        addSubQuestionsHandler={dialogNode.addSubQuestion.bind(dialogNode)}
        removeSubQuestionsHandler={dialogNode.removeSubQuestion.bind(dialogNode)}
      />

      {dialogNode.subQuestions.length > 0 && (
        <SideBarEditor.Base>
          <SideBarEditor.SideBar
            items={dialogNode.subQuestions.map((item) => ({
              id: item.groupKey,
              name: item.groupKey,
            }))}
            activeItemId={activeSubQuestionId}
            setActiveItemId={(key: string) =>
              setActiveSubQuestionId(key as SubQuestionGroup)
            }
          ></SideBarEditor.SideBar>
          <SideBarEditor.Editor>
            {activeSubQuestion && (
              <SubQuestion
                key={activeSubQuestion.groupKey}
                subQuestion={activeSubQuestion}
              />
            )}
          </SideBarEditor.Editor>
        </SideBarEditor.Base>
      )}
    </div>
  );
};

export default observer(GroupedSubQuestionsSection);
