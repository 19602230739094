import React from 'react';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  text: string;
  inErrorState: boolean;
  createHandler: () => void;
}
const CreatorDisplayRow: React.FC<IProps> = ({ text, inErrorState, createHandler }) => {
  const getIcon = useGetIcon();

  const displayText = inErrorState ? 'Cannot overwrite: ' : 'Add new item: ';

  return (
    <div className='select-item creator-display-field' onClick={createHandler}>
      <i
        className={getIcon(inErrorState ? 'error' : 'add') + ' ' + 'select-item-icon'}
      ></i>
      <span>
        {displayText}
        <strong>{text}</strong>
      </span>
    </div>
  );
};

export default CreatorDisplayRow;
