import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import {
  BotAdminState,
  SubscriptionAdminState,
  UserAdminState,
} from '../../../architecture/enums/Admin';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { TableData } from '../../../architecture/enums/TableData';
import { Subscription } from '../../../models/Admin/Subscription';
import { Notification } from '../../../models/Utilities/Notification';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Modal from '../../common/modal/Modal';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import LoadingPage from '../../layout/LoadingPage';
import CreateSubscription from './CreateSubscription';
import { HEADERS } from './constants';

const KEY = 'search';

const SubscriptionsList: React.FC = () => {
  const getIcon = useGetIcon();
  const { showModal } = useModal();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const [search, setSearch] = useState(params.search ? params.search.toString() : '');

  const [openCreateSubscriptionModal, setOpenCreateSubscriptionModal] = useState(false);
  const { subscriptionAdminStore, botAdminStore, userAdminStore } = useAdminStores();
  const [subscriptions, setSubscriptions] = useState(
    subscriptionAdminStore.allSubscriptions
  );

  useEffect(() => {
    subscriptionAdminStore.load();
  }, []);

  const columns: Array<Column<TableData>> = React.useMemo(
    () => [
      {
        id: HEADERS.subscriptionName.id,
        Header: HEADERS.subscriptionName.label,
        accessor: (subscription: TableData) => (subscription as Subscription).name,
        minWidth: 150,
      },
      {
        id: HEADERS.creationDate.id,
        Header: HEADERS.creationDate.label,
        accessor: (subscription: TableData) =>
          (subscription as Subscription).creationDate,
      },
      {
        id: HEADERS.actions.id,
        Header: HEADERS.actions.label,
        width: subscriptionAdminStore.getSelectedSubscription ? 25 : 10,
        Cell: (item: Cell<Subscription>) => {
          const isEmpty = item.row.original.isEmpty;
          const inactiveClass = !isEmpty ? 'actions-icon-inactive' : '';

          return (
            <div className='actions'>
              <i
                className={`${getIcon('remove')} actions-icon ${inactiveClass}`}
                onClick={() => {
                  if (isEmpty) {
                    showModal(
                      ModalTypes.DeleteElement,
                      new Subscription(item.row.original.id, item.row.original.name)
                    );
                  } else {
                    new Notification({
                      text: 'Subscription still contains Bots',
                      type: 'warning',
                    });
                  }
                }}
              ></i>
            </div>
          );
        },
      },
    ],
    [subscriptionAdminStore.getSelectedSubscription]
  );

  const data: TableData[] = useMemo(() => {
    if (!subscriptions) {
      return [];
    }
    return subscriptions.map((subscription: Subscription) => {
      return {
        id: subscription.id,
        name: subscription.name || '',
        creationDate: subscription.creationDate || '',
        isEmpty: subscription.isEmpty,
      };
    });
  }, [subscriptions]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  useEffect(() => {
    setSubscriptions(subscriptionAdminStore.allSubscriptions);
  }, [subscriptionAdminStore.allSubscriptions]);

  const handleRowClick = (subscription: TableData) => {
    subscriptionAdminStore.setSelectedSubscription(subscription.id as string | undefined);
  };

  const resetModal = () => {
    setOpenCreateSubscriptionModal(false);
  };

  const saveSubscription = (subscription: Subscription) => {
    subscriptionAdminStore.create(subscription);
  };

  const keyDownHandler = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && search) {
      handleParamsChange(search);
    } else if (event.key === 'Enter' && !search) {
      clearParamsChange();
    }
  };

  const blurHandler = () => {
    if (search) {
      handleParamsChange(search);
    }
  };

  const handleParamsChange = (value: string) => {
    let newData = params;
    const data = { [KEY]: value };
    newData = { ...newData, ...data };

    // remove blank attributes from data
    const dataObj = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v));

    return history.push({
      pathname: location.pathname,
      search: queryString.stringify(dataObj),
    });
  };

  const clearParamsChange = () => {
    delete params[KEY];

    return history.push({
      pathname: location.pathname,
      search: queryString.stringify(params),
    });
  };

  useEffect(() => {
    if (params.search) {
      setSearch(params.search.toString());
      let filteredSubscriptions =
        subscriptionAdminStore.search(params.search as string) || [];
      setSubscriptions(filteredSubscriptions as Subscription[]);
    } else {
      setSearch('');
      setSubscriptions(subscriptionAdminStore.allSubscriptions);
    }
  }, [params.search]);

  return (
    <>
      {subscriptionAdminStore.getState(SubscriptionAdminState.SubscriptionsLoading) &&
      !botAdminStore.getState(BotAdminState.BotsLoading) &&
      !userAdminStore.getState(UserAdminState.UsersLoading) ? (
        <LoadingPage />
      ) : (
        <div className='container-wrapper'>
          <div className='header'>
            <div className='search-input'>
              <Input
                value={search}
                className='filter-input'
                placeholder='Search for a Subscription'
                changeHandler={setSearch}
                blurHandler={blurHandler}
                keyDownHandler={keyDownHandler}
              />
              <i className={getIcon('search')}></i>
            </div>
            <div className='button-wrapper'>
              <Button
                content='Add Subscription'
                className='btn-primary btn-round'
                clickHandler={() => setOpenCreateSubscriptionModal(true)}
              />
            </div>
          </div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            selectedRow={subscriptionAdminStore.getSelectedSubscription as TableData}
            prepareRow={prepareRow}
            onRowClick={handleRowClick}
          />
          <Modal
            manuallyTriggered={openCreateSubscriptionModal}
            closeHandler={resetModal}
          >
            <CreateSubscription
              resetModal={resetModal}
              saveSubscription={saveSubscription}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default observer(SubscriptionsList);
