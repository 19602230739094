import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { PropertyType } from '../../../../../architecture/enums/Dataset';
import { DatasetProperty } from '../../../../../models/Dataset/Property/DatasetProperty';
import { PropertyValue } from '../../../../../models/Dataset/PropertyValue';
import { DatasetActionNode as DatasetActionNodeModel } from '../../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import rootStore from '../../../../../stores/rootStore';
import Select from '../../../../common/inputElements/select/Select';
import PropertiesValues from './components/PropertiesValues';
import DatasetContextVariableHandler from './DatasetContextVariableHandler';

interface IProps {
  dialogNode: DatasetActionNodeModel;
}

const CreateNewDatasetVariable: React.FC<IProps> = ({ dialogNode }) => {
  const { datasetStore } = useContext(rootStore);

  const changeDatasetHandler = (value: string) => {
    let properties: PropertyValue[] = [];
    dialogNode.contextVariable = undefined;
    dialogNode.dataset = datasetStore.allDatasets?.find((d) => d.name === value);

    dialogNode.dataset?.properties.map((property) => {
      properties.push(
        new PropertyValue(
          property.id!,
          property.type,
          property.annotation,
          property.type === PropertyType.Simple ? '' : undefined,
          property.type !== PropertyType.Simple
            ? (property as DatasetProperty).dataset
            : undefined
        )
      );
    });

    dialogNode.properties = properties;
  };

  return (
    <div>
      <div className='flex wrapper'>
        Dataset:
        <Select
          id='type'
          triggerLabel={dialogNode?.dataset?.name || ''}
          items={datasetStore.allDatasets.map((dataset) => ({
            title: dataset.name,
          }))}
          className='select-input'
          selectHandler={(value) => changeDatasetHandler(value)}
          closeOnSelect
        />
      </div>
      <PropertiesValues dialogNode={dialogNode} />
      {dialogNode.dataset && <DatasetContextVariableHandler dialogNode={dialogNode} />}
    </div>
  );
};

export default observer(CreateNewDatasetVariable);
