import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Color } from '../../../architecture/enums/ConfirmationSettings';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import { useGetIcon } from '../../customHooks/useGetIcon';
import ColorField from '../components/ColorField';
import SettingsInfoIcon from '../components/SettingsInfoIcon';

interface IProps {
  formik: any;
}

const Colors: React.FC<IProps> = ({ formik }) => {
  const { botSettingsStore } = useContext(rootStore);
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  const getIcon = useGetIcon();

  return (
    <div className='properties-wrapper'>
      <h3 className='title'>Colors</h3>
      <div className='colors-wrapper'>
        <div className='input-group'>
          <label htmlFor={Color.PrimaryColor}>
            Primary Color
            <SettingsInfoIcon tooltip='The primary color is used for the background of the title.' />
          </label>
          <ColorField
            id={Color.PrimaryColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.primaryColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.primaryColor = color)
            }
          />
          {formik.touched.primaryColor && formik.errors.primaryColor ? (
            <div className='validation-error'>{formik.errors.primaryColor}</div>
          ) : null}
        </div>
        <div className='input-group'>
          <label htmlFor={Color.PrimaryTextColor}>
            Headline color <SettingsInfoIcon tooltip='The color of all headlines.' />
          </label>
          <ColorField
            id={Color.PrimaryTextColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.primaryTextColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.primaryTextColor = color)
            }
          />
          {formik.touched.primaryTextColor && formik.errors.primaryTextColor ? (
            <div className='validation-error'>{formik.errors.primaryTextColor}</div>
          ) : null}
        </div>
        <div className='input-group'>
          <label htmlFor={Color.PrimaryButtonTextColor}>
            Button Text Color{' '}
            <SettingsInfoIcon tooltip='This color is used for the submit button font.' />
          </label>
          <ColorField
            id={Color.PrimaryButtonTextColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.primaryButtonTextColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.primaryButtonTextColor =
                color)
            }
          />
          {formik.touched.primaryButtonTextColor &&
          formik.errors.primaryButtonTextColor ? (
            <div className='validation-error'>{formik.errors.primaryButtonTextColor}</div>
          ) : null}
        </div>
        <div className='input-group'>
          <label htmlFor={Color.SecondaryColor}>
            Secondary Color{' '}
            <SettingsInfoIcon tooltip='The secondary color is used as the button color and for the divison lines.' />
          </label>
          <ColorField
            id={Color.SecondaryColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.secondaryColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.secondaryColor = color)
            }
          />
          {formik.touched.secondaryColor && formik.errors.secondaryColor ? (
            <div className='validation-error'>{formik.errors.secondaryColor}</div>
          ) : null}
        </div>

        <div className='input-group'>
          <label htmlFor={Color.BackgroundColor}>
            Background Color <SettingsInfoIcon tooltip='The background color.' />
          </label>
          <ColorField
            id={Color.BackgroundColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.backgroundColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.backgroundColor = color)
            }
          />
          {formik.touched.backgroundColor && formik.errors.backgroundColor ? (
            <div className='validation-error'>{formik.errors.backgroundColor}</div>
          ) : null}
        </div>

        <div className='input-group'>
          <label htmlFor={Color.TitleTextColor}>
            Title Text Color <SettingsInfoIcon tooltip='We are using primary color for' />
          </label>
          <ColorField
            id={Color.TitleTextColor}
            selectedColor={selectedColor}
            color={formik.values.confirmationSettings.titleTextColor || ''}
            setSelectedColor={setSelectedColor}
            setColorValue={(color) =>
              (botSettingsStore.settings.confirmationSettings.titleTextColor = color)
            }
          />
          {formik.touched.titleTextColor && formik.errors.titleTextColor ? (
            <div className='validation-error'>{formik.errors.titleTextColor}</div>
          ) : null}
        </div>
      </div>
      <div className='reset-wrapper'>
        <Button
          content={'Reset to default'}
          icon={getIcon('undo')}
          className={'btn btn-primary btn-round'}
          clickHandler={(e) => {
            e.preventDefault();
            botSettingsStore.resetColorsToDefault();
          }}
        />
      </div>
    </div>
  );
};

export default observer(Colors);
