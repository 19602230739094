import { makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { CommunicationDialogNode } from '../CommunicationDialogNode';

export class GenerativeDefaultAnswerNode extends CommunicationDialogNode {
  readonly type: DialogNodeTypes = DialogNodeTypes.GenerativeDefaultAnswerNode;
  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Generative Default Answer Node';

    makeObservable(this, {
      type: observable,
    });
  }
}
