export const HEADERS = {
  date: {
    label: 'Date',
    id: 'date',
  },
  conversations: {
    label: 'Conversations',
    id: 'conversations',
  },
  conversationThreads: {
    label: 'Conversation Threads',
    id: 'conversationThreads',
  },
  communicationActivities: {
    label: 'Communication Activities',
    id: 'communicationActivities',
  },
  costs: {
    label: 'Costs',
    id: 'costs',
  },
  credits: {
    label: 'Credits',
    id: 'credits',
  },
};
