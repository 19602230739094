import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import pic from '../../assets/oops_sign.png';
import rootStore from '../../stores/rootStore';

const ErrorPage = () => {
  const { appStore } = useContext(rootStore);

  useEffect(() => {
    appStore.isInErrorState = true;

    return () => {
      appStore.isInErrorState = false;
    };
  });

  return (
    <div id='error-page-container' className='content-without-subnavigation'>
      <img src={pic} alt='error_img' id='error-pic' />
      <h1>Oops, an error has occured.</h1>
      <h2>
        Please contact the{' '}
        <a href='mailto:info@lailo.ai' className='link-tag'>
          Lailo Support Team
        </a>{' '}
        for more information.
      </h2>
      <div>
        <Link to='/' className='back-to-home-link'>
          Back to Home page
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
