import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media } from '../../../../../models/Media/Media';
import { Utilities } from '../../../../../models/Utilities/Utilities';

interface IProps {
  media: Media;
  index: number;
}

const Tooltip: React.FC<IProps> = ({ media, index }) => {
  return (
    <div className={`item-tooltip ${index < 2 ? 'tooltip-right' : 'tooltip-left'}`}>
      <div className='tooltip-title'>{Utilities.truncateText(media.fileName, 24)}</div>
      <div className='tooltip-info'>
        Created at <i>{format(new Date(media.created), 'dd.MM.yyyy')} </i>
      </div>

      {media.description && (
        <div>
          Description:
          <i> {Utilities.truncateText(media.description, 79)} </i>
        </div>
      )}
    </div>
  );
};

export default observer(Tooltip);
