import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Label as LabelModel } from '../../models/Intents/Label';
import Button from '../common/Button';

import Input from '../common/Input';
import { useGetIcon } from '../customHooks/useGetIcon';

interface IProps {
  disabled: boolean;
  label: LabelModel;
  deleteHandler: () => void;
}
const Label: React.FC<IProps> = ({ disabled, label, deleteHandler }) => {
  const getIcon = useGetIcon();

  const [value, setValue] = useState(label.text.value);

  const changeHandler = (input: string) => {
    setValue(input);
  };

  const blurHandler = () => {
    value ? label.setText(value) : deleteHandler();
  };

  return (
    <div className='input-field-with-btn'>
      <Input
        disabled={disabled}
        name=''
        textAlign='center'
        value={value}
        changeHandler={changeHandler}
        blurHandler={blurHandler}
      />
      <Button
        disabled={disabled}
        icon={getIcon('close')}
        className={'btn-remove'}
        clickHandler={deleteHandler}
      />
    </div>
  );
};

export default observer(Label);
