import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import * as Yup from 'yup';
import Button from '../../common/Button';
import Input from '../../common/Input';

const validationSchema = Yup.object({
  credits: Yup.number()
    .required('This field is required')
    .positive('Credits must be postive')
    .integer('Credits must be an integer number')
    .moreThan(0, 'Credits must be greater than 0'),
});

interface IProps {
  resetModal: () => void;
  addPayment: (credits: number, remarks?: string) => void;
}

const AddCreditPaymentModal = ({ resetModal, addPayment }: IProps) => {
  const formik = useFormik({
    initialValues: {
      credits: 0,
      remarks: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addPayment(values.credits, values.remarks);
    },
  });

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Add Payment</h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='credits'>Credits:</label>
            <Input
              id='credits'
              inputType='number'
              placeholder='Credits'
              value={formik.values.credits.toString()}
              changeHandler={(value) => formik.setFieldValue('credits', value)}
            />
            {formik.touched.credits && formik.errors.credits ? (
              <div className='validation-error'>{formik.errors.credits}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label htmlFor='remarks'>Remarks:</label>
            <Input
              id='remarks'
              placeholder='Remarks'
              value={formik.values.remarks}
              changeHandler={(value) => formik.setFieldValue('remarks', value)}
            />
            {formik.touched.remarks && formik.errors.remarks ? (
              <div className='validation-error'>{formik.errors.remarks}</div>
            ) : null}
          </div>
        </div>
        <div className='btn-container'>
          <Button
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={resetModal}
          />
          <Button content='Add Payment' className='btn-primary btn-round' type='submit' />
        </div>
      </form>
    </div>
  );
};

export default observer(AddCreditPaymentModal);
