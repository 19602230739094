import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { ContextVariableInstanceTypes } from '../../../architecture/enums/ContextVariableInstanceTypes';
import { IContextVariable } from '../../../architecture/interfaces/contextVariables/IContextVariable';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import useCreateContentEditableText from '../../customHooks/useCreateContentEditableText';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Dropdown from '../Dropdown';

interface IProps {
  id?: string;
  disabled?: boolean;
  value: string | undefined;
  saveHandler: (text: string) => void;
  placeholder?: string;
  title?: string;
  multiline?: boolean;
  autofocus?: boolean;
  validator?: () => boolean;
  className?: string;
}
const TextBoxWithCtx: React.FC<IProps> = ({
  disabled,
  value,
  saveHandler,
  placeholder = 'Enter a value...',
  title,
  multiline = false,
  autofocus = false,
  validator,
  className,
  id,
}) => {
  const getIcon = useGetIcon();

  const divRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const getDivRef = () => divRef.current as unknown as HTMLDivElement;

  const searchIcon = useGetIcon()('search');
  const { ctxVarStore } = useContext(rootStore);

  const [touched, setTouched] = useState(false);

  const [windowSelection, setWindowSelection] = useState<Selection | null>(null);
  const selectionRange = windowSelection?.getRangeAt(0) ?? null;

  const [ctxFilter, setCtxFilter] = useState('');
  const [ctxDropdownOpen, setCtxDropdownOpen] = useState(false);
  const [ctxFilteredList, setCtxFilteredList] = useState<IContextVariable[]>([]);
  const [selectedCtxIndex, setSelectedCtxIndex] = useState(0);

  useEffect(() => {
    if (autofocus) {
      setTimeout(() => getDivRef()?.focus(), 1);
    }
  }, []);

  useEffect(() => {
    getDivRef().innerHTML = '';
  }, [id]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [selectionRange]);

  const handleCtxClick = (ctx: IContextVariable) => {
    console.table({ ...ctx });
  };

  const processedContent = useCreateContentEditableText({
    value,
    placeholder,
    clickHandler: handleCtxClick,
  });

  useEffect(() => {
    if (ctxFilter) {
      setCtxFilteredList(
        ctxVarStore.userAndSystemVariables.filter((ctx) =>
          ctx.name.toLowerCase().includes(ctxFilter.toLowerCase())
        )
      );
    } else {
      setCtxFilteredList(ctxVarStore.userAndSystemVariables);
    }
  }, [ctxFilter, ctxVarStore.userAndSystemVariables]);

  const handleFocus = () => {
    if (placeholder === getDivRef().textContent) {
      getDivRef().innerHTML = '';
    }
    setTouched(true);
    insertCursorAfterElement(getDivRef());
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    event.persist();

    if (!multiline && event.key === 'Enter') {
      event.preventDefault();
    }

    if (event.key === 'Backspace') {
      handleBackspaceWithinTextBox();
    }

    if (ctxDropdownOpen) {
      event.preventDefault();
      const key = event.key;
      handleKeyPressWhileCtxDropdownOpen(key);
    } else {
      if (multiline && event.key === 'Tab') {
        event.preventDefault();
        insertTextAtCaret(Array(4).join('\u00a0'));
      }
    }

    if (event.key === ctxVarStore.trigger) {
      event.preventDefault();
      const sel = window.getSelection();
      setWindowSelection(sel);
      setCtxDropdownOpen(true);
    }
  };

  /**
   * Checks an edge-case and handles it if only one ContextVariable-Span is present within the TextBox
   */
  const handleBackspaceWithinTextBox = () => {
    // There is a bug where if a Context Variable is the first and only element in the TextBox
    // It cannot be deleted. The focusNode of the window selection is the textbox itself (instead of text which is the normal behaviour)
    const sel = window.getSelection();
    const range = sel?.getRangeAt(0);

    if (sel?.focusNode === getDivRef() && sel?.focusOffset === 2) {
      // focusNode.children: [span.context-variable]
      // BUT
      // focusNode.childNodes: [text, span.contextvariable] --> where text is ""
      // Text is not an element but a more general Node
      const firstElementChild = (sel.focusNode as HTMLDivElement)?.firstElementChild;

      if (!firstElementChild) return;

      const hasCtxSpanAsOnlyElement =
        getDivRef().firstElementChild === getDivRef().lastElementChild &&
        firstElementChild.classList.contains('context-variable');

      // If there are other text nodes inside the Text Box, this issue is not present
      if (hasCtxSpanAsOnlyElement) {
        // Pre-selecting the span element for when the backspace fires, it shall be purged
        range?.selectNode(firstElementChild);
      }
    }
  };

  const handleKeyPressWhileCtxDropdownOpen = (key: string) => {
    switch (key) {
      case 'Backspace':
        setCtxFilter((filter) => filter.substring(0, filter.length - 1));
        break;
      case 'Escape':
        insertTextAtCaret(ctxVarStore.trigger, selectionRange!);
        resetCtxDropdown();
        break;
      case 'ArrowUp':
        if (selectedCtxIndex === 0) {
          setSelectedCtxIndex(ctxVarStore.userAndSystemVariables.length - 1);
          return;
        }
        setSelectedCtxIndex((prev) => (prev -= 1));
        break;
      case 'ArrowDown':
        if (selectedCtxIndex === ctxVarStore.userAndSystemVariables.length - 1) {
          setSelectedCtxIndex(0);
          return;
        }
        setSelectedCtxIndex((prev) => (prev += 1));
        break;
      case 'Enter':
      case 'Tab':
        if (Utilities.isEmpty(ctxFilteredList)) return;

        insertCtxAtCaret(ctxFilteredList[selectedCtxIndex]);
        resetCtxDropdown();
        break;
      default:
        if (key.length === 1) {
          setCtxFilter((filter) => (filter += key));
          setSelectedCtxIndex(0);
        }
        break;
    }
  };

  const resetCtxDropdown = () => {
    setCtxFilter('');
    setSelectedCtxIndex(0);
    setCtxDropdownOpen(false);
    setWindowSelection(null);
  };

  const createCtxSpan = (ctx: IContextVariable) => {
    const span = document.createElement('span');
    span.className = 'context-variable';
    span.textContent = ctxVarStore.trigger + ctx.name;
    span.dataset.ctxId = ctx.id;
    span.dataset.type = ctx.instanceType;

    span.contentEditable = 'false';
    span.onclick = () => handleCtxClick(ctx);
    return span;
  };

  const determineInsertedCtxType = (ctx: IContextVariable) => {
    switch (ctx.instanceType) {
      case ContextVariableInstanceTypes.ListContextVariable: {
        return {
          icon: getIcon('list'),
          type: ctx.instanceType,
        };
      }
      case ContextVariableInstanceTypes.SystemContextVariable: {
        return {
          icon: getIcon('config'),
          type: ctx.instanceType,
        };
      }
      case ContextVariableInstanceTypes.ContextVariable:
      default: {
        return {
          icon: getIcon('user'),
          type: ctx.instanceType,
        };
      }
    }
  };

  const insertCursorAfterElement = (element: HTMLElement) => {
    const range = selectionRange ?? document.createRange();
    const sel = windowSelection ?? window.getSelection();

    range.setStart(element, 0);
    range.collapse(false);

    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const insertCtxAtCaret = (ctx: IContextVariable) => {
    const span = createCtxSpan(ctx);

    if (selectionRange) {
      selectionRange.insertNode(span);
    }

    saveSanitizedMessage();
    insertCursorAfterElement(span);
    resetCtxDropdown();
  };

  const insertTextAtCaret = (text: string, range?: Range) => {
    const node = document.createTextNode(text);

    if (!range) range = document.createRange();

    range.insertNode(node);
    range.collapse(false);

    saveSanitizedMessage();
    resetCtxDropdown();
  };

  const saveSanitizedMessage = () => {
    const sanitizedMessage = ctxVarStore
      .swapNamesToIds(getDivRef().innerText)
      .replace(/\u00A0/gi, ' ');

    saveHandler(sanitizedMessage);
  };

  const sanitizePaste = (event: React.ClipboardEvent) => {
    event.persist();
    event.preventDefault();

    const range = document.getSelection()?.getRangeAt(0);
    range?.deleteContents();

    insertTextAtCaret(event.clipboardData.getData('text/plain'), range);
  };

  const handleClickOutside = (event: any) => {
    if (
      searchRef?.current &&
      !searchRef?.current?.contains(event?.target) &&
      ctxDropdownOpen
    ) {
      event.preventDefault();
      insertTextAtCaret(ctxVarStore.trigger, selectionRange!);
      resetCtxDropdown();
    }
  };

  return (
    <Fragment>
      {title && <h4 style={{ margin: '5px 0' }}>{title}</h4>}
      <div
        id={id}
        ref={divRef}
        style={{ minHeight: multiline ? '60px' : 'auto' }}
        role={'textbox'}
        data-disabled={disabled}
        contentEditable={true}
        className={className ? className : 'contenteditable-textarea'}
        data-valid={validator ? (!touched ? true : validator()) : true}
        data-multiline={multiline}
        onFocus={handleFocus}
        onKeyDown={handleKeyPress}
        onBlur={saveSanitizedMessage}
        onPaste={sanitizePaste}
        suppressContentEditableWarning
      >
        {processedContent}
      </div>
      {ctxDropdownOpen && (
        <Dropdown attachedTo={getDivRef()} ref={searchRef}>
          <div className='ctx-search-field'>
            <i className={searchIcon}></i>
            <input type='text' name='filter' value={ctxFilter} readOnly />
          </div>
          {!Utilities.isEmpty(ctxFilteredList) ? (
            <Fragment>
              {ctxFilteredList.map((ctx, index) => (
                <div
                  className={`ctx-dropdown-item ${
                    index === selectedCtxIndex && 'ctx-item-active'
                  }`}
                  key={ctx.id}
                  data-ctx-id={ctx.id}
                  data-type={ctx.instanceType}
                  onMouseEnter={() => setSelectedCtxIndex(index)}
                  onClick={() => insertCtxAtCaret(ctx)}
                >
                  <i className={determineInsertedCtxType(ctx).icon}></i>{' '}
                  <span>{ctx.name}</span>
                </div>
              ))}
            </Fragment>
          ) : (
            <div
              className='ctx-dropdown-item'
              style={{ textAlign: 'center', cursor: 'default' }}
            >
              No match found
            </div>
          )}
        </Dropdown>
      )}
    </Fragment>
  );
};

export default observer(TextBoxWithCtx);
