import { observer } from 'mobx-react-lite';
import React from 'react';

interface IProps {
  darkMode?: boolean;
  children: React.ReactNode;
}

const Editor: React.FC<IProps> = ({ darkMode = false, children }) => {
  return (
    <div className='editor' data-dark-mode={darkMode.toString()}>
      {children}
    </div>
  );
};

export default observer(Editor);
