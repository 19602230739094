import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { FILTERS, DESCENDING_SORT, ASCENDING_SORT } from '../../../../architecture/constants/ConversationHistory';

interface IProps {
  handleParamsChange: (key: string, value: string) => void;
}

const SortContainer: React.FC<IProps> = ({ handleParamsChange }) => {
  const getIcon = useGetIcon();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const sortItems = () => {
    if(Number(params.sort) === DESCENDING_SORT || !params.sort){
      handleParamsChange(FILTERS.SORT.NAME, ASCENDING_SORT.toString());
    } else {
      handleParamsChange(FILTERS.SORT.NAME, DESCENDING_SORT.toString());
    }
  }

  return (
    <div className='sort-container'>
      <div onClick={() => sortItems()}> Sort by date
        {(Number(params.sort) === DESCENDING_SORT || !params.sort)? (
          <i className={getIcon('down')}> </i>
        ) : (
          <i className={getIcon('up')}> </i>
        )}
      </div>
    </div>
  );
};

export default observer(SortContainer);
