import { observable, makeObservable } from 'mobx';
import { AuxiliaryHistoryElement } from './AuxiliaryHistoryElement';

export class ContextVariableUpdateElement extends AuxiliaryHistoryElement {
  contextVariableId: string;
  initialValue: string[];
  metadata: any;
  value: string[];

  constructor() {
    super();
    this.contextVariableId = '';
    this.initialValue = [];
    this.metadata = '';
    this.value = [];

    makeObservable(this, {
      contextVariableId: observable,
      initialValue: observable,
      value: observable,
      metadata: observable
    });
  }
}
