import { observer } from 'mobx-react-lite';
import React from 'react';
import { NameQuestionNode as NameQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NameQuestionNode';
import MultiQuestionNode from '../MultiQuestionNode/MultiQuestionNode';
interface IProps {
  dialogNode: NameQuestionNodeModel;
}

const NameQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <MultiQuestionNode dialogNode={dialogNode} />;
};

export default observer(NameQuestionNode);
