import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Column, useFlexLayout, useTable } from 'react-table';
import { TableData } from '../../../architecture/enums/TableData';
import { DailyBillingSummary } from '../../../models/Admin/DailyBillingSummary';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import { HEADERS } from './constants';

interface IProps {
  onRowClick: () => void;
}

const BillingOverview: React.FC<IProps> = ({ onRowClick }: IProps) => {
  const { botAdminStore, billingAdminStore } = useAdminStores();

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: HEADERS.communicationActivities.id,
        Header: HEADERS.communicationActivities.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).communicationActivities,
      },
      {
        id: HEADERS.conversations.id,
        Header: HEADERS.conversations.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).conversations,
      },
      {
        id: HEADERS.conversationThreads.id,
        Header: HEADERS.conversationThreads.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).conversationThreads,
      },
      {
        id: HEADERS.credits.id,
        Header: HEADERS.credits.label,
        accessor: (transaction: TableData) =>
          (transaction as DailyBillingSummary).credits,
      },
      {
        id: HEADERS.costs.id,
        Header: HEADERS.costs.label,
        accessor: (transaction: TableData) => (transaction as DailyBillingSummary).costs,
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    const billingSummary = billingAdminStore.getBillingSummary;
    if (!billingSummary) {
      return [];
    }

    return [
      {
        communicationActivities: billingSummary.communicationActivities,
        conversations: billingSummary.conversations,
        conversationThreads: billingSummary.conversationThreads,
        credits: billingSummary.credits,
        costs: billingSummary.costs,
      },
    ];
  }, [billingAdminStore.getBillingSummary]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  return (
    <Table
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      selectedRow={botAdminStore.getSelectedBot as TableData}
      prepareRow={prepareRow}
      onRowClick={() => onRowClick()}
    />
  );
};

export default observer(BillingOverview);
