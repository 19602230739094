import React from 'react';

interface IProps {
  children: React.ReactNode;
}

const TabHeader: React.FC<IProps> = ({ children }) => {
  return <div className='tab-header'>{children}</div>;
};

export default TabHeader;
