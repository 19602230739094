import { observer } from 'mobx-react-lite';
import React from 'react';
import { AddressQuestionNode as AddressQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AddressQuestionNode';
import MultiQuestionNode from '../MultiQuestionNode/MultiQuestionNode';
interface IProps {
  dialogNode: AddressQuestionNodeModel;
}

const AddressQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <MultiQuestionNode dialogNode={dialogNode} />;
};

export default observer(AddressQuestionNode);
