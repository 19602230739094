import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import useAdminStores from '../../customHooks/useAdminStores';
import SubscriptionsList from '../subscriptions/SubscriptionsList';
import AvatarTexturesList from './AvatarTexturesList';

const AvatarTexturesPage: React.FC = () => {
  const { subscriptionAdminStore } = useAdminStores();

  useEffect(() => {
    return () => {
      subscriptionAdminStore.setSelectedSubscription(undefined);
    };
  }, []);

  const showSubscriptions = () => {
    return <SinglePane pane={<SubscriptionsList />} />;
  };

  const showSubscriptionsAndTextures = () => {
    return (
      <TwoPanes leftPane={<SubscriptionsList />} rightPane={<AvatarTexturesList />} />
    );
  };

  if (subscriptionAdminStore.getSelectedSubscription) {
    return showSubscriptionsAndTextures();
  }

  return showSubscriptions();
};

export default observer(AvatarTexturesPage);
