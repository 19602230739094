import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router';
import img from './assets/Lailo_Logo_Black.png';
import MainPage from './components/layout/MainPage';
import WelcomeNewUserPage from './components/layout/Welcome/WelcomeNewUserPage';
import WelcomePage from './components/layout/Welcome/WelcomePage';
import AuthContext from './ms_authentication/context/AuthContext';

function App() {
  useEffect(() => {
    console.log(
      `%cVersion: ${process.env.REACT_APP_VERSION}, Feed: ${process.env.REACT_APP_ENV}`,
      `display: inline-block ; background: url(${img}) no-repeat center center/contain; ` +
        'padding: 10px 175px 108px 10px ; ' +
        'border: 2px solid black ; font-size: 11px ; line-height: 11px ; ' +
        'font-family: monospace ;'
    );
  }, []);

  const { newUser, loading } = useContext(AuthContext);

  return (
    <>
      <AuthenticatedTemplate>
        {!loading && (newUser ? <WelcomeNewUserPage /> : <MainPage />)}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <WelcomePage />
      </UnauthenticatedTemplate>
    </>
  );
}

export default withRouter(observer(App));
