import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

interface IProps {
  leftPane: ReactNode;
  rightPane: ReactNode;
}

const TwoPanes = ({ leftPane, rightPane }: IProps) => {
  return (
    <div className='two-panes'>
      {leftPane}
      {rightPane}
    </div>
  );
};

export default observer(TwoPanes);
