import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import rootStore from '../../../stores/rootStore';
import Actions from './Actions';
import Search from './Search';

const Header: React.FC = () => {
  const { knowledgebaseStore } = useContext(rootStore);

  useEffect(() => {
    return () => {
      knowledgebaseStore.setSearchQuery(undefined);
    };
  }, []);

  const handleParamsChange = (value: string) => {
    knowledgebaseStore.setSearchQuery(value);
  };

  const clearParamsChange = () => {
    knowledgebaseStore.setSearchQuery(undefined);
  };

  return (
    <div className='header-wrapper'>
      <Search
        handleParamsChange={handleParamsChange}
        clearParamsChange={clearParamsChange}
      />
      <Actions />
    </div>
  );
};

export default observer(Header);
