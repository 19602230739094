import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import rootStore from '../../../stores/rootStore';
import SinglePane from '../../common/paneNavigation/SinglePane';
import SinglePaneWithNavigation from '../../common/paneNavigation/SinglePaneWithNavigation';
import DatasetList from '../manage/DatasetList';
import DataList from './DataList';

const DatasetData: React.FC = () => {
  const { datasetStore } = useContext(rootStore);

  useEffect(() => {
    return () => {
      datasetStore.setSelectedDataset(null);
    };
  }, []);

  const showDatasetListOnly = () => {
    return <SinglePane pane={<DatasetList />} />;
  };

  const showData = () => {
    return (
      <SinglePaneWithNavigation
        pane={<DataList />}
        clickHandler={() => datasetStore.setSelectedDataset(null)}
        buttonContent='All Datasets'
      />
    );
  };

  if (datasetStore.getSelectedDataset) {
    return showData();
  }

  return showDatasetListOnly();
};

export default observer(DatasetData);
