import React, { useContext } from 'react';

import { observer } from 'mobx-react-lite';
import rootStore from '../../../stores/rootStore';
import useConfirmBeforeUnload from '../../customHooks/useConfirmBeforeUnload';

interface IProps {
  children: JSX.Element;
}

const Designer = ({ children }: IProps) => {
  const { appStore } = useContext(rootStore);

  useConfirmBeforeUnload(appStore.hasEditorStructureChanged);

  // The following commented code was meant for the auto-save feature and is not
  // used at the moment. See LocalStorageStore for more information.
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     localStorageStore.backupUnsavedDialog();
  //   }, 5000);
  //   return () => clearInterval(interval);
  // }, [dialogStore.currentlyEditedDialog?.dialogId]);

  // useEffect(() => {
  //   if (dialogStore.autoSavedDialogAvailable) {
  //     showModal(ModalTypes.RestoreStructure);
  //   }
  // }, [dialogStore.autoSavedDialogAvailable]);

  return <div id='designer'>{children}</div>;
};

export default observer(Designer);
