import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { TableData } from '../../../../architecture/enums/TableData';
import Table from '../../../common/table/Table';
import { PROPERTY_HEADERS } from '../../../../architecture/constants/Dataset';
import { Property } from '../../../../models/Dataset/Property/Property';
import { Dataset } from '../../../../models/Dataset/Dataset';
import { SimpleProperty } from '../../../../models/Dataset/Property/SimpleProperty';
import { DatasetProperty } from '../../../../models/Dataset/Property/DatasetProperty';
import { DatasetListProperty } from '../../../../models/Dataset/Property/DatasetListProperty';
import Actions from './cells/Actions';
import { PropertyState, PropertyType } from '../../../../architecture/enums/Dataset';
import Type from './cells/Type';
import { IProperty } from '../../../../architecture/interfaces/dataset/IProperty';

interface IProps {
  editMode: boolean;
  dataset: Dataset;
  setEditProperty: (
    property: SimpleProperty | DatasetProperty | DatasetListProperty | null
  ) => void;
}

const PropertyList: React.FC<IProps> = ({ editMode, dataset, setEditProperty }) => {
  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: PROPERTY_HEADERS.key.id,
        Header: PROPERTY_HEADERS.key.label,
        accessor: (property: TableData) => (property as Property).key,
        Cell: (item: Cell<SimpleProperty | DatasetProperty | DatasetListProperty>) => {
          return (
            <div
              className={`${
                item.row.original.state === PropertyState.Deleted && 'deleted'
              }`}
            >
              {item.row.original.key}
            </div>
          );
        },
      },
      {
        id: PROPERTY_HEADERS.type.id,
        Header: PROPERTY_HEADERS.type.label,
        accessor: (property: TableData) => (property as Property).type,
        Cell: (item: Cell<DatasetListProperty | DatasetProperty | SimpleProperty>) => {
          return (
            <div
              className={`${
                item.row.original.state === PropertyState.Deleted && 'deleted'
              }`}
            >
              <Type property={item.row.original} />
            </div>
          );
        },
      },
      {
        id: PROPERTY_HEADERS.annotation.id,
        Header: PROPERTY_HEADERS.annotation.label,
        accessor: (property: TableData) => (property as Property).annotation,
        Cell: (item: Cell<SimpleProperty | DatasetProperty | DatasetListProperty>) => {
          return (
            <div
              className={`${
                item.row.original.state === PropertyState.Deleted && 'deleted'
              }`}
            >
              {item.row.original.annotation}
            </div>
          );
        },
      },
      {
        id: PROPERTY_HEADERS.actions.id,
        Header: PROPERTY_HEADERS.actions.label,
        width: 60,
        Cell: (item: Cell<SimpleProperty | DatasetProperty | DatasetListProperty>) => {
          if (editMode) {
            return (
              <Actions
                property={item.row.original}
                setEditProperty={setEditProperty}
                dataset={dataset}
              />
            );
          } else return null;
        },
      },
    ],
    [editMode]
  );

  const data: TableData[] = useMemo(() => {
    if (!dataset?.properties) {
      return [];
    }
    return dataset?.properties.map((property: IProperty) => {
      if (property.type === PropertyType.Dataset) return property as DatasetProperty;
      if (property.type === PropertyType.ListDataset)
        return property as DatasetListProperty;
      if (property.type === PropertyType.Simple) return property as SimpleProperty;
      return property as SimpleProperty;
    });
  }, [JSON.stringify(dataset)]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  const handleRowClick = (property: TableData) => {
    setEditProperty(property as SimpleProperty | DatasetProperty | DatasetListProperty);
  };

  return (
    <div className='container-wrapper property-table-wrapper'>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
        onRowClick={editMode ? handleRowClick : undefined}
      />
    </div>
  );
};

export default observer(PropertyList);
