import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { BaseDialogNode } from '../../../models/DialogNodes/BaseDialogNode';
import rootStore from '../../../stores/rootStore';
import Textarea from '../../common/inputElements/Textarea';
import useDialogAttributeHandler from '../../customHooks/useAttributeChangeHandler';
import NodeBottomBar from '../../dialogElements/nodes/misc/NodeBottomBar';
import NodeIcon from './misc/NodeIcon';
import NodeSideBar from './misc/NodeSideBar';

interface IProps {
  dialogNode: BaseDialogNode;
  children?: React.ReactNode;
}

const DialogNode: React.FC<IProps> = ({ dialogNode, children }: IProps) => {
  const updateTitle = useDialogAttributeHandler<BaseDialogNode>(dialogNode, 'title');
  const { uiStore } = useContext(rootStore);

  return (
    <div
      className='dialog-node-container'
      id={dialogNode.id}
      data-error={!dialogNode.isValid}
    >
      <div className='dialog-node' data-node-type={dialogNode.type}>
        <NodeSideBar dialogNode={dialogNode} />
        <NodeIcon
          icon={uiStore.nodeIcons[dialogNode.type]}
          isError={!dialogNode.isValid}
        />
        <div className='node-description'>
          <Textarea
            name='title'
            value={dialogNode.title || ''}
            textAlign='center'
            changeHandler={updateTitle}
          />
          {children}
        </div>
      </div>
      <NodeBottomBar dialogNode={dialogNode} />
    </div>
  );
};

export default observer(DialogNode);
