import { useGetIcon } from './useGetIcon';
import { Media as MediaModel } from '../../models/Media/Media';
import {
  PDF_CONTENT_TYPE,
  SPREADSHEET_CONTENT_TYPE,
  PRESENTATION_CONTENT_TYPE,
  LINE_CONTENT_TYPE,
} from '../../architecture/constants/Media';

export function useGetDocumentMediaFileIcon() {
  const getIcon = useGetIcon();

  const getDocumentMediaFileIcon = (item: MediaModel) => {
    if (PDF_CONTENT_TYPE.includes(item?.contentType)) return getIcon('pdf');
    else if (SPREADSHEET_CONTENT_TYPE.includes(item?.contentType))
      return getIcon('spreadsheet');
    else if (PRESENTATION_CONTENT_TYPE.includes(item?.contentType))
      return getIcon('presentation');
    else if (LINE_CONTENT_TYPE.includes(item?.contentType)) return getIcon('doc');
    else return getIcon('file_exclamantion');
  };

  return getDocumentMediaFileIcon;
}
