import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, BillingAdminState } from '../../architecture/enums/Admin';
import type {
  IBillingSummary,
  IDailyBillingSummary,
} from '../../architecture/interfaces/HTTP/AdminParams';
import { BillingSummary } from '../../models/Admin/BillingSummary';
import { DailyBillingSummary } from '../../models/Admin/DailyBillingSummary';
import { BillingConnector } from '../../models/Connectors/admin/BillingConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class BillingAdminStore {
  private static instance: BillingAdminStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    BillingAdminStore.instance = this;
  }

  @observable
  state: BillingAdminState = BillingAdminState.None;

  @observable
  private _dailySummaries: DailyBillingSummary[] = [];

  @observable
  private _billingSummary: BillingSummary | undefined = undefined;

  @observable
  private _from?: Date = undefined;

  @observable
  private _to?: Date = undefined;

  @computed
  get timespanSelected(): boolean {
    return !!this._from || !!this._to;
  }

  @action
  async setTimespan(from?: Date, to?: Date) {
    this._from = from;
    this._to = to;

    await this.load();
  }

  @action
  resetTimespan() {
    this._from = undefined;
    this._to = undefined;
  }

  @computed
  get getBillingSummary(): BillingSummary | undefined {
    return this._billingSummary;
  }

  @computed
  getFrom() {
    return this._from;
  }

  @computed
  getTo() {
    return this._to;
  }

  @computed
  getState(state: BillingAdminState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: BillingAdminState) {
    this.state |= state;
  }

  @computed
  removeState(state: BillingAdminState) {
    this.state &= ~state;
  }

  @computed
  get allDailySummaries() {
    return this._dailySummaries;
  }

  async load() {
    try {
      if (this.getState(BillingAdminState.DetailsLoading)) {
        return;
      }

      if (!this.timespanSelected) return;

      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      const botId = this.rootStore.adminStore.botAdminStore.getSelectedBot?.id;
      if (!botId) return;

      this.setState(BillingAdminState.DetailsLoading);

      const response = await BillingConnector.get(
        subscriptionId,
        botId,
        this._from,
        this._to
      );

      this.purge();
      runInAction(() => this.restoreDailySummaries(response.dailySummaries));
      runInAction(() => this.restoreSummary(response.summary));

      this.removeState(BillingAdminState.DetailsLoading);
    } catch (error) {
      this.removeState(BillingAdminState.DetailsLoading);
    }
  }

  @action
  private restoreSummary(summary: IBillingSummary) {
    this._billingSummary = plainToInstance(
      ADMINS_CLASS_MAPPING[Admin.BillingSummary],
      summary
    );
  }

  @action
  private restoreDailySummaries(dailySummaries: IDailyBillingSummary[]) {
    dailySummaries.forEach((dailySummary) => {
      const instance = plainToInstance(
        ADMINS_CLASS_MAPPING[Admin.BillingDailySummary],
        dailySummary
      );
      this._dailySummaries.push(instance);
    });
  }

  @action
  private purge() {
    this._billingSummary = undefined;
    this._dailySummaries = [];
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('BillingAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default BillingAdminStore;
