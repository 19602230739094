import type { AccountInfo } from '@azure/msal-browser';
import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { USERS_SUBSCRIPTION } from '../architecture/constants/LocalStorage';
import { DatasetState } from '../architecture/enums/DatasetState';
import { DialogState } from '../architecture/enums/DialogState';
import { MediaState } from '../architecture/enums/MediaState';
import { Role } from '../architecture/enums/Roles';
import { SubscriptionState } from '../architecture/enums/SubscriptionState';
import type {
  IGetSubscriptionsResponse,
  ISubscription,
  IUsersSubscription,
} from '../architecture/interfaces/HTTP/SubscriptionParams';
import { SubscriptionConnector } from '../models/Connectors/SubscriptionConnector';
import { Subscription } from '../models/Subscription/Subscription';
import { RootStore } from './rootStore';

class SubscriptionStore {
  private static instance: SubscriptionStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    SubscriptionStore.instance = this;
  }

  @observable
  private _subscriptionRepository: Subscription[] = [];

  @observable
  state: SubscriptionState = SubscriptionState.None;

  @computed
  getState(state: SubscriptionState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: SubscriptionState) {
    this.state |= state;
  }

  @computed
  removeState(state: SubscriptionState) {
    this.state &= ~state;
  }

  @computed
  get allSubscriptions() {
    return this._subscriptionRepository;
  }

  @action
  purge() {
    this._subscriptionRepository = [];
  }

  @observable
  selectedSubscription?: Subscription;

  @action
  getUsersSubscription(userId: string) {
    let usersSubscriptions = this.getUsersSubscriptionsFromLocalStorage();

    const alreadyStoredUsersSubscription = usersSubscriptions.find(
      (us: IUsersSubscription) => us.user === userId
    );

    if (alreadyStoredUsersSubscription) {
      this.setSelectedSubscription(alreadyStoredUsersSubscription.subscription);
    }

    return this.selectedSubscription;
  }

  @action
  storeUsersSubscription(subscriptionId: string, userId: string) {
    if (subscriptionId === this.selectedSubscription?.id) {
      return;
    }

    this.rootStore.datasetStore.removeState(DatasetState.Initialized);
    let usersSubscriptions = this.getUsersSubscriptionsFromLocalStorage();

    let subscriptionAlreadyStored = usersSubscriptions.find(
      (us: IUsersSubscription) => us.user === userId
    );

    if (subscriptionAlreadyStored) {
      subscriptionAlreadyStored.subscription = subscriptionId;
    } else {
      usersSubscriptions.push({
        user: userId,
        subscription: subscriptionId,
      });
    }

    this.rootStore.mediaStore.removeState(MediaState.Initialized);
    this.rootStore.dialogStore.removeState(DialogState.Initialized);
    this.rootStore.datasetStore.removeState(DatasetState.Initialized);
    this.setSelectedSubscription(subscriptionId);
    this.saveUsersSubscriptionToLocalStorage(usersSubscriptions);
  }

  @action
  async load(force: boolean = false) {
    try {
      if (!this.getState(SubscriptionState.Initialized) || force) {
        this.setState(SubscriptionState.SubscriptionsLoading);
        const response = await SubscriptionConnector.getSubscriptions();
        runInAction(() => this.restoreSubscriptions(response));

        this.removeState(SubscriptionState.SubscriptionsLoading);
        this.setState(SubscriptionState.Initialized);
      }
    } catch (error) {
      this.removeState(SubscriptionState.SubscriptionsLoading);
    }
  }

  @action
  async createTrialAccount(user: AccountInfo): Promise<boolean> {
    try {
      this.setState(SubscriptionState.CreatingTrialAccount);

      const givenName = (user.idTokenClaims as any).given_name;
      const surname = (user.idTokenClaims as any).family_name;
      const company = (user.idTokenClaims as any).extension_Company;

      const response = await SubscriptionConnector.createTrialAccount(
        givenName,
        surname,
        company
      );

      this.load(true);

      this.removeState(SubscriptionState.CreatingTrialAccount);

      return response;
    } catch (error) {
      this.removeState(SubscriptionState.CreatingTrialAccount);
      return false;
    }
  }

  @action
  private restoreSubscriptions(response: IGetSubscriptionsResponse) {
    this.purge();

    response.subscriptions.forEach((subscription: ISubscription) => {
      const instance = plainToInstance(Subscription, subscription);
      this._subscriptionRepository.push(instance);
    });

    if (
      this.allSubscriptions.some((subscription) =>
        subscription.roles.includes(Role.GlobalAdmin)
      )
    ) {
      this._subscriptionRepository.forEach((subscription) => {
        if (!subscription.roles.includes(Role.GlobalAdmin)) {
          subscription.roles.push(Role.GlobalAdmin);
        }
      });
    }
  }

  @action
  private saveUsersSubscriptionToLocalStorage(usersSubscriptions: IUsersSubscription[]) {
    localStorage.setItem(USERS_SUBSCRIPTION, JSON.stringify(usersSubscriptions));
  }

  @action
  private getUsersSubscriptionsFromLocalStorage(): IUsersSubscription[] {
    return JSON.parse(localStorage.getItem(USERS_SUBSCRIPTION) || '[]');
  }

  @action
  private setSelectedSubscription(subscriptionId: string) {
    this.selectedSubscription = this._subscriptionRepository.find(
      (s) => s.id === subscriptionId
    );
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('SubscriptionStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default SubscriptionStore;
