import React from 'react';

interface IProps {
  headers: string[];
  withControlColumn?: boolean;
}
const Head: React.FC<IProps> = ({ headers, withControlColumn = true }) => {
  return (
    <thead>
      <tr>
        {headers.map((item) => (
          <th key={item}>{item}</th>
        ))}
        {withControlColumn && <th className='control-column-head'></th>}
      </tr>
    </thead>
  );
};

export default Head;
