import { Expose } from 'class-transformer';
import { ConditionTypes } from '../../architecture/enums/Condition';
import { Utilities } from '../Utilities/Utilities';
import { Condition, ISerializedCondition } from './Condition';

export class ActionCardCondition extends Condition {
  type: ConditionTypes = ConditionTypes.ActionCardCondition;

  @Expose()
  actionId: string;

  constructor(actionId: string = '', id?: string) {
    super(id);
    this.actionId = actionId;
  }

  get isValid(): boolean {
    return !Utilities.isEmpty(this.actionId);
  }

  serialize(): ISerializedActionCardCondition {
    return {
      ...super.serialize(),
      actionId: this.actionId,
    };
  }
}

export interface ISerializedActionCardCondition extends ISerializedCondition {
  actionId: string;
}
