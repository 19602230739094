import { IGetStorageDataResponse } from '../../architecture/interfaces/HTTP/StorageParams';
import { HttpAgent } from '../../utility/HttpAgent';
import { Notification } from '../Utilities/Notification';

export class StorageConnector {
  static async get(
    subscriptionId: string,
    datasetId: string,
    page?: number,
    pageSize?: number,
    descendingOrder?: boolean,
    from?: Date,
    to?: Date
  ): Promise<IGetStorageDataResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Subscriptions/${subscriptionId}/Datasets/${datasetId}/Storage?page=${
        page ? page : ''
      }&pageSize=${pageSize ? pageSize : ''}&from=${from ? from.toISOString() : ''}&to=${
        to ? to.toISOString() : ''
      }&orderByDescending=${descendingOrder !== undefined ? descendingOrder : ''}`
    );
  }

  static async delete(
    subscriptionId: string,
    datasetId: string,
    ids: string[]
  ): Promise<void> {
    return await HttpAgent.requests
      .delete(`/Api/V2/Subscriptions/${subscriptionId}/Datasets/${datasetId}/Storage`, {
        data: {
          entries: ids,
        },
      })
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async export(
    subscriptionId: string,
    datasetId: string,
    ids: string[],
    format: 'json' | 'csv'
  ): Promise<ArrayBuffer | undefined> {
    if (datasetId === undefined || format === undefined) {
      return undefined;
    }

    return await HttpAgent.requests.post(
      `/Api/V2/Subscriptions/${subscriptionId}/Datasets/${datasetId}/Storage/Export`,
      {
        format: format,
        ids: ids,
      },
      {
        responseType: 'arraybuffer',
      }
    );
  }
}
