import { observer } from 'mobx-react-lite';
import React from 'react';
import { NumberQuestionNode as NumberQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NumberQuestionNode';
import SingleQuestionNode from '../SingleQuestionNode/SingleQuestionNode';
interface IProps {
  dialogNode: NumberQuestionNodeModel;
}

const NumberQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <SingleQuestionNode dialogNode={dialogNode} />;
};

export default observer(NumberQuestionNode);
