import {
  IConfirmationSettings,
  IResetBotSecret,
  ISettings,
} from '../../architecture/interfaces/HTTP/SettingsParams';
import { HttpAgent } from '../../utility/HttpAgent';

export class BotSettingsConnector {
  static async updateConfirmationSettings(
    botId?: string,
    body?: IConfirmationSettings
  ): Promise<void> {
    return await HttpAgent.requests.put(
      `/Api/V2/Bots/${botId}/Settings/Confirmation`,
      body
    );
  }

  static async getSettings(botId?: string): Promise<ISettings> {
    return await HttpAgent.requests.get(`/Api/V2/Bots/${botId}/Settings`);
  }

  static async resetBotSecret(botId?: string): Promise<IResetBotSecret> {
    return await HttpAgent.requests.get(`/Api/V2/Bots/${botId}/Settings/BotSecret`);
  }
}
