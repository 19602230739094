import { Expose, Type } from 'class-transformer';
import { makeAutoObservable } from 'mobx';
import {
  IBillingAddress,
  IContact,
  ISubscription,
} from '../../architecture/interfaces/HTTP/AdminParams';
import { ISubscriptionForm } from '../../architecture/interfaces/admin/ISubscriptionForm';

export class Subscription implements ISubscription {
  @Expose()
  id?: string;

  @Expose()
  name: string;

  @Expose()
  creationDate?: Date;

  @Expose()
  @Type(() => Contact)
  contact: Contact;

  @Expose()
  @Type(() => BillingAddress)
  billingAddress: BillingAddress;

  @Expose()
  isReseller: boolean;

  @Expose()
  isEmpty: boolean;

  constructor(
    id?: string,
    name?: string,
    isReseller?: boolean,
    contact?: Contact,
    billingAddress?: BillingAddress,
    isEmpty?: boolean
  ) {
    this.id = id;
    this.name = name ?? '';
    this.isReseller = isReseller ?? false;
    this.isEmpty = isEmpty !== undefined ? isEmpty : true;

    this.contact = contact ?? new Contact();
    this.billingAddress = billingAddress ?? new BillingAddress();

    this.creationDate = undefined;

    makeAutoObservable(this);
  }

  public static fromForm(form: ISubscriptionForm) {
    return new Subscription(
      form.id,
      form.subscriptionName,
      form.isReseller,
      new Contact(form.firstName, form.lastName, form.phoneNumber),
      new BillingAddress(
        form.companyName,
        form.receiverName,
        form.street,
        form.postalCode,
        form.city,
        form.country
      )
    );
  }
}

export class Contact implements IContact {
  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  phoneNumber: string;

  constructor(firstName?: string, lastName?: string, phoneNumber?: string) {
    this.firstName = firstName ?? '';
    this.lastName = lastName ?? '';
    this.phoneNumber = phoneNumber ?? '';

    makeAutoObservable(this);
  }
}

export class BillingAddress implements IBillingAddress {
  @Expose()
  companyName?: string;

  @Expose()
  receiverName?: string;

  @Expose()
  street?: string;

  @Expose()
  postalCode?: string;

  @Expose()
  city?: string;

  @Expose()
  country?: string;

  constructor(
    companyName?: string,
    receiverName?: string,
    street?: string,
    postalCode?: string,
    city?: string,
    country?: string
  ) {
    this.companyName = companyName ?? '';
    this.receiverName = receiverName ?? '';
    this.street = street ?? '';
    this.postalCode = postalCode ?? '';
    this.city = city ?? '';
    this.country = country ?? '';

    makeAutoObservable(this);
  }
}
