import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { QuestionDialogNode as QuestionDialogNodeModel } from '../../../../models/DialogNodes/QuestionNodes/QuestionDialogNode';
import CommunicationDialogNode from '../communicationNode/CommunicationDialogNode';

interface IProps {
  dialogNode: QuestionDialogNodeModel;
  children?: React.ReactNode;
}

const QuestionDialogNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <Fragment>
      <CommunicationDialogNode dialogNode={dialogNode}>
        {children}
      </CommunicationDialogNode>
    </Fragment>
  );
};

export default observer(QuestionDialogNode);
