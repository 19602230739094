import React from 'react';

interface IProps {
  isStored: boolean;
  canStoreKeyArray: boolean;
  onKeyClick: () => void;
  title: string | number;
}
const Key: React.FC<IProps> = ({ isStored, canStoreKeyArray, onKeyClick, title }) => {
  return (
    <span
      className='json-prop-row-key'
      data-stored={isStored}
      data-can-store-key-array={canStoreKeyArray}
      onClick={onKeyClick}
    >
      {title}{' '}
    </span>
  );
};

export default Key;
