import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { PropertyType } from '../../../../../architecture/enums/Dataset';
import { DatasetProperty } from '../../../../../models/Dataset/Property/DatasetProperty';
import { PropertyValue } from '../../../../../models/Dataset/PropertyValue';
import { DatasetActionNode as DatasetActionNodeModel } from '../../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import rootStore from '../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import Select from '../../../../common/inputElements/select/Select';
import PropertiesValues from './components/PropertiesValues';

interface IProps {
  dialogNode: DatasetActionNodeModel;
}
const UpdateExistingDatasetVariable: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore, datasetStore } = useContext(rootStore);
  const [dataset, setDataset] = useState(
    datasetStore.allDatasets.find((d) => d.name === dialogNode.contextVariable?.type)
  );
  const [canBeUpdatedProperties, setCanBeUpdatedProperties] = useState(
    dataset?.properties?.filter(
      (value) =>
        !dialogNode.properties.find((prop) => prop.annotation === value.annotation)
    )
  );

  const handleCtxSelection = (value: string) => {
    dialogNode.contextVariable = ctxVarStore.datasetVariables?.find(
      (d) => d.name === value
    );

    const linkedDataset = datasetStore.allDatasets.find(
      (d) => d.name === dialogNode.contextVariable?.type
    );

    dialogNode.properties = [];
    dialogNode.dataset = linkedDataset;

    setDataset(linkedDataset);
    setCanBeUpdatedProperties(
      linkedDataset?.properties?.filter(
        (value) =>
          !dialogNode.properties.find((prop) => prop.annotation === value.annotation)
      )
    );
  };

  const updateProperty = (value: string) => {
    const property = dataset?.properties.find((prop) => prop.annotation === value);

    if (property)
      dialogNode.properties.push(
        new PropertyValue(
          property.id!,
          property.type,
          property.annotation,
          property.type === PropertyType.Simple ? '' : undefined,
          property.type !== PropertyType.Simple
            ? (property as DatasetProperty).dataset
            : undefined
        )
      );

    setCanBeUpdatedProperties(
      dataset?.properties?.filter(
        (value) =>
          !dialogNode.properties.find((prop) => prop.annotation === value.annotation)
      )
    );
  };

  return (
    <div>
      <div className='flex wrapper'>
        Update:
        <ContextVariableSelector
          items={ctxVarStore.datasetVariables}
          triggerLabel={dialogNode.contextVariable?.name ?? 'Add or select'}
          selectHandler={handleCtxSelection}
          replaceSpecialCharacters
          closeOnSelect
        />
        {dialogNode.contextVariable && (
          <div className='ml-1'>of dataset type {dialogNode.contextVariable.type} </div>
        )}
      </div>

      <PropertiesValues dialogNode={dialogNode} updating={true} />

      {dialogNode.contextVariable &&
        canBeUpdatedProperties &&
        canBeUpdatedProperties.length > 0 && (
          <div className='flex wrapper mt-1'>
            <div>Update property: </div>
            <Select
              triggerLabel={''}
              items={canBeUpdatedProperties?.map((value) => ({
                title: value.annotation,
              }))}
              className='select-input'
              selectHandler={(value) => updateProperty(value)}
              closeOnSelect
            />
          </div>
        )}
    </div>
  );
};

export default observer(UpdateExistingDatasetVariable);
