import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useReadOnly } from '../../../components/customHooks/useReadOnly';
import Input from '../../common/Input';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  handleParamsChange: (key: string) => void;
  clearParamsChange: () => void;
}

const Search: React.FC<IProps> = ({ handleParamsChange, clearParamsChange }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const [search, setSearch] = useState('');

  const blurHandler = () => {
    if (search) {
      handleParamsChange(search);
    } else {
      clearParamsChange();
    }
  };

  const keyDownHandler = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && search) {
      handleParamsChange(search);
    } else if (event.key === 'Enter' && !search) {
      clearParamsChange();
    }
  };

  const clearInput = () => {
    setSearch('');
    clearParamsChange();
  };

  const setInput = () => {
    handleParamsChange(search ?? '');
  };

  return (
    <div className='search-input'>
      <Input
        disabled={knowledgebaseReadOnlyMode}
        value={search ?? ''}
        className='filter-input'
        placeholder='Search for a Question or an Answer'
        changeHandler={(value) => setSearch(value)}
        blurHandler={blurHandler}
        keyDownHandler={keyDownHandler}
      />
      {search && <i className={getIcon('close')} onClick={clearInput}></i>}
      <i className={getIcon('search')} onClick={setInput}></i>
    </div>
  );
};

export default observer(Search);
