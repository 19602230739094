import React from 'react';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import Button from '../../../../../common/Button';
import ParagraphWithCtx from '../../../../../common/ParagraphWithCtx';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';
import { IContextFunction } from '../../../../../../architecture/interfaces/contextVariables/IContextActions';

interface IProps {
  item: IContextFunction;
  isManuallyAdded: boolean;
  openEditor: () => void;
}
const FunctionExecution: React.FC<IProps> = ({ item, isManuallyAdded, openEditor }) => {
  const getIcon = useGetIcon();
  return (
    <Fragment>
      <div className='item-title-group' onClick={openEditor}>
        <Button
          icon={getIcon('code')}
          className='ctx-list-item-icon btn-borderless'
          data-state='functionExecution'
          tooltip='Edit new value'
        />
        <span>
          <strong>{item.ctx.name}</strong>
          {isManuallyAdded && (
            <Button
              className='manually-added-ctx-icon'
              icon={getIcon('add_alt')}
              tooltip='This context variable was created manually'
              tooltipPosition='top'
            />
          )}
        </span>
      </div>
      <div className='ctx-list-item-details'>
        <p>
          The following command will be executed on <strong>{item.ctx.name}</strong>
        </p>
        <ParagraphWithCtx text={item.equation} />
      </div>
    </Fragment>
  );
};

export default observer(FunctionExecution);
