import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetIcon } from '../customHooks/useGetIcon';
import Button from './Button';

interface IBtnDropdownConfig {
  triggerBtn: React.ReactChild;
  controlBtns: React.ReactChild[];
}
interface IProps {
  title?: string;
  config: IBtnDropdownConfig;
  open: boolean;
  moveLeft?: boolean;
  setOpen: (open: boolean) => void;
}
const ButtonDropdown: React.FC<IProps> = ({
  title,
  config,
  open,
  moveLeft = false,
  setOpen,
}) => {
  const getIcon = useGetIcon();

  return (
    <div className='btn-dropdown-container'>
      {config.triggerBtn}
      <div
        className='btn-dropdown-container-body'
        data-open={open}
        data-move-left={moveLeft}
      >
        <Button
          className='btn-borderless'
          style={{
            color: 'whitesmoke',
            position: 'absolute',
            top: '-2.5px',
            right: '-2.5px',
          }}
          icon={getIcon('close')}
          clickHandler={() => setOpen(false)}
        />
        {title && <h4 className='title'>{title}</h4>}
        <div className='btn-container'>{config.controlBtns}</div>
      </div>
    </div>
  );
};

export default observer(ButtonDropdown);
