import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import type { DefaultBehaviour } from '../../architecture/enums/DefaultBehaviour';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { ISerializedTrigger, Trigger } from './Trigger';

export class DefaultTrigger extends Trigger {
  type: TriggerTypes = TriggerTypes.Default;

  @Expose()
  defaultBehaviour: DefaultBehaviour = 'DefaultBlock';

  @Expose()
  generativeAnswerPrompt: string = '';

  constructor() {
    super();

    makeObservable(this, {
      defaultBehaviour: observable,
      generativeAnswerPrompt: observable,
    });
  }

  serialize(): ISerializedDefaultTrigger {
    return {
      ...super.serialize(),
      defaultBehaviour: this.defaultBehaviour,
      generativeAnswerPrompt: this.generativeAnswerPrompt,
    };
  }
}

interface ISerializedDefaultTrigger extends ISerializedTrigger {
  defaultBehaviour: DefaultBehaviour;
  generativeAnswerPrompt: string;
}
