import { observer } from 'mobx-react-lite';
import React from 'react';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Links: React.FC<IProps> = ({ entry }) => {
  const getIcon = useGetIcon();

  const setUrlRedirectData = (actionId: string, caption?: string, url?: string) => {
    const action = entry.actions.find((action) => action.id === actionId);
    if (!action) throw new Error('Invalid operation');

    let parsedData = JSON.parse(action.data);

    if (caption !== undefined) {
      parsedData = { ...parsedData, caption };
    } else if (url !== undefined) {
      parsedData = { ...parsedData, url };
    }

    action.data = JSON.stringify(parsedData);
  };

  const renderUrls = () => {
    return entry.urlRedirectActions.map((action, key) => {
      const parsedData = JSON.parse(action.data);
      const caption = parsedData['caption'];
      const url = parsedData['url'];

      return (
        <div key={key} className='url-redirect-entry'>
          <Input
            value={caption ?? ''}
            changeHandler={(value) => setUrlRedirectData(action.id, value, undefined)}
            isValid={action.isCaptionValid}
            placeholder='Insert a caption'
          />
          <Input
            value={url ?? ''}
            changeHandler={(value) => setUrlRedirectData(action.id, undefined, value)}
            isValid={action.isUrlValid}
            placeholder='Insert a url'
          />
          <i
            className={`${getIcon('close')} close-icon`}
            onClick={() => entry.removeAction(action)}
          ></i>
        </div>
      );
    });
  };

  const addNewUrlRedirect = () => {
    entry.addRedirectAction();
  };

  return (
    <>
      <div className='title'></div>
      <div className='links-attachments'>
        <Button
          icon={getIcon('add')}
          content='New Link'
          className='btn-borderless add-condition-btn bold add-link-button'
          style={{ background: 'transparent' }}
          tooltipPosition='right'
          clickHandler={addNewUrlRedirect}
        />
        <div className='links-header'>
          <div className='links-headers-item'>Caption</div>
          <div className='links-headers-item'>URL</div>
        </div>
        {renderUrls()}
      </div>
    </>
  );
};

export default observer(Links);
