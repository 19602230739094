import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { DefaultBehaviour } from '../../../architecture/enums/DefaultBehaviour';
import { DefaultDialogBlock } from '../../../models/DialogBlocks/DefaultDialogBlock';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { DefaultTrigger } from '../../../models/Triggers/DefaultTrigger';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import RadioButtonGroup from '../../common/inputElements/RadioButtonGroup';
import Textarea from '../../common/inputElements/Textarea';

interface IProps {
  block: DialogBlockModel;
}

const defaultBehaviourOptions: { label: string; value: DefaultBehaviour }[] = [
  { label: 'Structured Response', value: 'DefaultBlock' },
  {
    label: 'AI-Generated Response',
    value: 'GenerativeAnswer',
  },
];

const DefaultBlockTrigger: React.FC<IProps> = ({ block }) => {
  const { botSettingsStore } = useContext(rootStore);

  const defaultBlock = block as DefaultDialogBlock;
  const defaultTrigger = block.trigger as DefaultTrigger;

  const resetPromptToDefault = () => {
    const defaultPrompt = botSettingsStore.settings.defaultPrompts.generativeAnswerPrompt;
    if (!defaultPrompt) throw new Error('Settings not loaded');

    defaultTrigger.generativeAnswerPrompt = defaultPrompt;
  };

  return (
    <div className='default-trigger-wrapper'>
      <h3 className='title'>Fallback Response</h3>
      <div className='input-group'>
        <RadioButtonGroup
          name='default-behaviour-btn-group'
          options={defaultBehaviourOptions}
          initialSelectedValue={defaultTrigger.defaultBehaviour}
          onSelectionChange={(value) => {
            defaultTrigger.defaultBehaviour = value as DefaultBehaviour;
            defaultBlock.switchDefaultBehaviour(defaultTrigger.defaultBehaviour);
          }}
        />
        {defaultTrigger.defaultBehaviour === 'GenerativeAnswer' && (
          <div className='prompt-input-container'>
            <div className='prompt-instruction'>
              Provide a general instruction to guide the AI-generated responses. This will
              influence the tone and style of the bot's answers. Use the 'Reset
              instruction' button to revert to the default guidance.
            </div>
            <Textarea
              className='prompt-textarea'
              rows={5}
              value={defaultTrigger.generativeAnswerPrompt}
              changeHandler={(text) => (defaultTrigger.generativeAnswerPrompt = text)}
            />
            <Button clickHandler={resetPromptToDefault} content={'Reset instruction'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(DefaultBlockTrigger);
