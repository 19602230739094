import { observable, makeObservable } from 'mobx';
import { BaseHistoryElement } from '../BaseHistoryElement';

export class InitiatingHistoryElement extends BaseHistoryElement {
  channel: string;

  constructor() {
    super();
    this.channel = '';

    makeObservable(this, {
      channel: observable,
    });
  }
}
