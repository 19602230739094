import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media as MediaModel } from '../../../models/Media/Media';
import MediaItem from '../MediaItem';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useGetDocumentMediaFileIcon } from '../../customHooks/useGetDocumentMediaFileIcon';

interface IProps {
  item: MediaModel;
}

const DocumentItem: React.FC<IProps> = ({ item }: IProps) => {
  const getIcon = useGetIcon();
  const getDocumentMediaFileIcon = useGetDocumentMediaFileIcon();

  return (
    <MediaItem item={item}>
      <i className={`${getDocumentMediaFileIcon(item)} document-icon`}></i>
      <div className='video-icon'>
        <i className={`${getIcon('document')} ${'media-item-icon'}`}></i>
      </div>
    </MediaItem>
  );
};

export default observer(DocumentItem);
