import { observable, makeObservable } from 'mobx';
import { BaseHistoryElement } from '../BaseHistoryElement';

export class NodeHistoryElement extends BaseHistoryElement {
  blockId: string;
  nodeId: string;

  constructor() {
    super();
    this.blockId = '';
    this.nodeId = '';

    makeObservable(this, {
      blockId: observable,
      nodeId: observable,
    });
  }
}
