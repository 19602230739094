import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { UserAdminState } from '../../../architecture/enums/Admin';
import { TableData } from '../../../architecture/enums/TableData';
import { User } from '../../../models/Admin/User';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import LoadingPage from '../../layout/LoadingPage';
import AddExistingUser from './AddExistingUser';
import CreateUser from './CreateUser';
import Actions from './components/Actions';
import { HEADERS } from './constants';

const UsersList: React.FC = () => {
  const { userAdminStore, subscriptionAdminStore } = useAdminStores();
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openAddExistingUserModal, setOpenAddExistingUserModal] = useState(false);
  const [info, setInfo] = useState<string | undefined | null>(null); // null is default value if error or info message doesn't exist

  useEffect(() => {
    userAdminStore.load();
  }, [subscriptionAdminStore.getSelectedSubscription]);

  const columns: Array<Column<TableData>> = React.useMemo(
    () => [
      {
        id: HEADERS.userName.id,
        Header: HEADERS.userName.label,
        accessor: (user: TableData) => (user as User).displayName,
        minWidth: 100,
      },
      {
        id: HEADERS.email.id,
        Header: HEADERS.email.label,
        accessor: (user: TableData) => (user as User).email,
        minWidth: 200,
      },
      {
        id: HEADERS.actions.id,
        Header: HEADERS.actions.label,
        width: 30,
        Cell: (item: Cell<User>) => {
          return <Actions item={item} />;
        },
      },
    ],
    []
  );

  const data: TableData[] = React.useMemo(() => {
    if (!userAdminStore.allUsers) {
      return [];
    }

    return userAdminStore.allUsers.map((user: User) => {
      return {
        id: user.id,
        email: user.email,
        displayName: user.displayName || '',
      };
    });
  }, [userAdminStore.allUsers]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<TableData>(
      {
        columns,
        data,
      },
      useFlexLayout
    );

  const handleRowClick = (user: TableData) => {
    userAdminStore.setSelectedUser(user.id as string);
  };

  const createUser = async (user: User) => {
    const response = await userAdminStore.create({
      givenName: user.givenName,
      surname: user.surname,
      email: user.email,
      roles: user.roles || [],
    });

    setInfo(response);
  };

  const handleCloseCreateUserModal = () => {
    setOpenCreateUserModal(false);
    if (info) {
      userAdminStore.load();
    }
    setInfo(null);
  };

  return (
    <div className='container-wrapper'>
      {userAdminStore.getState(UserAdminState.UsersLoading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4 className='title-name'>
              {subscriptionAdminStore.getSelectedSubscription?.name}
            </h4>
            <div className='button-wrapper'>
              <Button
                content='Add existing User'
                className='btn-primary btn-round'
                clickHandler={() => setOpenAddExistingUserModal(true)}
              />
              <Button
                content='Create User'
                className='btn-primary btn-round'
                clickHandler={() => setOpenCreateUserModal(true)}
              />
            </div>
          </div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            selectedRow={userAdminStore.getSelectedUser as TableData}
            prepareRow={prepareRow}
            onRowClick={handleRowClick}
          />
          <Modal
            manuallyTriggered={openCreateUserModal}
            closeHandler={handleCloseCreateUserModal}
          >
            <CreateUser
              resetModal={handleCloseCreateUserModal}
              saveUser={createUser}
              info={info}
            />
          </Modal>
          <Modal
            manuallyTriggered={openAddExistingUserModal}
            closeHandler={() => setOpenAddExistingUserModal(false)}
          >
            <AddExistingUser resetModal={() => setOpenAddExistingUserModal(false)} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(UsersList);
