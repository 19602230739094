import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { Trigger } from './Trigger';

export class ContextTrigger extends Trigger {
  // ctx: ContextVariable;

  constructor() {
    super();
    this.type = TriggerTypes.Context;
  }
}
