import { observer } from 'mobx-react-lite';
import React from 'react';
import { EventTargetPlatforms } from '../../../../../architecture/enums/EventTrigger';
import { EventTarget as EventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/EventTarget';
import { MicrosoftTeamsEventTarget as MicrosoftTeamsEventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/MicrosoftTeamsEventTarget';
import { TelegramEventTarget as TelegramEventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/TelegramEventTarget';
import Select from '../../../../common/inputElements/select/Select';
import { useReadOnly } from '../../../../customHooks/useReadOnly';
import EventTarget from '.././eventTargets/EventTarget';

interface IProps {
  target: EventTargetModel;
  setTarget: (target: EventTargetModel) => void;
}
const EventTargetContainer: React.FC<IProps> = ({ target, setTarget }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const handlePlatformSelect = (platform: EventTargetPlatforms) => {
    if (platform === target.platform) return;

    switch (platform) {
      case EventTargetPlatforms.MicrosoftTeams:
        setTarget(new MicrosoftTeamsEventTargetModel());
        break;
      case EventTargetPlatforms.Telegram:
        setTarget(new TelegramEventTargetModel());
        break;
      default:
        break;
    }
  };

  return (
    <div className='event-target-container'>
      <h3>Target Platform</h3>
      <Select
        items={Object.entries(EventTargetPlatforms).map(([key, value]) => ({
          title: value,
          id: key,
        }))}
        disabled={readOnly}
        triggerLabel={target.platform}
        selectHandler={(title) => handlePlatformSelect(title as EventTargetPlatforms)}
        fullWidth
        closeOnSelect
      />

      <EventTarget target={target} />
    </div>
  );
};

export default observer(EventTargetContainer);
