import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { SystemMessageElement as SystemMessageElementModel } from '../../../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/SystemMessageElement';
import BaseHistoryElement from '../BaseHistoryElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: SystemMessageElementModel;
}

const SystemMessageElement: React.FC<IProps> = ({ element }: IProps) => {
  const { blockStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  return (
    <BaseHistoryElement element={element} title='System Message' icon={getIcon('message')}>
      <div className='card-paragraph'>
        <i className={getIcon('text')}></i>
        <span className='card-paragraph-text'>{element.text}</span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(SystemMessageElement);
