import { IGetBotsResponse } from '../../architecture/interfaces/HTTP/DialogParams';
import { IGetSubscriptionsResponse } from '../../architecture/interfaces/HTTP/SubscriptionParams';
import { HttpAgent } from '../../utility/HttpAgent';

export class SubscriptionConnector {
  static async getBots(subscriptionId: string): Promise<IGetBotsResponse> {
    return await HttpAgent.requests.get(`/Api/V2/Subscriptions/${subscriptionId}/Bots`);
  }

  static async getSubscriptions(): Promise<IGetSubscriptionsResponse> {
    return await HttpAgent.requests.get(`/Api/V2/Subscriptions`);
  }

  static async createTrialAccount(
    givenName: string,
    surname: string,
    company: string
  ): Promise<boolean> {
    try {
      await HttpAgent.requests.post(`/Api/V2/Subscriptions/Trial`, {
        givenName,
        surname,
        company,
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}
