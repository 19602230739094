import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import ButtonDropdown from '../../../common/ButtonDropdown';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  content: string;
  active?: boolean;
  icon: string | undefined;
  clickHandler: () => void;
}

const ProfileMenuButton: React.FC<IProps> = ({
  content,
  icon,
  clickHandler,
  active = false,
}) => {
  const location = useLocation();
  const { nodeStore, appStore } = useContext(rootStore);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getIcon = useGetIcon();
  const history = useHistory();

  const handleSave = async () => {
    const savedSuccesfully = await appStore.save();

    if (!savedSuccesfully) {
      history.push('/error');
    }
  };

  const handleDiscard = () => {
    appStore.discardChanges();
  };

  const onButtonClick = () => {
    if (!appStore.hasEditorStructureChanged || !location.pathname.includes('dialogs')) {
      clickHandler();
    } else {
      setDropdownOpen(true);
    }
  };

  return (
    <ButtonDropdown
      title='Do you want to save your changes?'
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      config={{
        triggerBtn: (
          <Button
            icon={icon}
            className={`btn-borderless sign-out ${active && 'active'}`}
            content={content}
            clickHandler={onButtonClick}
          />
        ),
        controlBtns: [
          <Button
            key='Save'
            className='btn-primary btn-round mx-2'
            content='Save'
            disabled={appStore.isInErrorState || !nodeStore.areNodesValid}
            icon={getIcon('save')}
            name='btn-save-on-overview-switch'
            clickHandler={() => {
              handleSave && handleSave();
              setDropdownOpen(false);
              clickHandler();
            }}
          />,
          <Button
            key='Discard'
            className='btn-dark btn-round mx-2'
            content='Discard'
            icon={getIcon('close')}
            clickHandler={() => {
              handleDiscard && handleDiscard();
              setDropdownOpen(false);
              clickHandler();
            }}
          />,
        ],
      }}
    />
  );
};

export default observer(ProfileMenuButton);
