import { observer } from 'mobx-react-lite';
import React from 'react';
import { IncomingActionCardElement as IncomingActionCardElementModel } from '../../../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingActionCardElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: IncomingActionCardElementModel;
}

const IncomingActionCardElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <BaseHistoryElement element={element} title='Incoming Action Card' icon={getIcon('exclamation')}>
      <div className='card-paragraph'>
        <i className={getIcon('send')}></i>
        <span className='card-paragraph-text'> <i> Action Id: {element.actionId} </i> </span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(IncomingActionCardElement);
