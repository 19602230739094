import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  message: string;
}

const InfoMessage: React.FC<IProps> = ({ message }) => {
  const getIcon = useGetIcon();

  return (
    <div className='message info'>
      <div>User successfully created.</div>
      <div>
        Temporary password: <u>{message}</u>
        <i
          className={`${getIcon('copy')} pointer ml-1`}
          onClick={() => {
            navigator.clipboard.writeText(message);
          }}
        ></i>
      </div>
    </div>
  );
};

export default observer(InfoMessage);
