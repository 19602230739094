import { Exclude, Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { ContextVariable } from '../../ContextVariables/ContextVariable';
import { TransformIntoContextVariable } from '../../Utilities/Deserialization/Decorators';

export class EventParameter {
  @Exclude()
  id: string;

  @Expose()
  key: string;

  @Expose({ name: 'ctxId' })
  @TransformIntoContextVariable
  ctx: ContextVariable | null;

  @Expose()
  required: boolean = false;

  constructor(
    key?: string,
    ctx?: ContextVariable | null,
    required: boolean = false,
    id?: string
  ) {
    this.key = key ?? '';
    this.ctx = ctx ? ctx : null;
    this.required = required;
    this.id = id ?? v4();

    makeObservable(this, {
      key: observable,
      ctx: observable,
      required: observable,
    });
  }

  serialize(): ISerializedEventParameter {
    return {
      key: this.key,
      ctxId: this.ctx?.id,
      required: this.required,
    };
  }
}

export interface ISerializedEventParameter {
  key: string;
  ctxId?: string;
  required: boolean;
}
