import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { SubscriptionState } from '../../../architecture/enums/SubscriptionState';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import SubscriptionTable from '../../subscription/SubscriptionTable';
import LoadingPage from '../LoadingPage';

const SubscriptionSettings = () => {
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const { subscriptionStore } = useContext(rootStore);

  useEffect(() => {
    if (!userLoading && authenticated) {
      subscriptionStore.load(true);
    }

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  return subscriptionStore.getState(SubscriptionState.SubscriptionsLoading) ? (
    <LoadingPage />
  ) : (
    <div className='subscription-page'>
      <div className='subscription-content'>
        <h2 className='title'>Subscriptions</h2>
        <SubscriptionTable />
      </div>
    </div>
  );
};

export default observer(SubscriptionSettings);
