import axios from 'axios';
import {
  IAddDialogBody,
  IAddDialogResponse,
  IGetDialogResponse,
  IUpdateDialogBody,
  IUpdateDialogResponse,
  IUpdateDialogSettingsBody,
} from '../../architecture/interfaces/HTTP/DialogParams';
import { Notification } from '../../models/Utilities/Notification';
import { ISerializedStore, SerializationStore } from '../../stores/SerializationStore';
import { HttpAgent } from '../../utility/HttpAgent';
import { Dialog } from '../Bot/Dialog';

export class DialogConnector {
  static async get(
    botId: string,
    dialogId: string,
    version: number | null
  ): Promise<IGetDialogResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}?version=${version}`
    );
  }

  static async activate(botId: string, dialogId: string): Promise<void> {
    await HttpAgent.requests.put(`/Api/V2/Bots/${botId}/Dialogs/${dialogId}/active`, {});
  }

  static async add(
    botId: string,
    name: string,
    description: string,
    locale: string,
    editorStructure: ISerializedStore
  ): Promise<IAddDialogResponse> {
    const body: IAddDialogBody = {
      botId,
      locale,
      versionName: name,
      versionDescription: description,
      editorStructure: JSON.stringify(editorStructure),
    };
    return await HttpAgent.requests.post(`/Api/V2/Bots/${botId}/Dialogs`, body);
  }

  static async update(
    botId: string,
    dialog: Dialog,
    includeDialogStructure: boolean = true
  ): Promise<IUpdateDialogResponse> {
    const body: IUpdateDialogBody = {
      locale: dialog.locale,
      versionName: dialog.versionName,
      versionDescription: dialog.versionDescription,
      editorStructure: includeDialogStructure
        ? JSON.stringify(SerializationStore.getInstance().serialize())
        : null,
    };

    return await HttpAgent.requests.put(
      `/Api/V2/Bots/${botId}/Dialogs/${dialog.dialogId}`,
      body
    );
  }

  static async updateSettings(
    botId: string,
    dialog: Dialog
  ): Promise<IUpdateDialogResponse> {
    const body: IUpdateDialogSettingsBody = {
      locale: dialog.locale,
      versionName: dialog.versionName,
      versionDescription: dialog.versionDescription,
    };

    return await HttpAgent.requests.post(
      `/Api/V2/Bots/${botId}/Dialogs/${dialog.dialogId}/Settings`,
      body
    );
  }

  static async delete(botId: string, dialogId: string): Promise<any> {
    return await HttpAgent.requests.delete(`/Api/V2/Bots/${botId}/Dialogs/${dialogId}`);
  }

  static async export(botId: string, dialogId: string): Promise<any> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Export`,
      {
        responseType: 'blob',
      }
    );
  }

  static async import(botId: string, dialogId: string, file: File): Promise<any> {
    try {
      const formData = new FormData();
      formData.append('Imported Dialog', file);

      return await HttpAgent.requests.post(
        `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Import`,
        formData
      );
    } catch (error) {
      let message = 'Dialog import failed.';
      if (axios.isAxiosError(error) && error?.response?.data?.errorMessage) {
        message = error.response.data.errorMessage;
      }
      new Notification({ text: message, type: 'error' });

      throw error;
    }
  }
}
