import { observer } from 'mobx-react-lite';
import React from 'react';
import { EmailQuestionNode as EmailQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EmailQuestionNode';
import SingleQuestionNode from '../SingleQuestionNode/SingleQuestionNode';
interface IProps {
  dialogNode: EmailQuestionNodeModel;
}

const EmailQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <SingleQuestionNode dialogNode={dialogNode} />;
};

export default observer(EmailQuestionNode);
