import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { history } from '..';
import { DatasetState } from '../architecture/enums/DatasetState';
import type {
  IDataset,
  IPageDetails,
} from '../architecture/interfaces/HTTP/DatasetParams';
import { IStorageEntry } from '../architecture/interfaces/HTTP/StorageParams';
import { DatasetConnector } from '../models/Connectors/DatasetConnector';
import { StorageConnector } from '../models/Connectors/StorageConnector';
import { Dataset } from '../models/Dataset/Dataset';
import { Property } from '../models/Dataset/Property/Property';
import { StorageEntry } from '../models/Dataset/StorageEntry';
import { RootStore } from './rootStore';

export default class DatasetStore {
  private static instance: DatasetStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    DatasetStore.instance = this;
  }

  @observable
  state: DatasetState = DatasetState.None;

  @observable
  pageDetails: IPageDetails = {
    totalPages: 0,
    page: 1,
  };

  @computed
  getState(state: DatasetState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: DatasetState) {
    this.state |= state;
  }

  @computed
  removeState(state: DatasetState) {
    this.state &= ~state;
  }

  @observable
  private _datasetRepository: Dataset[] = [];

  @observable
  private _datasetDataRepository: StorageEntry[] = [];

  @observable
  private selectedDataset?: Dataset;

  @computed
  get allDatasets() {
    return this._datasetRepository;
  }

  @computed
  get datasetData() {
    return this._datasetDataRepository;
  }

  @action
  setSelectedDataset(id: string | null) {
    this.selectedDataset = this._datasetRepository.find((dataset) => dataset.id === id);
  }

  @computed
  get getSelectedDataset() {
    return this.selectedDataset;
  }

  @action
  search(search: string) {
    return this._datasetRepository.filter((dataset) =>
      dataset.name.toLowerCase().includes(search.toLowerCase())
    );
  }

  @action
  async load(force?: boolean) {
    try {
      if (!this.getState(DatasetState.Initialized) || force) {
        const subscriptionId = this.getSubscriptionId();

        this.setState(DatasetState.DatasetsLoading);

        const response = await DatasetConnector.getAll(subscriptionId);
        runInAction(() => this.restoreDatasets(response));

        this.removeState(DatasetState.DatasetsLoading);
        this.setState(DatasetState.Initialized);
      }
    } catch (error) {
      this.removeState(DatasetState.DatasetsLoading);
    }
  }

  @action
  async loadDatasetData(
    page?: number,
    pageSize?: number,
    descendingOrder?: boolean,
    from?: Date,
    to?: Date
  ) {
    try {
      const subscriptionId = this.getSubscriptionId();
      if (!this.selectedDataset?.id) {
        return;
      }

      this.setState(DatasetState.DataLoading);
      const response = await StorageConnector.get(
        subscriptionId,
        this.selectedDataset.id,
        page,
        pageSize,
        descendingOrder,
        from,
        to
      );

      this.pageDetails = {
        totalPages: response.totalPages,
        page: response.page,
      };

      runInAction(() => this.restoreDatasetData(response.entries));
      this.removeState(DatasetState.DataLoading);
    } catch (error) {
      this.removeState(DatasetState.DataLoading);
    }
  }

  @action
  purge() {
    this._datasetRepository = [];
  }

  @action
  private restoreDatasets(datasets: IDataset[]) {
    this.purge();
    datasets.forEach((dataset) => {
      const instance = plainToInstance(Dataset, dataset);
      this._datasetRepository.push(instance);
    });
  }

  @action
  private restoreDatasetData(datasetData: IStorageEntry[]) {
    this._datasetDataRepository = [];
    datasetData.forEach((dataset) => {
      const instance = plainToInstance(StorageEntry, dataset);
      this._datasetDataRepository.push(instance);
    });
  }

  @action
  async update(dataset: Dataset) {
    try {
      const subscriptionId = this.getSubscriptionId();
      if (!dataset) {
        return;
      }

      this.setState(DatasetState.UpdateLoading);
      if (dataset) await DatasetConnector.update(subscriptionId, dataset);
      await this.load(true);
      this.refreshSelectedDataset();
      this.removeState(DatasetState.UpdateLoading);
    } catch (error) {
      this.removeState(DatasetState.UpdateLoading);
    }
  }

  @action
  refreshSelectedDataset() {
    this.selectedDataset = this._datasetRepository.find(
      (dataset) => dataset.id === this.selectedDataset?.id
    );
  }

  @action
  async create(name: string, properties: Property[]) {
    try {
      this.setState(DatasetState.DatasetCreating);

      const subscriptionId = this.getSubscriptionId();
      await DatasetConnector.create(subscriptionId, name, properties);
      await this.load(true);
      this.refreshSelectedDataset();

      this.removeState(DatasetState.DatasetCreating);
    } catch (error) {
      this.removeState(DatasetState.DatasetCreating);
    }
  }

  @action
  async remove(item: Dataset) {
    try {
      const subscriptionId = this.getSubscriptionId();
      this.setState(DatasetState.DatasetsLoading);
      await DatasetConnector.delete(subscriptionId, item.id);
      this.setSelectedDataset(null);
      this.load(true);
    } catch (error) {
      this.removeState(DatasetState.DatasetsLoading);
    }
  }

  @action
  async deleteData(ids: string[]) {
    try {
      const subscriptionId = this.getSubscriptionId();
      if (!this.selectedDataset?.id) {
        return;
      }

      this.setState(DatasetState.DataLoading);
      await StorageConnector.delete(subscriptionId, this.selectedDataset.id, ids);
      this.loadDatasetData();
      this.removeState(DatasetState.DataLoading);
    } catch (error) {
      this.removeState(DatasetState.DataLoading);
    }
  }

  getById(id: string): Dataset | undefined {
    return this.allDatasets.find((dataset) => dataset.id === id);
  }

  @action
  async export(ids: string[], format: 'json' | 'csv'): Promise<ArrayBuffer | undefined> {
    const subscriptionId = this.getSubscriptionId();
    if (!this.selectedDataset?.id) {
      return;
    }

    return await StorageConnector.export(
      subscriptionId,
      this.selectedDataset.id,
      ids,
      format
    );
  }

  private getSubscriptionId() {
    const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
    if (!subscriptionId) {
      history.push('/settings');
      throw new Error('No subscription selected');
    }
    return subscriptionId;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('DatasetStore instance has not been initialized.');
    }

    return this.instance;
  }
}
