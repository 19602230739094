import { observer } from 'mobx-react-lite';
import React from 'react';
import { EmailActionNode as EmailActionNodeModel } from '../../../../models/DialogNodes/ActionNodes/EmailActionNode';
import TextBoxWithCtx from '../../../common/inputElements/TextBoxWithCtx';
import useDialogAttributeHandler from '../../../customHooks/useAttributeChangeHandler';
import DialogNode from '../DialogNode';

interface IProps {
  dialogNode: EmailActionNodeModel;
}
const EmailActionNode: React.FC<IProps> = ({ dialogNode }) => {
  // Custom Hooks to get icons and update node attributes
  const updateAddress = useDialogAttributeHandler<EmailActionNodeModel>(
    dialogNode,
    'address'
  );
  const updateSubject = useDialogAttributeHandler<EmailActionNodeModel>(
    dialogNode,
    'subject'
  );
  const updateMessage = useDialogAttributeHandler<EmailActionNodeModel>(
    dialogNode,
    'body'
  );

  return (
    <DialogNode dialogNode={dialogNode as EmailActionNodeModel}>
      <TextBoxWithCtx
        value={dialogNode.address}
        saveHandler={updateAddress}
        title='Address'
        placeholder='Enter an address'
        validator={() => dialogNode.isEmailValid}
      />
      <TextBoxWithCtx
        value={dialogNode.subject}
        saveHandler={updateSubject}
        title='Subject'
        placeholder='Enter a subject'
      />
      <TextBoxWithCtx
        value={dialogNode.body}
        saveHandler={updateMessage}
        title='Message'
        placeholder='Enter a message'
        multiline
      />
      <br />
    </DialogNode>
  );
};

export default observer(EmailActionNode);
