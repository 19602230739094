import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Subscription } from '../../models/Subscription/Subscription';
import AuthContext from '../../ms_authentication/context/AuthContext';
import rootStore from '../../stores/rootStore';
import Icon from '../common/Icon';
import Switch from '../common/inputElements/Switch';
import { useGetIcon } from '../customHooks/useGetIcon';

interface IProps {
  subscription: Subscription;
}

const SubscriptionRow: React.FC<IProps> = ({ subscription }) => {
  const { subscriptionStore } = useContext(rootStore);
  const { user, updateUserRoles } = useContext(AuthContext);
  const getIcon = useGetIcon();

  return (
    <tr
      className='details-row'
      data-active={subscription.id === subscriptionStore.selectedSubscription?.id}
    >
      <td>
        {subscription.id === subscriptionStore.selectedSubscription?.id && (
          <Icon icon={getIcon('star')} className='active-icon' />
        )}
        <span>{subscription.name} </span>
      </td>
      <td>{subscription.roles.join(', ')}</td>

      <td className='table-control-column' onClick={() => {}}>
        <Switch
          value={subscription.id === subscriptionStore.selectedSubscription?.id}
          clickHandler={() => {
            if (user?.localAccountId) {
              updateUserRoles(subscription.roles);
              subscriptionStore.storeUsersSubscription(
                subscription.id,
                user.localAccountId
              );
            }
          }}
        />
      </td>
    </tr>
  );
};

export default observer(SubscriptionRow);
