import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { SubQuestionKey } from '../../../../../../../architecture/enums/SubQuestionKey';
import { MultiQuestionNode } from '../../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/MultiQuestionNode';
import { SideBarEditor } from '../../../../../../layout/sideBarEditor/SideBarEditor';
import MultiQuestionSubQuestionInserter from '../inserters/MultiQuestionSubQuestionInserter';
import SubQuestion from '../SubQuestion';

interface IProps {
  dialogNode: MultiQuestionNode;
}
const MultiQuestionSubQuestionsSection: React.FC<IProps> = ({ dialogNode }) => {
  const [activeSubQuestionId, setActiveSubQuestionId] = useState<
    SubQuestionKey | undefined
  >();
  const activeSubQuestion = activeSubQuestionId
    ? dialogNode.getSubQuestionById(activeSubQuestionId)
    : null;

  return (
    <div className='multi-ctx-section-container'>
      <MultiQuestionSubQuestionInserter
        keys={dialogNode.allowedSubQuestionKeys}
        canAddSubQuestions={dialogNode.isPrefixValid}
        isKeyStored={dialogNode.isKeyStored.bind(dialogNode)}
        addSubQuestionHandler={dialogNode.addSubQuestion.bind(dialogNode)}
        removeSubQuestionHandler={dialogNode.removeSubQuestion.bind(dialogNode)}
      />

      {dialogNode.subQuestions.length > 0 && (
        <SideBarEditor.Base>
          <SideBarEditor.SideBar
            items={dialogNode.subQuestions.map((item) => ({
              id: item.id,
              name: item.key,
            }))}
            activeItemId={activeSubQuestionId}
            setActiveItemId={(id) => setActiveSubQuestionId(id as SubQuestionKey)}
          ></SideBarEditor.SideBar>
          <SideBarEditor.Editor>
            {activeSubQuestion && (
              <SubQuestion key={activeSubQuestion.key} subQuestion={activeSubQuestion} />
            )}
          </SideBarEditor.Editor>
        </SideBarEditor.Base>
      )}
    </div>
  );
};

export default observer(MultiQuestionSubQuestionsSection);
