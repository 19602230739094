import React from 'react';
import { useGetIcon } from '../customHooks/useGetIcon';

const Icon: React.FC = () => {
  const getIcon = useGetIcon();
  return (
    <div className='empty-data'>
      <i className={getIcon('search')}></i>
      There are no results
    </div>
  );
};

export default Icon;
