import { action, computed, makeAutoObservable, observable } from 'mobx';
import { DialogBlockTypes } from '../architecture/enums/DialogComponentType';
import { DefaultDialogBlock } from '../models/DialogBlocks/DefaultDialogBlock';
import { DialogBlock } from '../models/DialogBlocks/DialogBlock';
import { KnowledgebaseBlock } from '../models/DialogBlocks/KnowledgebaseBlock';
import { RootNode } from '../models/DialogNodes/RootNode';
import { DefaultTrigger } from '../models/Triggers/DefaultTrigger';
import BotSettingsStore from './BotSettingsStore';
import { RootStore } from './rootStore';

class BlockStore {
  private static instance: BlockStore;

  @observable
  private _blockRepository: DialogBlock[] = [];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    BlockStore.instance = this;
  }

  private _selectedBlock?: DialogBlock;

  @computed
  get allBlocks() {
    return this._blockRepository;
  }

  @computed
  get nonEmptyBlocks() {
    return this.allBlocks.filter((block) => !block.isEmpty());
  }

  @computed
  get selectedBlock() {
    return this._selectedBlock;
  }

  @computed
  get areBlocksValid() {
    return this.allBlocks.every((block) => block.isValid);
  }

  @action
  add(block: DialogBlock) {
    this._blockRepository.push(block);
    const defaultNode = new RootNode(block);
    this.rootStore.nodeStore.append(defaultNode);
  }

  @computed
  getKnowledgebaseBlock() {
    return this._blockRepository.find(
      (block) => block.type === DialogBlockTypes.KnowledgebaseBlock
    );
  }

  @action
  addKnowledgebaseBlock() {
    if (
      this._blockRepository.some(
        (block) => block.type === DialogBlockTypes.KnowledgebaseBlock
      )
    ) {
      return;
    }

    const knowledgebaseBlock = new KnowledgebaseBlock();
    knowledgebaseBlock.createDefaultNodes();

    this._blockRepository.push(knowledgebaseBlock);
  }

  @action
  restore(block: DialogBlock) {
    // The following is necessary for backwarts compatibility
    if (block.type === DialogBlockTypes.DefaultDialogBlock) {
      block = this.restoreDefaultBlock(block);
    }
    this._blockRepository.push(block);
  }

  private restoreDefaultBlock(block: DialogBlock): DefaultDialogBlock {
    const defaultBlock = block as DefaultDialogBlock;
    if (!defaultBlock?.trigger) {
      block.trigger = new DefaultTrigger();
    }

    const trigger = defaultBlock.trigger as DefaultTrigger;

    if (!trigger.generativeAnswerPrompt) {
      const defaultPrompt =
        BotSettingsStore.getInstance().settings.defaultPrompts.generativeAnswerPrompt;
      if (!defaultPrompt) throw new Error('Settings not loaded');
      trigger.generativeAnswerPrompt = defaultPrompt;
    }

    return defaultBlock;
  }

  @action
  remove(dialogBlock: DialogBlock) {
    dialogBlock.remove();
    this.removeBlockFromRepository(dialogBlock);
  }

  @action
  selectBlock(block: DialogBlock) {
    this._selectedBlock = block;
  }

  @action
  clearBlockSelection() {
    this._selectedBlock = undefined;
  }

  @action
  getById(blockId: string) {
    return this._blockRepository.find((block) => block.id === blockId) as DialogBlock;
  }

  @action
  getByCondition(filterCallback: (dialogComponent: DialogBlock) => boolean) {
    return this._blockRepository.find(filterCallback);
  }

  @action
  getDefaultBlock() {
    return this.allBlocks.find(
      (block) => block.type === DialogBlockTypes.DefaultDialogBlock
    );
  }

  @computed
  getMostBottomBlock() {
    if (this.allBlocks.length === 0 || this.allBlocks.every((x) => !x.isAttached())) {
      return undefined;
    }

    let dialogBlock = this.allBlocks[0];
    let yCoordinate = dialogBlock.position.y;

    for (let block of this.allBlocks) {
      if (!block.isAttached()) {
        continue;
      }
      if (block.position.y > dialogBlock.position.y) {
        dialogBlock = block;
        yCoordinate = dialogBlock.position.y;
      }
    }
    return dialogBlock;
  }

  @action
  purge() {
    this.allBlocks.forEach((block) => this.remove(block));
    this.clearBlockSelection();
  }

  @computed
  getMostLeftBlock() {
    if (this.allBlocks.length === 0 || this.allBlocks.every((x) => !x.isAttached())) {
      return undefined;
    }

    let dialogBlock = this.allBlocks[0];
    let xCoordinate = dialogBlock.position.x;

    for (let block of this.allBlocks) {
      if (!block.isAttached()) {
        continue;
      }
      if (block.position.x < dialogBlock.position.x) {
        dialogBlock = block;
        xCoordinate = dialogBlock.position.x;
      }
    }
    return dialogBlock;
  }

  @computed
  getMostRightBlock() {
    if (this.allBlocks.length === 0 || this.allBlocks.every((x) => !x.isAttached())) {
      return undefined;
    }

    let dialogBlock = this.allBlocks[0];
    let xCoordinate = dialogBlock.position.x;

    for (let block of this.allBlocks) {
      if (!block.isAttached()) {
        continue;
      }
      if (block.position.x > dialogBlock.position.x) {
        dialogBlock = block;
        xCoordinate = dialogBlock.position.x;
      }
    }
    return dialogBlock;
  }

  @computed
  getMostTopBlock() {
    if (this.allBlocks.length === 0 || this.allBlocks.every((x) => !x.isAttached())) {
      return undefined;
    }

    let dialogBlock = this.allBlocks[0];
    let yCoordinate = dialogBlock.position.y;

    for (let block of this.allBlocks) {
      if (!block.isAttached()) {
        continue;
      }
      if (block.position.y < dialogBlock.position.y) {
        dialogBlock = block;
        yCoordinate = dialogBlock.position.y;
      }
    }
    return dialogBlock;
  }

  setBlockById(blockId: string) {
    const block = this?.allBlocks?.find((b) => b.id === blockId);
    if (block) this.selectBlock(block);
  }

  private removeBlockFromRepository(dialogBlock: DialogBlock) {
    this._blockRepository = this._blockRepository.filter(
      (block) => block !== dialogBlock
    );
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('BlockStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default BlockStore;
