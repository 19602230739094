import React from 'react';

interface IProps {
  icon: string;
  className?: string;
}
const Icon: React.FC<IProps> = ({ icon, className }) => {
  return <i className={icon + ' ' + 'icon' + ' ' + (className ?? '')}></i>;
};

export default Icon;
