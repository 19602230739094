import { observer } from 'mobx-react-lite';
import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import { Media as MediaModel } from '../../../models/Media/Media';

interface IProps {
  item: MediaModel;
}

const DocumentPreview: React.FC<IProps> = ({ item }: IProps) => {
  const docs = [{ uri: item.url }];

  return (
    <div className='preview-content'>
      <DocViewer
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: false,
          },
        }}
        style={{ width: 500, height: 470 }}
      />
    </div>
  );
};

export default observer(DocumentPreview);
