import { observable, makeObservable } from 'mobx';
import { TriggerHistoryElement } from './TriggerHistoryElement';

export class LuisIntentTriggerElement extends TriggerHistoryElement {
  score?: number;
  threshold?: number
  appId: string;
  intent: string;

  constructor() {
    super();
    this.score = 0;
    this.threshold = 0;
    this.appId = ''
    this.intent = '';

    makeObservable(this, {
      score: observable,
      threshold: observable,
      appId: observable,
      intent: observable,
    });
  }
}
