import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { runInAction } from 'mobx';
import { Notification } from '../models/Utilities/Notification';
import { Utilities } from '../models/Utilities/Utilities';
import { acquireOrRefreshAccessToken } from '../ms_authentication/init';
import UIStore from '../stores/UIStore';

//#region Axios Interceptors
// Axios Request Interceptor to sanitize body in JSON and to set loading
axios.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = await acquireOrRefreshAccessToken();
    if (!token) throw new Error('Unauthorized');

    config.headers['Authorization'] = `Bearer ${token}`;

    if (config.data && typeof config.data === 'string') {
      config.data = Utilities.sanitizeJSON(config.data);
    }

    runInAction(() => {
      UIStore.getInstance().loading.isLoading = true;
    });

    return config;
  },
  (error) => {
    console.error(error);
    delete axios.defaults.headers['Authorization'];

    UIStore.getInstance().loading.isLoading = false;
    new Notification({ text: error.message, type: 'error' });

    Promise.reject('No access token available');
  }
);

// Axios Response Interceptor
axios.interceptors.response.use(
  (value: AxiosResponse) => {
    runInAction(() => {
      UIStore.getInstance().loading.isLoading = false;
    });
    return value;
  },
  async (error: AxiosError) => {
    UIStore.getInstance().loading.isLoading = false;

    const errorText = (() => {
      switch (error.response?.status) {
        case 401:
          return 'Unauthorized Attempt';
      }
    })();

    if (errorText && error.response?.status !== 404) {
      new Notification({ text: errorText, type: 'error' });
    }

    return Promise.reject(error);
  }
);
//#endregion

export abstract class HttpAgent {
  private static responseBody = (response: AxiosResponse) => {
    if (response) return response.data;
  };

  public static cancelRequest = () => {
    const cancelToken = axios.CancelToken.source();
    cancelToken.cancel();
  };

  static requests = {
    get: (url: string, header?: AxiosRequestConfig) =>
      axios.get(url, header).then(HttpAgent.responseBody),
    post: (url: string, body: any, header?: AxiosRequestConfig) =>
      axios.post(url, body, header).then(HttpAgent.responseBody),
    put: (url: string, body: any, header?: AxiosRequestConfig) =>
      axios.put(url, body, header).then(HttpAgent.responseBody),
    delete: (url: string, header?: AxiosRequestConfig) =>
      axios.delete(url, header).then(HttpAgent.responseBody),
  };
}
