import { Exclude, Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

export abstract class BaseDialogComponent {
  @Expose()
  title: string = '';

  @Expose()
  id: string;

  @Exclude({ toClassOnly: true })
  parents: BaseDialogComponent[];

  @Exclude({ toClassOnly: true })
  children: BaseDialogComponent[];

  protected constructor() {
    this.id = v4();
    this.parents = [];
    this.children = [];

    makeObservable(this, {
      id: observable,
      title: observable,
      parents: observable,
      children: observable,
      addParent: action,
      addChild: action,
      remove: action,
      removeChild: action,
      removeParent: action,
      replaceChild: action,
      containsChild: action,
      containsParent: action,
      getBlockParent: action,
      getChild: action,
      getChildren: action,
      getParents: action,
      isLeaf: computed,
      isRoot: computed,
    });
  }

  addParent(parentNode: BaseDialogComponent) {
    if (this.parents.find((x) => x === parentNode)) return;

    this.parents.push(parentNode);
    parentNode.addChild(this);
  }

  addChild(childNode: BaseDialogComponent) {
    if (this.children.find((x) => x === childNode)) return;

    this.children.push(childNode);
    childNode.addParent(this);
  }

  containsChild(childNode: BaseDialogComponent) {
    if (this.children.find((x) => x === childNode)) {
      return true;
    } else {
      return false;
    }
  }

  containsParent(parentNode: BaseDialogComponent) {
    if (this.parents.find((x) => x === parentNode)) {
      return true;
    } else {
      return false;
    }
  }

  getChild(childId: string) {
    return this.children.find((x) => x.id === childId);
  }

  getChildren() {
    return this.children;
  }

  getBlockParent(parentId: string) {
    return this.parents.find((x) => x.id === parentId);
  }

  getParents() {
    return this.parents;
  }

  get isLeaf() {
    return this.children.length === 0;
  }

  get isRoot() {
    return this.parents.length === 0;
  }

  remove() {
    if (this.isRoot && this.isLeaf) {
      return;
    } else if (!this.isRoot && this.isLeaf) {
      // Attention: this "strange" way of iteration was chosen on pupose due to deleting items in array while iterating
      for (let i = this.getParents().length - 1; i >= 0; i--) {
        this.getParents()[i].removeChild(this);
      }
    } else if (this.isRoot && !this.isLeaf) {
      // Attention: this "strange" way of iteration was chosen on pupose due to deleting items in array while iterating
      for (let i = this.getChildren().length - 1; i >= 0; i--) {
        this.getChildren()[i].removeParent(this);
      }
    } else {
      // Attention: this "strange" way of iteration was chosen on pupose due to deleting items in array while iterating
      for (let i = this.getParents().length - 1; i >= 0; i--) {
        this.getParents()[i].removeChild(this);
      }
      for (let i = this.getChildren().length - 1; i >= 0; i--) {
        this.getChildren()[i].removeParent(this);
      }
    }
  }

  removeChild(childComponent: BaseDialogComponent) {
    const parentIndex = childComponent.parents.indexOf(this);
    if (parentIndex !== -1) {
      childComponent.parents.splice(parentIndex, 1);
    }

    const childIndex = this.children.indexOf(childComponent);
    if (childIndex !== -1) {
      this.children.splice(childIndex, 1);
    }
  }

  removeParent(parentComponent: BaseDialogComponent) {
    const childIndex = parentComponent.children.indexOf(this);
    if (childIndex !== -1) {
      parentComponent.children.splice(childIndex, 1);
    }

    const parentIndex = this.parents.indexOf(parentComponent);
    if (parentIndex !== -1) {
      this.parents.splice(parentIndex, 1);
    }
  }

  replaceChild(
    oldchildComponent: BaseDialogComponent,
    newChildComponent: BaseDialogComponent
  ) {
    this.removeChild(oldchildComponent);
    this.addChild(newChildComponent);
  }

  /**
   * This function should only be used for deserialization. It bypasses
   * the normal addChild behaviour, which would also add connection to the
   * parent nodes.
   *
   * NOTE: If we find a smarter way to deserialize the entire structure, this
   * function will be obsolete.
   *
   * @param childComponent - The child component to add.
   */
  restoreChild(childComponent: BaseDialogComponent) {
    if (!this.children.includes(childComponent)) {
      this.children.push(childComponent);
    }
  }

  /**
   * This function should only be used for deserialization. It bypasses
   * the normal addChild behaviour, which would also add connection to the
   * parent nodes.
   *
   * NOTE: If we find a smarter way to deserialize the entire structure, this
   * function will be obsolete.
   *
   * @param parentComponent - The parent component to add.
   */
  restoreParent(parentComponent: BaseDialogComponent) {
    if (!this.parents.includes(parentComponent)) {
      this.parents.push(parentComponent);
    }
  }

  serialize(): ISerializedDialogComponent {
    return {
      id: this.id,
      title: this.title,
      parents: this.parents.map((parent) => parent.id),
      children: this.children.map((child) => child.id),
    };
  }
}

export interface ISerializedDialogComponent {
  id: string;
  title?: string;
  parents: string[];
  children: string[];
}
