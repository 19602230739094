import { BaseDialogComponent } from '../../models/BaseDialogComponent';

export default function useDialogAttributeHandler<T extends BaseDialogComponent>(
  element: T,
  attribute: keyof T
) {
  const changeAttribute = (value: T[keyof T]) => {
    element[attribute] = value;
  };

  return changeAttribute;
}
