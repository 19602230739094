import { action, computed, makeAutoObservable, observable } from 'mobx';
import { ContextVariableInstanceTypes } from '../architecture/enums/ContextVariableInstanceTypes';
import { DialogNodeTypes } from '../architecture/enums/DialogComponentType';
import { IContextVariable } from '../architecture/interfaces/contextVariables/IContextVariable';
import {
  ContextVariable,
  DatasetContextVariable,
  ListContextVariable,
} from '../models/ContextVariables/ContextVariable';
import { SystemContextVariable } from '../models/ContextVariables/SystemContextVariable';
import { Dataset } from '../models/Dataset/Dataset';
import { BaseMultiQuestionNode } from '../models/DialogNodes/QuestionNodes/BaseMultiQuestionNode';
import { Utilities } from '../models/Utilities/Utilities';
import { RootStore } from './rootStore';

const systemVariableCollection = [
  new SystemContextVariable('Session', '1f25d0b1-463b-4809-b421-4be4d3e01b92'),
  new SystemContextVariable('LastUserInput', '5523e40a-6ce9-427e-85e3-b1d41ad2959e'),
  new SystemContextVariable('Date', '985cfc09-ed76-42fa-9e39-75fcb182e583'),
  new SystemContextVariable('Time', '29de609c-e914-4924-9bf7-57db6764e450'),
  new SystemContextVariable('User', '52251579-c589-4806-bfa0-36234676bc4f'),
  new SystemContextVariable(
    'ConversationReference',
    'cfc0b5d8-2136-4a0b-93c7-4c5d94f64488'
  ),
];

class ContextVariableStore {
  public static instance: ContextVariableStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this._systemVariables = systemVariableCollection;
    makeAutoObservable(this);
    ContextVariableStore.instance = this;
  }

  // The triggering character that opens the context variable dropdown
  @observable
  trigger = '#';

  @observable
  openingTag = '{{';

  @observable
  closingTag = '}}';

  @observable
  private _userVariables: ContextVariable[] = [];

  @observable
  private _datasetVariables: DatasetContextVariable[] = [];

  @observable
  private _systemVariables: SystemContextVariable[];

  // Pattern of a GUID that helps to identify the context variables in the node attribute (e.g. in Dialognode Message)
  @observable
  guidPattern = new RegExp(
    `(${this.openingTag}[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}${this.closingTag})`,
    'g'
  );

  @observable
  namePattern = new RegExp(`${this.trigger}[A-z\u00C0-\u017F0-9.*]+`, 'gu');

  @computed
  get allVariables() {
    return [...this._userVariables, ...this._datasetVariables, ...this._systemVariables];
  }

  @computed
  get userAndSystemVariables() {
    return [...this._userVariables, ...this._systemVariables];
  }

  @computed
  get userAndDatasetVariables() {
    return [...this._userVariables, ...this._datasetVariables];
  }

  @computed
  get listAndSystemVariables() {
    return [...this.listContextVariables, ...this._systemVariables];
  }

  @computed
  get systemVariables() {
    return this._systemVariables;
  }

  @computed
  get userVariables() {
    return this._userVariables;
  }

  @computed
  get datasetVariables() {
    return this._datasetVariables;
  }

  @computed
  get singleValueUserContextVariables() {
    return this.userVariables.filter(
      (item) => item.instanceType === ContextVariableInstanceTypes.ContextVariable
    );
  }

  @computed
  get listContextVariables() {
    return this.userVariables.filter(
      (item) => item.instanceType === ContextVariableInstanceTypes.ListContextVariable
    );
  }

  @action
  restore(ctx: ContextVariable) {
    this._userVariables.push(ctx);
  }

  @action
  restoreDatasetCtxVariable(ctx: DatasetContextVariable) {
    this._datasetVariables.push(ctx);
  }
  /**
   * Creates a new ContextVariable instance with the provided name and adds it to the repository
   * @param name Name of the CTX
   * @returns The new instance of the Context Variable
   */
  @action
  addIfNotExists(
    name: string,
    type: ContextVariableInstanceTypes = ContextVariableInstanceTypes.ContextVariable,
    dataset?: Dataset
  ): ContextVariable | null {
    if (Utilities.isEmpty(name)) return null;

    let ctx = this.getByName(name) as ContextVariable | undefined;
    if (ctx) return ctx;

    switch (type) {
      case ContextVariableInstanceTypes.ListContextVariable: {
        ctx = new ListContextVariable(name);
        this._userVariables.push(ctx);
        break;
      }
      case ContextVariableInstanceTypes.DatasetContextVariable: {
        if (!dataset) {
          throw Error('Dataset must be defined');
        }
        ctx = new DatasetContextVariable(name, dataset);
        this._datasetVariables.push(ctx);
        break;
      }
      default: {
        ctx = new ContextVariable(name);
        this._userVariables.push(ctx);
        break;
      }
    }

    return ctx;
  }

  @action
  remove(ctx: DatasetContextVariable | ContextVariable) {
    if (ctx instanceof DatasetContextVariable) {
      this._datasetVariables = this._datasetVariables.filter((item) => item !== ctx);
    } else if (ctx instanceof ContextVariable) {
      this._userVariables = this._userVariables.filter((item) => item !== ctx);
    }
  }

  @action
  purge() {
    this._userVariables = [];
    this._datasetVariables = [];
  }

  /**
   * Returns a context variable that matches one in the repo by ID
   * @param id ID of the context variable
   */
  getById(id: string): IContextVariable | undefined {
    if (Utilities.isEmpty(id)) return;

    return (
      this.allVariables.find((ctx) => this.peelTags(id).includes(ctx.id)) ||
      this.datasetVariables.find((ctx) => this.peelTags(id).includes(ctx.id))
    );
  }

  /**
   * Returns a context variable that matches one in the repo by name
   * @param name Name of the context variable
   */
  getByName(name: string): IContextVariable | undefined {
    if (Utilities.isEmpty(name)) return;

    return this.allVariables.find(
      (ctx) => ctx.name.toLowerCase() === this.peelTags(name.toLowerCase())
    );
  }

  /**
   * Returns an array of all the existing prefixes of a certain multi-question dialog node type
   * @param type Type of the Dialog Node
   * @returns A string array
   */
  multiQuestionPrefixes(type: DialogNodeTypes): string[] {
    const prefixes = this.rootStore.nodeStore.allNodes
      .filter(
        (node) =>
          node instanceof BaseMultiQuestionNode &&
          (node as BaseMultiQuestionNode).type === type
      )
      .map((node) => (node as BaseMultiQuestionNode).prefix)
      .filter((prefix) => !Utilities.isEmpty(prefix));

    // Creating a Set to filter out duplicates. For example you have Address already but you type in Address2
    // At one point you will have Address twiche in the array which leads to key duplications and therefore conflicts in the Datalist
    return [...new Set(prefixes)];
  }

  /**
   * Filters out the context variable IDs from a text.
   * @param text A text that might contain ids of context variables
   * @returns An array of ContextVariables
   */
  extractCtxVarsFromText(text: string): IContextVariable[] {
    if (!text || typeof text !== 'string') return [];

    const vars = text.match(this.guidPattern);
    if (!vars) return [];

    const result: IContextVariable[] = [];
    vars.forEach((ctxValue) => {
      const ctx = this.getById(ctxValue);
      if (ctx) {
        result.push(ctx);
      }
    });

    return result;
  }

  /**
   * Replaces the names of Context Variables with their IDs in a text
   * @param text A string
   * @returns A string where the context variable names are replaced by their ids
   */
  swapNamesToIds(text: string): string {
    // Filters out the words in the text that match the namePattern
    // This array is dirty! Potential names can be exactly the name of a CTX but it can also be something like "ageyears" from the text "you are #ageyears old" even if you were looking for a CTX with the name "age"
    const potentialCtxNames = text.match(this.namePattern);
    if (potentialCtxNames?.length === 0) return text;

    // An array of objects will be created that can look like
    // [
    //   {
    //     name: "color",
    //     ctx: CtxVariable
    //   },
    //   {
    //     name: "ageyear",
    //     ctx: undefined
    //   }
    // ]
    // This indicates that the name of the second object contains a context variable but in the textbox there were no whitespaces between the words "age" and "year"
    // Therefore the actual CTX cannot be found in the repository by directly its name.
    let vars = potentialCtxNames?.map((name) => ({ name, ctx: this.getByName(name) }));
    if (!vars) return text;

    // A new string will be created to work with it later on
    let newText = text;

    // If there is an undefined context variable in the vars array a more unprecise search will begin and
    // the ctx will be assigned to the missing ctx whose name contains a part of that potential name
    // ageyear --> the CTX with the name "age" will be found and assigned to the CTX in the vars array.
    // If finding a matching CTX was not successful the object with an undefined ctx will be removed from the "vars" array.
    vars = vars
      .map((item) => {
        if (!item.ctx) {
          item.ctx = this.allVariables.find((x) => item.name.includes(x.name));
        }
        return item;
      })
      .filter((item) => item.ctx !== undefined);

    // To not lose the "appended" string after the Ctx's name we have to gather the fragments.
    // "ageyear" would result in "age" while "year" would be lost forever. Not cool!
    vars.forEach((item) => {
      // Splitting the current item.name "ageyear" where the delimiter is the found ctx's name results in an array of strings where
      // The first element is the trigger character and
      // The second one is the appended string which we are trying to preserve
      // BEWARE: If the "appended" string is the same as the name of the Ctx, it will be removed during the splitting. E.g. #SessionSession
      const nameFrags = item.name
        .split(item.ctx!.name)
        .filter((frag) => frag !== this.trigger);

      // The Ctx name will be swapped out with the Ctx id
      // And the appended string will be re-attached
      newText = newText.replace(
        item.name,
        this.openingTag + item.ctx!.id + this.closingTag + nameFrags.join('')
      );
    });

    return newText;
  }

  /**
   * Removes the trigger character and the opening and closing tags of a context variable
   * @param text A string
   * @returns A string
   */
  peelTags(text: string) {
    return text
      .replace(new RegExp(this.trigger, 'g'), '')
      .replace(this.openingTag, '')
      .replace(this.closingTag, '');
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('ContextVariableStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default ContextVariableStore;
