import { observer } from 'mobx-react-lite';
import React from 'react';
import { Entity as EntityModel } from '../../../../../../../models/DialogNodes/EntityNode/Entity';
import { EntityDefinition } from '../../../../../../../models/DialogNodes/EntityNode/EntityDefinition';
import Button from '../../../../../../common/Button';
import { useGetIcon } from '../../../../../../customHooks/useGetIcon';

interface IProps {
  entity: EntityModel;
  entityDefinition: EntityDefinition;
  index: number;
}

const Actions: React.FC<IProps> = ({ entity, entityDefinition, index }) => {
  const getIcon = useGetIcon();

  const addNewEntity = () => {
    entityDefinition.addEntity();
  };

  const removeEntity = (entity: EntityModel) => {
    entityDefinition.removeEntity(entity);
  };

  const isValuePartOfAnswer = (entity: EntityModel) => {
    return entityDefinition.text?.toLowerCase().includes(entity.value?.toLowerCase());
  };

  return (
    <div className='flex-wrapper'>
      {
        <div className='flex-wrapper'>
          {isValuePartOfAnswer(entity) ? (
            <i
              className={`${getIcon('check_solid')} success mr-1`}
              style={{ visibility: entity.value === '' ? 'hidden' : 'visible' }}
            ></i>
          ) : (
            <i className={`${getIcon('close')} error mr-1`}></i>
          )}
        </div>
      }
      <Button
        className={'btn-round btn-borderless'}
        icon={getIcon('remove')}
        disabled={entityDefinition.entities.length === 1}
        clickHandler={() => removeEntity(entity)}
      />
      {entityDefinition.entities.length === index + 1 && (
        <Button
          className={'btn-round btn-borderless'}
          icon={getIcon('add_alt')}
          clickHandler={addNewEntity}
        />
      )}
    </div>
  );
};

export default observer(Actions);
