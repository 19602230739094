import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import type {
  ISerializedIntent,
  ISerializedLabel,
} from '../architecture/interfaces/HTTP/IntentParams';
import IntentConnector from '../models/Connectors/IntentConnector';
import { Intent } from '../models/Intents/Intent';
import { Label } from '../models/Intents/Label';
import { Notification } from '../models/Utilities/Notification';
import { RootStore } from './rootStore';

class IntentStore {
  private static instance: IntentStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    IntentStore.instance = this;
  }

  @observable
  private _intentRepository: Intent[] = [];

  @computed
  get allIntents() {
    return this._intentRepository;
  }

  @computed
  get activeIntents() {
    return this._intentRepository.filter((intent) => intent.state !== 'Deleted');
  }

  @computed
  get shouldBeUpdated() {
    const intentsShouldBeUpdated = this._intentRepository.some(
      (intent) => intent.state !== 'UpToDate'
    );
    const labelsShouldBeUpdated = this._intentRepository
      .map((intent) => intent.labels)
      .flat()
      .some((label) => label.state !== 'UpToDate');
    return intentsShouldBeUpdated || labelsShouldBeUpdated;
  }

  @action
  addOrUpdate(intent: Intent) {
    if (this._intentRepository.find((item) => item.id === intent.id)) {
      this._intentRepository = this._intentRepository.map((item) =>
        item.id === intent.id ? intent : item
      );
    } else {
      this._intentRepository.push(intent);
    }
  }

  @action
  async load(dialogId: string) {
    try {
      const response = await IntentConnector.get(this.currentlyEditedBotId, dialogId);
      runInAction(() => this.restore(response.intents));
    } catch (error) {}
  }

  @action
  async update() {
    try {
      const dialog = this.rootStore.dialogStore.currentlyEditedDialog!;
      const response = await IntentConnector.update(
        this.currentlyEditedBotId,
        dialog.dialogId
      );
      runInAction(() => this.restore(response.intents));
    } catch (error) {
      new Notification({ text: 'Saving Intents failed', type: 'error' });
    }
  }

  @action
  getById(id: string) {
    return this.activeIntents.find((intent) => intent.id === id);
  }

  @action
  getByName(name: string) {
    return this.activeIntents.find((intent) => intent.name === name);
  }

  @action
  purge() {
    this._intentRepository = [];
  }

  @action
  remove(intent: Intent) {
    this._intentRepository = this._intentRepository.filter(
      (item) => item.id !== intent.id
    );
  }

  @action
  private restore(intents: ISerializedIntent[]) {
    this.purge();
    intents.forEach((serializedIntent: ISerializedIntent) => {
      this.setupIntent(serializedIntent);
    });
  }

  @action
  private setupIntent(serializedIntent: ISerializedIntent) {
    const labels = serializedIntent.labels.map((label: ISerializedLabel) => {
      const restoredLabel = new Label(label.text, label.id);
      restoredLabel.state = 'UpToDate';
      return restoredLabel;
    });
    const intent = new Intent(serializedIntent.name, labels, serializedIntent.id);
    intent.state = 'UpToDate';
    this.addOrUpdate(intent);
  }

  private get currentlyEditedBotId() {
    return this.rootStore.dialogStore.currentlyEditedBot?.botId;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('IntentStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default IntentStore;
