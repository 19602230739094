import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { CreditManagmentState } from '../../../architecture/enums/Admin';
import CreditManagementTable from '../../common/CreditManagementTable';
import useAdminStores from '../../customHooks/useAdminStores';
import LoadingPage from '../../layout/LoadingPage';

// Admin contingent view
const ContingentOverview: React.FC = () => {
  const { subscriptionAdminStore, botAdminStore, creditManagementAdminStore } =
    useAdminStores();

  useEffect(() => {
    creditManagementAdminStore.loadOverview();
  }, [subscriptionAdminStore.getSelectedSubscription, botAdminStore.getSelectedBot]);

  return (
    <>
      {creditManagementAdminStore.getState(CreditManagmentState.Loading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <CreditManagementTable
            payments={creditManagementAdminStore.getPayments}
            totalCredits={creditManagementAdminStore.getTotalCredits}
            consumedCredits={creditManagementAdminStore.getConsumedCredits}
            showOutdateButton={true}
          />
        </>
      )}
    </>
  );
};

export default observer(ContingentOverview);
