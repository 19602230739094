import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import Button from '../../common/Button';
import Input from '../../common/Input';

interface IProps {
  resetModal: () => void;
  submit: (textureName: string, files: FileList) => void;
}

const AddAvatarTextureModal: React.FC<IProps> = ({ resetModal, submit }) => {
  const fileRef = useRef<HTMLInputElement | null>(null);

  const validationSchema = Yup.object({
    textureName: Yup.string().required('This field is required'),
    files: Yup.array().test(
      'TextureFilesRequired',
      'A texture pack is made up of exactly 5 texture files.',
      function () {
        return fileRef.current?.files?.length === 5;
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      textureName: '',
      files: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (fileRef.current?.files) {
        submit(values.textureName, fileRef.current.files);
      }
    },
  });

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Add Texture</h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='texture-name'>Texture Name:</label>
            <Input
              id='texture-name'
              placeholder='Name of the texture'
              value={formik.values.textureName}
              changeHandler={(value) => formik.setFieldValue('textureName', value)}
            />
            {formik.touched.textureName && formik.errors.textureName ? (
              <div className='validation-error'>{formik.errors.textureName}</div>
            ) : null}
            <br />
            <div className='input-group texture-upload'>
              <label htmlFor='file-upload'>Files:</label>
              <input id='file-upload' ref={fileRef} type='file' name='files' multiple />
              <Button
                content='Select files'
                className='btn-inactive btn-round'
                clickHandler={() => fileRef.current?.click()}
                type='button'
              />
              {formik.touched.files && formik.errors.files ? (
                <div className='validation-error'>{formik.errors.files}</div>
              ) : null}
              {fileRef.current?.files?.length === 5 && (
                <div className='validation-error'>Successfully selected 5 files</div>
              )}
            </div>
            <div className='btn-container'>
              <Button
                content='Cancel'
                className='btn-inactive btn-round'
                clickHandler={resetModal}
              />
              <Button
                content='Add Texture'
                className='btn-primary btn-round'
                type='submit'
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(AddAvatarTextureModal);
