import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { SubscriptionState } from '../../../architecture/enums/SubscriptionState';
import lailo_pic from '../../../assets/lailo_station.jpg';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Loading from '../../common/Loading';
import { useGetIcon } from '../../customHooks/useGetIcon';

const WelcomeNewUserPage = () => {
  const { newUserOnboarded, user, newUser } = useContext(AuthContext);
  const { subscriptionStore } = useContext(rootStore);

  const [showError, setShowError] = useState(false);
  const getIcon = useGetIcon();

  const supportEmail = 'mailto:info@lailo.ai';

  const createTrialAccount = async () => {
    if (user && newUser) {
      const successful = await subscriptionStore.createTrialAccount(user);
      if (!successful) {
        setShowError(true);
      }
    }
  };

  useEffect(() => {
    createTrialAccount();
  }, [user]);

  const renderLoading = () => {
    if (showError) {
      return (
        <div className='loading-block'>
          <p className='error-message'>
            We are very sorry, but it was not possible to create a trial account. <br />
            Please contact <a href={supportEmail}>support</a>.
          </p>
        </div>
      );
    }

    if (subscriptionStore.getState(SubscriptionState.CreatingTrialAccount)) {
      return (
        <div className='loading-block'>
          <p className='loading-text'>
            We are currently setting up your trial account. This won't take long.
          </p>
          <div className='loading-container'>
            <Loading fullScreen={false} />
          </div>
        </div>
      );
    }

    return (
      <div className='loading-block'>
        <Button
          content='Start Lailo'
          className='btn-primary btn-round'
          clickHandler={() => {
            newUserOnboarded();
          }}
          type='button'
          icon={getIcon('sign_in')}
        />
      </div>
    );
  };

  return (
    <div
      className='page'
      style={{ background: `url(${lailo_pic}) no-repeat center center/cover` }}
    >
      <div className='container'>
        <h1 className='main-title'>Welcome to Lailo!</h1>
        <div className='meta-description'>
          <>
            <p>
              We're delighted to have you with us. Lailo is an innovative platform
              designed for building digital assistants that easily integrate into various
              channels. It's the perfect tool for crafting individual dialogs and
              harnessing AI-supported recognition to enhance user interactions.
            </p>
            <p>
              Our platform simplifies the process of understanding and responding to your
              users' needs in real-time, offering a tailored and engaging experience.
            </p>
            <p>
              If you have any questions or need assistance, our{' '}
              <a href={supportEmail}>support</a> team is always ready to help. Welcome
              aboard, and we can't wait to see what you'll achieve with Lailo!
            </p>
            {renderLoading()}
          </>
        </div>
      </div>
    </div>
  );
};

export default observer(WelcomeNewUserPage);
