import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dataset } from '../../../../models/Dataset/Dataset';

interface IProps {
  dataset: Dataset;
}

const Status: React.FC<IProps> = ({ dataset }) => {
  return (
    <div className='status-wrapper'>
      <div className='status-icon' data-status={dataset.status}></div>
      {dataset.status}
    </div>
  );
};

export default observer(Status);
