import { useContext } from 'react';
import rootStore from '../../stores/rootStore';

const useAdminStores = () => {
  const { adminStore } = useContext(rootStore);

  const botAdminStore = adminStore.botAdminStore;
  const subscriptionAdminStore = adminStore.subscriptionAdminStore;
  const userAdminStore = adminStore.userAdminStore;
  const billingAdminStore = adminStore.billingAdminStore;
  const creditManagementAdminStore = adminStore.creditManagementAdminStore;
  const avatarTexturesAdminStore = adminStore.avatarTexturesAdminStore;

  return {
    botAdminStore,
    subscriptionAdminStore,
    userAdminStore,
    billingAdminStore,
    creditManagementAdminStore,
    avatarTexturesAdminStore,
  };
};

export default useAdminStores;
