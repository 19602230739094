import { plainToInstance } from 'class-transformer';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Payment } from '../../../models/Admin/Payment';
import { ContingentConnector } from '../../../models/Connectors/ContingentConnector';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import CreditManagementTable from '../../common/CreditManagementTable';
import LoadingPage from '../LoadingPage';

interface IParamsProps {
  dialogId?: string;
}

// User contingent view
const CreditsOverview = () => {
  const params: IParamsProps = useParams();
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const { dialogStore } = useContext(rootStore);

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    if (!dialogStore.currentlyEditedDialog && params.dialogId) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params]);

  const load = async () => {
    const botId = dialogStore.currentlyEditedBot?.botId;
    if (!botId) return;

    setLoading(true);
    const overview = await ContingentConnector.getOverview(botId);
    setPayments(overview.payments.map((payment) => plainToInstance(Payment, payment)));
    setConsumedCredits(overview.consumedCredits);
    setLoading(false);
  };

  const [payments, setPayments] = useState<Payment[]>([]);
  const [consumedCredits, setConsumedCredits] = useState(0);
  const [loading, setLoading] = useState(false);

  const totalCredits =
    payments.length === 0
      ? 0
      : payments
          .map((payment) => payment.credits)
          .reduce((prev, current) => prev + current);

  return (
    <div className='credit-management-table-container'>
      {loading ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <div className='credit-management-table-wrapper'>
          <CreditManagementTable
            payments={payments}
            totalCredits={totalCredits}
            consumedCredits={consumedCredits}
            showOutdateButton={false}
          />
        </div>
      )}
    </div>
  );
};

export default observer(CreditsOverview);
