import { Exclude } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { EventTargetReceiverTypes } from '../../../../architecture/enums/EventTrigger';
import { IReceiver } from '../../../../architecture/interfaces/trigger/IEventTrigger';
export abstract class BaseEventReceiver implements IReceiver {
  @Exclude()
  abstract type: EventTargetReceiverTypes;

  constructor() {
    makeObservable(this, {
      type: observable,
    });
  }

  get isValid() {
    return true;
  }

  get isChanged() {
    return false;
  }

  serialize(): ISerializedEventReceiver {
    return {
      type: this.type,
    };
  }
}

export interface ISerializedEventReceiver {
  type: EventTargetReceiverTypes;
}
