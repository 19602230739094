export enum SingleValueConditionOperator {
  IsSet = 'IsSet',
  IsNotSet = 'IsNotSet',
  IsEqual = 'IsEqual',
  IsNotEqual = 'IsNotEqual',
  IsGreaterThan = 'IsGreaterThan',
  IsLessThan = 'IsLessThan',
  IsPositive = 'IsPositive',
  IsNegative = 'IsNegative',
  Contains = 'Contains',
  Matches = 'Matches',
  IsGreaterThanOrEqualTo = 'IsGreaterThanOrEqualTo',
  IsLessThanOrEqualTo = 'IsLessThanOrEqualTo',
}

export enum ListConditionOperator {
  IsSet = 'IsSet',
  IsNotSet = 'IsNotSet',
  Includes = 'Includes',
  IncludesNot = 'IncludesNot',
  LengthEquals = 'LengthEquals',
}

const ConditionOperator = {
  ...ListConditionOperator,
  ...SingleValueConditionOperator,
};

export type ConditionOperator = typeof ConditionOperator;

export const CONDITIONS_REQUIRING_VALUES: (keyof ConditionOperator)[] = [
  ConditionOperator.IsEqual,
  ConditionOperator.IsNotEqual,
  ConditionOperator.IsGreaterThan,
  ConditionOperator.IsLessThan,
  ConditionOperator.IsGreaterThanOrEqualTo,
  ConditionOperator.IsLessThanOrEqualTo,
  ConditionOperator.Contains,
  ConditionOperator.Matches,
  ConditionOperator.Includes,
  ConditionOperator.IncludesNot,
  ConditionOperator.LengthEquals,
];

export const CONDITIONS_WITHOUT_VALUES: (keyof ConditionOperator)[] = Object.values(
  ConditionOperator
).filter(
  (operator) =>
    CONDITIONS_REQUIRING_VALUES.includes(ConditionOperator[operator]) === false
);

export enum ConditionTypes {
  ContextCondition = 'ContextCondition',
  ActionCardCondition = 'ActionCardCondition',
  Undefined = 'Undefined',
}
