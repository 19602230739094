import { makeObservable, observable } from 'mobx';
import { TriggerHistoryElement } from './TriggerHistoryElement';

export class KnowledgebaseTriggerElement extends TriggerHistoryElement {
  answer: string;

  constructor() {
    super();
    this.answer = '';

    makeObservable(this, {
      answer: observable,
    });
  }
}
