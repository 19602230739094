import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { DatasetListAction, PropertyType } from '../../architecture/enums/Dataset';
import type { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import { IPropertyValue } from '../../architecture/interfaces/dataset/IPropertyValue';
import {
  ContextVariable,
  DatasetContextVariable,
} from '../ContextVariables/ContextVariable';
import {
  TranformIntoValue,
  TransformIntoContextVariableFromValue,
  TransformIntoCreatedVariables,
  TransformIntoDatasetListAction,
} from '../Utilities/Deserialization/Decorators';

export class PropertyValue {
  @Expose()
  id: string;

  @Expose()
  annotation: string = '';

  @Expose()
  @TransformIntoDatasetListAction
  datasetListAction?: DatasetListAction = DatasetListAction.Set;

  @Expose()
  datasetType?: string;

  @Expose()
  type: PropertyType = PropertyType.Simple;

  @Expose()
  @TranformIntoValue
  value?: string;

  @Expose()
  @TransformIntoContextVariableFromValue
  contextVariable?: IContextVariable;

  @Expose()
  @TransformIntoCreatedVariables
  createdVariables?: ContextVariable[];

  constructor(
    id: string,
    type: PropertyType,
    annotation: string,
    value?: string,
    datasetType?: string
  ) {
    this.id = id;
    this.type = type;
    this.annotation = annotation;
    this.value = value;
    this.datasetType = datasetType;

    makeObservable(this, {
      id: observable,
      annotation: observable,
      value: observable,
      type: observable,
      datasetType: observable,
      contextVariable: observable,
      createdVariables: observable,
      datasetListAction: observable,
    });
  }

  addCtxVariableToCtxVariablesList(ctxVariable: DatasetContextVariable) {
    if (!this.createdVariables) this.createdVariables = [];
    this.createdVariables?.push(ctxVariable);
  }

  replaceCtxVariableInCtxVariablesList(
    ctxVariable: DatasetContextVariable,
    index: number
  ) {
    this.createdVariables?.splice(index, 1, ctxVariable);
  }

  removeCtxVariableInCtxVariablesList(ctxVariable: DatasetContextVariable) {
    this.createdVariables = this.createdVariables?.filter(
      (variable) => variable.id !== ctxVariable.id
    );
  }

  serialize(): IPropertyValue {
    return {
      id: this.id,
      annotation: this.annotation,
      type: this.type,
      datasetType: this.datasetType,
      datasetListAction:
        this.type === PropertyType.ListDataset ? this.datasetListAction : undefined,
      value:
        this.type === PropertyType.Simple
          ? this.value
          : this.type === PropertyType.Dataset
          ? this.contextVariable?.id
          : undefined,
      values:
        this.type === PropertyType.ListDataset
          ? this.createdVariables?.map((ctx) => ctx.id)
          : undefined,
    };
  }
}
