import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DialogState } from '../../../architecture/enums/DialogState';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import Confirm from '../../common/modal/templates/Confirm';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  isLoading: boolean;
  disabled: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const ImportDialogButton: React.FC<IProps> = ({
  isLoading,
  disabled,
  setIsLoading,
}: IProps) => {
  const { dialogStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const importDialog = async (file: File) => {
    await dialogStore.importDialog(file);
  };

  const importFile = () => {
    if (!inputRef.current) {
      return;
    }

    const selectedFile = inputRef.current.files![0];
    if (selectedFile && selectedFile.name.endsWith('.lailo')) {
      importDialog(selectedFile);
    }
    inputRef.current!.value = '';
  };

  useEffect(() => {
    setIsLoading(dialogStore.getState(DialogState.Importing));
  }, [dialogStore.getState(DialogState.Importing)]);

  const triggerUploadInput = () => {
    inputRef.current!.click();
  };

  return (
    <div>
      <input
        ref={inputRef}
        type='file'
        accept='.lailo'
        name='fileInput'
        id='fileInput'
        onChange={() => setConfirmModalOpened(true)}
        style={{ display: 'none' }}
      />
      <div className='btn-container'>
        <Button
          content='Import'
          icon={getIcon('upload')}
          className='btn-primary btn-round'
          clickHandler={triggerUploadInput}
          disabled={disabled}
          isLoading={isLoading}
        />
      </div>
      <Modal
        manuallyTriggered={confirmModalOpened}
        closeHandler={() => {
          setConfirmModalOpened(false);
        }}
      >
        <Confirm
          confirmationText='Do you want to replace your current dialog?'
          confirmedNotification='Dialog will be imported'
          onCancel={() => {
            setConfirmModalOpened(false);
          }}
          onConfirmed={() => {
            setConfirmModalOpened(false);
            importFile();
          }}
        />
      </Modal>
    </div>
  );
};

export default observer(ImportDialogButton);
