import { observer } from 'mobx-react-lite';
import React from 'react';
import Input from '../../common/Input';
import { useGetIcon } from '../../customHooks/useGetIcon';
import ColorPicker from './ColorPicker';

interface IProps {
  id: string;
  selectedColor: string | null;
  color: string;
  setSelectedColor: (color: string | null) => void;
  setColorValue: (color: string | null) => void;
}

const ColorField: React.FC<IProps> = ({
  id,
  selectedColor,
  color,
  setSelectedColor,
  setColorValue,
}) => {
  const getIcon = useGetIcon();
  return (
    <>
      <div className='picker-field'>
        <Input
          id={id}
          value={color}
          changeHandler={(color) => setColorValue(color.toUpperCase() || null)}
        />
        <i
          className={`${getIcon('palette')} picker-icon`}
          onClick={() => setSelectedColor(id)}
        ></i>
      </div>

      {selectedColor === id && (
        <ColorPicker
          color={color}
          clearSelectedColor={() => setSelectedColor(null)}
          handleColorChange={(color) => setColorValue(color)}
        />
      )}
    </>
  );
};

export default observer(ColorField);
