import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { UserAdminState } from '../../../architecture/enums/Admin';
import { Notification } from '../../../models/Utilities/Notification';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Loading from '../../common/Loading';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  onClose: () => void;
}

const NewPasswordModal: React.FC<IProps> = ({ onClose: close }) => {
  const { userAdminStore } = useAdminStores();
  const getIcon = useGetIcon();

  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);

  const resetPassword = async () => {
    const response = await userAdminStore.resetPassword();
    if (response) {
      setNewPassword(response.temporaryPassword);
    }
  };

  useEffect(() => {
    resetPassword();
  }, []);

  return (
    <div className='modal-default admin-modal reset-password-modal'>
      <div className='header'>
        <h4>Passwort reset</h4>
      </div>
      <div className='body'>
        {userAdminStore.getState(UserAdminState.ResetPassword) ? (
          <div className='password-reset-loading'>
            <Loading fullScreen={false} />
          </div>
        ) : (
          <>
            <div className='input-group'>
              <label htmlFor='new-password'>New temporary Password</label>
              <Input
                id='new-password'
                value={newPassword ?? ''}
                changeHandler={() => {}}
                actionIcon={getIcon('copy')}
                action={() => {
                  if (newPassword) {
                    navigator.clipboard.writeText(newPassword);
                    new Notification({ text: 'Copied to clipboard', type: 'success' });
                  }
                }}
              />
            </div>
            <Button
              type='button'
              content='Close'
              className='btn-primary btn-round details-button'
              clickHandler={close}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default observer(NewPasswordModal);
