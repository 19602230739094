import { NEW_USER, RESET, SET_LOADING, SET_USER, SET_USER_ROLES } from './actionTypes';
import { IAuthStateDefinition } from './AuthContext';

const reducer = (
  state: IAuthStateDefinition,
  action: { type: string; payload?: any }
): IAuthStateDefinition => {
  switch (action.type) {
    case SET_USER_ROLES:
      return {
        ...state,
        userRoles: action.payload.roles,
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload.user === undefined ? null : action.payload.user,
        authenticated: action.payload.authenticated,
        loading: false,
        userRoles: action.payload.roles,
        username: action.payload.username,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case RESET:
      return {
        user: null,
        authenticated: false,
        loading: false,
        userRoles: [],
        newUser: undefined,
        username: '',
      };
    case NEW_USER:
      return {
        ...state,
        newUser: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
