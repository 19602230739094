import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import type {
  IConversationDetails,
  IGetHistoryElement,
  IPageDetails,
  ISearchConversation,
  ISearchConversationsBody,
} from '../architecture/interfaces/HTTP/ConversationHistoryParams';
import { ConversationThread } from '../models/ConversationHistory/ConversationThread';
import { ELEMENT_TYPE_CLASS_MAPPING } from '../models/Utilities/ConversationHistory/Mappings';

import { HistoryElementFilter } from '../architecture/constants/ConversationHistory';
import { ConversationHistoryConnector } from '../models/Connectors/ConversationHistoryConnector';
import { Notification } from '../models/Utilities/Notification';
import { RootStore } from './rootStore';

class ConversationHistoryStore {
  private static instance: ConversationHistoryStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    ConversationHistoryStore.instance = this;
  }

  @observable
  private _conversationThreadRepository: ConversationThread[] = [];

  @observable
  pageDetails: IPageDetails = {
    totalPages: 0,
    totalItems: 0,
  };

  @observable
  isLoading: boolean = false;

  @observable
  selectedHistoryElementFilter = HistoryElementFilter.ShowAllElements;

  @observable
  conversationDetails: IConversationDetails = {
    from: null,
    to: null,
    channel: null,
    reviewed: null,
    sessionId: null,
    costs: null,
    credits: null,
  };

  @observable
  private _historyElementsRepository: any[] = [];

  @computed
  get allConversationThreads() {
    return this._conversationThreadRepository;
  }

  @action
  clearHistorySelection() {
    this._conversationThreadRepository = [];
  }

  @action
  setHistoryElementFilter(filter: HistoryElementFilter) {
    this.selectedHistoryElementFilter = filter;
  }

  @computed
  get historyElements() {
    return this._historyElementsRepository;
  }

  @action
  purge() {
    this._conversationThreadRepository = [];
    this._historyElementsRepository = [];
  }

  @action
  async loadConversationHistoryOverview(filters: ISearchConversationsBody) {
    try {
      const dialog = this.rootStore.dialogStore.currentlyEditedDialog;
      if (dialog) {
        this.isLoading = true;
        const response = await ConversationHistoryConnector.search(
          this.currentlyEditedBotId,
          dialog?.dialogId,
          filters
        );
        this.isLoading = false;
        this.pageDetails = {
          totalPages: response.totalPages,
          totalItems: response.totalItems,
        };
        runInAction(() => this.restoreConversations(response.conversations));
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  @action
  private restoreConversations(conversations: ISearchConversation[]) {
    this.purge();
    conversations.forEach((conversation: ISearchConversation) => {
      this.setupConversationThread(conversation);
    });
  }

  @action
  private restoreHistoryElements(elements: IGetHistoryElement[]) {
    this.purge();
    elements.forEach((element) => {
      const instance = plainToInstance(
        ELEMENT_TYPE_CLASS_MAPPING[element.typeDescription],
        element
      );
      this._historyElementsRepository.push(instance);
    });
  }

  @action
  addConversationThread(conversationThread: ConversationThread) {
    this._conversationThreadRepository.push(conversationThread);
  }

  @action
  async updateConversationReviewState(conversationThreadId: string, reviewed: boolean) {
    try {
      const dialog = this.rootStore.dialogStore.currentlyEditedDialog!;
      this.conversationDetails = {
        ...this.conversationDetails,
        reviewed: reviewed,
      };

      const thread = this._conversationThreadRepository.find(
        (thread) => thread.conversationThreadId === conversationThreadId
      );
      if (thread && reviewed) {
        thread.reviewed = new Date();
      } else if (thread) {
        thread.reviewed = null;
      }

      await ConversationHistoryConnector.updateConversationReviewState(
        this.currentlyEditedBotId,
        dialog.dialogId,
        conversationThreadId,
        reviewed
      );
    } catch (error) {
      new Notification({
        text: 'Updating Conversation Review state failed',
        type: 'error',
      });
    }
  }

  @action
  async getHistoryElements(conversationThreadId: string) {
    try {
      const dialog = this.rootStore.dialogStore.currentlyEditedDialog;
      if (dialog) {
        this.isLoading = true;
        const response = await ConversationHistoryConnector.getHistoryElements(
          this.currentlyEditedBotId,
          dialog.dialogId,
          conversationThreadId
        );
        this.isLoading = false;
        this.conversationDetails = {
          from: response.from,
          to: response.to,
          channel: response.channel,
          reviewed: response.reviewed,
          sessionId: response.sessionId,
          credits: response.credits,
          costs: response.costs,
        };

        runInAction(() => this.restoreHistoryElements(response.conversation));
      }
    } catch (error) {
      this.isLoading = false;
    }
  }

  private setupConversationThread(conversation: ISearchConversation) {
    const conversationThread = new ConversationThread(conversation);
    this.addConversationThread(conversationThread);
  }

  private get currentlyEditedBotId() {
    return this.rootStore.dialogStore.currentlyEditedBot?.botId;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('ConversationHistoryStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default ConversationHistoryStore;
