import { makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { CommunicationDialogNode } from '../CommunicationDialogNode';

export class MessageDialogNode extends CommunicationDialogNode {
  readonly type: DialogNodeTypes = DialogNodeTypes.MessageDialogNode;
  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Message Dialog Node';

    makeObservable(this, {
      type: observable,
    });
  }
}
