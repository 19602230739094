import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AuthContext from '../../ms_authentication/context/AuthContext';
import rootStore from '../../stores/rootStore';
import { HttpAgent } from '../../utility/HttpAgent';
import MediaList from '../media/MediaList';

const MediaOverview = () => {
  const { dialogStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);

  const load = async () => {
    await dialogStore.loadBots();
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  return (
    <div className='content-without-subnavigation'>
      <MediaList />
    </div>
  );
};

export default observer(MediaOverview);
