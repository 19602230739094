import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
}

const CardContainer: React.FC<IProps> = ({ children }) => {
  return (
    <div className='card-container'>
      <div className='card-group'>{children}</div>
    </div>
  );
};

export default observer(CardContainer);
