import { observer } from 'mobx-react-lite';
import React from 'react';
import { Redirect as RedirectModel } from '../../../../models/Redirects/Redirect';
import RedirectRow from './RedirectRow';

interface IProps {
  redirects: RedirectModel[];
  noConditions: boolean;
  removeRedirectHandler: (redirect: RedirectModel) => void;
}
const Redirects: React.FC<IProps> = ({
  redirects,
  noConditions,
  removeRedirectHandler,
}) => {
  return (
    <div>
      {redirects.map((redirect) => (
        <RedirectRow
          noConditions={noConditions}
          key={redirect.id}
          redirect={redirect}
          removeRedirectHandler={() => removeRedirectHandler(redirect)}
        />
      ))}
    </div>
  );
};

export default observer(Redirects);
