import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState } from 'react';
import { FacialExpressions } from '../../../../architecture/enums/FacialExpressions';
import { ContextCondition } from '../../../../models/Conditions/ContextCondition';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { MessageDialogNode } from '../../../../models/DialogNodes/MessageNodes/MessageDialogNode';
import { QuestionDialogNode as QuestionDialogNodeModel } from '../../../../models/DialogNodes/QuestionNodes/QuestionDialogNode';
import { QuestionAdaptiveCardNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { TextQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/TextQuestionNode';
import { InvalidArgumentError } from '../../../../models/errors/InvalidArgumentError';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import Switch from '../../../common/inputElements/Switch';
import Tab from '../../../common/tabElements/Tab';
import TabBody from '../../../common/tabElements/TabBody';
import TabContainer from '../../../common/tabElements/TabContainer';
import TabHeader from '../../../common/tabElements/TabHeader';
import useDialogAttributeHandler from '../../../customHooks/useAttributeChangeHandler';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import ConditionContainer from '../conditions/ConditionContainer';
import ActionAttachments from './ActionAttachments';
import AutoRecognition from './AutoRecognition';
import Links from './Links';
import MediaFiles from './MediaFiles';
import Settings from './Settings';

interface IProps {
  dialogNode: CommunicationDialogNode;
}

// Handles the rendering of either the Message or the Ask Dialog Node with including Tabs
// Handles the node property editing and saving the changes
const CommunicationNodeConfigurations: React.FC<IProps> = ({ dialogNode }) => {
  // Get Icon Custom Hook
  const getIcon = useGetIcon();
  const { ctxVarStore } = useContext(rootStore);

  const updateFacialExpression = useDialogAttributeHandler<CommunicationDialogNode>(
    dialogNode,
    'facialExpression'
  );

  let tabs = [
    {
      icon: getIcon('config'),
      text: 'Settings',
    },
    {
      icon: getIcon('brain'),
      text: 'AI',
    },
    {
      icon: getIcon('fast_forward'),
      text: 'Skip',
    },
    {
      icon: getIcon('smile'),
      text: 'Facial',
    },
    {
      icon: getIcon('code'),
      text: 'Actions',
    },
    {
      icon: getIcon('link'),
      text: 'Links',
    },
    {
      icon: getIcon('images'),
      text: 'Media',
    },
  ];

  if (
    dialogNode instanceof MessageDialogNode ||
    dialogNode instanceof TextQuestionNode ||
    dialogNode instanceof QuestionAdaptiveCardNode
  ) {
    tabs = tabs.filter((tab) => tab.text !== 'AI');
  }

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const addNewSkipCondition = () => {
    dialogNode.addCondition(
      new ContextCondition(ctxVarStore.userAndSystemVariables[0], 'IsSet')
    );
  };

  const renderSkipOptions = () => {
    return (
      <Fragment>
        {dialogNode instanceof QuestionDialogNodeModel && (
          <Switch
            key='skipIfKnown'
            value={(dialogNode as QuestionDialogNodeModel).skipIfKnown}
            clickHandler={() =>
              ((dialogNode as QuestionDialogNodeModel).skipIfKnown = !(
                dialogNode as QuestionDialogNodeModel
              ).skipIfKnown)
            }
            label={'Skip question if context variable is set'}
          />
        )}
        <p>
          You can add other conditions to define when this node should be skipped in the
          dialog.
        </p>

        <ConditionContainer
          conditions={dialogNode.conditions}
          addNewConditionHandler={addNewSkipCondition}
          removeHandler={dialogNode.removeCondition.bind(dialogNode)}
        />
      </Fragment>
    );
  };

  const renderTabBody = (tabText: string) => {
    switch (tabText) {
      case 'Settings':
        return <Settings dialogNode={dialogNode} />;
      case 'AI':
        return <AutoRecognition dialogNode={dialogNode as QuestionDialogNodeModel} />;
      case 'Skip':
        return renderSkipOptions();
      case 'Facial':
        return (
          <div className='grid repeat-3'>
            {Object.values(FacialExpressions).map((exp) => (
              <Button
                key={exp}
                className={`${
                  exp === dialogNode.facialExpression || '' ? 'btn-dark' : 'btn-primary'
                } btn-round`}
                content={exp}
                clickHandler={() => updateFacialExpression(exp)}
              />
            ))}
          </div>
        );
      case 'Actions':
        return <ActionAttachments dialogNode={dialogNode} />;
      case 'Links':
        return <Links dialogNode={dialogNode} />;
      case 'Media':
        return <MediaFiles dialogNode={dialogNode} />;
      default:
        throw new InvalidArgumentError('Invalid Tab Header');
    }
  };

  return (
    <Fragment>
      <TabContainer>
        <TabHeader>
          {tabs.map((tab) => (
            <Tab
              key={tab.text}
              icon={tab.icon}
              text={tab.text}
              isActive={activeTab.text === tab.text}
              onClick={() => setActiveTab(tab)}
            />
          ))}
        </TabHeader>
        <TabBody>{renderTabBody(activeTab.text)}</TabBody>
      </TabContainer>
    </Fragment>
  );
};

export default observer(CommunicationNodeConfigurations);
