import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import { AddressQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AddressQuestionNode';
import { AttachmentQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AttachmentQuestionNode';
import { ChoiceQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import { DateTimeQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DateTimeQuestionNode';
import { DecisiveQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DecisiveQuestionNode';
import { EmailQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EmailQuestionNode';
import { EntityQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import { NameQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NameQuestionNode';
import { NumberQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NumberQuestionNode';
import { PhoneQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/PhoneQuestionNode';
import { QuestionAdaptiveCardNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { TextQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/TextQuestionNode';
import { InvalidArgumentError } from '../../../../models/errors/InvalidArgumentError';
import { InvalidOperationError } from '../../../../models/errors/InvalidOperationError';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import NodeTypeSelector from '../../../dialogElements/nodes/typeSelectors/NodeTypeSelector';
import Button from '../../Button';

const QuestionNodeTypeSelector = () => {
  const { blockStore, nodeStore, uiStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const questionNodeTypeInfo = {
    text: {
      title: 'A Simple Text Answer',
      body: 'Asks a question from the user who should answer with a statement. For example: How is your day going today?',
    },
    number: {
      title: 'A Number',
      body: 'The user should answer with a number. Typically a number question nodes can be used to inquire the user\'s age or some kind of a unit. The answer can be then used for redirect purposes by checking if it is greater than / smaller than a desired value. For example: "How old are you" or "How many of XY product would you like to order?"',
    },
    decisive: {
      title: 'A Decision',
      body: 'Asks the user a question to which the only possible answers are "Yes" or "No". For Example: "I have saved your billing address." -- Lailo repeats data -- "Is it correct?"',
    },
    attachment: {
      title: 'An attachment',
      body: 'Ask the user to upload one or more attachments. This can be an image, video or document.',
    },
    email: {
      title: 'An E-Mail Address',
      body: 'Asks the user about an e-mail address. It is a good idea to use an E-Mail Question Node instead of a simple Text Question Node because it is possible to identify and validate an e-mail pattern for example. Or to force the user to enter the E-Mail address manually.',
    },
    multichoice: {
      title: 'Multiple Choice',
      body: 'Gives the user a few pre-defined answers to choose from. Ask a question to which you can pre-define answers, for eaxample "What is your favourite color" - Red - Blue - Green - Black and so on.',
    },
    address: {
      title: 'An Address',
      body: "If you would like to ask the user about a his / her address, use this node. It is possible to correctly identify cities, streets, street numbers, zip codes and countries through Artifical Intelligence so Lailo will know exactly how to interpret the user's address.",
    },
    name: {
      title: 'A Name',
      body: 'It is possible to recognize and store names through Artifical Intelligence. It is not only a pattern-recognition for "My name is XY" or "I am called XY" but it is way more flexible.',
    },
    phone: {
      title: 'Phone Nr.',
      body: 'Ask the user about his / her phone number. The provided number will be evaluated and checked if it is a valid number.',
    },
    card: {
      title: 'Question Card',
      body: 'Send a universal Card with input fields to gather multiple information from the user.',
    },
    datetime: {
      title: 'Date And Time',
      body: 'Ask the user about a certain Date and Time.',
    },
    entity: {
      title: 'Custom Entity',
      body: 'Asks the user about an arbitrary topic and filters for a specific entity.',
    },
  };

  const [activeInfo, setActiveInfo] = useState<{ title: string; body: string }>();

  const { closeModal } = useModal();

  useEffect(() => {
    setActiveInfo(questionNodeTypeInfo['text']);
    // eslint-disable-next-line
  }, []);

  // const handleClick = (nodeType: keyof typeof QuestionNodeTypes) => {
  const handleClick = (nodeType: DialogNodeTypes) => {
    if (!blockStore.selectedBlock) {
      throw new InvalidOperationError('Block must be set.');
    }

    let node: BaseDialogNode;
    switch (nodeType) {
      case DialogNodeTypes.TextQuestionNode:
        node = new TextQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.NumberQuestionNode:
        node = new NumberQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.EmailQuestionNode:
        node = new EmailQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.DecisiveQuestionNode:
        node = new DecisiveQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.ChoiceQuestionNode:
        node = new ChoiceQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.AddressQuestionNode:
        node = new AddressQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.NameQuestionNode:
        node = new NameQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.PhoneQuestionNode:
        node = new PhoneQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.QuestionAdaptiveCardNode:
        node = new QuestionAdaptiveCardNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.DateTimeQuestionNode:
        node = new DateTimeQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.EntityQuestionNode:
        node = new EntityQuestionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.AttachmentQuestionNode:
        node = new AttachmentQuestionNode(blockStore.selectedBlock!);
        break;
      default:
        throw new InvalidArgumentError('Node type unkonwn');
    }

    if (nodeStore.selectedParentNode) {
      nodeStore.insertAfter(node, nodeStore.selectedParentNode);
    } else {
      // If there is no selected parent node in the first place, the node will be inserted at the bottom of the block
      // Which means that auto scrolling is desired
      uiStore.scrollToElement(uiStore.nodeListContainer!, 'bottom');
      nodeStore.append(node);
    }

    closeModal();
  };

  const btnGroup = () => (
    <Fragment>
      <Button
        icon={getIcon('name')}
        content='Name'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['name'])}
        clickHandler={() => handleClick(DialogNodeTypes.NameQuestionNode)}
      />
      <Button
        icon={getIcon('text')}
        content='Text'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['text'])}
        clickHandler={() => handleClick(DialogNodeTypes.TextQuestionNode)}
      />
      <Button
        icon={getIcon('number')}
        content='Number'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['number'])}
        clickHandler={() => handleClick(DialogNodeTypes.NumberQuestionNode)}
      />
      <Button
        icon={getIcon('paperclip')}
        content='Attachment'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['attachment'])}
        clickHandler={() => handleClick(DialogNodeTypes.AttachmentQuestionNode)}
      />
      <Button
        icon={getIcon('calendar')}
        content='Date And Time'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['datetime'])}
        clickHandler={() => handleClick(DialogNodeTypes.DateTimeQuestionNode)}
      />
      <Button
        icon={getIcon('email')}
        content='Email'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['email'])}
        clickHandler={() => handleClick(DialogNodeTypes.EmailQuestionNode)}
      />
      <Button
        icon={getIcon('decisive')}
        content='Yes/No'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['decisive'])}
        clickHandler={() => handleClick(DialogNodeTypes.DecisiveQuestionNode)}
      />
      <Button
        icon={getIcon('multichoice')}
        content='Multiple'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['multichoice'])}
        clickHandler={() => handleClick(DialogNodeTypes.ChoiceQuestionNode)}
      />
      <Button
        icon={getIcon('address')}
        content='Address'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['address'])}
        clickHandler={() => handleClick(DialogNodeTypes.AddressQuestionNode)}
      />
      <Button
        icon={getIcon('phone')}
        content='Phone'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['phone'])}
        clickHandler={() => handleClick(DialogNodeTypes.PhoneQuestionNode)}
      />
      <Button
        icon={getIcon('card')}
        content='Question Card'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['card'])}
        clickHandler={() => handleClick(DialogNodeTypes.QuestionAdaptiveCardNode)}
      />
      <Button
        icon={getIcon('icons')}
        content='Custom Entity'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(questionNodeTypeInfo['entity'])}
        clickHandler={() => handleClick(DialogNodeTypes.EntityQuestionNode)}
      />
    </Fragment>
  );

  return (
    <NodeTypeSelector
      activeInfo={activeInfo!}
      title='I would like to ask about...'
      btnGroup={btnGroup()}
    />
  );
};

export default QuestionNodeTypeSelector;
