import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import IntentStore from '../../stores/IntentStore';
import { ISerializedLabel } from '../Intents/Label';
import { ISerializedTrigger, Trigger } from './Trigger';

export class IntentTrigger extends Trigger {
  @Expose({ name: 'intent' })
  intentName?: string;
  readonly type = TriggerTypes.Intent;

  constructor() {
    super();
    this.intentName = '';

    makeObservable(this, {
      intentName: observable,
      intent: computed,
    });
  }

  get intent() {
    if (this.intentName) {
      return IntentStore.getInstance().getByName(this.intentName)!;
    }

    return undefined;
  }

  get isValid() {
    return !!(this.intentName && this.intent && this.intent?.isValid);
  }

  remove() {
    this.intent?.remove();
    this.intentName = undefined;
  }

  serialize(): ISerializedIntentTrigger {
    return {
      ...super.serialize(),
      intent: this.intentName,
      labels: this.intent?.labels
        ? this.intent.labels.map((label) => label.serialize())
        : [],
    };
  }
}

export interface ISerializedIntentTrigger extends ISerializedTrigger {
  intent?: string;
  labels: ISerializedLabel[];
}
