import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';
import Data from './Data';
import Manage from './Manage';

const Dataset = () => {
  return (
    <div id='dataset' className='content-with-subnavigation'>
      <Switch>
        <Route exact path='/datasets/manage' component={Manage} />
        <Route exact path='/datasets/data' component={Data} />
      </Switch>
    </div>
  );
};

export default observer(Dataset);
