import { useContext } from 'react';
import rootStore from '../../stores/rootStore';
export const useGetIcon = () => {
  const {
    uiStore: { icons },
  } = useContext(rootStore);

  const getIcon = (icon: keyof typeof icons) => {
    return icons[icon];
  };

  return getIcon;
};
