import { observer } from 'mobx-react-lite';
import React from 'react';
import { SubQuestionGroup } from '../../../../../../../architecture/enums/SubQuestionKey';
import { GroupedSubQuestion } from '../../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedSubQuestion';
import Button from '../../../../../../common/Button';
import { useGetIcon } from '../../../../../../customHooks/useGetIcon';

interface IProps {
  groups: SubQuestionGroup[];
  canAddSubQuestions: boolean;
  isKeyStored: (key: SubQuestionGroup) => boolean;
  addSubQuestionsHandler: (key: SubQuestionGroup) => GroupedSubQuestion;
  removeSubQuestionsHandler: (key: SubQuestionGroup) => void;
}

const GroupSubQuestionInserter: React.FC<IProps> = ({
  groups,
  canAddSubQuestions,
  isKeyStored,
  addSubQuestionsHandler,
  removeSubQuestionsHandler,
}) => {
  const getIcon = useGetIcon();

  const renderButton = (key: SubQuestionGroup) => {
    const settings = getButtonSettings(key);

    return (
      <Button
        key={key}
        icon={settings.icon}
        className={`btn-round ${settings.theme}`}
        disabled={!canAddSubQuestions}
        content={`${key}`}
        clickHandler={settings.clickHandler}
      />
    );
  };

  const getButtonSettings = (key: SubQuestionGroup) => {
    if (isKeyStored(key)) {
      return {
        icon: getIcon('minus'),
        theme: 'btn-dark',
        clickHandler: () => {
          removeSubQuestionsHandler(key);
        },
      };
    }
    return {
      icon: getIcon('add'),
      theme: 'btn-primary',
      clickHandler: () => {
        addSubQuestionsHandler(key);
      },
    };
  };
  return (
    <div className='multi-ctx-button-container'>
      {groups.map((key) => renderButton(key as SubQuestionGroup))}
    </div>
  );
};

export default observer(GroupSubQuestionInserter);
