import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { SubscriptionState } from '../../architecture/enums/SubscriptionState';
import { Subscription } from '../../models/Subscription/Subscription';
import { Notification } from '../../models/Utilities/Notification';
import rootStore from '../../stores/rootStore';
import {
  acquireOrRefreshAccessToken,
  processLogin,
  processLogout,
  processSignUp,
} from '../init';
import AuthContext, { IAuthStateDefinition } from './AuthContext';
import AuthReducer from './AuthReducer';
import { NEW_USER, RESET, SET_LOADING, SET_USER, SET_USER_ROLES } from './actionTypes';

const AuthState: React.FC = (props: any) => {
  const initialState: IAuthStateDefinition = {
    user: null,
    authenticated: false,
    loading: true,
    userRoles: [],
    newUser: undefined,
    username: '',
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const { subscriptionStore } = useContext(rootStore);

  const { instance, accounts, inProgress } = useMsal();

  const _handleInitialAuthentication = async () => {
    await _getToken();
  };

  useEffect(() => {
    if (inProgress === 'none' && accounts.length > 0) {
      _handleInitialAuthentication();
    }
  }, [inProgress, accounts, instance]);

  useEffect(() => {
    _handleToken();
  }, [token]);

  useEffect(() => {
    if (subscriptionStore.getState(SubscriptionState.Initialized)) {
      _setUserInfo();
    }
  }, [subscriptionStore.getState(SubscriptionState.Initialized)]);

  const login = async () => {
    try {
      await processLogin();
    } catch (error) {
      _reset();
      new Notification({ type: 'error', text: 'Login failed' });
    }
  };

  const signUp = async () => {
    try {
      await processSignUp();
    } catch (error) {
      _reset();
      new Notification({ type: 'error', text: 'Registration failed' });
    }
  };

  const logout = async () => {
    try {
      await processLogout('/');
    } catch (error) {
      new Notification({ type: 'error', text: 'Logout failed' });
    }
  };

  const newUserOnboarded = async () => {
    try {
      await subscriptionStore.load(true);
      _setUserInfo();

      dispatch({
        type: NEW_USER,
        payload: false,
      });
    } catch (error) {
      new Notification({ type: 'error', text: 'Onboarding failed' });
    }
  };

  const _handleToken = async () => {
    if (token) {
      await subscriptionStore.load();

      const newUser = !!(accounts[0] as any).idTokenClaims?.newUser;
      if (newUser && subscriptionStore.allSubscriptions.length === 0) {
        dispatch({
          type: NEW_USER,
          payload: true,
        });
      }
    }
  };

  const _getToken = async (forceRefresh: boolean = false) => {
    _setLoading(true);
    const tok = await acquireOrRefreshAccessToken(forceRefresh);
    setToken(tok);
  };

  const _setUserInfo = () => {
    const currentAccount = accounts[0];
    let selectedSubscription: Subscription | undefined;
    if (currentAccount?.localAccountId) {
      if (subscriptionStore.allSubscriptions.length === 1) {
        subscriptionStore.storeUsersSubscription(
          subscriptionStore.allSubscriptions[0].id,
          currentAccount.localAccountId
        );
      }

      selectedSubscription = subscriptionStore.getUsersSubscription(
        currentAccount.localAccountId
      );

      dispatch({
        type: SET_USER,
        payload: {
          roles: selectedSubscription?.roles,
          user: currentAccount,
          authenticated: !!currentAccount && !!token,
          username: _getUsername(currentAccount),
        },
      });
    }

    _setLoading(false);
  };

  const _reset = () => {
    dispatch({
      type: RESET,
    });
  };

  const _setLoading = (isLoading: boolean) => {
    dispatch({
      type: SET_LOADING,
      payload: isLoading,
    });
  };

  const updateUserRoles = (roles: string[]) => {
    dispatch({
      type: SET_USER_ROLES,
      payload: {
        roles: roles,
      },
    });
  };

  const _getUsername = (user: AccountInfo): string => {
    const claims = user.idTokenClaims;
    if (!claims) {
      return '';
    }
    return `${(claims as any).given_name} ${(claims as any).family_name}`;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        signUp,
        logout,
        updateUserRoles,
        newUserOnboarded,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default observer(AuthState);
