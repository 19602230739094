import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MediaState } from '../../../architecture/enums/MediaState';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import KnowledgebaseContainer from '../../knowledgebase/KnowledgebaseContainer';

interface IParamsProps {
  dialogId?: string;
}

const KnowledgebaseOverview = () => {
  const { dialogStore, mediaStore, subscriptionStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();

  const load = async () => {
    await dialogStore.loadBots();
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  const loadMediaFiles = async () => {
    await mediaStore.load();
  };

  useEffect(() => {
    loadMediaFiles();
  }, [subscriptionStore.selectedSubscription?.id]);

  useEffect(() => {
    if (
      !dialogStore.currentlyEditedDialog &&
      params.dialogId &&
      mediaStore.getState(MediaState.Initialized)
    ) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params, mediaStore.getState(MediaState.Initialized)]);

  return <KnowledgebaseContainer />;
};

export default observer(KnowledgebaseOverview);
