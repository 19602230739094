import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Loading from '../common/Loading';

interface IProps {
  fullScreen?: boolean;
  showText?: boolean;
}

const LoadingPage: React.FC<IProps> = ({ fullScreen = true, showText = true }) => {
  const { botId, dialogId } = useParams<{ botId: string; dialogId: string }>();

  const [passedTime, setPassedTime] = useState(0);
  const animationDuration = 2500;

  useEffect(() => {
    const incrementPassedTime = setInterval(
      () => setPassedTime((prev) => (prev += 1)),
      animationDuration
    );

    return () => {
      clearInterval(incrementPassedTime);
    };
  }, [dialogId, botId]);

  const renderText = () => {
    if (passedTime <= 1) return 'Please wait...';
    else if (passedTime > 1 && passedTime <= 3) return 'Just a bit more...';
    else if (passedTime > 3 && passedTime <= 6) return 'Stay hydrated ;)';
    else return 'This probably takes too long...';
  };

  return (
    <div className='auth-page-container'>
      <Loading fullScreen={fullScreen} />
      {showText && (
        <h2
          className={`auth-page-container-text ${fullScreen ? '' : 'nofullscreen'}`}
          data-should-animate={passedTime <= 6}
        >
          {renderText()}
        </h2>
      )}
    </div>
  );
};

export default LoadingPage;
