import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { SingleQuestionNode } from '../SingleQuestionNode';

export class NumberQuestionNode extends SingleQuestionNode {
  type: DialogNodeTypes = DialogNodeTypes.NumberQuestionNode;
  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Number Question Node';
  }
}
