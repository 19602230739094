import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { MediaState } from '../../architecture/enums/MediaState';
import rootStore from '../../stores/rootStore';
import Upload from '../common/inputElements/Upload';
import EmptyPage from '../common/EmptyPage';
import { useCreateMediaItem } from '../customHooks/useCreateMediaItem';
import LoadingPage from '../layout/LoadingPage';
import Filters from './filters/Filters';

const MediaList: React.FC = () => {
  const { createMediaItem } = useCreateMediaItem();
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { mediaStore, subscriptionStore } = useContext(rootStore);
  const [mediaItems, setMediaItems] = useState(mediaStore.allMediaItems);

  const { type, name } = params;

  const handleParamsChange = (key: string, value: string) => {
    let newData = params;
    const data = { [key]: value };
    newData = { ...newData, ...data };

    // remove blank attributes from data
    const dataObj = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v));

    return history.push({
      pathname: '/media',
      search: queryString.stringify(dataObj),
    });
  };

  const clearParamsChange = (key: string) => {
    delete params[key];

    return history.push({
      pathname: 'media',
      search: queryString.stringify(params),
    });
  };

  const renderMediaItems = () => {
    return mediaItems.map((item) => createMediaItem(item));
  };

  const loadMediaFiles = async () => {
    await mediaStore.load();
  };

  useEffect(() => {
    loadMediaFiles();
  }, [subscriptionStore.selectedSubscription?.id]);

  const upload = async (formData: FormData) => {
    await mediaStore.upload(formData);
  };

  useEffect(() => {
    const mediaItems = mediaStore.search(type?.toString(), name?.toString());
    setMediaItems(mediaItems);
  }, [mediaStore.allMediaItems, type, name]);

  return (
    <>
      {mediaStore.getState(MediaState.ItemsLoading) ? (
        <LoadingPage />
      ) : (
        <>
          {mediaStore.getState(MediaState.Initialized) && (
            <div className='media-wrapper content-without-subnavigation'>
              <div className='media-header'>
                <Filters
                  handleParamsChange={handleParamsChange}
                  clearParamsChange={clearParamsChange}
                />
              </div>
              <div className='upload'>
                <Upload upload={upload} />
              </div>
              {mediaItems.length > 0 ? (
                <div className='media-list-container'>{renderMediaItems()}</div>
              ) : (
                <EmptyPage />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default observer(MediaList);
