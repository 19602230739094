import { action, makeObservable, observable } from 'mobx';
import { ISerializedText, Text } from '../Utilities/Text';
import { State } from './State';

export class Label {
  id: number;
  text: Text;

  constructor(text?: string, id?: number, public state?: State) {
    this.id = id ? id : Math.floor(Math.random() * 1000000000);
    this.text = text ? new Text(text) : new Text('');
    this.state = state ?? 'Initialized';

    makeObservable(this, {
      id: observable,
      text: observable,
      state: observable,
      canBeRemovedFromArray: action,
      setDeletedState: action,
      setText: action,
    });
  }

  setText(text: string) {
    if (text === this.text.value) return;

    switch (this.state) {
      case 'Initialized':
        this.state = 'Added';
        break;
      case 'UpToDate':
        this.state = 'Updated';
        break;
      case 'Deleted':
        return;
      default:
        break;
    }

    this.text.value = text;
  }

  canBeRemovedFromArray(): boolean {
    switch (this.state) {
      case 'Added':
      case 'Initialized':
        return true;
      case 'UpToDate':
      case 'Updated':
      default:
        return false;
    }
  }

  setDeletedState() {
    this.state = 'Deleted';
  }

  serialize(): ISerializedLabel {
    return {
      id: this.id,
      text: this.text.serialize(),
    };
  }
}

export interface ISerializedLabel {
  id: number;
  text: ISerializedText;
}
