import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChoiceQuestionNode as ChoiceQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import SingleQuestionNode from '../SingleQuestionNode/SingleQuestionNode';
import MultiChoiceArea from './MultiChoiceArea';

interface IProps {
  dialogNode: ChoiceQuestionNodeModel;
}
const ChoiceQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return (
    <SingleQuestionNode dialogNode={dialogNode}>
      <MultiChoiceArea dialogNode={dialogNode} />
    </SingleQuestionNode>
  );
};

export default observer(ChoiceQuestionNode);
