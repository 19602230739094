import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';

const PronunciationTableHeader = () => {
  const { pronunciationStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const addRow = () => {
    pronunciationStore.addEntry();
  };

  return (
    <div className='action-header'>
      <Button
        content='Add a new row'
        className='btn-round btn-primary btn-action'
        icon={getIcon('add_alt')}
        reverseContent={true}
        clickHandler={() => addRow()}
      />
      <Button
        content='Discard changes'
        className='btn-round btn-inactive btn-action'
        icon={getIcon('history')}
        reverseContent={true}
        clickHandler={() => showModal(ModalTypes.DiscardPronunciationChanges)}
      />
    </div>
  );
};

export default observer(PronunciationTableHeader);
