import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { DatasetState } from '../../../architecture/enums/DatasetState';
import { MediaState } from '../../../architecture/enums/MediaState';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { RedirectDialogNode } from '../../../models/DialogNodes/RedirectDialogNode';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import Button from '../../common/Button';
import FloatingButtonGroup from '../../common/FloatingButtonGroup';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import DialogNodeList from '../DialogNodeList';
import LoadingPage from '../LoadingPage';

interface IParamsProps {
  dialogId?: string;
  blockId?: string;
}

const DialogDetails = () => {
  // Context | Stores
  const {
    blockStore,
    nodeStore,
    uiStore,
    dialogStore,
    mediaStore,
    datasetStore,
    subscriptionStore,
  } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();

  const scrollElement = useRef<HTMLDivElement>(null);

  // Custom Hooks
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const load = async () => {
    await Promise.all([dialogStore.loadBots(), datasetStore.load()]);
  };

  const loadMediaFiles = async () => {
    await mediaStore.load();
  };

  useEffect(() => {
    return () => {
      blockStore.clearBlockSelection();
    };
  }, []);

  useEffect(() => {
    if (!userLoading && authenticated) {
      load();
    }

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    loadMediaFiles();
  }, [subscriptionStore.selectedSubscription?.id]);

  useEffect(() => {
    if (
      !dialogStore.currentlyEditedDialog &&
      params.dialogId &&
      mediaStore.getState(MediaState.Initialized) &&
      datasetStore.getState(DatasetState.Initialized)
    ) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params, mediaStore.allMediaItems, datasetStore.allDatasets]);

  useEffect(() => {
    if (params.blockId) {
      blockStore.setBlockById(params.blockId);
    }
  }, [blockStore.allBlocks.length, params]);

  const handleNewNodeClick = (nodeType: string) => {
    switch (nodeType) {
      case 'message':
        uiStore.nodeListContainer = scrollElement.current!;
        showModal(ModalTypes.MessageNodeTypeSelector);
        break;
      case 'question':
        uiStore.nodeListContainer = scrollElement.current!;
        showModal(ModalTypes.QuestionNodeTypeSelector);
        break;
      case 'redirect':
        nodeStore.append(new RedirectDialogNode(blockStore.selectedBlock!));
        uiStore.scrollToElement(scrollElement.current!);
        break;
      case 'action':
        uiStore.nodeListContainer = scrollElement.current!;
        showModal(ModalTypes.ActionNodeTypeSelector);
        break;
      default:
        break;
    }
  };

  const floatingButtons = () => (
    <FloatingButtonGroup fixed={true} rounded={true} direction='up'>
      <Button icon={getIcon('info')} className={'btn btn-question btn-circle'} />
      <Button
        icon={getIcon('remove')}
        className={'btn btn-question btn-circle'}
        tooltip='Remove all nodes'
        clickHandler={() => showModal(ModalTypes.PurgeNodes, blockStore.selectedBlock)}
      />
      <Button
        icon={getIcon('redirect')}
        clickHandler={() => handleNewNodeClick('redirect')}
        className={'btn btn-add btn-circle'}
        tooltip='Redirect Node'
      />
      <Button
        icon={getIcon('config')}
        clickHandler={() => handleNewNodeClick('action')}
        className={'btn btn-add btn-circle'}
        tooltip='Action Node'
      />
      <Button
        icon={getIcon('question')}
        clickHandler={() => handleNewNodeClick('question')}
        className={'btn btn-add btn-circle'}
        tooltip='Question Node'
      />
      <Button
        icon={getIcon('message')}
        clickHandler={() => handleNewNodeClick('message')}
        className={'btn btn-add btn-circle'}
        tooltip='Message Node'
      />
      <Button
        icon={getIcon('up')}
        clickHandler={() => uiStore.scrollToTop(scrollElement.current!)}
        className={'btn btn-add btn-circle'}
        tooltip='To Top'
      />
    </FloatingButtonGroup>
  );

  return blockStore.selectedBlock ? (
    <div className='dialog-details-container'>
      {floatingButtons()}
      <DialogNodeList
        scrollElement={scrollElement}
        nodes={nodeStore.renderableSelectedNodes}
      />
    </div>
  ) : (
    <LoadingPage />
  );
};

export default observer(DialogDetails);
