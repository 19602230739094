import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import {
  CONDITIONS_REQUIRING_VALUES,
  ListConditionOperator,
} from '../../../../../../architecture/enums/Condition';
import { ContextCondition } from '../../../../../../models/Conditions/ContextCondition';
import Select from '../../../../../common/inputElements/select/Select';
import TextBoxWithCtx from '../../../../../common/inputElements/TextBoxWithCtx';

interface IProps {
  condition: ContextCondition;
}
const ListContextVariableHandler: React.FC<IProps> = ({ condition }) => {
  const handleConditionSelection = (operator: string) => {
    condition.operator = ListConditionOperator[operator as ListConditionOperator];
  };

  const handleConditionValueChange = (value: string) => {
    condition.value = value;
  };

  return (
    <Fragment>
      <Select
        items={Object.values(ListConditionOperator).map((operator) => ({
          title: operator,
        }))}
        selectHandler={handleConditionSelection}
        search
        closeOnSelect
        triggerLabel={condition.operator ?? 'Choose a condition'}
      />
      {/* Showing input field only if it is necessary */}
      {CONDITIONS_REQUIRING_VALUES.includes(condition.operator) && (
        <TextBoxWithCtx
          className='condition-setting-ctx'
          value={condition.value?.toString() ?? ''}
          saveHandler={handleConditionValueChange}
          placeholder=''
          multiline={false}
        />
      )}
    </Fragment>
  );
};

export default observer(ListContextVariableHandler);
