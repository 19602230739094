import React from 'react';
import { DialogNodeTypes } from '../../architecture/enums/DialogComponentType';
import { NodeConfigurationState } from '../../contexts/communicationConfig/NodeConfigurationState';
import { ActionAdaptiveCardNode as ActionAdaptiveCardNodeModel } from '../../models/DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import { ActionDialogNode as ActionDialogNodeModel } from '../../models/DialogNodes/ActionNodes/ActionDialogNode';
import { ApiActionNode as ApiActionNodeModel } from '../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { ConfirmationActionNode as ConfirmationActionNodeModel } from '../../models/DialogNodes/ActionNodes/ConfirmationActionNode';
import { ContextActionNode as ContextActionNodeModel } from '../../models/DialogNodes/ActionNodes/ContextActionNode';
import { DatasetActionNode as DatasetActionNodeModel } from '../../models/DialogNodes/ActionNodes/DatasetActionNode';
import { EmailActionNode as EmailActionNodeModel } from '../../models/DialogNodes/ActionNodes/EmailActionNode';
import { StorageActionNode as StorageActionNodeModel } from '../../models/DialogNodes/ActionNodes/StorageActionNode';
import { AdaptiveCardNode as AdaptiveCardNodeModel } from '../../models/DialogNodes/AdaptiveCardNode/AdaptiveCardNode';
import { BaseDialogNode } from '../../models/DialogNodes/BaseDialogNode';
import { MessageDialogNode as MessageDialogNodeModel } from '../../models/DialogNodes/MessageNodes/MessageDialogNode';
import { QuestionDialogNode as QuestionDialogNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionDialogNode';
import { AddressQuestionNode as AddressQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AddressQuestionNode';
import { AttachmentQuestionNode as AttachmentQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AttachmentQuestionNode';
import { ChoiceQuestionNode as ChoiceQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import { DateTimeQuestionNode as DateTimeQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DateTimeQuestionNode';
import { DecisiveQuestionNode as DecisiveQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DecisiveQuestionNode';
import { EmailQuestionNode as EmailQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EmailQuestionNode';
import { EntityQuestionNode as EntityQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import { NameQuestionNode as NameQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NameQuestionNode';
import { NumberQuestionNode as NumberQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/NumberQuestionNode';
import { PhoneQuestionNode as PhoneQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/PhoneQuestionNode';
import { QuestionAdaptiveCardNode as QuestionAdaptiveCardNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { TextQuestionNode as TextQuestionNodeModel } from '../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/TextQuestionNode';
import { RedirectDialogNode as RedirectDialogNodeModel } from '../../models/DialogNodes/RedirectDialogNode';
import RedirectDialogNode from '../dialogElements/nodes/RedirectDialogNode';
import ConfirmationActionNode from '../dialogElements/nodes/actionNodes/ConfirmationActionNode';
import EmailActionNode from '../dialogElements/nodes/actionNodes/EmailActionNode';
import ActionAdaptiveCardNode from '../dialogElements/nodes/actionNodes/adaptiveCard/ActionAdaptiveCardNode';
import ApiActionNode from '../dialogElements/nodes/actionNodes/apiActionNode/ApiActionNode';
import ContextActionNode from '../dialogElements/nodes/actionNodes/contextActionNode/ContextActionNode';
import DatasetActionNode from '../dialogElements/nodes/actionNodes/datasetActionNode/DatasetActionNode';
import StorageActionNode from '../dialogElements/nodes/actionNodes/storageActionNode/StorageActionNode';
import InfoAdaptiveCardNode from '../dialogElements/nodes/messageNodes/InfoAdaptiveCardNode';
import MessageDialogNode from '../dialogElements/nodes/messageNodes/MessageDialogNode';
import QuestionAdaptiveCardNode from '../dialogElements/nodes/questionNodes/AdaptiveCards/QuestionAdaptiveCardNode';
import AddressQuestionNode from '../dialogElements/nodes/questionNodes/Address/AddressQuestionNode';
import AttachmentQuestionNode from '../dialogElements/nodes/questionNodes/Attachment/AttachmentQuestionNode';
import ChoiceQuestionNode from '../dialogElements/nodes/questionNodes/Choice/ChoiceQuestionNode';
import DateTimeQuestionNode from '../dialogElements/nodes/questionNodes/DateTime/DateTimeQuestionNode';
import DecisiveQuestionNode from '../dialogElements/nodes/questionNodes/Decisive/DecisiveQuestionNode';
import EmailQuestionNode from '../dialogElements/nodes/questionNodes/Email/EmailQuestionNode';
import EntityQuestionNode from '../dialogElements/nodes/questionNodes/Entity/EntityQuestionNode';
import NameQuestionNode from '../dialogElements/nodes/questionNodes/Name/NameQuestionNode';
import NumberQuestionNode from '../dialogElements/nodes/questionNodes/Number/NumberQuestionNode';
import {
  default as PhoneQuestionNode,
  default as TextQuestionNode,
} from '../dialogElements/nodes/questionNodes/Text/TextQuestionNode';
export function useCreateDialogNode() {
  const _createQuestionNode = (dialogNode: QuestionDialogNodeModel) => {
    switch (dialogNode.type) {
      case DialogNodeTypes.AddressQuestionNode:
        return (
          <AddressQuestionNode dialogNode={dialogNode as AddressQuestionNodeModel} />
        );
      case DialogNodeTypes.DecisiveQuestionNode:
        return (
          <DecisiveQuestionNode dialogNode={dialogNode as DecisiveQuestionNodeModel} />
        );
      case DialogNodeTypes.EmailQuestionNode:
        return <EmailQuestionNode dialogNode={dialogNode as EmailQuestionNodeModel} />;
      case DialogNodeTypes.ChoiceQuestionNode:
        return <ChoiceQuestionNode dialogNode={dialogNode as ChoiceQuestionNodeModel} />;
      case DialogNodeTypes.NameQuestionNode:
        return <NameQuestionNode dialogNode={dialogNode as NameQuestionNodeModel} />;
      case DialogNodeTypes.NumberQuestionNode:
        return <NumberQuestionNode dialogNode={dialogNode as NumberQuestionNodeModel} />;
      case DialogNodeTypes.TextQuestionNode:
        return <TextQuestionNode dialogNode={dialogNode as TextQuestionNodeModel} />;
      case DialogNodeTypes.PhoneQuestionNode:
        return <PhoneQuestionNode dialogNode={dialogNode as PhoneQuestionNodeModel} />;
      case DialogNodeTypes.DateTimeQuestionNode:
        return (
          <DateTimeQuestionNode dialogNode={dialogNode as DateTimeQuestionNodeModel} />
        );
      case DialogNodeTypes.EntityQuestionNode:
        return <EntityQuestionNode dialogNode={dialogNode as EntityQuestionNodeModel} />;
      case DialogNodeTypes.AttachmentQuestionNode:
        return (
          <AttachmentQuestionNode
            dialogNode={dialogNode as AttachmentQuestionNodeModel}
          />
        );
      default:
        throw new Error(
          'There is no React Component implemented for this question node type.'
        );
    }
  };

  const _createActionNode = (dialogNode: ActionDialogNodeModel) => {
    switch (dialogNode.type) {
      case DialogNodeTypes.ApiActionNode:
        return <ApiActionNode dialogNode={dialogNode as ApiActionNodeModel} />;
      case DialogNodeTypes.ContextActionNode:
        return <ContextActionNode dialogNode={dialogNode as ContextActionNodeModel} />;
      case DialogNodeTypes.EmailActionNode:
        return <EmailActionNode dialogNode={dialogNode as EmailActionNodeModel} />;
      case DialogNodeTypes.DatasetActionNode:
        return <DatasetActionNode dialogNode={dialogNode as DatasetActionNodeModel} />;
      case DialogNodeTypes.StorageActionNode:
        return <StorageActionNode dialogNode={dialogNode as StorageActionNodeModel} />;
      case DialogNodeTypes.ConfirmationActionNode:
        return (
          <ConfirmationActionNode
            dialogNode={dialogNode as ConfirmationActionNodeModel}
          />
        );
      default:
        throw new Error(
          `There is no React Component implemented for this action node type. ${dialogNode.type}`
        );
    }
  };

  const _createAdaptiveCardNode = (dialogNode: AdaptiveCardNodeModel) => {
    switch (dialogNode.type) {
      case DialogNodeTypes.InfoAdaptiveCardNode:
        return <InfoAdaptiveCardNode dialogNode={dialogNode as AdaptiveCardNodeModel} />;
      case DialogNodeTypes.QuestionAdaptiveCardNode:
        return (
          <QuestionAdaptiveCardNode
            dialogNode={dialogNode as QuestionAdaptiveCardNodeModel}
          />
        );
      case DialogNodeTypes.ActionAdaptiveCardNode:
        return (
          <ActionAdaptiveCardNode
            dialogNode={dialogNode as ActionAdaptiveCardNodeModel}
          />
        );
      default:
        break;
    }
  };

  const createNode = (dialogNode: BaseDialogNode) => {
    const component = (() => {
      if (dialogNode instanceof MessageDialogNodeModel)
        return <MessageDialogNode dialogNode={dialogNode as MessageDialogNodeModel} />;
      if (dialogNode instanceof ActionDialogNodeModel)
        return _createActionNode(dialogNode as ActionDialogNodeModel);
      if (dialogNode instanceof QuestionDialogNodeModel)
        return _createQuestionNode(dialogNode as QuestionDialogNodeModel);
      if (dialogNode instanceof RedirectDialogNodeModel)
        return <RedirectDialogNode dialogNode={dialogNode as RedirectDialogNodeModel} />;
      if (dialogNode instanceof AdaptiveCardNodeModel)
        return _createAdaptiveCardNode(dialogNode as AdaptiveCardNodeModel);

      throw new Error('Unknown dialog node instance.');
    })();

    return (
      <NodeConfigurationState key={dialogNode.id}>{component}</NodeConfigurationState>
    );
  };

  return {
    createNode,
  };
}
