import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Column, useFlexLayout, useTable } from 'react-table';
import { TableData } from '../../../architecture/enums/TableData';
import { DailyBillingSummary } from '../../../models/Admin/DailyBillingSummary';
import { Utilities } from '../../../models/Utilities/Utilities';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import { HEADERS } from './constants';

const DailyBillingTable: React.FC = () => {
  const { botAdminStore, billingAdminStore } = useAdminStores();

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: HEADERS.date.id,
        Header: HEADERS.date.label,
        accessor: (dailySummary: TableData) => {
          return (dailySummary as DailyBillingSummary)?.date
            ? Utilities.getLocalDate(
                (dailySummary as DailyBillingSummary).date!,
                'dateOnly'
              )
            : 'n/a';
        },
      },
      {
        id: HEADERS.communicationActivities.id,
        Header: HEADERS.communicationActivities.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).communicationActivities,
      },
      {
        id: HEADERS.conversations.id,
        Header: HEADERS.conversations.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).conversations,
      },
      {
        id: HEADERS.conversationThreads.id,
        Header: HEADERS.conversationThreads.label,
        accessor: (dailySummary: TableData) =>
          (dailySummary as DailyBillingSummary).conversationThreads,
      },
      {
        id: HEADERS.credits.id,
        Header: HEADERS.credits.label,
        accessor: (transaction: TableData) =>
          (transaction as DailyBillingSummary).credits,
      },
      {
        id: HEADERS.costs.id,
        Header: HEADERS.costs.label,
        accessor: (transaction: TableData) => (transaction as DailyBillingSummary).costs,
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    if (!billingAdminStore.allDailySummaries) {
      return [];
    }

    return billingAdminStore.allDailySummaries.map(
      (dailySummary: DailyBillingSummary) => {
        return {
          date: dailySummary.date || '',
          tokens: dailySummary.credits,
          communicationActivities: dailySummary.communicationActivities,
          conversations: dailySummary.conversations,
          conversationThreads: dailySummary.conversationThreads,
          credits: dailySummary.credits,
          costs: dailySummary.costs,
        };
      }
    );
  }, [billingAdminStore.allDailySummaries]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  return (
    <Table
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      selectedRow={botAdminStore.getSelectedBot as TableData}
      prepareRow={prepareRow}
      // onRowClick={handleRowClick}
    />
  );
};

export default observer(DailyBillingTable);
