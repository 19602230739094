import React, { useEffect, useRef } from 'react';
import { Utilities } from '../../../../../../models/Utilities/Utilities';

interface IRowValueProps {
  value: string | number | null;
  isStored: boolean;
  clickHandler: () => void;
}

const Value: React.FC<IRowValueProps> = ({ value, isStored, clickHandler }) => {
  const limiter = 50;

  const processedValue =
    typeof value === 'number'
      ? value
      : Utilities.truncateText(value?.toString() ?? 'null', limiter);

  const fullTextRef = useRef(null);

  useEffect(() => {
    if (fullTextRef.current) {
      (fullTextRef.current as unknown as HTMLDivElement).innerHTML =
        value?.toString() ?? 'null';
    }
  }, [fullTextRef]);

  return (
    <span className='json-prop-row-value' data-stored={isStored} onClick={clickHandler}>
      {processedValue}
      {processedValue !== value && value != null && (
        <div ref={fullTextRef} className='full-text'></div>
      )}
    </span>
  );
};

export default Value;
