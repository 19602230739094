import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { SingleQuestionNode } from '../SingleQuestionNode';

export class DecisiveQuestionNode extends SingleQuestionNode {
  type = DialogNodeTypes.DecisiveQuestionNode;
  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Yes/No Question Node';
  }
}
