import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatasetStatus, PropertyState } from '../../../../../architecture/enums/Dataset';
import { Dataset } from '../../../../../models/Dataset/Dataset';
import { DatasetListProperty } from '../../../../../models/Dataset/Property/DatasetListProperty';
import { DatasetProperty } from '../../../../../models/Dataset/Property/DatasetProperty';
import { SimpleProperty } from '../../../../../models/Dataset/Property/SimpleProperty';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  dataset: Dataset;
  property: SimpleProperty | DatasetProperty | DatasetListProperty;
  setEditProperty: (
    property: SimpleProperty | DatasetProperty | DatasetListProperty
  ) => void;
}

const Actions: React.FC<IProps> = ({ dataset, property, setEditProperty }) => {
  const getIcon = useGetIcon();

  return (
    <div className={`actions ${property.state === PropertyState.Deleted && 'deleted'}`}>
      <i
        className={`${getIcon('edit_bold')} actions-icon`}
        onClick={() => {
          if (property.state !== PropertyState.Deleted) setEditProperty(property);
        }}
      ></i>
      {dataset.status !== DatasetStatus.Used && (
        <i
          className={`${getIcon('remove')} actions-icon`}
          onClick={() => {
            dataset.removeProperty(property);
          }}
        ></i>
      )}
    </div>
  );
};

export default observer(Actions);
