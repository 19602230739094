import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media as MediaModel } from '../../../models/Media/Media';

interface IProps {
  item: MediaModel;
}

const ImagePreview: React.FC<IProps> = ({ item }: IProps) => {
  return (
    <div className='preview-content'>
      <img src={item.url} width='100%' />
    </div>
  );
};

export default observer(ImagePreview);
