import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import VerticalNavigationButton from './VerticalNavigationButton';

interface IProps {
  pane: ReactNode;
  clickHandler: () => void;
  buttonContent: string;
}

const SinglePaneWithNavigation = ({ pane, clickHandler, buttonContent }: IProps) => {
  return (
    <div className='single-pane-with-nav'>
      <VerticalNavigationButton clikHandler={clickHandler} content={buttonContent} />
      {pane}
    </div>
  );
};

export default observer(SinglePaneWithNavigation);
