import { observer } from 'mobx-react-lite';
import React from 'react';
import { IMAGES_CONTENT_TYPE } from '../../../../architecture/constants/Media';
import { MediaTypes } from '../../../../architecture/enums/MediaType';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import Files from './components/Files';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Image: React.FC<IProps> = ({ entry }) => {
  return (
    <>
      <div className='title'></div>
      <p className='subtitle'>Select a picture from your library:</p>
      <Files
        mediaType={MediaTypes.Image}
        entry={entry}
        mediaFiles={entry.mediaFiles.filter((media) =>
          IMAGES_CONTENT_TYPE.includes(media.contentType)
        )}
      />
    </>
  );
};

export default observer(Image);
