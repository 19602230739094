import { Exclude, Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

export class Entity {
  @Exclude()
  id: string;

  @Expose()
  name: string;

  @Expose()
  value: string;

  constructor(name: string = '', value: string = '') {
    this.id = v4();
    this.value = value;
    this.name = name;

    makeObservable(this, {
      value: observable,
      name: observable,
    });
  }

  get isValid(): boolean {
    return this.name && this.value ? true : false;
  }

  serialize(): ISerializedEntity {
    return {
      name: this.name,
      value: this.value,
    };
  }
}

export interface ISerializedEntity {
  name: string;
  value: string;
}
