import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { AdaptiveCardInput } from '../../../../../../models/DialogNodes/AdaptiveCardNode/Misc/AdaptiveCardInput';
import rootStore from '../../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import Select from '../../../../../common/inputElements/select/Select';

interface IProps {
  inputFields: AdaptiveCardInput[];
}
const AdaptiveCardInputMapping: React.FC<IProps> = ({ inputFields }) => {
  const { ctxVarStore } = useContext(rootStore);

  const handleInputCtxSelect = (field: AdaptiveCardInput, ctxName: string) => {
    const ctx = ctxVarStore.addIfNotExists(ctxName);
    field.assignCtx(ctx);
  };

  return (
    <Fragment>
      <h4 style={{ textAlign: 'center' }}>
        Store the user input values in context variables
      </h4>
      <div className='adaptive-card-input-fields-container'>
        {inputFields
          .filter((field) => field.isValid)
          .map((field) => (
            <div key={field.inputId} className='adaptive-card-input-row'>
              <h4 className='input-field-id'>
                {field.inputId}
                <span className='input-field-id-meta'>{field.type}</span>
              </h4>
              <ContextVariableSelector
                items={ctxVarStore.userVariables}
                triggerLabel={field.ctx ? field.ctx.name : 'Select a context variable'}
                selectHandler={(title: string) => handleInputCtxSelect(field, title)}
                fullWidth
                replaceSpecialCharacters
                creator
                closeOnSelect
              />
            </div>
          ))}
      </div>
    </Fragment>
  );
};

export default observer(AdaptiveCardInputMapping);
