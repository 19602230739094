import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import {
  DOCUMENTS_CONTENT_TYPE,
  IMAGES_CONTENT_TYPE,
  VIDEOS_CONTENT_TYPE,
} from '../../../architecture/constants/Media';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import { InvalidArgumentError } from '../../../models/errors/InvalidArgumentError';
import Button from '../../common/Button';
import Tab from '../../common/knowledgebaseTabElements/Tab';
import TabBody from '../../common/knowledgebaseTabElements/TabBody';
import TabContainer from '../../common/knowledgebaseTabElements/TabContainer';
import TabHeader from '../../common/knowledgebaseTabElements/TabHeader';
import Actions from './tabs/Action';
import Document from './tabs/Document';
import Face from './tabs/Face';
import Image from './tabs/Image';
import Links from './tabs/Links';
import Video from './tabs/Video';

interface IProps {
  entry: KnowledgebaseEntry;
  resetModal: () => void;
}

const Attachments: React.FC<IProps> = ({ entry, resetModal }) => {
  const attachmentsTabs = [
    {
      text: 'Face',
      numberOfAttachments: entry.facialExpression ? 1 : 0,
    },
    {
      text: 'Image',
      numberOfAttachments: entry.mediaFiles.filter((media) =>
        IMAGES_CONTENT_TYPE.includes(media.contentType)
      ).length,
    },
    {
      text: 'Video',
      numberOfAttachments: entry.mediaFiles.filter((media) =>
        VIDEOS_CONTENT_TYPE.includes(media.contentType)
      ).length,
    },
    {
      text: 'Document',
      numberOfAttachments: entry.mediaFiles.filter((media) =>
        DOCUMENTS_CONTENT_TYPE.includes(media.contentType)
      ).length,
    },
    {
      text: 'Links',
      numberOfAttachments: entry.urlRedirectActions.length,
    },
    {
      text: 'Action',
      numberOfAttachments: entry.genericActions.length,
    },
  ];
  const [tabs, setTabs] = useState(attachmentsTabs);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    setTabs(attachmentsTabs);
  }, [JSON.stringify(entry)]);

  const renderTabBody = (tabText: string) => {
    switch (tabText) {
      case 'Face':
        return <Face entry={entry} />;
      case 'Image':
        return <Image entry={entry} />;
      case 'Video':
        return <Video entry={entry} />;
      case 'Document':
        return <Document entry={entry} />;
      case 'Links':
        return <Links entry={entry} />;
      case 'Action':
        return <Actions entry={entry} />;
      default:
        throw new InvalidArgumentError('Invalid Tab Header');
    }
  };

  return (
    <div className='modal-default knowledgebase-modal'>
      <div className='header'>
        <h3>Attachments</h3>
      </div>
      <div className='attachments-container'>
        <TabContainer>
          <TabHeader>
            {tabs.map((tab) => (
              <Tab
                key={tab.text}
                badge={tab.numberOfAttachments}
                text={tab.text}
                isActive={activeTab.text === tab.text}
                onClick={() => setActiveTab(tab)}
              />
            ))}
          </TabHeader>
          <TabBody>{renderTabBody(activeTab.text)}</TabBody>
        </TabContainer>
      </div>
      <div className='btn-container'>
        <Button
          content='Done'
          className='btn-primary btn-round'
          clickHandler={resetModal}
          disabled={!entry.areAttachmentsValid()}
          tooltip={
            !entry.areAttachmentsValid()
              ? 'Please configure all attachments properly'
              : undefined
          }
          tooltipPosition='top'
        />
      </div>
    </div>
  );
};

export default observer(Attachments);
