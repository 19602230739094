import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from '@azure/msal-browser';

export const configuration: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES!],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
};

const instance = (function (config: Configuration) {
  let _instance: PublicClientApplication | null = null;

  if (_instance === null) {
    _instance = new PublicClientApplication(config);
  }

  return _instance;
})(configuration);

let aquireTokenAuthority = process.env.REACT_APP_MSAL_AUTHORITY;

export async function processLogin() {
  const loginRequest = {
    authority: process.env.REACT_APP_MSAL_AUTHORITY,
    scopes: [],
  };

  await instance.loginRedirect(loginRequest);
}

export async function processSignUp() {
  const signUpRequest = {
    authority: process.env.REACT_APP_MSAL_REGISTER_AUTHORITY,
    scopes: [],
  };

  await instance.loginRedirect(signUpRequest);
}

instance.addEventCallback((message: EventMessage) => {
  // if (message.eventType === EventType.LOGIN_START) {
  //   const account = (message.payload as AuthenticationResult).account;
  //   instance.setActiveAccount(account);
  // } else
  // Remember the used authority from the login for further calls to aquire a token
  if (message.eventType === EventType.LOGIN_SUCCESS) {
    const account = (message.payload as AuthenticationResult).account;
    aquireTokenAuthority = (message.payload as AuthenticationResult).authority;
    instance.setActiveAccount(account);
  }
});
export async function processLogout(redirectUri?: string) {
  await instance.logoutRedirect({ postLogoutRedirectUri: redirectUri });
}
export async function acquireOrRefreshAccessToken(forceRefresh: boolean = false) {
  const account = instance.getActiveAccount() as AccountInfo | undefined;

  const loginRequest = {
    authority: aquireTokenAuthority,
    scopes: [process.env.REACT_APP_MSAL_AUTH_SCOPE_READWRITE_ALL!],
    account: account,
    forceRefresh: forceRefresh,
  };

  try {
    if (!account) throw new Error('No account is active');

    const token = await instance.acquireTokenSilent(loginRequest);
    return token.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return instance
        .acquireTokenPopup(loginRequest)
        .then((resp) => {
          return resp.accessToken;
        })
        .catch(() => {
          return null;
        });
    } else {
      console.error(error);
    }
  }
}

export default instance;
