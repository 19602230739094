import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import useAdminStores from '../../customHooks/useAdminStores';
import SubscriptionDetails from './SubscriptionDetails';
import SubscriptionsList from './SubscriptionsList';

const SubscriptionPage: React.FC = () => {
  const { subscriptionAdminStore } = useAdminStores();

  useEffect(() => {
    return () => {
      subscriptionAdminStore.setSelectedSubscription(undefined);
    };
  }, []);

  const showSubscriptions = () => {
    return <SinglePane pane={<SubscriptionsList />} />;
  };

  const showSubscriptionsAndDetails = () => {
    return (
      <TwoPanes leftPane={<SubscriptionsList />} rightPane={<SubscriptionDetails />} />
    );
  };

  if (subscriptionAdminStore.getSelectedSubscription) {
    return showSubscriptionsAndDetails();
  }

  return showSubscriptions();
};

export default observer(SubscriptionPage);
