import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CHANNEL_OPTIONS,
  FILTERS,
  REVIEWED_OPTIONS,
  TRIGGER_TYPE_OPTIONS,
} from '../../../architecture/constants/ConversationHistory';
import FilterBoxes from '../filterBoxes/FilterBoxes';
import DateFilter from './filtersGroup/DateFilter';
import FilterOptionsList from '../../common/FilterOptionsList';
import ScoreFilter from './filtersGroup/ScoreFilter';
import TextFilter from './filtersGroup/TextFilter';
import SearchInput from './SearchInput';
import SortContainer from './sorting/SortContainer';

interface IProps {
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
}

const Filters: React.FC<IProps> = ({ handleParamsChange, clearParamsChange }) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [filterInput, setFilterInput] = useState({
    name: FILTERS.USER_MESSAGE.NAME,
    title: FILTERS.USER_MESSAGE.TITLE,
  });

  const filtersGroup = () => {
    return (
      <div className='filters-group'>
        <TextFilter
          name={FILTERS.USER_MESSAGE.NAME}
          title={FILTERS.USER_MESSAGE.TITLE}
          className={
            filterInput.name === FILTERS.USER_MESSAGE.NAME ? 'filter-active' : ''
          }
          setFilterInput={(name, title) => setFilterInput({ name, title })}
        />
        <TextFilter
          name={FILTERS.TRIGGER_BLOCK_NAME.NAME}
          title={FILTERS.TRIGGER_BLOCK_NAME.TITLE}
          className={
            filterInput.name === FILTERS.TRIGGER_BLOCK_NAME.NAME ? 'filter-active' : ''
          }
          setFilterInput={(name, title) => setFilterInput({ name, title })}
        />
        <TextFilter
          name={FILTERS.EVENT_ID.NAME}
          title={FILTERS.EVENT_ID.TITLE}
          className={filterInput.name === FILTERS.EVENT_ID.NAME ? 'filter-active' : ''}
          setFilterInput={(name, title) => setFilterInput({ name, title })}
        />
        <DateFilter
          className={
            params[FILTERS.FROM_DATE.NAME] || params[FILTERS.TO_DATE.NAME]
              ? 'filter-active'
              : ''
          }
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
        <ScoreFilter
          className={
            params[FILTERS.FROM_SCORE.NAME] || params[FILTERS.TO_SCORE.NAME]
              ? 'filter-active'
              : ''
          }
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
        <FilterOptionsList
          name={FILTERS.CHANNEL.NAME}
          title={FILTERS.CHANNEL.TITLE}
          className={params[FILTERS.CHANNEL.NAME] ? 'filter-active' : ''}
          options={CHANNEL_OPTIONS}
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
        <FilterOptionsList
          name={FILTERS.TRIGGER_TYPE.NAME}
          title={FILTERS.TRIGGER_TYPE.TITLE}
          className={params[FILTERS.TRIGGER_TYPE.NAME] ? 'filter-active' : ''}
          options={TRIGGER_TYPE_OPTIONS}
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
        <FilterOptionsList
          name={FILTERS.REVIEWED.NAME}
          title={FILTERS.REVIEWED.TITLE}
          className={params[FILTERS.REVIEWED.NAME] ? 'filter-active' : ''}
          options={REVIEWED_OPTIONS}
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
      </div>
    );
  };

  return (
    <div>
      <div className='filters-container'>
        <SearchInput
          filter={filterInput}
          clearParamsChange={clearParamsChange}
          handleParamsChange={handleParamsChange}
        />
        {filtersGroup()}
      </div>
      <div className='filter-boxes-sorting-wrapper'>
        <div className='filter-boxes-wrapper'>
          <FilterBoxes clearParamsChange={clearParamsChange} />
        </div>
        <SortContainer handleParamsChange={handleParamsChange} />
      </div>
    </div>
  );
};

export default observer(Filters);
