import { makeObservable, observable } from 'mobx';
import { InitiatingHistoryElement } from './InitiatingHistoryElement';

export class IncomingActionCardElement extends InitiatingHistoryElement {
  actionId: string;

  constructor() {
    super();
    this.actionId = '';

    makeObservable(this, {
      actionId: observable,
    });
  }
}
