import React, { CSSProperties, useContext } from 'react';
import rootStore from '../../../../stores/rootStore';

interface IProps {
  icon: string;
  isError: boolean;
}

const NodeIcon: React.FC<IProps> = ({ icon, isError }) => {
  const { uiStore } = useContext(rootStore);

  const style: CSSProperties = isError ? { background: uiStore.colors.nodeError } : {};
  return <i className={icon + ' dialog-node-icon'} style={style}></i>;
};

export default NodeIcon;
