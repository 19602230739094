export const HEADERS = {
  userName: {
    label: 'User name',
    id: 'displayName',
  },
  email: {
    label: 'E-Mail',
    id: 'email',
  },
  actions: {
    label: '',
    id: 'actions',
  },
};
