import { computed, makeObservable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { AdaptiveCardNode } from '../AdaptiveCardNode/AdaptiveCardNode';

export class InfoAdaptiveCardNode extends AdaptiveCardNode {
  type = DialogNodeTypes.InfoAdaptiveCardNode;

  constructor(block: DialogBlock) {
    super(block);

    makeObservable(this, {
      hasInputs: computed,
      hasSubmits: computed,
    });
  }

  get hasInputs() {
    return this.inputMapping.length > 0;
  }

  get hasSubmits() {
    return this.submitMapping.length > 0;
  }

  get isValid() {
    return super.isValid && !this.hasInputs && !this.hasSubmits;
  }
}
