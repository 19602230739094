import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { useReadOnly } from '../../../components/customHooks/useReadOnly';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import { Question } from '../../../models/Knowledgebase/Question';
import Textarea from '../../common/inputElements/Textarea';
import { useGetIcon } from '../../customHooks/useGetIcon';
import QuestionTag from './questionTag/QuestionTag';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Questions: React.FC<IProps> = ({ entry }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const questionRef = useRef<HTMLDivElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const [question, setQuestion] = useState('');
  const [addingNewQuestion, setAddingNewQuestion] = useState(false);
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();

  const addQuestion = () => {
    entry.addQuestion(question);
    setQuestion('');
  };

  useEffect(() => {
    if (textareaRef.current && questionRef.current && tagRef.current) {
      textareaRef.current.style.maxWidth = questionRef.current?.offsetWidth - 50 + 'px';
      textareaRef.current.style.width = 30 + question.length * 9 + 'px';
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
      tagRef.current.style.height = scrollHeight + 9 + 'px';
    }
  }, [question]);

  return (
    <div ref={questionRef} id={question} className='cell-wrapper'>
      <div className={entry.state === 'Deleted' ? 'tags deleted' : 'tags'}>
        {entry.questions.map((questionItem: Question, index: number) => {
          return (
            <QuestionTag
              questionRef={questionRef}
              entry={entry}
              key={index}
              question={questionItem}
            />
          );
        })}
        {!knowledgebaseReadOnlyMode && (
          <div
            className='tag'
            ref={tagRef}
            onClick={() => {
              entry.state !== 'Deleted' &&
                !knowledgebaseReadOnlyMode &&
                setAddingNewQuestion(true);
              if (tagRef.current) {
                tagRef.current.style.height = '45px';
              }
            }}
          >
            {addingNewQuestion && (
              <Textarea
                style={{ marginBottom: '1px' }}
                autofocus={true}
                ref={textareaRef}
                value={question}
                disabled={entry.state === 'Deleted' || knowledgebaseReadOnlyMode}
                changeHandler={setQuestion}
                rows={1}
                keyDownHandler={(e) => {
                  if (e.key === 'Enter' && question.trim().length > 0) {
                    addQuestion();
                  }
                }}
                blurHandler={() => {
                  if (question.trim().length > 0) {
                    addQuestion();
                  }
                }}
                className='question-cell'
              />
            )}
            <i className={getIcon('add_alt')}></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(Questions);
