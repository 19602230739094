import React, { ChangeEvent, useRef } from 'react';
import Button from '../Button';

interface IProps {
  icon?: string;
  acceptFiles?: string;
  content?: string;
  tooltip?: string;
  upload: (formData: FormData, fileName?: string) => void;
}

const Upload: React.FC<IProps> = ({
  icon = 'fas fa-upload',
  acceptFiles,
  content = 'Upload',
  tooltip,
  upload,
}: IProps) => {
  // Ref Hook
  const inputRef = useRef<HTMLInputElement>(null);

  // Custom Functions, Handlers
  const handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files == null) return;

    // This only handles a single file. This is ok at this point because the
    // input doesn't support multiple files at the moment. But we can change
    // that in the future if necessary.
    const selectedFile = event.target.files[0];
    const formData: FormData = new FormData();

    formData.append('FormFile', selectedFile, selectedFile.name);

    upload(formData, selectedFile.name);
  };

  const triggerUpload = () => {
    inputRef.current!.click();
  };

  return (
    <div>
      <input
        ref={inputRef}
        accept={acceptFiles}
        type='file'
        name='fileInput'
        id='fileInput'
        onChange={handleFileInput}
        style={{ display: 'none' }}
      />
      <Button
        icon={icon}
        content={content}
        className='btn-round btn-primary'
        clickHandler={triggerUpload}
      />
    </div>
  );
};

export default Upload;
