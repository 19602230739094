import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionAdaptiveCardNode as ActionAdaptiveCardNodeModel } from '../../../../../models/DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import QuestionAdaptiveCardNode from '../../questionNodes/AdaptiveCards/QuestionAdaptiveCardNode';
import ActionSubmitRow from './ActionSubmitRow';

interface IProps {
  dialogNode: ActionAdaptiveCardNodeModel;
}

const ActionAdaptiveCardNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <QuestionAdaptiveCardNode dialogNode={dialogNode}>
      <h4>Set up a redirect for each submit action</h4>
      {dialogNode.submitMapping.map(
        (submit) =>
          submit.isValid && (
            <ActionSubmitRow
              key={submit.submitId + submit.title + submit.data._actionId}
              redirectOfSubmit={dialogNode.getRedirectOfSubmit(submit)}
              submitRow={submit}
            />
          )
      )}
      {children}
    </QuestionAdaptiveCardNode>
  );
};

export default observer(ActionAdaptiveCardNode);
