import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { KnowledgebaseNodeElement as KnowledgebaseNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/KnowledgebaseNodeElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: KnowledgebaseNodeElementModel;
}

const KnowledgebaseNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <BaseHistoryElement element={element} title='Knowledgebase Node' icon={getIcon('robot')} >
      <div className='card-paragraph'>
        <i className={getIcon('double_left')}></i>
        <span className='card-paragraph-text'>
          {element.text}
        </span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(KnowledgebaseNodeElement);
