import React from 'react';
import { ITooltipItem } from '../../../architecture/interfaces/tooltip/ITooltip';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  item: ITooltipItem;
  type?: 'error' | 'warning' | 'info';
  clickHandler?: (id: string) => void;
}
const TooltipMessage: React.FC<IProps> = ({ item, type, clickHandler }) => {
  const getIcon = useGetIcon();

  const getIconName = () => {
    switch (type) {
      case 'error':
        return getIcon('error');
      case 'info':
        return getIcon('info');
      case 'warning':
        return getIcon('error');
      default:
        return '';
    }
  };

  return (
    <li className='tooltip-message' onClick={() => clickHandler?.(item.id)}>
      {type && (
        <div className='tooltip-message-wrapper'>
          <i
            className={getIconName() + ' ' + 'tooltip-message-icon'}
            data-type={type ?? 'not-specified'}
          ></i>
        </div>
      )}
      <p className='tooltip-message-texts'>
        <span className='tooltip-message-text'>{item.message}</span>
        {item.meta && <span className='tooltip-message-meta'>{item.meta}</span>}
      </p>
    </li>
  );
};

export default TooltipMessage;
