import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import IntentStore from '../../stores/IntentStore';
import { Label } from './Label';
import { State } from './State';

// Intents are NOT part of the standard serialization/deserialization cycle.
// They will be set up via the IntentStore once a dialog is loaded.
export class Intent {
  id: string;
  name: string;
  labels: Label[];
  state: State = 'Added';

  constructor(name?: string, labels?: Label[], id?: string) {
    this.id = id ? id : v4();
    this.name = name ? name : v4();
    this.labels = labels ? labels : [];

    makeObservable(this, {
      id: observable,
      name: observable,
      labels: observable,
      state: observable,
      activeLabels: computed,
      isValid: computed,
      addLabel: action,
      setName: action,
      setLabels: action,
      remove: action,
      removeLabel: action,
    });
  }

  get activeLabels() {
    return this.labels.filter((label) => label.state !== 'Deleted');
  }

  get isValid() {
    return this.activeLabels.length > 0;
  }

  addLabel(labelText: string) {
    if (
      !this.labels.some(
        (label) => label.text.value.toLowerCase() === labelText.toLowerCase()
      )
    ) {
      const newLabel = new Label(labelText);
      newLabel.state = 'Added';
      this.labels.unshift(newLabel);
    }
  }

  setLabels(labels: Label[]) {
    this.labels = labels;
  }

  removeLabel(label: Label) {
    if (label.canBeRemovedFromArray()) {
      label.setDeletedState();
      this.labels = this.labels.filter((elem) => elem !== label);
    }
  }

  setName(name: string) {
    if (this.name === name) return;

    switch (this.state) {
      case 'Initialized':
        this.state = 'Added';
        break;
      case 'UpToDate':
        this.state = 'Updated';
        break;
      case 'Deleted':
        return;
      default:
        break;
    }

    this.name = name;
  }

  remove() {
    if (this.state === 'Added' || this.state === 'Initialized') {
      IntentStore.getInstance().remove(this);
    } else {
      this.state = 'Deleted';
    }
  }
}
