import { observer } from 'mobx-react-lite';
import React from 'react';
import { TextQuestionNode as TextQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/TextQuestionNode';
import SingleQuestionNode from '../SingleQuestionNode/SingleQuestionNode';
interface IProps {
  dialogNode: TextQuestionNodeModel;
}

const TextQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <SingleQuestionNode dialogNode={dialogNode} />;
};

export default observer(TextQuestionNode);
