import { GERMAN_TEXTS } from './texts/deutsch';
import { ENGLISH_TEXTS } from './texts/english';

export interface ILanguageValues {
  DashboardTitle: string;
  NavbarTitle: string;
}

export type ILanguageImplementation = {
  [key: string]: ILanguageValues;
};

export const languages = {
  'en-US': {
    label: 'English',
    code: 'en-US',
    picture: '/not-pic-yet.jpg',
  },
  'de-DE': {
    label: 'Deutsch',
    code: 'de-DE',
    picture: '/not-pic-yet.jpg',
  },
};

export const languageBundle: ILanguageImplementation = {
  ...GERMAN_TEXTS,
  ...ENGLISH_TEXTS,
};
