import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import { BillingAdminState } from '../../../architecture/enums/Admin';
import useAdminStores from '../../customHooks/useAdminStores';
import LoadingPage from '../../layout/LoadingPage';

interface IProps {
  children: ReactNode;
}

const BillingDetailsContainer: React.FC<IProps> = ({ children }: IProps) => {
  const { billingAdminStore, botAdminStore } = useAdminStores();

  const getUTCDateString = () => {
    const from = billingAdminStore.getFrom();
    const to = billingAdminStore.getTo();
    if (!from || !to) {
      return;
    }

    return `: ${from.getUTCDate()}.${
      from.getUTCMonth() + 1
    }.${from.getUTCFullYear()} - ${to.getUTCDate()}.${
      to.getUTCMonth() + 1
    }.${to.getUTCFullYear()}`;
  };

  return (
    <div className='billing-content container-wrapper'>
      {billingAdminStore.getState(BillingAdminState.DetailsLoading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4 className='title-name'>
              {botAdminStore.getSelectedBot?.botName}
              {billingAdminStore.timespanSelected && getUTCDateString()}
            </h4>
          </div>
          {children}
        </>
      )}
    </div>
  );
};

export default observer(BillingDetailsContainer);
