import { Expose, Transform } from 'class-transformer';
import { action, makeObservable, observable } from 'mobx';
import { ContextVariable } from '../../../ContextVariables/ContextVariable';
import { TransformIntoContextVariable } from '../../../Utilities/Deserialization/Decorators';
import { Utilities } from '../../../Utilities/Utilities';

export class AdaptiveCardInput {
  @Expose()
  inputId: string;

  @Expose()
  type: string;

  @Expose({ name: 'ctxId' })
  @TransformIntoContextVariable
  ctx: ContextVariable | null;

  constructor(inputId: string, type: string, ctx: ContextVariable | null = null) {
    this.inputId = inputId;
    this.type = type;
    this.ctx = ctx;

    makeObservable(
      this,
      {
        inputId: observable,
        type: observable,
        ctx: observable,
        assignCtx: action,
      },
      { autoBind: true }
    );
  }

  get isValid() {
    return !Utilities.isEmpty(this.inputId);
  }

  assignCtx(ctx: ContextVariable | null) {
    this.ctx = ctx;
  }

  serialize(): ISerializedAdaptiveCardInput {
    return {
      inputId: this.inputId,
      type: this.type,
      ctxId: this.ctx?.id,
    };
  }
}

export interface ISerializedAdaptiveCardInput {
  inputId: string;
  type: string;
  ctxId?: string;
}
