import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import { Question } from '../../../../models/Knowledgebase/Question';
import Textarea from '../../../common/inputElements/Textarea';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../customHooks/useReadOnly';

interface IProps {
  entry: KnowledgebaseEntry;
  edit: boolean;
  setEdit: (value: boolean) => void;
  question: Question;
  questionRef: React.RefObject<HTMLDivElement>;
}

const EditableTag: React.FC<IProps> = ({
  entry,
  edit,
  setEdit,
  question,
  questionRef,
}) => {
  const getIcon = useGetIcon();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const tagRef = useRef<HTMLDivElement>(null);
  const { knowledgebaseReadOnlyMode } = useReadOnly();

  useEffect(() => {
    if (textareaRef.current && tagRef.current && edit) {
      textareaRef.current.style.maxWidth = questionRef.current?.offsetWidth! - 50 + 'px';
      textareaRef.current.style.width = 30 + question.text.value.length * 9 + 'px';
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
      tagRef.current.style.height = scrollHeight + 9 + 'px';
      textareaRef.current.focus();
    }
  }, [edit, question.text.value]);

  const discardChange = () => {
    question.reset();
  };

  return (
    <div
      ref={tagRef}
      className={
        question.state === 'Deleted'
          ? 'tag deleted'
          : question.state === 'Added' || question.state === 'Modified'
          ? 'tag highlighted'
          : 'tag'
      }
    >
      <Textarea
        ref={textareaRef}
        value={question.text.value}
        disabled={knowledgebaseReadOnlyMode || question.state === 'Deleted'}
        changeHandler={(value) => {
          question.setQuestion(value);
        }}
        keyDownHandler={(e) => {
          if (e.key === 'Enter') {
            setEdit(false);
          }
        }}
        focusHandler={(e) =>
          textareaRef.current?.setSelectionRange(
            question.text.value.length,
            question.text.value.length
          )
        }
        className='question-cell'
      />
      {(question.state === 'Deleted' || question.state === 'Modified') && (
        <i
          className={`${getIcon('undo')} tag-icon`}
          onClick={() => {
            if (!knowledgebaseReadOnlyMode) {
              discardChange();
            }
          }}
        />
      )}
      {(question.state === 'Added' || question.state === 'UpToDate') && (
        <i
          className={`${getIcon('minus_circle')} delete-icon`}
          onClick={() => {
            if (!knowledgebaseReadOnlyMode) {
              entry.removeQuestion(question);
            }
          }}
        />
      )}
    </div>
  );
};

export default observer(EditableTag);
