import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AdaptiveCardNodeElement as AdaptiveCardNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/AdaptiveCardNodeElement';
import BaseHistoryElement from '../BaseHistoryElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: AdaptiveCardNodeElementModel;
}

const AdaptiveCardNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();
  const { blockStore, nodeStore } = useContext(rootStore);
  const block = blockStore?.allBlocks?.find(block => block?.id === element.blockId);
  const node = nodeStore?.allNodes?.find(block => block?.id === element.nodeId);

  return (
    <BaseHistoryElement element={element} title='Adaptive Card Node is created' icon={getIcon('card')}>
      <div className='card-paragraph'>
        <i className={getIcon('network')}></i>
        <span className='message-wrapper'> Block: {block?.title}; </span>
        <span className='message-wrapper'> Node: {node?.title}.</span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(AdaptiveCardNodeElement);
