import { observer } from 'mobx-react-lite';
import React from 'react';
import { AdaptiveCardNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { ActionAdaptiveCardNode } from '../../../../models/DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import { AdaptiveCardNode } from '../../../../models/DialogNodes/AdaptiveCardNode/AdaptiveCardNode';
import { InfoAdaptiveCardNode } from '../../../../models/DialogNodes/MessageNodes/InfoAdaptiveCardNode';
import { QuestionAdaptiveCardNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { Utilities } from '../../../../models/Utilities/Utilities';
import InfoMessage from '../../../common/InfoMessage';

interface IProps {
  dialogNode: AdaptiveCardNode;
}
const AdaptiveCardErrorMessages: React.FC<IProps> = ({ dialogNode }) => {
  const defaultMessage = 'Card template is invalid JSON';

  const _createInfoErrorMessages = (dialogNode: InfoAdaptiveCardNode) => {
    if (dialogNode.hasInputs || dialogNode.hasSubmits) {
      return [
        <InfoMessage
          key='info-message-error'
          message='Info Cards are not allowed to have any input fields or submit actions'
          type='error'
        />,
      ];
    }

    return null;
  };

  const _createQuestionErrorMessages = (dialogNode: QuestionAdaptiveCardNode) => {
    const components = [];
    if (dialogNode.submitMapping.length !== 1)
      components.push(
        <InfoMessage
          key='question-submit-action-error'
          message='Input Cards must have exactly 1 submit action'
          type='error'
        />
      );

    _createSubmitMappingErrorMessages(components);

    if (Utilities.isEmpty(dialogNode.inputMapping))
      components.push(
        <InfoMessage
          key='question-input-mapping-error'
          message='Input Cards must have at least 1 input field'
          type='error'
        />
      );

    if (!dialogNode.areInputsValid) {
      components.push(
        <InfoMessage
          key={'action-input-map-id-error'}
          message='Input fields must always have a unique ID'
          type='error'
        />
      );
    }

    return components;
  };

  const _createActionErrorMessages = (dialogNode: ActionAdaptiveCardNode) => {
    const components = [];

    if (dialogNode.submitMapping.length === 0) {
      components.push(
        <InfoMessage
          key='action-submit-mapping-missing'
          message='Action Cards must have submit actions.'
          type='error'
        />
      );
    }

    _createSubmitMappingErrorMessages(components);

    if (!dialogNode.areInputsValid) {
      components.push(
        <InfoMessage
          key={'action-input-map-id-error'}
          message='Input fields must always have a unique ID'
          type='error'
        />
      );
    }

    return components;
  };

  const _createSubmitMappingErrorMessages = (components: JSX.Element[]) => {
    dialogNode.submitMapping
      .filter((submit) => !submit.isValid)
      .forEach((submit) => {
        let msg = `${
          submit.title ? submit.title : 'A submit action'
        } is missing properties: `;

        let missingParts = [];

        if (Utilities.isEmpty(submit.title)) {
          missingParts.push('Title');
        }
        if (Utilities.isEmpty(submit.submitId)) {
          missingParts.push('ID');
        }

        msg = msg + missingParts.join(', ');

        components.push(
          <InfoMessage
            key={'action-submit-map-prop-error' + submit.data._actionId}
            message={msg}
            type='error'
          />
        );
      });
  };

  const createErrorMessage = () => {
    if (Utilities.isEmpty(dialogNode.template)) return null;

    if (!Utilities.isJsonValid(dialogNode.template)) {
      return [
        <InfoMessage
          key='question-template-error'
          message={defaultMessage}
          type='error'
        />,
      ];
    }

    if (dialogNode.type === AdaptiveCardNodeTypes.InfoAdaptiveCardNode) {
      return _createInfoErrorMessages(dialogNode as InfoAdaptiveCardNode);
    }

    if (dialogNode.type === AdaptiveCardNodeTypes.QuestionAdaptiveCardNode) {
      return _createQuestionErrorMessages(dialogNode as QuestionAdaptiveCardNode);
    }

    if (dialogNode.type === AdaptiveCardNodeTypes.ActionAdaptiveCardNode) {
      return _createActionErrorMessages(dialogNode as ActionAdaptiveCardNode);
    }

    return null;
  };

  return <div className='error-messages-container'>{createErrorMessage()}</div>;
};

export default observer(AdaptiveCardErrorMessages);
