import { DialogNodeTypes } from '../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { BaseDialogNode } from './BaseDialogNode';
import { Redirectable } from './RedirectableNode';

class BaseRedirectDialogNode extends BaseDialogNode {
  type: DialogNodeTypes = DialogNodeTypes.RedirectDialogNode;
  constructor(block: DialogBlock) {
    super(block);
    this.title = 'Redirect Dialog Node';
  }
}

export class RedirectDialogNode extends Redirectable(BaseRedirectDialogNode) {}
