import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { UserConnector } from '../../../models/Connectors/UserConnector';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import Button from '../../common/Button';
import Input from '../../common/Input';

const UpdateProfile = () => {
  const { user } = useContext(AuthContext);

  const [givenName, setGivenName] = useState(
    (user?.idTokenClaims as any).given_name ?? ''
  );
  const [surname, setSurname] = useState((user?.idTokenClaims as any).family_name ?? '');
  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState<boolean | undefined>(undefined);

  const save = async () => {
    setStatus(undefined);
    setIsSaving(true);
    setStatus(await UserConnector.updateProfile(givenName, surname));
    setIsSaving(false);
  };

  const renderStatus = () => {
    if (status === undefined) return <></>;

    if (status) {
      return (
        <div className='status-message status-message-success'>
          Successfully updated profile. Changes will be visible after the next login.
        </div>
      );
    } else {
      return (
        <div className='status-message status-message-error'>
          An error occured while updating the profile.
        </div>
      );
    }
  };

  return (
    <div className='profile-page'>
      <div className='profile-page-content'>
        <h2 className='title'>Update Profile</h2>
        <div className='input-group next-gen'>
          <label htmlFor='given-name'>Given name</label>
          <Input
            id='given-name'
            placeholder='Given name'
            value={givenName}
            changeHandler={(value) => setGivenName(value)}
            disabled={isSaving}
          />
          <br />
          <label htmlFor='surname'>Surname</label>
          <Input
            id='surname'
            placeholder='Surname'
            value={surname}
            changeHandler={(value) => setSurname(value)}
            disabled={isSaving}
          />
          <div className='btn-container'>
            <Button
              content='Save'
              className='btn-primary btn-round'
              type='button'
              clickHandler={save}
              disabled={isSaving}
              isLoading={isSaving}
            />
          </div>
        </div>
        <div className='status-container'>{renderStatus()}</div>
      </div>
    </div>
  );
};

export default observer(UpdateProfile);
