import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatasetListProperty } from '../../../../../models/Dataset/Property/DatasetListProperty';
import { DatasetProperty } from '../../../../../models/Dataset/Property/DatasetProperty';
import { SimpleProperty } from '../../../../../models/Dataset/Property/SimpleProperty';

interface IProps {
  property: DatasetListProperty | DatasetProperty | SimpleProperty;
}

const Type: React.FC<IProps> = ({ property }) => {
  const getType = () => {
    if (property instanceof SimpleProperty) return property.type;
    if (property instanceof DatasetProperty) return property.dataset;
    if (property instanceof DatasetListProperty) return property.dataset + ' List';
  };
  return <>{getType()}</>;
};

export default observer(Type);
