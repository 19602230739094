export enum KnowledgebaseState {
  None = 0,
  QuestionsLoading = 1 << 0,
  SourcesLoading = 1 << 1,
  Initialized = 1 << 2,
}

export enum KnowledgebaseEntryState {
  UpToDate = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3,
}
