import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { UserAdminState } from '../../../architecture/enums/Admin';
import { Utilities } from '../../../models/Utilities/Utilities';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Modal from '../../common/modal/Modal';
import Confirm from '../../common/modal/templates/Confirm';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';
import NewPasswordModal from './NewPasswordModal';
import Roles from './components/Roles';

const validationSchema = Yup.object({
  userName: Yup.string().required('This field is required'),
  roles: Yup.array().min(1, 'You must select at least one role'),
});

const UserDetails: React.FC = () => {
  const getIcon = useGetIcon();
  const { userAdminStore } = useAdminStores();

  const [showConfirmPasswortReset, setShowConfirmPasswortReset] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const initialUserValues = {
    userName: userAdminStore.getSelectedUser?.displayName || '',
    email: userAdminStore.getSelectedUser?.email || '',
    id: userAdminStore.getSelectedUser?.id || '',
    roles: userAdminStore.getSelectedUser?.roles || [],
  };

  const formik = useFormik({
    initialValues: initialUserValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (userAdminStore.getSelectedUser?.id) {
        userAdminStore.update(userAdminStore.getSelectedUser.id, {
          roles: values.roles || [],
        });
      }
    },
  });

  const cancel = () => {
    userAdminStore.setSelectedUser(undefined);
  };

  useEffect(() => {
    formik.setValues(initialUserValues);
  }, [userAdminStore.getSelectedUser]);

  return (
    <div className='details-wrapper'>
      <div className='header'>
        <h4>{userAdminStore.getSelectedUser?.displayName}</h4>
      </div>
      {userAdminStore.getState(UserAdminState.UpdatingUserLoading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className='body'>
            <div className='input-group'>
              <label htmlFor='userName'>User Name</label>
              <Input
                id='userName'
                name='userName'
                disabled={true}
                placeholder='Type user name here...'
                value={formik.values.userName}
                changeHandler={(value) => formik.setFieldValue('userName', value)}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className='validation-error'>{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className='input-group'>
              <label htmlFor='email'>E-Mail</label>
              <Input
                id='email'
                name='email'
                disabled={true}
                placeholder='Type email address here...'
                value={formik.values.email}
                changeHandler={(value) => formik.setFieldValue('email', value)}
              />
              {formik.touched.userName && formik.errors.userName ? (
                <div className='validation-error'>{formik.errors.userName}</div>
              ) : null}
            </div>
            <div className='input-group'>
              <label htmlFor='creation-date'>Creation Date</label>
              <Input
                id='creation-date'
                disabled={true}
                value={Utilities.getLocalDate(
                  userAdminStore.getSelectedUser?.created,
                  'datetime'
                )}
                changeHandler={() => {}}
                actionIcon={getIcon('lock')}
              />
            </div>
            <div className='input-group'>
              <label htmlFor='user-id'>UserID</label>
              <Input
                id='user-id'
                disabled={true}
                value={formik.values.id}
                changeHandler={() => {}}
                actionIcon={getIcon('lock')}
              />
            </div>
            <div className='input-group'>
              <label htmlFor='role'>Roles:</label>
              <Roles
                selectedRoles={formik.values.roles || []}
                setSelectedRoles={(roles) =>
                  formik.setFieldValue('roles', roles as string[])
                }
              />
              {formik.touched.roles && formik.errors.roles ? (
                <div className='validation-error'>{formik.errors.roles}</div>
              ) : null}
            </div>
          </div>
          <div className='btn-container'>
            <Button
              type='submit'
              content='Save changes'
              className='btn-primary btn-round details-button'
            />
            <Button
              type='button'
              content='Reset password'
              className='btn-primary btn-round details-button'
              clickHandler={() => setShowConfirmPasswortReset(true)}
            />
            <Button
              content='Cancel'
              className='btn-inactive btn-round'
              clickHandler={cancel}
            />
          </div>
        </form>
      )}
      <Modal
        manuallyTriggered={showConfirmPasswortReset}
        closeHandler={() => {
          setShowConfirmPasswortReset(false);
        }}
      >
        <Confirm
          confirmationText="Do you want to reset the user's password?"
          onCancel={() => {
            setShowConfirmPasswortReset(false);
          }}
          onConfirmed={() => {
            setShowConfirmPasswortReset(false);
            setShowResetPasswordModal(true);
          }}
        />
      </Modal>
      <Modal
        manuallyTriggered={showResetPasswordModal}
        closeHandler={() => {
          setShowResetPasswordModal(false);
        }}
      >
        <NewPasswordModal onClose={() => setShowResetPasswordModal(false)} />
      </Modal>
    </div>
  );
};

export default observer(UserDetails);
