import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useLocation } from 'react-router-dom';
import { FILTERS } from '../../../../architecture/constants/ConversationHistory';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  className?: string;
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
}

const DateFilter: React.FC<IProps> = ({
  className,
  handleParamsChange,
  clearParamsChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const getIcon = useGetIcon();
  const params = queryString.parse(location.search);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event?.target as HTMLElement;
    if (ref?.current && !ref?.current?.contains(target)) {
      setOpen(false);
    }
  };

  const clear = () => {
    clearParamsChange(FILTERS.FROM_DATE.NAME);
    clearParamsChange(FILTERS.TO_DATE.NAME);
  };

  return (
    <div ref={ref}>
      <div className={`text-filter ${className}`} onClick={() => setOpen(!open)}>
        {FILTERS.DATE.TITLE}
        <i className={`${getIcon('down')} filter-dropdown-icon`}></i>
      </div>
      {open && (
        <div className='filter-container'>
          <div className='filter-row'>
            <div> From: </div>
            <DateTimePicker
              calendarIcon={<i className={getIcon('calendar')}> </i>}
              calendarClassName='date-filter'
              value={params.from ? new Date(params.from?.toString()) : undefined}
              disableClock={true}
              format='dd.MM.yyyy HH:mm'
              onChange={(date) =>
                handleParamsChange(
                  FILTERS.FROM_DATE.NAME,
                  format(date, "yyyy-MM-dd'T'HH:mm")
                )
              }
            />
          </div>
          <div className='filter-row'>
            <div> To: </div>
            <DateTimePicker
              calendarIcon={<i className={getIcon('calendar')}> </i>}
              calendarClassName='date-filter'
              value={params.to ? new Date(params.to?.toString()) : undefined}
              disableClock={true}
              format='dd.MM.yyyy HH:mm'
              onChange={(date) =>
                handleParamsChange(
                  FILTERS.TO_DATE.NAME,
                  format(date, "yyyy-MM-dd'T'HH:mm")
                )
              }
            />
          </div>
          <div className='clear'>
            <span onClick={() => clear()}>
              <i className={getIcon('close')}> </i> Clear
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(DateFilter);
