import React from 'react';
import { JsonContainer } from '../../../../../../models/Json/JsonContainer';
import { JsonElement } from '../../../../../../models/Json/JsonElement';
import {
  JsonValue,
  JsonValue as JsonValueModel,
} from '../../../../../../models/Json/JsonValue';
import Value from './Value';
import Key from './Key';

interface IProps {
  element: JsonValueModel;
  isKeyStored: boolean;
  isValueStored: boolean;
  handleKeyClick: () => void;
  handleValueClick: () => void;
}
const KeyValuePair: React.FC<IProps> = ({
  element,
  isKeyStored,
  isValueStored,
  handleKeyClick,
  handleValueClick,
}) => {
  const canStoreKeyArray = !!element.parentTree.find(
    (parent) =>
      (parent as JsonContainer).isArrayElement || !!element.nearestArrayContainerParent
  );

  const onKeyClick = () => {
    if (!canStoreKeyArray || !element.parent) return;

    handleKeyClick();
  };

  // If the key is a number the JsonValue element is a member of an array and therefore the keys are not to be displayed
  const shouldRenderKey =
    typeof element.name !== 'number' && isNaN(parseInt(element.name.toString(), 10));

  return (
    <div className='json-prop-row'>
      {shouldRenderKey && (
        <Key
          title={element.name}
          canStoreKeyArray={canStoreKeyArray}
          isStored={isKeyStored}
          onKeyClick={onKeyClick}
        />
      )}
      <Value
        isStored={isValueStored}
        value={element.value}
        clickHandler={handleValueClick}
      />
    </div>
  );
};

// Re-render only if the "isValueStored" or "isKeyStored" prop changes
export default React.memo(
  KeyValuePair,
  (prevProps, nextProps) =>
    prevProps.isValueStored === nextProps.isValueStored &&
    prevProps.isKeyStored === nextProps.isKeyStored
);
