export enum PropertyType {
  Simple = 'Simple',
  Dataset = 'Dataset',
  ListDataset = 'DatasetList',
}

export enum PropertyState {
  UpToDate = 'UpToDate',
  Deleted = 'Deleted',
  Added = 'Added',
}

export enum DatasetStatus {
  Used = 'Used',
  Unused = 'Unused',
  Error = 'Error',
  New = 'New',
}

export enum DatasetListAction {
  Add = 'Add',
  Set = 'Set',
}
