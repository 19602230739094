import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { PronunciationState } from '../architecture/enums/PronunciationState';
import type {
  IPronunciation,
  IUpdatePronunciationsRequest,
} from '../architecture/interfaces/HTTP/PronunciationParams';
import { PronunciationConnector } from '../models/Connectors/PronunciationConnector';
import { Pronunciation } from '../models/Subscription/Pronunciation';
import { Notification } from '../models/Utilities/Notification';
import { RootStore } from './rootStore';

export default class PronunciationStore {
  private static instance: PronunciationStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    PronunciationStore.instance = this;
  }

  @observable
  _language?: string = undefined;

  @observable
  _pronunciationRepository: Pronunciation[] = [];

  @observable
  state: PronunciationState = PronunciationState.None;

  @observable
  pagination = {
    totalPages: 0,
    page: 0,
    pageSize: 25,
  };

  @computed
  get language() {
    return this._language;
  }

  @computed
  get currentPageEntries() {
    const start = this.pagination.page * this.pagination.pageSize;
    const end = (this.pagination.page + 1) * this.pagination.pageSize;
    return this._pronunciationRepository.slice(start, end);
  }

  @computed
  getState(state: PronunciationState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: PronunciationState) {
    this.state |= state;
  }

  @computed
  removeState(state: PronunciationState) {
    this.state &= ~state;
  }

  @computed
  get isInErrorState(): boolean {
    // Text or SSML may not be empty
    if (
      this._pronunciationRepository.some(
        (pronuciaton) => !pronuciaton.text || !pronuciaton.ssml
      )
    )
      return true;

    // Texts may not be identical (this does not apply to SSML)
    const texts = this._pronunciationRepository.map((item) => item.text!.toLowerCase());
    const uniqueTexts = new Set(texts);
    return uniqueTexts.size !== texts.length;
  }

  @action
  async load(language: string, force: boolean = false) {
    try {
      if (
        !this.getState(PronunciationState.Initialized) ||
        force ||
        this._language !== language
      ) {
        this._language = language;

        this.removeState(PronunciationState.Initialized);
        this.setState(PronunciationState.Loading);

        const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
        if (!subscriptionId) {
          return;
        }
        const response = await PronunciationConnector.get(subscriptionId, language);
        runInAction(() => this.restore(response));

        this.pagination.totalPages = Math.ceil(
          this._pronunciationRepository.length / this.pagination.pageSize
        );
        this.pagination.page = 0;

        this.removeState(PronunciationState.Loading);
        this.setState(PronunciationState.Initialized);
      }
    } catch (error) {
      this.removeState(PronunciationState.Loading);
    }
  }

  @action
  async save() {
    try {
      if (!this.getState(PronunciationState.Initialized)) {
        return;
      }

      if (!this._language) return;

      this.setState(PronunciationState.Saving);
      const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
      if (!subscriptionId) {
        return;
      }

      const request = this.createUpdateRequest();
      if (
        request.added.length === 0 &&
        request.updated.length === 0 &&
        request.deleted.length === 0
      ) {
        new Notification({ text: 'No changes to save', type: 'warning' });
        return;
      }

      const response = await PronunciationConnector.udpate(
        subscriptionId,
        this._language,
        request
      );
      runInAction(() => this.restore(response));

      this.removeState(PronunciationState.Saving);
    } catch (error) {
      this.removeState(PronunciationState.Loading);
    }
  }

  @action
  discardChanges() {
    if (!this._pronunciationRepository) return;

    this._pronunciationRepository = this._pronunciationRepository.filter(
      (entry) => !entry.isAdded
    );

    this._pronunciationRepository.map((entry) => entry.discard());
  }

  @action
  unloadStore() {
    this.purge();
    this.removeState(PronunciationState.Initialized);
    this.pagination = {
      totalPages: 0,
      page: 0,
      pageSize: 25,
    };
  }

  @action
  addEntry() {
    this._pronunciationRepository.unshift(new Pronunciation());
  }

  @action
  removeItem(id: string) {
    const item = this._pronunciationRepository.find((item) => item.id === id);
    if (!item) return;

    if (!item.isAdded) {
      item.isDeleted = true;
    } else {
      this._pronunciationRepository = this._pronunciationRepository.filter(
        (item) => item.id !== id
      );
    }
  }

  @action
  restore(items: IPronunciation[]) {
    this.purge();
    items.forEach((item) => {
      const instance = plainToInstance(Pronunciation, item);
      this._pronunciationRepository.push(instance);
    });
  }

  @action
  purge() {
    this._pronunciationRepository = [];
  }

  @computed
  private createUpdateRequest(): IUpdatePronunciationsRequest {
    return {
      added: this._pronunciationRepository
        .filter((entry) => entry.isAdded)
        .map((entry) => {
          return { text: entry.text!, ssml: entry.ssml! };
        }),
      deleted: this._pronunciationRepository
        .filter((entry) => entry.isDeleted)
        .map((entry) => entry.id!),
      updated: this._pronunciationRepository.filter((entry) => {
        return (
          !entry.isAdded &&
          !entry.isDeleted &&
          (entry.ssmlIsModified() || entry.textIsModified())
        );
      }),
    };
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('DatasetStore instance has not been initialized.');
    }

    return this.instance;
  }
}
