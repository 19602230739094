import React, { useReducer } from 'react';
import * as actions from '../actionTypes';
import NodeConfigurationContext from './NodeConfigurationContext';
import { nodeConfigurationReducer } from './NodeConfigurationReducer';

export const NodeConfigurationState = (props: any) => {
  const initialState = {
    showDetails: false,
  };

  const [state, dispatch] = useReducer(nodeConfigurationReducer, initialState);

  const setShowDetails = (show?: boolean) => {
    dispatch({
      type: actions.SET_SHOW_DETAILS,
      payload: show !== undefined ? show : !state.showDetails,
    });
  };

  return (
    <NodeConfigurationContext.Provider
      value={{
        ...state,
        setShowDetails,
      }}
    >
      {props.children}
    </NodeConfigurationContext.Provider>
  );
};
