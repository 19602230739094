import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import msal_client_instance from '../init';
import AuthState from './AuthState';

const AuthProvider = (props: any) => {
  return (
    <MsalProvider instance={msal_client_instance}>
      <AuthState>{props.children}</AuthState>
    </MsalProvider>
  );
};

export default AuthProvider;
