import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import { IContextVariable } from '../../../../../architecture/interfaces/contextVariables/IContextVariable';
import rootStore from '../../../../../stores/rootStore';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import Select from '../Select';

interface IProps {
  disabled?: boolean;
  items: IContextVariable[];
  triggerLabel: string;
  closeOnSelect?: boolean;
  creator?: boolean;
  clear?: boolean;
  selectHandler: (name: string) => void;
  [key: string]: any;
}
const ContextVariableSelector: React.FC<IProps> = ({
  disabled,
  items,
  triggerLabel,
  creator = false,
  clear = false,
  closeOnSelect = true,
  selectHandler,
  ...rest
}) => {
  const {
    uiStore: { colors },
  } = useContext(rootStore);

  const getIcon = useGetIcon();

  const getSettings = (
    type: ContextVariableInstanceTypes
  ): { icon: string; color: string } => {
    switch (type) {
      case ContextVariableInstanceTypes.ListContextVariable: {
        return {
          icon: getIcon('list'),
          color: colors['success'],
        };
      }
      case ContextVariableInstanceTypes.SystemContextVariable: {
        return {
          icon: getIcon('config'),
          color: colors['info'],
        };
      }
      case ContextVariableInstanceTypes.ContextVariable:
      default: {
        return {
          icon: getIcon('user'),
          color: colors['secondary'],
        };
      }
    }
  };

  return (
    <Select
      disabled={disabled ? disabled : false}
      items={items.map((ctx) => ({
        title: ctx.name,
        id: ctx.id,
        settings: {
          icon: getSettings(ctx.instanceType).icon,
          color: getSettings(ctx.instanceType).color,
        },
      }))}
      selectHandler={selectHandler}
      search={creator ? false : true}
      creator={creator}
      clear={clear}
      replaceSpecialCharacters
      closeOnSelect={closeOnSelect}
      triggerLabel={triggerLabel}
      {...rest}
    />
  );
};

export default observer(ContextVariableSelector);
