import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { Condition as ConditionModel } from '../../../../models/Conditions/Condition';
import { ContextCondition } from '../../../../models/Conditions/ContextCondition';
import { ListContextVariable } from '../../../../models/ContextVariables/ContextVariable';
import { Redirect as RedirectModel } from '../../../../models/Redirects/Redirect';
import rootStore from '../../../../stores/rootStore';
import ConditionContainer from '../conditions/ConditionContainer';
import RedirectDatalist from './RedirectDatalist';

interface IProps {
  redirect: RedirectModel;
  noConditions?: boolean;
}

const Redirect: React.FC<IProps> = ({ redirect, noConditions }) => {
  const { ctxVarStore } = useContext(rootStore);

  const addNewCondition = () => {
    redirect.addCondition(
      new ContextCondition(ctxVarStore.userAndSystemVariables[0], 'IsSet')
    );
  };

  const handleRemoveCondition = (condition: ConditionModel) => {
    redirect.removeCondition(condition);
  };

  const canAddCondition = redirect.conditions.length === 0 && !!redirect.targetBlock;

  return (
    <Fragment>
      <div className='condition-settings-block'>
        {/* Target Block Selector */}
        <RedirectDatalist redirect={redirect} />
      </div>
      {/* Condition Container with Rows [Condition Settings][RemoveBTN] */}
      {!noConditions && (
        <ConditionContainer
          conditions={redirect.conditions}
          canAddCondition={canAddCondition}
          addNewConditionHandler={addNewCondition}
          removeHandler={handleRemoveCondition}
        />
      )}
    </Fragment>
  );
};

export default observer(Redirect);
