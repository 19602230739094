import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { Utilities } from '../Utilities/Utilities';
import { ISerializedTrigger, Trigger } from './Trigger';

export class RegexInputTrigger extends Trigger {
  @Expose()
  pattern: string;
  constructor() {
    super();
    this.pattern = '';
    this.type = TriggerTypes.RegexInput;

    makeObservable(this, {
      pattern: observable,
    });
  }

  get isValid() {
    return !Utilities.isEmpty(this.pattern);
  }

  serialize(): ISerializedRegexInputTrigger {
    return {
      ...super.serialize(),
      pattern: this.pattern,
    };
  }
}

export interface ISerializedRegexInputTrigger extends ISerializedTrigger {
  pattern: string;
}
