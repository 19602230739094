import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { EntityDefinition } from '../../../../../../models/DialogNodes/EntityNode/EntityDefinition';
import Button from '../../../../../common/Button';
import Input from '../../../../../common/Input';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';
import Entity from './entity/Entity';

interface IProps {
  entityDefinition: EntityDefinition;
  removeEntityDefinition: (entityDefinition: EntityDefinition) => void;
  entities: string[];
  addedEntityMapping?: (previousEntityName: string, newEntityName: string) => void;
  deletedEntityMapping?: (entity: string) => void;
  allowNewEntities?: boolean;
}

const Example: React.FC<IProps> = ({
  entityDefinition,
  removeEntityDefinition,
  entities,
  addedEntityMapping,
  deletedEntityMapping,
  allowNewEntities = true,
}) => {
  const getIcon = useGetIcon();

  useEffect(() => {
    if (entityDefinition.entities.length === 0) {
      addNewEntity();
    }
  }, [entityDefinition.entities]);

  const addNewEntity = () => {
    entityDefinition.addEntity();
  };

  const renderEntities = () => {
    return entityDefinition.entities.map((entity, index) => {
      return (
        <Entity
          key={index}
          entity={entity}
          entityDefinition={entityDefinition}
          index={index}
          entities={entities}
          addedEntityMapping={addedEntityMapping}
          deletedEntityMapping={deletedEntityMapping}
          allowNewEntities={allowNewEntities}
        />
      );
    });
  };

  return (
    <div className='example'>
      <div className='flex-wrapper'>
        <Input
          value={entityDefinition.text}
          textAlign='left'
          autoFocus={true}
          placeholder={'Insert a new example...'}
          changeHandler={(value) => (entityDefinition.text = value)}
        />
        <Button
          className={'btn-round btn-borderless'}
          icon={getIcon('remove')}
          clickHandler={() => removeEntityDefinition(entityDefinition)}
        />
      </div>
      <hr></hr>
      {renderEntities()}
      {entityDefinition.entities.length === 0 && (
        <Button
          className={'btn-round btn-borderless'}
          icon={getIcon('add_alt')}
          clickHandler={addNewEntity}
        />
      )}
    </div>
  );
};

export default observer(Example);
