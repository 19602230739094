import { Exclude, Expose, Transform } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { IPronunciation } from '../../architecture/interfaces/HTTP/PronunciationParams';

export class Pronunciation implements IPronunciation {
  @Expose()
  id: string;

  @Expose()
  created: Date | null = new Date();

  @Expose()
  text: string | null = null;

  @Expose()
  ssml: string | null = null;

  @Expose()
  @Transform(({ obj }) => obj['text'])
  originalText: string | null = null;

  @Expose()
  @Transform(({ obj }) => obj['ssml'])
  originalSsml: string | null = null;

  @Exclude()
  isDeleted: boolean = false;

  @Expose()
  @Transform(({ obj }) => !obj['created'])
  isAdded: boolean = false;

  constructor() {
    this.id = v4();
    this.isAdded = true;

    makeObservable(this, {
      id: observable,
      text: observable,
      ssml: observable,
      originalSsml: observable,
      originalText: observable,
      isDeleted: observable,
      isAdded: observable,
    });
  }

  discard() {
    if (this.originalText) {
      this.text = this.originalText;
    }

    if (this.originalSsml) {
      this.ssml = this.originalSsml;
    }

    this.isDeleted = false;
  }

  textIsModified(): boolean {
    return this.text !== this.originalText;
  }

  ssmlIsModified(): boolean {
    return this.ssml !== this.originalSsml;
  }
}
