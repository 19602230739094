import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { PROMPT_NODE_TITLES } from '../../../../architecture/constants/ConversationHistory';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { PromptNodeTypes } from '../../../../architecture/enums/HistoryElementType';
import { PromptNodeElement as PromptNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/PromptNodeElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: PromptNodeElementModel;
}

const PromptNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const { uiStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const getPromptTitle = () => {
    switch (element?.promptType) {
      case PromptNodeTypes.TextPromptNode:
        return PROMPT_NODE_TITLES.TextPromptNode;
      case PromptNodeTypes.ConfirmPromptNode:
        return PROMPT_NODE_TITLES.ConfirmPromptNode;
      case PromptNodeTypes.NumberPromptNode:
        return PROMPT_NODE_TITLES.NumberPromptNode;
      case PromptNodeTypes.DateTimePromptNode:
        return PROMPT_NODE_TITLES.DateTimePromptNode;
      case PromptNodeTypes.ChoicePromptNode:
        return PROMPT_NODE_TITLES.ChoicePromptNode;
      case PromptNodeTypes.AddressPromptNode:
        return PROMPT_NODE_TITLES.AddressPromptNode;
      case PromptNodeTypes.NamePromptNode:
        return PROMPT_NODE_TITLES.NamePromptNode;
      case PromptNodeTypes.EmailPromptNode:
        return PROMPT_NODE_TITLES.EmailPromptNode;
      case PromptNodeTypes.PhonePromptNode:
        return PROMPT_NODE_TITLES.PhonePromptNode;
      case PromptNodeTypes.EntityPromptNode:
        return PROMPT_NODE_TITLES.EntityPromptNode;
      case PromptNodeTypes.AttachmentPromptNode:
        return PROMPT_NODE_TITLES.AttachmentPromptNode;
      default:
        break;
    }
  };

  const getElementIcon = (): string => {
    switch (element?.promptType) {
      case PromptNodeTypes.TextPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.TextQuestionNode];
      case PromptNodeTypes.ConfirmPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.DecisiveQuestionNode];
      case PromptNodeTypes.NumberPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.NumberQuestionNode];
      case PromptNodeTypes.DateTimePromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.DateTimeQuestionNode];
      case PromptNodeTypes.ChoicePromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.ChoiceQuestionNode];
      case PromptNodeTypes.AddressPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.AddressQuestionNode];
      case PromptNodeTypes.NamePromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.NameQuestionNode];
      case PromptNodeTypes.EmailPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.EmailQuestionNode];
      case PromptNodeTypes.PhonePromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.PhoneQuestionNode];
      case PromptNodeTypes.EntityPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.EntityQuestionNode];
      case PromptNodeTypes.AttachmentPromptNode:
        return uiStore.nodeIcons[DialogNodeTypes.AttachmentQuestionNode];
      default:
        return getIcon('question');
    }
  };

  return (
    <BaseHistoryElement
      element={element}
      title={getPromptTitle()}
      icon={getElementIcon()}
    >
      <div className='card-paragraph'>
        <i className={getIcon('double_left')}></i>
        <span className='card-paragraph-text'>{element.text}</span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(PromptNodeElement);
