import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  billingClicked: () => void;
  contingentClicked: () => void;
}

const BillingDetailsSelector = ({ billingClicked, contingentClicked }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <div className='billing-selector-container'>
      <div className='billing-card' onClick={billingClicked}>
        <div className='billing-card-icon'>
          <i className={getIcon('receipt')}></i>
        </div>
        <span className='billing-card-text'>Billing</span>
      </div>
      <div className='billing-card' onClick={contingentClicked}>
        <div className='billing-card-icon'>
          <i className={getIcon('coins')}></i>
        </div>
        <span className='billing-card-text'>Contingent</span>
      </div>
    </div>
  );
};

export default observer(BillingDetailsSelector);
