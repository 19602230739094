import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { MicrosoftTeamsEventTarget as MicrosoftTeamsEventTargetModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/MicrosoftTeamsEventTarget';
import { ChannelReceiver as ChannelReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/ChannelReceiver';
import { UserReceiver as UserReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/UserReceiver';
import Select from '../../../../../common/inputElements/select/Select';
import { useReadOnly } from '../../../../../customHooks/useReadOnly';
import Receiver from './Receiver';

interface IProps {
  target: MicrosoftTeamsEventTargetModel;
}
const MicrosoftTeamsEventTarget: React.FC<IProps> = ({ target }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const handleSelectReceiver = (name: string) => {
    switch (name) {
      case EventTargetReceiverTypes.Channel:
        target.receiver = new ChannelReceiverModel();
        break;
      case EventTargetReceiverTypes.User:
        target.receiver = new UserReceiverModel();
        break;
    }
  };

  const receiverLabel = (() => {
    if (target.receiver instanceof UserReceiverModel) return 'User';
    if (target.receiver instanceof ChannelReceiverModel) return 'Channel';

    return 'Select a receiver';
  })();

  return (
    <Fragment>
      <h3>Receiver Type</h3>
      <Select
        disabled={readOnly}
        items={target.supportedReceivers.map((item) => ({
          title: item,
          id: item,
        }))}
        triggerLabel={receiverLabel}
        selectHandler={handleSelectReceiver}
        closeOnSelect
        fullWidth
      />

      <Receiver receiver={target.receiver} />
    </Fragment>
  );
};

export default observer(MicrosoftTeamsEventTarget);
