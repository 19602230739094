import { observer } from 'mobx-react-lite';
import React from 'react';
import { Fragment } from 'react';
import { IContextReassign } from '../../../../../../architecture/interfaces/contextVariables/IContextActions';
import Button from '../../../../../common/Button';
import ParagraphWithCtx from '../../../../../common/ParagraphWithCtx';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';

interface IProps {
  item: IContextReassign;
  isManuallyAdded: boolean;
  openEditor: () => void;
}

const Reassignment: React.FC<IProps> = ({ item, isManuallyAdded, openEditor }) => {
  const getIcon = useGetIcon();

  return (
    <Fragment>
      <div className='item-title-group' onClick={openEditor}>
        <Button
          icon={getIcon('edit_alt')}
          className='ctx-list-item-icon btn-borderless'
          data-state='toBeReassigned'
          tooltip='Edit new value'
        />
        <span>
          <strong>{item.ctx.name}</strong>
          {isManuallyAdded && (
            <Button
              className='manually-added-ctx-icon'
              icon={getIcon('add_alt')}
              tooltip='This context variable was created manually'
              tooltipPosition='top'
            />
          )}
        </span>
      </div>
      <div className='ctx-list-item-details'>
        <p>
          The value of <strong>{item.ctx.name}</strong> will be reassigned to:
        </p>
        <ParagraphWithCtx text={item.value} />
      </div>
    </Fragment>
  );
};

export default observer(Reassignment);
