import React, { CSSProperties, Fragment } from 'react';

interface IProps {
  autofocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  value: string;
  name?: string;
  id?: string;
  allowInlineTab?: boolean;
  rows?: number;
  className?: string;
  style?: CSSProperties;
  textAlign?: 'center' | 'left' | 'right';
  inError?: boolean;
  blurHandler?: () => void;
  changeHandler: (text: string) => void;
  keyDownHandler?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  mouseDownHandler?: (event: React.MouseEvent) => void;
  mouseUpHandler?: (event: React.MouseEvent) => void;
  focusHandler?: (event: React.FocusEvent) => void;
  pasteHandler?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  darkMode?: boolean;
}
const Textarea = React.forwardRef<HTMLTextAreaElement, IProps>(
  (
    {
      autofocus = false,
      disabled = false,
      value,
      placeholder,
      name,
      id,
      className,
      style,
      inError = false,
      changeHandler,
      keyDownHandler,
      blurHandler,
      mouseDownHandler,
      mouseUpHandler,
      focusHandler,
      pasteHandler,
      rows = 2,
      textAlign = 'left',
      allowInlineTab = false,
      darkMode = false,
    },
    ref
  ) => {
    const handleBlur = () => blurHandler && blurHandler();

    const checkKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Tab') {
        if (allowInlineTab) {
          _insertAtSelection('\t', event);
        }
      }

      if (event.key === 'Enter') {
        _insertAtSelection('\n', event);
      }

      keyDownHandler && keyDownHandler(event);
    };

    const _insertAtSelection = (
      text: string,
      event: React.KeyboardEvent<HTMLTextAreaElement>
    ) => {
      event.preventDefault();
      const start = event.currentTarget.selectionStart;
      const end = event.currentTarget.selectionEnd;

      // set textarea value to: text before caret + text param + text after caret
      event.currentTarget.value =
        event.currentTarget.value.substring(0, start) +
        text +
        event.currentTarget.value.substring(end);

      // put caret at right position again
      event.currentTarget.selectionStart = event.currentTarget.selectionEnd = start + 1;
    };

    return (
      <Fragment>
        <textarea
          ref={ref}
          autoFocus={autofocus}
          placeholder={placeholder}
          data-error={inError}
          disabled={disabled}
          id={id ? id : name}
          value={value}
          name={name}
          spellCheck='false'
          className={'dialog-node-input' + (className ? ' ' + className : '')}
          rows={rows}
          style={{ textAlign: textAlign, ...style }}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            changeHandler(event.currentTarget.value)
          }
          onFocus={focusHandler}
          onBlur={handleBlur}
          onPaste={pasteHandler}
          onKeyDown={checkKeyDown}
          onMouseDown={mouseDownHandler}
          onMouseUp={mouseUpHandler}
          data-dark-mode={darkMode.toString()}
        ></textarea>
      </Fragment>
    );
  }
);

export default Textarea;
