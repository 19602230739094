import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState } from 'react';
import { HttpRequestType } from '../../../../../architecture/enums/RequestType';
import NodeConfigurationContext from '../../../../../contexts/communicationConfig/NodeConfigurationContext';
import { ApiActionNode as ApiNodeModel } from '../../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { InvalidArgumentError } from '../../../../../models/errors/InvalidArgumentError';
import Button from '../../../../common/Button';
import Icon from '../../../../common/Icon';
import InfoIcon from '../../../../common/InfoIcon';
import TextBoxWithCtx from '../../../../common/inputElements/TextBoxWithCtx';
import Select from '../../../../common/inputElements/select/Select';
import Modal from '../../../../common/modal/Modal';
import Tab from '../../../../common/tabElements/Tab';
import TabBody from '../../../../common/tabElements/TabBody';
import TabContainer from '../../../../common/tabElements/TabContainer';
import TabHeader from '../../../../common/tabElements/TabHeader';
import useDialogAttributeHandler from '../../../../customHooks/useAttributeChangeHandler';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import DialogNode from '../../DialogNode';
import RedirectContainer from '../../redirects/RedirectContainer';
import HeaderTab from './HeaderTab';
import InsertJsonHandler from './InsertJsonHandler';
import PlaceholderTab from './PlaceholderTab';
import ResponseTab from './ResponseTab';

interface IProps {
  dialogNode: ApiNodeModel;
}

type TabLabels =
  | 'Headers'
  | 'Body'
  | 'Response'
  | 'Placeholder'
  | 'Error Handling'
  | 'JSON';

type TabSettings = {
  icon: string;
  text: TabLabels;
  hidden: boolean;
  disabled: boolean;
};

type ConfirmationModal = {
  open: boolean;
  callback: null | (() => void);
};

const ApiActionNode: React.FC<IProps> = ({ dialogNode }) => {
  const { showDetails, setShowDetails } = useContext(NodeConfigurationContext);

  const getIcon = useGetIcon();

  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>({
    open: false,
    callback: null,
  });

  const updateURL = useDialogAttributeHandler<ApiNodeModel>(dialogNode, 'url');
  const updateBody = useDialogAttributeHandler<ApiNodeModel>(dialogNode, 'body');
  const updateRequestType = useDialogAttributeHandler<ApiNodeModel>(
    dialogNode,
    'httpRequestType'
  );

  const handleRequestTypeChange = (value: HttpRequestType) => {
    updateRequestType(value);
    if (dialogNode.httpRequestType === HttpRequestType.GET && activeTab.text === 'Body') {
      setActiveTab(tabs[0]);
    }
  };

  const tabs: TabSettings[] = [
    {
      icon: getIcon('list'),
      text: 'Headers',
      disabled: false,
      hidden: false,
    },
    {
      icon: getIcon('body'),
      text: 'Body',
      disabled: dialogNode.httpRequestType === HttpRequestType.GET,
      hidden: false,
    },
    {
      icon: getIcon('code'),
      text: 'JSON',
      disabled: false,
      hidden: true,
    },
    {
      icon: getIcon('edit'),
      text: 'Response',
      disabled: false,
      hidden: false,
    },
    {
      icon: getIcon('error'),
      text: 'Error Handling',
      disabled: false,
      hidden: false,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const renderTabBody = (tabText: TabLabels) => {
    switch (tabText) {
      case 'Headers':
        return <HeaderTab dialogNode={dialogNode} />;
      case 'Body':
        return (
          <TextBoxWithCtx
            title='Body'
            value={dialogNode.body}
            saveHandler={updateBody}
            placeholder='Enter an HTTP Request body as JSON'
            multiline
            // validator={() => Utilities.isJsonValid(dialogNode.body)}
          />
        );
      case 'Response':
        return (
          <ResponseTab
            dialogNode={dialogNode}
            closeHandler={() => setActiveTab(tabs[0])}
          />
        );
      case 'Error Handling':
        return <RedirectContainer dialogNode={dialogNode} noConditions singleRedirect />;
      case 'JSON':
        return (
          <InsertJsonHandler
            dialogNode={dialogNode}
            switchToRespnseView={() => changeTab('Response')}
            closeHandler={() => changeTab('Headers')}
            handlePaste={(text: string) =>
              handleJsonChange(text, () => executePaste(text))
            }
            clearHandler={() => handleJsonChange(undefined, () => executeClear())}
          />
        );
      default:
        throw new InvalidArgumentError('Invalid Tab Header');
    }
  };

  const handleCreateRequest = () => {
    if (!dialogNode.isValid) return;

    if (dialogNode.apiContextVariables.size > 0) {
      setConfirmationModal({ open: true, callback: executeRequest });
    } else {
      executeRequest();
    }
  };

  const executeRequest = () => {
    dialogNode.createRequest().then((res) => {
      resetModal();
      if (!res.error) {
        setShowDetails(true);
        changeTab('Response');
      }
    });
  };

  const changeTab = (tabName: TabLabels) => {
    if (!showDetails) setShowDetails(true);
    setActiveTab(tabs.find((tab) => tab.text === tabName)!);
  };

  const handleJsonChange = (text?: string, callback?: (text?: string) => void) => {
    if (dialogNode.apiContextVariables.size > 0) {
      setConfirmationModal({
        open: true,
        callback: () => callback?.(text),
      });
    } else {
      callback?.(text);
    }
  };

  const executePaste = (text: string) => {
    dialogNode.pasteResponse(text);
    resetModal();
  };

  const executeClear = () => {
    dialogNode.clearResponse();
    resetModal();
  };

  const resetModal = () => {
    setConfirmationModal({ open: false, callback: null });
  };

  const [slideMultiplier, setSlideMultiplier] = useState(0);

  return (
    <Fragment>
      <DialogNode dialogNode={dialogNode}>
        <div data-show-details={showDetails}>
          <h4 style={{ marginBottom: '6px' }}>Set an HTTP Request Type and a URL</h4>
          <div className='http-selection-container'>
            <Select
              items={Object.keys(HttpRequestType).map((item) => ({ title: item }))}
              closeOnSelect
              triggerLabel={dialogNode.httpRequestType}
              selectHandler={(text: string) =>
                handleRequestTypeChange(text as HttpRequestType)
              }
            />
            <TextBoxWithCtx
              value={dialogNode.url || ''}
              saveHandler={updateURL}
              placeholder='Enter a URL'
            />
          </div>
          <div className='response-creator-container'>
            {/* TODO: Create a separate, generic Slider component If we need the it in another place  */}
            <div
              className='slider'
              style={{ transform: `translateX(${slideMultiplier * 100 * -1}%)` }}
            >
              <div className='stage stage-0'>
                <div className='creator-card' onClick={() => changeTab('JSON')}>
                  <h3 className='title'>
                    <Icon icon={getIcon('code')} />
                    <span>Insert JSON</span>
                  </h3>
                  <div className='body'>
                    If you know how the response looks like you can copy-paste an example
                    of it in JSON format
                  </div>
                </div>
                <div
                  className='creator-card'
                  data-disabled={!dialogNode.isValid}
                  onClick={
                    dialogNode.dependsOnContextVariables
                      ? () => setSlideMultiplier(1)
                      : handleCreateRequest
                  }
                >
                  <h3 className='title'>
                    <Button
                      className='btn-borderless icon'
                      name={dialogNode.id}
                      icon={getIcon('robot')}
                    />
                    <span>HTTP Request</span>
                  </h3>
                  <div className='body'>
                    Execute an HTTP Request on the provided API endpoint and check out the
                    result right away
                  </div>
                </div>
              </div>
              <div className='stage stage-1'>
                <Button
                  content='Back'
                  className='btn-borderless btn-absolute-left-top'
                  style={{ left: 0, top: '15px', fontWeight: 600 }}
                  icon={getIcon('back')}
                  clickHandler={() => setSlideMultiplier((current) => current - 1)}
                />
                <h3>Are your configurations correct?</h3>
                <Button
                  reverseContent
                  name={dialogNode.id}
                  icon={getIcon('send')}
                  className='btn-round btn-primary execute-request-btn'
                  content='Send Request'
                  clickHandler={handleCreateRequest}
                />
                <PlaceholderTab dialogNode={dialogNode} />
              </div>
            </div>
          </div>
          <TabContainer>
            <TabHeader>
              {tabs.map((tab) => (
                <Tab
                  key={tab.text}
                  icon={tab.icon}
                  text={tab.text}
                  disabled={tab.disabled}
                  hidden={tab.hidden}
                  isActive={activeTab.text === tab.text}
                  onClick={() => setActiveTab(tab)}
                />
              ))}
            </TabHeader>
            <TabBody>{renderTabBody(activeTab.text)}</TabBody>
          </TabContainer>
        </div>
      </DialogNode>

      <Modal manuallyTriggered={confirmationModal.open} closeHandler={resetModal}>
        <div className='modal-default api-request-confirm-window'>
          <div className='header'>
            <InfoIcon type='warning' />
            <h4>All stored Api Context Variables will be erased.</h4>
            <h4>Are you sure to continue?</h4>
          </div>
          <div className='btn-container'>
            <Button
              name={dialogNode.id}
              icon={getIcon('check')}
              content='Continue'
              className='btn-dark btn-round'
              clickHandler={() => confirmationModal.callback?.()}
            />
            <Button
              icon={getIcon('close')}
              content='Cancel'
              className='btn-primary btn-round'
              clickHandler={resetModal}
            />
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default observer(ApiActionNode);
