import { observable, makeObservable } from 'mobx';
import { AuxiliaryHistoryElement } from './AuxiliaryHistoryElement';

export class SystemMessageElement extends AuxiliaryHistoryElement {
  text: string;

  constructor() {
    super();
    this.text = '';

    makeObservable(this, {
      text: observable,
    });
  }
}
