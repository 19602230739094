import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import {
  ContextActions,
  IContextFunction,
  IContextReassign,
} from '../../../../../architecture/interfaces/contextVariables/IContextActions';
import { ContextVariable } from '../../../../../models/ContextVariables/ContextVariable';
import { ContextActionNode as ContextActionNodeModel } from '../../../../../models/DialogNodes/ActionNodes/ContextActionNode';
import { Notification } from '../../../../../models/Utilities/Notification';
import { Utilities } from '../../../../../models/Utilities/Utilities';
import rootStore from '../../../../../stores/rootStore';
import Button from '../../../../common/Button';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import Modal from '../../../../common/modal/Modal';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import DialogNode from '../../DialogNode';
import ContextActionItem from './ContextActionItem';
import Deleted from './items/Deleted';
import FunctionExecution from './items/FunctionExecution';
import Pending from './items/Pending';
import Reassignment from './items/Reassignment';
import { ContextActionModal } from './modals';

interface SelectedContextVariable {
  ctx: ContextVariable | null;
  category: ContextActions | 'Pending';
}
interface IProps {
  dialogNode: ContextActionNodeModel;
}
const ContextActionNode: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const getIcon = useGetIcon();

  const [touchedCtxList, setTouchedCtxList] = useState<ContextVariable[]>([
    ...dialogNode.createdVariables,
    ...dialogNode.toBeDeletedContextVariables,
    ...dialogNode.toBeReassignedContextVariables,
    ...dialogNode.toBeCalculatedContextVariables,
  ]);

  // Sorting the touched list to position manually craeted variables on top and the rest below
  const sortedList = React.useMemo(() => {
    type Accumulator = { manual: ContextVariable[]; global: ContextVariable[] };

    const accumulator: Accumulator = { manual: [], global: [] };

    const separated = touchedCtxList.reduce((acc: Accumulator, item: ContextVariable) => {
      dialogNode.createdVariables.includes(item)
        ? acc['manual'].push(item)
        : acc['global'].push(item);

      return acc;
    }, accumulator);

    // Removing duplicates: both createdVariables and any of the action arrays can contain the same CTX
    return [...new Set([...separated.manual, ...separated.global])];
  }, [touchedCtxList]);

  useEffect(() => {
    // Touched-Ctx-List can get out-of-sync if a Context Variable is removed from
    // the delete- or reassign array of the dialog node VIA its Reaction
    // Because it means that the Context Variable does not exist in the Store anymore
    const outOfSyncItems = touchedCtxList.filter(
      (item) => !ctxVarStore.userVariables.includes(item)
    );
    const isTouchedListOutOfSync = !Utilities.isEmpty(outOfSyncItems);

    if (isTouchedListOutOfSync) {
      // To keep the Context Variables that are currently in the temporary zone
      //    --> not yet in the dialognode's delete- or reassign array but already in the touched list
      // we filter out the ones that are non-existant in the store but are in the touched list
      setTouchedCtxList(touchedCtxList.filter((item) => !outOfSyncItems.includes(item)));
    }
  }, [
    dialogNode.toBeDeletedContextVariables,
    dialogNode.toBeReassignedContextVariables,
    dialogNode.toBeCalculatedContextVariables,
  ]);

  const [selectedCtx, setSelectedCtx] = useState<SelectedContextVariable>({
    category: 'Pending',
    ctx: null,
  });

  //#region Handling Context Variable Actions And State
  const handleDeleteAction = (ctx: ContextVariable) => {
    dialogNode.deleteCtxValue(ctx);
  };

  const handleReassign = (ctx: ContextVariable) => {
    dialogNode.reassignCtx(ctx, '');
    handleOpenModal(ctx, ContextActions.Reassign);
  };

  const handleExecuteFunction = (ctx: ContextVariable) => {
    dialogNode.addFunctionAction(ctx, '');
    handleOpenModal(ctx, ContextActions.Function);
  };

  const cancelAnyActionChange = (ctx: ContextVariable) => {
    if (ctx.isCreatedInContextNode && !ctx.isAccessedInNodes) {
      dialogNode.removeCreatedContextVariable(ctx);
    } else {
      dialogNode.cancelAnyAction(ctx);
    }

    setTouchedCtxList((prev) => prev.filter((item) => item !== ctx));
  };

  //#endregion

  //#region Handling Modal State And Actions

  const handleOpenModal = (ctx: ContextVariable, category: ContextActions) => {
    setSelectedCtx({ ctx, category });
  };

  const resetSelectedCtx = () => {
    setSelectedCtx({ ctx: null, category: 'Pending' });
  };
  //#endregion

  //#region Datalist handling

  const insertCtxIntoFilteredList = (name: string) => {
    if (!name) {
      new Notification({
        text: 'New context variable can not be empty',
        type: 'warning',
      });
      return;
    }

    let ctx = ctxVarStore.getByName(name) as ContextVariable | undefined;

    let updatedTouchedList: ContextVariable[] = [];
    if (!ctx) {
      ctx = dialogNode.createContextVariable(name);
      if (!ctx) return;

      updatedTouchedList = [ctx, ...touchedCtxList];
    } else {
      updatedTouchedList = [...touchedCtxList, ctx];
    }

    if (ctx) {
      if (!touchedCtxList.find((item) => item.id === ctx!.id)) {
        setTouchedCtxList(updatedTouchedList);
      }
    }
  };
  //#endregion

  const renderContextActionRow = (ctx: ContextVariable) => {
    const isManuallyAdded = !!dialogNode.createdVariables.find(
      (item) => item.id === ctx.id
    );

    if (dialogNode.toBeDeletedContextVariables.includes(ctx))
      return <Deleted isManuallyAdded={isManuallyAdded} name={ctx.name} />;

    if (dialogNode.toBeReassignedContextVariables.includes(ctx))
      return (
        <Reassignment
          isManuallyAdded={isManuallyAdded}
          item={dialogNode.getReassignedCtxDetails(ctx)!}
          openEditor={() => handleOpenModal(ctx, ContextActions.Reassign)}
        />
      );

    if (dialogNode.toBeCalculatedContextVariables.includes(ctx))
      return (
        <FunctionExecution
          isManuallyAdded={isManuallyAdded}
          item={dialogNode.getFunctionCtxDetails(ctx)!}
          openEditor={() => handleOpenModal(ctx, ContextActions.Function)}
        />
      );

    return (
      <Pending
        isManuallyAdded={isManuallyAdded}
        ctx={ctx}
        deleteHandler={() => handleDeleteAction(ctx)}
        handleReassign={() => handleReassign(ctx)}
        handleExecuteFunction={() => handleExecuteFunction(ctx)}
      />
    );
  };

  const renderModalBody = () => {
    if (selectedCtx.category === ContextActions.Reassign)
      return (
        <ContextActionModal.Reassignment
          item={dialogNode.getReassignedCtxDetails(selectedCtx.ctx!) as IContextReassign}
        />
      );

    if (selectedCtx.category === ContextActions.Function)
      return (
        <ContextActionModal.FunctionExecution
          item={dialogNode.getFunctionCtxDetails(selectedCtx.ctx!) as IContextFunction}
        />
      );
  };

  return (
    <DialogNode dialogNode={dialogNode}>
      <p>
        You can define what should happen to your context variables if the dialog reaches
        this point
      </p>
      <div className='datalist-container'>
        <ContextVariableSelector
          items={ctxVarStore.userVariables.filter((ctx) => !touchedCtxList.includes(ctx))}
          triggerLabel='Select Or Create A Context Variable'
          selectHandler={insertCtxIntoFilteredList}
          closeOnSelect={false}
          creator
          fullWidth
        />
      </div>
      <div className='ctx-list'>
        {sortedList.map((ctx) => (
          <ContextActionItem key={ctx.id}>
            <Button
              icon={getIcon('remove')}
              tooltip={
                ctx.isCreatedInContextNode && !ctx.isAccessedInNodes
                  ? 'Remove Context Variable'
                  : 'Cancel Chosen Action'
              }
              className='btn-borderless btn-remove btn-float-right'
              clickHandler={() => cancelAnyActionChange(ctx)}
            />
            {renderContextActionRow(ctx)}
          </ContextActionItem>
        ))}
      </div>
      <Modal manuallyTriggered={selectedCtx.ctx !== null} closeHandler={resetSelectedCtx}>
        <div className='context-action-editor'>{renderModalBody()}</div>
      </Modal>
    </DialogNode>
  );
};

export default observer(ContextActionNode);
