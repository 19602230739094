import { observer } from 'mobx-react-lite';
import React from 'react';
import { KnowledgebaseTriggerElement as KnowledgebaseTriggerElementModel } from '../../../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/KnowledgebaseTriggerElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: KnowledgebaseTriggerElementModel;
}

const KnowledgebaseTriggerElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <BaseHistoryElement
      element={element}
      icon={getIcon('robot')}
      title='Knowledgebase Trigger evaluated'
    >
      {element.triggered ? (
        <div className='card-paragraph'>
          <i className={getIcon('check')}> </i>
          <span> Triggered Knowledgebase Block</span>
        </div>
      ) : (
        <div className='card-paragraph'>
          <i className={getIcon('close_circle')}> </i>
          <span> Not triggered Knowledgebase block</span>
        </div>
      )}
    </BaseHistoryElement>
  );
};

export default observer(KnowledgebaseTriggerElement);
