import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { SingleQuestionNode } from '../SingleQuestionNode';

export class PhoneQuestionNode extends SingleQuestionNode {
  type = DialogNodeTypes.PhoneQuestionNode;
  constructor(block: DialogBlock) {
    super(block);
    this.title = 'Phone Question Node';
  }
}
