import { observer } from 'mobx-react-lite';
import React from 'react';
import { RedirectableNode } from '../../../../models/DialogNodes/RedirectableNode';
import { Redirect as RedirectModel } from '../../../../models/Redirects/Redirect';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import Redirects from './Redirects';

interface IProps {
  dialogNode: RedirectableNode;
  noConditions?: boolean;
  singleRedirect?: boolean;
}
const RedirectContainer: React.FC<IProps> = ({
  dialogNode,
  noConditions = false,
  singleRedirect = false,
}) => {
  const getIcon = useGetIcon();

  const addNewRedirect = () => {
    dialogNode.addRedirect(new RedirectModel());
  };

  return (
    <div className='redirect-container'>
      <Button
        icon={getIcon('add')}
        content={'Add new redirect'}
        className='btn-primary btn-smaller btn-round'
        clickHandler={addNewRedirect}
        disabled={singleRedirect && dialogNode.redirects.length > 0}
      />
      <Redirects
        noConditions={noConditions}
        redirects={dialogNode.redirects}
        removeRedirectHandler={dialogNode.removeRedirect.bind(dialogNode)}
      />
    </div>
  );
};

export default observer(RedirectContainer);
