import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import {
  DOCUMENTS_CONTENT_TYPE,
  IMAGES_CONTENT_TYPE,
  VIDEOS_CONTENT_TYPE,
} from '../architecture/constants/Media';
import { MediaState } from '../architecture/enums/MediaState';
import { MediaTypes } from '../architecture/enums/MediaType';
import { MediaConnector } from '../models/Connectors/MediaConnector';
import { Media } from '../models/Media/Media';
import { RootStore } from './rootStore';

class MediaStore {
  private static instance: MediaStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    MediaStore.instance = this;
  }

  @observable
  state: MediaState = MediaState.None;

  @observable
  private _mediaRepository: Media[] = [];

  @computed
  getState(state: MediaState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: MediaState) {
    this.state |= state;
  }

  @computed
  removeState(state: MediaState) {
    this.state &= ~state;
  }

  @observable
  _selectedMediaItems: Media[] = [];

  @observable
  totalItems: number = 0;

  @computed
  get allMediaItems() {
    return this._mediaRepository;
  }

  @action
  async load(force?: boolean) {
    try {
      if (!this.getState(MediaState.Initialized) || force) {
        this.setState(MediaState.ItemsLoading);
        const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
        if (!subscriptionId) {
          return;
        }
        const response = await MediaConnector.get(subscriptionId);
        runInAction(() => this.restore(response));
        this.removeState(MediaState.ItemsLoading);
        this.setState(MediaState.Initialized);
      }
    } catch (error) {
      this.removeState(MediaState.ItemsLoading);
    }
  }

  @action
  purge() {
    this._mediaRepository = [];
  }

  @action
  purgeSelectedMediaItems() {
    this._selectedMediaItems = [];
  }

  @action
  restore(items: Media[]) {
    this.purge();
    items.forEach((item) => {
      const instance = plainToInstance(Media, item);
      this._mediaRepository.push(instance);
    });
  }

  @action
  toggleMediaFileSelection(item: Media) {
    const ifExist = this._selectedMediaItems.find(
      (media) => media.mediaId === item.mediaId
    );
    if (!ifExist) {
      this._selectedMediaItems.push(item as Media);
    } else {
      this._selectedMediaItems = this._selectedMediaItems.filter(
        (media) => media.mediaId !== item.mediaId
      );
    }
  }

  @action
  selectOnlyOneMediaFile(item: Media) {
    const ifExist = this._selectedMediaItems.find(
      (media) => media.mediaId === item.mediaId
    );
    this._selectedMediaItems = [];

    if (!ifExist) {
      this._selectedMediaItems.push(item as Media);
    }
  }

  @action
  addToSelectedItems(id: string) {
    const selectedMedia = this._mediaRepository.find((media) => media.mediaId === id);
    if (selectedMedia) this._selectedMediaItems.push(selectedMedia);
  }

  @action
  search(type: string | undefined | null, name?: string | undefined | null) {
    let filteredMediaFiles = this._mediaRepository;
    if (type) {
      if (parseInt(type) === MediaTypes.Document) {
        filteredMediaFiles = filteredMediaFiles.filter((media) =>
          DOCUMENTS_CONTENT_TYPE.includes(media.contentType)
        );
      }
      if (parseInt(type) === MediaTypes.Image) {
        filteredMediaFiles = filteredMediaFiles.filter((media) =>
          IMAGES_CONTENT_TYPE.includes(media.contentType)
        );
      }
      if (parseInt(type) === MediaTypes.Video) {
        filteredMediaFiles = filteredMediaFiles.filter((media) =>
          VIDEOS_CONTENT_TYPE.includes(media.contentType)
        );
      }
    }

    if (name) {
      filteredMediaFiles = filteredMediaFiles.filter(
        (media) =>
          media.fileName?.toLowerCase().includes(name.toLowerCase()) ||
          media.description?.toLowerCase().includes(name.toLowerCase())
      );
    }

    return filteredMediaFiles;
  }

  @action
  async remove(item: Media) {
    try {
      const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
      this.setState(MediaState.ItemsLoading);
      if (!subscriptionId) {
        return;
      }
      await MediaConnector.delete(subscriptionId!, item.mediaId);
      this.load(true);
    } catch (error) {
      this.removeState(MediaState.ItemsLoading);
    }
  }

  @action
  getById(mediaId: string) {
    return this._mediaRepository.find((item) => item.mediaId === mediaId);
  }

  @action
  async upload(formData: FormData) {
    try {
      const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
      if (!subscriptionId) {
        return;
      }
      this.setState(MediaState.ItemsLoading);
      await MediaConnector.upload(subscriptionId!, formData);
      this.load(true);
    } catch (error) {
      this.removeState(MediaState.ItemsLoading);
    }
  }

  @action
  async update(mediaId: string, description: string) {
    try {
      const subscriptionId = this.rootStore.subscriptionStore.selectedSubscription?.id;
      if (!subscriptionId) {
        return;
      }
      this.setState(MediaState.ItemsLoading);
      await MediaConnector.update(subscriptionId!, mediaId, description);
      this.load(true);
    } catch (error) {
      this.removeState(MediaState.ItemsLoading);
    }
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('MediaStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default MediaStore;
