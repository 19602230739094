export const HEADERS = {
    questions: {
        label: 'Questions',
        id: 'questions',
    },
    answer: {
        label: 'Answer',
        id: 'answer',
    },
    actions: {
        label: '',
        id: 'actions',
    },
}

export const FILE_TYPES = 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, .tsv, text/tsv';

export const PAGE_SIZE = 20;