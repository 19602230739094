import { observer } from 'mobx-react-lite';
import React from 'react';
import { EntityQuestionNode as EntityQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import QuestionDialogNode from '../QuestionDialogNode';
import ContextVariableMapping from './examples/ContextVariableMapping';
import EntityExampleArea from './examples/EntityExampleArea';

interface IProps {
  dialogNode: EntityQuestionNodeModel;
}

const EntityQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return (
    <QuestionDialogNode dialogNode={dialogNode}>
      <EntityExampleArea dialogNode={dialogNode} />
      <ContextVariableMapping dialogNode={dialogNode} />
    </QuestionDialogNode>
  );
};

export default observer(EntityQuestionNode);
