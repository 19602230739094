import React, { useContext, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import rootStore from '../../stores/rootStore';
import ContextVariable from '../dialogElements/ContextVariable';

interface IParams {
  value?: string;
  placeholder?: string;
  clickHandler?: (ctx: IContextVariable) => void;
}
export default ({ value, placeholder = 'Enter a message', clickHandler }: IParams) => {
  const { ctxVarStore } = useContext(rootStore);

  const [processedContent, setProcessedContent] = useState<
    (JSX.Element | HTMLSpanElement | string)[]
  >([]);

  const createPlaceholder = () => [
    <span key={v4()} className='placeholder-span'>
      {placeholder}
    </span>,
  ];

  useEffect(() => {
    if (!value) {
      setProcessedContent(createPlaceholder());
    } else {
      const textFragments = value!.split(ctxVarStore.guidPattern);
      setProcessedContent(
        textFragments.map((frag: string) => {
          if (frag.match(ctxVarStore.guidPattern)) {
            const ctx = ctxVarStore.getById(frag);
            if (!ctx) return '';

            return (
              <ContextVariable
                key={ctx.id + Math.random()}
                ctx={ctx}
                clickHandler={() => clickHandler?.(ctx)}
              />
            );
          } else {
            return frag;
          }
        })
      );
    }
  }, []);

  return processedContent;
};
