import { observer } from 'mobx-react-lite';
import React from 'react';
import { QuestionAdaptiveCardNode as QuestionAdaptiveCardNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { Utilities } from '../../../../../models/Utilities/Utilities';
import InfoAdaptiveCardNode from '../../messageNodes/InfoAdaptiveCardNode';
import AdaptiveCardInputMapping from './misc/AdaptiveCardInputMapping';

interface IProps {
  dialogNode: QuestionAdaptiveCardNodeModel;
}
const QuestionAdaptiveCardNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <InfoAdaptiveCardNode dialogNode={dialogNode}>
      {!Utilities.isEmpty(dialogNode.inputMapping) && (
        <AdaptiveCardInputMapping inputFields={dialogNode.inputMapping} />
      )}
      {children}
    </InfoAdaptiveCardNode>
  );
};

export default observer(QuestionAdaptiveCardNode);
