import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { EventParameter } from '../../../../../models/Triggers/EventTrigger/EventParameter';
import rootStore from '../../../../../stores/rootStore';
import Button from '../../../../common/Button';
import Input from '../../../../common/Input';
import Switch from '../../../../common/inputElements/Switch';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../../customHooks/useReadOnly';

interface IProps {
  mapping: EventParameter[];
  addNewParamHandler: () => void;
  removeParamHandler: (item: EventParameter) => void;
}
const Parameters: React.FC<IProps> = ({
  mapping,
  addNewParamHandler,
  removeParamHandler,
}) => {
  const getIcon = useGetIcon();
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const { ctxVarStore } = useContext(rootStore);

  const handleCtxSelect = (name: string, item: EventParameter) => {
    item.ctx = ctxVarStore.addIfNotExists(name);
  };

  return (
    <Fragment>
      <h3>Parameters</h3>
      <Button
        disabled={readOnly}
        className='add-btn btn-borderless'
        icon={getIcon('add')}
        content='Add Parameter'
        clickHandler={addNewParamHandler}
      />
      <div className='key-value-pairs-container'>
        {mapping.length > 0 && (
          <div className='title-row'>
            <h4>Required</h4>
            <h4>Event Key</h4>
            <h4>Context Variable</h4>
          </div>
        )}
        {mapping.map((item) => (
          <div key={item.id} className='event-trigger-param-row'>
            <Switch
              disabled={readOnly}
              value={item.required}
              clickHandler={() => (item.required = !item.required)}
            />
            <Input
              disabled={readOnly}
              value={item.key}
              changeHandler={(text) => {
                item.key = text;
              }}
            />
            <ContextVariableSelector
              disabled={readOnly}
              items={ctxVarStore.userVariables}
              selectHandler={(name) => handleCtxSelect(name, item)}
              triggerLabel={item.ctx?.name ?? 'Select or create a CTX'}
              creator
              replaceSpecialCharacters
              closeOnSelect
              openUpwards
              fullWidth
            />
            <Button
              disabled={readOnly}
              className='btn btn-remove btn-borderless'
              icon={getIcon('remove')}
              tooltip='Remove parameter'
              tooltipPosition='top'
              clickHandler={() => removeParamHandler(item)}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default observer(Parameters);
