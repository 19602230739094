import { HttpAgent } from '../../utility/HttpAgent';
import { Media } from '../Media/Media';
import { Notification } from '../Utilities/Notification';
import { IUpdateMediaBody } from '../../architecture/interfaces/HTTP/MediaParams';

export class MediaConnector {
  static async delete(subscriptionId: string, mediaId: string): Promise<void> {
    return await HttpAgent.requests.delete(
      `/Api/V2/Subscriptions/${subscriptionId}/Media/${mediaId}`
    ).catch(error => {
      let text = null;
      switch (error.response.status) {
        case 400:
          text = 'Bad request';
          break;
        default:
          break;
      }
      if(text){
        new Notification({ text: text, type: 'error' });
      }
    })
  }

  static async update(subscriptionId: string, mediaId: string, description: string): Promise<void> {
    const body: IUpdateMediaBody = {
      description: description
    };
    return await HttpAgent.requests.put(
      `/Api/V2/Subscriptions/${subscriptionId}/Media/${mediaId}`, body
    ).catch(error => {
      let text = null;
      switch (error.response.status) {
        case 400:
          text = 'Bad request';
          break;
        default:
          break;
      }
      if(text){
        new Notification({ text: text, type: 'error' });
      }
    });
  }

  static async upload(subscriptionId: string, body: FormData): Promise<Media> {
    return await HttpAgent.requests.post(
      `/Api/V2/Subscriptions/${subscriptionId}/Media`,
      body
    ).catch(error => {
      let text = null;
      switch (error.response.status) {
        case 400:
          text = 'Bad request';
          break;
        case 413:
          text = 'File is larger than allowed (current limit is 30MB)';
          break;
        case 415:
          text = 'Unsupported media type';
          break;
        default:
          break;
      }
      if(text){
        new Notification({ text: text, type: 'error' });
      }
    });
  }

  static async get(subscriptionId: string | null): Promise<Media[]> {
    return await HttpAgent.requests.get(`/Api/V2/Subscriptions/${subscriptionId}/Media`)
    .catch(error => {
      let text = null;
      switch (error.response.status) {
        case 400:
          text = 'No subscription id given';
          break;
        default:
          break;
      }
      if(text){
        new Notification({ text: text, type: 'error' });
      }
    });
  }
}
