import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import type { IContextVariable } from '../../../architecture/interfaces/contextVariables/IContextVariable';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { TransformIntoContextVariable } from '../../Utilities/Deserialization/Decorators';
import { ISerializedQuestionDialogNode, QuestionDialogNode } from './QuestionDialogNode';

export abstract class SingleQuestionNode extends QuestionDialogNode {
  @Expose({ name: 'contextVariableId' })
  @TransformIntoContextVariable
  contextVariable?: IContextVariable;

  constructor(block: DialogBlock) {
    super(block);

    makeObservable(this, {
      contextVariable: observable,
    });
  }

  serialize(): ISerializedSingleQuestionNode {
    return {
      ...super.serialize(),
      contextVariableId: this.contextVariable?.id,
    };
  }
}

export interface ISerializedSingleQuestionNode extends ISerializedQuestionDialogNode {
  contextVariableId?: string;
}
