import { Exclude, Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { eventTargetPlatforms } from '../../../../../architecture/constants/EventTrigger';
import {
  EventTargetPlatforms,
  EventTargetReceiverTypes,
} from '../../../../../architecture/enums/EventTrigger';
import { BaseEventReceiver, ISerializedEventReceiver } from '../BaseEventReceiver';
import { EventTarget, ISerializedEventTarget } from '../EventTarget';
import { ChannelReceiver } from './Receivers/ChannelReceiver';
import { UserReceiver } from './Receivers/UserReceiver';

const EVENT_RECEIVER_DISCRIMINATOR = {
  discriminator: {
    property: 'type',
    subTypes: [
      { value: ChannelReceiver, name: EventTargetReceiverTypes.Channel },
      { value: UserReceiver, name: EventTargetReceiverTypes.User },
    ],
  },
};

export class MicrosoftTeamsEventTarget extends EventTarget {
  platform = EventTargetPlatforms.MicrosoftTeams;
  platformKey = eventTargetPlatforms.MICROSOFT_TEAMS;

  @Exclude()
  supportedReceivers = [
    EventTargetReceiverTypes['User'],
    EventTargetReceiverTypes['Channel'],
  ];

  @Expose()
  @Type(() => BaseEventReceiver, EVENT_RECEIVER_DISCRIMINATOR)
  receiver: BaseEventReceiver;

  constructor() {
    super();

    makeObservable(this, {
      receiver: observable,
    });

    this.receiver = new UserReceiver();
  }

  get isValid(): boolean {
    return this.receiver.isValid;
  }

  get isChanged(): boolean {
    return this.receiver.isChanged;
  }

  serialize(): ISerializedTeamsEventTarget {
    return {
      ...super.serialize(),
      receiver: this.receiver.serialize(),
    };
  }
}

export interface ISerializedTeamsEventTarget extends ISerializedEventTarget {
  receiver: ISerializedEventReceiver;
}
