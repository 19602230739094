import React from 'react';
import Select from '../../../../../common/inputElements/select/Select';

interface IProps {
  items: string[];
  label: string;
  disabled: boolean;
  selectHandler: (text: string) => void;
}
const PrefixHandler: React.FC<IProps> = ({ label, items, disabled, selectHandler }) => {
  return (
    <div className='prefix-input-wrapper' style={{ margin: '0 0 1rem 0' }}>
      <Select
        items={items.map((item) => ({ title: item }))}
        disabled={disabled}
        triggerLabel={label}
        selectHandler={selectHandler}
        replaceSpecialCharacters
        creator
        closeOnSelect
      />
    </div>
  );
};

export default PrefixHandler;
