import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { KeyValuePair } from '../../../../../models/Utilities/KeyValuePair';
import Button from '../../../../common/Button';
import Input from '../../../../common/Input';
import TextBoxWithCtx from '../../../../common/inputElements/TextBoxWithCtx';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  header: KeyValuePair;
  deleteHandler: (header: KeyValuePair) => void;
}
const Header: React.FC<IProps> = ({ header, deleteHandler }) => {
  const [isAboutToDelete, setIsAboutToDelete] = useState(false);
  const updateKey = (text: string) => {
    header.key = text;
  };

  return (
    <div
      key={header.id}
      className='header-row header-row-key-value'
      data-is-about-to-delete={isAboutToDelete}
    >
      <Input
        name=''
        value={header.key}
        controlled
        blurHandler={updateKey}
        placeholder='Enter a key'
        textAlign='center'
      />
      <TextBoxWithCtx
        value={header.value}
        saveHandler={(newValue) => (header.value = newValue)}
      />
      <Button
        icon={useGetIcon()('remove')}
        className='btn-remove btn-borderless'
        clickHandler={() => deleteHandler(header)}
        mouseEnterHandler={() => setIsAboutToDelete(true)}
        mouseLeaveHandler={() => setIsAboutToDelete(false)}
      />
    </div>
  );
};

export default observer(Header);
