import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Text } from '../../../../models/Utilities/Text';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import MessageItem from './MessageItem';

interface IProps {
  messages: Text[];
  newMessageHandler: (message: Text) => void;
  removeMessageHandler: (message: Text) => void;
}
const Messages: React.FC<IProps> = ({
  messages,
  newMessageHandler,
  removeMessageHandler,
}) => {
  const getIcon = useGetIcon();

  const resetActiveMessageId = () => {
    setActiveMessageId(undefined);
  };

  // Currently unused collapse state
  const [collapsed, setCollapsed] = useState(false);

  const [activeMessageId, setActiveMessageId] = useState<string | undefined>();

  const handleActiveMessageChange = React.useCallback((id: string) => {
    setActiveMessageId(id);
  }, []);

  // Currently unused collapse handler
  const handleCollapse = () => {
    setActiveMessageId(undefined);
    setCollapsed((prev) => !prev);
  };

  const handleNewMessage = () => {
    const message = new Text('');
    newMessageHandler(message);
    setCollapsed(false);
    setActiveMessageId(message.id);
  };

  const handleRemoveMessage = React.useCallback((message: Text) => {
    if (message.id === activeMessageId) {
      setActiveMessageId(undefined);
    }

    removeMessageHandler(message);
  }, []);

  const handleBlur = (e: React.FocusEvent) => {
    const currentTarget = e.currentTarget;
    const isCtxDropdownOpen = document.querySelector(`.ctx-dropdown-container`);

    // Check the newly focused element in the next tick of the event loop
    setTimeout(() => {
      // Check if the new activeElement is a child of the original container
      if (!currentTarget.contains(document.activeElement) && !isCtxDropdownOpen) {
        resetActiveMessageId();
      }
    }, 0);
  };

  return (
    <div id='messages-main-container' onBlur={handleBlur}>
      {!collapsed && (
        <div className='node-messages-container'>
          {messages.map((msg) => (
            <MessageItem
              key={msg.id}
              message={msg}
              isActive={activeMessageId === msg.id}
              clickHandler={() => handleActiveMessageChange(msg.id)}
              removeHandler={() => handleRemoveMessage(msg)}
            />
          ))}
        </div>
      )}
      <div className='control-btns'>
        {/* <div className='show-messages-wrapper'>
          {collapsed && (
					<div className="message-count">{ messages.length }</div>
				)}
				<Button
					icon={collapsed ? getIcon('eye') : getIcon('eye_closed')}
					tooltip={collapsed ? 'Show messages' : 'Hide messages'}
					tooltipPosition='top'
					className='btn-round btn-borderless show-messages-btn'
					clickHandler={handleCollapse}
				/>
        </div> */}
        <Button
          key={5}
          className='btn-borderless add-new-msg-btn'
          tooltip='New Message'
          tooltipPosition='top'
          icon={getIcon('add')}
          clickHandler={handleNewMessage}
        />
      </div>
    </div>
  );
};

export default observer(Messages);
