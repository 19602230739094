import { Exclude, Expose } from 'class-transformer';
import { computed, makeObservable, observable, reaction } from 'mobx';
import { v4 } from 'uuid';
import { ContextVariableInstanceTypes } from '../../architecture/enums/ContextVariableInstanceTypes';
import { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import type { ContextVariableTypes } from '../../architecture/types/ContextVariableTypes';
import BlockStore from '../../stores/BlockStore';
import ContextVariableStore from '../../stores/ContextVariableStore';
import NodeStore from '../../stores/NodeStore';
import { CommunicationDialogNode } from '.././DialogNodes/CommunicationDialogNode';
import { ContextCondition } from '../Conditions/ContextCondition';
import { Dataset } from '../Dataset/Dataset';
import { ActionAdaptiveCardNode } from '../DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import { ApiActionNode } from '../DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { ContextActionNode } from '../DialogNodes/ActionNodes/ContextActionNode';
import { DatasetActionNode } from '../DialogNodes/ActionNodes/DatasetActionNode';
import { EmailActionNode } from '../DialogNodes/ActionNodes/EmailActionNode';
import { StorageActionNode } from '../DialogNodes/ActionNodes/StorageActionNode';
import { BaseMultiQuestionNode } from '../DialogNodes/QuestionNodes/BaseMultiQuestionNode';
import { GroupedMultiQuestionNode } from '../DialogNodes/QuestionNodes/MultiQuestionNode/GroupedMultiQuestionNode';
import { MultiQuestionNode } from '../DialogNodes/QuestionNodes/MultiQuestionNode/MultiQuestionNode';
import { ChoiceQuestionNode } from '../DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import { QuestionAdaptiveCardNode } from '../DialogNodes/QuestionNodes/QuestionNodeTypes/QuestionAdaptiveCardNode';
import { SingleQuestionNode } from '../DialogNodes/QuestionNodes/SingleQuestionNode';
import { RedirectableNode } from '../DialogNodes/RedirectableNode';
import { EventTrigger } from '../Triggers/EventTrigger/EventTrigger';
import { Utilities } from '../Utilities/Utilities';

export class ContextVariable implements IContextVariable {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  type: ContextVariableTypes;

  @Exclude()
  instanceType: ContextVariableInstanceTypes;

  @Expose()
  datasetId?: string;

  constructor(name: string) {
    this.id = v4();
    this.name = name;
    this.type = 'string';
    this.instanceType = ContextVariableInstanceTypes.ContextVariable;

    makeObservable(this, {
      id: observable,
      name: observable,
      type: observable,
      isUsed: computed,
      isAccessedInNodes: computed,
      isCreatedInContextNode: computed,
    });

    reaction(
      () => this.isUsed,
      (isUsed) => {
        if (!isUsed) ContextVariableStore.getInstance().remove(this);
      }
    );
  }

  get isUsed() {
    return this.isAccessedInNodes || this.isCreatedInContextNode;
  }

  get isAccessedInNodes() {
    const redirects = NodeStore.getInstance()
      .allNodes.filter((node) => node.isRedirectable)
      .map((node) => (node as RedirectableNode).redirects)
      .flat();

    const isUsedInRedirects =
      redirects.filter((red) =>
        red.conditions
          .filter((cond) => cond instanceof ContextCondition)
          .some((cond) => (cond as ContextCondition).ctx === this)
      ).length > 0;

    const communicationNodes = NodeStore.getInstance().allNodes.filter(
      (node) => node instanceof CommunicationDialogNode
    );
    const emailNodes = NodeStore.getInstance().allNodes.filter(
      (node) => node instanceof EmailActionNode
    ) as EmailActionNode[];

    const datasetNodes = NodeStore.getInstance().allNodes.filter(
      (node) => node instanceof DatasetActionNode
    ) as DatasetActionNode[];

    const storageNodes = NodeStore.getInstance().allNodes.filter(
      (node) => node instanceof StorageActionNode
    ) as StorageActionNode[];

    const isUsedInMessages = communicationNodes.some((node) => {
      const ctxs = (node as CommunicationDialogNode).messages
        .map((msg) =>
          ContextVariableStore.getInstance().extractCtxVarsFromText(msg.value)
        )
        .flat();

      return ctxs.includes(this);
    });

    const singleSectionQuestionNodes = communicationNodes.filter(
      (node) => node instanceof SingleQuestionNode
    ) as SingleQuestionNode[];

    const isUsedInQuestionNodeContext = singleSectionQuestionNodes.some(
      (node) => node.contextVariable === this
    );

    const isUsedInChoiceQuestionNodes = singleSectionQuestionNodes
      .filter((item) => item instanceof ChoiceQuestionNode)
      .some((node) => (node as ChoiceQuestionNode).listContextVariable === this);

    const baseMultiQuestionNodes = communicationNodes.filter(
      (node) => node instanceof BaseMultiQuestionNode
    );

    const multiQuestionNodes = baseMultiQuestionNodes.filter(
      (node) => node instanceof MultiQuestionNode
    ) as MultiQuestionNode[];

    const groupedMultiQuestionNodes = baseMultiQuestionNodes.filter(
      (node) => node instanceof GroupedMultiQuestionNode
    ) as GroupedMultiQuestionNode[];

    const isUsedInGroupedMultiQuestionNodes = groupedMultiQuestionNodes.some((node) =>
      node.subQuestions.some(
        (question) =>
          !!question.contextVariables.find((item) => item.contextVariable === this)
      )
    );

    const isUsedInMultiQuestionNode = multiQuestionNodes.some((node) =>
      node.subQuestions.some((item) => item.ctx === this)
    );

    const isUsedInMultiQuestionNodes =
      isUsedInGroupedMultiQuestionNodes || isUsedInMultiQuestionNode;

    const isUsedInApiNodes = NodeStore.getInstance()
      .allNodes.filter((node) => node instanceof ApiActionNode)
      .some(
        (node) =>
          !!(node as ApiActionNode).getApiContextVariableById(this) ||
          (node as ApiActionNode).requestDependencies.some((dep) => dep === this)
      );

    const isUsedInEmailNodes = emailNodes.some((email) => {
      const stringBundle =
        (email.body ?? '') + (email.title ?? '') + (email.subject ?? '');
      const ctxs =
        ContextVariableStore.getInstance().extractCtxVarsFromText(stringBundle);

      return !!ctxs.find((ctx) => ctx === this);
    });

    const isUsedInEventTriggers = BlockStore.getInstance()
      .allBlocks.filter((block) => block.trigger && block.trigger instanceof EventTrigger)
      .map((block) => block.trigger)
      .some((trigger) =>
        (trigger as EventTrigger).contextMapping.some((item) => item.key === this.name)
      );

    const adaptiveCards = communicationNodes.filter(
      (node) =>
        node instanceof QuestionAdaptiveCardNode || node instanceof ActionAdaptiveCardNode
    );

    const isUsedInAdaptiveCardsData = adaptiveCards
      .map((node) => (node as QuestionAdaptiveCardNode).data)
      .flat()
      .some((variable) => variable.value === this.id);

    const isUsedInAdaptiveCardsInputMapping = adaptiveCards
      .map((node) => (node as QuestionAdaptiveCardNode).inputMapping)
      .flat()
      .some((item) => item.ctx?.id === this.id);

    const isUsedInStorageNodes = storageNodes.some(
      (node) => node.contextVariable === this
    );

    const isUsedInDatasetNodes = datasetNodes.some((node) => {
      let isUsedInProperty = false;
      node.properties.forEach((property) => {
        if (property.value && property.value.includes(this.id)) {
          isUsedInProperty = true;
        }
        if (
          property.createdVariables &&
          property.createdVariables.some((createdProp) => createdProp.id === this.id)
        ) {
          isUsedInProperty = true;
        }
        if (property.contextVariable && property.contextVariable.id === this.id) {
          isUsedInProperty = true;
        }
      });

      return node.contextVariable === this || isUsedInProperty;
    });

    const isUsedInAdaptiveCards =
      isUsedInAdaptiveCardsData || isUsedInAdaptiveCardsInputMapping;

    if (
      isUsedInMessages ||
      isUsedInRedirects ||
      isUsedInQuestionNodeContext ||
      isUsedInChoiceQuestionNodes ||
      isUsedInMultiQuestionNodes ||
      isUsedInApiNodes ||
      isUsedInEmailNodes ||
      isUsedInEventTriggers ||
      isUsedInAdaptiveCards ||
      isUsedInDatasetNodes ||
      isUsedInStorageNodes
    ) {
      return true;
    }

    return false;
  }

  get isCreatedInContextNode() {
    return !!NodeStore.getInstance()
      .allNodes.filter((node) => node instanceof ContextActionNode)
      .find((node) => (node as ContextActionNode).createdVariables.includes(this));
  }

  public static getFromStore(id: string) {
    if (Utilities.isEmpty(id)) return undefined;

    return ContextVariableStore.getInstance().getById(id);
  }

  serialize(): ISerializedContextVariable {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      instanceType: this.instanceType,
      datasetId: this.datasetId,
    };
  }
}

export class ListContextVariable extends ContextVariable {
  constructor(name: string) {
    super(name);

    this.type = 'list<string>';
    this.instanceType = ContextVariableInstanceTypes.ListContextVariable;
  }
}

export class DatasetContextVariable extends ContextVariable {
  constructor(name: string, dataset: Dataset) {
    super(name);

    this.type = dataset?.name;
    this.datasetId = dataset?.id;
    this.instanceType = ContextVariableInstanceTypes.DatasetContextVariable;
  }
}

export interface ISerializedContextVariable extends IContextVariable {}
