import React, { CSSProperties, useEffect, useState } from 'react';

interface IProps {
  id?: string;
  action?: () => void;
  secondaryAction?: () => void;
  disabled?: boolean;
  name?: string;
  value: string;
  placeholder?: string;
  inputType?: string;
  className?: string;
  textAlign?: 'left' | 'center' | 'right';
  autoFocus?: boolean;
  saveOnEnterOnly?: boolean;
  inputFactory?: boolean;
  style?: CSSProperties;
  controlled?: boolean;
  isValid?: boolean;
  actionIcon?: string;
  secondaryActionIcon?: string;
  blurHandler?: (text: string) => void;
  changeHandler?: (text: string) => void;
  clickHandler?: (event: React.MouseEvent<HTMLElement>) => void;
  keyDownHandler?: (event: React.KeyboardEvent) => void;
  darkMode?: boolean;
  min?: number;
  max?: number;
  ref?: React.LegacyRef<HTMLInputElement> | undefined;
  multiple?: boolean;
}

const Input: React.FC<IProps> = ({
  id,
  name,
  action,
  secondaryAction,
  value,
  placeholder,
  disabled = false,
  inputType = 'text',
  className,
  textAlign = 'left',
  autoFocus = false,
  saveOnEnterOnly = false,
  inputFactory = false,
  style = {},
  controlled = false,
  isValid = true,
  actionIcon,
  secondaryActionIcon,
  blurHandler,
  changeHandler,
  keyDownHandler,
  darkMode = false,
  clickHandler = undefined,
  min = undefined,
  max = undefined,
  ref = undefined,
  multiple = undefined,
}: IProps) => {
  useEffect(() => {
    if (controlled && !blurHandler) {
      throw new Error('If set Input to be controlled you must provide a blurHandler!');
    }

    if (!controlled && !changeHandler) {
      throw new Error(
        'If your Input is not controlled you must provide a changeHandler!'
      );
    }
  }, []);

  const [controlledValue, setControlledValue] = useState(value);

  const handleBlur = (event: React.FocusEvent) =>
    !saveOnEnterOnly && blurHandler?.(controlled ? controlledValue : value);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (saveOnEnterOnly) {
        blurHandler?.(controlled ? controlledValue : value);
        changeHandler?.('');
      } else {
        event.currentTarget.blur();
      }
      if (inputFactory) event.currentTarget.focus();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (controlled) {
      setControlledValue(e.currentTarget.value);
    } else {
      changeHandler?.(e.currentTarget.value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (keyDownHandler) keyDownHandler(event);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    if (clickHandler) {
      clickHandler(event);
    }
  };

  return (
    <div className='input'>
      <input
        id={id}
        ref={ref}
        autoFocus={autoFocus}
        name={name}
        disabled={disabled}
        type={inputType}
        placeholder={placeholder}
        style={{ height: '100%', textAlign: textAlign, ...style }}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onKeyDown={handleKeyDown}
        onClick={handleOnClick}
        value={controlled ? controlledValue : value}
        data-valid={isValid}
        data-dark-mode={darkMode.toString()}
        className={`dialog-node-input ${className ? className : ''}`}
        min={min}
        max={max}
        multiple
      />
      {secondaryActionIcon && (
        <i
          className={`${secondaryActionIcon} additional-icon`}
          onClick={secondaryAction}
        ></i>
      )}
      {actionIcon && <i className={`${actionIcon} input-icon`} onClick={action}></i>}
    </div>
  );
};

export default Input;
