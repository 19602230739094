import { Exclude, Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { PropertyState, PropertyType } from '../../../architecture/enums/Dataset';

export class Property {
  @Expose()
  id: string;

  @Exclude()
  type: PropertyType;

  @Expose()
  key: string;

  @Expose()
  annotation: string;

  @Exclude()
  state: PropertyState;

  constructor(
    type?: PropertyType,
    key?: string,
    annotation?: string,
    id?: string | null,
    state?: PropertyState
  ) {
    this.id = id ? id : v4();
    this.type = type ? type : PropertyType.Simple;
    this.key = key ? key : '';
    this.annotation = annotation ? annotation : '';
    this.state = state ? state : PropertyState.UpToDate;

    makeObservable(this, {
      id: observable,
      type: observable,
      key: observable,
      annotation: observable,
      state: observable,
    });
  }

  serialize() {
    return {
      id: this.id,
      annotation: this.annotation,
      key: this.key,
      type: this.type,
    };
  }
}
