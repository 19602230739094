import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { DatasetStatus } from '../../../architecture/enums/Dataset';
import { DatasetState } from '../../../architecture/enums/DatasetState';
import { Dataset } from '../../../models/Dataset/Dataset';
import { DatasetListProperty } from '../../../models/Dataset/Property/DatasetListProperty';
import { DatasetProperty } from '../../../models/Dataset/Property/DatasetProperty';
import { SimpleProperty } from '../../../models/Dataset/Property/SimpleProperty';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Modal from '../../common/modal/Modal';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';
import DatasetDetailsHeader from './components/DatasetDetailsHeader';
import ManageProperty from './properties/ManageProperty';
import PropertyList from './properties/PropertyList';

interface IProps {
  editMode: boolean;
  addDataset: boolean;
  setEditMode: (value: boolean) => void;
  setAddDataset: (value: boolean) => void;
}

const DatasetDetails: React.FC<IProps> = ({
  addDataset,
  editMode,
  setEditMode,
  setAddDataset,
}) => {
  const { datasetStore } = useContext(rootStore);
  const [addProperty, setAddProperty] = useState(false);
  const [editProperty, setEditProperty] = useState<
    SimpleProperty | DatasetProperty | DatasetListProperty | null
  >(null);
  const [dataset, setDataset] = useState(
    datasetStore.getSelectedDataset || new Dataset()
  );
  const getIcon = useGetIcon();

  useEffect(() => {
    if (addDataset) {
      setDataset(new Dataset());
    }
  }, [addDataset]);

  useEffect(() => {
    if (datasetStore.getSelectedDataset) setDataset(datasetStore.getSelectedDataset);
  }, [datasetStore.getSelectedDataset]);

  const discardChanges = () => {
    setEditMode(false);
    setAddDataset(false);
    datasetStore.setSelectedDataset(null);
  };

  const saveChanges = async () => {
    setEditMode(false);
    setAddDataset(false);
    if (dataset.status === DatasetStatus.New) {
      await datasetStore.create(dataset.name, dataset.properties);
    } else {
      await datasetStore.update(dataset);
    }
  };

  const resetModal = () => {
    setEditProperty(null);
    setAddProperty(false);
  };

  return (
    <div className='details-wrapper'>
      {datasetStore.getState(DatasetState.UpdateLoading) ? (
        <LoadingPage fullScreen={false} />
      ) : (
        <>
          <DatasetDetailsHeader
            editMode={editMode}
            setEditMode={setEditMode}
            dataset={dataset}
            addDataset={addDataset}
            setAddProperty={setAddProperty}
          />
          <div className='body'>
            {dataset && dataset.properties.length > 0 ? (
              <PropertyList
                editMode={editMode}
                dataset={dataset}
                setEditProperty={setEditProperty}
              />
            ) : (
              <div className='info-text'>No properties yet</div>
            )}
            {!editMode && (
              <div className='input-group'>
                <div className='input-grid'>
                  <div className='input-group'>
                    <label htmlFor='subscription-name'>Creation Date</label>
                    <Input
                      id='creation-date'
                      name='creation-date'
                      value={
                        dataset?.created
                          ? Utilities.getLocalDate(dataset.created, 'datetime')
                          : 'n/a'
                      }
                      changeHandler={() => {}}
                      disabled
                    />
                  </div>
                  <div className='input-group'>
                    <label htmlFor='entries'>Entries</label>
                    <Input
                      id='entries'
                      value={dataset?.entries}
                      changeHandler={() => {}}
                      actionIcon={getIcon('lock')}
                      disabled
                    />
                  </div>
                </div>
              </div>
            )}
            {editMode && (
              <div className='btn-container'>
                <Button
                  type='button'
                  content='Save Changes'
                  disabled={dataset.properties.length === 0 || !dataset.name}
                  className='btn-primary btn-round ml-1'
                  clickHandler={saveChanges}
                />
                <Button
                  content='Discard Changes'
                  className='btn-inactive btn-round'
                  clickHandler={discardChanges}
                />
              </div>
            )}
          </div>
          <Modal
            manuallyTriggered={editProperty || addProperty ? true : false}
            closeHandler={resetModal}
          >
            <ManageProperty
              title={addProperty ? 'Add property' : 'Edit property'}
              resetModal={resetModal}
              property={editProperty}
              dataset={dataset}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(DatasetDetails);
