import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FILTERS } from '../../../../architecture/constants/ConversationHistory';
import Input from '../../../common/Input';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  className?: string;
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
}

const ScoreFilter: React.FC<IProps> = ({
  className,
  handleParamsChange,
  clearParamsChange,
}) => {
  const location = useLocation();
  const getIcon = useGetIcon();
  const ref = useRef<HTMLDivElement>(null);
  const params = queryString.parse(location.search);
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(params.fromScore ? params.fromScore.toString() : '');
  const [to, setTo] = useState(params.toScore ? params.toScore.toString() : '');

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event?.target as HTMLElement;
    if (ref?.current && !ref?.current?.contains(target)) {
      setOpen(false);
    }
  };

  const clear = () => {
    setFrom('');
    setTo('');
    clearParamsChange(FILTERS.FROM_SCORE.NAME);
    clearParamsChange(FILTERS.TO_SCORE.NAME);
  };

  useEffect(() => {
    if(!params.fromScore){
      setFrom('');
    }
    if(!params.toScore){
      setTo('');
    }
  }, [params.fromScore, params.toScore])

  return (
    <div ref={ref}>
      <div className={`text-filter ${className}`} onClick={() => setOpen(!open)}>
        {FILTERS.SCORE.TITLE}
        <i className={`${getIcon('down')} filter-dropdown-icon`}></i>
      </div>
      {open && (
        <div className='filter-container'>
          <div className='filter-row'>
            <div> From: </div>
            <Input
              className='score-input'
              inputType='number'
              name={FILTERS.FROM_SCORE.NAME}
              value={from}
              blurHandler={() => handleParamsChange(FILTERS.FROM_SCORE.NAME, from)}
              changeHandler={setFrom}
            />
          </div>
          <div className='filter-row'>
            <div> To: </div>
            <Input
              className='score-input'
              inputType='number'
              name={FILTERS.TO_SCORE.NAME}
              value={to}
              blurHandler={() => handleParamsChange(FILTERS.TO_SCORE.NAME, to)}
              changeHandler={setTo}
            />
          </div>
          <div className='clear'>
            <span onClick={() => clear()}>
              <i className={getIcon('close')}> </i> Clear
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(ScoreFilter);
