import React, { useContext, useEffect } from 'react';
import { Redirect, Route } from 'react-router';
import { Role } from '../../architecture/enums/Roles';
import AuthContext from '../../ms_authentication/context/AuthContext';

interface IProps {
  children: React.ReactElement<any, any> | null;
  path: string;
  redirectPath?: string;
  roles?: Role[];
}

export function ProtectedRoute({
  children,
  path,
  redirectPath = '/error',
  roles = [Role.GlobalAdmin],
}: IProps) {
  const { userRoles, authenticated } = useContext(AuthContext);

  return (
    <Route
      path={path}
      render={() => {
        if (authenticated) {
          if (userRoles.some((r) => roles.includes(r))) return children;
          else return <Redirect to={redirectPath} />;
        }
      }}
    />
  );
}
