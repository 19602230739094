import { observable } from 'mobx';
import AvatarTexturesAdminStore from './adminStores/AvatarTexturesAdminStore';
import BillingAdminStore from './adminStores/BillingAdminStore';
import BotAdminStore from './adminStores/BotAdminStore';
import CreditManagementAdminStore from './adminStores/CreditManagementAdminStore';
import SubscriptionAdminStore from './adminStores/SubscriptionAdminStore';
import UserAdminStore from './adminStores/UserAdminStore';
import { RootStore } from './rootStore';

class AdminStore {
  private static instance: AdminStore;
  rootStore: RootStore;

  @observable
  botAdminStore: BotAdminStore;

  @observable
  subscriptionAdminStore: SubscriptionAdminStore;

  @observable
  userAdminStore: UserAdminStore;

  @observable
  billingAdminStore: BillingAdminStore;

  @observable
  creditManagementAdminStore: CreditManagementAdminStore;

  @observable
  avatarTexturesAdminStore: AvatarTexturesAdminStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    this.botAdminStore = new BotAdminStore(rootStore);
    this.subscriptionAdminStore = new SubscriptionAdminStore(rootStore);
    this.userAdminStore = new UserAdminStore(rootStore);
    this.billingAdminStore = new BillingAdminStore(rootStore);
    this.creditManagementAdminStore = new CreditManagementAdminStore(rootStore);
    this.avatarTexturesAdminStore = new AvatarTexturesAdminStore(rootStore);

    AdminStore.instance = this;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('AdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default AdminStore;
