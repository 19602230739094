import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  resetModal: () => void;
  selectedRows: string[];
}

const DeleteData: React.FC<IProps> = ({ resetModal, selectedRows }) => {
  const { datasetStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const handleDelete = async () => {
    resetModal();
    await datasetStore.deleteData(selectedRows);
  };

  const title =
    selectedRows.length > 1
      ? 'Are you sure you want to delete these elements?'
      : 'Are you sure you want to delete this element?';

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>{title}</h3>
      </div>
      <div className='btn-container'>
        <Button
          content='Delete'
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleDelete}
        />
        <Button
          content='Cancel'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={resetModal}
        />
      </div>
    </div>
  );
};

export default observer(DeleteData);
