import { action, computed, makeAutoObservable, observable } from 'mobx';
import { KnowledgebaseState } from '../architecture/enums/KnowledgebaseState';
import type { ISerializedKnowledgebase } from '../architecture/interfaces/ISerializedKnowledgebase';
import { KnowledgebaseEntry } from '../models/Knowledgebase/KnowledgebaseEntry';
import { RootStore } from './rootStore';

class KnowledgebaseStore {
  private static instance: KnowledgebaseStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    KnowledgebaseStore.instance = this;
  }

  @observable
  state: KnowledgebaseState = KnowledgebaseState.None;

  @observable
  private _knowledgebaseEntryRepository: KnowledgebaseEntry[] = [];

  @observable
  private _searchQuery?: string;

  @computed
  getState(state: KnowledgebaseState): boolean {
    return (this.state & state) === state;
  }

  @computed
  get isActive() {
    return !!this.rootStore.blockStore.getKnowledgebaseBlock();
  }

  @computed
  setState(state: KnowledgebaseState) {
    this.state |= state;
  }

  @computed
  removeState(state: KnowledgebaseState) {
    this.state &= ~state;
  }

  @computed
  get allKnowledgebaseEntries() {
    if (!this.isActive) return [];

    // It's not possible to directly sort an observable, so a copy is used:
    // https://dev.to/acro5piano/to-pass-mobxs-observable-array-to-flatlist-slice-is-needed-2b45
    return this._knowledgebaseEntryRepository.slice().sort();
  }

  @computed
  get activeKnowledgebaseEntries() {
    return this.allKnowledgebaseEntries.filter(
      (knowledgebaseEntry) => knowledgebaseEntry.state !== 'Deleted'
    );
  }

  @computed
  get isInErrorState() {
    return this._knowledgebaseEntryRepository.some(
      (entry) =>
        !entry.answer.value ||
        entry.questions.length === 0 ||
        entry.questions.every((q) => q.state === 'Deleted')
    );
  }

  @computed
  get filteredKnowledgebaseEntries() {
    if (!this._searchQuery) {
      return this.allKnowledgebaseEntries;
    }

    return this.allKnowledgebaseEntries.filter(
      (entry) =>
        entry.answer.value.toLowerCase().includes(this._searchQuery!.toLowerCase()) ||
        entry.questions.find((q) =>
          q.text.value.toLowerCase().includes(this._searchQuery!.toLowerCase())
        )
    );
  }

  @action
  setSearchQuery(query?: string) {
    this._searchQuery = query;
  }

  @action
  isEdited() {
    return (
      this._knowledgebaseEntryRepository.some((entry) => !entry.embeddingId) ||
      this._knowledgebaseEntryRepository.some((entry) => entry.state !== 'UpToDate') ||
      this._knowledgebaseEntryRepository.some((entry) =>
        entry.questions.some((q) => q.state !== 'UpToDate')
      )
    );
  }

  @action
  discardChanges() {
    this._knowledgebaseEntryRepository = this._knowledgebaseEntryRepository.filter(
      (entry) => entry.state !== 'Added'
    );

    this._knowledgebaseEntryRepository.forEach((entry) => {
      entry.discardExistingEntry();
    });
  }

  @action
  addRow() {
    const newEntry = new KnowledgebaseEntry();
    newEntry.state = 'Added';

    this._knowledgebaseEntryRepository.unshift(newEntry);
  }

  @action
  purge() {
    this._knowledgebaseEntryRepository = [];
  }

  @action
  remove(knowledgebaseEntry: KnowledgebaseEntry) {
    this._knowledgebaseEntryRepository = this._knowledgebaseEntryRepository.filter(
      (item) => item.embeddingId !== knowledgebaseEntry.embeddingId
    );
  }

  @action
  public restore(entry: KnowledgebaseEntry) {
    if (!entry) return;

    this._knowledgebaseEntryRepository.push(entry);
  }

  @action
  public serialize(): ISerializedKnowledgebase {
    if (!this.isActive) {
      return {
        knowledgebaseEntries: [],
      };
    }

    return {
      knowledgebaseEntries: this._knowledgebaseEntryRepository.map((entry) =>
        entry.serialize()
      ),
    };
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('KnowledgebaseStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default KnowledgebaseStore;
