import { IDataset } from '../../architecture/interfaces/HTTP/DatasetParams';
import { HttpAgent } from '../../utility/HttpAgent';
import { Dataset } from '../Dataset/Dataset';
import { Property } from '../Dataset/Property/Property';
import { Notification } from '../Utilities/Notification';

export class DatasetConnector {
  static async getAll(subscriptionId: string): Promise<IDataset[]> {
    return await HttpAgent.requests.get(
      `/Api/V2/Subscriptions/${subscriptionId}/Datasets`
    );
  }

  static async delete(subscriptionId: string, datasetId: string): Promise<void> {
    return await HttpAgent.requests
      .delete(`/Api/V2/Subscriptions/${subscriptionId}/Datasets/${datasetId}`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async update(subscriptionId: string, dataset: Dataset): Promise<void> {
    return await HttpAgent.requests
      .put(`/Api/V2/Subscriptions/${subscriptionId}/Datasets/${dataset.id}`, {
        dataset: dataset.serialize(),
      })
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          case 404:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async create(
    subscriptionId: string,
    name: string,
    properties: Property[]
  ): Promise<Dataset> {
    return await HttpAgent.requests
      .post(`/Api/V2/Subscriptions/${subscriptionId}/Datasets`, {
        dataset: {
          name: name,
          properties: properties.map((prop) => prop.serialize()),
        },
      })
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }
}
