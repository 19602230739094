import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { Intent as IntentModel } from '../../../models/Intents/Intent';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { IntentTrigger as IntentTriggerModel } from '../../../models/Triggers/IntentTrigger';
import rootStore from '../../../stores/rootStore';
import useModal from '../../customHooks/useModal';
import Intent from '../../intents/Intent';

interface IProps {
  block: DialogBlockModel;
}
const IntentTrigger: React.FC<IProps> = ({ block }) => {
  const { intentStore } = useContext(rootStore);
  const trigger = block.trigger as IntentTriggerModel;

  const { setCloseInterceptor, setPreventClosing } = useModal();

  const [intentName, setIntentName] = useState('');
  const [localIntent, setLocalIntent] = useState<IntentModel>();

  useEffect(() => {
    return () => {
      if (!trigger.isValid) {
        block.removeTrigger();
      }
    };
  }, []);

  useEffect(() => {
    let intent = new IntentModel();
    if (trigger.intent) {
      intent.id = trigger.intent.id;
      intent.name = trigger.intent.name;
      intent.setLabels(trigger.intent.labels);
      intent.state = trigger.intent.state;
    }
    setLocalIntent(intent);
    setIntentName(intent.name);

    return () => {
      setLocalIntent(undefined);
      setIntentName('');
      setPreventClosing(false);
      setCloseInterceptor(undefined);
    };
  }, [trigger]);

  const handleSaveIntent = () => {
    if (localIntent && intentName) {
      intentStore.addOrUpdate(localIntent);
      trigger.intentName = localIntent.name;
      localIntent?.setName(intentName);
    }
  };

  setCloseInterceptor(handleSaveIntent);

  return (
    <div className='intent-trigger'>{localIntent && <Intent intent={localIntent} />}</div>
  );
};

export default observer(IntentTrigger);
