import { HttpAgent } from '../../utility/HttpAgent';

export class ProxyConnector {
  static async getAudio(
    subscriptionId: string,
    content: string,
    language: string,
    usePronunciation = true
  ): Promise<any> {
    return await HttpAgent.requests.get(
      `/api/v2/proxy/subscription/${subscriptionId}/audio?text=${encodeURIComponent(
        content
      )}&language=${language}&usePronunciation=${usePronunciation}`
    );
  }
}
