import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { GroupedMultiQuestionNode } from '../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedMultiQuestionNode';
import { MultiQuestionNode as MultiQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/MultiQuestionNode';
import { Utilities } from '../../../../../models/Utilities/Utilities';
import rootStore from '../../../../../stores/rootStore';
import QuestionDialogNode from '../QuestionDialogNode';
import PrefixHandler from './components/PrefixHandler';

interface IProps {
  dialogNode: MultiQuestionNodeModel | GroupedMultiQuestionNode;
}
const BaseMultiQuestionNode: React.FC<IProps> = ({ dialogNode, children }) => {
  const { ctxVarStore } = useContext(rootStore);

  const handlePrefixChange = (value: string) => {
    dialogNode.setPrefix(value);
  };
  return (
    <QuestionDialogNode dialogNode={dialogNode}>
      <PrefixHandler
        items={ctxVarStore.multiQuestionPrefixes(dialogNode.type)}
        disabled={dialogNode.subQuestions.length > 0}
        label={
          Utilities.isEmpty(dialogNode.prefix)
            ? 'Select / Create a prefix'
            : dialogNode.prefix
        }
        selectHandler={handlePrefixChange}
      />
      {children}
    </QuestionDialogNode>
  );
};

export default observer(BaseMultiQuestionNode);
