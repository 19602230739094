import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { IncomingMessageElement as IncomingMessageElementModel } from '../../../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingMessageElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: IncomingMessageElementModel;
}

const IncomingMessageElement: React.FC<IProps> = ({ element }: IProps) => {
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);

  const getIcon = useGetIcon();

  const renderAttachments = () => {
    if (!element.attachments || element.attachments.length === 0) {
      return <></>;
    }

    return (
      <div className='card-paragraph'>
        <i className={getIcon('paperclip')}></i>
        <span className='card-paragraph-text'>
          {element.attachments.length === 1 ? (
            <span>User sent one attachment.</span>
          ) : (
            <span>User sent {element.attachments.length} attachments.</span>
          )}
        </span>
      </div>
    );
  };

  const renderAttachmentDetails = () => {
    return (
      <div className='card-paragraph'>
        <span className='card-paragraph-text'>
          <ul>
            {element.attachments.map((attachment, index) => {
              return (
                <li>
                  Attachment '{attachment.name}' is of type {attachment.contentType}
                </li>
              );
            })}
          </ul>
        </span>
      </div>
    );
  };

  return (
    <BaseHistoryElement
      element={element}
      title='Incoming Message'
      icon={getIcon('message')}
      openAdditionalInfo={openAdditionalInfo}
      setOpenAdditionalInfo={
        element.attachments !== undefined && element.attachments.length > 0
          ? setOpenAdditionalInfo
          : undefined
      }
    >
      {element.text && (
        <div className='card-paragraph'>
          <i className={getIcon('double_right')}></i>
          <span className='card-paragraph-text'>{element.text}</span>
        </div>
      )}
      {renderAttachments()}
      {openAdditionalInfo && renderAttachmentDetails()}
    </BaseHistoryElement>
  );
};

export default observer(IncomingMessageElement);
