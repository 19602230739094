import { observer } from 'mobx-react-lite';
import React, { useRef } from 'react';
import { DialogLanguages } from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import { Bot } from '../../../models/Bot/Bot';
import { Dialog } from '../../../models/Bot/Dialog';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Select from '../../common/inputElements/select/Select';
import Textarea from '../../common/inputElements/Textarea';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  bot: Bot;
  dialog: Dialog;
  updateDialog: () => void;
  resetModal: () => void;
}
const DialogSettings: React.FC<IProps> = ({ bot, dialog, updateDialog, resetModal }) => {
  const getIcon = useGetIcon();

  const originalDialog = useRef(Object.freeze({ ...dialog }));

  const handleCancel = () => {
    const original = originalDialog.current;

    dialog.versionName = original.versionName;
    dialog.versionDescription = original.versionDescription;

    resetModal();
  };

  return (
    <div className='modal-default dialog-overview-modal'>
      <div className='header'>
        <h4>Settings of {dialog.versionName}</h4>
      </div>
      <div className='body'>
        <div className='input-group'>
          <label htmlFor='new-dialog-name'>Dialog Name</label>
          <Input
            id='new-dialog-name'
            value={dialog.versionName}
            changeHandler={(text: string) => (dialog.versionName = text)}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='new-dialog-description'>Dialog Description</label>
          <Textarea
            id='new-dialog-description'
            value={dialog.versionDescription}
            rows={5}
            changeHandler={(text: string) => (dialog.versionDescription = text)}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='new-dialog-language'>Dialog Language</label>
          <Select
            triggerLabel={bot.language}
            items={Object.entries(DialogLanguages).map(
              ([lang, code]: [string, string]) => ({
                title: code,
                meta: lang,
              })
            )}
            disabled
            selectHandler={() => {}}
            closeOnSelect
            fullWidth
          />
        </div>
      </div>
      <div className='btn-container'>
        <Button
          name='save-dialog-btn'
          icon={getIcon('save')}
          content='Save and close'
          className='btn-dark btn-round'
          clickHandler={updateDialog}
          disabled={!dialog.versionName || !bot}
        />
        <Button
          icon={getIcon('close')}
          content='Cancel'
          className='btn-primary btn-round'
          clickHandler={handleCancel}
        />
      </div>
    </div>
  );
};

export default observer(DialogSettings);
