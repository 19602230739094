import { action, makeObservable, observable } from 'mobx';
import { Redirect } from '../Redirects/Redirect';
import { ActionAdaptiveCardNode } from './ActionNodes/ActionAdaptiveCardNode';
import { ApiActionNode } from './ActionNodes/ApiActionNode/ApiActionNode';
import { BaseDialogNode } from './BaseDialogNode';
import { RedirectDialogNode } from './RedirectDialogNode';

// Extend this type for all redirectable nodes!
export type RedirectableNode =
  | RedirectDialogNode
  | ApiActionNode
  | ActionAdaptiveCardNode;

type GConstructor<T = {}> = new (...args: any[]) => T;
type DerivedFromBaseDialogNode = GConstructor<BaseDialogNode>;

export function Redirectable<TBase extends DerivedFromBaseDialogNode>(Base: TBase) {
  return class Redirectable extends Base {
    redirects: Redirect[] = [];
    constructor(...args: any[]) {
      super(...args);

      makeObservable(this, {
        redirects: observable,
        addRedirect: action,
        isAnotherRedirectTargetingTheSameNodeAs: action,
        removeRedirect: action,
        serialize: action,
      });
    }

    get isRedirectable() {
      return true;
    }

    get isValid(): boolean {
      return super.isValid && this.redirects.every((redirect) => redirect.isValid);
    }

    addRedirect(redirect: Redirect) {
      this.redirects.unshift(redirect);
    }

    isAnotherRedirectTargetingTheSameNodeAs(redirect: Redirect) {
      return this.redirects
        .filter((red) => red !== redirect)
        .some((red) => red.targetNode === redirect.targetNode);
    }

    remove() {
      this.redirects.forEach((redirect) => this.removeRedirect(redirect));
      super.remove();
    }

    removeRedirect(redirect: Redirect) {
      redirect.targetNode && this.removeChild(redirect.targetNode);

      const targetBlockExists = !!redirect.targetBlock;
      const noOtherRedirectTargetingTheSameNode =
        !this.isAnotherRedirectTargetingTheSameNodeAs(redirect);
      const noOtherRedirectNodeTargetingTheSameBlock =
        !this.dialogBlock.isAnotherRedirectNodeInBlockTargetingSameBlockAs(
          this,
          redirect
        );

      if (
        targetBlockExists &&
        noOtherRedirectTargetingTheSameNode &&
        noOtherRedirectNodeTargetingTheSameBlock
      ) {
        this.dialogBlock.removeChild(redirect.targetBlock!);
      }

      this.redirects = this.redirects.filter((r) => r !== redirect);
    }

    serialize() {
      return {
        ...super.serialize(),
        redirects: this.redirects.map((redirect) => redirect.serialize()),
      };
    }
  };
}
