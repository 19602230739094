import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalTypes } from '../../architecture/enums/ModalTypes';
import { Media as MediaModel } from '../../models/Media/Media';
import { Utilities } from '../../models/Utilities/Utilities';
import rootStore from '../../stores/rootStore';
import { useGetIcon } from '../customHooks/useGetIcon';
import useModal from '../customHooks/useModal';

interface IProps {
  item: MediaModel;
  children?: React.ReactNode;
}

const MediaItem: React.FC<IProps> = ({ item, children }: IProps) => {
  const { showModal } = useModal();
  const location = useLocation();
  const getIcon = useGetIcon();
  const { mediaStore } = useContext(rootStore);
  const isMediaLibrary = location.pathname.includes('media');
  const isConfirmationSettings = location.pathname.includes('confirmation-settings');

  const openOverviewModal = () => {
    if (isMediaLibrary) {
      showModal(ModalTypes.MediaDetails, item);
    } else if (isConfirmationSettings) {
      mediaStore.selectOnlyOneMediaFile(item);
    } else {
      mediaStore.toggleMediaFileSelection(item);
    }
  };

  const isChecked = () => {
    return mediaStore._selectedMediaItems.find((media) => media.mediaId === item.mediaId);
  };

  return (
    <div className='media-item' onClick={openOverviewModal}>
      {isChecked() && !isMediaLibrary && (
        <div className='checked-item'>
          <i className={`${getIcon('check_solid')} ${'checked-item-icon'}`}></i>
        </div>
      )}
      {children}
      {!isMediaLibrary && (
        <div className={`item-tooltip tooltip-top`}>
          <div>
            Created: <i> {format(new Date(item.created), 'dd.MM.yyyy HH:mm')} </i>
          </div>
          <div>
            Name:
            <i> {Utilities.truncateText(item.fileName, 32)} </i>
          </div>
          {item.description && (
            <div>
              Description:
              <i> {Utilities.truncateText(item.description, 79)} </i>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default observer(MediaItem);
