import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC } from 'react';

interface RadioButtonProps {
  className?: string;
  label: string;
  name: string;
  value: string | number;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButton: FC<RadioButtonProps> = ({
  className,
  label,
  name,
  value,
  checked,
  onChange,
}) => {
  return (
    <label>
      <input
        className={`radio-btn ${className}`}
        type='radio'
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

export default observer(RadioButton);
