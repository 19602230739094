import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import NodeConfigurationContext from '../../../../contexts/communicationConfig/NodeConfigurationContext';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import Button from '../../../common/Button';
import RedirectButton from '../../../common/RedirectButton';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';

interface IProps {
  dialogNode: BaseDialogNode;
}

const NodeSideBar: React.FC<IProps> = ({ dialogNode }) => {
  // Custom Hooks
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const { showDetails, setShowDetails } = useContext(NodeConfigurationContext);

  return (
    <div className='node-side-bar'>
      <Fragment>
        <RedirectButton
          target='https://www.youtube.com/watch?v=oHg5SJYRHA0'
          icon={getIcon('info')}
          className='btn-borderless btn-primary'
        />
        <Button
          icon={getIcon('up')}
          className='btn-borderless btn-primary'
          clickHandler={() => dialogNode.moveUp()}
        />
        <Button
          icon={getIcon('down')}
          className='btn-borderless btn-primary'
          clickHandler={() => dialogNode.moveDown()}
        />
        <Button
          icon={getIcon('remove')}
          className='btn-borderless btn-primary'
          clickHandler={() => showModal(ModalTypes.DeleteElement, dialogNode)}
        />
        <Button
          icon={showDetails ? getIcon('close') : getIcon('more')}
          className={`btn-borderless ${showDetails ? 'btn-secondary' : 'btn-primary'}`}
          tooltip={showDetails ? 'Hide' : 'More'}
          tooltipPosition='right'
          clickHandler={() => setShowDetails()}
        />
      </Fragment>
    </div>
  );
};

export default observer(NodeSideBar);
