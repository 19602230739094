import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import TwoPanesWithBackNavigation from '../../common/paneNavigation/TwoPanesWithBackNavigation';
import useAdminStores from '../../customHooks/useAdminStores';
import SubscriptionsList from '../subscriptions/SubscriptionsList';
import BotDetails from './BotDetails';
import BotsList from './BotsList';

const BotsPage: React.FC = () => {
  const { botAdminStore, subscriptionAdminStore } = useAdminStores();

  const resizeSubscriptionsList = () => {
    botAdminStore.setSelectedBot(undefined);
    subscriptionAdminStore.setSelectedSubscription(undefined);
  };

  useEffect(() => {
    return () => {
      subscriptionAdminStore.setSelectedSubscription(undefined);
      botAdminStore.setSelectedBot(undefined);
    };
  }, []);

  const showOnlySubscriptions = () => {
    return <SinglePane pane={<SubscriptionsList />} />;
  };

  const showSubscriptionsAndBots = () => {
    return (
      <TwoPanes
        leftPane={<SubscriptionsList />}
        rightPane={<BotsList showControls={true} />}
      />
    );
  };

  const showBotsAndDetails = () => {
    return (
      <TwoPanesWithBackNavigation
        buttonContent='Subscriptions'
        clickHandler={resizeSubscriptionsList}
        leftPane={<BotsList />}
        rightPane={<BotDetails />}
      />
    );
  };

  // When a subscription and a bot are selected, display the bots in the left pane and the details
  // in the right pane. Also, add a vertical navigation pane on the left.
  if (subscriptionAdminStore.getSelectedSubscription && botAdminStore.getSelectedBot) {
    return showBotsAndDetails();
  }

  // When a subscription is selected, show the subscription list in the left pane, and the bots list
  // in the right pane.
  if (subscriptionAdminStore.getSelectedSubscription && !botAdminStore.getSelectedBot) {
    return showSubscriptionsAndBots();
  }

  // If nothing is selected only show the subscription list.
  return showOnlySubscriptions();
};

export default observer(BotsPage);
