import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { SettingsState } from '../../../architecture/enums/ConfirmationSettingsState';
import { MediaState } from '../../../architecture/enums/MediaState';
import rootStore from '../../../stores/rootStore';
import LoadingPage from '../../layout/LoadingPage';
import Colors from '../settingsProperties/Colors';
import Images from '../settingsProperties/Images';
import Links from '../settingsProperties/Links';
import { validationSchema } from './validation-schema';

const ConfirmDataset: React.FC = () => {
  const { botSettingsStore, dialogStore, mediaStore } = useContext(rootStore);

  const formik = useFormik({
    initialValues: botSettingsStore.settings,
    validationSchema: validationSchema,
    onSubmit: () => {},
  });

  const load = async () => {
    await botSettingsStore.getSettings();
  };

  useEffect(() => {
    return () => {
      botSettingsStore.removeState(SettingsState.Initialized);
    };
  }, []);

  useEffect(() => {
    if (dialogStore?.currentlyEditedDialog?.dialogId) {
      load();
    }
  }, [dialogStore?.currentlyEditedDialog?.dialogId]);

  useEffect(() => {
    formik.setValues(botSettingsStore.settings);
  }, [JSON.stringify(botSettingsStore.settings)]);

  useEffect(() => {
    formik.handleSubmit();
  }, [formik.values]);

  return (
    <>
      {botSettingsStore.getState(SettingsState.GetSettingsLoading) ||
      botSettingsStore.getState(SettingsState.UpdatingLoading) ||
      mediaStore.getState(MediaState.ItemsLoading) ? (
        <LoadingPage />
      ) : (
        <>
          {botSettingsStore.getState(SettingsState.Initialized) && (
            <div className='settings-container'>
              <div className='settings-content'>
                <form onBlur={formik.handleSubmit}>
                  <Colors formik={formik} />
                  <Images formik={formik} />
                  <Links formik={formik} />
                </form>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default observer(ConfirmDataset);
