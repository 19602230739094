import { Exclude, Expose, Type } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { KeyValuePair, ISerializedKeyValuePair } from '../../Utilities/KeyValuePair';
import { Utilities } from '../../Utilities/Utilities';
import {
  CommunicationDialogNode,
  ISerializedCommunicationDialogNode,
} from '../CommunicationDialogNode';
import {
  AdaptiveCardInput,
  ISerializedAdaptiveCardInput,
} from './Misc/AdaptiveCardInput';
import { AdaptiveCardSubmit } from './Misc/AdaptiveCardSubmit';

export abstract class AdaptiveCardNode extends CommunicationDialogNode {
  @Exclude()
  readonly ADAPTIVE_CARD_VARIABLE_FORMAT = /(\$\{.*?\})/gm;

  @Exclude()
  readonly ADAPTIVE_CARD_INPUT_FIELD_FORMAT = /Input[.].*\b/gm;

  @Exclude()
  readonly ADAPTIVE_CARD_SUBMIT_ACTION_FORMAT = /Action[.](Submit|Execute)/gm;

  @Expose()
  abstract readonly type: DialogNodeTypes;

  @Expose()
  template: string;

  @Expose()
  @Type(() => KeyValuePair)
  data: KeyValuePair[];

  @Expose()
  @Type(() => AdaptiveCardInput)
  inputMapping: AdaptiveCardInput[];

  @Expose()
  @Type(() => AdaptiveCardSubmit)
  submitMapping: AdaptiveCardSubmit[];

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Adaptive Card Node';
    this.template = '';
    this.data = [];
    this.inputMapping = [];
    this.submitMapping = [];

    makeObservable(this, {
      template: observable,
      data: observable,
      inputMapping: observable,
      submitMapping: observable,
      hasSupportedVersion: computed,
      extractInputs: action,
      extractSubmits: action,
      pasteTemplate: action,
      clearTemplate: action,
      createData: action,
    });
  }

  get isValid() {
    return super.isValid && Utilities.isJsonValid(this.template);
  }

  get parsedTemplate() {
    if (!Utilities.isJsonValid(this.template)) return null;

    return JSON.parse(this.template);
  }

  get hasSupportedVersion() {
    if (!Utilities.isJsonValid(this.template)) return true;

    const parsedTemplate = JSON.parse(this.template);
    return parseFloat(parsedTemplate.version ?? '1000') <= 1.3;
  }

  clearTemplate() {
    this.template = '';
    this.data = [];
    this.inputMapping = [];
    this.submitMapping = [];
  }

  createData() {
    const extractedData = this.extractData().map((item) => new KeyValuePair(item, ''));

    this.data = extractedData.map((item) => {
      const existingEntry = this.data.find((entry) => entry.key === item.key);
      if (existingEntry) return existingEntry;

      return item;
    });
  }

  extractData() {
    const vars = this.template.match(this.ADAPTIVE_CARD_VARIABLE_FORMAT);
    const values = !vars ? [] : [...vars.values()].map(this.peelTags);
    return values;
  }

  extractInputs() {
    if (!this.parsedTemplate) return;

    const inputFields: AdaptiveCardInput[] = [];

    Object.values(this.parsedTemplate).forEach((value) =>
      this.findInputFields(value, inputFields)
    );

    this.inputMapping = inputFields.map((field) => {
      const existingInputMap = this.inputMapping.find(
        (item) => item.inputId == field.inputId
      );

      if (existingInputMap) return existingInputMap;

      return field;
    });
  }

  protected findInputFields(value: any, arr: AdaptiveCardInput[]) {
    // Simple strings or numbers are not interesting
    if (!value || typeof value !== 'object') return;

    // At this point "value" can be an array or an object (simple or nested)

    // If "value" has the key of "Input.*" we can push it to the referred array and we are done
    if ('type' in value && !!value.type.match(this.ADAPTIVE_CARD_INPUT_FIELD_FORMAT)) {
      arr.push(new AdaptiveCardInput(value.id, value.type));
    } else {
      // Else we are looping through the values of "value" because it can contain furher objects or arrays
      Object.values(value).forEach((val) => this.findInputFields(val, arr));
    }
  }

  extractSubmits() {
    if (!this.parsedTemplate) return;

    const actions: AdaptiveCardSubmit[] = [];

    // We will mutate the template if submit actions have no "data : { actionId: '....' }""
    // If we find Action.Submit fields, we create an AdaptiveCardSubmit class which takes care of the "data" property.
    // The template will be mutated in this process and to display the new data-->actionId in the template itself
    // we can switch out the old template to the new mutated one
    const template = { ...this.parsedTemplate };
    Object.values(template).forEach((value) => this.findSubmits(value, actions));

    // We set the mutated template to this.template
    this.template = JSON.stringify(template, undefined, 2);
    this.submitMapping = actions.map((action) => {
      const existingSubmitMap = this.submitMapping.find(
        (item) => item.submitId == action.submitId && item.title === action.title
      );
      if (existingSubmitMap) return existingSubmitMap;

      return action;
    });
  }

  protected findSubmits(value: any, arr: AdaptiveCardSubmit[]) {
    // Simple strings or numbers are not interesting
    if (!value || typeof value !== 'object') return;

    // At this point "value" can be an array or an object (simple or nested)
    // If "value" has the key of "Action.Submit/Action.Execute*" we can push it to the referred array and we are done
    if ('type' in value && !!value.type.match(this.ADAPTIVE_CARD_SUBMIT_ACTION_FORMAT)) {
      const storedActionSubmit = this.submitMapping.find(
        (item) => item.submitId === value.id
      );

      const submit = new AdaptiveCardSubmit(
        storedActionSubmit ? { ...storedActionSubmit.data, ...value.data } : value.data,
        value.title,
        value.id
      );
      arr.push(submit);
      value.data = submit.data;
    } else {
      // Else we are looping through the values of "value" because it can contain furher objects or arrays
      Object.values(value).forEach((val) => this.findSubmits(val, arr));
    }
  }

  pasteTemplate(text: string) {
    if (!Utilities.isJsonValid(text)) return;

    this.template = text;
    this.createData();
    this.extractInputs();
    this.extractSubmits();
  }

  protected peelTags(key: string) {
    return key.replace('${', '').replace('}', '');
  }

  serialize(): ISerializedAdaptiveCardNode {
    return {
      ...super.serialize(),
      type: this.type,
      template: this.template,
      data: this.data.map((item) => item.serialize()),
      inputMapping: this.inputMapping.map((item) => item.serialize()),
      submitMapping: this.submitMapping,
    };
  }
}
export interface ISerializedAdaptiveCardNode extends ISerializedCommunicationDialogNode {
  type: DialogNodeTypes;
  template: string;
  data: ISerializedKeyValuePair[];
  inputMapping: ISerializedAdaptiveCardInput[];
  submitMapping: AdaptiveCardSubmit[];
}
