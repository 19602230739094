import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { Utilities } from '../../../../../Utilities/Utilities';
import { BaseEventReceiver, ISerializedEventReceiver } from '../../BaseEventReceiver';

export class ChannelReceiver extends BaseEventReceiver {
  type = EventTargetReceiverTypes.Channel;

  @Expose()
  tenantId: string;

  @Expose()
  team: string;

  @Expose()
  channel: string;

  constructor() {
    super();
    this.tenantId = '';
    this.team = '';
    this.channel = 'General';

    makeObservable(this, {
      tenantId: observable,
      team: observable,
      channel: observable,
      isValid: computed,
    });
  }

  get isValid() {
    return (
      Utilities.isGuidValid(this.tenantId) &&
      !Utilities.isEmpty(this.team) &&
      !Utilities.isEmpty(this.channel)
    );
  }

  get isChanged() {
    return (
      !Utilities.isEmpty(this.tenantId) ||
      !Utilities.isEmpty(this.team) ||
      this.channel !== 'General'
    );
  }

  serialize(): ISerializedChannelEventReceiver {
    return {
      ...super.serialize(),
      tenantId: this.tenantId,
      team: this.team,
      channel: this.channel,
    };
  }
}

export interface ISerializedChannelEventReceiver extends ISerializedEventReceiver {
  tenantId: string;
  team: string;
  channel: string;
}
