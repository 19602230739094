import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

interface ICheckboxProps {
  disabled?: boolean;
  className?: string;
  name?: string;
  content?: string;
  value?: Date | string | boolean | null;
  clickHandler?: (value: boolean) => void;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  disabled = false,
  className,
  name,
  content,
  value,
  clickHandler,
}: ICheckboxProps) => {
  const [selected, setSelected] = useState(value);
  const handleClick = () => {
    clickHandler?.(selected ? false : true);
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className={className}>
      <input
        type='checkbox'
        name={name}
        disabled={disabled}
        checked={selected ? true : false}
        onChange={handleClick}
      ></input>
      <label htmlFor={name}> {content} </label>
    </div>
  );
};

export default observer(Checkbox);
