import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { AiTrigger as AiTriggerModel } from '../../../models/Triggers/AiTrigger';
import TextInputWithDelete from '../../common/inputElements/TextInputWithDelete';
import useModal from '../../customHooks/useModal';
import { useReadOnly } from '../../customHooks/useReadOnly';

interface IProps {
  block: DialogBlockModel;
}

const AiTrigger: React.FC<IProps> = ({ block }) => {
  const trigger = block.trigger as AiTriggerModel;

  const { setPreventClosing } = useModal();
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  useEffect(() => {
    setPreventClosing(!trigger.isValid);

    return () => {
      setPreventClosing(false);
    };
  }, [JSON.stringify(block.trigger)]);

  return (
    <>
      <h3>Description</h3>
      <p>
        Provide a detailed and specific description of when this AI trigger should
        activate. The AI will use this description to determine if the user intends to
        enter the corresponding block. To achieve the best results, consider the user's
        potential intents and the context of your Bot. The effectiveness of this trigger
        relies on the quality of your description. Additionally, ensure that the
        descriptions of different AI triggers do not overlap.
      </p>
      <TextInputWithDelete
        disabled={readOnly}
        key={trigger.intentDescription.id}
        textObject={trigger.intentDescription}
        deleteHandler={() => (trigger.intentDescription.value = '')}
        autoFocus
      />
    </>
  );
};

export default observer(AiTrigger);
