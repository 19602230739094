import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { ModalTypes } from '../../architecture/enums/ModalTypes';
import { TableData } from '../../architecture/enums/TableData';
import { Payment } from '../../models/Admin/Payment';
import { Utilities } from '../../models/Utilities/Utilities';
import { useGetIcon } from '../customHooks/useGetIcon';
import useModal from '../customHooks/useModal';
import Table from './table/Table';

interface IProps {
  payments: Payment[];
  totalCredits: number;
  consumedCredits: number;
  showOutdateButton: boolean;
}

const CreditManagementTable = ({
  payments,
  totalCredits,
  consumedCredits,
  showOutdateButton = true,
}: IProps) => {
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: 'created',
        Header: 'Created',
        accessor: (payment: TableData) => (payment as Payment).created,
      },
      {
        id: 'remarks',
        Header: 'Remarks',
        accessor: (payment: TableData) => (payment as Payment).remarks,
      },
      {
        id: 'credits',
        Header: 'Credits',
        accessor: (payment: TableData) => (payment as Payment).credits,
      },
      {
        id: 'outdate',
        Header: '',
        width: 25,
        Cell: (item: Cell<Payment>) => {
          if (!showOutdateButton) return <></>;

          const payment = item.row.original as Payment;

          return (
            <div className='actions'>
              <i
                className={`${getIcon('close')} actions-icon`}
                onClick={() => {
                  showModal(
                    ModalTypes.DeleteElement,
                    new Payment(
                      payment.created,
                      payment.credits,
                      payment.id,
                      payment.outdated,
                      payment.remarks
                    )
                  );
                }}
              ></i>
            </div>
          );
        },
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    return payments.map((payment) => {
      return {
        id: payment.id,
        outdated: payment.outdated,
        created: Utilities.getLocalDate(payment.created, 'datetime'),
        credits: payment.credits,
        remarks: payment.remarks,
      };
    });
  }, [JSON.stringify(payments)]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  return (
    <div className='container-wrapper'>
      <Table
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />
      <div className='credit-payment-sum-container'>
        <div className='credit-payment-sum'>
          <div
            className='progress-bar'
            style={{ width: `${(consumedCredits * 100) / totalCredits}%` }}
          ></div>
          <div className='text'>
            <span className='description'>Credits:</span>
            <span className='number'>{consumedCredits}</span>
            {' / '}
            <span className='number'>{totalCredits}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(CreditManagementTable);
