import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { KeyValuePair } from '../../../../models/Utilities/KeyValuePair';
import TextBoxWithCtx from '../../../common/inputElements/TextBoxWithCtx';

interface IProps {
  data: KeyValuePair[];
}
const AdaptiveCardData: React.FC<IProps> = ({ data }) => {
  return (
    <Fragment>
      <h4 style={{ textAlign: 'center' }}>Provide values for your template variables</h4>
      <div className='adaptive-card-values-container'>
        {data.map((item) => (
          <div key={item.id} className='adaptive-card-value-row'>
            <h4 className='adaptive-card-key'>{item.key}</h4>
            <TextBoxWithCtx
              value={item.value}
              saveHandler={(text: string) => (item.value = text)}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default observer(AdaptiveCardData);
