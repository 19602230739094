import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { TriggerTypes } from '../../../architecture/enums/TriggerTypes';
import { DefaultDialogBlock } from '../../../models/DialogBlocks/DefaultDialogBlock';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { KnowledgebaseBlock } from '../../../models/DialogBlocks/KnowledgebaseBlock';
import { CombinationTrigger } from '../../../models/Triggers/CombinationTrigger';
import { DefaultTrigger } from '../../../models/Triggers/DefaultTrigger';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Bubble from '../../common/bubble/Bubble';
import BubbleContainer from '../../common/bubble/BubbleContainer';
import Textarea from '../../common/inputElements/Textarea';
import useDialogAttributeHandler from '../../customHooks/useAttributeChangeHandler';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import { useReadOnly } from '../../customHooks/useReadOnly';
import BlockInfoIcon from './misc/BlockInfoIcon';

interface IProps {
  block: DialogBlockModel;
  isDragging: boolean;
}
const DialogBlock: React.FC<IProps> = ({ block, isDragging }) => {
  const { blockStore, uiStore, dialogStore, conversationHistoryStore } =
    useContext(rootStore);

  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const getIcon = useGetIcon();
  const { showModal } = useModal();
  const location = useLocation();

  // Dynamic route because the knowledgebase block can either be reached from the
  // designer or via the history
  const knowledgebaseRoute =
    location.pathname.replace('designer', 'knowledgebase') + location.search;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const getTextAreaRef = () => textAreaRef.current as unknown as HTMLTextAreaElement;

  const [isEditable, setIsEditable] = useState(false);

  const updateTitle = useDialogAttributeHandler<DialogBlockModel>(block, 'title');

  const handleTitleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    uiStore.dialogBlockPanZoomHandler.panZoomInstance?.pause();
  };

  const handleTextAreaBlur = () => {
    setIsEditable(false);
    uiStore.dialogBlockPanZoomHandler.panZoomInstance?.resume();
  };

  const handleEditTitle = () => {
    getTextAreaRef()?.setSelectionRange(block.title.length, block.title.length);
    setTimeout(() => getTextAreaRef()?.focus(), 1);
    setIsEditable(!isEditable);
  };

  const isHighlighted = () => {
    const highlighted = conversationHistoryStore.historyElements.find(
      (a) => a.blockId === block.id
    );
    if (readOnly) {
      if (highlighted) {
        return 'blocked-block-overview-highlight';
      } else {
        return 'blocked-block-overview';
      }
    }
    return '';
  };

  const renderOpenButton = () => {
    if (block instanceof KnowledgebaseBlock) {
      return (
        <Link
          to={knowledgebaseRoute}
          className='node-block-footer-link'
          onClick={() => blockStore.selectBlock(block)}
        >
          <i className={getIcon('open')} style={{ marginRight: '.3rem' }}></i>
          <span>Open</span>
        </Link>
      );
    }

    if (block instanceof DefaultDialogBlock) {
      if ((block.trigger as DefaultTrigger).defaultBehaviour === 'GenerativeAnswer')
        return <></>;
    }

    return (
      <Link
        to={`/dialogs/${dialogStore?.currentlyEditedDialog?.dialogId}/blocks/${block.id}`}
        className='node-block-footer-link'
        onClick={() => blockStore.selectBlock(block)}
        hidden={readOnly}
      >
        <i className={getIcon('open')} style={{ marginRight: '.3rem' }}></i>
        <span>Open</span>
      </Link>
    );
  };

  return (
    <div
      className={`node-block ${isHighlighted()} ${
        block instanceof DefaultDialogBlock || block instanceof KnowledgebaseBlock
          ? 'special-block'
          : ''
      }`}
      id={block.id}
    >
      {block.trigger && (
        <BubbleContainer position='side'>
          {block.trigger.type === TriggerTypes.Combination ? (
            (block.trigger as CombinationTrigger).triggers.map((tr) => (
              <Bubble
                trigger={tr}
                isKnowledgebaseBlock={block instanceof KnowledgebaseBlock}
              />
            ))
          ) : (
            <Bubble
              trigger={block.trigger}
              isKnowledgebaseBlock={block instanceof KnowledgebaseBlock}
              clickHandler={() => showModal(ModalTypes.Trigger, block)}
            />
          )}
        </BubbleContainer>
      )}
      {block instanceof KnowledgebaseBlock && (
        <BubbleContainer position='side'>
          <Bubble isKnowledgebaseBlock={block instanceof KnowledgebaseBlock} />
        </BubbleContainer>
      )}
      <div className='node-block-header'>
        {!block.trigger && !readOnly && !(block instanceof KnowledgebaseBlock) && (
          <Button
            tooltipPosition='top'
            icon={getIcon('add')}
            className={'add-trigger-btn btn-circle btn-borderless'}
            clickHandler={() => showModal(ModalTypes.Trigger, block)}
          />
        )}
        <Textarea
          disabled={isDragging || !isEditable}
          ref={textAreaRef}
          value={block.title}
          name='title'
          rows={3}
          changeHandler={updateTitle}
          mouseDownHandler={handleTitleClick}
          blurHandler={handleTextAreaBlur}
          className={
            isEditable
              ? 'node-block-header-title-editable'
              : 'node-block-header-title-default'
          }
        />
      </div>
      <div className='node-block-footer'>
        <div>
          {!readOnly && block.userModificationAllowed && (
            <Button
              icon={getIcon('remove')}
              className={'btn btn-remove btn-borderless'}
              clickHandler={() => showModal(ModalTypes.DeleteElement, block)}
            />
          )}
          {!readOnly && (
            <Button
              icon={getIcon('edit')}
              className={'btn btn-remove btn-borderless'}
              clickHandler={handleEditTitle}
            />
          )}
        </div>

        <div className='node-block-footer-info'>
          <BlockInfoIcon block={block} />

          {renderOpenButton()}
        </div>
      </div>
    </div>
  );
};

export default observer(DialogBlock);
