import { observer } from 'mobx-react-lite';
import React, { CSSProperties, useRef, useState } from 'react';
import { AdaptiveCardNode as AdaptiveCardNodeModel } from '../../../../models/DialogNodes/AdaptiveCardNode/AdaptiveCardNode';
import { Utilities } from '../../../../models/Utilities/Utilities';
import Button from '../../../common/Button';
import InfoMessage from '../../../common/InfoMessage';
import Textarea from '../../../common/inputElements/Textarea';
import TextareaJson from '../../../common/TextareaJson';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import CommunicationNodeConfigurations from '../communicationNode/CommunicationNodeConfigurations';
import DialogNode from '../DialogNode';
import AdaptiveCardData from '../misc/AdaptiveCardData';
import AdaptiveCardErrorMessages from '../misc/AdaptiveCardErrorMessages';

interface IProps {
  dialogNode: AdaptiveCardNodeModel;
}

const InfoAdaptiveCardNode: React.FC<IProps> = ({ dialogNode, children }) => {
  const getIcon = useGetIcon();

  // Beware! Manually changing the structure inside the textbox can have unexpected results!
  const handleChange = (text: string) => {
    dialogNode.template = text;
    dialogNode.createData(); // Re-extracting variables every time the text changes preserving the ones whose keys were not changed.
    dialogNode.extractInputs(); // Re-extracting input fields every time the text changes preserving the ones whose keys were not changed.
    dialogNode.extractSubmits(); // Re-extracting submit fields
  };

  const handlePaste = (text: string) => {
    dialogNode.pasteTemplate(text);
  };

  return (
    <DialogNode dialogNode={dialogNode}>
      {!dialogNode.hasSupportedVersion && (
        <div className='info-message-container'>
          <InfoMessage
            message='Only template versions smaller than or equal to 1.3 are supported and yours is higher.'
            type='error'
          />
        </div>
      )}
      <Button
        content='Clear Structure'
        icon={getIcon('refresh')}
        className='remove-btn btn-borderless'
        clickHandler={() => dialogNode.clearTemplate()}
      />
      <TextareaJson
        value={dialogNode.template}
        onChange={handleChange}
        onPaste={handlePaste}
      />

      {!Utilities.isEmpty(dialogNode.data) && <AdaptiveCardData data={dialogNode.data} />}

      {children}

      {!dialogNode.isValid && <AdaptiveCardErrorMessages dialogNode={dialogNode} />}

      <CommunicationNodeConfigurations dialogNode={dialogNode} />
    </DialogNode>
  );
};

export default observer(InfoAdaptiveCardNode);
