import { IAvatarTexture } from '../../../architecture/interfaces/HTTP/AdminParams';
import { HttpAgent } from '../../../utility/HttpAgent';

export class AvatarTexturesConnector {
  static async getAll(subscriptionId: string): Promise<IAvatarTexture[]> {
    return await HttpAgent.requests.get(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/AvatarTextures`
    );
  }

  static async delete(subscriptionId: string, name: string): Promise<null> {
    return await HttpAgent.requests.delete(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/AvatarTextures?name=${name}`
    );
  }

  static async upload(subscriptionId: string, name: string, files: FileList) {
    const formData = new FormData();
    Array.from(files).forEach((file, index) => {
      formData.append(`file${index}`, file);
    });

    return await HttpAgent.requests.post(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/AvatarTextures?name=${name}`,
      formData
    );
  }
}
