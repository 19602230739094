import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DialogsSubmenu } from '../../../../architecture/enums/Navbar';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import NavLink from '../components/NavLink';
import Save from '../components/Save';

const DialogsSubNavbar: React.FC = () => {
  const history = useHistory();
  const getIcon = useGetIcon();
  const location = useLocation();

  const { dialogStore } = useContext(rootStore);
  const [activeSubmenu, setActiveSubmenu] = useState('');

  const dialogId = dialogStore.currentlyEditedDialog?.dialogId;

  useEffect(() => {
    if (location.pathname.includes('history')) {
      setActiveSubmenu(DialogsSubmenu.History);
    } else if (location.pathname.includes('bot-settings')) {
      setActiveSubmenu(DialogsSubmenu.Settings);
    } else if (location.pathname.includes('credits')) {
      setActiveSubmenu(DialogsSubmenu.Credits);
    } else if (
      location.pathname.includes('designer') ||
      location.pathname.includes('knowledgebase') ||
      location.pathname.includes('blocks')
    ) {
      setActiveSubmenu(DialogsSubmenu.Designer);
    } else {
      setActiveSubmenu(DialogsSubmenu.Overview);
    }
  }, [location.pathname, dialogStore.currentlyEditedDialog]);

  const openDialog = async () => {
    if (dialogStore.currentlyEditedDialog) {
      await dialogStore.setDialogToEdit(dialogStore.currentlyEditedDialog);
      window.scrollTo({ top: 0, left: 0 });

      history.push(`/dialogs/${dialogId}/designer`);
    } else {
      history.push('/');
    }
  };

  const showDesignerSubNavbar = location.pathname !== '/';
  const showBackButton =
    dialogStore.currentlyEditedDialog &&
    (location.pathname.includes('/blocks') ||
      location.pathname.includes('/knowledgebase') ||
      location.pathname.includes('/history/') ||
      location.pathname.includes('/bot-settings/'));

  return (
    <>
      {showDesignerSubNavbar && (
        <div className='subnavbar-menu'>
          <div className='control-subnavbar-menu'>
            {showBackButton && (
              <NavLink
                label='Back'
                icon={getIcon('back')}
                onClick={() => history.goBack()}
              />
            )}
            {dialogStore.currentlyEditedDialog && <Save />}
          </div>
          <div className='flex'>
            <NavLink
              label={DialogsSubmenu.Overview}
              active={activeSubmenu === DialogsSubmenu.Overview}
              routeTo='/'
              checkForUnsavedChanges={true}
            />
            <NavLink
              label={DialogsSubmenu.Designer}
              active={activeSubmenu === DialogsSubmenu.Designer}
              onClick={openDialog}
            />
            <NavLink
              label={DialogsSubmenu.History}
              active={activeSubmenu === DialogsSubmenu.History}
              routeTo={`/dialogs/${dialogId}/history`}
              checkForUnsavedChanges={true}
            />
            <NavLink
              label={DialogsSubmenu.Credits}
              active={activeSubmenu === DialogsSubmenu.Credits}
              routeTo={`/dialogs/${dialogId}/credits`}
              checkForUnsavedChanges={true}
            />
            <NavLink
              label={DialogsSubmenu.Settings}
              active={activeSubmenu === DialogsSubmenu.Settings}
              routeTo={`/dialogs/${dialogId}/bot-settings`}
              checkForUnsavedChanges={true}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default observer(DialogsSubNavbar);
