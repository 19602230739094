import { Expose, Type } from 'class-transformer';

export class StorageEntry {
  @Expose()
  id: string;

  @Expose()
  data: string;

  @Expose()
  @Type(() => Date)
  created: Date;

  constructor(id: string, data: string, created: Date) {
    this.id = id;
    this.data = data;
    this.created = created;
  }
}
