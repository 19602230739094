import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { UserInputTrigger as UserInputTriggerModel } from '../../../models/Triggers/UserInputTrigger';
import { Text } from '../../../models/Utilities/Text';
import Input from '../../common/Input';
import TextInputWithDelete from '../../common/inputElements/TextInputWithDelete';
import { useReadOnly } from '../../customHooks/useReadOnly';

interface IProps {
  block: DialogBlockModel;
}
const UserInputTrigger: React.FC<IProps> = ({ block }) => {
  const [newUserInputValue, setNewUserInputValue] = useState('');
  const trigger = block.trigger as UserInputTriggerModel;
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  useEffect(() => {
    return () => {
      if (!trigger?.isValid) {
        block.removeTrigger();
      }
    };
  }, []);

  const handleNewUserInputValue = () => {
    if (newUserInputValue)
      trigger.values = [new Text(newUserInputValue), ...trigger.values];
    reset();
  };

  const deleteTriggerValue = (textId: string) => {
    trigger.values = trigger.values.filter((val) => val.id !== textId);
  };

  const reset = () => {
    setNewUserInputValue('');
  };

  return (
    <div>
      {!readOnly && (
        <Input
          name='new-user-input-value'
          className='with-border mt-2'
          value={newUserInputValue}
          autoFocus
          inputFactory
          textAlign='center'
          changeHandler={setNewUserInputValue}
          blurHandler={handleNewUserInputValue}
        />
      )}
      <div className='user-input-trigger-values'>
        <hr />
        {trigger.values.map((text) => (
          <TextInputWithDelete
            disabled={readOnly}
            key={text.id}
            textObject={text}
            deleteHandler={() => deleteTriggerValue(text.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default observer(UserInputTrigger);
