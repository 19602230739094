import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import rootStore from '../../stores/rootStore';
import Head from './Head';
import SubscriptionRow from './SubscriptionRow';

const SubsciptionTable: React.FC = () => {
  const { subscriptionStore } = useContext(rootStore);

  return (
    <>
      <div className='table-wrapper'>
        <table className='table'>
          <Head headers={['Name', 'Roles']} withControlColumn />
          <tbody>
            {subscriptionStore.allSubscriptions.map((subscription, index) => (
              <SubscriptionRow key={index} subscription={subscription} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default observer(SubsciptionTable);
