import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { ActionAdaptiveCardNode } from '../../../../models/DialogNodes/ActionNodes/ActionAdaptiveCardNode';
import { ApiActionNode } from '../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { ConfirmationActionNode } from '../../../../models/DialogNodes/ActionNodes/ConfirmationActionNode';
import { ContextActionNode } from '../../../../models/DialogNodes/ActionNodes/ContextActionNode';
import { DatasetActionNode } from '../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import { EmailActionNode } from '../../../../models/DialogNodes/ActionNodes/EmailActionNode';
import { StorageActionNode } from '../../../../models/DialogNodes/ActionNodes/StorageActionNode';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import { InvalidArgumentError } from '../../../../models/errors/InvalidArgumentError';
import { InvalidOperationError } from '../../../../models/errors/InvalidOperationError';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import NodeTypeSelector from '../../../dialogElements/nodes/typeSelectors/NodeTypeSelector';
import Button from '../../Button';

// type NodeTypes = 'api' | 'email' | 'contextVariable' | 'card'
type NodeTypes =
  | DialogNodeTypes.ApiActionNode
  | DialogNodeTypes.EmailActionNode
  | DialogNodeTypes.ContextActionNode
  | DialogNodeTypes.ActionAdaptiveCardNode
  | DialogNodeTypes.DatasetActionNode
  | DialogNodeTypes.StorageActionNode
  | DialogNodeTypes.ConfirmationActionNode;

const ActionNodeTypeSelector = () => {
  // Custom Hooks
  const getIcon = useGetIcon();
  const { closeModal } = useModal();

  // React Context
  const { blockStore, nodeStore, uiStore } = useContext(rootStore);

  const actionNodeTypeInfo = {
    email: {
      title: 'Send an email',
      body: 'Lailo sends an e-mail to the provided address. You can set the subject and write a simple text mail, for example an order confirmation. Usage of Context Variables is possible.',
    },
    api: {
      title: 'Make an API call',
      body: 'Lailo can create an HTTP-Request to make an API call. This process happens asynchronously. For example if the user is curious about weather details Lailo can make a GET-Request to a weather-API. Usage of Context Variables is possible.',
    },
    contextVariable: {
      title: 'Manage Context Variables',
      body: 'There are multiple ways of setting up a context variable. You can create one in conjuntion with a Question Dialog Node or by selecting values from an API response. Or you can create a Context Node to manually create a Context Variable that is not bound to a Question Node. You can also manually delete existing Context Variables if the dialog reaches this node.',
    },
    card: {
      title: 'Send an Action Card',
      body: 'Create an Action Card which can contain input fields and must contain Submit Actions (Action.Submit on adaptivecards.io). This card can be used to trigger a new flow of the dialog by entering a pre-defined dialog block at any time. The conversation is not stopped after sending an Action Card.',
    },
    dataset: {
      title: 'Manage Datasets',
      body: 'Datasets help to organize larger sets of data. Define their structure in the Dataset menu and add or update Dataset Variables with this node.',
    },
    storage: {
      title: 'Manage Storage',
      body: 'After all necessary data is collected and organized in a Dataset, this node is able to persistently store data from the User.',
    },
    confirmation: {
      title: 'Confirm Dataset',
      body: 'It may happen that the user has accidentally entered incorrect data. To give him or her the opportunity to correct these errors, the Confirmation Node presents the user with a form in which all fields of a particular Dataset can be changed.',
    },
  };

  const [activeInfo, setActiveInfo] = useState<{ title: string; body: string }>();

  useEffect(() => {
    setActiveInfo(actionNodeTypeInfo['email']);
  }, []);

  const handleClick = (nodeType: NodeTypes) => {
    if (!blockStore.selectBlock) {
      throw new InvalidOperationError('Block must be set.');
    }

    let node: BaseDialogNode;
    switch (nodeType) {
      case DialogNodeTypes.EmailActionNode:
        node = new EmailActionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.ApiActionNode:
        node = new ApiActionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.ContextActionNode:
        node = new ContextActionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.ActionAdaptiveCardNode:
        node = new ActionAdaptiveCardNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.DatasetActionNode:
        node = new DatasetActionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.StorageActionNode:
        node = new StorageActionNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.ConfirmationActionNode:
        node = new ConfirmationActionNode(blockStore.selectedBlock!);
        break;
      default:
        throw new InvalidArgumentError('Node type unkonwn');
    }

    if (nodeStore.selectedParentNode) {
      nodeStore.insertAfter(node, nodeStore.selectedParentNode);
    } else {
      // If there is no selected parent node in the first place, the node will be inserted at the bottom of the block
      // Which means that auto scrolling is desired
      uiStore.scrollToElement(uiStore.nodeListContainer!, 'bottom');
      nodeStore.append(node);
    }

    closeModal();
  };

  const btnGroup = () => (
    <Fragment>
      <Button
        icon={getIcon('envelope')}
        content='Send an E-Mail'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['email'])}
        clickHandler={() => handleClick(DialogNodeTypes.EmailActionNode)}
      />
      <Button
        icon={getIcon('robot')}
        content='Call an API'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['api'])}
        clickHandler={() => handleClick(DialogNodeTypes.ApiActionNode)}
      />
      <Button
        icon={getIcon('config')}
        content='Manage Context Variables'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['contextVariable'])}
        clickHandler={() => handleClick(DialogNodeTypes.ContextActionNode)}
      />
      <Button
        icon={getIcon('card')}
        content='Send an Action Card'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['card'])}
        clickHandler={() => handleClick(DialogNodeTypes.ActionAdaptiveCardNode)}
      />
      <Button
        icon={getIcon('table')}
        content='Manage Datasets'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['dataset'])}
        clickHandler={() => handleClick(DialogNodeTypes.DatasetActionNode)}
      />
      <Button
        icon={getIcon('database')}
        content='Manage Storage'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['storage'])}
        clickHandler={() => handleClick(DialogNodeTypes.StorageActionNode)}
      />
      <Button
        icon={getIcon('check_double')}
        content='Confirm Dataset'
        className='btn-round btn-primary'
        mouseEnterHandler={() => setActiveInfo(actionNodeTypeInfo['confirmation'])}
        clickHandler={() => handleClick(DialogNodeTypes.ConfirmationActionNode)}
      />
    </Fragment>
  );

  return (
    <NodeTypeSelector
      activeInfo={activeInfo!}
      title='After the current Dialog Node I would like to...'
      btnGroup={btnGroup()}
    />
  );
};

export default ActionNodeTypeSelector;
