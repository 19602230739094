import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Condition as ConditionModel } from '../../../../models/Conditions/Condition';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import ConditionWrapper from './ConditionWrapper';

interface IProps {
  conditions: ConditionModel[];
  canAddCondition?: boolean;
  addNewConditionHandler: () => void;
  removeHandler: (condition: ConditionModel) => void;
}
const ConditionContainer: React.FC<IProps> = ({
  conditions,
  addNewConditionHandler,
  removeHandler,
  canAddCondition = true,
}) => {
  const getIcon = useGetIcon();

  return (
    <Fragment>
      {canAddCondition && (
        <Button
          icon={getIcon('add')}
          content='New condition'
          className='btn-borderless add-condition-btn bold'
          style={{ background: 'transparent' }}
          tooltipPosition='right'
          clickHandler={addNewConditionHandler}
        />
      )}
      {conditions.map((condition) => (
        <ConditionWrapper
          key={condition.id}
          condition={condition}
          removeHandler={removeHandler}
        />
      ))}
    </Fragment>
  );
};

export default observer(ConditionContainer);
