import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

// The RestoreStructure component was meant for the auto-save feature and is not
// used at the moment. See LocalStorageStore for more information.
const RestoreStructure = () => {
  const { dialogStore, localStorageStore } = useContext(rootStore);
  const [modifiedDate, setModifiedDate] = useState('');

  const { closeModal } = useModal();
  const getIcon = useGetIcon();

  const handleRestoreChanges = () => {
    dialogStore.restoreDialogFromStorage();
    closeModal();
  };

  useEffect(() => {
    if (dialogStore.currentlyEditedDialog?.dialogId) {
      const storedDialog = localStorageStore.getStoredDialog(
        dialogStore.currentlyEditedDialog.dialogId
      );
      if (storedDialog) {
        setModifiedDate(storedDialog.modified);
      }
    }
  }, [dialogStore.currentlyEditedDialog?.dialogId]);

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>
          You have unsaved changes from{' '}
          {modifiedDate && format(new Date(modifiedDate), 'dd.MM.yyyy HH:mm')}.
        </h3>
        <p className='header-description'>Do you want to restore them?</p>
      </div>
      <div className='btn-container'>
        <Button
          content='Yes'
          icon={getIcon('check_solid')}
          className='btn-primary btn-round'
          clickHandler={handleRestoreChanges}
        />
        <Button
          content='No'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={closeModal}
        />
      </div>
    </div>
  );
};

export default observer(RestoreStructure);
