import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import Modal from '../../../common/modal/Modal';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import DeleteData from './DeleteData';
import ExportData from './ExportData';

interface IProps {
  selectedRows: string[];
  from?: Date;
  setFrom: (date: Date | undefined) => void;
  to?: Date;
  setTo: (date: Date | undefined) => void;
}

const DataListHeader: React.FC<IProps> = ({ selectedRows, from, to, setFrom, setTo }) => {
  const { datasetStore } = useContext(rootStore);
  const [openExportDataModal, setOpenExportDataModal] = useState(false);
  const [openDeleteDataModal, setOpenDeleteDataModal] = useState(false);

  const getIcon = useGetIcon();

  return (
    <div className='header'>
      <div className='flex'>
        <div className='title'> {datasetStore.getSelectedDataset?.name}</div>
        <div className='filter-wrapper'>
          <div>From:</div>
          <DateTimePicker
            calendarIcon={<i className={`${getIcon('calendar')}`}> </i>}
            calendarClassName=''
            value={from}
            disableClock={true}
            format='dd.MM.yyyy HH:mm'
            onChange={(date) => {
              setFrom(date);
            }}
          />
          <i
            className={`${getIcon('close')} pointer`}
            onClick={() => setFrom(undefined)}
          ></i>
          <div className='ml-2'>To:</div>
          <DateTimePicker
            calendarIcon={<i className={`${getIcon('calendar')}`}> </i>}
            calendarClassName=''
            value={to}
            disableClock={true}
            format='dd.MM.yyyy HH:mm'
            onChange={(date) => {
              setTo(date);
            }}
          />
          <i
            className={`${getIcon('close')} pointer`}
            onClick={() => setTo(undefined)}
          ></i>
        </div>
      </div>
      <div className='button-wrapper'>
        <Button
          disabled={selectedRows.length === 0}
          content='Delete Data'
          icon={getIcon('remove')}
          className='btn-inactive btn-round'
          clickHandler={() => setOpenDeleteDataModal(true)}
        />
        <Button
          disabled={selectedRows.length === 0}
          content='Export Data'
          icon={getIcon('download')}
          className='btn-primary btn-round'
          clickHandler={() => setOpenExportDataModal(true)}
        />
      </div>
      <Modal
        manuallyTriggered={openExportDataModal}
        closeHandler={() => setOpenExportDataModal(false)}
      >
        <ExportData
          resetModal={() => setOpenExportDataModal(false)}
          selectedRows={selectedRows}
        />
      </Modal>
      <Modal
        manuallyTriggered={openDeleteDataModal}
        closeHandler={() => setOpenDeleteDataModal(false)}
      >
        <DeleteData
          resetModal={() => setOpenDeleteDataModal(false)}
          selectedRows={selectedRows}
        />
      </Modal>
    </div>
  );
};

export default observer(DataListHeader);
