import IntentStore from '../../stores/IntentStore';
import { HttpAgent } from '../../utility/HttpAgent';

export default class IntentConnector {
  private static get intentStore() {
    return IntentStore.getInstance();
  }

  static async get(botId: string | undefined, dialogId: string) {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Intents`
    );
  }

  static async update(botId: string | undefined, dialogId: string) {
    const body: IntentStructure = {
      add: {
        intents: this.getAddedIntents(),
        labels: this.getAddedLabels(),
      },
      update: {
        intents: this.getUpdateIntents(),
        labels: this.getUpdateLabels(),
      },
      delete: this.GetDelete(),
    };
    return await HttpAgent.requests.post(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/Intents`,
      body
    );
  }

  //#region Setting up Add Objects
  private static getAddedIntents(): AddIntent[] {
    const list = this.intentStore.activeIntents
      .filter((intent) => intent.state === 'Added')
      .map((intent) => ({
        name: intent.name,
        labels: intent.labels.map((label) => label.text.value),
      }));

    return list;
  }
  private static getAddedLabels(): AddLabel[] {
    const list = this.intentStore.activeIntents
      .filter((intent) => intent.state !== 'Added')
      .filter((intent) => intent.labels.some((label) => label.state === 'Added'))
      .map((intent) => ({
        intent: intent.name,
        labels: intent.labels
          .filter((label) => label.state === 'Added')
          .map((label) => label.text.value),
      }));

    return list;
  }
  //#endregion

  //#region Setting up Update Objects

  private static getUpdateIntents(): UpdateIntent[] {
    const list = this.intentStore.activeIntents
      .filter((intent) => intent.state === 'Updated')
      .map((intent) => ({
        id: intent.id,
        name: intent.name,
      }));

    return list;
  }

  private static getUpdateLabels(): UpdateLabel[] {
    const list = this.intentStore.activeIntents
      .map((intent) =>
        intent.labels
          .filter((label) => label.state === 'Updated')
          .map((label) => ({
            id: label.id,
            text: label.text.value,
          }))
      )
      .flat();

    return list;
  }

  //#endregion

  //#region Setting up Delete Objects

  private static GetDelete(): Delete {
    return {
      intentIds: this.intentStore.allIntents
        .filter((intent) => intent.state === 'Deleted')
        .map((intent) => intent.id),
      labelIds: this.intentStore.allIntents
        .map((intent) =>
          intent.labels
            .filter((label) => label.state === 'Deleted')
            .map((label) => label.id)
        )
        .flat(),
    };
  }

  //#endregion
}

interface IntentStructure {
  add: Add;
  update: Update;
  delete: Delete;
}
interface Add {
  intents: AddIntent[];
  labels: AddLabel[];
}

interface AddIntent {
  name: string;
  labels: string[];
}

interface AddLabel {
  intent: string;
  labels: string[];
}

interface Delete {
  intentIds: string[];
  labelIds: number[];
}

interface Update {
  intents: UpdateIntent[];
  labels: UpdateLabel[];
}

interface UpdateIntent {
  id: string;
  name: string;
}

interface UpdateLabel {
  id: number;
  text: string;
}
