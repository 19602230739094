import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import LinkItem from './LinkItem';

interface IProps {
  dialogNode: CommunicationDialogNode;
}

const Links: React.FC<IProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();

  const addNewUrlHandler = () => {
    dialogNode.addAction(new ActionAttachment('urlRedirect'));
  };

  const handleRemoveAction = (id: string) => {
    const action = dialogNode.actions.find((action) => action.id === id);
    if (!action) return;

    dialogNode.removeAction(action);
  };

  const renderUrls = () => {
    return dialogNode.urlRedirectActions.map((action, key) => {
      const saveData = (url: string, caption: string) => {
        const data = { url, caption };
        action.data = JSON.stringify(data);
      };

      return (
        <LinkItem
          data={action.data !== '' ? JSON.parse(action.data) : null}
          key={key}
          action={action}
          handleRemoveAction={() => handleRemoveAction(action.id)}
          saveData={saveData}
        />
      );
    });
  };

  return (
    <div className='urls-container'>
      <Button
        icon={getIcon('add')}
        content='New Link'
        className='btn-borderless add-condition-btn bold'
        style={{ background: 'transparent' }}
        tooltipPosition='right'
        clickHandler={addNewUrlHandler}
        disabled={!dialogNode.areUrlRedirectActionsValid}
      />
      <div className='header'>
        <div className='headers-item'>URL</div>
        <div className='headers-item'>Caption</div>
      </div>
      {renderUrls()}
    </div>
  );
};

export default observer(Links);
