export const PAGE_SIZE = 25;
export const DESCENDING_SORT = 1;
export const ASCENDING_SORT = 0;

export const FILTERS = {
  SORT: {
    NAME: 'sort',
  },
  PAGE: {
    NAME: 'page',
  },
  USER_MESSAGE: {
    NAME: 'userMessage',
    TITLE: 'User Message',
  },
  SCORE: {
    NAME: 'score',
    TITLE: 'Score',
  },
  DATE: {
    NAME: 'date',
    TITLE: 'Date',
  },
  FROM_DATE: {
    NAME: 'from',
    TITLE: 'From:',
  },
  TO_DATE: {
    NAME: 'to',
    TITLE: 'To:',
  },
  FROM_SCORE: {
    NAME: 'fromScore',
    TITLE: 'From:',
  },
  TO_SCORE: {
    NAME: 'toScore',
    TITLE: 'To:',
  },
  CHANNEL: {
    NAME: 'channel',
    TITLE: 'Channel',
  },
  TRIGGER_BLOCK_NAME: {
    NAME: 'triggerBlockName',
    TITLE: 'Block',
  },
  EVENT_ID: {
    NAME: 'eventId',
    TITLE: 'Event',
  },
  TRIGGER_TYPE: {
    NAME: 'triggerType',
    TITLE: 'Trigger',
  },
  SESSION_ID: {
    NAME: 'sessionId',
    TITLE: 'Session',
  },
  REVIEWED: {
    NAME: 'reviewed',
    TITLE: 'Reviewed',
  },
};

export const CHANNEL_OPTIONS = [
  {
    label: 'directline',
    title: 'DirectLine',
  },
  {
    label: 'msteams',
    title: 'Microsoft Teams',
  },
  {
    label: 'telegram',
    title: 'Telegram',
  },
  {
    label: 'emulator',
    title: 'Emulator',
  },
  {
    label: 'webchat',
    title: 'Webchat',
  },
];

export const TRIGGER_TYPE_OPTIONS = [
  {
    label: 'DefaultTrigger',
    title: 'Not Triggered',
  },
  {
    label: 'ListTrigger',
    title: 'List Trigger',
  },
  {
    label: 'RegexTrigger',
    title: 'RegExp Trigger',
  },
  {
    label: 'KnowledgebaseTrigger',
    title: 'Knowledgebase',
  },
  {
    label: 'LuisIntentTrigger',
    title: 'Intent Trigger',
  },
  {
    label: 'EventTrigger',
    title: 'Event Trigger',
  },
];

export const PROMPT_NODE_TITLES = {
  TextPromptNode: 'Text Question Node',
  ConfirmPromptNode: 'Confirm Question Node',
  NumberPromptNode: 'Number Question Node',
  DateTimePromptNode: 'DateTime Question Node',
  ChoicePromptNode: 'Choice Question Node',
  AddressPromptNode: 'Address Question Node',
  NamePromptNode: 'Name Question Node',
  EmailPromptNode: 'Email Question Node',
  PhonePromptNode: 'Phone Question Node',
  EntityPromptNode: 'Custom Entity Question Node',
  AttachmentPromptNode: 'Attachment Question Node',
};

export const REVIEWED_OPTIONS = [
  {
    label: 'true',
    title: 'Reviewed',
  },
  {
    label: 'false',
    title: 'Not reviewed',
  },
];

export const PARAMS = {
  USER_MESSAGE: 'User Message',
  FROM: 'From Date',
  TO: 'To Date',
  FROM_SCORE: 'From Score',
  TO_SCORE: 'To Score',
  CHANNEL: 'Channel',
  TRIGGER_BLOCK_NAME: 'Block Name',
  EVENT_ID: 'Event Id',
  TRIGGER_TYPE: 'Trigger Type',
  SESSION_ID: 'Session Id',
  REVIEWED: 'Reviewed',
  FROM_DATE: 'From date',
  TO_DATE: 'To date',
};

export enum HistoryElementFilter {
  ShowAllElements,
  ShowMessagesOnly,
}
