import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ConfirmationActionNode as ConfirmationActionNodeModel } from '../../../../models/DialogNodes/ActionNodes/ConfirmationActionNode';
import { Text as TextModel } from '../../../../models/Utilities/Text';
import rootStore from '../../../../stores/rootStore';
import Input from '../../../common/Input';
import ContextVariableSelector from '../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import Messages from '../communicationNode/Messages';
import DialogNode from '../DialogNode';

interface IProps {
  dialogNode: ConfirmationActionNodeModel;
}

const ConfirmationActionNode: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const handleCtxSelection = (value: string) => {
    dialogNode.contextVariable = ctxVarStore.getByName(value);
  };

  const addNewMessage = (message: TextModel) => {
    dialogNode.addMessage(message);
  };

  const removeMessage = (msg: TextModel) => {
    dialogNode.removeMessage(msg);
  };

  return (
    <DialogNode dialogNode={dialogNode as ConfirmationActionNodeModel}>
      <Messages
        messages={dialogNode.messages}
        newMessageHandler={addNewMessage}
        removeMessageHandler={removeMessage}
      />
      <div className='dataset-variable-selection'>
        <span className='description'>
          The user will be asked to confirm the correct values of the following <br />
          <span className='bold'> Dataset Variable</span>:
        </span>
        <ContextVariableSelector
          items={ctxVarStore.datasetVariables}
          triggerLabel={dialogNode.contextVariable?.name ?? 'Select'}
          selectHandler={handleCtxSelection}
          replaceSpecialCharacters
          closeOnSelect
        />
      </div>
      <div className='annotation'>
        For the confirmation view, the annotations which were defined for each dataset
        will be used. The following annotation will be used for the given Dataset
        Variable, which can be seen as a 'heading' of the validation form.
        <div className='annotation-form'>
          <span>Annotation:</span>
          <Input
            className='confirmation-annotation-input'
            value={dialogNode.annotation}
            changeHandler={(text) => (dialogNode.annotation = text)}
          />
        </div>
      </div>
    </DialogNode>
  );
};

export default observer(ConfirmationActionNode);
