import { Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { SubQuestionKey } from '../../../../architecture/enums/SubQuestionKey';
import ContextVariableStore from '../../../../stores/ContextVariableStore';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import {
  ISerializedMultiQuestionDialogNode,
  BaseMultiQuestionNode
} from '../BaseMultiQuestionNode';
import { ISerializedSubQuestion, SubQuestion } from './SubQuestion';

export abstract class MultiQuestionNode extends BaseMultiQuestionNode {
  @Expose()
  @Type(() => SubQuestion)
  subQuestions: SubQuestion[] = [];

  allowedSubQuestionKeys: SubQuestionKey[];
  constructor(block: DialogBlock, allowedSubQuestionKeys: Array<SubQuestionKey>) {
    super(block);
    this.allowedSubQuestionKeys = allowedSubQuestionKeys;
    
    makeObservable(this, {
        subQuestions: observable,
    })
  }

  get isValid() {
    return super.isValid && this.areSubQuestionsValid;
  }

  get areSubQuestionsValid() {
    if (this.subQuestions.length === 0) return false;

    return this.subQuestions.every((question) => question.isValid);
  }

  isKeyStored(key: SubQuestionKey): boolean {
    return this.subQuestions.some((item) => item.key === key);
  }

  getSubQuestionByKey(key: SubQuestionKey) {
    return this.subQuestions.find((item) => item.key === key);
  }

  getSubQuestionById(id: string) {
    return this.subQuestions.find(item => item.id === id);
  }

  addSubQuestion(key: SubQuestionKey): SubQuestion {
    if (!(this.allowedSubQuestionKeys as SubQuestionKey[]).includes(key)) {
      throw new Error(
        `SubQuesiton Key (${key}) is not a member of the MultiQuestionNode SubQuestion Keys: ${this.allowedSubQuestionKeys}`
      );
    }

    // Returning an existing instance of the subQuestion if there is one
    const existingSubQuestion = this.getSubQuestionByKey(key);
    if (existingSubQuestion) return existingSubQuestion;

    // Creating a brand new subQuestion instance
    const ctx = ContextVariableStore.getInstance().addIfNotExists(
      this.prefix + this.separator + key
    )!;

    const subQuestion = new SubQuestion(key, ctx);

    this.subQuestions.push(subQuestion);

    return subQuestion;
  }

  removeSubQuestion(key: SubQuestionKey) {
    this.subQuestions = this.subQuestions.filter((question) => question.key !== key);
  }

  serialize() {
    return {
      ...super.serialize(),
      subQuestions: this.subQuestions.map((item) => item.serialize()),
    };
  }
}

export interface ISerializedMultiQuestionNode
  extends ISerializedMultiQuestionDialogNode {
  subQuestions: ISerializedSubQuestion[];
}
