import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { UserConnector } from '../../../models/Connectors/UserConnector';
import Button from '../../common/Button';
import Input from '../../common/Input';
import { useGetIcon } from '../../customHooks/useGetIcon';

const ChangePassword = () => {
  const getIcon = useGetIcon();

  const [newPassword, setNewPassword] = useState('');
  const [newRepeatedPassword, setRepeatedNewPassword] = useState('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [allowSave, setAllowSave] = useState(false);

  const [passwordLengthRule, setPasswordLengthRule] = useState(false);
  const [passwordClassesRule, setPasswordClassesRule] = useState(false);
  const [passwordsMatchRule, setPasswordMatchRule] = useState(false);

  useEffect(() => {
    setSuccess(undefined);
    setError(undefined);

    const passwordLengthCheck = newPassword.length >= 8 && newPassword.length <= 64;
    setPasswordLengthRule(passwordLengthCheck);

    const passwordRepeatCheck =
      newPassword.length > 1 && newPassword === newRepeatedPassword;
    setPasswordMatchRule(passwordRepeatCheck);

    const passwordClassesCheck =
      passwordContainsSpecialCharacter() +
        passwordContainsUppercaseLetter() +
        passwordContainsLowercaseLetter() +
        passwordContainsDigit() >=
      3;

    setPasswordClassesRule(passwordClassesCheck);

    setAllowSave(passwordLengthCheck && passwordRepeatCheck && passwordClassesCheck);
  }, [newPassword, newRepeatedPassword]);

  const save = async () => {
    setLoading(true);
    const successful = await UserConnector.updatePassword(newPassword);
    if (!successful) {
      setError('Password was not updated. Please try again.');
    } else {
      setSuccess('Password has been changed successfully.');
    }
    setLoading(false);
  };

  const passwordContainsSpecialCharacter = (): number => {
    const regex = /[!@#$%^&*()\-_+=[\]{}|\\:;',./?`~"]/;
    return regex.test(newPassword) ? 1 : 0;
  };

  const passwordContainsUppercaseLetter = (): number => {
    const regex = /[A-Z]/;
    return regex.test(newPassword) ? 1 : 0;
  };

  const passwordContainsLowercaseLetter = (): number => {
    const regex = /[a-z]/;
    return regex.test(newPassword) ? 1 : 0;
  };

  const passwordContainsDigit = (): number => {
    const regex = /\d/;
    return regex.test(newPassword) ? 1 : 0;
  };

  const getRuleIcon = (condition: boolean) => {
    return <i className={condition ? getIcon('check_simple') : getIcon('close')} />;
  };

  return (
    <div className='password-page'>
      <div className='password-page-content'>
        <h2 className='title'>Change Password</h2>
        <div className='input-group next-gen'>
          <label htmlFor='new-password'>New Password</label>
          <Input
            id='new-password'
            placeholder='New Password'
            inputType='password'
            value={newPassword}
            changeHandler={(value) => {
              setNewPassword(value);
            }}
            disabled={loading}
          />
          <br />
          <label htmlFor='new-password-repeact'>Repeat new Password</label>
          <Input
            id='new-password-repeact'
            placeholder='Repeat new Password'
            value={newRepeatedPassword}
            inputType='password'
            changeHandler={(value) => {
              setRepeatedNewPassword(value);
            }}
            disabled={loading}
          />
          <div className='btn-container'>
            <Button
              content='Save'
              className='btn-primary btn-round'
              type='button'
              clickHandler={save}
              disabled={loading || !allowSave}
              isLoading={loading}
            />
          </div>
        </div>
        <div className='status-container'>
          <div
            className={`status-message status-message${
              passwordLengthRule ? '-success' : '-info'
            }`}
          >
            {getRuleIcon(passwordLengthRule)}
            <span>Password must be 8-64 characters long.</span>
          </div>
          <div
            className={`status-message status-message${
              passwordClassesRule ? '-success' : '-info'
            }`}
          >
            {getRuleIcon(passwordClassesRule)}
            <span>
              Password must include 3 of 4 character classes: <br />
              uppercase, lowercase, numbers, symbols
            </span>
          </div>
          <div
            className={`status-message status-message${
              passwordsMatchRule ? '-success' : '-info'
            }`}
          >
            {getRuleIcon(passwordsMatchRule)}
            <span>Repeated password must match</span>
          </div>
          {error && <div className='status-message status-message-error'>{error}</div>}
          {success && (
            <div className='status-message status-message-success'>{success}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ChangePassword);
