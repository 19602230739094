import { Exclude, Expose, Type } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { FacialExpressions } from '../../architecture/enums/FacialExpressions';
import { Condition, ISerializedCondition } from '../Conditions/Condition';
import { ContextCondition } from '../Conditions/ContextCondition';
import { CONDITION_DISCRIMINATOR } from '../Conditions/Discriminator';
import { ListContextVariable } from '../ContextVariables/ContextVariable';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { ISerializedMedia, Media } from '../Media/Media';
import {
  TransformIntoActions,
  TransformIntoMedia,
  TransformIntoTextArray,
} from '../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../Utilities/Text';
import { Utilities } from '../Utilities/Utilities';
import { ActionAttachment } from './ActionAttachment';
import { BaseDialogNode, ISerializedDialogNode } from './BaseDialogNode';

export abstract class CommunicationDialogNode extends BaseDialogNode {
  @Exclude()
  private restrictedTypes = ['forceTextInput', 'activateMicrophone'];

  @Expose()
  @TransformIntoTextArray
  messages: Text[];

  @Expose()
  @TransformIntoMedia
  mediaFiles: Media[];

  @Expose()
  facialExpression?: FacialExpressions;

  @Expose()
  @TransformIntoActions
  actions: ActionAttachment[];

  @Expose()
  @Type(() => Condition, CONDITION_DISCRIMINATOR)
  conditions: Condition[];

  constructor(block: DialogBlock) {
    super(block);
    this.mediaFiles = [];
    this.messages = [new Text('', '')];
    this.conditions = [];
    this.actions = [];

    makeObservable(this, {
      messages: observable,
      mediaFiles: observable,
      facialExpression: observable,
      actions: observable,
      conditions: observable,
      activateMicrophone: computed,
      addAction: action,
      removeAction: action,
      addCondition: action,
      removeCondition: action,
      areActionsValid: computed,
    });
  }

  get isValid() {
    return (
      this.areActionsValid && this.conditions.every((condition) => condition.isValid)
    );
  }

  get areActionsValid() {
    return this.actions.every((action) => action.isValid);
  }

  get displayedActions() {
    return this.actions.filter((item) => !this.restrictedTypes.includes(item.type));
  }

  get genericActions() {
    return this.displayedActions.filter((action) => action.type !== 'urlRedirect');
  }

  get areGenericActionsValid() {
    return this.genericActions.every((action) => action.isValid);
  }

  get areUrlRedirectActionsValid() {
    return this.urlRedirectActions.every((action) => action.isValid);
  }

  get urlRedirectActions() {
    return this.displayedActions.filter((action) => action.type === 'urlRedirect');
  }

  get activateMicrophone() {
    return this.actions.find((item) => item.type === 'activateMicrophone');
  }

  addMessage(text: string | Text) {
    const message = text instanceof Text ? text : new Text(text);
    this.messages.push(message);
  }

  addMediaFiles(files: Media[]) {
    this.mediaFiles = files;
  }

  removeMediaFile(file: Media) {
    this.mediaFiles = this.mediaFiles.filter((media) => media !== file);
  }

  removeMessage(msg: Text) {
    this.messages = this.messages.filter((message) => msg !== message);
  }

  addAction(action: ActionAttachment) {
    this.actions.unshift(action);
  }

  removeAction(action: ActionAttachment) {
    this.actions = this.actions.filter((item) => item !== action);
  }

  addCondition(condition: Condition) {
    if (condition instanceof ContextCondition) {
      condition.operator =
        condition.ctx instanceof ListContextVariable ? 'Includes' : 'IsSet';
    }
    this.conditions.unshift(condition);
  }

  removeCondition(condition: Condition) {
    this.conditions = this.conditions.filter((item) => item !== condition);
  }

  serialize(): ISerializedCommunicationDialogNode {
    return {
      ...super.serialize(),
      messages: this.messages.map((msg) => msg.serialize()),
      actions: Utilities.isEmpty(this.actions)
        ? null
        : JSON.stringify(this.actions.map((item) => item.serialize())),
      facialExpression: this.facialExpression,
      conditions: this.conditions.map((item) => item.serialize()),
      mediaFiles: this.mediaFiles.map((item) => item.serialize()),
    };
  }
}

export interface ISerializedCommunicationDialogNode extends ISerializedDialogNode {
  messages: ISerializedText[];
  actions: string | null;
  facialExpression?: FacialExpressions;
  conditions: ISerializedCondition[];
  mediaFiles: ISerializedMedia[];
}
