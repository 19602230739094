import { observable, makeObservable } from 'mobx';
import { NodeHistoryElement } from './NodeHistoryElement';

export class KnowledgebaseNodeElement extends NodeHistoryElement {
  text: string;

  constructor() {
    super();
    this.text = '';

    makeObservable(this, {
      text: observable,
    });
  }
}
