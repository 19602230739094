import { makeObservable, observable, action } from 'mobx';
import UIStore from '../../stores/UIStore';
import { v4 } from 'uuid';

type NotificationType = 'info' | 'success' | 'warning' | 'error';

interface NotificationInitObject {
  text: string;
  type?: NotificationType;
  autoRemove?: boolean;
}

export class Notification {
  id: string;
  text: string;
  type: NotificationType;
  autoRemove: boolean;

  constructor({ text, type = 'info', autoRemove = true }: NotificationInitObject) {
    this.id = v4();
    this.text = text;
    this.type = type;
    this.autoRemove = autoRemove;

    makeObservable(this, {
      id: observable,
      text: observable,
      remove: action,
    });

    UIStore.getInstance().notifications.push(this);

    if (this.autoRemove) {
      setTimeout(() => this.remove(), 2500);
    }
  }

  remove() {
    UIStore.getInstance().notifications = UIStore.getInstance().notifications.filter(
      (n) => n !== this
    );
  }
}
