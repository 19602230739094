import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

const Canvas = () => {
  const canvasRef = useRef(null);

  const location = useLocation();

  const [show, setShow] = useState<boolean>(false);

  const getCanvas = () => {
    return canvasRef.current as unknown as SVGSVGElement;
  };

  useEffect(() => {
    setShow(location.pathname.includes('designer'));
  }, [location, setShow]);

  useEffect(() => {
    addMarkers(getCanvas());
    getCanvas().setAttributeNS(
      'http://www.w3.org/2000/xmlns/',
      'xmlns:xlink',
      'http://www.w3.org/1999/xlink'
    );
  }, []);

  const addMarkers = (svgCanvasElement: SVGSVGElement) => {
    let definitions = svgCanvasElement.getElementsByTagName('defs')[0];
    if (!definitions) {
      definitions = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
      svgCanvasElement.appendChild(definitions);
    }

    const arrowMarker = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
    arrowMarker.setAttribute('id', 'arrow');
    arrowMarker.setAttribute('viewBox', '0 0 10 10');
    arrowMarker.setAttribute('refX', '0');
    arrowMarker.setAttribute('refY', '5');
    arrowMarker.setAttribute('markerUnits', 'strokeWidth');
    arrowMarker.setAttribute('markerWidth', '10');
    arrowMarker.setAttribute('markerHeight', '10');
    arrowMarker.setAttribute('orient', 'auto');
    const arrowMarkerPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    arrowMarkerPath.setAttribute('d', 'M 0 0 L 10 5 L 0 10 z');
    arrowMarker.setAttribute('fill', 'rgb(110, 110, 110)');
    arrowMarker.appendChild(arrowMarkerPath);
    definitions.appendChild(arrowMarker);

    const dotMarker = document.createElementNS('http://www.w3.org/2000/svg', 'marker');
    dotMarker.setAttribute('id', 'dot');
    dotMarker.setAttribute('viewBox', '0 0 10 10');
    dotMarker.setAttribute('refX', '10');
    dotMarker.setAttribute('refY', '5');
    dotMarker.setAttribute('markerUnits', 'strokeWidth');
    dotMarker.setAttribute('markerWidth', '10');
    dotMarker.setAttribute('markerHeight', '10');
    dotMarker.setAttribute('orient', 'auto');
    const dotMarkerCircle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
    );
    dotMarkerCircle.setAttribute('cx', '5');
    dotMarkerCircle.setAttribute('cy', '5');
    dotMarkerCircle.setAttribute('r', '5');
    dotMarkerCircle.setAttribute('fill', 'rgb(110, 110, 110)');
    dotMarker.appendChild(dotMarkerCircle);
    definitions.appendChild(dotMarker);
  };

  return ReactDOM.createPortal(
    <svg
      ref={canvasRef}
      id='svg-canvas'
      style={{ display: show ? 'block' : 'none' }}
    ></svg>,
    document.getElementById('canvas-root')!
  );
};

export default observer(Canvas);
