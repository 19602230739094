import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DialogLanguages } from '../../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import { useQuery } from '../../../customHooks/useQuery';
import NavLink from '../components/NavLink';
import Save from '../components/Save';

const PronunciationSubNavbar: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = useQuery();

  const languageQuery = queryParams['language'];

  const languageLabels = Object.keys(DialogLanguages);
  if (!languageLabels) throw new Error('No languages configured');

  // Automatically route to the first configured language
  if (
    location.pathname === '/settings/pronunciation' &&
    (!languageQuery || !languageLabels.includes(languageQuery))
  ) {
    history.push(`/settings/pronunciation?language=${languageLabels[0]}`);
  }

  const [activeSubmenu, setActiveSubmenu] = useState(languageQuery);

  useEffect(() => {
    setActiveSubmenu(languageQuery);
  }, [location.search]);

  const renderLinks = () => {
    return languageLabels.map((language) => {
      return (
        <NavLink
          key={language}
          label={language}
          active={activeSubmenu === language}
          routeTo={`/settings/pronunciation?language=${language}`}
        />
      );
    });
  };

  return (
    <div className='subnavbar-menu'>
      <div className='control-subnavbar-menu'>
        <Save />
      </div>
      <div className='flex'>{renderLinks()}</div>
    </div>
  );
};

export default observer(PronunciationSubNavbar);
