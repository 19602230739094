import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Image } from '../../../architecture/enums/ConfirmationSettings';
import { MediaTypes } from '../../../architecture/enums/MediaType';
import { ConfirmationSettings } from '../../../models/Bot/ConfirmationSettings';
import rootStore from '../../../stores/rootStore';
import Modal from '../../common/modal/Modal';
import SelectMediaList from '../../media/selectMedia/SelectMediaList';
import ImageField from '../components/ImageField';
import SettingsInfoIcon from '../components/SettingsInfoIcon';

interface IProps {
  formik: any;
}

const Images: React.FC<IProps> = ({ formik }) => {
  const { botSettingsStore } = useContext(rootStore);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  return (
    <div className='properties-wrapper'>
      <h3 className='title'>Images</h3>
      <div className='images-wrapper'>
        <div className='input-group'>
          <label htmlFor={Image.BackgroundImage}>
            Background Image{' '}
            <SettingsInfoIcon tooltip='Optimal resolution 1920x1080px.' />
          </label>
          <ImageField
            id={Image.BackgroundImage}
            image={formik.values.confirmationSettings.backgroundImageId}
            setSelectedImage={setSelectedImage}
          />
        </div>
        <div className='input-group'>
          <label htmlFor={Image.LogoImage}>
            Logo Image <SettingsInfoIcon tooltip='Optimal resolution 375x115px.' />
          </label>
          <ImageField
            id={Image.LogoImage}
            image={formik.values.confirmationSettings.logoId}
            setSelectedImage={setSelectedImage}
          />
        </div>
      </div>

      <Modal
        manuallyTriggered={selectedImage ? true : false}
        closeHandler={() => setSelectedImage(null)}
      >
        <SelectMediaList
          mediaType={MediaTypes.Image}
          resetModal={() => setSelectedImage(null)}
          addImage={(imageItem) => {
            if (selectedImage) {
              botSettingsStore.settings.confirmationSettings[
                selectedImage as keyof ConfirmationSettings
              ] = imageItem?.mediaId || null;
            }
            setSelectedImage(null);
          }}
          selectedImage={
            selectedImage === Image.BackgroundImage
              ? formik.values.backgroundImageId
              : formik.values.logoId
          }
        />
      </Modal>
    </div>
  );
};

export default observer(Images);
