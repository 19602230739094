import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Input from '../../common/Input';
import { FILTERS } from '../../../architecture/constants/ConversationHistory';

interface IProps {
  filter: { name: string, title: string};
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
}

const SearchInput: React.FC<IProps> = ({ filter, handleParamsChange, clearParamsChange }) => {
  const getIcon = useGetIcon();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [search, setSearch] = useState(params.userMessage ? params.userMessage.toString() : '');

  useEffect(() => {
    if(filter.name === FILTERS.USER_MESSAGE.NAME && params.userMessage) {
      setSearch(params.userMessage.toString());
    } else {
      setSearch('');
    }
  }, [filter])

  const keyDownHandler = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && search) {
      handleParamsChange(filter.name, search)
    } else if (event.key === 'Enter' && !search){
      clearParamsChange(filter.name);
    }
  }

  const blurHandler = () => {
    if (search) {
      handleParamsChange(filter.name, search)
    } else {
      if(params[filter.name]){
        clearParamsChange(filter.name);
      }
    }
  }

  useEffect(() => {
    if(!params[filter.name]){
      setSearch('');
    }
  }, [params[filter.name]])

  return (
    <div className='search-input'>
      <i className={getIcon('filter')}></i>
      <Input
        value={search}
        autoFocus={true}
        className='filter-input'
        placeholder={`Filter by ${filter.title}`}
        changeHandler={setSearch}
        blurHandler={blurHandler}
        keyDownHandler={keyDownHandler}
      />
    </div>
  );
};

export default observer(SearchInput);
