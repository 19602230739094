import { observer } from 'mobx-react-lite';
import React, { ReactNode, useEffect, useState } from 'react';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import TwoPanesWithBackNavigation from '../../common/paneNavigation/TwoPanesWithBackNavigation';
import TwoPanesWithTwoNavigations from '../../common/paneNavigation/TwoPanesWithTwoNavigations';
import useAdminStores from '../../customHooks/useAdminStores';
import BotsList from '../bots/BotsList';
import SubscriptionsList from '../subscriptions/SubscriptionsList';
import BillingDetailsContainer from './BillingDetailsContainer';
import BillingDetailsSelector from './BillingDetailsSelector';
import BillingOverview from './BillingOverview';
import BillingTimespanSelector from './BillingTimespanSelector';
import ContingentContainer from './ContingentContainer';
import ContingentOverview from './ContingentOverview';
import DailyBillingTable from './DailyBillingTable';

const enum DetailView {
  'Select',
  'Contingent',
  'Billing',
}

const enum UsageView {
  'Overview',
  'Daily',
}

const BillingPage: React.FC = () => {
  const { botAdminStore, subscriptionAdminStore, billingAdminStore } = useAdminStores();
  const [detailView, setDetailView] = useState<DetailView>(DetailView.Select);
  const [usageViewDetails, setUsageViewDetails] = useState<UsageView>(UsageView.Overview);

  useEffect(() => {
    billingAdminStore.load();
  }, [subscriptionAdminStore.getSelectedSubscription, botAdminStore.getSelectedBot]);

  const resizeSubscriptionsList = () => {
    botAdminStore.setSelectedBot(undefined);
    subscriptionAdminStore.setSelectedSubscription(undefined);
  };

  useEffect(() => {
    return () => {
      resizeSubscriptionsList();
    };
  }, []);

  const showOnlySubscriptions = () => {
    return <SinglePane pane={<SubscriptionsList />} />;
  };

  const showSubscriptionsAndBots = () => {
    return <TwoPanes leftPane={<SubscriptionsList />} rightPane={<BotsList />} />;
  };

  const showBillingSelector = () => {
    return (
      <TwoPanesWithBackNavigation
        leftPane={<BotsList />}
        rightPane={
          <BillingDetailsContainer>
            <BillingDetailsSelector
              billingClicked={() => setDetailView(DetailView.Billing)}
              contingentClicked={() => setDetailView(DetailView.Contingent)}
            />
          </BillingDetailsContainer>
        }
        buttonContent='Subscriptions'
        clickHandler={() => {
          resizeSubscriptionsList();
        }}
      />
    );
  };

  const showUsageDetails = () => {
    const rightPane = (
      <BillingDetailsContainer>
        {billingAdminStore.timespanSelected ? (
          usageViewDetails === UsageView.Overview ? (
            <BillingOverview onRowClick={() => setUsageViewDetails(UsageView.Daily)} />
          ) : (
            <DailyBillingTable />
          )
        ) : (
          <BillingTimespanSelector />
        )}
      </BillingDetailsContainer>
    );

    return renderTwoPanesWithTwoNavigations(rightPane);
  };

  const showCreditDetails = () => {
    const rightPane = (
      <ContingentContainer>
        <ContingentOverview />
      </ContingentContainer>
    );
    return renderTwoPanesWithTwoNavigations(rightPane);
  };

  const renderTwoPanesWithTwoNavigations = (rightPane: ReactNode) => {
    return (
      <TwoPanesWithTwoNavigations
        leftPane={<BotsList />}
        rightPane={rightPane}
        firstButtonContent='Subscriptions'
        firstButtonClick={() => {
          resizeSubscriptionsList();
        }}
        secondButtonContent='Overview'
        secondButtonClick={() => {
          billingAdminStore.resetTimespan();
          setDetailView(DetailView.Select);
          setUsageViewDetails(UsageView.Overview);
        }}
      />
    );
  };

  if (!subscriptionAdminStore.getSelectedSubscription && !botAdminStore.getSelectedBot) {
    return showOnlySubscriptions();
  }

  if (!!subscriptionAdminStore.getSelectedSubscription && !botAdminStore.getSelectedBot) {
    return showSubscriptionsAndBots();
  }

  if (detailView === DetailView.Select) {
    return showBillingSelector();
  } else if (detailView === DetailView.Contingent) {
    return showCreditDetails();
  } else if (detailView === DetailView.Billing) {
    return showUsageDetails();
  }

  throw new Error('Inconsistent configuration in BillingPage component.');
};

export default observer(BillingPage);
