import { observer } from 'mobx-react-lite';
import React from 'react';
import { format } from 'date-fns';
import { BaseHistoryElement as BaseHistoryElementModel } from '../../../models/ConversationHistory/HistoryElement/BaseHistoryElement';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  element: BaseHistoryElementModel;
  openAdditionalInfo?: boolean;
  setOpenAdditionalInfo?: (value: boolean) => void;
  title?: string;
  children?: React.ReactNode;
  icon: string;
}

const BaseHistoryElement: React.FC<IProps> = ({ icon, openAdditionalInfo, setOpenAdditionalInfo, element, title, children }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <div className='history-element'>
      <span className='history-element-icon'>
        <i className={`element-icon ${icon}`}></i>
      </span>
      <div className='date-wrapper'>{format(new Date(element.created), 'dd.MM.yyyy HH:mm:ss')}</div>
      {title &&
        <div className='card-header'>{title}</div>
      }
      {children}
      {setOpenAdditionalInfo &&
        <div className='toggle-additional-info' onClick={() => setOpenAdditionalInfo(!openAdditionalInfo)}>
          {openAdditionalInfo ? (
            <i className={getIcon('up')}> </i>
          ) : (
            <i className={getIcon('down')}> </i>
          )}
        </div>
      }
    </div>
  );
};

export default observer(BaseHistoryElement);
