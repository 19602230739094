import { observer } from 'mobx-react-lite';
import React from 'react';
import MediaFileDetails from '../../../media/details/MediaFileDetails';

const MediaDetails = () => {

  return (
    <div className='modal-default'>
      <MediaFileDetails />
    </div>
  );
};

export default observer(MediaDetails);
