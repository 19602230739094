import { observer } from 'mobx-react-lite';
import React from 'react';
import { AdaptiveCardSubmit } from '../../../../../models/DialogNodes/AdaptiveCardNode/Misc/AdaptiveCardSubmit';
import { Redirect as RedirectModel } from '../../../../../models/Redirects/Redirect';
import Redirect from '../../redirects/Redirect';

interface IProps {
  submitRow: AdaptiveCardSubmit;
  redirectOfSubmit: RedirectModel;
}
const ActionSubmitRow: React.FC<IProps> = ({ submitRow, redirectOfSubmit }) => {
  return (
    <div className='submit-mapping-row'>
      <p>
        Select a target block to enter after <strong>{submitRow.title}</strong> is
        submitted
      </p>
      {redirectOfSubmit && <Redirect redirect={redirectOfSubmit} noConditions />}
    </div>
  );
};

export default observer(ActionSubmitRow);
