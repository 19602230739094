import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ValidationResultElement as ValidationResultElementModel } from '../../../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ValidationResultElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { ValidationTypes } from '../../../../architecture/enums/HistoryElementType';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: ValidationResultElementModel;
}

const ValidationResultElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();

  const getValidationMessage = () => {
    let message;
    switch (element.validation) {
      case ValidationTypes.Invalid:
        return (
          <div className='card-header'>
            Question was not answered correctly
          </div>
        )
      case ValidationTypes.TooManyAttempts:
        return (
          <div className='card-header'>
            Question was not answered
          </div>
        )
      case ValidationTypes.CancelDialog:
        return (
          <div className='card-header'>
            Dialog is cancelled
          </div>
        )
      case ValidationTypes.CancelledByActionCard:
        return (
          <div className='card-header'>
            Dialog is cancelled by action card
          </div>
        )
      default:
        break;
    }
  }

  return (
    <BaseHistoryElement element={element} icon={getIcon('check')}>
      {getValidationMessage()}
      <div className='card-paragraph'>
        <i className={getIcon('error')}></i>
        <span className='card-paragraph-text'>After {element.attemptCount} attempt(s) question was aborted</span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(ValidationResultElement);
