import { observer } from 'mobx-react-lite';
import React from 'react';
import { Dialog } from '../../../models/Bot/Dialog';
import { Utilities } from '../../../models/Utilities/Utilities';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Switch from '../../common/inputElements/Switch';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  dialog: Dialog;
  beingEdited: boolean;
  openDialogSettings: () => void;
  openDialog: () => void;
  handleActivate: () => void;
  handleRemoveDialog: () => void;
}
const DialogTableRow: React.FC<IProps> = ({
  dialog,
  beingEdited,
  openDialog,
  handleActivate,
  handleRemoveDialog,
  openDialogSettings,
}) => {
  const getIcon = useGetIcon();

  return (
    <tr className='details-row' data-active={dialog.isActive}>
      <td onClick={openDialog}>
        {dialog.isActive && <Icon icon={getIcon('star')} className='active-icon' />}
        <span className='dialog-name'>
          {dialog.versionName}{' '}
          {beingEdited && (
            <Button
              className='btn-borderless'
              name={dialog.dialogId}
              icon={getIcon('edit')}
              tooltip='Dialog is currently being edited'
              tooltipPosition='top'
            />
          )}
        </span>
      </td>
      <td onClick={openDialog}>{dialog.versionDescription}</td>
      <td onClick={openDialog}>{dialog.lastEditor ?? 'Unknown user'}</td>
      <td onClick={openDialog}>
        {dialog.modified ? Utilities.getLocalDate(dialog.modified) : 'Not yet modified'}
      </td>
      <td className='table-control-column' onClick={(e) => e.stopPropagation()}>
        <Switch value={dialog.isActive} clickHandler={handleActivate} />
        <Button
          icon={getIcon('config')}
          className='btn-borderless'
          tooltip='Settings'
          tooltipPosition='top'
          clickHandler={openDialogSettings}
        />
        <Button
          icon={getIcon('remove')}
          className='btn-remove btn-borderless'
          clickHandler={handleRemoveDialog}
        />
      </td>
    </tr>
  );
};

export default observer(DialogTableRow);
