import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';
import { Redirect as RedirectModel } from '../../../../models/Redirects/Redirect';
import rootStore from '../../../../stores/rootStore';
import Select from '../../../common/inputElements/select/Select';

interface IDatalistProps {
  redirect: RedirectModel;
}
const RedirectDatalist: React.FC<IDatalistProps> = ({ redirect }) => {
  const { blockStore } = useContext(rootStore);

  const saveSelectChange = (value: string, id?: string) => {
    if (!value) {
      if (!!redirect.targetBlock) {
        redirect.setTargetBlock(null);
      }

      return;
    }

    let block = blockStore.getByCondition(
      (block) => block.title === value || block.id === id
    );

    if (!block) {
      block = new DialogBlockModel();
      block.title = value;
      blockStore.add(block);
    }

    redirect.setTargetBlock(block);
  };

  return (
    <Select
      creator
      placeholder='Choose or create a block...'
      items={blockStore.allBlocks
        .filter((block) => !block.isEmpty())
        .map((block) => ({ title: block.title }))}
      triggerLabel={redirect.targetBlock?.title ?? 'Select a block'}
      closeOnSelect
      selectHandler={saveSelectChange}
    />
  );
};

export default observer(RedirectDatalist);
