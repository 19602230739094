import { observer } from 'mobx-react-lite';
import React from 'react';
import { MessageNodeElement as MessageNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/MessageNodeElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: MessageNodeElementModel;
}

const MessageNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <BaseHistoryElement element={element} title='Outgoing Message' icon={getIcon('message')}>
      <div className='card-paragraph'>
        <i className={getIcon('double_left')}></i>
        <span className='card-paragraph-text'>{element.text}</span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(MessageNodeElement);
