import { Expose } from 'class-transformer';
import { makeAutoObservable } from 'mobx';
import { ISubscription } from '../../architecture/interfaces/HTTP/SubscriptionParams';

export class Subscription implements ISubscription {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  roles: string[];

  constructor(id: string, name: string, roles: string[]) {
    this.id = id;
    this.name = name;
    this.roles = roles;

    makeAutoObservable(this);
  }
}
