import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import VerticalNavigationButton from './VerticalNavigationButton';

interface IProps {
  leftPane: ReactNode;
  rightPane: ReactNode;
  buttonContent: string;
  clickHandler: () => void;
}

const TwoPanesWithBackNavigation = ({
  leftPane,
  rightPane,
  buttonContent,
  clickHandler,
}: IProps) => {
  return (
    <div className='two-panes-with-nav'>
      <VerticalNavigationButton clikHandler={clickHandler} content={buttonContent} />
      {leftPane}
      {rightPane}
    </div>
  );
};

export default observer(TwoPanesWithBackNavigation);
