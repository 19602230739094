import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { AddressKey } from '../../../../architecture/enums/SubQuestionKey';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import {
  ISerializedMultiQuestionNode,
  MultiQuestionNode,
} from '../MultiQuestionNode/MultiQuestionNode';

export class AddressQuestionNode extends MultiQuestionNode {
  @Expose()
  verifyAddress: boolean;
  type = DialogNodeTypes.AddressQuestionNode;
  constructor(block: DialogBlock) {
    super(block, Object.values(AddressKey));

    this.title = 'Address Question Node';
    this.verifyAddress = true;

    makeObservable(this, {
      verifyAddress: observable,
    });
  }

  serialize(): ISerializedAddressQuestionNode {
    return {
      ...super.serialize(),
      verifyAddress: this.verifyAddress,
    };
  }
}

export interface ISerializedAddressQuestionNode extends ISerializedMultiQuestionNode {
  verifyAddress: boolean;
}
