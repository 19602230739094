import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, UserAdminState } from '../../architecture/enums/Admin';
import { SubscriptionState } from '../../architecture/enums/SubscriptionState';
import type {
  ICreateUserRequest,
  IExistingUser,
  IResetUserPasswordResponse,
  IUpdateUserRequest,
  IUser,
} from '../../architecture/interfaces/HTTP/AdminParams';
import { User } from '../../models/Admin/User';
import { UserConnector } from '../../models/Connectors/admin/UserConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class UserAdminStore {
  private static instance: UserAdminStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    UserAdminStore.instance = this;
  }

  @observable
  private _userRepository: User[] = [];

  @observable
  private _existingUsersRepository: IExistingUser[] = [];

  @observable
  private selectedUser?: User;

  @observable
  state: UserAdminState = UserAdminState.None;

  @computed
  getState(state: UserAdminState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: UserAdminState) {
    this.state |= state;
  }

  @computed
  removeState(state: UserAdminState) {
    this.state &= ~state;
  }

  @computed
  get allUsers() {
    return this._userRepository;
  }

  @computed
  get existingUsers() {
    return this._existingUsersRepository;
  }

  @action
  setSelectedUser(userId?: string) {
    this.selectedUser = this._userRepository.find((user) => user.id === userId);
  }

  @computed
  get getSelectedUser() {
    return this.selectedUser;
  }

  @action
  purge() {
    this._userRepository = [];
  }

  @action
  async load() {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(UserAdminState.UsersLoading);
      const users = await UserConnector.get(subscriptionId);
      runInAction(() => this.restoreUsers(users));
      this.removeState(UserAdminState.UsersLoading);
    } catch (error) {
      this.removeState(UserAdminState.UsersLoading);
    }
  }

  @action
  async getExistingUsers() {
    try {
      this.setState(UserAdminState.ExistingUsersLoading);
      const users = await UserConnector.getExistingUsers();
      runInAction(() => this.restoreExistingUsers(users));
      this.removeState(UserAdminState.ExistingUsersLoading);
    } catch (error) {
      this.removeState(UserAdminState.ExistingUsersLoading);
    }
  }

  @action
  private restoreUsers(users: IUser[]) {
    this.purge();
    users.forEach((user) => {
      const instance = plainToInstance(ADMINS_CLASS_MAPPING[Admin.User], user);
      this._userRepository.push(instance);
    });
  }

  @action
  private restoreExistingUsers(users: IExistingUser[]) {
    this._existingUsersRepository = [];
    users.forEach((user) => {
      this._existingUsersRepository.push(user);
    });
  }

  @action
  async create(userRequest: ICreateUserRequest) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(UserAdminState.CreatingUserLoading);
      const response = await UserConnector.create(subscriptionId, userRequest);
      this.removeState(UserAdminState.CreatingUserLoading);
      return response.temporaryPassword;
    } catch (error) {
      this.removeState(UserAdminState.CreatingUserLoading);
      return undefined;
    }
  }

  @action
  async update(
    userId: string,
    userRequest: IUpdateUserRequest,
    reloadUsersList?: boolean
  ) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      if (reloadUsersList) {
        if (userId === this.getSelectedUser?.id) {
          this.setSelectedUser(undefined);
        }
        this.setState(UserAdminState.UsersLoading);
      } else {
        this.setState(UserAdminState.UpdatingUserLoading);
      }

      await UserConnector.update(subscriptionId, userId, userRequest);
      this.removeState(UserAdminState.UpdatingUserLoading);
      if (reloadUsersList) {
        this.load();
      }
      this.rootStore.subscriptionStore.removeState(SubscriptionState.Initialized);
    } catch (error) {
      this.removeState(UserAdminState.UsersLoading);
      this.removeState(UserAdminState.UpdatingUserLoading);
    }
  }

  @action
  async remove(user: User) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      if (user.id === this.getSelectedUser?.id) {
        this.setSelectedUser(undefined);
      }
      this.setState(UserAdminState.UsersLoading);
      if (user.id) await UserConnector.delete(subscriptionId, user.id);
      this.rootStore.subscriptionStore.removeState(SubscriptionState.Initialized);
      this.load();
    } catch (error) {
      this.removeState(UserAdminState.UsersLoading);
    }
  }

  @action
  async resetPassword(): Promise<IResetUserPasswordResponse | undefined> {
    try {
      if (!this.selectedUser?.id) return;

      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(UserAdminState.ResetPassword);
      const response = await UserConnector.resetPassword(
        subscriptionId,
        this.selectedUser.id
      );

      this.removeState(UserAdminState.ResetPassword);

      return response;
    } catch (error) {
      this.removeState(UserAdminState.ResetPassword);
    }
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('UserAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default UserAdminStore;
