import { observer } from 'mobx-react-lite';
import React from 'react';
import { GroupedSubQuestion } from '../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedSubQuestion';
import { SubQuestion as SubQuestionModel } from '../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/SubQuestion';
import { Text } from '../../../../../../models/Utilities/Text';
import Messages from '../../../communicationNode/Messages';

interface IProps {
  subQuestion: SubQuestionModel | GroupedSubQuestion;
}
const SubQuestion: React.FC<IProps> = ({ subQuestion }) => {
  return (
    <div className='subQuestion'>
      <Messages
        messages={subQuestion.questions}
        newMessageHandler={(item: Text) => subQuestion.addQuestion(item)}
        removeMessageHandler={(item: Text) => subQuestion.removeQuestion(item)}
      />
    </div>
  );
};

export default observer(SubQuestion);
