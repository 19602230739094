import React from 'react';
import VideoItem from '../media/mediaType/VideoItem';
import ImageItem from '../media/mediaType/ImageItem';
import DocumentItem from '../media/mediaType/DocumentItem';
import { DOCUMENTS_CONTENT_TYPE, VIDEOS_CONTENT_TYPE, IMAGES_CONTENT_TYPE } from '../../architecture/constants/Media';

export function useCreateMediaItem() {
  const createMediaItem = (item: any) => {
    const component = (() => {
      if (VIDEOS_CONTENT_TYPE.includes(item?.contentType))
         return <VideoItem item={item} />;
      if (IMAGES_CONTENT_TYPE.includes(item?.contentType))
        return <ImageItem item={item} />;
      if (DOCUMENTS_CONTENT_TYPE.includes(item?.contentType))
        return <DocumentItem item={item} />;

      throw new Error('Unknown media item instance.');
    })();

    return (
      <React.Fragment key={item.mediaId}>{component}</React.Fragment>
    );
  };

  return {
    createMediaItem,
  };
}
