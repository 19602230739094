import { observer } from 'mobx-react-lite';
import React from 'react';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import { User } from '../../../../models/Admin/User';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';

interface IProps {
  item: any;
}

const Actions: React.FC<IProps> = ({ item }) => {
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  return (
    <div className='actions'>
      <i
        className={`${getIcon('remove')} actions-icon`}
        onClick={() => {
          showModal(
            ModalTypes.DeleteElement,
            new User(
              item.row.original.id,
              item.row.original.email,
              item.row.original.displayName
            )
          );
        }}
      ></i>
    </div>
  );
};

export default observer(Actions);
