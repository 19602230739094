import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import { BillingAdminState } from '../../../architecture/enums/Admin';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import useAdminStores from '../../customHooks/useAdminStores';
import LoadingPage from '../../layout/LoadingPage';
import AddCreditPaymentModal from './AddCreditPaymentModal';

interface IProps {
  children: ReactNode;
}

const ContingentContainer: React.FC<IProps> = ({ children }: IProps) => {
  const { billingAdminStore, botAdminStore, creditManagementAdminStore } =
    useAdminStores();

  const [openAddCreditsModal, setOpenAddCreditsModal] = useState(false);

  const closeModal = () => {
    setOpenAddCreditsModal(false);
  };

  return (
    <div className='billing-content container-wrapper'>
      {billingAdminStore.getState(BillingAdminState.DetailsLoading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4 className='title-name'>{botAdminStore.getSelectedBot?.botName}</h4>
            <div className='button-wrapper'>
              <Button
                content={'Add credits'}
                className='btn-primary btn-round'
                clickHandler={() => setOpenAddCreditsModal(true)}
              />
            </div>
          </div>
          {children}
          <Modal manuallyTriggered={openAddCreditsModal} closeHandler={closeModal}>
            <AddCreditPaymentModal
              resetModal={closeModal}
              addPayment={(credits, remarks) => {
                creditManagementAdminStore.addPayment(credits, remarks);
                closeModal();
              }}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(ContingentContainer);
