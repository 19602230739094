import { ConditionTypes } from '../../architecture/enums/Condition';
import { ActionCardCondition } from './ActionCardCondition';
import { ContextCondition } from './ContextCondition';

export const CONDITION_DISCRIMINATOR = {
  discriminator: {
    property: 'type',
    subTypes: [
      { value: ContextCondition, name: ConditionTypes.ContextCondition },
      { value: ActionCardCondition, name: ConditionTypes.ActionCardCondition },
    ],
  },
};
