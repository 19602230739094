export const HEADERS = {
  texture: {
    label: 'Texture',
    id: 'name',
  },
  created: {
    label: 'Creation Date',
    id: 'created',
  },
  actions: {
    label: '',
    id: 'actions',
  },
};
