import { AvatarTexture } from '../../models/Admin/AvatarTexture';
import { Bot } from '../../models/Admin/Bot';
import { Payment } from '../../models/Admin/Payment';
import { Subscription } from '../../models/Admin/Subscription';
import { User } from '../../models/Admin/User';
import { Dialog } from '../../models/Bot/Dialog';
import { Dataset } from '../../models/Dataset/Dataset';
import { DialogBlock } from '../../models/DialogBlocks/DialogBlock';
import { BaseDialogNode } from '../../models/DialogNodes/BaseDialogNode';
import { Intent } from '../../models/Intents/Intent';
import { Media } from '../../models/Media/Media';
import { Trigger } from '../../models/Triggers/Trigger';

export enum ModalTypes {
  DeleteElement = 'DeleteElement',
  MessageNodeTypeSelector = 'MessageNodeTypeSelector',
  ActionNodeTypeSelector = 'ActionNodeTypeSelector',
  QuestionNodeTypeSelector = 'QuestionNodeTypeSelector',
  IntentTrigger = 'IntentTrigger',
  Trigger = 'Trigger',
  PurgeBlocks = 'PurgeBlocks',
  PurgeNodes = 'PurgeNodes',
  MediaDetails = 'MediaDetails',
  DiscardKnowledgebaseChanges = 'DiscardKnowledgebaseChanges',
  DiscardPronunciationChanges = 'DiscardPronunciationChanges',
  MediaFileSelector = 'MediaFileSelector',
  RestoreStructure = 'RestoreStructure',
  AddCreditPayment = 'AddCreditPayment',
}

export type ModalElementTypes =
  | BaseDialogNode
  | DialogBlock
  | Intent
  | Trigger
  | Dialog
  | Media
  | Dataset
  | Subscription
  | User
  | Payment
  | Bot
  | AvatarTexture
  | undefined;
