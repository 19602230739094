import { observer } from 'mobx-react-lite';
import React from 'react';
import { IReceiver } from '../../../../../../architecture/interfaces/trigger/IEventTrigger';
import { ChannelReceiver as ChannelReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/ChannelReceiver';
import { UserReceiver as UserReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/UserReceiver';
import ChannelReceiver from './receivers/ChannelReceiver';
import UserReceiver from './receivers/UserReceiver';

interface IProps {
  receiver: IReceiver;
}

const Receiver: React.FC<IProps> = ({ receiver }) => {
  const component = (() => {
    if (receiver instanceof UserReceiverModel)
      return <UserReceiver receiver={receiver} />;

    if (receiver instanceof ChannelReceiverModel)
      return <ChannelReceiver receiver={receiver} />;

    throw new Error('Receiver Instance is not defined or not implemented.');
  })();

  return <div className='receiver-details'>{component}</div>;
};

export default observer(Receiver);
