import React, { useContext, useEffect, useState } from 'react';
import rootStore from '../../stores/rootStore';
import ContextVariable from '../dialogElements/ContextVariable';

const ParagraphWithCtx: React.FC<{ text: string }> = React.memo(
  ({ text }) => {
    const { ctxVarStore } = useContext(rootStore);

    const [content, setContent] = useState<(JSX.Element | HTMLSpanElement | string)[]>(
      []
    );

    useEffect(() => {
      if (text) {
        const textFragments = text!.split(ctxVarStore.guidPattern);
        setContent(
          textFragments.map((frag: string) => {
            if (frag.match(ctxVarStore.guidPattern)) {
              const ctx = ctxVarStore.getById(frag);
              if (!ctx) return '';

              return (
                <ContextVariable
                  key={ctx.id + Math.random()}
                  ctx={ctx}
                  clickHandler={() => {}}
                />
              );
            } else {
              return frag;
            }
          })
        );
      } else {
        setContent(['']);
      }
    }, [text]);

    return <p className='paragraph-with-ctx'>{content}</p>;
  },
  (prev, next) => {
    return prev.text === next.text;
  }
);

export default ParagraphWithCtx;
