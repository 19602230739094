import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media as MediaModel } from '../../../models/Media/Media';
import MediaItem from '../MediaItem';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  item: MediaModel;
}

const VideoItem: React.FC<IProps> = ({ item }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <MediaItem item={item}>
      <video src={`${item.url}#t=1`} width='100%' height='100%'></video>
      <div className='video-icon'>
        <i className={`${getIcon('play')} ${'media-item-icon'}`}></i>
      </div>
    </MediaItem>
  );
};

export default observer(VideoItem);
