import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import rootStore from '../../../stores/rootStore';
import AudioPlaybackIcon from '../../common/AudioPlaybackIcon';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useReadOnly } from '../../customHooks/useReadOnly';
import Attachments from '../attachments/Attachments';
import AttachmentButton from './components/AttachmentButton';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Actions: React.FC<IProps> = ({ entry }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const [openAttachements, setOpenAttachments] = useState(false);
  const { dialogStore } = useContext(rootStore);

  const resetModal = () => {
    if (entry.areAttachmentsValid()) {
      setOpenAttachments(false);
    }
  };

  return (
    <div className='cell-wrapper cell-wrapper-actions'>
      <div className='actions'>
        <AudioPlaybackIcon
          text={entry.answer.value}
          language={dialogStore.currentlyEditedDialog?.locale!}
          disabled={knowledgebaseReadOnlyMode}
        />
        {entry.state !== 'Deleted' && (
          <>
            {entry.state !== 'Added' && (
              <AttachmentButton entry={entry} setOpenAttachments={setOpenAttachments} />
            )}
            <Button
              className='btn-remove btn-borderless'
              icon={getIcon('remove')}
              disabled={knowledgebaseReadOnlyMode}
              clickHandler={() => entry.remove()}
            />
          </>
        )}
        {entry.state === 'Deleted' && (
          <Button
            className='btn-remove btn-borderless'
            icon={getIcon('undo')}
            disabled={knowledgebaseReadOnlyMode}
            clickHandler={() => entry.revert()}
          />
        )}
      </div>
      <Modal manuallyTriggered={openAttachements} closeHandler={resetModal}>
        <Attachments entry={entry} resetModal={resetModal} />
      </Modal>
    </div>
  );
};

export default observer(Actions);
