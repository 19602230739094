import { Exclude, Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import {
  SubQuestionGroup,
  SubQuestionKey,
} from '../../../architecture/enums/SubQuestionKey';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { QuestionDialogNode, ISerializedQuestionDialogNode } from './QuestionDialogNode';
import { SubQuestion } from './MultiQuestionNode/SubQuestion';
import { GroupedSubQuestion } from './MultiQuestionNode/GroupedSubQuestion';

export abstract class BaseMultiQuestionNode extends QuestionDialogNode {
  @Expose()
  prefix: string = '';

  @Exclude()
  separator: string = '.';

  @Exclude()
  abstract allowedSubQuestionKeys: Array<SubQuestionKey> | Array<SubQuestionGroup>;

  constructor(block: DialogBlock) {
    super(block);

    makeObservable(this, {
      prefix: observable,
      isPrefixValid: computed,
      areSubQuestionsValid: computed,
      setPrefix: action,
      addSubQuestion: action,
      removeSubQuestion: action,
    });
  }

  abstract get areSubQuestionsValid(): boolean;
  abstract isKeyStored(key: SubQuestionKey | SubQuestionGroup): boolean;
  abstract addSubQuestion(
    key: SubQuestionKey | SubQuestionGroup
  ): SubQuestion | GroupedSubQuestion;
  abstract removeSubQuestion(key: SubQuestionKey | SubQuestionGroup): void;

  get isValid() {
    return super.isValid && this.isPrefixValid;
  }

  get isPrefixValid() {
    return this.prefix.length > 0;
  }

  setPrefix(prefix: string) {
    this.prefix = prefix;
  }

  serialize(): ISerializedMultiQuestionDialogNode {
    return {
      ...super.serialize(),
      prefix: this.prefix,
    };
  }
}

export interface ISerializedMultiQuestionDialogNode
  extends ISerializedQuestionDialogNode {
  prefix: string;
}
