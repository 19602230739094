import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import useModal from '../../../customHooks/useModal';
import { Media } from '../../../../models/Media/Media';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useGetDocumentMediaFileIcon } from '../../../customHooks/useGetDocumentMediaFileIcon';
import {
  DOCUMENTS_CONTENT_TYPE,
  VIDEOS_CONTENT_TYPE,
  IMAGES_CONTENT_TYPE,
} from '../../../../architecture/constants/Media';
import Modal from '../../../common/modal/Modal';
import MediaFileSelector from '../../../common/modal/templates/MediaFileSelector';

interface IProps {
  dialogNode: CommunicationDialogNode;
}

const MediaFiles: React.FC<IProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();
  const [openMediaFileSelector, setOpenMediaFileSelector] = useState(false);
  const getDocumentMediaFileIcon = useGetDocumentMediaFileIcon();
  const { showModal } = useModal();

  const getMediaIcon = (media: Media) => {
    if (DOCUMENTS_CONTENT_TYPE.includes(media.contentType)) {
      return <i className={getIcon('document')}> </i>;
    }
    if (IMAGES_CONTENT_TYPE.includes(media.contentType)) {
      return <i className={getIcon('image')}> </i>;
    }
    if (VIDEOS_CONTENT_TYPE.includes(media.contentType)) {
      return <i className={getIcon('play')}> </i>;
    }
  };

  const resetModal = () => {
    setOpenMediaFileSelector(false);
  };

  return (
    <>
      <div className='media-items-container'>
        {dialogNode.mediaFiles.map((media, key) => {
          return (
            <div key={key} className='media-node-item'>
              <span className='node-icon'>{getMediaIcon(media)}</span>
              {VIDEOS_CONTENT_TYPE.includes(media.contentType) ? (
                <video
                  src={`${media.url}#t=1`}
                  width='100%'
                  height='100%'
                  onClick={() => showModal(ModalTypes.MediaDetails, media)}
                ></video>
              ) : IMAGES_CONTENT_TYPE.includes(media.contentType) ? (
                <img
                  src={media.url}
                  width='100%'
                  height='100%'
                  style={{ objectFit: 'cover' }}
                  onClick={() => showModal(ModalTypes.MediaDetails, media)}
                />
              ) : (
                <div
                  onClick={() => showModal(ModalTypes.MediaDetails, media)}
                  className='document-item'
                >
                  <i className={`${getDocumentMediaFileIcon(media)} document-icon`}> </i>
                </div>
              )}
              <span
                className='close-node-item'
                onClick={() => dialogNode.removeMediaFile(media)}
              >
                <i className={getIcon('close')}> </i>
              </span>
            </div>
          );
        })}
      </div>
      <Button
        icon={getIcon('add')}
        content='Add'
        className={` ${'btn-round btn-primary'} ${'button-wrapper'}`}
        clickHandler={() => setOpenMediaFileSelector(true)}
      />

      <Modal manuallyTriggered={openMediaFileSelector} closeHandler={resetModal}>
        <MediaFileSelector item={dialogNode} resetModal={resetModal} />
      </Modal>
    </>
  );
};

export default observer(MediaFiles);
