import { observer } from 'mobx-react-lite';
import React from 'react';
import { ISelectItem } from '../../../../../../../architecture/interfaces/select/ISelectItem';
import { Entity as EntityModel } from '../../../../../../../models/DialogNodes/EntityNode/Entity';
import { EntityDefinition } from '../../../../../../../models/DialogNodes/EntityNode/EntityDefinition';
import Input from '../../../../../../common/Input';
import Select from '../../../../../../common/inputElements/select/Select';
import Actions from './Actions';

interface IProps {
  entity: EntityModel;
  entityDefinition: EntityDefinition;
  index: number;
  entities: string[];
  addedEntityMapping?: (previousEntityName: string, newEntityName: string) => void;
  deletedEntityMapping?: (entity: string) => void;
  allowNewEntities?: boolean;
}

const Entity: React.FC<IProps> = ({
  entity,
  entityDefinition,
  index,
  entities,
  addedEntityMapping,
  deletedEntityMapping,
  allowNewEntities = true,
}) => {
  const getSelectElements = (): ISelectItem[] =>
    entities.map((entity: string) => ({
      title: entity || '',
    }));

  const removeMapping = (previousEntityName: string) => {
    deletedEntityMapping && deletedEntityMapping(previousEntityName);
  };

  const addOrUpdateMapping = (previousEntityName: string, newEntityName: string) => {
    addedEntityMapping && addedEntityMapping(previousEntityName, newEntityName);
  };

  return (
    <div className='entity'>
      <div
        className='example-wrapper'
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        <Select
          triggerLabel={entity.name}
          items={getSelectElements()}
          selectHandler={(value) => {
            const previousEntityName = entity.name;
            if (value) {
              entity.name = value;
              addOrUpdateMapping(previousEntityName, value);
            } else {
              entity.name = '';
              removeMapping(previousEntityName);
            }
          }}
          placeholder={'Insert entity name...'}
          search={!allowNewEntities}
          creator={allowNewEntities}
          closeOnSelect
          replaceSpecialCharacters
          fullWidth
        />
      </div>
      <div className='example-wrapper'>
        <Input
          value={entity.value}
          style={{ height: 'fit-content' }}
          textAlign='left'
          placeholder={'Insert entity value...'}
          changeHandler={(value) => (entity.value = value)}
          inputFactory
        />
        <hr></hr>
      </div>
      <Actions entityDefinition={entityDefinition} entity={entity} index={index} />
    </div>
  );
};

export default observer(Entity);
