import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import {
  ListConditionOperator,
  SingleValueConditionOperator,
} from '../../../../../architecture/enums/Condition';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import { ContextCondition as ContextConditionModel } from '../../../../../models/Conditions/ContextCondition';
import rootStore from '../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import ListContextVariableHandler from './extensions/ListContextVariableHandler';
import SingleValueContextVariableHandler from './extensions/SingleValueContextVariableHandler';

interface IProps {
  condition: ContextConditionModel;
}

const ContextCondition: React.FC<IProps> = ({ condition }) => {
  const { ctxVarStore } = useContext(rootStore);

  const handleCtxSelection = (name: string) => {
    const ctx = ctxVarStore.getByName(name);
    if (!ctx) return;

    condition.ctx = ctx;

    switch (condition.ctx.instanceType) {
      case ContextVariableInstanceTypes.ListContextVariable:
        condition.operator =
          condition.operator in ListConditionOperator
            ? condition.operator
            : ListConditionOperator.IsSet;
        break;
      case ContextVariableInstanceTypes.SystemContextVariable:
      default:
        condition.operator =
          condition.operator in SingleValueConditionOperator
            ? condition.operator
            : SingleValueConditionOperator.IsSet;
        break;
    }
  };

  const renderHandler = () => {
    switch (condition.ctx.instanceType) {
      case ContextVariableInstanceTypes.ListContextVariable:
        return <ListContextVariableHandler condition={condition} />;
      default:
        return <SingleValueContextVariableHandler condition={condition} />;
    }
  };

  return (
    <Fragment>
      <div className='condition-item' data-error={condition.isValid === false}>
        <ContextVariableSelector
          items={ctxVarStore.userAndSystemVariables}
          selectHandler={handleCtxSelection}
          triggerLabel={condition.ctx?.name ?? 'Choose a Variable'}
        />
        {renderHandler()}
      </div>
    </Fragment>
  );
};

export default observer(ContextCondition);
