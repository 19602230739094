import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { Utilities } from '../Utilities/Utilities';

export class ActionAttachment {
  id: string;
  type: string;
  data: string;

  constructor(type: string = '', data: string = '') {
    this.id = v4();
    this.type = type;
    this.data = data;

    makeObservable(this, {
      type: observable,
      data: observable,
      serialize: action,
      isTypeValid: computed,
      isDataValid: computed,
    });
  }

  get isTypeValid() {
    return !Utilities.isEmpty(this.type);
  }

  get isUrlValid() {
    if (!this.data) {
      return false;
    }

    const url = JSON.parse(this.data).url;

    // Allow relative URLs
    if (url.startsWith('/')) return true;

    return Utilities.isUrlValid(url);
  }

  get isCaptionValid() {
    return this.data ? !Utilities.isEmpty(JSON.parse(this.data).caption) : true;
  }

  get isValid() {
    return this.isTypeValid && this.isDataValid;
  }

  get isDataValid() {
    if (Utilities.isEmpty(this.data)) return true;

    return this.type !== 'urlRedirect'
      ? Utilities.isJsonValid(this.data)
      : this.isUrlValid && this.isCaptionValid;
  }

  serialize(): ISerializedActionAttachment {
    if (!this.isDataValid || !this.data) {
      return {
        type: this.type,
      };
    } else {
      return {
        type: this.type,
        data: {
          ...JSON.parse(this.data),
        },
      };
    }
  }
}

export interface ISerializedActionAttachment {
  type: string;
  data?: string;
  url?: string; // legacy parameter
  caption?: string; // legacy parameter
}
