import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { DialogState } from '../../../architecture/enums/DialogState';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import { useGetIcon } from '../../customHooks/useGetIcon';
import ImportDialogButton from '../components/ImportDialogButton';

const ImportExport: React.FC = () => {
  const [importActive, setImportActive] = useState(false);
  const [exportActive, setExportActive] = useState(false);

  const getIcon = useGetIcon();
  const { dialogStore } = useContext(rootStore);

  useEffect(() => {
    setExportActive(dialogStore.getState(DialogState.Exporting));
  }, [dialogStore.getState(DialogState.Exporting)]);

  return (
    <div className='settings-container'>
      <div className='settings-content'>
        <div className='properties-wrapper'>
          <h3 className='title'>Import & Export</h3>
        </div>
        <div className='input-export-wrapper'>
          <div className='explanation'>
            <p>
              Here you can upload a backed-up version of your dialog. This feature enables
              you to roll back to an earlier state, including the knowledge base as it was
              at that moment.
            </p>
            <p>
              Please be mindful. Uploading a backup will replace the current state of your
              dialog, irrevocably deleting any updates or additions since that backup was
              saved. To safeguard your work, we strongly recommend backing up the current
              version before proceeding.
            </p>
          </div>
          <div className='btn-container'>
            <ImportDialogButton
              isLoading={importActive}
              disabled={exportActive}
              setIsLoading={(state) => setImportActive(state)}
            />
            <Button
              icon={getIcon('download')}
              content={'Export'}
              className={'btn-primary btn-round'}
              disabled={importActive}
              clickHandler={() => dialogStore.exportDialog()}
              isLoading={exportActive}
            />
          </div>
          {(importActive || exportActive) && (
            <div className='status'>
              {importActive && (
                <span className='import-status'>
                  The dialog is currently being imported. Please remain on this page until
                  the import is complete.
                </span>
              )}
              {exportActive && (
                <span className='export-status'>
                  Your dialog is currently being exported. Your download will start
                  shortly.
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(ImportExport);
