import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { v4 } from 'uuid';
import { Text } from '../../../models/Utilities/Text';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Button from '../Button';
import Input from '../Input';

interface IProps {
  disabled?: boolean;
  controlled?: boolean;
  value?: string;
  textObject?: Text;
  placeholder?: string;
  deleteHandler: () => void;
  changeHandler?: (text: string) => void;
  blurHandler?: (text: string) => void;
  btnMouseEnterHandler?: () => void;
  btnMouseLeaveHandler?: () => void;
  autoFocus?: boolean;
}

const TextInputWithDelete: React.FC<IProps> = ({
  disabled,
  textObject,
  value,
  placeholder,
  controlled,
  deleteHandler,
  changeHandler,
  blurHandler,
  btnMouseEnterHandler,
  btnMouseLeaveHandler,
  autoFocus = false,
}) => {
  useEffect(() => {
    if (controlled && (!blurHandler || value === undefined)) {
      throw Error(
        'You must provide the TextInputWithDelete component with a changeHandler and a blurHandler if the "controlled" flag is set!'
      );
    }

    if (!controlled && !textObject) {
      throw Error(
        'You must provide the TextInputWithDelete component with a Text instance if the "controlled" flag is not set!'
      );
    }
  }, [controlled, value, textObject, blurHandler]);

  const closeIcon = useGetIcon()('close');

  const init = {
    controlled: {
      value: value!,
      changeHandler: changeHandler!,
      blurHandler: blurHandler!,
    },
    uncontrolled: {
      value: textObject?.value ?? '',
      changeHandler: (text: string) => (textObject!.value = text),
      blurHandler: () => {
        if (!textObject!.value) deleteHandler();
      },
    },
  };

  return (
    <div
      className='input-field-with-btn'
      style={{ border: '1px solid black' }}
      key={textObject?.id ?? v4()}
    >
      {controlled ? (
        <Input
          disabled={disabled ? disabled : false}
          name=''
          controlled
          value={init.controlled.value!}
          textAlign={'center'}
          blurHandler={(text) => init.controlled.blurHandler?.(text)}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      ) : (
        <Input
          disabled={disabled ? disabled : false}
          name='value'
          value={init.uncontrolled.value}
          changeHandler={init.uncontrolled.changeHandler}
          textAlign={'center'}
          blurHandler={() => init.uncontrolled.blurHandler?.()}
          placeholder={placeholder}
          autoFocus={autoFocus}
        />
      )}
      <Button
        disabled={disabled}
        icon={closeIcon}
        className={'btn-remove'}
        clickHandler={deleteHandler}
        mouseEnterHandler={btnMouseEnterHandler}
        mouseLeaveHandler={btnMouseLeaveHandler}
      />
    </div>
  );
};

export default observer(TextInputWithDelete);
