import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import {
  ITooltipItem,
  ITooltipPosition,
} from '../../../architecture/interfaces/tooltip/ITooltip';
import TooltipMessageList from './TooltipMessageList';

export type TooltipItemIconType = 'warning' | 'info' | 'error';

interface IProps {
  items: ITooltipItem[];
  className?: string;
  type?: TooltipItemIconType;
  handleItemClick: (id: string) => void;
}
const TooltipContainer: React.FC<IProps> = ({
  items,
  className,
  children,
  type,
  handleItemClick,
}) => {
  const DEFAULT_TOOLTIP_POSITION = {
    top: '100%',
    right: '-100px',
    left: 'unset',
    bottom: 'unset',
  };
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState<ITooltipPosition>(DEFAULT_TOOLTIP_POSITION);

  const containerRef = useRef(null);
  const getContainerRefParams = () =>
    (containerRef.current as unknown as HTMLDivElement).getBoundingClientRect();

  const handleMouseEnter = (event: React.MouseEvent) => {
    if (open) return;

    const { clientX, clientY } = event;
    let calculatedPosition: ITooltipPosition = { ...DEFAULT_TOOLTIP_POSITION };

    const isAtLeftBorder = clientX - getContainerRefParams().width < 0;
    const isAtBottom = clientY + getContainerRefParams().width > window.innerHeight;

    if (isAtLeftBorder) {
      calculatedPosition = {
        ...calculatedPosition,
        right: 'unset',
        left: '0',
      };
    }

    if (isAtBottom) {
      calculatedPosition = {
        ...calculatedPosition,
        bottom: '100%',
        top: 'unset',
      };
    }

    setPosition(calculatedPosition);
    setOpen(true);
  };

  const reset = () => {
    setOpen(false);
    setPosition(DEFAULT_TOOLTIP_POSITION);
  };

  return (
    <div
      ref={containerRef}
      className={
        className
          ? `tooltip-at-cursor-container ${className}`
          : 'tooltip-at-cursor-container'
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={reset}
    >
      {children}
      {open && (
        <TooltipMessageList
          items={items}
          type={type}
          position={position}
          handleItemClick={handleItemClick}
        />
      )}
    </div>
  );
};

export default observer(TooltipContainer);
