import { Expose, Type } from 'class-transformer';
import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { eventTargetPlatforms } from '../../../architecture/constants/EventTrigger';
import { TriggerTypes } from '../../../architecture/enums/TriggerTypes';
import { Utilities } from '../../Utilities/Utilities';
import { ISerializedTrigger, Trigger } from '../Trigger';
import { EventParameter, ISerializedEventParameter } from './EventParameter';
import { EventTarget, ISerializedEventTarget } from './EventTarget/EventTarget';
import { MicrosoftTeamsEventTarget } from './EventTarget/MicrosoftTeams/MicrosoftTeamsEventTarget';
import { TelegramEventTarget } from './EventTarget/Telegram/TelegramEventTarget';

const EVENT_TARGET_DISCRIMINATOR = {
  discriminator: {
    property: 'platform',
    subTypes: [
      { value: MicrosoftTeamsEventTarget, name: eventTargetPlatforms.MICROSOFT_TEAMS },
      { value: TelegramEventTarget, name: eventTargetPlatforms.TELEGRAM },
    ],
  },
};

export class EventTrigger extends Trigger {
  @Expose()
  eventId: string;

  @Expose()
  @Type(() => EventTarget, EVENT_TARGET_DISCRIMINATOR)
  target: EventTarget;

  @Expose()
  @Type(() => EventParameter)
  contextMapping: EventParameter[];

  @Expose()
  isDynamic: boolean = false;

  constructor() {
    super();
    this.type = TriggerTypes.Event;
    this.eventId = v4();
    this.target = new MicrosoftTeamsEventTarget();
    this.contextMapping = [];

    makeObservable(this, {
      eventId: observable,
      target: observable,
      contextMapping: observable,
      isDynamic: observable,
      addParameter: action,
      removeParameter: action,
    });
  }

  get isValid() {
    let contextMappingValid = true;
    if (this.contextMapping && this.contextMapping.length > 0) {
      contextMappingValid = this.contextMapping.every((item) => !!item.ctx);
    }

    return this.isDynamic
      ? Utilities.isGuidValid(this.eventId) && contextMappingValid
      : Utilities.isGuidValid(this.eventId) && this.target.isValid && contextMappingValid;
  }

  get isChanged() {
    return (
      this.target.isChanged ||
      (this.contextMapping.length > 0 &&
        this.contextMapping.find(
          (item) => item.ctx?.name || (item.key && item.key !== '') || item.required
        ))
    );
  }

  addParameter(parameter: EventParameter) {
    this.contextMapping.unshift(parameter);
  }

  removeParameter(parameter: EventParameter) {
    this.contextMapping = this.contextMapping.filter((elem) => elem !== parameter);
  }

  serialize(): ISerializedEventTrigger {
    return {
      ...super.serialize(),
      eventId: this.eventId,
      target: !this.isDynamic ? this.target.serialize() : undefined,
      isDynamic: this.isDynamic,
      contextMapping: this.contextMapping.map((item) => item.serialize()),
    };
  }
}

export interface ISerializedEventTrigger extends ISerializedTrigger {
  eventId: string;
  target?: ISerializedEventTarget;
  contextMapping: ISerializedEventParameter[];
  isDynamic: boolean;
}
