import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import type { IContextVariable } from '../../../architecture/interfaces/contextVariables/IContextVariable';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { TransformIntoContextVariable } from '../../Utilities/Deserialization/Decorators';
import { ISerializedDialogNode } from '../BaseDialogNode';
import { ActionDialogNode } from './ActionDialogNode';

export class StorageActionNode extends ActionDialogNode {
  type: DialogNodeTypes = DialogNodeTypes.StorageActionNode;

  @Expose({ name: 'contextVariableId' })
  @TransformIntoContextVariable
  contextVariable?: IContextVariable;

  @Expose()
  override: boolean = false;

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Storage Action Node';

    makeObservable(this, {
      contextVariable: observable,
      override: observable,
    });
  }

  get isValid(): boolean {
    return this.contextVariable ? true : false;
  }

  serialize(): ISerializedStorageActionNode {
    return {
      ...super.serialize(),
      contextVariableId: this.contextVariable?.id,
      override: this.override,
    };
  }
}

export interface ISerializedStorageActionNode extends ISerializedDialogNode {
  contextVariableId?: string;
  override: boolean;
}
