import { observer } from 'mobx-react-lite';
import React from 'react';

interface IProps {
  className?: string;
  value: boolean;
  label?: string | JSX.Element;
  disabled?: boolean;
  clickHandler: () => void;
}

const Switch: React.FC<IProps> = ({
  className = '',
  value,
  label,
  disabled,
  clickHandler,
}) => {
  const handleClick = () => {
    if (disabled) return;

    clickHandler();
  };

  return (
    <div
      className={`lailo-switch-container ${className}`}
      data-disabled={disabled}
      onClick={handleClick}
    >
      <div className='lailo-switch-slider' data-checked={value}>
        <div className='lailo-switch-slider-circle'></div>
      </div>
      <div className='lailo-switch-label'>{label}</div>
    </div>
  );
};

export default observer(Switch);
