import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ContextActionNodeElement as ContextActionNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/ContextActionNodeElement';
import BaseHistoryElement from '../BaseHistoryElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: ContextActionNodeElementModel;
}

const ContextActionNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const { blockStore, nodeStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const block = blockStore?.allBlocks?.find(block => block?.id === element.blockId);
  const node = nodeStore?.allNodes?.find(block => block?.id === element.nodeId);

  return (
    <BaseHistoryElement element={element} title='Context Action Node' icon={getIcon('config')}>
      <div className='card-paragraph'>
        <i className={getIcon('hashtag')}></i>
        <span> Context Variables were updated </span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(ContextActionNodeElement);
