import React, { useContext, useState } from 'react';
import {
  DialogLanguages,
  IAddDialogFormInitialization,
} from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import { Bot } from '../../../models/Bot/Bot';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Select from '../../common/inputElements/select/Select';
import Textarea from '../../common/inputElements/Textarea';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  bot: Bot;
  resetModal: () => void;
}
const AddDialogForm: React.FC<IProps> = ({ bot, resetModal }) => {
  const { dialogStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const initialDialog = {
    name: '',
    description: '',
    locale: DialogLanguages.Deutsch,
  };

  const [newDialog, setNewDialog] = useState<IAddDialogFormInitialization>(initialDialog);

  const handleChange = (key: keyof typeof initialDialog, value: string) => {
    setNewDialog((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const selectLanguage = (code: DialogLanguages) => {
    setNewDialog((prev) => ({
      ...prev,
      locale: code,
    }));
  };

  const addNewDialog = async () => {
    await dialogStore.addNewDialog(
      bot!.botId,
      newDialog.name,
      newDialog.description,
      newDialog.locale
    );

    resetModal();
  };

  return (
    <div className='modal-default dialog-overview-modal'>
      <div className='header'>
        <h4>Create a new dialog for {bot?.name}</h4>
      </div>
      <div className='body'>
        <div className='input-group'>
          <label htmlFor='new-dialog-name'>Dialog Name</label>
          <Input
            id='new-dialog-name'
            value={newDialog.name}
            changeHandler={(text: string) => handleChange('name', text)}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='new-dialog-description'>Dialog Description</label>
          <Textarea
            id='new-dialog-description'
            value={newDialog.description}
            rows={5}
            changeHandler={(text: string) => handleChange('description', text)}
          />
        </div>
        <div className='input-group'>
          <label htmlFor='new-dialog-language'>Dialog Language</label>
          <Select
            triggerLabel={newDialog.locale}
            items={Object.entries(DialogLanguages).map(
              ([lang, code]: [string, string]) => ({
                title: code,
                meta: lang,
              })
            )}
            selectHandler={(code) => selectLanguage(code as DialogLanguages)}
            closeOnSelect
            fullWidth
          />
        </div>
      </div>
      <div className='btn-container'>
        <Button
          name='new-dialog-btn'
          icon={getIcon('add')}
          content='Add New Dialog'
          className='btn-dark btn-round'
          clickHandler={addNewDialog}
          disabled={!newDialog.name || !bot}
        />
        <Button
          icon={getIcon('close')}
          content='Cancel'
          className='btn-primary btn-round'
          clickHandler={resetModal}
        />
      </div>
    </div>
  );
};

export default AddDialogForm;
