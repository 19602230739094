import React, { useEffect, useRef, useState } from 'react';
import { useGetIcon } from '../customHooks/useGetIcon';
import Button from './Button';

interface IProps {
  title: string;
  handleInfoBtnClick?: () => void;
}
const Accordion: React.FC<IProps> = ({ title, children, handleInfoBtnClick }) => {
  const [open, setOpen] = useState(true);
  const [maxHeight, setMaxHeight] = useState('100%');
  const getIcon = useGetIcon();

  const bodyRef = useRef(null);
  const getBodyRef = () => bodyRef.current as unknown as HTMLDivElement;

  useEffect(() => {
    if (getBodyRef() && open) setMaxHeight(getBodyRef().scrollHeight + 'px');
  }, [bodyRef, open, children]);

  const handleTransition = () => {
    const initialHeight = getBodyRef().scrollHeight;

    if (open) setMaxHeight('0px');
    else setMaxHeight(initialHeight + 'px');

    setOpen((prev) => !prev);
  };

  const handleInfoClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleInfoBtnClick?.();
  };

  return (
    <div className='accordion'>
      <div className='accordion-header' onClick={handleTransition}>
        <Button
          className='btn-borderless'
          icon={getIcon('info')}
          clickHandler={handleInfoClick}
        />
        {title}
      </div>
      <div
        className='accordion-body'
        ref={bodyRef}
        data-open={open}
        style={{ maxHeight: maxHeight }}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
