import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { AdminSubmenu } from '../../../../architecture/enums/Navbar';
import rootStore from '../../../../stores/rootStore';

import NavLink from '../components/NavLink';

const AdminSubNavbar: React.FC = () => {
  const location = useLocation();
  const { dialogStore } = useContext(rootStore);

  const [activeSubmenu, setActiveSubmenu] = useState(AdminSubmenu.Subscriptions);

  useEffect(() => {
    if (location.pathname.includes('subscriptions')) {
      setActiveSubmenu(AdminSubmenu.Subscriptions);
    } else if (location.pathname.includes('bots')) {
      setActiveSubmenu(AdminSubmenu.Bots);
    } else if (location.pathname.includes('users')) {
      setActiveSubmenu(AdminSubmenu.Users);
    } else if (location.pathname.includes('textures')) {
      setActiveSubmenu(AdminSubmenu.AvatarTextures);
    } else if (location.pathname.includes('billing')) {
      setActiveSubmenu(AdminSubmenu.Billing);
    }
  }, [location.pathname, dialogStore.currentlyEditedDialog]);

  return (
    <div className='subnavbar-menu'>
      <div className='flex'>
        <NavLink
          label={AdminSubmenu.Subscriptions}
          active={activeSubmenu === AdminSubmenu.Subscriptions}
          routeTo='/admin/subscriptions'
        />
        <NavLink
          label={AdminSubmenu.Bots}
          active={activeSubmenu === AdminSubmenu.Bots}
          routeTo='/admin/bots'
        />
        <NavLink
          label={AdminSubmenu.Users}
          active={activeSubmenu === AdminSubmenu.Users}
          routeTo='/admin/users'
        />
        <NavLink
          label={AdminSubmenu.AvatarTextures}
          active={activeSubmenu === AdminSubmenu.AvatarTextures}
          routeTo='/admin/textures'
        />
        <NavLink
          label={AdminSubmenu.Billing}
          active={activeSubmenu === AdminSubmenu.Billing}
          routeTo='/admin/billing'
        />
      </div>
    </div>
  );
};

export default observer(AdminSubNavbar);
