import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { GroupReceiver as GroupReceiverModel } from '../../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/GroupReceiver';
import TextBoxWithCtx from '../../../../../../common/inputElements/TextBoxWithCtx';
import { useReadOnly } from '../../../../../../customHooks/useReadOnly';

interface IProps {
  receiver: GroupReceiverModel;
}
const GroupReceiver: React.FC<IProps> = ({ receiver }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  return (
    <Fragment>
      <TextBoxWithCtx
        disabled={readOnly}
        title='Group Name'
        value={receiver.groupName}
        saveHandler={(text: string) => (receiver.groupName = text)}
      />
    </Fragment>
  );
};

export default observer(GroupReceiver);
