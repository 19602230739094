import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, BotAdminState } from '../../architecture/enums/Admin';
import { DialogState } from '../../architecture/enums/DialogState';
import type {
  IBot,
  IUpdateBotRequest,
} from '../../architecture/interfaces/HTTP/AdminParams';
import type { ICreateBotForm } from '../../architecture/interfaces/admin/IBotForm';
import type { ISubscriptionSettings } from '../../architecture/interfaces/admin/ISubscriptionSettings';
import { Bot } from '../../models/Admin/Bot';
import { BotConnector } from '../../models/Connectors/admin/BotConnector';
import { SubscriptionConnector } from '../../models/Connectors/admin/SubscriptionConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class BotAdminStore {
  private static instance: BotAdminStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);

    BotAdminStore.instance = this;
  }

  @observable
  private _botRepository: Bot[] = [];

  @observable
  private _selectedBot?: Bot = undefined;

  @observable
  private _subscriptionSettings: ISubscriptionSettings | undefined = undefined;

  @observable
  state: BotAdminState = BotAdminState.None;

  @computed
  getState(state: BotAdminState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: BotAdminState) {
    this.state |= state;
  }

  @computed
  removeState(state: BotAdminState) {
    this.state &= ~state;
  }

  @computed
  get allBots() {
    return this._botRepository;
  }

  @computed
  get subscriptionSettings() {
    return this._subscriptionSettings;
  }

  @action
  setSelectedBot(botId?: string) {
    this._selectedBot = this._botRepository.find((bot) => bot.id === botId);
  }

  @computed
  get getSelectedBot() {
    return this._selectedBot;
  }

  @action
  purge() {
    this._botRepository = [];
  }

  @action
  async load() {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(BotAdminState.BotsLoading);
      const bots = await BotConnector.getBySubscriptionId(subscriptionId);
      runInAction(() => this.restoreBots(bots));

      const settings = await SubscriptionConnector.getSettings(subscriptionId);
      runInAction(() => this.restoreSubscriptionSettings(settings));

      this.removeState(BotAdminState.BotsLoading);
    } catch (error) {
      this.removeState(BotAdminState.BotsLoading);
    }
  }

  @action
  private restoreBots(bots: IBot[]) {
    this.purge();

    bots.forEach((bot) => {
      const instance = plainToInstance(ADMINS_CLASS_MAPPING[Admin.Bot], bot);
      this._botRepository.push(instance);
    });
  }

  @action
  private restoreSubscriptionSettings(settings: ISubscriptionSettings) {
    this._subscriptionSettings = settings;
  }

  @action
  async create(item: ICreateBotForm) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(BotAdminState.BotsLoading);

      this.rootStore.dialogStore.removeState(DialogState.Initialized);

      await BotConnector.create(subscriptionId, item);
      await this.load();
    } catch (error) {
      this.removeState(BotAdminState.BotsLoading);
    }
  }

  @action
  async refreshBotSecret(botId: string) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(BotAdminState.SecretRefreshing);
      const response = await BotConnector.refreshBotSecret(subscriptionId, botId);
      if (response && this._selectedBot) {
        this._selectedBot.botSecret = response.botSecret;
      }

      this.removeState(BotAdminState.SecretRefreshing);
    } catch (error) {
      this.removeState(BotAdminState.SecretRefreshing);
    }
  }

  @action
  async remove(bot: Bot) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(BotAdminState.BotsLoading);
      await BotConnector.delete(subscriptionId, bot.id as string);

      this._selectedBot = undefined;
      this.rootStore.dialogStore.removeState(DialogState.Initialized);

      await this.load();
    } catch (error) {
      this.removeState(BotAdminState.BotsLoading);
    }
  }

  @action
  async update(botId: string, request: IUpdateBotRequest) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(BotAdminState.BotsLoading);
      await BotConnector.update(subscriptionId, botId, request);
      this.load();
    } catch (error) {
      this.removeState(BotAdminState.BotsLoading);
    }
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('BotAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default BotAdminStore;
