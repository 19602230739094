import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { Position } from '../../../models/Utilities/Position';
import { useReadOnly } from '../../customHooks/useReadOnly';
import rootStore from '../../../stores/rootStore';
import DialogBlock from './DialogBlock';

interface IProps {
  block: DialogBlockModel;
}

const DraggableBlock: React.FC<IProps> = ({ block }) => {
  const NAVBAR_HEIGHT = 156;
  const LEFT_BORDER = block.geometry.width;
  const TOP_BORDER = NAVBAR_HEIGHT + block.geometry.height;
  const BOTTOM_BORDER = window.innerHeight - block.geometry.height;
  const RIGHT_BORDER = window.innerWidth - block.geometry.width;

  // Canvas Context
  const { canvasStore, uiStore } = useContext(rootStore);

  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: block.position.x, y: block.position.y });

  const blockRef = useRef(null);

  const handleMouseDown = () => {
    uiStore.dialogBlockPanZoomHandler.panZoomInstance!.pause();
    setIsDragging(true);
  };

  const processMouseMove = (e: any, data: { x: number; y: number }) => {
    const SLIDE_DISTANCE = 5;

    if (e.clientX > RIGHT_BORDER)
      uiStore.dialogBlockPanZoomHandler.panZoomInstance?.moveBy(
        SLIDE_DISTANCE * -1,
        0,
        false
      );
    if (e.clientX < LEFT_BORDER)
      uiStore.dialogBlockPanZoomHandler.panZoomInstance?.moveBy(SLIDE_DISTANCE, 0, false);
    if (e.clientY < TOP_BORDER)
      uiStore.dialogBlockPanZoomHandler.panZoomInstance?.moveBy(0, SLIDE_DISTANCE, false);
    if (e.clientY > BOTTOM_BORDER)
      uiStore.dialogBlockPanZoomHandler.panZoomInstance?.moveBy(
        0,
        SLIDE_DISTANCE * -1,
        false
      );

    setPosition({ x: data.x, y: data.y });

    canvasStore.refresh();
  };

  const handleDragStop = () => {
    uiStore.dialogBlockPanZoomHandler.panZoomInstance!.resume();
    block.position = new Position(position.x, position.y);
    setIsDragging(false);
  };

  useEffect(() => {
    setPosition({ x: block.position.x, y: block.position.y });
  }, [block.position]);

  return (
    <Draggable
      position={position}
      disabled={readOnly}
      nodeRef={blockRef}
      onStart={() => handleMouseDown()}
      scale={uiStore.dialogBlockPanZoomHandler.panZoomInstance?.getTransform().scale}
      onDrag={(e: any, data: any) => processMouseMove(e, data)}
      onStop={handleDragStop}
    >
      <div
        ref={blockRef}
        id={block.id}
        data-block-id={block.id}
        style={{
          height: block.geometry.height + 'px',
          width: block.geometry.width + 'px',
        }}
        className={`draggable-node-block ${isDragging ? 'dragging' : ''}`}
      >
        <DialogBlock block={block} isDragging={isDragging} />
      </div>
    </Draggable>
  );
};

export default observer(DraggableBlock);
