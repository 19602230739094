import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { FacialExpressions } from '../../../../architecture/enums/FacialExpressions';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import Button from '../../../common/Button';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Face: React.FC<IProps> = ({ entry }) => {
  const [selectedFacialExporession, setSelectedFacialExpression] = useState(
    entry.facialExpression
  );

  return (
    <>
      <div className='title'></div>
      <p className='subtitle'>Select a facial expression:</p>
      <div className='grid repeat-2'>
        {Object.values(FacialExpressions).map((exp) => (
          <Button
            key={exp}
            className={`${
              exp === selectedFacialExporession ? 'btn-primary' : 'btn-inactive'
            } btn-round`}
            content={exp}
            clickHandler={() => {
              setSelectedFacialExpression(exp);
              entry.setFacialExpression(exp);
            }}
          />
        ))}
      </div>
    </>
  );
};

export default observer(Face);
