import { makeObservable, observable } from 'mobx';
import { Attachment } from '../AuxiliaryHistoryElement/Attachment';
import { InitiatingHistoryElement } from './InitiatingHistoryElement';

export class IncomingMessageElement extends InitiatingHistoryElement {
  text: string;
  attachments: Attachment[];

  constructor() {
    super();
    this.text = '';
    this.attachments = [];

    makeObservable(this, {
      text: observable,
      attachments: observable,
    });
  }
}
