import { observer } from 'mobx-react-lite';
import React from 'react';
import { ContextVariableMap } from '../../../../../../models/DialogNodes/EntityNode/ContextVariableMap';
import { EntityQuestionNode } from '../../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import Button from '../../../../../common/Button';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';
import Example from './Example';

interface IProps {
  dialogNode: EntityQuestionNode;
}

const EntityExampleArea: React.FC<IProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();

  const renderEntityDefinitions = () => {
    return dialogNode.entityDefinitions?.map((entityDefinition, index) => {
      return (
        <Example
          entityDefinition={entityDefinition}
          removeEntityDefinition={(entityDefinition) =>
            dialogNode.removeEntityDefinition(entityDefinition)
          }
          key={index}
          entities={dialogNode.contextVariableMapping.map((item) => item.entityName)}
          addedEntityMapping={(previousEntityName, newEntityName) => {
            dialogNode.addContextVariableMap(
              previousEntityName,
              new ContextVariableMap(newEntityName)
            );
          }}
          deletedEntityMapping={(previousEntityName) =>
            dialogNode.removeContextVariableMap(previousEntityName)
          }
        />
      );
    });
  };

  const addExample = () => {
    dialogNode.addEntityDefinition();
  };

  return (
    <div className='examples-area'>
      {dialogNode.entityDefinitions?.length > 0 && <h4>Examples:</h4>}
      {renderEntityDefinitions()}
      <div className='add-example'>
        <Button
          content='Add example'
          className={'btn-primary btn-round'}
          icon={getIcon('add_alt')}
          clickHandler={addExample}
        />
      </div>
    </div>
  );
};

export default observer(EntityExampleArea);
