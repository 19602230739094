import { observer } from 'mobx-react-lite';
import React from 'react';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DefaultDialogBlock } from '../../../../models/DialogBlocks/DefaultDialogBlock';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';
import { KnowledgebaseBlock } from '../../../../models/DialogBlocks/KnowledgebaseBlock';
import { IntentTrigger as IntentTriggerModel } from '../../../../models/Triggers/IntentTrigger';
import InfoIcon from '../../../common/InfoIcon';

interface IProps {
  block: DialogBlockModel;
}
const BlockInfoIcon: React.FC<IProps> = ({ block }) => {
  const calculateInfoIconType = () => {
    const nodesLength =  block.nodes.filter((node) => node.type !== DialogNodeTypes.RootNode).length;

    if (block.isTriggerNeededButMissing) {
      return (
        <InfoIcon
          type='error'
          tooltip='This Dialog-Block needs a block-level trigger in order to be part of the dialog.'
        />
      );
    }

    if(nodesLength === 0){
      return (
        <InfoIcon
          type='error'
          tooltip='This Dialog-Block has no nodes.'
        />
      );
    }

    if (
      (block.trigger && !block.trigger.isValid) ||
      (block.trigger instanceof IntentTriggerModel &&
        block.trigger.intent!.activeLabels.length < 3)
    ) {
      return (
        <InfoIcon
          type='warning'
          tooltip='There are Trigger-related issues with this Block. The Trigger might be invalid or some attributes are missing.'
        />
      );
    }

    return null;
  };
  return block instanceof DefaultDialogBlock || block instanceof KnowledgebaseBlock
    ? null
    : calculateInfoIconType();
};

export default observer(BlockInfoIcon);
