import { Expose, Transform } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { Utilities } from '../../../Utilities/Utilities';

export interface IAdaptiveCardSubmitData {
  _actionId: string;
  [key: string]: string;
}

export class AdaptiveCardSubmit {
  @Expose()
  submitId: string;

  @Expose()
  title: string;

  @Expose()
  data: IAdaptiveCardSubmitData;

  constructor(data: IAdaptiveCardSubmitData, title: string, submitId: string) {
    this.submitId = submitId ?? '';
    this.data = data;
    this.title = title ?? '';

    makeObservable(this, {
      title: observable,
      submitId: observable,
      data: observable,
      isValid: computed,
      hasActionId: computed,
    });

    this.addActionId();
  }

  get isValid() {
    return (
      this.hasActionId &&
      !Utilities.isEmpty(this.submitId) &&
      !Utilities.isEmpty(this.title)
    );
  }

  get hasActionId() {
    if (!this.data) return false;
    return '_actionId' in this.data;
  }

  serialize() {
    return {
      submitId: this.submitId,
      title: this.title,
      data: { ...this.data },
    };
  }

  private addActionId() {
    if (this.hasActionId) return;

    this.data = this.data
      ? {
          ...this.data,
          _actionId: this.data._actionId ?? v4(),
        }
      : {
          _actionId: v4(),
        };
  }
}
