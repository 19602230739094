export enum InitiatingHistoryElementTypes {
  IncomingMessage = 0,
  IncomingEvent = 1,
  IncomingActionCard = 2,
}

export enum InitiatingHistoryElementDescriptionTypes {
  IncomingMessage = 'IncomingMessage',
  IncomingEvent = 'IncomingEvent',
  IncomingActionCard = 'IncomingActionCard',
}

export enum TriggerHistoryElementDescriptionTypes {
  ListTrigger = 'ListTrigger',
  RegexTrigger = 'RegexTrigger',
  KnowledgebaseTrigger = 'KnowledgebaseTrigger',
  LuisIntentTrigger = 'LuisIntentTrigger',
  EventTrigger = 'EventTrigger',
  DefaultTrigger = 'DefaultTrigger',
  AiTrigger = 'AiTrigger',
}

export enum TriggerHistoryElementTypes {
  ListTrigger = 100,
  RegexTrigger = 101,
  KnowledgebaseTrigger = 102,
  LuisIntentTrigger = 103,
  EventTrigger = 104,
  DefaultTrigger = 105,
  AiTrigger = 106,
}

export enum NodeHistoryElementDescriptionTypes {
  MessageNode = 'MessageNode',
  PromptNode = 'PromptNode',
  KnowledgebaseNode = 'KnowledgebaseNode',
  EmailActionNode = 'EmailActionNode',
  ContextActionNode = 'ContextActionNode',
  ApiNode = 'ApiNode',
  AdaptiveCardNode = 'AdaptiveCardNode',
}

export enum NodeHistoryElementTypes {
  MessageNode = 200,
  PromptNode = 201,
  KnowledgebaseNode = 202,
  EmailActionNode = 203,
  ContextActionNode = 204,
  ApiNode = 205,
  AdaptiveCardNode = 206,
}

export enum AuxiliaryHistoryElementDescriptionTypes {
  ValidationResult = 'ValidationResult',
  ContextVariableUpdate = 'ContextVariableUpdate',
  SystemMessage = 'SystemMessage',
}

export enum AuxiliaryHistoryElementTypes {
  ValidationResult = 300,
  ContextVariableUpdate = 301,
  SystemMessage = 302,
}

export enum PromptNodeTypes {
  TextPromptNode = 'TextPromptNode',
  ConfirmPromptNode = 'ConfirmPromptNode',
  NumberPromptNode = 'NumberPromptNode',
  DateTimePromptNode = 'DateTimePromptNode',
  ChoicePromptNode = 'ChoicePromptNode',
  AddressPromptNode = 'AddressPromptNode',
  NamePromptNode = 'NamePromptNode',
  EmailPromptNode = 'EmailPromptNode',
  PhonePromptNode = 'PhonePromptNode',
  EntityPromptNode = 'EntityPromptNode',
  AttachmentPromptNode = 'AttachmentPromptNode',
}

export enum ValidationTypes {
  Valid = 'Valid',
  Invalid = 'Invalid',
  TooManyAttempts = 'TooManyAttempts',
  CancelDialog = 'CancelDialog',
  CancelledByActionCard = 'CancelledByActionCard',
  RepromptForSubquestion = 'RepromptForSubquestion',
}
