import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { User } from '../../../models/Admin/User';
import Button from '../../common/Button';
import Input from '../../common/Input';
import InfoMessage from './components/InfoMessage';
import Roles from './components/Roles';
import useAdminStores from '../../customHooks/useAdminStores';
import useModal from '../../customHooks/useModal';
import { UserAdminState } from '../../../architecture/enums/Admin';

interface IProps {
  info: string | null | undefined;
  saveUser: (user: User) => void;
  resetModal: () => void;
}

const validationSchema = Yup.object({
  givenName: Yup.string().required('This field is required'),
  surname: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is requried'),
  roles: Yup.array().min(1, 'You should to select at least one option'),
});

const CreateUser: React.FC<IProps> = ({ info, saveUser, resetModal }) => {
  const { userAdminStore } = useAdminStores();
  const { setCloseInterceptor } = useModal();

  useEffect(() => {
    return () => {
      setCloseInterceptor(undefined);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      givenName: '',
      surname: '',
      email: '',
      roles: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveUser(values as User);
    },
  });

  setCloseInterceptor(resetModal);

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Create User </h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='givenName'>First Name:</label>
            <Input
              id='givenName'
              name='givenName'
              placeholder='Type first name here...'
              value={formik.values.givenName}
              changeHandler={(value) => formik.setFieldValue('givenName', value)}
            />
            {formik.touched.givenName && formik.errors.givenName ? (
              <div className='validation-error'>{formik.errors.givenName}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label htmlFor='surname'>Last Name:</label>
            <Input
              id='surname'
              name='surname'
              placeholder='Type last name here...'
              value={formik.values.surname}
              changeHandler={(value) => formik.setFieldValue('surname', value)}
            />
            {formik.touched.surname && formik.errors.surname ? (
              <div className='validation-error'>{formik.errors.surname}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label htmlFor='email'>Email:</label>
            <Input
              id='email'
              name='email'
              placeholder='Type email address here...'
              value={formik.values.email}
              changeHandler={(value) => formik.setFieldValue('email', value)}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='validation-error'>{formik.errors.email}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label htmlFor='email'>Roles:</label>
            <Roles
              selectedRoles={formik.values.roles}
              setSelectedRoles={(roles) =>
                formik.setFieldValue('roles', roles as string[])
              }
            />
            {formik.touched.roles && formik.errors.roles ? (
              <div className='validation-error'>{formik.errors.roles}</div>
            ) : null}
          </div>

          {info && <InfoMessage message={info} />}
          {info === undefined && (
            <div className='message error'>
              <div>User was not created. Please check if this</div>
              <div>user is alreday member of another subscription.</div>
            </div>
          )}
        </div>
        <div className='btn-container'>
          <Button
            disabled={userAdminStore.getState(UserAdminState.CreatingUserLoading)}
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={resetModal}
          />
          <Button
            disabled={
              userAdminStore.getState(UserAdminState.CreatingUserLoading) ||
              (info ? true : false)
            }
            type='submit'
            name='create-user'
            content='Add User'
            className='btn-primary btn-round'
          />
        </div>
      </form>
    </div>
  );
};

export default observer(CreateUser);
