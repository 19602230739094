import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState } from 'react';
import { ListContextVariable } from '../../../../../models/ContextVariables/ContextVariable';
import { ChoiceQuestionNode } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/ChoiceQuestionNode';
import { Text } from '../../../../../models/Utilities/Text';
import rootStore from '../../../../../stores/rootStore';
import Button from '../../../../common/Button';
import Input from '../../../../common/Input';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import TextInputWithDelete from '../../../../common/inputElements/TextInputWithDelete';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  dialogNode: ChoiceQuestionNode;
}

const MultiChoiceArea: React.FC<IProps> = ({ dialogNode }) => {
  // Custom Hook
  const getIcon = useGetIcon();
  const { ctxVarStore } = useContext(rootStore);

  // Local state handlers
  const [showInput, setShowInput] = useState(false);
  const [newChoiceValue, setNewChoiceValue] = useState('');

  const handleInputChange = (value: string) => {
    setNewChoiceValue(value);
  };

  const handleBlur = () => {
    if (newChoiceValue !== '') {
      dialogNode.addChoice(newChoiceValue);
    }
    setNewChoiceValue('');
  };

  const handleListCtxSelect = (name: string) => {
    const ctx = ctxVarStore.listContextVariables.find(
      (item) => item.name === name
    ) as ListContextVariable;

    dialogNode.listContextVariable = ctx ?? undefined;
  };

  return (
    <Fragment>
      <div className='multi-choice-container'>
        <div className='multi-choice-container-list-context-variable-selection'>
          <p>
            You can also choose a List Context Variable which will dynamically create
            choices for this node
          </p>
          <ContextVariableSelector
            items={ctxVarStore.listContextVariables}
            triggerLabel={
              dialogNode.listContextVariable?.name ?? 'Choose a List Context Variable'
            }
            selectHandler={handleListCtxSelect}
            fullWidth
          />
        </div>
        <br />
        <div className='multi-choice-container-header'>
          {showInput ? (
            <Button
              content='Save changes'
              className={'btn-dark btn-round'}
              icon={getIcon('save')}
              clickHandler={() => setShowInput(false)}
            />
          ) : (
            <Button
              content='New answer'
              icon={getIcon('add')}
              className={'btn-primary btn-round'}
              clickHandler={() => setShowInput(true)}
            />
          )}
        </div>
        {showInput && (
          <Input
            name=''
            value={newChoiceValue}
            textAlign='center'
            autoFocus={true}
            placeholder={'Insert a new choice...'}
            changeHandler={handleInputChange}
            blurHandler={handleBlur}
            inputFactory
          />
        )}
        <div className='multi-choice-items'>
          {dialogNode.choices.map((choice: Text) => (
            <TextInputWithDelete
              key={choice.id}
              textObject={choice}
              deleteHandler={() => dialogNode.removeChoice(choice.id)}
              placeholder='Enter a text...'
            />
          ))}
        </div>
      </div>
    </Fragment>
  );
};

export default observer(MultiChoiceArea);
