import { Bot } from '../../../models/Bot/Bot';
import { Dialog } from '../../../models/Bot/Dialog';

export enum DialogLanguages {
  'Deutsch' = 'de-DE',
  'English' = 'en-US',
}

export interface IAddDialogFormInitialization {
  name: string;
  description: string;
  locale: DialogLanguages;
}

export interface IEditDialogSettingsInitialization {
  bot: Bot;
  dialog: Dialog;
}
