import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import { ISelectItem } from '../../../../../architecture/interfaces/select/ISelectItem';
import {
  AllowedAttachmentType,
  AttachmentQuestionNode as AttachmentQuestionNodeModel,
} from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AttachmentQuestionNode';
import rootStore from '../../../../../stores/rootStore';
import Switch from '../../../../common/inputElements/Switch';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import Select from '../../../../common/inputElements/select/Select';
import QuestionDialogNode from '../QuestionDialogNode';

interface IProps {
  dialogNode: AttachmentQuestionNodeModel;
}

const AttachmentQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const [error, setError] = useState(false);
  const [multipleFiles, setMultipleFiles] = useState(dialogNode.multipleFiles ?? false);
  const [selectedDataType, setSelectedDataType] = useState(
    (
      Object.keys(AllowedAttachmentType) as Array<keyof typeof AllowedAttachmentType>
    )[1].toString()
  );

  const validateInput = (input: string) => {
    const containsForbiddenName =
      (multipleFiles &&
        ctxVarStore.userAndSystemVariables.map((v) => v.name).some((v) => v == input)) ||
      (!multipleFiles &&
        ctxVarStore.listAndSystemVariables.map((v) => v.name).some((v) => v == input));

    setError(containsForbiddenName);
    return containsForbiddenName;
  };

  const handleCtxSelection = (value: string) => {
    if (error) {
      return;
    }

    dialogNode.contextVariable = value
      ? ctxVarStore.addIfNotExists(
          value,
          multipleFiles
            ? ContextVariableInstanceTypes.ListContextVariable
            : ContextVariableInstanceTypes.ContextVariable
        )!
      : undefined;
  };

  const toggleMultipleFiles = () => {
    dialogNode.contextVariable = undefined;
    setMultipleFiles((prev) => {
      dialogNode.multipleFiles = !prev;
      return !prev;
    });
  };

  const ctxItems = multipleFiles
    ? ctxVarStore.listContextVariables
    : ctxVarStore.singleValueUserContextVariables;

  const dataTypeItems = (): ISelectItem[] => {
    return (
      Object.keys(AllowedAttachmentType) as Array<keyof typeof AllowedAttachmentType>
    ).map((key, index) => {
      return {
        id: index.toString(),
        title: key.toString(),
      };
    });
  };

  const selectDataType = (title: string, id?: string) => {
    dialogNode.allowedAttachmentType = title as AllowedAttachmentType;
    setSelectedDataType(dialogNode.allowedAttachmentType.toString());
  };

  return (
    <QuestionDialogNode dialogNode={dialogNode}>
      <div className='attachment-node-options'>
        <h4>Single or multiple files?</h4>
        <Switch
          className='multiple-files-switch'
          clickHandler={toggleMultipleFiles}
          value={multipleFiles}
          label={'Allow the user to upload multiple attachments at once'}
        />
      </div>
      <h4>Which file type do you want to allow?</h4>
      <Select
        items={dataTypeItems()}
        selectHandler={selectDataType}
        closeOnSelect={true}
        triggerLabel={selectedDataType}
      />
      <h4>Where would you like to save the answer?</h4>
      <ContextVariableSelector
        items={ctxItems}
        triggerLabel={dialogNode.contextVariable?.name ?? 'Add or select'}
        selectHandler={handleCtxSelection}
        inputValidator={validateInput}
        replaceSpecialCharacters
        creator
        closeOnSelect
      />
    </QuestionDialogNode>
  );
};

export default observer(AttachmentQuestionNode);
