import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { Utilities } from '../../../../../Utilities/Utilities';
import { BaseEventReceiver, ISerializedEventReceiver } from '../../BaseEventReceiver';

export class UserReceiver extends BaseEventReceiver {
  type = EventTargetReceiverTypes.User;

  @Expose()
  email: string;

  constructor() {
    super();
    this.email = '';

    makeObservable(this, {
      email: observable,
      isValid: computed,
    });
  }

  get isValid() {
    return Utilities.isEmailValid(this.email);
  }

  get isChanged() {
    return !Utilities.isEmpty(this.email);
  }

  serialize(): ISerializedUserEventReceiver {
    return {
      ...super.serialize(),
      email: this.email,
    };
  }
}

export interface ISerializedUserEventReceiver extends ISerializedEventReceiver {
  email: string;
}
