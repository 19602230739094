import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatasetStatus } from '../../../../architecture/enums/Dataset';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import { Dataset } from '../../../../models/Dataset/Dataset';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Status from './Status';

interface IProps {
  dataset: Dataset | null;
  editMode: boolean;
  addDataset: boolean;
  setEditMode: (value: boolean) => void;
  setAddProperty: (value: boolean) => void;
}

const DatasetDetailsHeader: React.FC<IProps> = ({
  dataset,
  editMode,
  addDataset,
  setEditMode,
  setAddProperty,
}) => {
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  return (
    <div className='header'>
      {editMode && dataset ? (
        <Input
          id='dataset-name'
          name='dataset-date'
          placeholder='Input dataset title...'
          value={dataset?.name || ''}
          changeHandler={(value) => (dataset.name = value)}
          actionIcon={getIcon('edit_bold')}
          disabled={dataset.status === DatasetStatus.Used}
        />
      ) : (
        <h4>{dataset?.name}</h4>
      )}

      {dataset && <Status dataset={dataset} />}
      <div className='button-wrapper'>
        {dataset?.status !== DatasetStatus.Used && !addDataset && (
          <Button
            name='delete-dataset-btn'
            content='Delete Dataset'
            icon={getIcon('remove')}
            className='btn-inactive btn-round'
            clickHandler={() => showModal(ModalTypes.DeleteElement, dataset)}
          />
        )}
        {editMode ? (
          <Button
            icon={getIcon('add')}
            name='add-property-btn'
            content='Add Property'
            className='btn-primary btn-round edit-btn'
            clickHandler={() => setAddProperty(true)}
          />
        ) : (
          <Button
            icon={getIcon('edit_bold')}
            name='edit-dataset-btn'
            content='Edit Dataset'
            className='btn-primary btn-round edit-btn'
            clickHandler={() => setEditMode(true)}
          />
        )}
      </div>
    </div>
  );
};

export default observer(DatasetDetailsHeader);
