import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { ContextVariableUpdateElement as ContextVariableUpdateElementModel } from '../../../../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ContextVariableUpdateElement';
import rootStore from '../../../../../stores/rootStore';
import BaseHistoryElement from '../../BaseHistoryElement';
import AdditionalInfo from './AdditionalInfo';
import Values from './Values';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  element: ContextVariableUpdateElementModel;
}

const ContextVariableUpdateElement: React.FC<IProps> = ({ element }: IProps) => {
  const { ctxVarStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);

  const ctxVar = ctxVarStore.getById(element.contextVariableId);

  return (
    <BaseHistoryElement
      element={element}
      openAdditionalInfo={openAdditionalInfo}
      setOpenAdditionalInfo={setOpenAdditionalInfo}
      icon={getIcon('config')}
      title='Context Variable updated'>
      <div className='card-paragraph'>
        <Values value={element?.value} ctxVar={ctxVar} />
      </div>

      {openAdditionalInfo && (
        <AdditionalInfo
          element={element}
          ctxVar={ctxVar}
        />
      )}
    </BaseHistoryElement>
  );
};

export default observer(ContextVariableUpdateElement);
