import { observer } from 'mobx-react-lite';
import React, { RefObject, useContext, useEffect } from 'react';
import { BaseDialogNode } from '../../models/DialogNodes/BaseDialogNode';
import rootStore from '../../stores/rootStore';
import { useCreateDialogNode } from '../customHooks/useCreateDialogNode';

interface IProps {
  nodes: BaseDialogNode[];
  scrollElement: RefObject<HTMLDivElement>;
}
const DialogNodeList: React.FC<IProps> = ({ scrollElement, nodes }) => {
  const { blockStore, uiStore } = useContext(rootStore);
  const { createNode } = useCreateDialogNode();

  useEffect(() => {
    const areNodesInBlockValid = blockStore.selectedBlock?.nodes.every(
      (node) => node.isValid
    );
    if (areNodesInBlockValid) {
      uiStore.scrollToElement(scrollElement.current!, 'top');
    }
  }, [blockStore.selectedBlock]);

  return (
    <div ref={scrollElement} className='dialog-node-list-container'>
      {nodes.map((node) => createNode(node))}
    </div>
  );
};

export default observer(DialogNodeList);
