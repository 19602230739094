import { makeObservable, observable } from 'mobx';
import { Attachment } from './Attachment';
import { AuxiliaryHistoryElement } from './AuxiliaryHistoryElement';

export class ValidationResultElement extends AuxiliaryHistoryElement {
  text: string;
  attachments: Attachment[];

  attemptCount: number;
  repromptText: string;
  validation: string;

  constructor() {
    super();
    this.text = '';
    this.attemptCount = 0;
    this.repromptText = '';
    this.validation = '';
    this.attachments = [];

    makeObservable(this, {
      text: observable,
      attemptCount: observable,
      repromptText: observable,
      validation: observable,
      attachments: observable,
    });
  }
}
