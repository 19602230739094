import { observer } from 'mobx-react-lite';
import React, { useRef, useEffect } from 'react';
import { SketchPicker } from 'react-color';

interface IProps {
  color: string;
  clearSelectedColor: () => void;
  handleColorChange: (color: string) => void;
}

const ColorPicker: React.FC<IProps> = ({
  color,
  clearSelectedColor,
  handleColorChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event?.target as HTMLDivElement;
    if (!ref?.current?.contains(target)) {
      clearSelectedColor();
    }
  };

  return (
    <div ref={ref} className='picker-wrapper'>
      <SketchPicker
        disableAlpha={true}
        presetColors={[]}
        color={color.toUpperCase()}
        onChangeComplete={(color) => handleColorChange(color.hex.toUpperCase())}
      />
    </div>
  );
};

export default observer(ColorPicker);
