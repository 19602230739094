import { observer } from 'mobx-react-lite';
import React from 'react';
import { EventTarget as EventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/EventTarget';
import { MicrosoftTeamsEventTarget as MicrosoftTeamsEventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/MicrosoftTeamsEventTarget';
import { TelegramEventTarget as TelegramEventTargetModel } from '../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/TelegramEventTarget';
import MicrosoftTeamsEventTarget from './MicrosoftTeams/MicrosoftTeamsEventTarget';
import TelegramEventTarget from './Telegram/TelegramEventTarget';

interface IProps {
  target: EventTargetModel;
}
const EventTarget: React.FC<IProps> = ({ target }) => {
  const renderTargetDetails = () => {
    if (target instanceof MicrosoftTeamsEventTargetModel)
      return <MicrosoftTeamsEventTarget target={target} />;
    if (target instanceof TelegramEventTargetModel) {
      return <TelegramEventTarget target={target} />;
    }

    return null;
  };
  return renderTargetDetails();
};

export default observer(EventTarget);
