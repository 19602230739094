export enum NameKey {
  FirstName = 'FirstName',
  LastName = 'LastName',
}

export enum AddressKey {
  City = 'City',
  Street = 'Street',
  StreetNumber = 'StreetNumber',
  PostalCode = 'PostalCode',
  Country = 'Country',
}

export enum DateTimeKey {
  Year = 'Year',
  Month = 'Month',
  Day = 'Day',
  Hour = 'Hour',
  Minute = 'Minute',
}

export enum DateTimeGroup {
  Date = 'Date',
  Time = 'Time',
}

export type SubQuestionGroupKeyMapping = {
  [key in SubQuestionGroup]: string[];
};

export const DateTimeKeyMapping = {
  [DateTimeGroup.Date]: [DateTimeKey.Year, DateTimeKey.Month, DateTimeKey.Day],
  [DateTimeGroup.Time]: [DateTimeKey.Hour, DateTimeKey.Minute],
};

export type SubQuestionGroup = DateTimeGroup;

export type SubQuestionKey = NameKey | AddressKey;
