import React from 'react';
import { observer } from 'mobx-react-lite';

interface IProps {
  name: string;
  title: string;
  className?: string;
  setFilterInput: (name: string, title: string) => void;
}

const TextFilter: React.FC<IProps> = ({ name, title, className, setFilterInput }) => {
  return (
    <div className={`text-filter ${className}`} onClick={() => setFilterInput(name, title)}>
      { title }
    </div>
  );
};

export default observer(TextFilter);
