import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import TooltipContainer from '../../../common/tooltip/TooltipContainer';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../customHooks/useReadOnly';

interface IProps {}

const Save: React.FC<IProps> = ({}) => {
  const {
    nodeStore,
    appStore,
    blockStore,
    dialogStore,
    botSettingsStore,
    knowledgebaseStore,
    pronunciationStore,
  } = useContext(rootStore);
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const getIcon = useGetIcon();
  const history = useHistory();
  const location = useLocation();

  const handleSave = async () => {
    if (location.pathname.includes('bot-settings/confirmation')) {
      botSettingsStore.updateConfirmationSettings();
    } else if (location.pathname.includes('settings/pronunciation')) {
      pronunciationStore.save();
    } else {
      const savedSuccesfully = await appStore.save();
      if (!savedSuccesfully) {
        history.push('/error');
      }
    }
  };

  const redirectToErrorBlock = (id: string) => {
    const node = nodeStore.getById(id)!;

    if (node.type === 'KnowledgebaseNode') {
      return;
    }

    blockStore.selectBlock(node.dialogBlock);
    history.push(
      `/dialogs/${dialogStore?.currentlyEditedDialog?.dialogId}/blocks/${node.dialogBlock.id}`
    );

    setTimeout(() => {
      const nodeElement = document.getElementById(node.id)! as HTMLElement;
      const container = document.querySelector('.dialog-node-list-container');
      container?.scrollTo({
        top: nodeElement.offsetHeight + nodeElement.clientHeight,
        left: 0,
        behavior: 'smooth',
      });
    }, 50);
  };

  const errorTooltipItems = [
    ...nodeStore.invalidNodes.map((node) => ({
      id: node.id,
      message: node.title ?? node.type,
      meta: `from block: ${node.dialogBlock.title}`,
    })),
  ];

  if (knowledgebaseStore.isInErrorState) {
    const knowledgebaseBlock = blockStore.getKnowledgebaseBlock();
    const knowledgebaeNode = nodeStore.getKnowledgebaseNode();
    if (knowledgebaseBlock && knowledgebaeNode) {
      errorTooltipItems.push({
        id: knowledgebaeNode.id,
        message: 'Error in Knowledge Base',
        meta: `Check '${knowledgebaseBlock.title}'`,
      });
    }
  }

  if (pronunciationStore.isInErrorState) {
    errorTooltipItems.push({
      id: 'pronunciation-table',
      message: 'Pronuncation table',
      meta: `Please check for repeated texts or empty cells.`,
    });
  }

  const showSaveButton =
    location.pathname.includes('designer') ||
    location.pathname.includes('knowledgebase') ||
    location.pathname.includes('blocks') ||
    location.pathname.endsWith('/bot-settings/confirmation') ||
    location.pathname.includes('settings/pronunciation');

  return (
    <>
      {!readOnly && showSaveButton && (
        <TooltipContainer
          handleItemClick={redirectToErrorBlock}
          type='error'
          className='height-full'
          items={errorTooltipItems}
        >
          <Button
            icon={getIcon('save')}
            className='control-button'
            content='Save'
            disabled={
              appStore.isInErrorState ||
              !nodeStore.areNodesValid ||
              !blockStore.areBlocksValid ||
              knowledgebaseStore.isInErrorState ||
              pronunciationStore.isInErrorState
            }
            clickHandler={handleSave}
            name={'save-to-db-btn'}
          />
        </TooltipContainer>
      )}
    </>
  );
};

export default observer(Save);
