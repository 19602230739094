import { observer } from 'mobx-react-lite';
import React from 'react';

const ContextActionItem: React.FC = (props) => {
  return (
    <div className='ctx-list-item'>
      <div className='ctx-list-item-row'>{props.children}</div>
    </div>
  );
};

export default observer(ContextActionItem);
