import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import rootStore from '../../../stores/rootStore';
import useModal from '../../customHooks/useModal';
import ModalWithChildren from './ModalWithChildren';
import PremadeTemplateModal from './PremadeTemplateModal';

interface IProps {
  hideCloseButton?: boolean;
  manuallyTriggered?: boolean;
  closeHandler?: () => void;
  openConnectedModal?: boolean;
  preventCloseOnOverlayClick?: boolean;
}
const Modal: React.FC<IProps> = ({
  hideCloseButton,
  manuallyTriggered,
  closeHandler,
  children,
  openConnectedModal = false,
  preventCloseOnOverlayClick = false,
}) => {
  const { uiStore } = useContext(rootStore);
  const modalRoot = document.getElementById('modal-root')!;

  // Custom Hooks
  const { modalOpen: shouldPremadeModalOpen } = useModal();

  const willOpen = shouldPremadeModalOpen || manuallyTriggered || openConnectedModal;

  useEffect(() => {
    if (manuallyTriggered !== undefined && !closeHandler)
      throw new Error(
        'A manually triggered (non-premade) modal needs a pre-defined close handler.'
      );
  }, []);

  // The modal root is a fixed element to top, bottom, left, right 0. It is also a flex-box that centers its elements.
  // Its z-index will be dynamically changed if a model is opened / closed
  useEffect(() => {
    if (openConnectedModal && manuallyTriggered) {
      uiStore.openSecondModal();
    } else if (openConnectedModal && !manuallyTriggered) {
      uiStore.closeSecondModal();
    }

    if (willOpen) {
      modalRoot.style.zIndex = '10000';
    } else {
      modalRoot.style.zIndex = '-1';
    }

    return () => {
      // all modals should be closed, only if all modals are closed zIndex will be -1
      if (
        manuallyTriggered === undefined ||
        (manuallyTriggered && modalRoot.childNodes.length == 0)
      )
        modalRoot.style.zIndex = '-1';
    };
  }, [shouldPremadeModalOpen, manuallyTriggered]);

  const renderModal = () => {
    if (!willOpen) return null;

    if (manuallyTriggered !== undefined && manuallyTriggered)
      return (
        <ModalWithChildren
          closeHandler={() => closeHandler?.()}
          hideCloseButton={hideCloseButton}
          children={children}
          hidden={!openConnectedModal && uiStore.secondModalOpened}
          preventCloseOnOverlayClick={preventCloseOnOverlayClick}
        />
      );
    else if (shouldPremadeModalOpen && manuallyTriggered === undefined)
      return <PremadeTemplateModal />;
    else return null;
  };

  if (!modalRoot) return null;

  return ReactDOM.createPortal(willOpen ? renderModal() : null, modalRoot);
};

export default observer(Modal);
