import { Expose, Type } from 'class-transformer';
import { makeAutoObservable } from 'mobx';
import { IBot } from '../../architecture/interfaces/HTTP/AdminParams';

export class Bot implements IBot {
  @Expose()
  botName?: string;

  @Expose()
  languageCode?: string;

  @Expose()
  @Type(() => Date)
  creationDate?: Date;

  @Expose()
  @Type(() => Date)
  startDate?: Date;

  @Expose()
  @Type(() => Date)
  expiryDate?: Date;

  @Expose({ name: 'botId' })
  id: string;

  @Expose()
  requests?: number;

  @Expose()
  botSecret: string;

  @Expose()
  knowledgebaseName?: string;

  @Expose()
  threshold?: number;

  @Expose()
  avatarTexture?: string;

  constructor() {
    this.botName = '';
    this.botSecret = '';
    this.knowledgebaseName = '';
    this.languageCode = '';
    this.id = '';
    this.avatarTexture = undefined;

    this.threshold = 0;
    this.requests = 0;

    makeAutoObservable(this);
  }
}
