import { observer } from 'mobx-react-lite';
import React from 'react';
import { Row as RowType } from 'react-table';
import { TableData } from '../../../architecture/enums/TableData';

interface IRowProps {
  selectedRow?: TableData;
  onRowClick?: (data: TableData, row?: RowType) => void;
  row: RowType<TableData>;
  classRow?: string;
}

const Row: React.FC<IRowProps> = ({
  row,
  onRowClick,
  selectedRow,
  classRow,
}: IRowProps) => {
  return (
    <div
      className={
        classRow
          ? classRow
          : selectedRow && selectedRow?.id === row.original.id
          ? 'tr selected'
          : 'tr'
      }
      data-row-clickable={onRowClick ? 'true' : 'false'}
      {...row?.getRowProps()}
    >
      {row.cells.map((cell) => {
        return (
          <div
            {...cell.getCellProps()}
            className='td'
            onClick={() =>
              cell.column.id !== 'actions' && onRowClick && onRowClick(row.original, row)
            }
          >
            <div>{cell.render('Cell')}</div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(Row);
