import { computed, makeObservable } from 'mobx';
import { JsonContainer } from './JsonContainer';
import { JsonValue } from './JsonValue';
import { PathElement } from './PathElements/PathElement';

export type PathDefinition = {
  stringified: string;
  pathTree: PathElement[];
};

export abstract class JsonElement {
  /**
   * Returns the stringified path of a JsonElement
   * @param element A JsonElement instance
   * @param forKey Determines if a Key has been stored - at JsonContainer it is irrelevant but a JsonValue can have its key OR value stored
   * @returns The stringified path of how to reach the element
   */
  public static getStringifiedPathOf(element: JsonElement, forKey: boolean) {
    if (element instanceof JsonContainer) {
      const casted = element as JsonContainer;
      return casted.paths.stringified;
    } else {
      const casted = element as JsonValue;
      if (forKey) {
        return casted.paths.key.stringified;
      }
      return casted.paths.value.stringified;
    }
  }

  private _name: string | number;
  parent: JsonContainer | null;
  depth: number;
  index?: number;
  constructor(
    parent: JsonContainer | null,
    name: string | number,
    depth: number,
    index?: number
  ) {
    this.parent = parent;
    this._name = name;
    this.depth = depth;
    this.index = index;
    parent?.children.push(this);

    makeObservable(this, {
      parentTree: computed,
      stringifiedParentTree: computed,
      nearestArrayContainerParent: computed,
      isArrayElement: computed,
    });
  }

  get isArrayElement() {
    return this.index !== undefined;
  }

  get name() {
    return this._name;
  }

  set name(value: string | number) {
    this._name = value;
  }

  get parentTree() {
    let parents: JsonContainer[] = [];

    let parent = this.parent;
    while (parent) {
      parents.unshift(parent);
      parent = parent.parent;
    }

    return parents;
  }

  /**
   * Serializes the parent tree to PathElements. The forKey parameter is a decisive factor in case of JsonValues if we stored a Key or a Value.
   * @param forKey Boolean
   */
  abstract serializeToPathElements(forKey: boolean): PathElement[];

  /** Calculates the COMPLETE path to reach this JsonElement from bottom to top. */
  get stringifiedParentTree(): string {
    let path =
      this.isArrayElement && typeof this.name !== 'number'
        ? [this.index, this.name]
        : [this.name];

    let parent = this.parent;
    while (parent) {
      path.unshift(parent.name);
      parent = parent.parent;
    }
    return path.join('.');
  }

  /** From the bottom to top we check if we find a parent that is an ArrayContainer
   * If so, its parent will be the nearest element whose value is an array and will be used as a starting point
   * for ListElements
   */
  get nearestArrayContainerParent(): JsonContainer | undefined {
    let parent: JsonContainer | undefined;
    let index = this.parentTree.length - 1;

    while (index >= 0) {
      let currentParent = this.parentTree[index];

      if (currentParent?.isArrayContainer) {
        parent = currentParent;
        break;
      }

      index--;
    }

    return !!parent ? parent : undefined;
  }

  /**
   * Checks if we find an element in the parent tree whose stringified path is the same as the container parameter's
   * It does NOT compare instances because that would not be reliable at deserialization
   * @param container A JsonContainer instance
   * @returns Boolean
   */
  isChildOf(container: JsonContainer) {
    return this.parentTree.find(
      (parent) => parent.stringifiedParentTree === container.stringifiedParentTree
    );
  }
}
