import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { TransformIntoTextArray } from '../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../Utilities/Text';
import { Utilities } from '../Utilities/Utilities';
import { ISerializedTrigger, Trigger } from './Trigger';

export class UserInputTrigger extends Trigger {
  @Expose()
  @TransformIntoTextArray
  values: Text[];

  constructor() {
    super();
    this.values = [];
    this.type = TriggerTypes.UserInput;

    makeObservable(this, {
      values: observable,
    });
  }

  get isValid() {
    return !Utilities.isEmpty(this.values);
  }

  serialize(): ISerializedUserInputTrigger {
    return {
      ...super.serialize(),
      values: this.values.map((val) => val.serialize()),
    };
  }
}

export interface ISerializedUserInputTrigger extends ISerializedTrigger {
  values: ISerializedText[];
}
