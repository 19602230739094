export enum Admin {
  Bot = 'Bot',
  Subscription = 'Subscription',
  User = 'User',
  BillingDailySummary = 'BillingDailySummary',
  BillingSummary = 'BillingSummary',
  CreditManagement = 'CreditManagement',
  AvatarTexture = 'AvatarTexture',
}

export enum BotAdminState {
  None = 0,
  BotsLoading = 1 << 0,
  SecretRefreshing = 1 << 1,
  UpdateLoading = 1 << 2,
}

export enum SubscriptionAdminState {
  None = 0,
  SubscriptionsLoading = 1 << 0,
}

export enum UserAdminState {
  None = 0,
  UsersLoading = 1 << 0,
  UpdateLoading = 1 << 1,
  ExistingUsersLoading = 1 << 2,
  CreatingUserLoading = 1 << 3,
  UpdatingUserLoading = 1 << 4,
  ResetPassword = 1 << 5,
}

export enum BillingAdminState {
  None = 0,
  DetailsLoading = 1,
}

export enum CreditManagmentState {
  None = 0,
  Loading = 1,
}

export enum AvatarTexturesAdminState {
  None = 0,
  Loading = 1,
}
