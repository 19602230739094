import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { NavbarMenu } from '../../../architecture/enums/Navbar';
import { Role } from '../../../architecture/enums/Roles';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import { useGetIcon } from '../../customHooks/useGetIcon';
import NavigationButton from './components/NavigationButton';

interface IProps {
  activeNavbarMenu?: string;
}

const NavMenu: React.FC<IProps> = ({ activeNavbarMenu }) => {
  const getIcon = useGetIcon();
  const { userRoles } = useContext(AuthContext);

  return (
    <div className='navbar-navigation-buttons'>
      {userRoles?.includes(Role.GlobalAdmin) && (
        <NavigationButton
          content='Admin'
          icon={getIcon('config')}
          active={activeNavbarMenu === NavbarMenu.Admin}
          routeTo='/admin/subscriptions'
        />
      )}
      <NavigationButton
        content='Media'
        icon={getIcon('link')}
        active={activeNavbarMenu === NavbarMenu.Media}
        routeTo='/media'
      />
      <NavigationButton
        content='Storage'
        icon={getIcon('table')}
        active={activeNavbarMenu === NavbarMenu.Storage}
        routeTo='/datasets/manage'
      />
      <NavigationButton
        content='Bots'
        icon={getIcon('users')}
        active={activeNavbarMenu === NavbarMenu.Bots}
        routeTo='/'
      />
    </div>
  );
};

export default observer(NavMenu);
