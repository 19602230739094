import { observer } from 'mobx-react-lite';
import React from 'react';
import { useHistory } from 'react-router';

interface IProps {
  label: string;
  path: string;
  icon: string;
}

const Card: React.FC<IProps> = ({ label, path, icon }) => {
  const history = useHistory();

  return (
    <div
      className='card'
      onClick={() => {
        history.push(path);
      }}
    >
      <div className='card-icon'>
        <i className={icon}></i>
      </div>
      <div className='card-text'>
        <span>{label}</span>
      </div>
    </div>
  );
};

export default observer(Card);
