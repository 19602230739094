import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media as MediaModel } from '../../../models/Media/Media';
import MediaItem from '../MediaItem';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  item: MediaModel;
}

const ImageItem: React.FC<IProps> = ({ item }: IProps) => {
  const getIcon = useGetIcon();

  return (
    <MediaItem item={item}>
      <img src={item.url} width='100%' height='100%' style={{ objectFit: 'cover' }} />
      <div className='video-icon'>
        <i className={`${getIcon('image')} ${'media-item-icon'}`}></i>
      </div>
    </MediaItem>
  );
};

export default observer(ImageItem);
