import { observer } from 'mobx-react-lite';
import React from 'react';
import { DecisiveQuestionNode as DecisiveQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DecisiveQuestionNode';
import SingleQuestionNode from '../SingleQuestionNode/SingleQuestionNode';
interface IProps {
  dialogNode: DecisiveQuestionNodeModel;
}

const DecisiveQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <SingleQuestionNode dialogNode={dialogNode} />;
};

export default observer(DecisiveQuestionNode);
