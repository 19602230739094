import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';
import { Utilities } from '../../../models/Utilities/Utilities';
import { Media as MediaModel } from '../../../models/Media/Media';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import Button from '../../common/Button';
import Textarea from '../../common/inputElements/Textarea';
import useModal from '../../customHooks/useModal';
import rootStore from '../../../stores/rootStore';
import DocumentPreview from './DocumentPreview';
import VideoPreview from './VideoPreview';
import ImagePreview from './ImagePreview';
import { useGetIcon } from '../../customHooks/useGetIcon';
import {
  DOCUMENTS_CONTENT_TYPE,
  VIDEOS_CONTENT_TYPE,
  IMAGES_CONTENT_TYPE,
} from '../../../architecture/constants/Media';

const MediaFileDetails: React.FC = () => {
  const { uiStore, dialogStore, mediaStore } = useContext(rootStore);
  const item = uiStore.modalState.element as MediaModel;
  const getIcon = useGetIcon();
  const { closeModal, showModal } = useModal();
  const history = useHistory();
  const [description, setDescription] = useState(item.description);

  const renderMediaPreview = () => {
    if (VIDEOS_CONTENT_TYPE.includes(item.contentType))
      return <VideoPreview item={item} />;
    if (IMAGES_CONTENT_TYPE.includes(item.contentType))
      return <ImagePreview item={item} />;
    if (DOCUMENTS_CONTENT_TYPE.includes(item.contentType))
      return <DocumentPreview item={item} />;
  };

  const saveFile = () => {
    saveAs(item.url, item.fileName);
  };

  return (
    <div className='media-details'>
      {renderMediaPreview()}
      <div className='list-wrapper'>
        <h4 className='details-header'>Media Details</h4>
        <p className='item-name'>
          <i className={getIcon('document')} />{' '}
          {Utilities.truncateText(item.fileName, 45)}
        </p>
        <p className='item'>
          <i className={getIcon('calendar')} />{' '}
          {format(new Date(item.created), 'dd.MM.yyyy HH:mm')}
        </p>
        <p className='item'>
          <i className={getIcon('user')} /> {item.creator}
        </p>

        <div className='item'>
          <i className={getIcon('edit')} />
          <Textarea
            name='description'
            placeholder='Add description here...'
            className='media-details-textarea'
            value={description || ''}
            textAlign='left'
            changeHandler={(value) => setDescription(value)}
            blurHandler={() => mediaStore.update(item.mediaId, description)}
          />
        </div>

        <div className='detail-buttons'>
          <Button
            icon={getIcon('download')}
            className='btn-borderless'
            tooltip='Download'
            tooltipPosition='top'
            clickHandler={saveFile}
          />
          <Button
            icon={getIcon('remove')}
            tooltip='Delete'
            tooltipPosition='top'
            className='btn-borderless'
            clickHandler={() => {
              closeModal();
              showModal(ModalTypes.DeleteElement, item as MediaModel);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(MediaFileDetails);
