import { observer } from 'mobx-react-lite';
import React from 'react';
import { QuestionDialogNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionDialogNode';
import { AttachmentQuestionNode } from '../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/AttachmentQuestionNode';
import Switch from '../../../common/inputElements/Switch';

interface IProps {
  dialogNode: QuestionDialogNode;
}

const AutoRecognition: React.FC<IProps> = ({ dialogNode }) => {
  let text = 'Use AI to check if the user has already given the answer to this question';

  if (dialogNode instanceof AttachmentQuestionNode) {
    text = 'Check for sent attachments in previous user messages';
  }

  return (
    <div className='auto-recognition-container'>
      <Switch
        key='autoRecognition'
        value={dialogNode.autoRecognitionEnabled}
        clickHandler={() => {
          dialogNode.autoRecognitionEnabled = !dialogNode.autoRecognitionEnabled;
        }}
        label={
          <div className='auto-recognition-label'>
            <span>{text}</span>
          </div>
        }
      />
    </div>
  );
};

export default observer(AutoRecognition);
