import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { ApiActionNode } from '../../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { Utilities } from '../../../../../models/Utilities/Utilities';
import rootStore from '../../../../../stores/rootStore';
import Button from '../../../../common/Button';
import TextareaJson from '../../../../common/TextareaJson';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  dialogNode: ApiActionNode;
  closeHandler: () => void;
  switchToRespnseView: () => void;
  handlePaste: (text: string) => void;
  clearHandler: () => void;
}
const InsertJsonHandler: React.FC<IProps> = ({
  dialogNode,
  switchToRespnseView,
  closeHandler,
  handlePaste,
  clearHandler,
}) => {
  const getIcon = useGetIcon();
  const { uiStore } = useContext(rootStore);
  return (
    <div className='textarea-response-creator'>
      <div className='textarea-response-creator-top-bar'>
        <div className='btn-container'>
          <Button
            icon={getIcon('back')}
            className='btn-borderless'
            content='Back'
            clickHandler={closeHandler}
          />
          <Button
            icon={getIcon('ban')}
            className='btn-borderless btn-remove'
            style={{ color: uiStore.colors.darkRed }}
            content='Clear JSON'
            disabled={Utilities.isEmpty(dialogNode.response)}
            clickHandler={clearHandler}
          />
          <Button
            icon={getIcon('forward')}
            className='btn-borderless'
            content='View Result'
            reverseContent
            disabled={Utilities.isEmpty(dialogNode.response)}
            clickHandler={switchToRespnseView}
          />
        </div>
      </div>
      <TextareaJson
        staticHeight
        value={dialogNode.response ?? ''}
        onPaste={handlePaste}
      />
    </div>
  );
};

export default observer(InsertJsonHandler);
