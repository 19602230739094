import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState } from 'react';
import RadioButton from './RadioButton';

interface Option {
  label: string;
  value: string | number;
}

interface RadioButtonGroupProps {
  name: string;
  btnClassName?: string;
  options: Option[];
  initialSelectedValue?: string | number;
  onSelectionChange: (selectedValue: string | number) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  name,
  btnClassName,
  options,
  initialSelectedValue,
  onSelectionChange,
}) => {
  if (!options) {
    throw new Error('Cannot use RadioButtonGroup without any option');
  }

  const initialValue = initialSelectedValue ?? options[0].value;

  if (!options.map((option) => option.value).includes(initialValue)) {
    throw new Error('Initial selected value is not available in options');
  }

  const [selectedValue, setSelectedValue] = useState<string | number>(initialValue);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onSelectionChange(newValue);
  };

  return (
    <div className={'radio-btn-group'}>
      {options.map((option) => (
        <RadioButton
          className={btnClassName}
          key={option.value}
          label={option.label}
          name={name}
          value={option.value}
          // eslint-disable-next-line eqeqeq
          checked={selectedValue == option.value}
          onChange={handleRadioChange}
        />
      ))}
    </div>
  );
};

export default observer(RadioButtonGroup);
