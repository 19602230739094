import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { ChannelReceiver as ChannelReceiverModel } from '../../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/ChannelReceiver';
import TextBoxWithCtx from '../../../../../../common/inputElements/TextBoxWithCtx';
import { useReadOnly } from '../../../../../../customHooks/useReadOnly';

interface IProps {
  receiver: ChannelReceiverModel;
}
const ChannelReceiver: React.FC<IProps> = ({ receiver }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  return (
    <Fragment>
      <TextBoxWithCtx
        disabled={readOnly}
        title='Team Name'
        value={receiver.team}
        saveHandler={(text: string) => (receiver.team = text)}
      />
      <TextBoxWithCtx
        disabled={readOnly}
        title='Channel Name'
        value={receiver.channel}
        saveHandler={(text: string) => (receiver.channel = text)}
      />
      <TextBoxWithCtx
        disabled={readOnly}
        title='Tenant ID'
        value={receiver.tenantId}
        saveHandler={(text: string) => (receiver.tenantId = text)}
      />
    </Fragment>
  );
};

export default observer(ChannelReceiver);
