import { DATE_TIME_DISPLAY_OPTIONS } from '../../architecture/interfaces/IDateTimeDisplay';
import ContextVariableStore from '../../stores/ContextVariableStore';

export class Utilities {
  static sanitizeJSON(text: string) {
    return text.replace(/\r\n|\n|\r|\t|\s|[\u00a0]/gm, '').replace(/[ ]*"/g, '"');
  }
  static isJsonValid(body?: string) {
    if (!body) return false;
    try {
      const sanitizedBody = JSON.stringify(this.sanitizeJSON(body));
      JSON.parse(JSON.parse(sanitizedBody));
      return true;
    } catch (error) {
      return false;
    }
  }
  static isEmpty(input: any[] | string | object | undefined | null) {
    if (input === null || input === undefined) return true;

    // Indicates that the input might be something like "{}"
    if (typeof input === 'string' && this.isJsonValid(input)) {
      try {
        return Object.keys(JSON.parse(input)).length === 0;
      } catch (error) {
        return false;
      }
    }

    if (typeof input === 'object') {
      return Object.keys(input).length === 0;
    }

    if (Array.isArray(input) || typeof input === 'string') {
      return input.length === 0;
    }

    return false;
  }

  static isEmailValid(input?: string) {
    if (!input) return false;

    const pattern =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!input.match(pattern);
  }

  static isUrlValid(input?: string) {
    if (!input) return false;
    const pattern =
      /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%\/\&=?\.]+\.[a-z]{2,4}\/?([^\s<>\#%"\,\{\}\\|\\\^\[\]`]+)?)/gi;
    return !!input.match(pattern);
  }

  static isHexCodeValid(input?: string) {
    if (!input) return false;
    const pattern = /^#[0-9a-fA-F]{6}$/;
    return !!input.match(pattern);
  }

  static areArraysEqual(arr1: any[], arr2: any[]) {
    if (arr1.length !== arr2.length) return false;

    return arr1.every((item, index) => arr2[index] === item);
  }

  static replaceCharacterInText(text: string, charToReplace: string, replacer: string) {
    const regexp = new RegExp(charToReplace, 'g');
    return text.replace(regexp, replacer);
  }

  static replaceAllSpecialCharactersInText({
    text,
    replacer = '_',
    exceptions = ['_', '.'],
  }: {
    text: string;
    replacer?: string;
    exceptions?: string[];
  }) {
    const regexp = new RegExp(/[!@°§#$%^&*()+\-=\[\]{};':"\\|,._<>\/?\s`´]+/);

    let characters = text.split('');

    characters = characters.map((x) => {
      if (exceptions.includes(x)) return x;

      return regexp.test(x) ? replacer : x;
    });

    return characters.join('');
  }

  static truncateText(text: string, limiter: number) {
    if (!text) return '';
    if (text.length <= limiter) return text;
    if (limiter <= 3) return `${text[0]}...`;

    let truncatePoint = limiter - 3;

    // Example:
    // Rich text: Lorem #Context sit amet, consetetur sadipscing elitr, sed
    // Actual text: Lorem {{e1d418e0-1a88-4aba-bf93-429bb9e24484}} sit amet, consetetur sadipscing elitr, sed

    // We have do differentiate between the interpreted length of the text, which is shown to the user and the actual
    // length of the text which may include context variables encoded as GUIDs.
    // Thus, in this algorithm the "interpretedLength" is compared to the limiter because this is what the user actually sees,
    // and the "actualLength" gives the position in the text-string.

    // With the above example:
    // truncatText(text, 10) = Lorem ...
    // Here interpretedLength and actualLength are equal because the context variable wouldn't fit the limit.
    // truncateText(text, 15) = Lorem #Context
    // here interpretedLength is 14 (Lorem #Context) and actualLength is 46 (Lorem {{e1d418e0-1a88-4aba-bf93-429bb9e24484}})
    // If we would say "Lorem #Context" fits inside the limit and return this first 15 characters we would actually return
    // "Lorem {{e1d418" which can of course not be interpreted as a rich text as "Lorem #Context".

    let interpretedLength = 0;
    let actualLength = 0;
    let result = '';

    const contextVariableStore = ContextVariableStore.getInstance();

    // handle case when the truncated text contains a context variable at the end of the truncated part
    const textFragments = text.split(contextVariableStore.guidPattern);

    for (const frag of textFragments) {
      const fragmentIsContextVariable = frag.match(contextVariableStore.guidPattern);
      if (!fragmentIsContextVariable) {
        result += frag;

        if (interpretedLength + frag.length <= truncatePoint) {
          interpretedLength += frag.length;
          actualLength += frag.length;
        } else {
          actualLength += truncatePoint - interpretedLength;
          return `${result.substring(0, actualLength)}...`;
        }
      }

      if (fragmentIsContextVariable) {
        const ctx = contextVariableStore.getById(frag);
        if (!ctx) continue;

        // If length of the context variable's name would exceed the limit, do not include
        // the context variable and return the previous state of the substring.
        const ctxLength = ctx.name.length;
        if (interpretedLength + ctxLength > truncatePoint) {
          return `${result}...`;
        } else {
          result += frag;
          interpretedLength += ctxLength + 1; // +1 because of #
          actualLength += frag.length; // That's the important point :)
        }
      }
    }

    return result;
  }

  static getLocalDate(
    date?: Date,
    format: 'complete' | 'dateOnly' | 'datetime' | 'dateShort' = 'complete'
  ) {
    if (!date) return '';

    let config = DATE_TIME_DISPLAY_OPTIONS.dateComplete;
    switch (format) {
      case 'dateOnly':
        config = DATE_TIME_DISPLAY_OPTIONS.dateOnly;
        break;
      case 'datetime':
        config = DATE_TIME_DISPLAY_OPTIONS.dateTime;
        break;
      case 'dateShort':
        config = DATE_TIME_DISPLAY_OPTIONS.dateShort;
        break;
    }

    return date.toLocaleString('de-DE', config);
  }

  static isGuidValid(input?: string) {
    if (!input) return false;

    const pattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    return !!input.match(pattern);
  }
}
