import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext } from 'react';
import { ModalTypes } from '../../../../architecture/enums/ModalTypes';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import { RedirectDialogNode } from '../../../../models/DialogNodes/RedirectDialogNode';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';

interface IProps {
  dialogNode: BaseDialogNode;
}

const NodeBottomBar: React.FC<IProps> = ({ dialogNode }) => {
  // Context | Stores
  const { blockStore, nodeStore } = useContext(rootStore);

  // Custom Hooks
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  // Click handlers
  const handleMessageNodeSelectorModal = () => {
    nodeStore.selectedParentNode = dialogNode;
    showModal(ModalTypes.MessageNodeTypeSelector);
  };

  const handleQuestionNodeSelectorModal = () => {
    nodeStore.selectedParentNode = dialogNode;
    showModal(ModalTypes.QuestionNodeTypeSelector);
  };

  const handleActionNodeModal = () => {
    nodeStore.selectedParentNode = dialogNode;
    showModal(ModalTypes.ActionNodeTypeSelector);
  };

  return (
    <div className='node-bottom-toolbar'>
      <Fragment>
        <Button
          icon={getIcon('message')}
          className='btn-primary btn-borderless'
          clickHandler={handleMessageNodeSelectorModal}
        />
        <Button
          icon={getIcon('question')}
          className='btn-primary btn-borderless'
          clickHandler={handleQuestionNodeSelectorModal}
        />
        <Button
          icon={getIcon('config')}
          className='btn-primary btn-borderless'
          clickHandler={handleActionNodeModal}
        />
        <Button
          icon={getIcon('redirect')}
          className='btn-primary btn-borderless'
          clickHandler={() =>
            nodeStore.insertAfter(
              new RedirectDialogNode(blockStore.selectedBlock!),
              dialogNode
            )
          }
        />
      </Fragment>
    </div>
  );
};

export default observer(NodeBottomBar);
