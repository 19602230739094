import { computed, makeObservable } from 'mobx';
import { JsonContainer } from './JsonContainer';
import { JsonElement, PathDefinition } from './JsonElement';
import { ArrayElement } from './PathElements/ArrayElement';
import { ListElement } from './PathElements/ListElement';
import { PathElement } from './PathElements/PathElement';
import { PropertyElement } from './PathElements/PropertyElement';

export class JsonValue extends JsonElement {
  value: string | number | null;

  constructor(
    parent: JsonContainer | null,
    name: string | number,
    depth: number,
    value: string | number | null,
    index?: number
  ) {
    super(parent, name, depth, index);
    this.value = value;

    makeObservable(this, {
      paths: computed,
    });
  }

  get paths(): { key: PathDefinition; value: PathDefinition } {
    return {
      value: {
        pathTree: this.getValuePathTree(),
        stringified: this.getValuePathTree()
          .map((item) => item.key)
          .join('.'),
      },
      key: {
        pathTree: this.getkeyPathTree(),
        stringified: this.sanitizedKeyPathString,
      },
    };
  }

  serializeToPathElements(forKey: boolean): PathElement[] {
    if (forKey) return this.paths.key.pathTree;

    return this.paths.value.pathTree;
  }

  private get sanitizedKeyPathString(): string {
    return PathElement.getStringifiedPathOf(this.getkeyPathTree());
  }

  private getValuePathTree(): PathElement[] {
    const transformed = [...this.parentTree, this].map((item) => {
      if ((item as JsonContainer).isArrayElement) return new ArrayElement(item.index!);

      return new PropertyElement(item.name.toString());
    });

    return transformed;
  }

  private getkeyPathTree(): PathElement[] {
    let baseArray: (JsonContainer | JsonValue)[] = [...this.parentTree];
    let sanitized = [];

    // Remove all elements the are below the nearest array-container parent in the parent-tree of this
    // Those properties will be handled during the instantiation of ListElement
    if (this.nearestArrayContainerParent) {
      baseArray = [...baseArray].filter(
        (item) => item.depth < this.nearestArrayContainerParent!.depth
      );
    }

    baseArray = [...baseArray, this];

    for (const item of baseArray) {
      //#region Handling what should happen with "this"

      // There is only one JsonValue instance in the parent-tree: this
      // It needs a bit of a special treatment
      if (item === this) {
        if (!this.parent) continue;

        if (this.parent.isArrayContainer) {
          // If the parent is an array container and this item has a depth of 2
          if (!this.parent.parent) {
            sanitized.push(new ListElement(null, [this.name]));
          } else {
            sanitized.push(new ListElement(this.name));
          }
        } else {
          let nestedPropertyNames: (string | number)[] = [this.name];

          if (this.nearestArrayContainerParent) {
            let parent: JsonContainer | null = this.parent;

            // Looping through all the parents until we reach the nearestArrayContainerParent
            // to create the ListElement's nested properties:
            //  - Key is the nearestArrayContainerParent's name
            //  - NestedProperties is an array of all the names of the elements in between
            while (!!parent && parent !== this.nearestArrayContainerParent) {
              if (!parent.isArrayElement) {
                nestedPropertyNames.unshift(parent.name);
              }
              parent = parent.parent;
            }
          } else {
            if (this.parent.parent) {
              nestedPropertyNames = [this.parent.name, this.name];
            } else {
              nestedPropertyNames = [this.name];
            }
          }

          sanitized.push(
            new ListElement(
              this.nearestArrayContainerParent?.name ?? null,
              nestedPropertyNames
            )
          );
        }
        continue;
      }

      //#endregion End of handling "this"

      if ((item as JsonContainer).isArrayElement) {
        if (
          item.parent?.paths.stringified !==
          this.nearestArrayContainerParent?.paths.stringified
        ) {
          sanitized.push(new ArrayElement(item.index!));
          continue;
        }

        continue;
      }

      // The JsonValue's direct parent and its nearest array container parent has already been taken care of above
      // All other JsonContainers will be converted to a PropertyElement
      if (item !== this.nearestArrayContainerParent && item !== this.parent) {
        sanitized.push(new PropertyElement(item.name.toString()));
      }
    }

    return sanitized;
  }
}
