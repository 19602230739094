import { Exclude, Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import type {
  SubQuestionGroup,
  SubQuestionGroupKeyMapping,
} from '../../../../architecture/enums/SubQuestionKey';
import ContextVariableStore from '../../../../stores/ContextVariableStore';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import {
  BaseMultiQuestionNode,
  ISerializedMultiQuestionDialogNode,
} from '../BaseMultiQuestionNode';
import { GroupedSubQuestion, ISerializedGroupedSubQuestion } from './GroupedSubQuestion';

export class GroupedMultiQuestionNode extends BaseMultiQuestionNode {
  @Expose()
  @Type(() => GroupedSubQuestion)
  subQuestions: GroupedSubQuestion[] = [];

  @Exclude()
  groupKeyMapping: SubQuestionGroupKeyMapping;

  allowedSubQuestionKeys: SubQuestionGroup[];

  constructor(
    block: DialogBlock,
    allowedSubQuestionKeys: Array<SubQuestionGroup>,
    groupMapping: SubQuestionGroupKeyMapping
  ) {
    super(block);
    this.groupKeyMapping = groupMapping;
    this.allowedSubQuestionKeys = allowedSubQuestionKeys;

    makeObservable(this, {
      subQuestions: observable,
      groupKeyMapping: observable,
    });
  }

  get isValid() {
    return super.isValid && this.areSubQuestionsValid;
  }

  get areSubQuestionsValid(): boolean {
    return this.subQuestions.every((item) => item.isValid);
  }
  isKeyStored(key: SubQuestionGroup): boolean {
    return this.subQuestions.some((item) => item.groupKey === key);
  }

  getSubQuestionByKey(key: SubQuestionGroup) {
    return this.subQuestions.find((item) => item.groupKey === key);
  }

  addSubQuestion(key: SubQuestionGroup): GroupedSubQuestion {
    if (!(this.allowedSubQuestionKeys as SubQuestionGroup[]).includes(key)) {
      throw new Error(
        `SubQuesiton Key (${key}) is not a member of the MultiQuestionNode SubQuestion Keys: ${this.allowedSubQuestionKeys}`
      );
    }

    // Returning an existing instance of the subQuestion if there is one
    const existingSubQuestion = this.getSubQuestionByKey(key);
    if (existingSubQuestion) return existingSubQuestion;

    const subQuestion = new GroupedSubQuestion(key);

    this.groupKeyMapping[key].forEach((subQuestionKey) => {
      const ctx = ContextVariableStore.getInstance().addIfNotExists(
        this.prefix + this.separator + subQuestionKey
      )!;

      subQuestion.contextVariables.push({ key: subQuestionKey, contextVariable: ctx });
    });

    this.subQuestions.push(subQuestion);
    return subQuestion;
  }
  removeSubQuestion(key: SubQuestionGroup): void {
    this.subQuestions = this.subQuestions.filter((item) => item.groupKey !== key);
  }

  serialize(): ISerializedGroupedMultiQuestionNode {
    return {
      ...super.serialize(),
      subQuestions: this.subQuestions.map((question) => question.serialize()),
    };
  }
}

interface ISerializedGroupedMultiQuestionNode extends ISerializedMultiQuestionDialogNode {
  subQuestions: ISerializedGroupedSubQuestion[];
}
