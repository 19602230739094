import { ContextVariable } from '../../../models/ContextVariables/ContextVariable';

export interface IContextReassign {
  ctx: ContextVariable;
  value: any; // Any for now, probably should be specified later
}

export interface IContextFunction {
  ctx: ContextVariable;
  equation: string;
}

export interface IContextActions {
  delete: ContextVariable[];
  reassign: IContextReassign[];
  function: IContextFunction[];
}

export enum ContextActions {
  Delete = 'Delete',
  Reassign = 'Reassign',
  Function = 'Function',
}
