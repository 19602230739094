import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { BaseEventReceiver, ISerializedEventReceiver } from '../../BaseEventReceiver';
import { Utilities } from '../../../../../Utilities/Utilities';

export class UserReceiver extends BaseEventReceiver {
  type = EventTargetReceiverTypes.User;

  @Expose()
  username: string;

  constructor() {
    super();
    this.username = '';

    makeObservable(this, {
      username: observable,
      isValid: computed,
    });
  }

  get isValid() {
    return this.username.length > 5;
  }

  get isChanged() {
    return !Utilities.isEmpty(this.username);
  }

  serialize(): ISerializedUsernameEventReceiver {
    return {
      ...super.serialize(),
      username: this.username,
    };
  }
}

export interface ISerializedUsernameEventReceiver extends ISerializedEventReceiver {
  username: string;
}
