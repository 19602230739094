import { makeObservable, observable } from 'mobx';
import { NodeHistoryElement } from './NodeHistoryElement';

export class ApiNodeElement extends NodeHistoryElement {
  body?: string;
  httpMethod: string;
  statusCode: number;
  url: string;
  responseBody?: string;

  constructor() {
    super();
    this.body = undefined;
    this.httpMethod = '';
    this.statusCode = 0;
    this.url = '';
    this.responseBody = undefined;

    makeObservable(this, {
      body: observable,
      httpMethod: observable,
      statusCode: observable,
      url: observable,
      responseBody: observable,
    });
  }
}
