import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  Cell,
  Column,
  HeaderProps,
  Row,
  useFlexLayout,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table';
import { DEFAULT_DATA_HEADERS, PAGE_SIZE } from '../../../architecture/constants/Dataset';
import { DatasetState } from '../../../architecture/enums/DatasetState';
import { TableData } from '../../../architecture/enums/TableData';
import { StorageEntry } from '../../../models/Dataset/StorageEntry';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import Checkbox from '../../common/Checkbox';
import Pagination from '../../common/pagination/Pagination';
import Table from '../../common/table/Table';
import LoadingPage from '../../layout/LoadingPage';
import DataListHeader from './components/DataListHeader';
import DatasetCell from './components/DatasetCell';

const DataList: React.FC = () => {
  const { datasetStore } = useContext(rootStore);
  const [from, setFrom] = useState<Date | undefined>(undefined);
  const [to, setTo] = useState<Date | undefined>(undefined);
  const [page, setPage] = useState(datasetStore.pageDetails.page);

  useEffect(() => {
    setPage(datasetStore.pageDetails.page);
  }, [datasetStore.pageDetails.page]);

  const getColumns = () => {
    const dataColumns: any = [
      {
        id: DEFAULT_DATA_HEADERS.select.id,
        Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<{}>) => {
          return (
            <Checkbox
              className='ml-3'
              value={getToggleAllRowsSelectedProps()?.checked}
              clickHandler={() =>
                toggleAllRowsSelected(
                  !getToggleAllRowsSelectedProps()?.checked ? true : false
                )
              }
              {...getToggleAllRowsSelectedProps()}
            />
          );
        },
        Cell: ({ row }: Cell) => {
          const { checked } = row.getToggleRowSelectedProps();
          return (
            <Checkbox
              value={checked}
              clickHandler={row.toggleRowSelected}
              {...row.getToggleRowSelectedProps()}
            />
          );
        },
        width: 20,
      },
      {
        id: DEFAULT_DATA_HEADERS.createdDate.id,
        disableSortBy: false,
        Header: DEFAULT_DATA_HEADERS.createdDate.label,
        accessor: (item: StorageEntry) =>
          Utilities.getLocalDate(item.created, 'datetime'),
      },
    ];

    datasetStore.getSelectedDataset?.properties.forEach((property) => {
      dataColumns.push({
        id: property.key.toLowerCase(),
        disableSortBy: true,
        Header: property.annotation,
        accessor: (item: any) => item[property.key.toLowerCase()],
        Cell: (item: Cell<StorageEntry>) => (
          <DatasetCell propertyKey={property.key} item={item.row.original} />
        ),
      });
    });

    return dataColumns;
  };

  const columns: Column<TableData>[] = useMemo(
    () => getColumns(),
    [datasetStore.getSelectedDataset]
  );

  const data: StorageEntry[] = useMemo(() => {
    return datasetStore.datasetData;
  }, [datasetStore.datasetData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      disableMultiSort: true,
      initialState: { sortBy: [] },
    },
    useFlexLayout,
    useSortBy,
    useRowSelect
  );

  useEffect(() => {
    datasetStore.loadDatasetData(
      page,
      PAGE_SIZE,
      sortBy ? sortBy[0]?.desc : undefined,
      from,
      to
    );
  }, [from, to, sortBy, page]);

  const pageChange = (page: number) => {
    setPage(page + 1);
  };

  const changeFrom = (date?: Date) => {
    setFrom(date);
    setPage(1);
  };

  const changeTo = (date?: Date) => {
    setTo(date);
    setPage(1);
  };

  return (
    <div className='container-wrapper full-width'>
      {datasetStore.getState(DatasetState.DataLoading) ? (
        <LoadingPage />
      ) : (
        <>
          <DataListHeader
            selectedRows={selectedFlatRows.map((row: Row) => (row.original as any).id)}
            from={from}
            setFrom={changeFrom}
            to={to}
            setTo={changeTo}
          />
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            selectedRow={datasetStore.getSelectedDataset as TableData}
            prepareRow={prepareRow}
            onRowClick={(undefined, row?: Row) => row?.toggleRowSelected()}
          />
          {datasetStore.pageDetails.totalPages > 1 && (
            <Pagination
              pageCount={datasetStore.pageDetails.totalPages}
              pageChange={pageChange}
              page={page - 1}
            />
          )}
        </>
      )}
    </div>
  );
};

export default observer(DataList);
