import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { AvatarTexturesAdminState } from '../../../architecture/enums/Admin';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { TableData } from '../../../architecture/enums/TableData';
import { AvatarTexture } from '../../../models/Admin/AvatarTexture';
import { Utilities } from '../../../models/Utilities/Utilities';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import LoadingPage from '../../layout/LoadingPage';
import AddAvatarTextureModal from './AddAvatarTextureModal';
import { HEADERS } from './constants';

const AvatarTexturesList: React.FC = () => {
  const { subscriptionAdminStore, avatarTexturesAdminStore } = useAdminStores();
  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const [addTextureOpen, setAddTextureOpen] = useState(false);

  useEffect(() => {
    avatarTexturesAdminStore.load();
  }, [JSON.stringify(subscriptionAdminStore.getSelectedSubscription)]);

  const columns: Array<Column<TableData>> = React.useMemo(
    () => [
      {
        id: HEADERS.texture.id,
        Header: HEADERS.texture.label,
        accessor: (texture: TableData) => (texture as AvatarTexture).name,
      },
      {
        id: HEADERS.created.id,
        Header: HEADERS.created.label,
        accessor: (texture: TableData) => (texture as AvatarTexture).created,
      },
      {
        id: HEADERS.actions.id,
        Header: HEADERS.actions.label,
        width: subscriptionAdminStore.getSelectedSubscription ? 25 : 10,
        Cell: (item: Cell<AvatarTexture>) => {
          const texture = new AvatarTexture();
          texture.name = item.row.original.name;

          return (
            <div className='actions'>
              <i
                className={`${getIcon('remove')} actions-icon`}
                onClick={() => {
                  showModal(ModalTypes.DeleteElement, texture);
                }}
              ></i>
            </div>
          );
        },
      },
    ],
    [avatarTexturesAdminStore.getAvatarTextures]
  );

  const data: TableData[] = React.useMemo(() => {
    const items = avatarTexturesAdminStore.getAvatarTextures;
    if (items.length === 0) return [];

    return items.map((item) => {
      return {
        name: item.name,
        created: item.created
          ? Utilities.getLocalDate(item.created!, 'datetime')
          : undefined,
      };
    });
  }, [JSON.stringify(avatarTexturesAdminStore.getAvatarTextures)]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<TableData>(
      {
        columns,
        data,
      },
      useFlexLayout
    );

  const handleRowClick = (texture: TableData) => {};

  return (
    <div className='container-wrapper'>
      {avatarTexturesAdminStore.getState(AvatarTexturesAdminState.Loading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4 className='title-name'>
              {subscriptionAdminStore.getSelectedSubscription?.name}
            </h4>
            <div className='button-wrapper'>
              <Button
                content='Add Texture'
                className='btn-primary btn-round'
                clickHandler={() => setAddTextureOpen(true)}
              />
            </div>
          </div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            prepareRow={prepareRow}
            onRowClick={handleRowClick}
          />
          <Modal
            manuallyTriggered={addTextureOpen}
            closeHandler={() => setAddTextureOpen(false)}
          >
            <AddAvatarTextureModal
              submit={(textureName, files) => {
                avatarTexturesAdminStore.add(textureName, files);
                setAddTextureOpen(false);
              }}
              resetModal={() => setAddTextureOpen(false)}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(AvatarTexturesList);
