import { useContext, useEffect, useState } from 'react';
import { ModalElementTypes, ModalTypes } from '../../architecture/enums/ModalTypes';
import rootStore from '../../stores/rootStore';

const useModal = () => {
  const { uiStore } = useContext(rootStore);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalOpen(uiStore.showModal);
  }, [uiStore.showModal]);

  const showModal = (type: ModalTypes, element?: ModalElementTypes | null) => {
    if (element) uiStore.modalState.element = element;
    uiStore.modalState.activeModalType = type;
  };

  const setCloseInterceptor = (closeInterceptor?: () => void) => {
    uiStore.modalState.closeInterceptor = closeInterceptor;
  };

  const setPreventClosing = (shouldPrevent: boolean, tooltip?: string) => {
    uiStore.modalState.preventClosing = shouldPrevent;
    uiStore.modalState.tooltip = tooltip;
  };

  const closeModal = () => {
    if (uiStore.modalState.preventClosing) return;

    if (uiStore.modalState.closeInterceptor) {
      uiStore.modalState.closeInterceptor();
    }

    uiStore.modalState.element = undefined;
    uiStore.modalState.activeModalType = undefined;
    uiStore.modalState.closeInterceptor = undefined;
    uiStore.modalState.tooltip = undefined;
  };

  return { modalOpen, showModal, setPreventClosing, setCloseInterceptor, closeModal };
};

export default useModal;
