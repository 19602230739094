import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { IContextFunction } from '../../../../../../architecture/interfaces/contextVariables/IContextActions';
import TextBoxWithCtx from '../../../../../common/inputElements/TextBoxWithCtx';

interface IProps {
  item: IContextFunction;
}

const Reassignment: React.FC<IProps> = ({ item }) => {
  return (
    <Fragment>
      <h4 className='context-action-editor-title'>
        Execute a function on {item.ctx.name}
      </h4>
      <p>
        <span>
          If you have a context variable which contains a number you can execute a few
          simple mathematical operations on its value.{' '}
        </span>
        <a
          className='ncalc-link'
          href='https://github.com/ncalc/ncalc'
          rel='noopener noreferrer'
          target='_blank'
        >
          Learn More
        </a>
      </p>
      <TextBoxWithCtx
        multiline
        value={item.equation}
        saveHandler={(text) => (item.equation = text)}
      />
    </Fragment>
  );
};

export default observer(Reassignment);
