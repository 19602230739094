import { observable, makeObservable } from 'mobx';
import { TriggerHistoryElement } from './TriggerHistoryElement';

export class EventTriggerElement extends TriggerHistoryElement {
  eventId?: string

  constructor() {
    super();
    this.eventId = '';

    makeObservable(this, {
      eventId: observable,
    });
  }
}
