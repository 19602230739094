import { action, makeObservable } from 'mobx';
import { DefaultBehaviour } from '../../architecture/enums/DefaultBehaviour';
import {
  DialogBlockTypes,
  DialogNodeTypes,
} from '../../architecture/enums/DialogComponentType';
import NodeStore from '../../stores/NodeStore';
import { GenerativeDefaultAnswerNode } from '../DialogNodes/MessageNodes/GenerativeDefaultAnswerNode';
import { MessageDialogNode } from '../DialogNodes/MessageNodes/MessageDialogNode';
import { RootNode } from '../DialogNodes/RootNode';
import { DefaultTrigger } from '../Triggers/DefaultTrigger';
import { Position } from '../Utilities/Position';
import { Text } from '../Utilities/Text';
import { DialogBlock } from './DialogBlock';

export class DefaultDialogBlock extends DialogBlock {
  type = DialogBlockTypes.DefaultDialogBlock;

  constructor() {
    super();

    this.trigger = new DefaultTrigger();
    this.position = new Position(10, 10);
    this.title = 'Default Block';
    this.userModificationAllowed = false;

    makeObservable(this, {
      createDefaultNode: action,
      createGenerativeAnswerNode: action,
    });
  }

  switchDefaultBehaviour(defaultBehaviour: DefaultBehaviour) {
    NodeStore.getInstance().removeAllNodesOfBlock(this);

    if (defaultBehaviour === 'DefaultBlock') {
      this.createDefaultNode();
    } else if (defaultBehaviour === 'GenerativeAnswer') {
      this.createGenerativeAnswerNode();
    }
  }

  createDefaultNode() {
    // Create the default node inside here should be reconsidered.

    if (!this.nodes.some((node) => node.type === DialogNodeTypes.RootNode)) {
      const rootNode = new RootNode(this);
      NodeStore.getInstance().append(rootNode);
    }

    const node = new MessageDialogNode(this);
    node.messages = [new Text('Sorry, ich weiß das leider nicht.')];

    NodeStore.getInstance().append(node);
  }

  createGenerativeAnswerNode() {
    if (!this.nodes.some((node) => node.type === DialogNodeTypes.RootNode)) {
      const rootNode = new RootNode(this);
      NodeStore.getInstance().append(rootNode);
    }

    const node = new GenerativeDefaultAnswerNode(this);

    NodeStore.getInstance().append(node);
  }
}
