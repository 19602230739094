import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import SettingsPage from '../../botSettings/SettingsPage';

interface IParamsProps {
  dialogId?: string;
}

const BotSettingsOverview = () => {
  const { dialogStore, mediaStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();

  const load = async () => {
    await Promise.all([dialogStore.loadBots(), mediaStore.load()]);
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    if (!dialogStore.currentlyEditedDialog && params.dialogId) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params]);

  return <SettingsPage />;
};

export default observer(BotSettingsOverview);
