import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';
import AvatarTexturesPage from '../../admin/avatarTextures/AvatarTexturesPage';
import BillingPage from '../../admin/billing/BillingPage';
import BotsPage from '../../admin/bots/BotsPage';
import SubscriptionPage from '../../admin/subscriptions/SubscriptionPage';
import UsersPage from '../../admin/users/UsersPage';

const Admin = () => {
  return (
    <div id='admin' className='content-with-subnavigation'>
      <Switch>
        <Route exact path='/admin/subscriptions' component={SubscriptionPage} />
        <Route exact path='/admin/bots' component={BotsPage} />
        <Route exact path='/admin/users' component={UsersPage} />
        <Route exact path='/admin/textures' component={AvatarTexturesPage} />
        <Route exact path='/admin/billing' component={BillingPage} />
      </Switch>
    </div>
  );
};

export default observer(Admin);
