export interface IDateOnly {
  year?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  day?: 'numeric' | '2-digit';
}

export interface IDateComplete extends IDateOnly {
  hour?: 'numeric' | '2-digit';
  minute?: 'numeric' | '2-digit';
  second?: 'numeric' | '2-digit';
}

export interface DateTimeDisplay {
  dateComplete: IDateComplete;
  dateOnly: IDateOnly;
  dateTime: IDateComplete;
  dateShort: IDateOnly;
}

export const DATE_TIME_DISPLAY_OPTIONS: DateTimeDisplay = {
  dateComplete: {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  },
  dateOnly: { year: 'numeric', month: 'long', day: '2-digit' },
  dateShort: { year: 'numeric', month: '2-digit', day: '2-digit' },
  dateTime: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
};
