import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import {
  DESCENDING_SORT,
  FILTERS,
  PAGE_SIZE,
} from '../../architecture/constants/ConversationHistory';
import { CONVERSATION_HISTORY_SCROLL } from '../../architecture/constants/LocalStorage';
import { Sort } from '../../architecture/enums/ConversationHistoryType';
import rootStore from '../../stores/rootStore';
import EmptyPage from '../common/EmptyPage';
import Pagination from '../common/pagination/Pagination';
import useWindowScrollPosition from '../customHooks/useWindowScrollPosition';
import LoadingPage from '../layout/LoadingPage';
import ConversationThreadRow from './ConversationThreadRow';
import Filters from './filters/Filters';

const ConversationHistoryList: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const params = queryString.parse(location.search);
  const { conversationHistoryStore, dialogStore } = useContext(rootStore);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    dialogId,
    page,
    sort,
    from,
    to,
    reviewed,
    channel,
    eventId,
    fromScore,
    toScore,
    triggerType,
    triggerBlockId,
    triggerBlockName,
    userMessage,
    sessionId,
  } = params;

  const load = async () => {
    const options = {
      from: params.from,
      to: params.to,
      reviewed: params.reviewed
        ? params.reviewed === 'true'
          ? !!params.reviewed
          : !params.reviewed
        : null,
      channel: params.channel,
      eventId: params.eventId,
      fromScore: params.fromScore,
      toScore: params.toScore,
      triggerType: params.triggerType,
      triggerBlockId: params.triggerBlockId,
      triggerBlockName: params.triggerBlockName,
      userMessage: params.userMessage,
      sessionId: params.sessionId,
    };

    await conversationHistoryStore.loadConversationHistoryOverview({
      page: Number(params.page),
      pageSize: PAGE_SIZE,
      sort: params.sort ? (Number(params.sort) as Sort) : (DESCENDING_SORT as Sort),
      searchOptions: Object.fromEntries(
        Object.entries(options).filter(([_, v]) => v !== null && v !== undefined)
      ),
    });
    setIsLoading(false);
    window.scrollTo({ top: 0, left: 0 });
  };

  useWindowScrollPosition(CONVERSATION_HISTORY_SCROLL, !isLoading);

  useEffect(() => {
    let dataObj = { page: 1 };
    const dialogId = params.dialogId || dialogStore?.currentlyEditedDialog?.dialogId;

    if (dialogId) {
      return history.push({
        pathname: `/dialogs/${dialogId}/history`,
        search: queryString.stringify(dataObj),
      });
    }
  }, [dialogId]);

  useEffect(() => {
    if (page) {
      load();
    }
  }, [
    dialogStore.currentlyEditedDialog,
    from,
    to,
    reviewed,
    channel,
    eventId,
    fromScore,
    toScore,
    triggerType,
    triggerBlockId,
    triggerBlockName,
    userMessage,
    sessionId,
    page,
    sort,
  ]);

  const handleParamsChange = (key: string, value: string) => {
    let newData = params;
    let data;
    if (key === FILTERS.PAGE.NAME || key === FILTERS.SORT.NAME) {
      data = { [key]: value };
    } else {
      data = { [key]: value, page: '1' };
    }
    newData = { ...newData, ...data };

    // remove blank attributes from data
    const dataObj = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v));

    return history.push({
      pathname: `/dialogs/${dialogStore?.currentlyEditedDialog?.dialogId}/history`,
      search: queryString.stringify(dataObj),
    });
  };

  const clearParamsChange = (key: string) => {
    delete params[key];

    return history.push({
      pathname: `/dialogs/${dialogStore?.currentlyEditedDialog?.dialogId}/history`,
      search: queryString.stringify(params),
    });
  };

  const renderConversationsThreads = () => {
    return conversationHistoryStore.allConversationThreads.map((thread, key) => {
      return (
        <ConversationThreadRow
          key={key}
          handleParamsChange={handleParamsChange}
          thread={thread}
          load={load}
        />
      );
    });
  };

  const pageChange = (page: number) => {
    handleParamsChange(FILTERS.PAGE.NAME, (page + 1).toString());
  };

  let paramPage = 0;
  if (params.page && typeof params.page == 'string') {
    paramPage = parseInt(params.page) - 1;
  }

  return (
    <div className='conversations-list-container'>
      <Filters
        handleParamsChange={handleParamsChange}
        clearParamsChange={clearParamsChange}
      />
      {conversationHistoryStore.isLoading ? (
        <LoadingPage />
      ) : (
        <div className='conversation-threads'>
          {renderConversationsThreads()}
          {conversationHistoryStore.pageDetails.totalItems > 0 &&
            conversationHistoryStore.pageDetails.totalItems > PAGE_SIZE && (
              <Pagination
                page={paramPage}
                pageCount={conversationHistoryStore.pageDetails.totalPages}
                pageChange={pageChange}
              />
            )}
          {conversationHistoryStore.pageDetails.totalItems === 0 && <EmptyPage />}
        </div>
      )}
    </div>
  );
};

export default observer(ConversationHistoryList);
