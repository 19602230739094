import { observer } from 'mobx-react-lite';
import React, { CSSProperties, Fragment, useContext } from 'react';
import rootStore from '../../stores/rootStore';
import Loading from './Loading';

interface IButtonProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  name?: string;
  content?: string | React.ReactElement;
  className?: string;
  icon?: string;
  tooltip?: string;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
  disabled?: boolean;
  style?: CSSProperties;
  reverseContent?: boolean;
  clickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  mouseEnterHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  mouseLeaveHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isLoading?: boolean;
}
const Button: React.FC<IButtonProps> = ({
  name,
  type,
  content,
  icon,
  className,
  tooltip,
  tooltipPosition,
  disabled,
  style,
  reverseContent = false,
  clickHandler,
  mouseEnterHandler,
  mouseLeaveHandler,
  isLoading,
  ...rest
}: IButtonProps) => {
  const { uiStore } = useContext(rootStore);

  const isInLoadingState =
    (uiStore.loading.isLoading && uiStore.loading.targetName === name) || isLoading;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || isInLoadingState) return;

    uiStore.loading.targetName = name || '';
    clickHandler?.(event);
  };

  const reverse = () => (
    <Fragment>
      {content !== undefined && (
        <span style={{ marginRight: icon ? '.4rem' : '0' }}>{content}</span>
      )}
      {isInLoadingState ? (
        <Loading fullScreen={false} />
      ) : (
        <Fragment>{icon && <i className={icon}></i>}</Fragment>
      )}
    </Fragment>
  );

  const render = () => (
    <Fragment>
      {isInLoadingState ? (
        <Loading fullScreen={false} />
      ) : (
        <Fragment>{icon && <i className={icon}></i>}</Fragment>
      )}
      {content !== undefined && (
        <span style={{ marginLeft: icon || isInLoadingState ? '.4rem' : '0' }}>
          {content}
        </span>
      )}
    </Fragment>
  );

  return (
    <button
      className={`btn ${className ? className : ''}`}
      type={type}
      data-disabled={disabled}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      style={name ? { ...style, alignItems: 'center' } : style}
      {...rest}
    >
      {reverseContent ? reverse() : render()}
      {tooltip && (
        <span
          className={`btn-tooltip tooltip-${tooltipPosition ? tooltipPosition : 'left'}`}
        >
          {tooltip}
        </span>
      )}
    </button>
  );
};

export default observer(Button);
