import React, { CSSProperties } from 'react';

interface IRedirectButtonProps {
  target: string;
  content?: string;
  className?: string;
  icon?: string;
  tooltip?: string;
  tooltipPosition?: string;
  disabled?: boolean;
  style?: CSSProperties;
}
const RedirectButton: React.FC<IRedirectButtonProps> = ({
  target,
  content,
  icon,
  className,
  tooltip,
  tooltipPosition,
  disabled,
  style,
}: IRedirectButtonProps) => {

  const onClick = (e: any) => {
    if(disabled)
      e.preventDefault()
  }

  return (
    <a
      className={`btn ${className}`}
      data-disabled={disabled}
      href={target}
      target='_blank'
      rel='noopener noreferrer'
      style={style}
      onClick={e => onClick(e)}
    >
      {icon && <i className={icon}></i>}
      {content && <span style={{ marginLeft: '.4rem' }}>{content}</span>}
      {tooltip && (
        <span
          className={`btn-tooltip tooltip-${tooltipPosition ? tooltipPosition : 'left'}`}
        >
          {tooltip}
        </span>
      )}
    </a>
  );
};

export default RedirectButton;
