import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { EventTriggerElement as EventTriggerElementModel } from '../../../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/EventTriggerElement';
import BaseHistoryElement from '../BaseHistoryElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: EventTriggerElementModel;
}

const EventTriggerElement: React.FC<IProps> = ({ element }: IProps) => {
  const { blockStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const block = blockStore?.allBlocks?.find(block => block?.trigger?.id === element.triggerId);

  return (
    <BaseHistoryElement element={element} title='Event Trigger evaluated' icon={getIcon('event_trigger')}>
      {element.triggered ? (
        <div className='card-paragraph'>
          <i className={getIcon('check')}> </i>
          <span> Triggered block "{block?.title}" </span>
        </div>
      ) : (
        <div className='card-paragraph'>
          <i className={getIcon('error')}> </i>
          <span> Not triggered block "{block?.title}" </span>
        </div>
      )}
    </BaseHistoryElement>
  );
};

export default observer(EventTriggerElement);
