import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, CreditManagmentState } from '../../architecture/enums/Admin';
import { IPayment } from '../../architecture/interfaces/HTTP/AdminParams';
import { Payment } from '../../models/Admin/Payment';
import { ContingentConnector } from '../../models/Connectors/admin/ContingentConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class CreditManagementAdminStore {
  private static instance: CreditManagementAdminStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
    CreditManagementAdminStore.instance = this;
  }

  @observable
  private _paymentRepository: Payment[] = [];

  @observable
  private _consumedCredits: number = 0;

  @observable
  state: CreditManagmentState = CreditManagmentState.None;

  @computed
  getState(state: CreditManagmentState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: CreditManagmentState) {
    this.state |= state;
  }

  @computed
  removeState(state: CreditManagmentState) {
    this.state &= ~state;
  }

  @computed
  get getPayments(): Payment[] {
    return this._paymentRepository;
  }

  @computed
  get getConsumedCredits(): number {
    return this._consumedCredits;
  }

  @computed
  get getTotalCredits(): number {
    const credits = this._paymentRepository.map((payment) => payment.credits);
    if (credits.length === 0) {
      return 0;
    }

    return credits.reduce((prev, current) => prev + current);
  }

  @action
  async loadOverview() {
    try {
      // if (this.getState(CreditManagmentState.Loading)) {
      //   return;
      // }

      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      const botId = this.rootStore.adminStore.botAdminStore.getSelectedBot?.id;
      if (!botId) return;

      this.setState(CreditManagmentState.Loading);

      const response = await ContingentConnector.getOverview(subscriptionId, botId);

      this.purge();
      runInAction(() => this.restorePayments(response.payments));
      this._consumedCredits = response.consumedCredits;

      this.removeState(CreditManagmentState.Loading);
    } catch (error) {
      this.removeState(CreditManagmentState.Loading);
    }
  }

  @action
  async outdatePayment(payment: Payment) {
    try {
      this.setState(CreditManagmentState.Loading);

      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      const botId = this.rootStore.adminStore.botAdminStore.getSelectedBot?.id;
      if (!botId) return;

      await ContingentConnector.deletePayment(subscriptionId, botId, payment.id);

      await this.loadOverview();

      this.removeState(CreditManagmentState.Loading);
    } catch (error) {
      this.removeState(CreditManagmentState.Loading);
    }
  }

  @action
  private restorePayments(payments: IPayment[]) {
    payments.forEach((payment) => {
      const instance = plainToInstance(
        ADMINS_CLASS_MAPPING[Admin.CreditManagement],
        payment
      );

      this._paymentRepository.push(instance);
    });
  }

  @action
  async addPayment(credits: number, remarks?: string) {
    try {
      this.setState(CreditManagmentState.Loading);

      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      const botId = this.rootStore.adminStore.botAdminStore.getSelectedBot?.id;
      if (!botId) return;

      await ContingentConnector.addPayment(subscriptionId, botId, credits, remarks);

      this.loadOverview();

      this.removeState(CreditManagmentState.Loading);
    } catch (error) {
      this.removeState(CreditManagmentState.Loading);
    }
  }

  purge() {
    this._paymentRepository = [];
    this._consumedCredits = 0;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('CreditManagementAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default CreditManagementAdminStore;
