import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useReadOnly } from '../../../components/customHooks/useReadOnly';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import Textarea from '../../common/inputElements/Textarea';

interface IProps {
  entry: KnowledgebaseEntry;
}

const Answer: React.FC<IProps> = ({ entry }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { knowledgebaseReadOnlyMode } = useReadOnly();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [entry.answer.value]);

  return (
    <div className='cell-wrapper'>
      <Textarea
        ref={textareaRef}
        value={entry.answer.value}
        disabled={entry.state === 'Deleted' || knowledgebaseReadOnlyMode}
        name='answer-value'
        changeHandler={(value) => entry.setAnswer(value)}
        placeholder='Type in your answer...'
        className={
          entry.state === 'Deleted'
            ? 'answer-cell deleted'
            : entry.state === 'Modified' || entry.state === 'Added'
            ? 'answer-cell highlighted'
            : 'answer-cell unchanged'
        }
      />
    </div>
  );
};

export default observer(Answer);
