import { IGetBillingResponse } from '../../../architecture/interfaces/HTTP/AdminParams';
import { HttpAgent } from '../../../utility/HttpAgent';

export class BillingConnector {
  static async get(
    subscriptionId: string,
    botId: string,
    from?: Date,
    to?: Date
  ): Promise<IGetBillingResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Admin/Billing/${subscriptionId}/Bots/${botId}?from=${
        from?.toISOString() ?? ''
      }&to=${to?.toISOString() ?? ''}`
    );
  }
}
