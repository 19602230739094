import { Exclude, Expose } from 'class-transformer';
import { ContextVariableInstanceTypes } from '../../architecture/enums/ContextVariableInstanceTypes';
import { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import type { ContextVariableTypes } from '../../architecture/types/ContextVariableTypes';

export class SystemContextVariable implements IContextVariable {
  @Expose()
  name: string;

  @Expose()
  id: string;

  @Expose()
  type: ContextVariableTypes;

  @Exclude()
  instanceType = ContextVariableInstanceTypes.SystemContextVariable;

  constructor(name: string, id: string) {
    this.name = name;
    this.id = id;
    this.type = 'string';
  }
}
