import { observer } from 'mobx-react-lite';
import React from 'react';
import { GroupedMultiQuestionNode as GroupedMultiQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/GroupedMultiQuestionNode';
import BaseMultiQuestionNode from './BaseMultiQuestionNode';
import GroupedSubQuestionsSection from './components/sections/GroupedSubQuestionsSection';

interface IProps {
  dialogNode: GroupedMultiQuestionNodeModel;
}
const GroupedMultiQuestionNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <BaseMultiQuestionNode dialogNode={dialogNode}>
      {children}
      <GroupedSubQuestionsSection dialogNode={dialogNode} />
    </BaseMultiQuestionNode>
  );
};

export default observer(GroupedMultiQuestionNode);
