import { observer } from 'mobx-react-lite';
import React from 'react';
import { ContextVariableUpdateElement as ContextVariableUpdateElementModel } from '../../../../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ContextVariableUpdateElement';
import { IContextVariable } from '../../../../../architecture/interfaces/contextVariables/IContextVariable';
import Values from './Values';

interface IProps {
  element?: ContextVariableUpdateElementModel;
  ctxVar?: IContextVariable;
}

const AdditionalInfo: React.FC<IProps> = ({ element, ctxVar }: IProps) => {
  return (
    <>
      {element?.metadata && (
        <div className='card-paragraph'>
          <div>Value was calculated by a Context Node function:</div>
          <span><span className='context-variable-name'>#{ctxVar?.name}</span>= {element?.metadata?.Function}</span>
        </div>
      )}
      {!element?.initialValue && (
        <div className='card-paragraph'>
          <span>Context Variable<span className='context-variable-name'>#{ctxVar?.name}</span>was not set before this update.</span>
        </div>
      )}
      {element?.initialValue && (
        <div className='card-paragraph'>
          <div>Before update:</div>
          <Values value={element?.initialValue} ctxVar={ctxVar} />
        </div>
      )}
    </>
  );
};

export default observer(AdditionalInfo);
