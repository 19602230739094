import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import Input from '../../../common/Input';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  action: ActionAttachment;
  data: { url: string; caption: string } | null;
  handleRemoveAction: () => void;
  saveData: (url: string, caption: string) => void;
}

const LinkItem: React.FC<IProps> = ({ action, data, handleRemoveAction, saveData }) => {
  const getIcon = useGetIcon();
  const [url, setUrl] = useState(data?.url || '');
  const [caption, setCaption] = useState(data?.caption || '');

  useEffect(() => {
    setUrl(data?.url || '');
    setCaption(data?.caption || '');
  }, [data]);

  useEffect(() => {
    saveData(url, caption);
  }, [url, caption]);

  return (
    <div className='url-wrapper'>
      <Input
        value={data?.url || ''}
        changeHandler={setUrl}
        isValid={action.isUrlValid}
        placeholder='Insert a URL'
      />
      <Input
        value={caption}
        changeHandler={setCaption}
        isValid={action.isCaptionValid}
        placeholder='Insert a caption'
      />
      <i
        className={`${getIcon('close')} close-icon`}
        onClick={() => handleRemoveAction()}
      ></i>
    </div>
  );
};

export default observer(LinkItem);
