import { Expose, Type } from 'class-transformer';
import { v4 } from 'uuid';

export class AvatarTexture {
  public id: string;

  @Expose()
  @Type(() => Date)
  public created: Date;

  @Expose()
  public name: string;

  @Expose()
  public location: string;

  constructor() {
    this.id = v4();
    this.created = new Date();
    this.name = '';
    this.location = '';
  }
}
