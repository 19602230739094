import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

interface IProps {
  pane: ReactNode;
}

const SinglePane = ({ pane }: IProps) => {
  return <div className='single-pane'>{pane}</div>;
};

export default observer(SinglePane);
