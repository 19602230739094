import { observer } from 'mobx-react-lite';
import React from 'react';
import { Media as MediaModel } from '../../../models/Media/Media';

interface IProps {
  item: MediaModel;
}

const VideoPreview: React.FC<IProps> = ({ item }: IProps) => {
  return (
    <div className='preview-content'>
      <video src={item.url} width='100%' controls></video>
    </div>
  );
};

export default observer(VideoPreview);
