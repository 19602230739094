import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Dataset } from '../../../../models/Dataset/Dataset';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import { useGetIcon } from '../../../customHooks/useGetIcon';

const KEY = 'search';

interface IProps {
  setDatasets?: (datasets: Dataset[]) => void;
  setAddDataset?: (value: boolean) => void;
  editMode?: boolean;
}

const DatasetListHeader: React.FC<IProps> = ({
  setDatasets,
  setAddDataset,
  editMode,
}) => {
  const { datasetStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const history = useHistory();
  const location = useLocation();
  const params = queryString.parse(location.search);
  const [search, setSearch] = useState(params.search ? params.search.toString() : '');

  const keyDownHandler = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && search) {
      handleParamsChange(search);
    } else if (event.key === 'Enter' && !search) {
      clearParamsChange();
    }
  };

  const blurHandler = () => {
    if (search) {
      handleParamsChange(search);
    } else {
      clearParamsChange();
    }
  };

  const handleParamsChange = (value: string) => {
    let newData = params;
    const data = { [KEY]: value };
    newData = { ...newData, ...data };

    // remove blank attributes from data
    const dataObj = Object.fromEntries(Object.entries(newData).filter(([_, v]) => v));

    return history.push({
      pathname: location.pathname,
      search: queryString.stringify(dataObj),
    });
  };

  const clearParamsChange = () => {
    delete params[KEY];

    return history.push({
      pathname: location.pathname,
      search: queryString.stringify(params),
    });
  };

  useEffect(() => {
    if (params.search) {
      setSearch(params.search.toString());
      let filteredDatasets = datasetStore.search(params.search as string) || [];
      if (setDatasets) setDatasets(filteredDatasets);
    } else {
      setSearch('');
      if (setDatasets) setDatasets(datasetStore.allDatasets);
    }
  }, [params.search, datasetStore.allDatasets]);

  return (
    <div className='header'>
      <div className='search-input'>
        <Input
          value={search}
          className='filter-input'
          placeholder='Search for a Dataset'
          changeHandler={setSearch}
          blurHandler={blurHandler}
          keyDownHandler={keyDownHandler}
        />
        <i className={getIcon('search')}></i>
      </div>

      {location.pathname.includes('manage') && (
        <div className='button-wrapper'>
          <Button
            disabled={editMode}
            content='Add Dataset'
            icon={getIcon('add')}
            className='btn-primary btn-round'
            clickHandler={() => {
              datasetStore.setSelectedDataset(null);
              if (setAddDataset) setAddDataset(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default observer(DatasetListHeader);
