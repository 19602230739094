import { action, computed, makeAutoObservable, observable } from 'mobx';
import { Notification } from '../models/Utilities/Notification';
import { RootStore } from './rootStore';

class AppStore {
  private static instance: AppStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    AppStore.instance = this;
  }

  @observable
  savingInProgress: boolean = false;

  @observable
  isInErrorState: boolean = false;

  @observable
  private _initialEditorStructure: string = '';

  @computed
  get hasEditorStructureChanged() {
    const currentEditorStructure = JSON.stringify(
      this.rootStore.serializationStore.serialize()
    );

    return this._initialEditorStructure !== currentEditorStructure;
  }

  @action
  updateInitialEditorStructure() {
    this._initialEditorStructure = JSON.stringify(
      this.rootStore.serializationStore.serialize()
    );
  }

  @action
  public populateStores(editorStructure: string) {
    const deserializedEditorStructure = JSON.parse(editorStructure);
    this.rootStore.serializationStore.setupStoresFromJson(deserializedEditorStructure);
    this.updateInitialEditorStructure();
  }

  @action
  async save(): Promise<boolean> {
    let infoNotification = new Notification({
      text: 'Saving data...',
      type: 'info',
      autoRemove: false,
    });

    try {
      this.savingInProgress = true;

      if (!this.rootStore.dialogStore.currentlyEditedDialog?.dialogId) return false;

      await this.rootStore.dialogStore.updateDialogStructure(
        this.rootStore.dialogStore.currentlyEditedDialog
      );

      infoNotification.remove();
      new Notification({
        text: 'Saved data',
        type: 'success',
      });

      this.savingInProgress = false;

      return true;
    } catch (error) {
      console.error(error);

      infoNotification.remove();
      new Notification({
        text: 'Saving data failed',
        type: 'error',
      });

      return false;
    }
  }

  @action
  async discardChanges() {
    this.populateStores(this._initialEditorStructure);
  }

  @action
  resetDialogSelection() {
    this.rootStore.serializationStore.clearStores();
    this.rootStore.dialogStore.reset();

    // The intent trigger is not used at the moment.
    // this.rootStore.intentStore.purge();
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('AppStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default AppStore;
