import { observer } from 'mobx-react-lite';
import React from 'react';
import { MultiQuestionNode as MultiQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/MultiQuestionNode';
import BaseMultiQuestionNode from './BaseMultiQuestionNode';
import MultiQuestionSubQuestionsSection from './components/sections/MultiQuestionSubQuestionsSection';

interface IProps {
  dialogNode: MultiQuestionNodeModel;
}
const MultiQuestionNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <BaseMultiQuestionNode dialogNode={dialogNode}>
      {children}
      <MultiQuestionSubQuestionsSection dialogNode={dialogNode} />
    </BaseMultiQuestionNode>
  );
};

export default observer(MultiQuestionNode);
