import { makeObservable, observable } from 'mobx';

export class Position {
  x: number = 0;
  y: number = 0;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;

    makeObservable(this, {
      x: observable,
      y: observable,
    });
  }
}
