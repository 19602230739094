import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Pronunciation } from '../../../../models/Subscription/Pronunciation';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  item: Pronunciation;
}

const RemoveIcon: React.FC<IProps> = ({ item }) => {
  const { pronunciationStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  if (item.isDeleted) {
    return (
      <Button
        className='btn-remove btn-borderless'
        icon={getIcon('undo')}
        tooltip='Undo deleting'
        tooltipPosition='top'
        clickHandler={() => {
          item.discard();
        }}
      />
    );
  }

  return (
    <Button
      className='btn-remove btn-borderless'
      icon={getIcon('remove')}
      tooltip='Remove this entry'
      tooltipPosition='top'
      clickHandler={() => {
        pronunciationStore.removeItem(item.id);
      }}
    />
  );
};

export default observer(RemoveIcon);
