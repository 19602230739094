import React from 'react';

interface IProps {
  icon: string;
  text: string;
  isActive: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}
const Tab: React.FC<IProps> = ({
  icon,
  text,
  isActive,
  disabled,
  hidden = false,
  onClick,
}) => {
  const clickHandler = () => {
    if (isActive || disabled) return;

    onClick();
  };
  return (
    <div
      className={`tab-button ${isActive ? 'active' : ''}`}
      data-disabled={disabled}
      data-hidden={hidden}
      onClick={clickHandler}
    >
      <i className={icon}></i> {text}
    </div>
  );
};

export default Tab;
