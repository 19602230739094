import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import rootStore from '../../stores/rootStore';
import Card from '../common/cardMenu/Card';
import CardContainer from '../common/cardMenu/CardContainer';
import { useGetIcon } from '../customHooks/useGetIcon';

const SettingsSelector: React.FC = () => {
  const getIcon = useGetIcon();

  const { dialogStore } = useContext(rootStore);

  const dialogId = dialogStore.currentlyEditedDialog?.dialogId;
  if (!dialogId) {
    return <></>;
  }

  const pathPrefix = `/dialogs/${dialogId}/bot-settings`;

  return (
    <div className='selector-page'>
      <CardContainer>
        <Card label='Bot' path={`${pathPrefix}/bot`} icon={getIcon('robot')} />
        <Card
          label='Import & Export'
          path={`${pathPrefix}/import-export`}
          icon={getIcon('exchange')}
        />
        <Card
          label='Confirm dataset view'
          path={`${pathPrefix}/confirmation`}
          icon={getIcon('check')}
        />
      </CardContainer>
    </div>
  );
};

export default observer(SettingsSelector);
