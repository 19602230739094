import { Expose, Type } from 'class-transformer';
import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import {
  DatasetStatus,
  PropertyState,
  PropertyType,
} from '../../architecture/enums/Dataset';
import { IProperty } from '../../architecture/interfaces/dataset/IProperty';
import { DatasetListProperty } from './Property/DatasetListProperty';
import { DatasetProperty } from './Property/DatasetProperty';
import { Property } from './Property/Property';
import { SimpleProperty } from './Property/SimpleProperty';

export const ResolvePropertyType: PropertyDecorator = Type(() => Property, {
  discriminator: {
    property: 'type',
    subTypes: [
      { value: SimpleProperty, name: PropertyType.Simple },
      { value: DatasetProperty, name: PropertyType.Dataset },
      { value: DatasetListProperty, name: PropertyType.ListDataset },
    ],
  },
});

export class Dataset {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  status: string;

  @Expose()
  @Type(() => Date)
  created?: Date;

  @Expose()
  entries: string;

  @Expose()
  @ResolvePropertyType
  properties: (DatasetListProperty | DatasetProperty | SimpleProperty)[];

  constructor(name?: string, properties?: Property[], id?: string) {
    this.name = name ? name : '';
    this.properties = properties ? properties : [];
    this.status = !id ? DatasetStatus.New : '';
    this.id = id ? id : v4();
    this.entries = '';

    makeObservable(this, {
      name: observable,
      properties: observable,
      id: observable,
      entries: observable,
      created: observable,
      status: observable,
      editProperty: action,
    });
  }

  editProperty(property: IProperty) {
    this.properties = this.properties.map((prop) => {
      if (prop.id === property.id) {
        if (property.dataset) {
          return property.type === PropertyType.Dataset
            ? new DatasetProperty(
                property.key,
                property.annotation,
                property.dataset,
                property.datasetId,
                property.id,
                property.state
              )
            : new DatasetListProperty(
                property.key,
                property.annotation,
                property.dataset,
                property.datasetId,
                property.id,
                property.state
              );
        } else {
          return new SimpleProperty(
            property.key,
            property.annotation,
            property.id,
            property.state
          );
        }
      } else return prop;
    });

    return this.properties.find((p) => p.id === property.id);
  }

  addProperty(newProperty: IProperty) {
    let property: Property;
    switch (newProperty.type) {
      case PropertyType.Simple:
        property = new SimpleProperty(
          newProperty.key,
          newProperty.annotation,
          undefined,
          newProperty.state
        );
        break;
      case PropertyType.Dataset:
        property = new DatasetProperty(
          newProperty.key,
          newProperty.annotation,
          newProperty.dataset || '',
          newProperty.datasetId || '',
          undefined,
          newProperty.state
        );
        break;
      case PropertyType.ListDataset:
        property = new DatasetListProperty(
          newProperty.key,
          newProperty.annotation,
          newProperty.dataset || '',
          newProperty.datasetId || '',
          undefined,
          newProperty.state
        );
        break;
      default:
        property = new SimpleProperty(
          newProperty.key,
          newProperty.annotation,
          newProperty.state
        );
    }

    this.properties.push(property);
  }

  removeProperty(property: DatasetListProperty | DatasetProperty | SimpleProperty) {
    if (property.state === PropertyState.UpToDate) {
      property.state = PropertyState.Deleted;
    } else if (property.state === PropertyState.Added) {
      this.properties = this.properties.filter((prop) => prop.id !== property.id);
    }
  }

  serialize() {
    return {
      id: this.id,
      name: this.name,
      properties: this.properties
        .filter((prop) => prop.state !== PropertyState.Deleted)
        .map((prop) => prop.serialize()),
    };
  }
}
