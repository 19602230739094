import { observer } from 'mobx-react-lite';
import React from 'react';
import Editor from './Editor';
import SideBar from './SideBar';

interface IProps {
  children: React.ReactNode;
}
const Base: React.FC<IProps> = ({ children }) => {
  return <div className='editor-with-side-bar'>{children}</div>;
};

export default observer(Base);

export const SideBarEditor = {
  SideBar,
  Editor,
  Base,
};
