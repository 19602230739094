import React from 'react';
import {
  ITooltipItem,
  ITooltipPosition,
} from '../../../architecture/interfaces/tooltip/ITooltip';
import { TooltipItemIconType } from './TooltipContainer';
import TooltipMessage from './TooltipMessage';

interface IProps {
  items: ITooltipItem[];
  type?: TooltipItemIconType;
  position: ITooltipPosition;
  handleItemClick: (item: any) => void;
}
const TooltipMessageList: React.FC<IProps> = ({
  items,
  position,
  type,
  handleItemClick,
}) => {
  return (
    <ul
      className='tooltip-message-list'
      style={{
        position: 'absolute',
        ...position,
      }}
    >
      {items.map((item) => (
        <TooltipMessage
          key={item.id}
          item={item}
          type={type}
          clickHandler={handleItemClick}
        />
      ))}
    </ul>
  );
};

export default TooltipMessageList;
