import { plainToInstance } from 'class-transformer';
import { action, makeAutoObservable } from 'mobx';
import { KnowledgebaseState } from '../architecture/enums/KnowledgebaseState';
import { IContextVariable } from '../architecture/interfaces/contextVariables/IContextVariable';
import { ISerializedKnowledgebase } from '../architecture/interfaces/ISerializedKnowledgebase';
import {
  ContextVariable,
  DatasetContextVariable,
  ISerializedContextVariable,
} from '../models/ContextVariables/ContextVariable';
import { SystemContextVariable } from '../models/ContextVariables/SystemContextVariable';
import { ISerializedBlock } from '../models/DialogBlocks/DialogBlock';
import {
  BaseDialogNode,
  ISerializedDialogNode,
} from '../models/DialogNodes/BaseDialogNode';
import { RedirectableNode } from '../models/DialogNodes/RedirectableNode';
import { KnowledgebaseEntry } from '../models/Knowledgebase/KnowledgebaseEntry';
import { Redirect } from '../models/Redirects/Redirect';
import {
  BLOCK_CLASS_MAPPING,
  CTX_INSTANCE_TYPE_MAPPING,
  NODE_CLASS_MAPPING,
} from '../models/Utilities/Deserialization/Mappings';
import { Text } from '../models/Utilities/Text';
import { RootStore } from './rootStore';

export class SerializationStore {
  private static instance: SerializationStore;
  constructor(public rootStore: RootStore) {
    SerializationStore.instance = this;

    makeAutoObservable(this);
  }

  @action
  public clearStores() {
    this.rootStore.uiStore.resetTransformations();
    this.rootStore.nodeStore.purge();
    this.rootStore.blockStore.purge();
    this.rootStore.ctxVarStore.purge();
    this.rootStore.knowledgebaseStore.purge();

    [...this.rootStore.canvasStore.canvasElement.children]
      .filter((child) => child.nodeName === 'path')
      .forEach((path) => path.remove());
  }

  @action
  public serialize(): ISerializedStore {
    return {
      dialogBlocks: this.rootStore.blockStore.allBlocks.map((block) => block.serialize()),
      dialogNodes: this.rootStore.nodeStore.allNodes.map((node) => node.serialize()),
      contextVariables: this.rootStore.ctxVarStore.userAndDatasetVariables.map((ctx) =>
        ctx.serialize()
      ),
      knowledgebase: this.rootStore.knowledgebaseStore.serialize(),
    };
  }

  @action
  public setupStoresFromJson(editorStructure: ISerializedStore): void {
    this.clearStores();

    this.restoreContextVariables(editorStructure.contextVariables);
    this.restoreBlocks(editorStructure.dialogBlocks);
    this.restoreNodes(editorStructure.dialogNodes);
    this.restoreKnowledgebase(editorStructure.knowledgebase);

    this.restoreConnections(editorStructure);
    this.rootStore.canvasStore.refresh();
  }

  private restoreContextVariables(vars: ISerializedContextVariable[]) {
    const deserializedContextVariables: IContextVariable[] = vars.map((item) =>
      plainToInstance(
        CTX_INSTANCE_TYPE_MAPPING[item.instanceType] ?? ContextVariable,
        item
      )
    );

    deserializedContextVariables
      .filter((item) => !(item instanceof SystemContextVariable))
      .forEach((item) =>
        item instanceof DatasetContextVariable
          ? this.rootStore.ctxVarStore.restoreDatasetCtxVariable(item)
          : this.rootStore.ctxVarStore.restore(item as ContextVariable)
      );
  }

  private restoreBlocks(blocks: ISerializedBlock[]) {
    const deserializedBlocks = blocks.map((block) => {
      return plainToInstance(BLOCK_CLASS_MAPPING[block.type], block);
    });
    deserializedBlocks.forEach((block) => this.rootStore.blockStore.restore(block));
  }

  private restoreNodes(nodes: ISerializedDialogNode[]) {
    const deserializedNodes = nodes.map((node) => {
      // @ts-ignore
      const instance = plainToInstance(NODE_CLASS_MAPPING[node.type], node, {
        excludeExtraneousValues: true,
      }) as BaseDialogNode;

      if ('redirects' in node) {
        const casted = instance as RedirectableNode;

        // @ts-ignore
        casted.redirects = node.redirects.map((item) => plainToInstance(Redirect, item));
      }

      return instance;
    });

    deserializedNodes.forEach((node) => this.rootStore.nodeStore.restore(node));
  }

  private restoreKnowledgebase(knowledgebase: ISerializedKnowledgebase) {
    if (!knowledgebase?.knowledgebaseEntries) {
      this.rootStore.knowledgebaseStore.setState(KnowledgebaseState.Initialized);
      return;
    }

    this.rootStore.knowledgebaseStore.setState(KnowledgebaseState.QuestionsLoading);
    knowledgebase.knowledgebaseEntries.forEach((entry) => {
      if (!entry) return;

      const instance = plainToInstance(KnowledgebaseEntry, entry);
      if (!instance) return;

      instance.initialAnswer = new Text(instance.answer.value);

      this.rootStore.knowledgebaseStore.restore(instance);
    });

    this.rootStore.knowledgebaseStore.removeState(KnowledgebaseState.QuestionsLoading);
    this.rootStore.knowledgebaseStore.setState(KnowledgebaseState.Initialized);
  }

  private restoreConnections(jsonResult: ISerializedStore) {
    this.restoreBlockConnections(jsonResult.dialogBlocks);
    this.restoreNodeConnections(jsonResult.dialogNodes);
  }

  private restoreBlockConnections(serializedBlocks: ISerializedBlock[]) {
    serializedBlocks.forEach((serializedBlock) => {
      const block = this.rootStore.blockStore.getById(serializedBlock.id);
      if (!block) {
        throw new Error('Block not found in block store');
      }

      serializedBlock.children.forEach((child) => {
        const childBlock = this.rootStore.blockStore.getById(child);
        block.restoreChild(childBlock);
      });

      serializedBlock.parents.forEach((parent) => {
        const parentBlock = this.rootStore.blockStore.getById(parent);
        block.restoreParent(parentBlock);
      });
    });
  }

  private restoreNodeConnections(serializedNodes: ISerializedDialogNode[]) {
    serializedNodes.forEach((serializedNode) => {
      const node = this.rootStore.nodeStore.getById(serializedNode.id);
      if (!node) {
        throw new Error('Node not found in block store');
      }

      serializedNode.children.forEach((child) => {
        const childNode = this.rootStore.nodeStore.getById(child);
        if (!childNode) {
          throw new Error('Node not found');
        }
        node.restoreChild(childNode);
      });

      serializedNode.parents.forEach((parent) => {
        const parentNode = this.rootStore.nodeStore.getById(parent);
        if (!parentNode) {
          throw new Error('Node not found');
        }
        node.restoreParent(parentNode);
      });
    });
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('SerializationStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export interface ISerializedStore {
  dialogBlocks: ISerializedBlock[];
  dialogNodes: ISerializedDialogNode[];
  contextVariables: ISerializedContextVariable[];
  knowledgebase: ISerializedKnowledgebase;
}
