import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Notification } from '../../../../models/Utilities/Notification';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

const DiscardKnowledgebaseChanges = () => {
  const { knowledgebaseStore } = useContext(rootStore);
  const { closeModal } = useModal();
  const getIcon = useGetIcon();

  const handleDiscard = async () => {
    knowledgebaseStore.discardChanges();

    new Notification({
      text: `Changes dicarded`,
      type: 'success',
    });

    closeModal();
  };

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>Are you sure you want to discard all changes?</h3>
      </div>
      <div className='btn-container'>
        <Button
          content='Yes'
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleDiscard}
        />
        <Button
          content='No'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={() => closeModal()}
        />
      </div>
    </div>
  );
};

export default observer(DiscardKnowledgebaseChanges);
