import { observer } from 'mobx-react-lite';
import React from 'react';
import { IContextVariable } from '../../../../../architecture/interfaces/contextVariables/IContextVariable';

interface IProps {
  ctxVar?: IContextVariable;
  value: any;
}

const Values: React.FC<IProps> = ({ ctxVar, value }: IProps) => {
  return (
    <>
    {Array.isArray(value) ? (
        <span> <span className='context-variable-name success'>#{ctxVar?.name}</span>= [
         {value?.map((v, index) => {
          return (
            <span>
              {v}
              {value.length - 1 !== index && ', '}
            </span>
          );
        })}
      ]</span>
    ) : (
      <span>
        <span><span className='context-variable-name'>#{ctxVar?.name}</span>= {value}</span>
      </span>
    )}
    </>
  );
};

export default observer(Values);
