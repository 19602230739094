import { Exclude, Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { ITrigger } from '../../architecture/interfaces/trigger/ITrigger';
export abstract class Trigger implements ITrigger {
  @Expose()
  id: string;

  @Exclude()
  type: TriggerTypes = TriggerTypes.UserInput;

  constructor() {
    this.id = v4();

    makeObservable(this, {
      id: observable,
      type: observable,
      isValid: computed,
      serialize: action,
    });
  }

  get isValid(): boolean {
    return true;
  }

  serialize(): ISerializedTrigger {
    return {
      id: this.id,
      type: this.type,
    };
  }
}

export interface ISerializedTrigger {
  id: string;
  type: TriggerTypes;
}
