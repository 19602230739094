import { computed, makeObservable } from 'mobx';
import { JsonElement, PathDefinition } from './JsonElement';
import { JsonValue } from './JsonValue';
import { ArrayElement } from './PathElements/ArrayElement';
import { ListElement } from './PathElements/ListElement';
import { PathElement } from './PathElements/PathElement';
import { PropertyElement } from './PathElements/PropertyElement';
export class JsonContainer extends JsonElement {
  public children: JsonElement[] = [];

  constructor(
    parent: JsonContainer | null,
    name: string | number,
    depth: number,
    index?: number
  ) {
    super(parent, name, depth, index);

    // At the time of instantiation paths CANNOT be determined as the tree is just being built up
    // E.g. { data: { branch: 'west', employees: [....] } }
    // "data" will have no children at creation, they will be processed at a later point in time so
    // it will evaluate as ArrayContainer but it's actually not.

    makeObservable(this, {
      isArrayContainer: computed,
      paths: computed,
    });
  }

  get paths(): PathDefinition {
    return {
      pathTree: this.getListPathTree(),
      stringified: this.getListPathTree()
        .map((item) => item.key)
        .join('.'),
    };
  }

  serializeToPathElements(forKey: boolean = false): PathElement[] {
    return this.paths.pathTree;
  }

  /**
   * Loops through the parent tree of the JsonContainer and creates PathElements from its parents.
   * @returns An PathElement Array
   */
  protected getListPathTree(): PathElement[] {
    let baseArray = [...this.parentTree, this];
    let transformed = [];

    for (const item of baseArray) {
      if (
        item === this &&
        this.isArrayContainer &&
        this.children.every((child) => child instanceof JsonValue)
      ) {
        // If "this" is a container for an array of primitive types
        // It also means that "this" is the last element in the tree
        transformed.push(new ListElement(this.name));
        continue;
      }

      if ((item as JsonContainer).isArrayElement) {
        transformed.push(new ArrayElement(item.index!));
        continue;
      }

      transformed.push(new PropertyElement(item.name.toString()));
    }

    return transformed;
  }

  /**
   * A JsonContainer is an ArrayContainer if:
   *  - Its name is of type number
   *  - Its name is a string but is actually a number (e.g. "2")
   *  - Its name is a string and all its values are array elements which can be determined via checking if all of them have an index set
   */
  get isArrayContainer() {
    // Genres: ["Drama", "Sci-Fi"]
    // or
    // Users: [{ id: 1, name: 'John Doe'}, { id: 2, name: 'Jane Doe'}]
    return this.children.every((child) => child.index !== undefined);
  }
}
