import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useState } from 'react';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import {
  ContextVariable as ContextVariableModel,
  ListContextVariable,
} from '../../../../../models/ContextVariables/ContextVariable';
import { ApiActionNode } from '../../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { JsonContainer } from '../../../../../models/Json/JsonContainer';
import { JsonElement } from '../../../../../models/Json/JsonElement';
import { JsonValue } from '../../../../../models/Json/JsonValue';
import { Utilities } from '../../../../../models/Utilities/Utilities';
import rootStore from '../../../../../stores/rootStore';
import Button from '../../../../common/Button';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import FoldableContainer from './responseSelector/FoldableContainer';
import KeyValuePair from './responseSelector/KeyValuePair';

interface ActiveJsonEntity {
  element: JsonElement | null;
  contextVariable: ContextVariableModel | undefined;
  isKey: boolean;
}

interface IProps {
  dialogNode: ApiActionNode;
  closeHandler: () => void;
}
const ResponseTab: React.FC<IProps> = ({ dialogNode, closeHandler }) => {
  const defaultParents =
    (dialogNode.flatJsonList?.elements.filter(
      (elem: JsonElement) => elem instanceof JsonContainer
    ) as JsonContainer[]) ?? [];

  const getIcon = useGetIcon();

  const initialEntity: ActiveJsonEntity = {
    element: null,
    contextVariable: undefined,
    isKey: false,
  };

  const [activeJsonEntity, setActiveJsonEntity] =
    useState<ActiveJsonEntity>(initialEntity);

  const [containersToHideChildrenOf, setContainersToHideChildrenOf] =
    useState<JsonContainer[]>(defaultParents);

  const { ctxVarStore } = useContext(rootStore);

  const saveDataListChange = (ctxName: string) => {
    if (!ctxName) return;

    const ctx = dialogNode.addApiContextVariable(
      ctxName,
      activeJsonEntity.element!,
      activeJsonEntity.isKey
    );

    setActiveJsonEntity({ ...activeJsonEntity, contextVariable: ctx! });
  };

  const renderTopBar = () => {
    if (activeJsonEntity.element) {
      if (
        !dialogNode.isPathAlreadyStored(
          JsonElement.getStringifiedPathOf(
            activeJsonEntity.element!,
            activeJsonEntity.isKey
          )
        )
      ) {
        return renderDataList();
      } else {
        return (
          <Fragment>
            <h5>
              Already stored as{' '}
              <span
                className='json-prop-name'
                data-ctx-type={activeJsonEntity.contextVariable?.instanceType}
              >
                {activeJsonEntity.contextVariable?.name}
              </span>
            </h5>
            <Button
              icon={getIcon('remove')}
              className='btn-remove btn-borderless'
              clickHandler={() =>
                dialogNode.removeApiContextVariable(
                  JsonElement.getStringifiedPathOf(
                    activeJsonEntity.element!,
                    activeJsonEntity.isKey
                  )
                )
              }
            />
          </Fragment>
        );
      }
    } else {
      return <h5>Click on a response value to store it as Context Variable</h5>;
    }
  };

  const renderDataList = () => {
    return (
      <div className='datalist-container'>
        <div className='datalist-container-header'>
          <h5 className='datalist-container-header-title'>
            Save{' '}
            <span
              className='json-prop-name'
              data-ctx-type={
                activeJsonEntity.isKey
                  ? ContextVariableInstanceTypes.ListContextVariable
                  : ContextVariableInstanceTypes.ContextVariable
              }
            >
              {activeJsonEntity.element?.name}
            </span>
          </h5>
          <ContextVariableSelector
            items={
              activeJsonEntity.isKey
                ? ctxVarStore.userVariables.filter(
                    (ctx) =>
                      ctx.instanceType ===
                      ContextVariableInstanceTypes.ListContextVariable
                  )
                : ctxVarStore.userVariables.filter(
                    (ctx) =>
                      ctx.instanceType !==
                      ContextVariableInstanceTypes.ListContextVariable
                  )
            }
            triggerLabel='Add Or Select'
            selectHandler={saveDataListChange}
            creator
            closeOnSelect
          />
        </div>
      </div>
    );
  };

  const toggleHideChildren = (parent: JsonContainer) => {
    setActiveJsonEntity(initialEntity);

    if (containersToHideChildrenOf.includes(parent)) {
      return setContainersToHideChildrenOf((prev) =>
        prev.filter((elem) => elem.stringifiedParentTree !== parent.stringifiedParentTree)
      );
    }

    setContainersToHideChildrenOf((prev) => [...prev, parent]);
  };

  const renderResponseContent = () =>
    dialogNode.flatJsonList?.elements.map((element: JsonElement, index: number) => (
      <div
        key={element.stringifiedParentTree}
        className='json-row'
        data-depth={element.depth}
        data-hidden={containersToHideChildrenOf.some((container) =>
          element.isChildOf(container)
        )}
      >
        {element instanceof JsonContainer ? (
          <FoldableContainer
            element={element}
            index={index}
            isChildValueStored={dialogNode.isPartOfStoredJsonValuesPath(element)}
            isJsonKeyStored={dialogNode.hasStoredJsonKey}
            isChildPrimitiveArrayKeyStored={dialogNode.isPartOfStoredPrimitiveArrayContainerPath(
              element
            )}
            isKeyStored={dialogNode.isPathAlreadyStored(element.paths.stringified)}
            isOpen={containersToHideChildrenOf.includes(element) === false}
            toggleHideChildren={() => toggleHideChildren(element)}
            storeKey={() => handleKeyClick(element)}
          />
        ) : (
          <KeyValuePair
            element={element as JsonValue}
            handleKeyClick={() => handleKeyClick(element)}
            isKeyStored={dialogNode.isPathAlreadyStored(
              (element as JsonValue).paths.key.stringified
            )}
            isValueStored={dialogNode.isPathAlreadyStored(
              (element as JsonValue).paths.value.stringified
            )}
            handleValueClick={() => handleValueClick(element as JsonValue)}
          />
        )}
      </div>
    ));

  const handleKeyClick = (element: JsonElement) => {
    let path: string;

    if (element instanceof JsonContainer) {
      path = element.paths.stringified;
    } else {
      path = (element as JsonValue).paths.key.stringified;
    }

    const ctx = dialogNode.getApiContextVariableByPath(path);

    setActiveJsonEntity({
      element,
      contextVariable: ctx,
      isKey: true,
    });
  };

  const handleValueClick = (element: JsonValue) => {
    setActiveJsonEntity({
      element,
      contextVariable: dialogNode.getApiContextVariableByPath(
        (element as JsonValue).paths.value.stringified
      ),
      isKey: false,
    });
  };

  return (
    <Fragment>
      <div className='json-prop-selector'>
        <div className='json-prop-selector-top-bar'>
          <div className='btn-container'>
            <Button
              icon={getIcon('back')}
              className='btn-borderless'
              content='Back'
              clickHandler={closeHandler}
            />
          </div>
          {renderTopBar()}
        </div>
        {dialogNode.flatJsonList && renderResponseContent()}
      </div>
    </Fragment>
  );
};

export default observer(ResponseTab);
