import { observer } from 'mobx-react-lite';
import React from 'react';
import { ConditionTypes } from '../../../../architecture/enums/Condition';
import { ActionCardCondition as ActionCardConditionModel } from '../../../../models/Conditions/ActionCardCondition';
import { Condition as ConditionModel } from '../../../../models/Conditions/Condition';
import { ContextCondition as ContextConditionModel } from '../../../../models/Conditions/ContextCondition';
import ActionCardCondition from './types/ActionCardCondition';
import ContextCondition from './types/ContextCondition';

interface IProps {
  condition: ConditionModel;
}

const Condition: React.FC<IProps> = ({ condition }) => {
  const conditionType = (() => {
    switch (condition.type) {
      case ConditionTypes.ActionCardCondition:
        return <ActionCardCondition condition={condition as ActionCardConditionModel} />;
      case ConditionTypes.ContextCondition:
      default:
        return <ContextCondition condition={condition as ContextConditionModel} />;
    }
  })();

  return conditionType;
};

export default observer(Condition);
