import React from 'react';
import { Fragment } from 'react';
import {
  ContextVariable,
  ListContextVariable,
} from '../../../../../../models/ContextVariables/ContextVariable';
import Button from '../../../../../common/Button';
import Icon from '../../../../../common/Icon';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';

interface IProps {
  ctx: ContextVariable;
  isManuallyAdded: boolean;
  deleteHandler: () => void;
  handleReassign: () => void;
  handleExecuteFunction: () => void;
}
const Pending: React.FC<IProps> = ({
  ctx,
  isManuallyAdded,
  deleteHandler,
  handleExecuteFunction,
  handleReassign,
}) => {
  const getIcon = useGetIcon();

  const canEditValue = (() => {
    if (ctx instanceof ListContextVariable) return false;

    return true;
  })();

  return (
    <Fragment>
      <div className='item-title-group'>
        <span>
          <strong>{ctx.name}</strong>
          {isManuallyAdded && (
            <Button
              className='manually-added-ctx-icon'
              icon={getIcon('add_alt')}
              tooltip='This context variable was created manually'
              tooltipPosition='top'
            />
          )}
        </span>
      </div>
      <div className='item-button-row'>
        <p>Choose an action:</p>
        {canEditValue && (
          <Fragment>
            <Button
              icon={getIcon('code')}
              className='btn-borderless'
              tooltip='Execute a function on this context variable'
              tooltipPosition='top'
              clickHandler={handleExecuteFunction}
            />
            <Button
              className='btn-borderless'
              tooltip='Overwrite to a new value'
              tooltipPosition='top'
              icon={getIcon('edit')}
              clickHandler={handleReassign}
            />
          </Fragment>
        )}
        <Button
          className='btn-borderless btn-remove'
          tooltip='Set its value to nothing'
          tooltipPosition='top'
          icon={getIcon('undo')}
          clickHandler={deleteHandler}
        />
      </div>
    </Fragment>
  );
};

export default Pending;
