import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { ContextVariableMap } from '../../../../../../models/DialogNodes/EntityNode/ContextVariableMap';
import { EntityQuestionNode } from '../../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/EntityQuestionNode';
import rootStore from '../../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';

interface IProps {
  dialogNode: EntityQuestionNode;
}

const ContextVariableMapping: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const handleCtxSelection = (value: string, ctxVariableMap: ContextVariableMap) => {
    if (error) {
      return;
    }

    const ctx = ctxVarStore.addIfNotExists(value);
    ctxVariableMap.contextVariable = ctx;
  };

  const [error, setError] = useState(false);

  const forbiddenNames = useRef<string[]>([
    ...ctxVarStore.systemVariables.map((item) => item.name),
    ...ctxVarStore.listContextVariables.map((item) => item.name),
  ]);

  const validateInput = (input: string) => {
    const containsForbiddenName = forbiddenNames.current.includes(input);
    setError(containsForbiddenName);
    return containsForbiddenName;
  };

  const renderEntities = () => {
    return dialogNode.contextVariableMapping?.map((ctxVariableMap, index) => {
      return (
        <div key={index} className='flex-wrapper'>
          <div className='entity-name mr-2'>{ctxVariableMap.entityName}</div>
          <div className='variable-wrapper ml-2 mt-1'>
            <ContextVariableSelector
              items={ctxVarStore.userVariables}
              triggerLabel={ctxVariableMap.contextVariable?.name ?? 'Add or select'}
              selectHandler={(value) => handleCtxSelection(value, ctxVariableMap)}
              inputValidator={validateInput}
              replaceSpecialCharacters
              creator
              closeOnSelect
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div className='examples-area'>
      {dialogNode.contextVariableMapping?.length > 0 && (
        <h4>Where would you like to save the entites?</h4>
      )}
      <div className='context-variables-mapping'>{renderEntities()}</div>
    </div>
  );
};

export default observer(ContextVariableMapping);
