import React from 'react';
import InfoIcon from '../../common/InfoIcon';

interface IProps {
  tooltip: string;
}

const SettingsInfoIcon: React.FC<IProps> = ({ tooltip }) => {
  return (
    <InfoIcon
      type='info'
      tooltip={tooltip}
      style={{ fontSize: '1rem', width: '10px', height: '10px' }}
    />
  );
};

export default SettingsInfoIcon;
