import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import { DatasetActionNode } from '../../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import rootStore from '../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';

interface IProps {
  dialogNode: DatasetActionNode;
}
const DatasetContextVariableHandler: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const [error, setError] = useState(false);

  const validateInput = (input: string) => {
    const forbiddenNames = [...ctxVarStore.allVariables.map((item) => item.name)];
    const containsForbiddenName = forbiddenNames.includes(input);
    setError(containsForbiddenName);
    return containsForbiddenName;
  };

  const handleCtxSelection = (value: string) => {
    if (error) {
      return;
    }

    dialogNode.contextVariable = value
      ? ctxVarStore.addIfNotExists(
          value,
          ContextVariableInstanceTypes.DatasetContextVariable,
          dialogNode.dataset
        )!
      : undefined;
  };

  return (
    <Fragment>
      <h4>Where would you like to save the dataset?</h4>
      <ContextVariableSelector
        items={ctxVarStore.datasetVariables.filter(
          (variable) => variable.type === dialogNode.dataset?.name
        )}
        triggerLabel={dialogNode.contextVariable?.name ?? 'Add or select'}
        selectHandler={handleCtxSelection}
        inputValidator={validateInput}
        replaceSpecialCharacters
        creator
        closeOnSelect
      />
    </Fragment>
  );
};

export default observer(DatasetContextVariableHandler);
