import { observable, makeObservable } from 'mobx';
import { NodeHistoryElement } from './NodeHistoryElement';

export class PromptNodeElement extends NodeHistoryElement {
  text: string;
  promptType: string;

  constructor() {
    super();
    this.text = '';
    this.promptType = '';

    makeObservable(this, {
      promptType: observable,
      text: observable,
    });
  }
}
