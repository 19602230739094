import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Checkbox from '../common/Checkbox';
import { useGetIcon } from '../customHooks/useGetIcon';


interface IProps {
  name: string;
  title: string;
  options: { label: string, title: string}[];
  className?: string;
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
  searchParams?: any;
}

const FilterOptionsList: React.FC<IProps> = ({
  name,
  title,
  options,
  className,
  handleParamsChange,
  clearParamsChange,
  searchParams
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const getIcon = useGetIcon();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event?.target as HTMLElement;
    if (ref?.current && !ref?.current?.contains(target)) {
      setOpen(false);
    }
  };

  const getValue = (option: string) => {
    return (params[name] && params[name] === option) || searchParams && searchParams[name] === option ? 'true' : null;
  };

  const clickHandler = (value: boolean, option: string) => {
    handleParamsChange(name, option);
  };

  return (
    <div onClick={() => {}} ref={ref}>
      <div className={`text-filter ${className}`} onClick={() => setOpen(!open)}>
        {title}
        <i className={`${getIcon('down')} filter-dropdown-icon`}></i>
      </div>
      {open && (
        <div className='filter-container'>
          {options.map((option, key) => {
            return (
              <Checkbox
                key={key}
                content={option.title}
                value={getValue(option.label)}
                clickHandler={(value: boolean) => clickHandler(value, option.label)}
              />
            );
          })}
          <div className='clear'>
            {' '}
            <span onClick={() => clearParamsChange(name)}>
              <i className={getIcon('close')}> </i> Clear
            </span>{' '}
          </div>
        </div>
      )}
    </div>
  );
};

export default observer(FilterOptionsList);
