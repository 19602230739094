export const HEADERS = {
  botName: {
    label: 'Bots',
    id: 'botName',
  },
  creationDate: {
    label: 'Creation Date',
    id: 'creationDate',
  },
  actions: {
    label: '',
    id: 'actions',
  },
};
