import { Transform, TransformFnParams } from 'class-transformer';
import { DatasetListAction } from '../../../architecture/enums/Dataset';
import BlockStore from '../../../stores/BlockStore';
import ContextVariableStore from '../../../stores/ContextVariableStore';
import DatasetStore from '../../../stores/DatasetStore';
import MediaStore from '../../../stores/MediaStore';
import {
  ActionAttachment,
  ISerializedActionAttachment,
} from '../../DialogNodes/ActionAttachment';
import { Question } from '../../Knowledgebase/Question';
import { ISerializedMedia } from '../../Media/Media';
import { Text } from '../Text';
import { Utilities } from '../Utilities';

export const TransformIntoText: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) => new Text(value.value)
);

export const TransformIntoTextArray: PropertyDecorator = Transform(
  ({ value, obj }: TransformFnParams) => {
    return value.map((msg: string | any) =>
      // Necessary for backward compatibility
      typeof msg === 'string' ? new Text(msg) : new Text(msg.value)
    );
  }
);

export const TransformIntoQuestion: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) =>
    value.map((text: string) => new Question(new Text(text), 'UpToDate'))
);

export const TransformIntoContextVariable: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) => ContextVariableStore.getInstance().getById(value)
);

export const TransformIntoDataset: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) => DatasetStore.getInstance().getById(value)
);

export const TransformIntoDialogBlock: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) => BlockStore.getInstance().getById(value)
);

export const TransformIntoMedia: PropertyDecorator = Transform(
  ({ value }: TransformFnParams) => {
    if (value) {
      return value
        .filter((v: ISerializedMedia) => MediaStore.getInstance().getById(v.mediaId))
        .map((v: ISerializedMedia) => MediaStore.getInstance().getById(v.mediaId));
    } else {
      return [];
    }
  }
);

export const TransformIntoActions: PropertyDecorator = Transform(({ value }) => {
  const actions = value && !Utilities.isEmpty(value) ? JSON.parse(value) : [];
  if (Utilities.isEmpty(value)) {
    return [];
  } else {
    return actions.reduce(
      (acc: ActionAttachment[], action: ISerializedActionAttachment) => {
        const { type, data, url, caption } = action;

        // Support for legacy action attachments.
        if (data === undefined && url !== undefined && caption !== undefined) {
          const newAction = new ActionAttachment(
            type,
            JSON.stringify({ url, caption }, undefined, 2)
          );
          return [...acc, newAction];
        } else {
          const newAction = new ActionAttachment(
            type,
            Utilities.isEmpty(data) ? '' : JSON.stringify(data, undefined, 2)
          );
          return [...acc, newAction];
        }
      },
      []
    );
  }
});

export const TransformIntoCreatedVariables: PropertyDecorator = Transform(
  ({ obj }: TransformFnParams) => {
    if (obj.type === 'DatasetList') {
      if (obj.values) {
        return obj.values.map((v: any) => ContextVariableStore.getInstance().getById(v));
      }
      if (obj.addValues)
        return obj.addValues.map((v: any) =>
          ContextVariableStore.getInstance().getById(v)
        );
    }
    return undefined;
  }
);

export const TransformIntoDatasetListAction: PropertyDecorator = Transform(
  ({ obj }: TransformFnParams) => {
    if (
      obj.type === 'DatasetList' &&
      obj.datasetListAction &&
      obj.datasetListAction === 'Add'
    ) {
      return DatasetListAction.Add;
    }
    return DatasetListAction.Set;
  }
);

export const TranformIntoValue: PropertyDecorator = Transform(
  ({ obj }: TransformFnParams) => {
    if (obj.type === 'Simple') {
      return obj.value;
    }
    return undefined;
  }
);

export const TransformIntoContextVariableFromValue: PropertyDecorator = Transform(
  ({ obj }: TransformFnParams) => {
    if (obj.type === 'Dataset') {
      return ContextVariableStore.getInstance().getById(obj.value);
    }
    return undefined;
  }
);
