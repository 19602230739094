import {
  IPronunciation,
  IUpdatePronunciationsRequest,
} from '../../architecture/interfaces/HTTP/PronunciationParams';
import { HttpAgent } from '../../utility/HttpAgent';
import { Notification } from '../Utilities/Notification';

export class PronunciationConnector {
  static async udpate(
    subscriptionId: string,
    language: string,
    request: IUpdatePronunciationsRequest
  ): Promise<IPronunciation[]> {
    return await HttpAgent.requests
      .post(
        `/Api/V2/Subscriptions/${subscriptionId}/Pronunciations?language=${language}`,
        request
      )
      .then((resp) => {
        new Notification({ text: 'Successfully saved changes', type: 'success' });
        return resp;
      })
      .catch(() => {
        new Notification({ text: 'Failed to update pronuncations', type: 'error' });
      });
  }

  static async get(subscriptionId: string, language: string): Promise<IPronunciation[]> {
    return await HttpAgent.requests
      .get(`/Api/V2/Subscriptions/${subscriptionId}/Pronunciations?language=${language}`)
      .catch(() => {
        new Notification({ text: 'Failed to fetch pronuncations', type: 'error' });
      });
  }
}
