import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, AvatarTexturesAdminState } from '../../architecture/enums/Admin';
import { IAvatarTexture } from '../../architecture/interfaces/HTTP/AdminParams';
import { AvatarTexture } from '../../models/Admin/AvatarTexture';
import { AvatarTexturesConnector } from '../../models/Connectors/admin/AvatarTexturesConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class AvatarTexturesAdminStore {
  private static instance: AvatarTexturesAdminStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    AvatarTexturesAdminStore.instance = this;
  }

  @observable
  private _avatarTextureRepository: AvatarTexture[] = [];

  @computed
  get getAvatarTextures() {
    return this._avatarTextureRepository;
  }

  @observable
  state: AvatarTexturesAdminState = AvatarTexturesAdminState.None;

  @computed
  getState(state: AvatarTexturesAdminState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: AvatarTexturesAdminState) {
    this.state |= state;
  }

  @computed
  removeState(state: AvatarTexturesAdminState) {
    this.state &= ~state;
  }

  @action
  purge() {
    this._avatarTextureRepository = [];
  }

  @action
  async load() {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(AvatarTexturesAdminState.Loading);

      const response = await AvatarTexturesConnector.getAll(subscriptionId);
      runInAction(() => this.restoreAvatarTextureRepository(response));

      this.removeState(AvatarTexturesAdminState.Loading);
    } catch (error) {
      this.removeState(AvatarTexturesAdminState.Loading);
    }
  }

  @action
  async delete(texture: AvatarTexture) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(AvatarTexturesAdminState.Loading);
      await AvatarTexturesConnector.delete(subscriptionId, texture.name);

      await this.load();

      this.removeState(AvatarTexturesAdminState.Loading);
    } catch (error) {
      console.log(error);
      this.removeState(AvatarTexturesAdminState.Loading);
    }
  }

  @action
  async add(name: string, files: FileList) {
    try {
      const subscriptionId =
        this.rootStore.adminStore.subscriptionAdminStore.getSelectedSubscription?.id;
      if (!subscriptionId) return;

      this.setState(AvatarTexturesAdminState.Loading);

      await AvatarTexturesConnector.upload(subscriptionId, name, files);

      await this.load();

      // this.removeState(TexturesAdminState.Loading);
    } catch (error) {
      console.log(error);
      this.removeState(AvatarTexturesAdminState.Loading);
    }
  }

  private restoreAvatarTextureRepository(textures: IAvatarTexture[]) {
    this.purge();

    textures.forEach((texture) => {
      const instance = plainToInstance(
        ADMINS_CLASS_MAPPING[Admin.AvatarTexture],
        texture
      );
      this._avatarTextureRepository.push(instance);
    });
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('AvatarTexturesAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default AvatarTexturesAdminStore;
