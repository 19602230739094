import { DialogNodeTypes } from '../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { BaseDialogNode } from './BaseDialogNode';

export class KnowledgebaseNode extends BaseDialogNode {
  readonly type: DialogNodeTypes = DialogNodeTypes.KnowledgebaseNode;
  constructor(block: DialogBlock) {
    super(block);
  }
}
