import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import 'reflect-metadata';
import App from './App';
import './index.scss';
import AuthProvider from './ms_authentication/context/AuthProvider';

// Using a lower-level Router object from React Router DOM
// Creating the history object for the useHistory() Hook separately allows us to use that history object in non-react files in the application
export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
