import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { MediaState } from '../../../architecture/enums/MediaState';
import { MediaTypes } from '../../../architecture/enums/MediaType';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Upload from '../../common/inputElements/Upload';
import { useCreateMediaItem } from '../../customHooks/useCreateMediaItem';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';
import Filters from '../filters/Filters';
import { CommunicationDialogNode } from '../../../models/DialogNodes/CommunicationDialogNode';
import { KnowledgebaseEntry } from '../../../models/Knowledgebase/KnowledgebaseEntry';
import { Media } from '../../../models/Media/Media';

interface IProps {
  mediaType?: MediaTypes;
  item?: CommunicationDialogNode | KnowledgebaseEntry;
  resetModal: () => void;
  addImage?: (image: Media | null) => void;
  selectedImage?: string;
}

const SelectMediaList: React.FC<IProps> = ({
  item,
  mediaType,
  resetModal,
  addImage,
  selectedImage,
}) => {
  const { createMediaItem } = useCreateMediaItem();
  const getIcon = useGetIcon();
  const { mediaStore, subscriptionStore, uiStore } = useContext(rootStore);
  const [allMediaItems, setAllMediaItems] = useState(mediaStore.allMediaItems);

  const [searchParams, setSearchParams] = useState({
    type: mediaType || '',
    name: '',
  });

  useEffect(() => {
    return () => {
      mediaStore.purgeSelectedMediaItems();
    };
  }, []);

  useEffect(() => {
    const mediaItems = mediaStore.search(
      searchParams.type?.toString(),
      searchParams.name?.toString()
    );
    setAllMediaItems(mediaItems);
  }, [mediaStore.allMediaItems, searchParams.type, searchParams.name]);

  const handleParamsChange = (key: string, value: string) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };

  const clearParamsChange = (key: string) => {
    setSearchParams({
      ...searchParams,
      [key]: null,
    });
  };

  const renderMediaItems = () => {
    return allMediaItems.map((item) => createMediaItem(item));
  };

  const addSelectedMediaItems = () => {
    uiStore.closeSecondModal();
    if (item) {
      item.addMediaFiles(mediaStore._selectedMediaItems);
    } else if (addImage) {
      addImage(
        mediaStore._selectedMediaItems.length > 0
          ? mediaStore._selectedMediaItems[0]
          : null
      );
    }
    resetModal();
  };

  useEffect(() => {
    if (item) {
      item.mediaFiles.forEach((i) => mediaStore.toggleMediaFileSelection(i));
    } else {
      if (selectedImage) mediaStore.addToSelectedItems(selectedImage);
    }
  }, []);

  const loadMediaFiles = async () => {
    await mediaStore.load();
  };

  useEffect(() => {
    loadMediaFiles();
  }, [subscriptionStore.selectedSubscription?.id]);

  const upload = async (formData: FormData) => {
    await mediaStore.upload(formData);
  };

  return (
    <div className='modal-media-wrapper'>
      <div className='media-header'>
        <Filters
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
          searchParams={searchParams}
          hideMediaTypeFilter={mediaType ? true : false}
        />
      </div>
      {mediaStore.getState(MediaState.ItemsLoading) ? (
        <LoadingPage />
      ) : (
        <>
          <div className='modal-media-list-container'>
            {allMediaItems?.length > 0 && renderMediaItems()}
          </div>
          <div className='modal-footer'>
            <Upload upload={upload} />
            <Button
              icon={getIcon('check_solid')}
              content='Done'
              className='btn-round btn-primary'
              clickHandler={() => addSelectedMediaItems()}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default observer(SelectMediaList);
