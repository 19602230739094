import { IPaymentOverview } from '../../../architecture/interfaces/HTTP/AdminParams';
import { HttpAgent } from '../../../utility/HttpAgent';

export class ContingentConnector {
  static async getOverview(
    subscriptionId: string,
    botId: string
  ): Promise<IPaymentOverview> {
    return await HttpAgent.requests.get(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}/Contingent`
    );
  }

  static async addPayment(
    subscriptionId: string,
    botId: string,
    credits: number,
    remarks?: string
  ) {
    return await HttpAgent.requests.post(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}/Contingent`,
      {
        credits: credits,
        remarks: remarks,
      }
    );
  }

  static async deletePayment(subscriptionId: string, botId: string, paymentId: string) {
    return await HttpAgent.requests.delete(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/Bots/${botId}/Contingent/${paymentId}`
    );
  }
}
