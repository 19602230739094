import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { AvatarTexture } from '../../../../models/Admin/AvatarTexture';
import { Bot } from '../../../../models/Admin/Bot';
import { Payment } from '../../../../models/Admin/Payment';
import { Subscription } from '../../../../models/Admin/Subscription';
import { User } from '../../../../models/Admin/User';
import { BaseDialogComponent } from '../../../../models/BaseDialogComponent';
import { Dialog } from '../../../../models/Bot/Dialog';
import { Dataset } from '../../../../models/Dataset/Dataset';
import { DialogBlock } from '../../../../models/DialogBlocks/DialogBlock';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import { Intent } from '../../../../models/Intents/Intent';
import { Media } from '../../../../models/Media/Media';
import { Notification } from '../../../../models/Utilities/Notification';
import { Utilities } from '../../../../models/Utilities/Utilities';
import rootStore from '../../../../stores/rootStore';
import useAdminStores from '../../../customHooks/useAdminStores';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

const ConfirmDelete = () => {
  const {
    uiStore,
    blockStore,
    nodeStore,
    intentStore,
    dialogStore,
    mediaStore,
    datasetStore,
  } = useContext(rootStore);
  const {
    subscriptionAdminStore,
    botAdminStore,
    avatarTexturesAdminStore,
    userAdminStore,
    creditManagementAdminStore,
  } = useAdminStores();
  const { closeModal } = useModal();
  const getIcon = useGetIcon();

  const handleDelete = async () => {
    const elementToRemove = uiStore.modalState.element;

    if (elementToRemove instanceof DialogBlock) {
      blockStore.remove(elementToRemove);
    } else if (elementToRemove instanceof BaseDialogNode) {
      nodeStore.remove(elementToRemove);
    } else if (elementToRemove instanceof Intent) {
      intentStore.remove(elementToRemove);
    } else if (elementToRemove instanceof Dialog) {
      await dialogStore.removeDialog(elementToRemove);
    } else if (elementToRemove instanceof Media) {
      mediaStore.remove(elementToRemove);
    } else if (elementToRemove instanceof Dataset) {
      datasetStore.remove(elementToRemove);
    } else if (elementToRemove instanceof Subscription) {
      subscriptionAdminStore.remove(elementToRemove);
    } else if (elementToRemove instanceof User) {
      userAdminStore.remove(elementToRemove);
    } else if (elementToRemove instanceof Payment) {
      creditManagementAdminStore.outdatePayment(elementToRemove);
    } else if (elementToRemove instanceof Bot) {
      await botAdminStore.remove(elementToRemove);
    } else if (elementToRemove instanceof AvatarTexture) {
      await avatarTexturesAdminStore.delete(elementToRemove);
    }

    new Notification({
      text: `${getDisplayName()} removed`,
      type: 'success',
    });

    closeModal();
  };

  const getDisplayName = () => {
    const elementToRemove = uiStore.modalState.element;

    if (elementToRemove instanceof Media) {
      return Utilities.truncateText(elementToRemove.fileName, 30);
    } else if (elementToRemove instanceof Intent || elementToRemove instanceof Dataset) {
      return elementToRemove.name;
    } else if (elementToRemove instanceof BaseDialogComponent) {
      return elementToRemove.title;
    } else if (elementToRemove instanceof Dialog) {
      return elementToRemove.versionName;
    } else if (elementToRemove instanceof Subscription) {
      return elementToRemove.name;
    } else if (elementToRemove instanceof User) {
      return elementToRemove.displayName;
    } else if (elementToRemove instanceof Payment) {
      return 'Payment';
    } else if (elementToRemove instanceof Bot) {
      return elementToRemove.botName;
    } else if (elementToRemove instanceof AvatarTexture) {
      return elementToRemove.name;
    } else {
      return 'Element';
    }
  };

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>Are you sure you want to delete this element?</h3>
      </div>
      <h2 className='header-highlight'>{getDisplayName()}</h2>
      <div className='btn-container'>
        <Button
          content='Delete'
          name={getDisplayName()}
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleDelete}
        />
        <Button
          content='Cancel'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={() => closeModal()}
        />
      </div>
    </div>
  );
};

export default observer(ConfirmDelete);
