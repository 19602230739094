import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { TriggerTypes } from '../../architecture/enums/TriggerTypes';
import { TransformIntoText } from '../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../Utilities/Text';
import { Utilities } from '../Utilities/Utilities';
import { ISerializedTrigger, Trigger } from './Trigger';

export class AiTrigger extends Trigger {
  readonly type = TriggerTypes.AI;

  @Expose()
  @TransformIntoText
  intentDescription: Text = new Text('');

  constructor() {
    super();

    makeObservable(this, {
      intentDescription: observable,
    });
  }

  get isValid() {
    return !Utilities.isEmpty(this.intentDescription.value);
  }

  serialize(): ISerializedAiTrigger {
    return {
      ...super.serialize(),
      intentDescription: this.intentDescription,
    };
  }
}

export interface ISerializedAiTrigger extends ISerializedTrigger {
  intentDescription: ISerializedText;
}
