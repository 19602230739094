import { Expose } from 'class-transformer';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { ISerializedQuestionDialogNode } from '../QuestionDialogNode';
import { SingleQuestionNode } from '../SingleQuestionNode';

export enum AllowedAttachmentType {
  Any = 'Any',
  Image = 'Image',
  Document = 'Document',
  Video = 'Video',
}

export class AttachmentQuestionNode extends SingleQuestionNode {
  type: DialogNodeTypes = DialogNodeTypes.AttachmentQuestionNode;

  @Expose()
  multipleFiles: boolean = false;

  @Expose()
  allowedAttachmentType: AllowedAttachmentType = AllowedAttachmentType.Image;

  constructor(block: DialogBlock) {
    super(block);

    this.autoRecognitionEnabled = false;
    this.title = 'Attachment Question Node';
  }

  serialize(): ISerializedAttachmentQuestionNode {
    return {
      ...super.serialize(),
      multipleFiles: this.multipleFiles,
      allowedAttachmentType: this.allowedAttachmentType,
    };
  }
}

export interface ISerializedAttachmentQuestionNode extends ISerializedQuestionDialogNode {
  multipleFiles: boolean;
  allowedAttachmentType: AllowedAttachmentType;
}
