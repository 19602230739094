import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import rootStore from '../../../stores/rootStore';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import DatasetDetails from './DatasetDetails';
import DatasetList from './DatasetList';

const ManageDataset: React.FC = () => {
  const { datasetStore } = useContext(rootStore);
  const [editMode, setEditMode] = useState(false);
  const [addDataset, setAddDataset] = useState(false);

  useEffect(() => {
    return () => {
      datasetStore.setSelectedDataset(null);
    };
  }, []);

  const showOnlyDatasets = () => {
    return (
      <SinglePane
        pane={
          <DatasetList
            editMode={editMode}
            setEditMode={setEditMode}
            setAddDataset={setAddDataset}
          />
        }
      />
    );
  };

  const showDatasetsAndDetails = () => {
    return (
      <TwoPanes
        leftPane={
          <DatasetList
            editMode={editMode}
            setEditMode={setEditMode}
            setAddDataset={setAddDataset}
          />
        }
        rightPane={
          <DatasetDetails
            editMode={editMode || addDataset}
            addDataset={addDataset}
            setEditMode={setEditMode}
            setAddDataset={setAddDataset}
          />
        }
      />
    );
  };

  if (datasetStore.getSelectedDataset || addDataset) {
    return showDatasetsAndDetails();
  }

  return showOnlyDatasets();
};

export default observer(ManageDataset);
