import { observable, makeObservable } from 'mobx';
import { NodeHistoryElement } from './NodeHistoryElement';

export class EmailActionNodeElement extends NodeHistoryElement {
  message: string;
  subject: string;
  to: string;

  constructor() {
    super();
    this.message = '';
    this.subject = '';
    this.to = '';

    makeObservable(this, {
      message: observable,
      subject: observable,
      to: observable
    });
  }
}
