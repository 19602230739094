import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { SideBarEditor } from '../../../layout/sideBarEditor/SideBarEditor';
import Action from './Action';

interface IActionAttachmentsProps {
  dialogNode: CommunicationDialogNode;
}

const ActionAttachments: React.FC<IActionAttachmentsProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();
  const [activeActionId, setActiveActionId] = useState<string | undefined>(
    dialogNode.genericActions.length > 0 ? dialogNode.genericActions[0].id : undefined
  );

  const activeAction = dialogNode.genericActions.find(
    (action) => action.id === activeActionId
  );

  const setFirstActionAsActive = () => {
    if (dialogNode.genericActions.length > 0) {
      setActiveActionId(dialogNode.genericActions[0].id);
    }
  };

  const handleAddNewAction = () => {
    dialogNode.addAction(new ActionAttachment());
    setFirstActionAsActive();
  };

  const handleRemoveAction = (id: string) => {
    if (id === activeActionId) {
      setActiveActionId(undefined);
    }

    const action = dialogNode.actions.find((action) => action.id === id);
    if (!action) return;

    dialogNode.removeAction(action);
  };

  return (
    <div className='action-attachments-container'>
      <SideBarEditor.Base>
        <SideBarEditor.SideBar
          items={dialogNode.genericActions.map((action) => ({
            id: action.id,
            name: action.type,
          }))}
          activeItemId={activeActionId}
          setActiveItemId={setActiveActionId}
          removeItemHandler={handleRemoveAction}
        >
          <Button
            icon={getIcon('add')}
            content='Add New Action'
            className='btn-borderless add-action-btn'
            clickHandler={handleAddNewAction}
            disabled={!dialogNode.areGenericActionsValid}
          />
        </SideBarEditor.SideBar>
        <SideBarEditor.Editor>
          {activeAction ? (
            <Action key={activeAction.id} action={activeAction} />
          ) : (
            <h3 style={{ alignSelf: 'center', justifySelf: 'center' }}>
              Select or create an Action Attachment
            </h3>
          )}
        </SideBarEditor.Editor>
      </SideBarEditor.Base>
    </div>
  );
};

export default observer(ActionAttachments);
