import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import {
  ConditionOperator,
  CONDITIONS_REQUIRING_VALUES,
  CONDITIONS_WITHOUT_VALUES,
  ConditionTypes,
} from '../../architecture/enums/Condition';
import type { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import { TransformIntoContextVariable } from '../Utilities/Deserialization/Decorators';
import { Condition, ISerializedCondition } from './Condition';

export class ContextCondition extends Condition {
  type = ConditionTypes.ContextCondition;

  @Expose({ name: 'ctxId' })
  @TransformIntoContextVariable
  ctx: IContextVariable;

  @Expose()
  operator: keyof ConditionOperator;

  @Expose()
  value?: string | number;

  constructor(
    ctx: IContextVariable,
    operator: keyof ConditionOperator,
    value?: string | number,
    id?: string
  ) {
    super(id);
    this.ctx = ctx;
    this.operator = operator;
    this.value = value;

    makeObservable(this, {
      ctx: observable,
      operator: observable,
      value: observable,
    });
  }

  get isValid(): boolean {
    if (CONDITIONS_REQUIRING_VALUES.includes(this.operator))
      return this.value && this.ctx ? true : false;

    if (CONDITIONS_WITHOUT_VALUES.includes(this.operator)) return !!this.ctx;

    return false;
  }

  serialize(): ISerializedContextCondition {
    return {
      ...super.serialize(),
      ctxId: this.ctx.id,
      operator: this.operator,
      value: CONDITIONS_REQUIRING_VALUES.includes(this.operator) ? this.value : undefined,
    };
  }
}

export interface ISerializedContextCondition extends ISerializedCondition {
  ctxId: string;
  operator: keyof ConditionOperator;
  value?: string | number;
}
