import { observer } from 'mobx-react-lite';
import React from 'react';
import { SingleQuestionNode as SingleQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/SingleQuestionNode';
import QuestionDialogNode from '../QuestionDialogNode';
import SingleQuestionContextVariableHandler from './SingleQuestionContextVariableHandler';

interface IProps {
  dialogNode: SingleQuestionNodeModel;
  children?: React.ReactNode;
}
const SingleQuestionNode: React.FC<IProps> = ({ dialogNode, children }) => {
  return (
    <QuestionDialogNode dialogNode={dialogNode}>
      {children}
      <SingleQuestionContextVariableHandler dialogNode={dialogNode} />
    </QuestionDialogNode>
  );
};

export default observer(SingleQuestionNode);
