import React from 'react';
import { DialogLanguages } from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import { Bot } from '../../../models/Bot/Bot';
import { Dialog } from '../../../models/Bot/Dialog';
import { Utilities } from '../../../models/Utilities/Utilities';
import Icon from '../../common/Icon';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  bot: Bot;
}
const BotDetails: React.FC<IProps> = ({ bot }) => {
  const getIcon = useGetIcon();

  const getLanguage = (language: string) => {
    return Object.entries(DialogLanguages).find(
      ([_, code]: [string, string]) => code === language
    )?.[0];
  };

  const createContributorList = (): string[] => {
    return bot.dialogs.reduce((acc: string[], dialog: Dialog, index: number) => {
      acc = [...new Set([...acc, dialog.creator, dialog.lastEditor])];
      return acc;
    }, []);
  };

  return (
    <div className='default-modal bot-details-modal'>
      <div className='header'>
        <h2 className='title'>
          <Icon icon={getIcon('robot')} /> {bot.name}
        </h2>
      </div>
      <div className='body'>
        <div className='detail-row'>
          <strong className='detail-key'>Creation Date: </strong>
          <span className='detail-info'>{Utilities.getLocalDate(bot.creationDate)}</span>
        </div>
        <div className='detail-row'>
          <strong className='detail-key'>Language: </strong>
          <span className='detail-info'>{getLanguage(bot.language)}</span>
        </div>
        <div className='detail-row'>
          <strong className='detail-key'>Dialog Count: </strong>
          <span className='detail-info'>{bot.dialogs.length}</span>
        </div>
        <div className='detail-row contributor-row'>
          <strong className='detail-key'>Contributors: </strong>
          <ul className='detail-info contributor-list'>
            {createContributorList().map((contributor) => (
              <li key={contributor} className='contributor-list-item'>
                <Icon icon={getIcon('user')} />
                <a href={`mailto:${contributor}`} className='contributor-mail-to'>
                  {contributor}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BotDetails;
