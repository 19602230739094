import React from 'react';
import { Utilities } from '../../../../models/Utilities/Utilities';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import SearchField from '../SearchField';
interface IProps {
  value: string;
  clear: boolean;
  valueExists: boolean;
  placeholder?: string;
  replaceSpecialCharacters?: boolean;
  isItemChosen: boolean;
  changeHandler: (value: string) => void;
  keyDownHandler: (event: React.KeyboardEvent) => void;
  createHandler: (title: string) => void;
}
const CreatorSearchField: React.FC<IProps> = ({
  value,
  clear,
  valueExists,
  replaceSpecialCharacters,
  isItemChosen,
  placeholder = 'Choose or create an item...',
  changeHandler,
  keyDownHandler,
  createHandler,
}) => {
  const getIcon = useGetIcon();

  const interceptHandleChange = (value: string) => {
    if (replaceSpecialCharacters) {
      value = Utilities.replaceAllSpecialCharactersInText({ text: value });
    }
    changeHandler(value);
  };

  const interceptKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !clear) {
      createHandler(value);
    }
    keyDownHandler(event);
  };

  const resetValue = () => createHandler('');

  return (
    <SearchField
      placeholder={placeholder}
      value={value}
      changeHandler={interceptHandleChange}
      keyDownHandler={interceptKeyDown}
    >
      {valueExists && <i className={getIcon('check')}></i>}
      {isItemChosen && <i className={getIcon('undo')} onClick={resetValue}></i>}
    </SearchField>
  );
};

export default CreatorSearchField;
