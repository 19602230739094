import React from 'react';

interface IProps {
  fullScreen?: boolean;
}

const Loading: React.FC<IProps> = ({ fullScreen = true }) => {
  return <div className={`loader ${fullScreen ? 'fullScreen' : 'show'}`}></div>;
};

export default Loading;
