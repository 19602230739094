import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import ButtonDropdown from '../../../common/ButtonDropdown';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  content?: string | React.ReactElement;
  icon?: string;
  disabled?: boolean;
  routeTo: string;
  active: boolean;
}

const NavigationButton: React.FC<IProps> = ({
  content,
  icon,
  disabled = false,
  routeTo,
  active,
}) => {
  const location = useLocation();
  const { nodeStore, appStore } = useContext(rootStore);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getIcon = useGetIcon();
  const history = useHistory();

  const handleSave = async () => {
    const savedSuccesfully = await appStore.save();

    if (!savedSuccesfully) {
      history.push('/error');
    }
  };

  const handleDiscard = () => {
    appStore.discardChanges();
  };

  const onButtonClick = () => {
    if (routeTo && routeTo === history.location.pathname) return;

    if (!appStore.hasEditorStructureChanged || !location.pathname.includes('dialogs')) {
      return history.push(routeTo);
    }

    setDropdownOpen(true);
  };

  return (
    <ButtonDropdown
      title='Do you want to save your changes?'
      open={dropdownOpen}
      setOpen={setDropdownOpen}
      config={{
        triggerBtn: (
          <Button
            icon={icon}
            className={`btn-round ${
              typeof content === 'string'
                ? active
                  ? 'btn-primary'
                  : 'btn-inactive'
                : 'logo-button'
            } `}
            content={content}
            clickHandler={onButtonClick}
            disabled={disabled}
          />
        ),
        controlBtns: [
          <Button
            key='Save'
            className='btn-primary btn-round mx-2'
            content='Save'
            disabled={appStore.isInErrorState || !nodeStore.areNodesValid}
            icon={getIcon('save')}
            name='btn-save-on-overview-switch'
            clickHandler={() => {
              handleSave && handleSave();
              setDropdownOpen(false);
              history.push(routeTo);
            }}
          />,
          <Button
            key='Discard'
            className='btn-dark btn-round mx-2'
            content='Discard'
            icon={getIcon('close')}
            clickHandler={() => {
              handleDiscard && handleDiscard();
              setDropdownOpen(false);
              history.push(routeTo);
            }}
          />,
        ],
      }}
    />
  );
};

export default observer(NavigationButton);
