import { observer } from 'mobx-react-lite';
import React from 'react';
import { UserReceiver as UserReceiverModel } from '../../../../../../../models/Triggers/EventTrigger/EventTarget/MicrosoftTeams/Receivers/UserReceiver';
import TextBoxWithCtx from '../../../../../../common/inputElements/TextBoxWithCtx';
import { useReadOnly } from '../../../../../../customHooks/useReadOnly';

interface IProps {
  receiver: UserReceiverModel;
}

const UserReceiver: React.FC<IProps> = ({ receiver }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const updateEmail = (text: string) => {
    receiver.email = text;
  };

  return (
    <TextBoxWithCtx
      disabled={readOnly}
      title='Email Address'
      value={receiver.email}
      saveHandler={updateEmail}
    />
  );
};

export default observer(UserReceiver);
