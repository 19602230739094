import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router';
import rootStore from '../../stores/rootStore';
import Modal from '../common/modal/Modal';
import Admin from './Admin/Admin';
import Canvas from './Canvas';
import Dashboard from './Dashboard/Dashboard';
import Dataset from './Dataset/Dataset';
import DialogOverview from './DialogOverview';
import ErrorPage from './ErrorPage';
import MediaOverview from './MediaOverview';
import Navbar from './Navbar/Navbar';
import Notification from './Notification';
import { ProtectedRoute } from './ProtectedRoute';
import SettingsPage from './Settings/SettingsPage';

const MainPage = () => {
  const { uiStore } = useContext(rootStore);

  return (
    <>
      <Navbar />
      <Canvas />
      {uiStore.showModal && <Modal />}
      <Switch>
        <Route exact path='/' component={DialogOverview} />
        <Route path='/settings' component={SettingsPage} />
        <Route path='/dialogs' component={Dashboard} />
        <ProtectedRoute path='/admin'>
          <Admin />
        </ProtectedRoute>
        <Route exact path='/media' component={MediaOverview} />
        <Route path='/datasets' component={Dataset} />
        <Route path='/error' component={ErrorPage} />
      </Switch>
      {uiStore.notifications.length > 0 &&
        uiStore.notifications.map((alert) => (
          <Notification key={alert.id} alert={alert} />
        ))}
    </>
  );
};

export default observer(MainPage);
