import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';
import SettingsSelector from './SettingsSelector';
import BotSettings from './childPages/BotSettings';
import ConfirmDataset from './childPages/ConfirmDataset';
import ImportExport from './childPages/ImportExport';

const SettingsPage = () => {
  const pathPrefix = `/dialogs/:dialogId/bot-settings`;

  return (
    <div className='settings-page content-without-subnavigation'>
      <Switch>
        <Route
          exact
          path={pathPrefix}
          render={() => {
            return <SettingsSelector />;
          }}
        />
        <Route exact path={`${pathPrefix}/bot`} component={BotSettings} />
        <Route exact path={`${pathPrefix}/import-export`} component={ImportExport} />
        <Route exact path={`${pathPrefix}/confirmation`} component={ConfirmDataset} />
      </Switch>
    </div>
  );
};

export default observer(SettingsPage);
