import { Expose, Type } from 'class-transformer';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { v4 } from 'uuid';
import NodeStore from '../../stores/NodeStore';
import { Condition, ISerializedCondition } from '../Conditions/Condition';
import { CONDITION_DISCRIMINATOR } from '../Conditions/Discriminator';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { BaseDialogNode } from '../DialogNodes/BaseDialogNode';
import { RedirectableNode } from '../DialogNodes/RedirectableNode';
import { TransformIntoDialogBlock } from '../Utilities/Deserialization/Decorators';

export class Redirect {
  @Expose()
  id: string;

  @Expose({ name: 'targetBlockId' })
  @TransformIntoDialogBlock
  targetBlock?: DialogBlock;

  @Expose()
  @Type(() => Condition, CONDITION_DISCRIMINATOR)
  conditions: Condition[];

  constructor(targetBlock?: DialogBlock, condition?: Condition[]) {
    this.id = v4();
    this.targetBlock = targetBlock;
    this.conditions = condition ? condition : [];

    makeObservable(this, {
      id: observable,
      conditions: observable,
      targetBlock: observable,
      targetNode: computed,
      isValid: computed,
      sourceNode: computed,
      addCondition: action,
      setTargetBlock: action,
      removeCondition: action,
      serialize: action,
    });

    // A dialog block is never empty. It has an invisible RootNode.
    // The only time it gets emptied is when the Block is deleted
    // Therefore any existing redirects to that block will be removed through this automated reaction.
    reaction(
      () => this.targetBlock?.isEmpty(),
      (isEmpty) => {
        // Checking if there is a source node is necessary when purging the entire application
        if (isEmpty && this.sourceNode) this.sourceNode.removeRedirect(this);
      }
    );
  }

  get isValid(): boolean {
    if (!this.targetBlock) {
      return false;
    }

    if (this.conditions.length === 0) {
      return true;
    }

    return this.conditions.every((condition) => condition.isValid);
  }

  get targetNode(): BaseDialogNode | undefined {
    if (!this.targetBlock || !this.isValid) {
      return undefined;
    }

    return this.targetBlock.rootDialogNode;
  }

  get sourceNode(): RedirectableNode {
    return NodeStore.getInstance().getByCondition(
      (node) => node.isRedirectable && (node as RedirectableNode).redirects.includes(this)
    ) as RedirectableNode;
  }

  setTargetBlock(newBlock: DialogBlock | null) {
    if (
      !this.sourceNode.isAnotherRedirectTargetingTheSameNodeAs(this) &&
      !this.sourceNode.dialogBlock.isAnotherRedirectNodeInBlockTargetingSameBlockAs(
        this.sourceNode
      )
    ) {
      this.targetNode && this.sourceNode.removeChild(this.targetNode);
      this.targetBlock && this.sourceNode.dialogBlock.removeChild(this.targetBlock);
    }

    if (!newBlock) {
      this.targetBlock = undefined;
      return;
    }

    this.targetBlock = newBlock;
    this.sourceNode.addChild(newBlock.rootNode!);
    this.sourceNode.dialogBlock.addChild(newBlock);
  }

  addCondition(condition: Condition) {
    this.conditions.push(condition);
  }

  removeCondition(condition: Condition) {
    this.conditions = this.conditions.filter((cond) => cond !== condition);
  }

  serialize(): ISerializedRedirect {
    return {
      id: this.id,
      targetBlockId: this.targetBlock?.id,
      targetNodeId: this.targetBlock?.rootNode?.id,
      conditions: this.conditions.map((condition) => condition.serialize()),
    };
  }
}

export interface ISerializedRedirect {
  id: string;
  targetBlockId?: string;
  targetNodeId?: string;
  conditions: ISerializedCondition[];
}
