import { makeObservable, observable } from 'mobx';
import {
  ChannelType,
  TriggerType,
} from '../../architecture/enums/ConversationHistoryType';
import { ISearchConversation } from '../../architecture/interfaces/HTTP/ConversationHistoryParams';

export class ConversationThread {
  channel: ChannelType;
  conversationThreadId: string;
  created: Date | string | null;
  eventId: string | null;
  reviewed: Date | string | null;
  score: number | null;
  sessionId: string;
  triggeredBlockId: string;
  triggeredBlockName: string;
  triggerType: TriggerType;
  userMessage: string | null;
  dialogVersion: number | null | undefined;
  costs: number | null;
  credits: number | null;
  botMessage: string | null;

  constructor(conversation: ISearchConversation) {
    this.channel = conversation.channel as ChannelType;
    this.dialogVersion = conversation.dialogVersion;
    this.conversationThreadId = conversation.conversationThreadId;
    this.created = conversation.created;
    this.eventId = conversation.eventId;
    this.reviewed = conversation.reviewed;
    this.score = conversation.score;
    this.sessionId = conversation.sessionId;
    this.triggeredBlockId = conversation.triggeredBlockId;
    this.triggeredBlockName = conversation.triggeredBlockName;
    this.triggerType = conversation.triggerType as TriggerType;
    this.userMessage = conversation.userMessage;
    this.costs = conversation.costs;
    this.credits = conversation.credits;
    this.botMessage = conversation.botMessage;

    makeObservable(this, {
      channel: observable,
      conversationThreadId: observable,
      created: observable,
      eventId: observable,
      reviewed: observable,
      score: observable,
      sessionId: observable,
      triggeredBlockId: observable,
      triggeredBlockName: observable,
      triggerType: observable,
      userMessage: observable,
      costs: observable,
      credits: observable,
      botMessage: observable,
    });
  }
}
