import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { BotAdminState } from '../../../architecture/enums/Admin';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import { TableData } from '../../../architecture/enums/TableData';
import { ICreateBotForm } from '../../../architecture/interfaces/admin/IBotForm';
import { ISubscriptionSettings } from '../../../architecture/interfaces/admin/ISubscriptionSettings';
import { Bot } from '../../../models/Admin/Bot';
import { SubscriptionConnector } from '../../../models/Connectors/admin/SubscriptionConnector';
import { Utilities } from '../../../models/Utilities/Utilities';
import Button from '../../common/Button';
import Modal from '../../common/modal/Modal';
import Table from '../../common/table/Table';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import LoadingPage from '../../layout/LoadingPage';
import CreateBot from './CreateBot';
import { HEADERS } from './constants';

interface IProps {
  showControls?: boolean;
}

const BotsList: React.FC<IProps> = ({ showControls = false }) => {
  const { botAdminStore, subscriptionAdminStore } = useAdminStores();
  const [openCreateBotModal, setOpenCreateBotModal] = useState(false);
  const [subscriptionSettings, setSubscriptionSettings] =
    useState<ISubscriptionSettings>();

  const getIcon = useGetIcon();
  const { showModal } = useModal();

  const loadSettings = async () => {
    const subscriptionId = subscriptionAdminStore.getSelectedSubscription?.id;
    if (!subscriptionId) return;

    const settings = await SubscriptionConnector.getSettings(subscriptionId);
    setSubscriptionSettings(settings);
  };

  useEffect(() => {
    botAdminStore.load();
    loadSettings();
  }, [subscriptionAdminStore.getSelectedSubscription]);

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: HEADERS.botName.id,
        Header: HEADERS.botName.label,
        accessor: (bot: TableData) => (bot as Bot).botName,
        minWidth: 150,
      },
      {
        id: HEADERS.creationDate.id,
        Header: HEADERS.creationDate.label,
        accessor: (bot: TableData) => (bot as Bot).creationDate,
      },
      {
        id: HEADERS.actions.id,
        Header: HEADERS.actions.label,
        width: subscriptionAdminStore.getSelectedSubscription ? 25 : 10,
        Cell: (item: Cell<Bot>) => {
          if (!showControls) return <></>;

          const bot = new Bot();
          bot.id = item.row.original.id;
          bot.botName = item.row.original.botName;

          return (
            <div className='actions'>
              <i
                className={`${getIcon('remove')} actions-icon`}
                onClick={() => {
                  showModal(ModalTypes.DeleteElement, bot);
                }}
              ></i>
            </div>
          );
        },
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    if (!botAdminStore.allBots) {
      return [];
    }
    return botAdminStore.allBots.map((bot: Bot) => {
      return {
        id: bot.id,
        botName: bot.botName || '',
        creationDate: bot.creationDate
          ? Utilities.getLocalDate(bot.creationDate!, 'datetime')
          : undefined,
      };
    });
  }, [botAdminStore.allBots]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  const handleRowClick = (bot: TableData) => {
    botAdminStore.setSelectedBot((bot as Bot).id);
  };

  const resetModal = () => {
    setOpenCreateBotModal(false);
  };

  const saveBot = async (bot: ICreateBotForm) => {
    await botAdminStore.create(bot);
    resetModal();
  };

  return (
    <div className='container-wrapper'>
      {botAdminStore.getState(BotAdminState.BotsLoading) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4 className='title-name'>
              {subscriptionAdminStore.getSelectedSubscription?.name}
            </h4>
            {showControls && (
              <div className='button-wrapper'>
                <Button
                  name='save-dialog-btn'
                  content='Add Bot'
                  className='btn-primary btn-round'
                  clickHandler={() => setOpenCreateBotModal(true)}
                />
              </div>
            )}
          </div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            rows={rows}
            selectedRow={botAdminStore.getSelectedBot as TableData}
            prepareRow={prepareRow}
            onRowClick={handleRowClick}
          />
          <Modal manuallyTriggered={openCreateBotModal} closeHandler={resetModal}>
            <CreateBot
              subscriptionSettings={subscriptionSettings}
              resetModal={resetModal}
              saveBot={saveBot}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(BotsList);
