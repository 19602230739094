import { IGetSubscriptionsResponse } from '../../../architecture/interfaces/HTTP/AdminParams';
import { ISubscriptionSettings } from '../../../architecture/interfaces/admin/ISubscriptionSettings';
import { HttpAgent } from '../../../utility/HttpAgent';
import { Subscription } from '../../Admin/Subscription';
import { Notification } from '../../Utilities/Notification';

export class SubscriptionConnector {
  static async get(): Promise<IGetSubscriptionsResponse> {
    return await HttpAgent.requests.get(`/Api/V2/Admin/Subscriptions`);
  }

  static async delete(subscriptionId: string): Promise<void> {
    return await HttpAgent.requests
      .delete(`/Api/V2/Admin/Subscriptions/${subscriptionId}`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async getSettings(subscriptionId: string): Promise<ISubscriptionSettings> {
    return await HttpAgent.requests.get(
      `/Api/V2/Admin/Subscriptions/${subscriptionId}/Settings`
    );
  }

  static async update(subscription: Subscription): Promise<void> {
    return await HttpAgent.requests
      .put(`/Api/V2/Admin/Subscriptions/${subscription.id}`, subscription)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          case 404:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async create(body: Subscription): Promise<Subscription> {
    return await HttpAgent.requests
      .post(`/Api/V2/Admin/Subscriptions`, { ...body, id: undefined })
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }
}
