import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import * as Yup from 'yup';
import { BotAdminState } from '../../../architecture/enums/Admin';
import { DialogLanguages } from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Switch from '../../common/inputElements/Switch';
import Select from '../../common/inputElements/select/Select';
import Modal from '../../common/modal/Modal';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';
import ConfirmSecretRefresh from './ConfirmSecretRefresh';

const validationSchema = Yup.object().shape({
  botName: Yup.string().required('This field is required'),
  threshold: Yup.number()
    .positive()
    .min(0, 'Minimum threshold value is 0')
    .max(100, 'Maximum threshold value is 100'),
  startDate: Yup.date().required('This field is required'),
  expiryDate: Yup.date().optional(),
  botLanguage: Yup.string().required('This field is required'),
  avatarTexture: Yup.string().optional(),
  textureActivated: Yup.boolean(),
});

const BotDetails: React.FC = () => {
  const { botAdminStore, subscriptionAdminStore } = useAdminStores();
  const [openConfirmSecretRefreshModal, setOpenConfirmSecretRefreshModal] =
    useState(false);
  const getIcon = useGetIcon();

  const selectedSubscription = subscriptionAdminStore.getSelectedSubscription;
  const selectedBot = botAdminStore.getSelectedBot;

  const noTextureAvailable =
    selectedBot?.avatarTexture === null ||
    selectedBot?.avatarTexture === undefined ||
    selectedBot.avatarTexture === '';

  const avatarTextureRepositorEmpty =
    botAdminStore.subscriptionSettings?.avatarTextures === undefined ||
    botAdminStore.subscriptionSettings?.avatarTextures.length === 0;

  const initialBotValues = {
    subscriptionName: selectedSubscription?.name,
    botName: selectedBot?.botName || '',
    startDate: selectedBot?.startDate || undefined,
    expiryDate: selectedBot?.expiryDate || undefined,
    botLanguage: selectedBot?.languageCode || '',
    botSecret: selectedBot?.botSecret || '',
    avatarTexture: selectedBot?.avatarTexture || '',
    avatarTextureActivated: !noTextureAvailable,
  };

  const formik = useFormik({
    initialValues: initialBotValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.avatarTextureActivated) {
        botAdminStore.update(selectedBot!.id, { ...values });
      } else {
        botAdminStore.update(selectedBot!.id, { ...values, avatarTexture: undefined });
      }
    },
  });

  const cancel = () => {
    botAdminStore.setSelectedBot(undefined);
  };

  const resetModal = () => {
    setOpenConfirmSecretRefreshModal(false);
  };

  useEffect(() => {
    formik.setValues(initialBotValues);
  }, [JSON.stringify(botAdminStore.getSelectedBot)]);

  return (
    <div className='details-wrapper'>
      {botAdminStore.getState(BotAdminState.SecretRefreshing) ? (
        <div className='loading'>
          <LoadingPage fullScreen={false} showText={false} />
        </div>
      ) : (
        <>
          <div className='header'>
            <h4>{selectedBot?.botName}</h4>
          </div>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className='body'>
              <div className='input-group'>
                <label htmlFor='bot-name'>Bot Name</label>
                <Input
                  id='bot-name'
                  value={formik.values.botName}
                  changeHandler={(value) => formik.setFieldValue('botName', value)}
                />
                {formik.touched.botName && formik.errors.botName ? (
                  <div className='validation-error'>{formik.errors.botName}</div>
                ) : null}
              </div>
              <div className='input-group'>
                <label htmlFor='bot-id'>BotID</label>
                <Input
                  id='bot-id'
                  disabled={true}
                  value={selectedBot?.id || ''}
                  changeHandler={() => {}}
                  actionIcon={getIcon('lock')}
                />
              </div>
              <div className='input-group'>
                <div className='input-grid'>
                  <div className='input-group'>
                    <label>Start Date</label>
                    <DateTimePicker
                      calendarIcon={
                        <i className={`${getIcon('caret_down')} date-icon`}> </i>
                      }
                      calendarClassName='date-filter'
                      value={formik.values.startDate}
                      disableClock={true}
                      format='dd.MM.yyyy HH:mm'
                      onChange={(date) => formik.setFieldValue('startDate', date)}
                    />
                    {formik.touched.startDate && formik.errors.startDate ? (
                      <div className='validation-error'>{formik.errors.startDate}</div>
                    ) : null}
                  </div>
                  <div className='input-group'>
                    <label>Expiry Date</label>
                    <DateTimePicker
                      calendarIcon={
                        <i className={`${getIcon('caret_down')} date-icon`}> </i>
                      }
                      calendarClassName='date-filter'
                      value={formik.values.expiryDate}
                      disableClock={true}
                      format='dd.MM.yyyy HH:mm'
                      onChange={(date) => formik.setFieldValue('expiryDate', date)}
                    />
                    {formik.touched.expiryDate && formik.errors.expiryDate ? (
                      <div className='validation-error'>{formik.errors.expiryDate}</div>
                    ) : null}
                  </div>
                  <div>
                    <label>Language</label>
                    <Select
                      id='bot-language'
                      triggerLabel={formik.values.botLanguage}
                      items={Object.entries(DialogLanguages).map(
                        ([lang, code]: [string, string]) => ({
                          title: code,
                          meta: lang,
                        })
                      )}
                      className='select-input'
                      disabled
                      selectHandler={(value) =>
                        formik.setFieldValue('botLanguage', value)
                      }
                      closeOnSelect
                      fullWidth
                    />
                    {formik.touched.botLanguage && formik.errors.botLanguage ? (
                      <div className='validation-error'>{formik.errors.botLanguage}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='input-group'>
                <label htmlFor='bot-secret'>Bot Secret</label>
                <Input
                  id='bot-secret'
                  disabled={true}
                  value={formik.values.botSecret}
                  changeHandler={(value) => formik.setFieldValue('botSecret', value)}
                  action={() => {
                    navigator.clipboard.writeText(formik.values.botSecret);
                  }}
                  secondaryAction={() => setOpenConfirmSecretRefreshModal(true)}
                  actionIcon={getIcon('copy')}
                  secondaryActionIcon={getIcon('rotate')}
                />
              </div>
              <div className='input-group'>
                <div className='select-input-grid'>
                  <div>
                    <label>Texture</label>
                    <Switch
                      value={formik.values.avatarTextureActivated}
                      clickHandler={() =>
                        formik.setFieldValue(
                          'avatarTextureActivated',
                          !formik.values.avatarTextureActivated
                        )
                      }
                      disabled={avatarTextureRepositorEmpty}
                    />
                  </div>
                  <div>
                    <label htmlFor='texture'></label>
                    <Select
                      id='texture'
                      triggerLabel={formik.values.avatarTexture}
                      items={
                        botAdminStore.subscriptionSettings?.avatarTextures.map(
                          (texture) => ({
                            title: texture.name,
                          })
                        ) ?? []
                      }
                      className='select-input'
                      selectHandler={(value) =>
                        formik.setFieldValue('avatarTexture', value)
                      }
                      closeOnSelect
                      disabled={
                        !formik.values.avatarTextureActivated ||
                        avatarTextureRepositorEmpty
                      }
                      fullWidth
                    />
                    {formik.touched.avatarTexture && formik.errors.avatarTexture ? (
                      <div className='validation-error'>
                        {formik.errors.avatarTexture}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='btn-container'>
              <Button
                content='Save changes'
                clickHandler={() => formik.handleSubmit()}
                className='btn-primary btn-round details-button'
              />
              <Button
                content='Cancel'
                className='btn-inactive btn-round'
                clickHandler={cancel}
              />
            </div>
          </form>
          <Modal
            manuallyTriggered={openConfirmSecretRefreshModal}
            closeHandler={resetModal}
          >
            <ConfirmSecretRefresh resetModal={resetModal} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default observer(BotDetails);
