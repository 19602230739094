import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import SinglePane from '../../common/paneNavigation/SinglePane';
import TwoPanes from '../../common/paneNavigation/TwoPanes';
import TwoPanesWithBackNavigation from '../../common/paneNavigation/TwoPanesWithBackNavigation';
import useAdminStores from '../../customHooks/useAdminStores';
import SubscriptionsList from '../subscriptions/SubscriptionsList';
import UserDetails from './UserDetails';
import UsersList from './UsersList';

const UsersPage: React.FC = () => {
  const { subscriptionAdminStore, userAdminStore } = useAdminStores();

  useEffect(() => {
    return () => {
      subscriptionAdminStore.setSelectedSubscription(undefined);
      userAdminStore.setSelectedUser(undefined);
    };
  }, []);

  const resizeSubscriptionsList = () => {
    userAdminStore.setSelectedUser(undefined);
    subscriptionAdminStore.setSelectedSubscription(undefined);
  };

  const showOnlySubscriptions = () => {
    return <SinglePane pane={<SubscriptionsList />} />;
  };

  const showSubscriptionsAndUsers = () => {
    return <TwoPanes leftPane={<SubscriptionsList />} rightPane={<UsersList />} />;
  };

  const showUsersAndDetails = () => {
    return (
      <TwoPanesWithBackNavigation
        buttonContent='Subscriptions'
        clickHandler={resizeSubscriptionsList}
        leftPane={<UsersList />}
        rightPane={<UserDetails />}
      />
    );
  };

  // When a subscription and a user are selected, display the user in the left pane and the details
  // in the right pane. Also, add a vertical navigation pane on the left.
  if (subscriptionAdminStore.getSelectedSubscription && userAdminStore.getSelectedUser) {
    return showUsersAndDetails();
  }

  // When a subscription is selected, show the subscription list in the left pane, and the user list
  // in the right pane.
  if (subscriptionAdminStore.getSelectedSubscription && !userAdminStore.getSelectedUser) {
    return showSubscriptionsAndUsers();
  }

  // If nothing is selected only show the subscription list.
  return showOnlySubscriptions();
};

export default observer(UsersPage);
