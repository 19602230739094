import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { Utilities } from '../../../../../Utilities/Utilities';
import { BaseEventReceiver, ISerializedEventReceiver } from '../../BaseEventReceiver';

export class GroupReceiver extends BaseEventReceiver {
  type = EventTargetReceiverTypes.Group;

  @Expose()
  groupName: string;

  constructor() {
    super();
    this.groupName = '';

    makeObservable(this, {
      groupName: observable,
      isValid: computed,
    });
  }

  get isValid() {
    return !Utilities.isEmpty(this.groupName);
  }

  get isChanged() {
    return !Utilities.isEmpty(this.groupName);
  }

  serialize(): ISerializedGroupEventReceiver {
    return {
      ...super.serialize(),
      groupName: this.groupName,
    };
  }
}

export interface ISerializedGroupEventReceiver extends ISerializedEventReceiver {
  groupName: string;
}
