import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DialogBlockTypes } from '../../../../architecture/enums/DialogComponentType';
import { TriggerTypes } from '../../../../architecture/enums/TriggerTypes';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../customHooks/useReadOnly';
import AiTrigger from '../AiTrigger';
import DefaultTrigger from '../DefaultTrigger';
import EventTrigger from '../event/EventTrigger';
import IntentTrigger from '../IntentTrigger';
import RegexInputTrigger from '../RegexInputTrigger';
import UserInputTrigger from '../UserInputTrigger';

interface TriggerConfigStageProps {
  block: DialogBlockModel;
}
const TriggerConfigStage: React.FC<TriggerConfigStageProps> = ({ block }) => {
  const getIcon = useGetIcon();
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const [triggerConfigDescription, setTriggerConfigDescription] = useState('');

  useEffect(() => {
    switch (block.trigger?.type) {
      case TriggerTypes.Default:
        setTriggerConfigDescription(
          "Set the fallback response of your bot when no other block is triggered. Choose between a predefined structured dialog flow or a more creative, AI-generated response. This setting ensures that users always receive a meaningful interaction, even if their input doesn't match any predefined triggers. Select the option that best suits your bot's purpose and desired user experience."
        );
        break;
      case TriggerTypes.UserInput:
        setTriggerConfigDescription(
          'Insert a few items in the list below which will trigger this block. The user input has to exactly match one of them. Case sensitivity is not considered.'
        );
        break;
      case TriggerTypes.RegexInput:
        setTriggerConfigDescription(
          'Insert a Regular Expression. If the user input satisfies the expression, this block will be triggered.'
        );
        break;
      case TriggerTypes.Intent:
        setTriggerConfigDescription(
          'Insert example sentences which define an intention. This trigger is based on machine learning. Thus, the more examples you give, the easier it is to abstract for the artificial intelligence and understand the underlying intent.'
        );
        break;
      case TriggerTypes.Event:
        setTriggerConfigDescription(
          'The Event Trigger consists the Event ID, a target platform and some optional parameters for Context Variables. The EventId is needed for the assignment of the corresponding event. When the event occurs, it can either trigger a broadcast message to all ongoing convesations, or it can trigger a conversation of a single user in the chosen platform.'
        );
        break;
      case TriggerTypes.AI:
        setTriggerConfigDescription(
          'Describe when this trigger should be executed. Please be as precise as possible and describe when the user should be directed to this part of the dialog.'
        );
        break;
      default:
        setTriggerConfigDescription('Configure your trigger!');
        break;
    }

    return () => setTriggerConfigDescription('');
  }, []);

  const renderTriggerConfig = () => {
    switch (block?.trigger?.type) {
      case TriggerTypes.Default:
        return <DefaultTrigger block={block} />;
      case TriggerTypes.UserInput:
        return <UserInputTrigger block={block} />;
      case TriggerTypes.RegexInput:
        return <RegexInputTrigger block={block} />;
      case TriggerTypes.Intent:
        return <IntentTrigger block={block} />;
      case TriggerTypes.Event:
        return <EventTrigger block={block} />;
      case TriggerTypes.AI:
        return <AiTrigger block={block} />;
      default:
        break;
    }
  };

  const handleRemove = () => {
    block.removeTrigger();
  };

  return (
    <div className='trigger-config-container'>
      <h2 className='trigger-config-container-title'>Set Up Your Trigger</h2>
      <p>{triggerConfigDescription}</p>
      <div className='trigger-config-container-body'>{renderTriggerConfig()}</div>
      {block.trigger &&
        !readOnly &&
        block.type !== DialogBlockTypes.DefaultDialogBlock && (
          <Button
            icon={getIcon('remove')}
            className='btn-remove btn-borderless'
            content='Remove Trigger'
            clickHandler={handleRemove}
          />
        )}
    </div>
  );
};

export default observer(TriggerConfigStage);
