import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { Redirect as RedirectModel } from '../../../../models/Redirects/Redirect';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import Redirect from './Redirect';

interface IProps {
  redirect: RedirectModel;
  noConditions: boolean;
  removeRedirectHandler: () => void;
}
const RedirectRow: React.FC<IProps> = ({
  redirect,
  noConditions,
  removeRedirectHandler,
}) => {
  const getIcon = useGetIcon();
  const history = useHistory();
  const { blockStore, dialogStore } = useContext(rootStore);

  return (
    <div className='redirect-main-container'>
      <div className='redirect-control-group'>
        {redirect.targetBlock && (
          <Button
            icon={getIcon('redirect')}
            className='btn-borderless'
            tooltip='Open selected block'
            tooltipPosition='left'
            clickHandler={() => {
              history.push(`/dialogs/${dialogStore?.currentlyEditedDialog?.dialogId}/blocks/${redirect?.targetBlock?.id}`);
              blockStore.selectBlock(redirect.targetBlock!);
            }}
          />
        )}
        <Button
          icon={getIcon('remove')}
          className='btn-remove btn-borderless btn-remove'
          tooltip='Remove redirect'
          tooltipPosition='left'
          clickHandler={removeRedirectHandler}
        />
      </div>
      <div className='redirect-settings-wrapper'>
        <Redirect redirect={redirect} noConditions={noConditions} />
      </div>
    </div>
  );
};

export default observer(RedirectRow);
