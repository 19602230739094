import { plainToInstance } from 'class-transformer';
import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { Admin, SubscriptionAdminState } from '../../architecture/enums/Admin';
import { MediaState } from '../../architecture/enums/MediaState';
import { SubscriptionState } from '../../architecture/enums/SubscriptionState';
import type { IGetSubscriptionsResponse } from '../../architecture/interfaces/HTTP/AdminParams';
import { Subscription } from '../../models/Admin/Subscription';
import { SubscriptionConnector } from '../../models/Connectors/admin/SubscriptionConnector';
import { ADMINS_CLASS_MAPPING } from '../../models/Utilities/Admin/Mappings';
import { RootStore } from '../rootStore';

class SubscriptionAdminStore {
  private static instance: SubscriptionAdminStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    SubscriptionAdminStore.instance = this;
  }

  @observable
  private _subscriptionRepository: Subscription[] = [];

  @observable
  private selectedSubscription: Subscription | undefined = undefined;

  @observable
  state: SubscriptionAdminState = SubscriptionAdminState.None;

  @computed
  getState(state: SubscriptionAdminState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: SubscriptionAdminState) {
    this.state |= state;
  }

  @computed
  removeState(state: SubscriptionAdminState) {
    this.state &= ~state;
  }

  @computed
  get allSubscriptions() {
    return this._subscriptionRepository;
  }

  @action
  setSelectedSubscription(subscriptionId?: string) {
    this.selectedSubscription = this._subscriptionRepository.find(
      (subscription) => subscription.id === subscriptionId
    );
  }

  @computed
  get getSelectedSubscription() {
    return this.selectedSubscription;
  }

  @action
  purge() {
    this._subscriptionRepository = [];
  }

  @action
  async load() {
    try {
      this.setState(SubscriptionAdminState.SubscriptionsLoading);
      const response = await SubscriptionConnector.get();
      runInAction(() => this.restoreSubscriptions(response));
      this.removeState(SubscriptionAdminState.SubscriptionsLoading);
    } catch (error) {
      this.removeState(SubscriptionAdminState.SubscriptionsLoading);
    }
  }

  @action
  search(search: string) {
    return this._subscriptionRepository.filter(
      (sub) =>
        sub.name.toLowerCase().includes(search.toLowerCase()) || sub.id!.includes(search)
    );
  }

  @action
  private restoreSubscriptions(response: IGetSubscriptionsResponse) {
    this.purge();

    response.subscriptions.forEach((subscription) => {
      const instance = plainToInstance(
        ADMINS_CLASS_MAPPING[Admin.Subscription],
        subscription
      );

      this._subscriptionRepository.push(instance);
    });
  }

  @action
  async create(item: Subscription) {
    try {
      this.setState(SubscriptionAdminState.SubscriptionsLoading);
      await SubscriptionConnector.create(item);
      this.load();
    } catch (error) {
      this.removeState(SubscriptionAdminState.SubscriptionsLoading);
    }
  }

  @action
  async remove(subscription: Subscription) {
    try {
      if (!subscription.id) return;
      if (subscription.id === this.getSelectedSubscription?.id) {
        this.setSelectedSubscription(undefined);
      }
      this.setState(SubscriptionAdminState.SubscriptionsLoading);
      await SubscriptionConnector.delete(subscription.id);
      this.rootStore.subscriptionStore.removeState(SubscriptionState.Initialized);
      this.load();
    } catch (error) {
      this.removeState(SubscriptionAdminState.SubscriptionsLoading);
    }
  }

  @action
  async update(subscription: Subscription) {
    try {
      this.setState(SubscriptionAdminState.SubscriptionsLoading);
      this.setSelectedSubscription(undefined);
      await SubscriptionConnector.update(subscription);
      this.rootStore.mediaStore.removeState(MediaState.Initialized);
      this.rootStore.subscriptionStore.removeState(SubscriptionState.Initialized);
      this.load();
    } catch (error) {
      this.removeState(SubscriptionAdminState.SubscriptionsLoading);
    }
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('SubscriptionAdminStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default SubscriptionAdminStore;
