import { Admin } from '../../../architecture/enums/Admin';
import { AvatarTexture } from '../../Admin/AvatarTexture';
import { BillingSummary } from '../../Admin/BillingSummary';
import { Bot } from '../../Admin/Bot';
import { DailyBillingSummary } from '../../Admin/DailyBillingSummary';
import { Payment } from '../../Admin/Payment';
import { Subscription } from '../../Admin/Subscription';
import { User } from '../../Admin/User';

export const ADMINS_CLASS_MAPPING = {
  [Admin.Subscription]: Subscription,
  [Admin.Bot]: Bot,
  [Admin.User]: User,
  [Admin.BillingSummary]: BillingSummary,
  [Admin.BillingDailySummary]: DailyBillingSummary,
  [Admin.CreditManagement]: Payment,
  [Admin.AvatarTexture]: AvatarTexture,
};
