import { observable, makeObservable } from 'mobx';
import { NodeHistoryElement } from './NodeHistoryElement';

export class AdaptiveCardNodeElement extends NodeHistoryElement {
  referenceId: string;

  constructor() {
    super();
    this.referenceId = '';

    makeObservable(this, {
      referenceId: observable,
    });
  }
}
