import { Expose, Transform } from 'class-transformer';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import type { IContextActions } from '../../../architecture/interfaces/contextVariables/IContextActions';
import {
  IContextFunction,
  IContextReassign,
} from '../../../architecture/interfaces/contextVariables/IContextActions';
import ContextVariableStore from '../../../stores/ContextVariableStore';
import { ContextVariable } from '../../ContextVariables/ContextVariable';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { ISerializedDialogNode } from '../BaseDialogNode';
import { ActionDialogNode } from './ActionDialogNode';

export class ContextActionNode extends ActionDialogNode {
  public static restoreContextActions(
    serializedActions: ISerializedContextActions
  ): IContextActions {
    const deserializedActions = {
      delete: serializedActions.delete
        .map((id) => ContextVariable.getFromStore(id)! as ContextVariable)
        .filter((ctx) => !!ctx),
      reassign: serializedActions.reassign
        .map((item) => ({
          ctx: ContextVariable.getFromStore(item.ctxId)! as ContextVariable,
          value: item.value,
        }))
        .filter((action) => !!(action as IContextReassign).ctx),
      function:
        serializedActions.function
          ?.map((item) => ({
            // nullable for backwards compatibility
            ctx: ContextVariable.getFromStore(item.ctxId)! as ContextVariable,
            equation: item.equation,
          }))
          .filter((action) => !!(action as IContextFunction).ctx) ?? [],
    };

    return deserializedActions;
  }

  public static restoreCreatedVariables(ctxIds?: string[]) {
    let array: ContextVariable[] = [];

    if (!ctxIds) return array;

    for (const id of ctxIds) {
      const ctx = ContextVariable.getFromStore(id) as ContextVariable;

      if (!ctx) continue;

      array.push(ctx);
    }

    return array;
  }

  type: DialogNodeTypes = DialogNodeTypes.ContextActionNode;

  @Expose()
  @Transform(({ value }) => ContextActionNode.restoreContextActions(value))
  contextActions: IContextActions;

  @Expose({ name: 'createdVariableIds' })
  @Transform(({ value }) => ContextActionNode.restoreCreatedVariables(value))
  createdVariables: ContextVariable[];

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Context Action Node';
    this.contextActions = { delete: [], reassign: [], function: [] };
    this.createdVariables = [];

    makeObservable(this, {
      contextActions: observable,
      createdVariables: observable,
      toBeDeletedContextVariables: computed,
      toBeReassignedContextVariables: computed,
      toBeCalculatedContextVariables: computed,
      reassignCtx: action,
      addFunctionAction: action,
      deleteCtxValue: action,
      cancelRemoval: action,
      cancelFunction: action,
      cancelReassignment: action,
      cancelAnyAction: action,
      createContextVariable: action,
      removeCreatedContextVariable: action,
    });

    // If a context variable is removed from the Store it will be automatically
    // removed from delete and reassign via this reaction
    reaction(
      () => ContextVariableStore.getInstance().userAndSystemVariables,
      () => {
        this.contextActions.delete = this.contextActions.delete.filter(
          (ctx) => ContextVariable.getFromStore(ctx.id) != undefined
        );

        this.contextActions.reassign = this.contextActions.reassign.filter(
          (item) => ContextVariable.getFromStore(item.ctx.id) != undefined
        );

        this.contextActions.function = this.contextActions.function.filter(
          (item) => ContextVariable.getFromStore(item.ctx.id) != undefined
        );

        this.createdVariables = this.createdVariables.filter(
          (item) => ContextVariable.getFromStore(item.id) != undefined
        );
      }
    );
  }

  get isValid(): boolean {
    return true;
  }

  get toBeDeletedContextVariables() {
    return this.contextActions.delete;
  }

  get toBeReassignedContextVariables() {
    return this.contextActions.reassign.map((item) => item.ctx);
  }

  get toBeCalculatedContextVariables() {
    return this.contextActions.function.map((item) => item.ctx);
  }

  createContextVariable(name: string) {
    if (!name) return;

    const ctx = ContextVariableStore.getInstance().addIfNotExists(name)!;
    if (!ctx) return;

    if (!this.createdVariables.find((item) => item.id === ctx.id)) {
      this.createdVariables.unshift(ctx);
    }

    return ctx;
  }

  removeCreatedContextVariable(ctx: ContextVariable) {
    this.createdVariables = this.createdVariables.filter(variable => variable.id !== ctx.id);
    if(!ctx.isUsed){
      ContextVariableStore.getInstance().remove(ctx);
    }
  }

  deleteCtxValue(variable: ContextVariable) {
    this.contextActions.delete.push(variable);
  }

  cancelRemoval(variable: ContextVariable) {
    this.contextActions.delete = this.contextActions.delete.filter(
      (ctx) => ContextVariable.getFromStore(ctx.id) != variable
    );
  }

  reassignCtx(ctx: ContextVariable, value: any) {
    const existingItem = this.contextActions.reassign.find((item) => item.ctx === ctx);

    if (existingItem) {
      this.contextActions.reassign = this.contextActions.reassign.map((item) =>
        item.ctx === existingItem.ctx ? ({ ctx, value } as IContextReassign) : item
      );

      return;
    }

    this.contextActions.reassign.push({ ctx, value });
  }

  addFunctionAction(ctx: ContextVariable, equation: string) {
    const existingItem = this.contextActions.function.find((item) => item.ctx === ctx);

    if (existingItem) {
      this.contextActions.function = this.contextActions.function.map((item) =>
        item.ctx === existingItem.ctx ? ({ ctx, equation } as IContextFunction) : item
      );

      return;
    }

    this.contextActions.function.push({ ctx, equation });
  }

  cancelReassignment(variable: ContextVariable) {
    this.contextActions.reassign = this.contextActions.reassign.filter(
      (item) => ContextVariable.getFromStore(item.ctx.id) != variable
    );
  }

  cancelFunction(variable: ContextVariable) {
    this.contextActions.function = this.contextActions.function.filter(
      (item) => ContextVariable.getFromStore(item.ctx.id) != variable
    );
  }

  cancelAnyAction(ctx: ContextVariable) {
    this.cancelReassignment(ctx);
    this.cancelRemoval(ctx);
    this.cancelFunction(ctx);
  }

  getReassignedCtxDetails(variable: ContextVariable) {
    return this.contextActions.reassign.find((item) => item.ctx === variable);
  }

  getFunctionCtxDetails(variable: ContextVariable) {
    return this.contextActions.function.find((item) => item.ctx === variable);
  }

  serialize(): ISerializedContextActionNode {
    return {
      ...super.serialize(),
      contextActions: this.serializeContextActions(),
      createdVariableIds: this.createdVariables.map((item) => item.id),
    };
  }

  private serializeContextActions(): ISerializedContextActions {
    return {
      delete: this.serializeDeletes(),
      reassign: this.serializeReassigns(),
      function: this.serializeFunctions(),
    };
  }

  private serializeDeletes() {
    return this.contextActions.delete.map((ctx) => ctx.id);
  }

  private serializeReassigns(): ISerializedReassignAction[] {
    return this.contextActions.reassign.map((item) => ({
      ctxId: item.ctx.id,
      value: item.value,
    }));
  }

  private serializeFunctions(): ISerializedFunctionAction[] {
    return this.contextActions.function.map((item) => ({
      ctxId: item.ctx.id,
      equation: item.equation,
    }));
  }
}

export interface ISerializedContextActionNode extends ISerializedDialogNode {
  contextActions: ISerializedContextActions;
  createdVariableIds: string[];
}

export interface ISerializedReassignAction {
  ctxId: string;
  value: any;
}

export interface ISerializedFunctionAction {
  ctxId: string;
  equation: string;
}

export interface ISerializedContextActions {
  delete: string[];
  reassign: ISerializedReassignAction[];
  function: ISerializedFunctionAction[];
}
