import { Expose } from 'class-transformer';
import { PathElementTypes } from '../../../architecture/interfaces/IPathElement';
import { PathElement } from './PathElement';

export class ListElement extends PathElement {
  type = PathElementTypes.ListElement;

  @Expose()
  nestedProperties?: (string | number)[];
  constructor(key: string | number | null, nestedProperties?: (string | number)[]) {
    super(key);
    this.nestedProperties = nestedProperties;
  }
}
