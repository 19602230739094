import React, { useRef, Fragment, useState } from 'react';
import { useGetIcon } from '../customHooks/useGetIcon';

interface IProps {
  fixed?: boolean;
  rounded?: boolean;
  direction?: 'up' | 'down';
  noMargin?: boolean;
}

const FloatingButtonGroup: React.FC<IProps> = (props) => {
  // Ref Hook
  const btnGroupListRef = useRef<HTMLUListElement>(null);

  const [fixatedOpen, setFixatedOpen] = useState(false);
  // Custom Hook
  const layerIcon = useGetIcon()('layer');

  // Custom Functions
  const triggerBtnGroup = () => {
    btnGroupListRef.current!.classList.add('active');
  };

  const closeBtnGroup = () => {
    if (!fixatedOpen) btnGroupListRef.current!.classList.remove('active');
  };

  // Processing the children
  const childrenAsListItems = React.Children.map(props.children, (child, index) => (
    <li
      key={index}
      className={`floating-btn-group-list-item ${!props.noMargin ? 'with-margin' : ''}`}
      style={{ transitionDelay: `${index * 50 * -1}ms` }}
    >
      {child}
    </li>
  ));

  const createFloatingBtnGroupClassList = () => {
    let classes = ['floating-btn-group'];
    if (props.fixed) {
      classes.push('fixed');
    }

    if (props.rounded) {
      classes.push('rounded');
    }

    props.direction ? classes.push(props.direction) : classes.push('up');

    return classes.join(' ');
  };

  const fixateOpen = () => {
    setFixatedOpen((prev) => !prev);
  };

  return (
    <div className={createFloatingBtnGroupClassList()}>
      <ul
        className={`floating-btn-group-list ${fixatedOpen ? 'active' : ''}`}
        ref={btnGroupListRef}
        onMouseEnter={triggerBtnGroup}
        onMouseLeave={closeBtnGroup}
      >
        {!props.direction || props.direction === 'up' ? (
          <Fragment>
            {childrenAsListItems}
            <li className='floating-btn-group-trigger' onClick={fixateOpen}>
              <i className={layerIcon}></i>
            </li>
          </Fragment>
        ) : (
          <Fragment>
            <li className='floating-btn-group-trigger' onClick={fixateOpen}>
              <i className={layerIcon}></i>
            </li>
            {childrenAsListItems}
          </Fragment>
        )}
      </ul>
    </div>
  );
};

export default FloatingButtonGroup;
