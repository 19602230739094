import { action, computed, makeAutoObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../architecture/enums/DialogComponentType';
import { DialogBlock } from '../models/DialogBlocks/DialogBlock';
import { BaseDialogNode } from '../models/DialogNodes/BaseDialogNode';
import { RootNode } from '../models/DialogNodes/RootNode';
import { InvalidOperationError } from '../models/errors/InvalidOperationError';
import { RootStore } from './rootStore';

class NodeStore {
  @observable
  private _nodeRepository: BaseDialogNode[] = [];

  public static instance: NodeStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    NodeStore.instance = this;
  }

  @observable
  selectedParentNode: BaseDialogNode | null = null;

  @computed
  get allNodes() {
    return this._nodeRepository;
  }

  /** Returns the nodes that belong to the selected block. Will be triggered on each selectedBlockId change or changes in the node repository */
  @computed
  get selectedNodes() {
    const nodes = this.allNodes.filter(
      (node) => node.dialogBlock! === this.rootStore.blockStore.selectedBlock
    );
    return this.sortNodesByChildren(nodes);
  }

  @computed
  getKnowledgebaseNode() {
    return this._nodeRepository.find(
      (node) => node.type === DialogNodeTypes.KnowledgebaseNode
    );
  }

  @computed
  get renderableSelectedNodes() {
    return this.selectedNodes.filter((node) => !(node instanceof RootNode));
  }

  @computed
  get areNodesValid() {
    return this.invalidNodes.length === 0;
  }

  @computed
  get invalidNodes() {
    return this._nodeRepository.filter((node) => !node.isValid);
  }

  /**
   * This function restores a dialog node during deserialization. This function must NOT be used
   * for adding a new dialog node. For that, use append() or insertAfter().
   *
   * @param restoreNode The node which should be restored.
   */
  @action
  restore(restoreNode: BaseDialogNode) {
    if (this._nodeRepository.includes(restoreNode)) {
      throw new InvalidOperationError('Node already exists in repository');
    }

    this._nodeRepository.push(restoreNode);
  }

  @action
  append(node: BaseDialogNode) {
    node.dialogBlock.addDialogNode(node);
    this._nodeRepository.push(node);
    this.selectedParentNode = null;
  }

  @action
  insertAfter(node: BaseDialogNode, parentNode: BaseDialogNode) {
    node.dialogBlock.addDialogNode(node, parentNode);
    this._nodeRepository.push(node);
    this.selectedParentNode = null;
  }

  @action
  getById(nodeId: string) {
    return this.allNodes.find((node) => node.id === nodeId);
  }

  @action
  remove(node: BaseDialogNode) {
    node.remove();
    this.removeNodeFromRepository(node);
  }

  @action
  removeAllNodesOfBlock(block: DialogBlock) {
    block.nodes
      .filter((node) => node.type !== DialogNodeTypes.RootNode)
      .forEach((node) => this.remove(node));
  }

  @action
  getByCondition(filterCallback: (dialogComponent: BaseDialogNode) => boolean) {
    return this._nodeRepository.find(filterCallback);
  }

  /**
   * Sorts the nodes of the selected block according to their parent-child relationship
   * @param blockId ID of the selected block
   */
  @action
  sortNodesByChildren(nodes: BaseDialogNode[]): BaseDialogNode[] {
    const rootNode = nodes.find((node) => node.isBlockRoot);
    let currentNode = rootNode;
    let sortedNodeList: BaseDialogNode[] = [];

    while (currentNode) {
      if (currentNode) {
        sortedNodeList.push(currentNode!);
        currentNode = currentNode.getBlockChild();
      }
    }

    return sortedNodeList;
  }

  @action
  purge() {
    this._nodeRepository = [];
    this.selectedParentNode = null;
  }

  private removeNodeFromRepository(node: BaseDialogNode) {
    const index = this._nodeRepository.findIndex((dialogNode) => dialogNode === node);
    this._nodeRepository.splice(index, 1);
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('NodeStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default NodeStore;
