import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { EventTargetReceiverTypes } from '../../../../../../architecture/enums/EventTrigger';
import { TelegramEventTarget as TelegramEventTargetModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/TelegramEventTarget';
import Select from '../../../../../common/inputElements/select/Select';
import { useReadOnly } from '../../../../../customHooks/useReadOnly';

import { GroupReceiver as GroupReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/GroupReceiver';
import { UserReceiver as UserReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/UserReceiver';
import Receiver from './Receiver';

interface IProps {
  target: TelegramEventTargetModel;
}

const TelegramEventTarget: React.FC<IProps> = ({ target }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const handleSelectReceiver = (name: string) => {
    switch (name) {
      case EventTargetReceiverTypes.User:
        target.receiver = new UserReceiverModel();
        break;
      case EventTargetReceiverTypes.Group:
        target.receiver = new GroupReceiverModel();
        break;
    }
  };

  const receiverLabel = (() => {
    if (target.receiver instanceof UserReceiverModel) return 'User';
    if (target.receiver instanceof GroupReceiverModel) return 'Group';

    return 'Select a receiver';
  })();

  return (
    <Fragment>
      <h3>Receiver Type</h3>
      <Select
        disabled={readOnly}
        items={target.supportedReceivers.map((item) => ({
          title: item,
          id: item,
        }))}
        triggerLabel={receiverLabel}
        selectHandler={handleSelectReceiver}
        closeOnSelect
        fullWidth
      />

      <Receiver receiver={target.receiver} />
    </Fragment>
  );
};

export default observer(TelegramEventTarget);
