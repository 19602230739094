import { AccountInfo } from '@azure/msal-browser';
import { createContext } from 'react';
import { Role } from '../../architecture/enums/Roles';

export interface IAuthContextDefinition extends IAuthStateDefinition {
  login: () => void;
  logout: () => void;
  signUp: () => void;
  updateUserRoles: (roles: string[]) => void;
  newUserOnboarded: () => Promise<void>;
}

export interface IAuthStateDefinition {
  user: AccountInfo | null;
  authenticated: boolean;
  loading: boolean;
  userRoles: Role[];
  newUser?: boolean;
  username: string;
}

const AuthContext = createContext<IAuthContextDefinition>({
  user: null,
  authenticated: false,
  loading: false,
  userRoles: [],
  login: (): void => {},
  signUp: (): void => {},
  logout: (): void => {},
  newUserOnboarded: async (): Promise<void> => {},
  updateUserRoles: (roles: string[]): void => {},
  newUser: undefined,
  username: '',
});

export default AuthContext;
