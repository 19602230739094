import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import * as Yup from 'yup';
import {
  DatasetStatus,
  PropertyState,
  PropertyType,
} from '../../../../architecture/enums/Dataset';
import { Dataset } from '../../../../models/Dataset/Dataset';
import { DatasetListProperty } from '../../../../models/Dataset/Property/DatasetListProperty';
import { DatasetProperty } from '../../../../models/Dataset/Property/DatasetProperty';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import Input from '../../../common/Input';
import Select from '../../../common/inputElements/select/Select';

const validationSchema = Yup.object().shape({
  key: Yup.string().required('This field is required'),
  annotation: Yup.string().required('This field is required'),
  dataset: Yup.string().when('type', {
    is: (value: PropertyType) =>
      (value && value === PropertyType.ListDataset) || value === PropertyType.Dataset,
    then: Yup.string().required('This field is required'),
  }),
});

interface IProps {
  property: any | null;
  resetModal: () => void;
  dataset: Dataset | null;
  title: string;
}

const ManageProperty: React.FC<IProps> = ({ resetModal, property, dataset, title }) => {
  const { datasetStore } = useContext(rootStore);

  const formik = useFormik({
    initialValues: {
      id: property?.id || null,
      key: property?.key || '',
      state: property?.state || PropertyState.Added,
      type: property?.type || PropertyType.Simple,
      annotation: property?.annotation || '',
      datasetId: property?.datasetId || '',
      dataset:
        property instanceof DatasetProperty || property instanceof DatasetListProperty
          ? property?.dataset
          : undefined,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (property && values.id) {
        dataset?.editProperty(values);
      } else {
        dataset?.addProperty(values);
      }
      resetModal();
    },
  });

  return (
    <div className='modal-default dataset-modal'>
      <div className='header'>
        <h3>{title}</h3>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='name'>Property Title:</label>
            <Input
              id='key'
              value={formik.values.key}
              placeholder='Type property title here …'
              changeHandler={(value) => formik.setFieldValue('key', value)}
              disabled={dataset?.status === DatasetStatus.Used}
            />
            {formik.touched.key && formik.errors.key ? (
              <div className='validation-error'>{formik.errors.key}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <div className='input-grid'>
              <div className='input-group'>
                <label htmlFor='company-name'>Type</label>
                <Select
                  id='type'
                  triggerLabel={formik.values.type}
                  items={Object.values(PropertyType).map((operator) => ({
                    title: operator,
                  }))}
                  className='select-input'
                  selectHandler={(value) => {
                    formik.setFieldValue('type', value);
                    if (value === PropertyType.Simple) {
                      formik.setFieldValue('dataset', undefined);
                    }
                  }}
                  disabled={dataset?.status === DatasetStatus.Used}
                  closeOnSelect
                  fullWidth
                />
              </div>
              <div className='input-group'>
                <label htmlFor='nested-dataset'>Nested Dataset</label>
                <Select
                  id='dataset'
                  triggerLabel={formik.values.dataset || ''}
                  items={datasetStore.allDatasets
                    .filter(
                      (dataset) => dataset.id !== datasetStore.getSelectedDataset?.id
                    )
                    .map((dataset) => ({
                      title: dataset.name,
                      id: dataset.id,
                    }))}
                  disabled={
                    formik.values.type === PropertyType.Simple ||
                    dataset?.status === DatasetStatus.Used
                  }
                  className='select-input'
                  selectHandler={(value) => {
                    formik.setFieldValue('dataset', value);
                    formik.setFieldValue(
                      'datasetId',
                      datasetStore.allDatasets.find((d) => d.name === value)?.id
                    );
                  }}
                  closeOnSelect
                  fullWidth
                />
                {formik.touched.dataset && formik.errors.dataset ? (
                  <div className='validation-error'>{formik.errors.dataset}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='input-group'>
            <label htmlFor='annotation'>Annotation</label>
            <Input
              id='bot-name'
              value={formik.values.annotation}
              placeholder='Type property annotation here …'
              changeHandler={(value) => formik.setFieldValue('annotation', value)}
            />
            {formik.touched.annotation && formik.errors.annotation ? (
              <div className='validation-error'>{formik.errors.annotation}</div>
            ) : null}
          </div>
        </div>
        <div className='btn-container'>
          <Button type='submit' content='Done' className='btn-primary btn-round' />
        </div>
      </form>
    </div>
  );
};

export default observer(ManageProperty);
