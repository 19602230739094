import React from 'react';

interface IProps {
  position: 'top' | 'side';
}

const BubbleContainer: React.FC<IProps> = ({ position, children }) => {
  return <div className={`bubble-container bubble-position-${position}`}>{children}</div>;
};

export default BubbleContainer;
