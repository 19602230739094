import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { EmailActionNodeElement as EmailActionNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/EmailActionNodeElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: EmailActionNodeElementModel;
}

const EmailActionNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);

  return (
    <BaseHistoryElement
      title='Email sent'
      icon={getIcon('envelope')}
      element={element}
      openAdditionalInfo={openAdditionalInfo}
      setOpenAdditionalInfo={setOpenAdditionalInfo}>
      <div className='card-paragraph'>
        <i className={getIcon('envelope_full')}></i>
        <span> To <i> {element.to} </i> with subject "{element.subject}"</span>
      </div>

      {openAdditionalInfo &&
        <div className='card-paragraph'>
          <span className='card-paragraph-email-text'>{element.message}</span>
        </div>
      }
    </BaseHistoryElement>
  );
};

export default observer(EmailActionNodeElement);
