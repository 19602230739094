import { Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { ListContextVariable } from '../../../ContextVariables/ContextVariable';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import {
  TransformIntoContextVariable,
  TransformIntoTextArray,
} from '../../../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../../../Utilities/Text';
import { Utilities } from '../../../Utilities/Utilities';
import { ISerializedSingleQuestionNode, SingleQuestionNode } from '../SingleQuestionNode';

export class ChoiceQuestionNode extends SingleQuestionNode {
  @Expose()
  @TransformIntoTextArray
  choices: Text[];

  @Expose({ name: 'listContextVariableId' })
  @TransformIntoContextVariable
  listContextVariable?: ListContextVariable;

  type = DialogNodeTypes.ChoiceQuestionNode;

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Multiple Choice Question Node';
    this.choices = [];

    makeObservable(this, {
      choices: observable,
      listContextVariable: observable,
      hasChoices: computed,
      addChoice: action,
      removeChoice: action,
    });
  }

  get isValid() {
    return super.isValid && this.hasChoices;
  }

  get hasChoices() {
    return !Utilities.isEmpty(this.choices) || !!this.listContextVariable;
  }

  addChoice(text: string): void {
    if (Utilities.isEmpty(text)) return;

    this.choices = [...this.choices, new Text(text)];
  }

  removeChoice(id: string): void {
    this.choices = this.choices.filter((choice) => choice.id !== id);
  }

  serialize(): ISerializedChoiceQuestionNode {
    return {
      ...super.serialize(),
      choices: this.choices.map((choice) => choice.serialize()),
      listContextVariableId: this.listContextVariable?.id,
    };
  }
}

export interface ISerializedChoiceQuestionNode extends ISerializedSingleQuestionNode {
  choices: ISerializedText[];
  listContextVariableId?: string;
}
