import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import * as Yup from 'yup';
import { Subscription } from '../../../models/Admin/Subscription';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Switch from '../../common/inputElements/Switch';

interface IProps {
  saveSubscription: (subscription: Subscription) => void;
  resetModal: () => void;
}

const validationSchema = Yup.object({
  subscriptionName: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  phoneNumber: Yup.string().required('This field is required'),
});

const CreateSubscription: React.FC<IProps> = ({ saveSubscription, resetModal }) => {
  const formik = useFormik({
    initialValues: {
      subscriptionName: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      companyName: '',
      receiverName: '',
      street: '',
      postalCode: '',
      city: '',
      country: '',
      isReseller: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveSubscription(Subscription.fromForm(values));
      resetModal();
    },
  });

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Create Subscription </h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='subscription-name'>Subscription Name:</label>
            <Input
              id='subscription-name'
              placeholder='Name your Subscription'
              value={formik.values.subscriptionName}
              changeHandler={(value) => formik.setFieldValue('subscriptionName', value)}
            />
            {formik.touched.subscriptionName && formik.errors.subscriptionName ? (
              <div className='validation-error'>{formik.errors.subscriptionName}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label className='subtitle'>Contact Person:</label>
            <div className='input-grid'>
              <div>
                <label htmlFor='first-name'>First Name</label>
                <Input
                  id='first-name'
                  value={formik.values.firstName}
                  placeholder='First Name'
                  changeHandler={(value) => formik.setFieldValue('firstName', value)}
                />
                {formik.touched.firstName && formik.errors.firstName ? (
                  <div className='validation-error'>{formik.errors.firstName}</div>
                ) : null}
              </div>
              <div>
                <label htmlFor='last-name'>Last Name</label>
                <Input
                  id='last-name'
                  value={formik.values.lastName}
                  placeholder='Last Name'
                  changeHandler={(value) => formik.setFieldValue('lastName', value)}
                />
                {formik.touched.lastName && formik.errors.lastName ? (
                  <div className='validation-error'>{formik.errors.lastName}</div>
                ) : null}
              </div>
              <div>
                <label htmlFor='phone-number'>Phone Number</label>
                <Input
                  id='phone-number'
                  value={formik.values.phoneNumber}
                  placeholder='Phone Number'
                  changeHandler={(value) => formik.setFieldValue('phoneNumber', value)}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                  <div className='validation-error'>{formik.errors.phoneNumber}</div>
                ) : null}
              </div>
              <div className='switch'>
                <Switch
                  value={formik.values.isReseller}
                  clickHandler={() =>
                    formik.setFieldValue('isReseller', !formik.values.isReseller)
                  }
                />
                <div className='switch-label'>Subscription Reseller:</div>
              </div>
            </div>
          </div>
          <div className='input-group'>
            <label className='subtitle'>Billing Adress (optional):</label>
            <div className='input-grid'>
              <div>
                <label htmlFor='company-name'>Company Name</label>
                <Input
                  id='company-name'
                  value={formik.values.companyName}
                  placeholder='Company Name'
                  changeHandler={(value) => formik.setFieldValue('companyName', value)}
                />
              </div>
              <div>
                <label htmlFor='receiver-name'>Receiver Name</label>
                <Input
                  id='receiver-name'
                  value={formik.values.receiverName}
                  placeholder='Receiver Name'
                  changeHandler={(value) => formik.setFieldValue('receiverName', value)}
                />
              </div>
            </div>
            <div className='input-grid'>
              <div>
                <label htmlFor='street'>Street</label>
                <Input
                  id='street'
                  value={formik.values.street}
                  placeholder='Street'
                  changeHandler={(value) => formik.setFieldValue('street', value)}
                />
              </div>
            </div>
            <div className='input-grid'>
              <div>
                <label htmlFor='postal-code'>Postal Code</label>
                <Input
                  id='postal-code'
                  value={formik.values.postalCode}
                  placeholder='Postal Code'
                  changeHandler={(value) => formik.setFieldValue('postalCode', value)}
                />
              </div>
              <div>
                <label htmlFor='city'>City</label>
                <Input
                  id='city'
                  value={formik.values.city}
                  placeholder='City'
                  changeHandler={(value) => formik.setFieldValue('city', value)}
                />
              </div>
              <div>
                <label htmlFor='country'>Country</label>
                <Input
                  id='country'
                  value={formik.values.country}
                  placeholder='Country'
                  changeHandler={(value) => formik.setFieldValue('country', value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <Button
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={resetModal}
          />
          <Button
            content='Add Subscription'
            className='btn-primary btn-round'
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

export default observer(CreateSubscription);
