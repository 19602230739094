import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DialogState } from '../../architecture/enums/DialogState';
import { Bot } from '../../models/Bot/Bot';
import AuthContext from '../../ms_authentication/context/AuthContext';
import rootStore from '../../stores/rootStore';
import { HttpAgent } from '../../utility/HttpAgent';
import Accordion from '../common/Accordion';
import EmptyPage from '../common/EmptyPage';
import Modal from '../common/modal/Modal';
import BotDetails from '../dialogElements/dialogOverview/BotDetails';
import DialogTable from '../dialogElements/dialogOverview/DialogTable';
import LoadingPage from './LoadingPage';

interface IModalState {
  open: boolean;
  bot: Bot | null;
}

const DialogOverview = () => {
  const { dialogStore, datasetStore, mediaStore, subscriptionStore } =
    useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);

  const [modalState, setModalState] = useState<IModalState>({
    open: false,
    bot: null,
  });

  const load = async () => {
    await Promise.all([dialogStore.loadBots(), datasetStore.load(), mediaStore.load()]);
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading, subscriptionStore.selectedSubscription?.id]);

  return (
    <>
      {dialogStore.getState(DialogState.BotsLoading) ? (
        <LoadingPage />
      ) : (
        <Fragment>
          {dialogStore.getState(DialogState.Initialized) && (
            <>
              {dialogStore.bots.length > 0 ? (
                <>
                  <div className='dialog-overview content-without-subnavigation'>
                    <div className='dialog-accordion'>
                      {dialogStore.bots.map((bot) => (
                        <Accordion
                          key={bot.botId}
                          title={bot.name}
                          handleInfoBtnClick={() => setModalState({ bot, open: true })}
                        >
                          <DialogTable bot={bot} />
                        </Accordion>
                      ))}
                    </div>
                  </div>

                  <Modal
                    manuallyTriggered={modalState.open}
                    closeHandler={() => setModalState({ open: false, bot: null })}
                  >
                    <BotDetails bot={modalState.bot!} />
                  </Modal>
                </>
              ) : (
                <EmptyPage />
              )}
            </>
          )}
        </Fragment>
      )}
    </>
  );
};

export default observer(DialogOverview);
