import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { IContextReassign } from '../../../../../../architecture/interfaces/contextVariables/IContextActions';
import TextBoxWithCtx from '../../../../../common/inputElements/TextBoxWithCtx';

interface IProps {
  item: IContextReassign;
}

const Reassignment: React.FC<IProps> = ({ item }) => {
  return (
    <Fragment>
      <h4 className='context-action-editor-title'>
        Reassign the value of {item.ctx.name ?? ''}
      </h4>
      <p>
        You can reassign the value of your context variable to whatever you find useful
        when the dialog reaches this node.
      </p>
      <TextBoxWithCtx
        multiline
        value={item.value}
        saveHandler={(text) => (item.value = text)}
      />
    </Fragment>
  );
};

export default observer(Reassignment);
