import { Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import {
  DatasetNodeType,
  DialogNodeTypes,
} from '../../../architecture/enums/DialogComponentType';
import type { IContextVariable } from '../../../architecture/interfaces/contextVariables/IContextVariable';
import { IPropertyValue } from '../../../architecture/interfaces/dataset/IPropertyValue';
import { Dataset } from '../../Dataset/Dataset';
import { PropertyValue } from '../../Dataset/PropertyValue';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import {
  TransformIntoContextVariable,
  TransformIntoDataset,
} from '../../Utilities/Deserialization/Decorators';
import { ISerializedDialogNode } from '../BaseDialogNode';
import { ActionDialogNode } from './ActionDialogNode';

export class DatasetActionNode extends ActionDialogNode {
  type: DialogNodeTypes = DialogNodeTypes.DatasetActionNode;

  @Expose({ name: 'contextVariableId' })
  @TransformIntoContextVariable
  contextVariable?: IContextVariable;

  @Expose({ name: 'datasetId' })
  @TransformIntoDataset
  dataset?: Dataset;

  @Expose()
  @Type(() => PropertyValue)
  properties: PropertyValue[] = [];

  @Expose()
  nodeType: DatasetNodeType = DatasetNodeType.Undefined;

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Dataset Action Node';

    makeObservable(this, {
      contextVariable: observable,
      nodeType: observable,
      dataset: observable,
      properties: observable,
    });
  }

  get isValid(): boolean {
    return true;
  }

  serialize(): ISerializedDatasetActionNode {
    return {
      ...super.serialize(),
      properties: this.properties.map((value: PropertyValue) => value.serialize()),
      datasetId: this.dataset?.id,
      contextVariableId: this.contextVariable?.id,
      nodeType: this.nodeType,
    };
  }
}

export interface ISerializedDatasetActionNode extends ISerializedDialogNode {
  properties: IPropertyValue[];
  contextVariableId?: string;
  datasetId?: string;
  nodeType: string;
}
