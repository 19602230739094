import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionCardCondition as ActionCardConditionModel } from '../../../../../models/Conditions/ActionCardCondition';

interface IProps {
  condition: ActionCardConditionModel;
}
/**
 * NOT USED AT THE MOMENT
 * @param param0 Condition of ActionCardCondition model
 * @returns An observer JSX Element
 */
const ActionCardCondition: React.FC<IProps> = ({ condition }) => {
  return <div>{condition.actionId}</div>;
};

export default observer(ActionCardCondition);
