import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Button from '../../common/Button';
import rootStore from '../../../stores/rootStore';

interface IProps {
  id: string;
  image?: string | null;
  setSelectedImage: (image: string | null) => void;
}

const ImageField: React.FC<IProps> = ({ id, image, setSelectedImage }) => {
  const { mediaStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const imageFile = mediaStore.allMediaItems.find((item) => item.mediaId === image)?.url;

  return (
    <div id={id} className='image-wrapper'>
      {imageFile && (
        <img
          src={imageFile}
          width='100%'
          height='100%'
          style={{ objectFit: 'cover', borderRadius: '6px' }}
        />
      )}

      <Button
        type='button'
        icon={getIcon('search')}
        className='btn-primary btn-circle image-button'
        clickHandler={() => setSelectedImage(id)}
      />
    </div>
  );
};

export default observer(ImageField);
