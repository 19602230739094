import {
  ICreateUserRequest,
  ICreateUserResponse,
  IExistingUser,
  IResetUserPasswordResponse,
  IUpdateUserRequest,
  IUser,
} from '../../../architecture/interfaces/HTTP/AdminParams';
import { HttpAgent } from '../../../utility/HttpAgent';
import { Notification } from '../../Utilities/Notification';

export class UserConnector {
  static async getExistingUsers(): Promise<IExistingUser[]> {
    return await HttpAgent.requests.get(`/Api/V2/Admin/Users`).catch((error) => {
      let text = null;
      switch (error.response.status) {
        case 400:
          text = 'Bad request';
          break;
        default:
          break;
      }
      if (text) {
        new Notification({ text: text, type: 'error' });
      }
    });
  }

  static async get(subscriptionId: string): Promise<IUser[]> {
    return await HttpAgent.requests
      .get(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Users`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'No subscription id given';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async delete(subscriptionId: string, userId: string): Promise<void> {
    return await HttpAgent.requests
      .delete(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Users/${userId}`)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async create(
    subscriptionId: string,
    body: ICreateUserRequest
  ): Promise<ICreateUserResponse> {
    return await HttpAgent.requests
      .post(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Users`, body)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async update(
    subscriptionId: string,
    userId: string,
    body: IUpdateUserRequest
  ): Promise<ICreateUserResponse> {
    return await HttpAgent.requests
      .put(`/Api/V2/Admin/Subscriptions/${subscriptionId}/Users/${userId}`, body)
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }

  static async resetPassword(
    subscriptionId: string,
    userId: string
  ): Promise<IResetUserPasswordResponse> {
    return await HttpAgent.requests
      .post(
        `/Api/V2/Admin/Subscriptions/${subscriptionId}/Users/${userId}/Password/Reset`,
        {}
      )
      .catch((error) => {
        let text = null;
        switch (error.response.status) {
          case 400:
            text = 'Bad request';
            break;
          default:
            break;
        }
        if (text) {
          new Notification({ text: text, type: 'error' });
        }
      });
  }
}
