import { observer } from 'mobx-react-lite';
import React, { useContext, useState, useRef } from 'react';
import { StorageActionNode as StorageActionNodeModel } from '../../../../../models/DialogNodes/ActionNodes/StorageActionNode';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import DialogNode from '../../DialogNode';
import { ContextVariableInstanceTypes } from '../../../../../architecture/enums/ContextVariableInstanceTypes';
import rootStore from '../../../../../stores/rootStore';
import Checkbox from '../../../../common/Checkbox';
import Switch from '../../../../common/inputElements/Switch';

interface IProps {
  dialogNode: StorageActionNodeModel;
}

const StorageActionNode: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);
  const [error, setError] = useState(false);

  const forbiddenNames = useRef<string[]>([
    ...ctxVarStore.allVariables.map((item) => item.name),
  ]);

  const validateInput = (input: string) => {
    const containsForbiddenName = forbiddenNames.current.includes(input);
    setError(containsForbiddenName);
    return containsForbiddenName;
  };

  const handleCtxSelection = (value: string) => {
    if (error) {
      return;
    }

    dialogNode.contextVariable = ctxVarStore.getByName(value);
  };

  return (
    <DialogNode dialogNode={dialogNode as StorageActionNodeModel}>
      <div>
        The following <span className='bold'>Dataset Variable</span> will be stored or
        updated in a permanent storage:
      </div>
      <br />
      <ContextVariableSelector
        items={ctxVarStore.datasetVariables}
        triggerLabel={dialogNode.contextVariable?.name ?? 'Add or select'}
        selectHandler={handleCtxSelection}
        inputValidator={validateInput}
        replaceSpecialCharacters
        closeOnSelect
      />
      <br />
      <Switch
        key='override'
        value={dialogNode.override}
        clickHandler={() => (dialogNode.override = !dialogNode.override)}
        label={'Override previously saved Dataset Variable on each execution'}
      />
    </DialogNode>
  );
};

export default observer(StorageActionNode);
