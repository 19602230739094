import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import SubscriptionStore from '../../stores/SubscriptionStore';
import { ProxyConnector } from '../Connectors/ProxyConnector';

export class Text {
  id: string;
  value: string;

  constructor(value: string, id?: string) {
    this.id = id ? id : v4();
    this.value = value;

    makeObservable(this, {
      value: observable,
    });
  }

  async getAudio(language: string): Promise<string | undefined> {
    try {
      const subscriptionId = SubscriptionStore.getInstance().selectedSubscription?.id;
      if (!subscriptionId) return;

      const response = await ProxyConnector.getAudio(
        subscriptionId,
        this.value,
        language
      );

      return response.audio;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  serialize(): ISerializedText {
    return {
      value: this.value,
    };
  }
}

export interface ISerializedText {
  value?: string;
}
