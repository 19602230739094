import { observer } from 'mobx-react-lite';
import React from 'react';
import Card from '../../common/cardMenu/Card';
import CardContainer from '../../common/cardMenu/CardContainer';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {}

const SettingsSelector: React.FC<IProps> = ({}) => {
  const getIcon = useGetIcon();

  return (
    <div className='selector-page'>
      <CardContainer>
        <Card
          label='Switch Subscription'
          path='/settings/subscriptions'
          icon={getIcon('exchange')}
        />
        <Card
          label='Pronunciation'
          path={'/settings/pronunciation'}
          icon={getIcon('sound')}
        />
        <Card label='Update Profile' path='/settings/profile' icon={getIcon('user')} />
        <Card label='Change Password' path='/settings/password' icon={getIcon('lock')} />
      </CardContainer>
    </div>
  );
};

export default observer(SettingsSelector);
