import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ISelectItem } from '../../../../architecture/interfaces/select/ISelectItem';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  item: ISelectItem;
  isActive: boolean;
  isChosen: boolean;
  clickHandler: (item: ISelectItem) => void;
}
const SelectItem: React.FC<IProps> = ({ item, isActive, isChosen, clickHandler }) => {
  const handleClick = () => {
    clickHandler(item);
  };

  const {
    uiStore: { colors },
  } = useContext(rootStore);

  const getIcon = useGetIcon();

  return (
    <div
      className='select-item'
      data-active={isActive}
      data-chosen={isChosen}
      onClick={handleClick}
    >
      <div className='select-item-title-wrapper'>
        {isChosen && (
          <i className={`select-item-icon ${getIcon('check')}`} data-type='check'></i>
        )}
        {item.settings?.icon !== undefined && (
          <i
            className={`select-item-icon ${item.settings.icon}`}
            style={{
              color:
                item.settings?.color !== undefined ? item.settings.color : colors.text,
            }}
          ></i>
        )}
        <p
          className='select-item-title'
          style={{
            color: item.settings?.color !== undefined ? item.settings.color : colors.text,
          }}
        >
          {item.title}
        </p>
      </div>
      {item.meta && <p className='select-item-meta'>{item.meta}</p>}
    </div>
  );
};

export default observer(SelectItem);
