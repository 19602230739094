import React, { useEffect, useState } from 'react';

interface IProps {
  badge?: number;
  text: string;
  isActive: boolean;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}
const Tab: React.FC<IProps> = ({
  badge,
  text,
  isActive,
  disabled,
  hidden = false,
  onClick,
}) => {
  const [badgeCount, setBadgeCount] = useState(badge);

  useEffect(() => {
    setBadgeCount(badge);
  }, [badge]);

  const clickHandler = () => {
    if (isActive || disabled) return;

    onClick();
  };

  const getBadge = () => {
    if (badgeCount && badgeCount > 0) return <span className='badge'>{badgeCount}</span>;
  };

  return (
    <div className={`tab-button ${isActive ? 'active' : ''}`} onClick={clickHandler}>
      {text}
      {getBadge()}
    </div>
  );
};

export default Tab;
