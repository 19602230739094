import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  CHANNEL_OPTIONS,
  HistoryElementFilter,
} from '../../../architecture/constants/ConversationHistory';
import {
  AuxiliaryHistoryElementTypes,
  InitiatingHistoryElementTypes,
  NodeHistoryElementTypes,
} from '../../../architecture/enums/HistoryElementType';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import Switch from '../../common/inputElements/Switch';
import { useCreateHistoryElement } from '../../customHooks/useCreateHistoryElement';
import { useGetIcon } from '../../customHooks/useGetIcon';
import LoadingPage from '../../layout/LoadingPage';

interface IParamsProps {
  dialogId?: string;
  conversationThreadId?: string;
}

const HistoryElement: React.FC = () => {
  const { conversationHistoryStore, dialogStore } = useContext(rootStore);
  const params: IParamsProps = useParams();
  const getIcon = useGetIcon();
  const history = useHistory();
  const { createHistoryElement } = useCreateHistoryElement();
  const [historyElements, setHistoryElements] = useState(
    conversationHistoryStore.historyElements
  );

  const load = async () => {
    if (params.conversationThreadId) {
      await conversationHistoryStore.getHistoryElements(params.conversationThreadId);
    }
  };

  const updateConversationReviewState = async (reviewed: boolean) => {
    if (params && params.conversationThreadId) {
      await conversationHistoryStore.updateConversationReviewState(
        params.conversationThreadId,
        reviewed
      );
    }
  };

  const setConversationAsReviewed = (reviewed: boolean) => {
    updateConversationReviewState(reviewed);
  };

  useEffect(() => {
    setHistoryElements(conversationHistoryStore.historyElements);

    return () => {
      conversationHistoryStore.setHistoryElementFilter(
        HistoryElementFilter.ShowAllElements
      );
    };
  }, [conversationHistoryStore.historyElements, conversationHistoryStore]);

  useEffect(() => {
    if (dialogStore.currentlyEditedDialog) {
      load();
    }
  }, [dialogStore.currentlyEditedDialog]);

  const showIncomingOutgoingMessages = () => {
    conversationHistoryStore.setHistoryElementFilter(
      HistoryElementFilter.ShowMessagesOnly
    );
    setHistoryElements(
      conversationHistoryStore.historyElements.filter(
        (element) =>
          element.type === InitiatingHistoryElementTypes.IncomingMessage ||
          element.type === NodeHistoryElementTypes.MessageNode ||
          element.type === NodeHistoryElementTypes.PromptNode ||
          element.type === NodeHistoryElementTypes.KnowledgebaseNode ||
          element.type === AuxiliaryHistoryElementTypes.ValidationResult
      )
    );
  };

  const showAllHistoryElements = () => {
    conversationHistoryStore.setHistoryElementFilter(
      HistoryElementFilter.ShowAllElements
    );
    setHistoryElements(conversationHistoryStore.historyElements);
  };

  return (
    <div id='history-element-container'>
      {conversationHistoryStore.isLoading || !conversationHistoryStore.historyElements ? (
        <LoadingPage />
      ) : (
        <>
          {conversationHistoryStore.historyElements.length > 0 && (
            <>
              <div className='history-element-header'>
                <div className='container-header'>
                  Conversation via
                  <span className='message-wrapper'>
                    {
                      CHANNEL_OPTIONS.find(
                        (option) =>
                          option.label ===
                          conversationHistoryStore.conversationDetails.channel
                      )?.title
                    }
                  </span>
                  started on{' '}
                  {conversationHistoryStore.conversationDetails.from &&
                    format(
                      new Date(conversationHistoryStore.conversationDetails.from),
                      'dd.MM.yyyy HH:mm:ss'
                    )}
                </div>
                <div className='history-header-buttons'>
                  <Button
                    className='btn-borderless btn-history-header'
                    tooltip={'Go to Conversation History Block Overview Page'}
                    tooltipPosition='top'
                    icon={getIcon('network')}
                    clickHandler={() => {
                      const dialogId = dialogStore?.currentlyEditedDialog?.dialogId;
                      return history.push(
                        `/dialogs/${dialogId}/history/${params.conversationThreadId}/designer`
                      );
                    }}
                  />
                  <Button
                    className='btn-borderless btn-history-header'
                    data-reviewed={
                      !!conversationHistoryStore.conversationDetails.reviewed
                        ? 'true'
                        : 'false'
                    }
                    tooltip={
                      !!conversationHistoryStore.conversationDetails.reviewed
                        ? 'Click to mark this conversation\nas not reviewed'
                        : 'Click to mark this conversation\nas reviewed'
                    }
                    tooltipPosition='top'
                    icon={
                      conversationHistoryStore.conversationDetails.reviewed
                        ? getIcon('eye')
                        : getIcon('eye_closed')
                    }
                    clickHandler={() => {
                      if (!!conversationHistoryStore.conversationDetails.reviewed) {
                        setConversationAsReviewed(false);
                      } else {
                        setConversationAsReviewed(true);
                      }
                    }}
                  />
                </div>
              </div>
              <div className='history-element-subheader'>
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowAllElements && (
                  <div className='history-element-filter'>
                    <Switch
                      value={false}
                      clickHandler={() => {
                        showIncomingOutgoingMessages();
                        return true;
                      }}
                    />
                    <span>Show only Messages</span>
                  </div>
                )}
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowMessagesOnly && (
                  <div className='history-element-filter'>
                    <Switch
                      value={true}
                      clickHandler={() => {
                        showAllHistoryElements();
                        return false;
                      }}
                    />
                    <span>Show all Elements</span>
                  </div>
                )}
              </div>

              {historyElements.map((element) => createHistoryElement(element))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default observer(HistoryElement);
