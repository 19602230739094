import React from 'react';

import { Notification as NotificationModel } from '../../models/Utilities/Notification';
import Loading from '../common/Loading';

interface IProps {
  alert: NotificationModel;
}
const Notification: React.FC<IProps> = ({ alert: { id, text, type, autoRemove } }) => {
  return (
    <div className={`alert ${type} ${autoRemove ? '' : 'persist'}`}>
      {!autoRemove && <Loading fullScreen={false} />}
      {text}
    </div>
  );
};

export default Notification;
