import { format } from 'date-fns';
import { action, makeAutoObservable, observable } from 'mobx';
import { STORED_DIALOG_STRUCTURE } from '../architecture/constants/LocalStorage';
import { IStoredDialog } from '../architecture/interfaces/localStorage/IDialog';
import { RootStore } from './rootStore';

// Important information:
// One core functionality of this Store is to handle Dialog backups which are
// periodically saved every few seconds (auto-save). After the first tests with
// this feature, we discovered that the topic is more complex than expected.
// The handling of the pure dialog structure was working fine, but e.g. the
// IntentStore had to be handled seperatly and also other planned features
// (e.g. Knowledgebase, Datasets) would probably need a separate handling.
// Thus, we decided to postpone this topic.
// If at any point we decide to not implement the auto-save at all, this file
// and all corresponding changes should be reverted. For a better overview,
// see Pull Request 1025 (15.09.2022) and 1037 (28.09.2022).
export default class LocalStorageStore {
  private static instance: LocalStorageStore;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);

    LocalStorageStore.instance = this;

    this.restoreStorage();
  }

  @observable
  autoSavedDialogs: IStoredDialog[] = [];

  @action
  backupUnsavedDialog() {
    if (
      this.rootStore.dialogStore.currentlyEditedDialog?.dialogId &&
      this.rootStore.appStore.hasEditorStructureChanged
    ) {
      this.updateAutoSavedDialogRepository();
      this.saveAutoSavedDialogsToStorage();
    }
  }

  @action
  getStoredDialog(dialogId: string): IStoredDialog | undefined {
    return this.autoSavedDialogs.find((dialog) => dialog.dialogId === dialogId);
  }

  @action
  removeStoredDialog(dialogId: string) {
    if (!dialogId) {
      return;
    }

    this.autoSavedDialogs = this.autoSavedDialogs.filter(
      (dialog) => dialog.dialogId !== dialogId
    );

    this.saveAutoSavedDialogsToStorage();
  }

  @action
  private updateAutoSavedDialogRepository() {
    const currentDialog = this.rootStore.dialogStore.currentlyEditedDialog;
    if (!currentDialog) {
      return;
    }

    const dialogToStore: IStoredDialog = {
      dialogId: currentDialog.dialogId,
      modified: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS"),
      structure: this.rootStore.serializationStore.serialize(),
    };

    this.autoSavedDialogs = this.autoSavedDialogs.filter(
      (dialog) => dialog.dialogId !== currentDialog.dialogId
    );

    this.autoSavedDialogs.push(dialogToStore);
  }

  private saveAutoSavedDialogsToStorage() {
    localStorage.setItem(STORED_DIALOG_STRUCTURE, JSON.stringify(this.autoSavedDialogs));
  }

  private restoreStorage() {
    this.autoSavedDialogs = JSON.parse(
      localStorage.getItem(STORED_DIALOG_STRUCTURE) || '[]'
    );
  }
}
