import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { FILTERS, TYPE_OPTIONS } from '../../../architecture/constants/Media';
import FilterOptionsList from '../../common/FilterOptionsList';
import SearchInput from './SearchInput';

interface IProps {
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
  searchParams?: any;
  hideMediaTypeFilter?: boolean;
}

const Filters: React.FC<IProps> = ({
  searchParams,
  handleParamsChange,
  clearParamsChange,
  hideMediaTypeFilter = false,
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const filtersGroup = () => {
    return (
      <div className='filters-group'>
        <FilterOptionsList
          searchParams={searchParams}
          name={FILTERS.TYPE.NAME}
          title={FILTERS.TYPE.TITLE}
          className={params[FILTERS.TYPE.NAME] ? 'filter-active' : ''}
          options={TYPE_OPTIONS}
          handleParamsChange={handleParamsChange}
          clearParamsChange={clearParamsChange}
        />
      </div>
    );
  };

  return (
    <div className='filters-container'>
      <SearchInput
        clearParamsChange={clearParamsChange}
        handleParamsChange={handleParamsChange}
      />
      {!hideMediaTypeFilter && filtersGroup()}
    </div>
  );
};

export default observer(Filters);
