import { makeObservable, observable } from 'mobx';
import { Expose } from 'class-transformer';
import { TransformIntoContextVariable } from '../../Utilities/Deserialization/Decorators';
import { ContextVariable } from '../../ContextVariables/ContextVariable';

export class ContextVariableMap {
  @Expose()
  entityName: string;

  @Expose({ name: 'contextVariableId' })
  @TransformIntoContextVariable
  contextVariable: ContextVariable | null;

  constructor(entityName: string = '', contextVariable: ContextVariable | null = null) {
    this.entityName = entityName;
    this.contextVariable = contextVariable;

    makeObservable(this, {
      entityName: observable,
      contextVariable: observable,
    });
  }

  serialize() {
    return {
      entityName: this.entityName,
      contextVariableId: this.contextVariable?.id
    }
  }
}

export interface ISerializedContextVariableMap {
  entityName: string;
  contextVariableId: string | undefined;
}