export const HEADERS = {
  subscriptionName: {
    label: 'Subscription Name',
    id: 'subscriptionName',
  },
  creationDate: {
    label: 'Creation Date',
    id: 'creationDate',
  },
  actions: {
    label: '',
    id: 'actions'
  }
};
