import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useState } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';
import { EventParameter } from '../../../../models/Triggers/EventTrigger/EventParameter';
import { EventTrigger as EventTriggerModel } from '../../../../models/Triggers/EventTrigger/EventTrigger';
import Switch from '../../../common/inputElements/Switch';
import TextInputWithDelete from '../../../common/inputElements/TextInputWithDelete';
import useModal from '../../../customHooks/useModal';
import { useReadOnly } from '../../../customHooks/useReadOnly';
import EventTargetContainer from './eventTargets/EventTargetContainer';
import Parameters from './parameters/Parameters';

interface IProps {
  block: DialogBlockModel;
}

const EventTrigger: React.FC<IProps> = ({ block }) => {
  const trigger = block.trigger as EventTriggerModel;
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const { setPreventClosing } = useModal();

  const [isDynamic, setIsDyamic] = useState<boolean>(trigger.isDynamic);

  useEffect(() => {
    return () => {
      if (!trigger.isValid) {
        block.removeTrigger();
        setPreventClosing(false);
      }
    };
  }, []);

  useEffect(() => {
    trigger.isDynamic = isDynamic;
    if (!trigger.isValid && trigger.isChanged) {
      setPreventClosing(true, 'Some input is not valid');
    } else {
      setPreventClosing(false);
    }
  }, [JSON.stringify(trigger), isDynamic]);

  const handleEventIdDelete = () => {
    trigger.eventId = '';
  };

  const updateEventId = (value: string) => {
    trigger.eventId = value;
  };

  const addEventParameter = () => {
    trigger.addParameter(new EventParameter());
  };

  const removeEventParameter = (parameter: EventParameter) => {
    trigger.removeParameter(parameter);
  };

  return (
    <Fragment>
      <h3>Event Id (Guid)</h3>
      <TextInputWithDelete
        disabled={readOnly}
        value={trigger.eventId}
        blurHandler={updateEventId}
        controlled
        placeholder='Enter Event ID'
        deleteHandler={handleEventIdDelete}
      />

      <h3>Dynamic Event</h3>
      <p>
        Depending on the capabilities of the platform, Events can either be defined for
        specific users, groups, channels, or they can be defined as dynamic. Dynamic
        events can be addressed to all known users of a bot. Users, channels and groups
        can be called using username, group name, etc., just as with static events.
        Additionally, dynamic Events can be called with a conversation reference
        independend from the communication channel. For that, the System Context Variable{' '}
        <b>#ConversationReference</b> can be used to give 3rd party services an easy
        callback method.
      </p>
      <Switch
        key={'isDynamicSwitch'}
        value={isDynamic}
        disabled={readOnly}
        clickHandler={() => setIsDyamic((prev) => !prev)}
        label={isDynamic ? 'Event is defined as dynamic' : 'Event is defined as static'}
      />

      {!isDynamic && (
        <EventTargetContainer
          target={trigger.target}
          setTarget={(newModel) => (trigger.target = newModel)}
        />
      )}

      <Parameters
        mapping={trigger.contextMapping}
        addNewParamHandler={addEventParameter}
        removeParamHandler={removeEventParameter}
      />
    </Fragment>
  );
};

export default observer(EventTrigger);
