import React, { CSSProperties, Fragment, useContext } from 'react';
import rootStore from '../../stores/rootStore';
import { useGetIcon } from '../customHooks/useGetIcon';
import Button from './Button';

interface IProps {
  type: 'warning' | 'error' | 'info';
  tooltip?: string;
  style?: CSSProperties;
  tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
}
const InfoIcon: React.FC<IProps> = ({
  type,
  style,
  tooltip,
  tooltipPosition = 'top',
}) => {
  const { uiStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const baseIconStyle: CSSProperties = {
    cursor: 'help',
    fontSize: '1.6rem',
    ...style,
  };

  const errorIconStyle: CSSProperties = {
    ...baseIconStyle,
    color: 'red',
  };

  const warningIconStyle: CSSProperties = {
    ...baseIconStyle,
    color: uiStore.colors.secondary,
  };

  const infoIconStyle: CSSProperties = {
    ...baseIconStyle,
    color: 'dodgerblue',
  };

  return (
    <Fragment>
      {type === 'info' ? (
        <Button
          className={'btn-borderless block-info-icon'}
          icon={getIcon('info')}
          style={infoIconStyle}
          tooltipPosition={tooltipPosition}
          tooltip={tooltip}
          clickHandler={(e) => {
            e.preventDefault();
          }}
        />
      ) : (
        <Button
          className={'btn-borderless block-info-icon'}
          icon={getIcon('error')}
          style={type === 'error' ? errorIconStyle : warningIconStyle}
          tooltipPosition={tooltipPosition}
          tooltip={tooltip}
          clickHandler={(e) => {
            e.preventDefault();
          }}
        />
      )}
    </Fragment>
  );
};

export default InfoIcon;
