import { observer } from 'mobx-react-lite';
import React, { ReactNode } from 'react';
import VerticalNavigationButton from './VerticalNavigationButton';

interface IProps {
  leftPane: ReactNode;
  rightPane: ReactNode;
  firstButtonContent: string;
  firstButtonClick: () => void;
  secondButtonContent: string;
  secondButtonClick: () => void;
}

const TwoPanesWithTwoNavigations = ({
  leftPane,
  rightPane,
  firstButtonContent,
  firstButtonClick,
  secondButtonContent,
  secondButtonClick,
}: IProps) => {
  return (
    <div className={'two-panes-with-two-navs'}>
      <VerticalNavigationButton
        clikHandler={firstButtonClick}
        content={firstButtonContent}
      />
      {leftPane}
      {
        <VerticalNavigationButton
          clikHandler={secondButtonClick}
          content={secondButtonContent}
        />
      }
      {rightPane}
    </div>
  );
};

export default observer(TwoPanesWithTwoNavigations);
