import React, { useContext } from 'react';
import {
  AuxiliaryHistoryElementTypes,
  InitiatingHistoryElementTypes,
  NodeHistoryElementTypes,
  TriggerHistoryElementTypes,
  ValidationTypes,
} from '../../architecture/enums/HistoryElementType';
import rootStore from '../../stores/rootStore';

import { AuxiliaryHistoryElement as AuxiliaryHistoryElementModel } from '../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/AuxiliaryHistoryElement';
import { ContextVariableUpdateElement as ContextVariableUpdateElementModel } from '../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ContextVariableUpdateElement';
import { SystemMessageElement as SystemMessageElementModel } from '../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/SystemMessageElement';
import { ValidationResultElement as ValidationResultElementModel } from '../../models/ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ValidationResultElement';
import { BaseHistoryElement as BaseHistoryElementModel } from '../../models/ConversationHistory/HistoryElement/BaseHistoryElement';

import { IncomingActionCardElement as IncomingActionCardElementModel } from '../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingActionCardElement';
import { IncomingEventElement as IncomingEventElementModel } from '../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingEventElement';
import { IncomingMessageElement as IncomingMessageElementModel } from '../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingMessageElement';
import { InitiatingHistoryElement as InitiatingHistoryElementModel } from '../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/InitiatingHistoryElement';

import { AdaptiveCardNodeElement as AdaptiveCardNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/AdaptiveCardNodeElement';
import { ApiNodeElement as ApiNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/ApiNodeElement';
import { ContextActionNodeElement as ContextActionNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/ContextActionNodeElement';
import { EmailActionNodeElement as EmailActionNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/EmailActionNodeElement';
import { KnowledgebaseNodeElement as KnowledgebaseNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/KnowledgebaseNodeElement';
import { MessageNodeElement as MessageNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/MessageNodeElement';
import { NodeHistoryElement as NodeHistoryElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/NodeHistoryElement';
import { PromptNodeElement as PromptNodeElementModel } from '../../models/ConversationHistory/HistoryElement/NodeHistoryElement/PromptNodeElement';

import { AiTriggerElement as AiTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/AiTriggerElement';
import { DefaultTriggerElement as DefaultTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/DefaultTriggerElement';
import { EventTriggerElement as EventTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/EventTriggerElement';
import { KnowledgebaseTriggerElement as KnowledgebaseTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/KnowledgebaseTriggerElement';
import { ListTriggerElement as ListTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/ListTriggerElement';
import { LuisIntentTriggerElement as LuisIntentTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/LuisIntentTriggerElement';
import { RegexTriggerElement as RegexTriggerElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/RegexTriggerElement';
import { TriggerHistoryElement as TriggerHistoryElementModel } from '../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/TriggerHistoryElement';
import DefaultTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/DefaultTriggerElement';
import EventTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/EventTriggerElement';
import KnowledgebaseTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/KnowledgebaseTriggerElement';
import ListTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/ListTriggerElement';
import LuisIntentTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/LuisIntentTriggerElement';
import RegexTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/RegexTriggerElement';

import IncomingActionCardElement from '../conversationHistory/historyElement/InitiatingHistoryElement/IncomingActionCardElement';
import IncomingEventElement from '../conversationHistory/historyElement/InitiatingHistoryElement/IncomingEventElement';
import IncomingMessageElement from '../conversationHistory/historyElement/InitiatingHistoryElement/IncomingMessageElement';

import AdaptiveCardNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/AdaptiveCardNodeElement';
import ApiNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/ApiNodeElement';
import ContextActionNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/ContextActionNodeElement';
import EmailActionNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/EmailActionNodeElement';
import KnowledgebaseNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/KnowledgebaseNodeElement';
import MessageNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/MessageNodeElement';
import PromptNodeElement from '../conversationHistory/historyElement/NodeHistoryElement/PromptNodeElement';

import ContextVariableUpdateElement from '../conversationHistory/historyElement/AuxiliaryHistoryElement/ContextVariableUpdate/ContextVariableUpdateElement';
import SystemMessageElement from '../conversationHistory/historyElement/AuxiliaryHistoryElement/SystemMessageElement';
import ValidationResultElement from '../conversationHistory/historyElement/AuxiliaryHistoryElement/ValidationResultElement';

import { HistoryElementFilter } from '../../architecture/constants/ConversationHistory';
import AiTriggerElement from '../conversationHistory/historyElement/TriggerHistoryElement/AiTriggerElement';

export function useCreateHistoryElement() {
  const { conversationHistoryStore } = useContext(rootStore);

  const _createAuxiliaryHistoryElement = (element: AuxiliaryHistoryElementModel) => {
    switch (element.type) {
      case AuxiliaryHistoryElementTypes.ValidationResult:
        switch ((element as ValidationResultElementModel).validation) {
          case ValidationTypes.Valid:
            return (
              <IncomingMessageElement element={element as IncomingMessageElementModel} />
            );
          case ValidationTypes.Invalid:
            return (
              <>
                <IncomingMessageElement
                  element={element as IncomingMessageElementModel}
                />
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowAllElements && (
                  <ValidationResultElement
                    element={element as ValidationResultElementModel}
                  />
                )}
              </>
            );
          case ValidationTypes.TooManyAttempts:
            return (
              <>
                <IncomingMessageElement
                  element={element as IncomingMessageElementModel}
                />
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowAllElements && (
                  <ValidationResultElement
                    element={element as ValidationResultElementModel}
                  />
                )}
              </>
            );
          case ValidationTypes.CancelDialog:
            return (
              <>
                <IncomingMessageElement
                  element={element as IncomingMessageElementModel}
                />
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowAllElements && (
                  <ValidationResultElement
                    element={element as ValidationResultElementModel}
                  />
                )}
              </>
            );
          case ValidationTypes.CancelledByActionCard:
            return (
              <>
                <IncomingMessageElement
                  element={element as IncomingMessageElementModel}
                />
                {conversationHistoryStore.selectedHistoryElementFilter ===
                  HistoryElementFilter.ShowAllElements && (
                  <ValidationResultElement
                    element={element as ValidationResultElementModel}
                  />
                )}
              </>
            );
          case ValidationTypes.RepromptForSubquestion:
            return (
              <>
                <IncomingMessageElement
                  element={element as IncomingMessageElementModel}
                />
                <MessageNodeElement
                  element={
                    {
                      ...element,
                      text: (element as ValidationResultElementModel).repromptText,
                    } as MessageNodeElementModel
                  }
                />
              </>
            );
          default:
            return (
              <ValidationResultElement
                element={element as ValidationResultElementModel}
              />
            );
        }
      case AuxiliaryHistoryElementTypes.ContextVariableUpdate:
        return (
          <ContextVariableUpdateElement
            element={element as ContextVariableUpdateElementModel}
          />
        );
      case AuxiliaryHistoryElementTypes.SystemMessage:
        return <SystemMessageElement element={element as SystemMessageElementModel} />;
      default:
        break;
    }
  };

  const _createInitiatingHistoryElement = (element: InitiatingHistoryElementModel) => {
    switch (element.type) {
      case InitiatingHistoryElementTypes.IncomingMessage:
        return (
          <IncomingMessageElement element={element as IncomingMessageElementModel} />
        );
      case InitiatingHistoryElementTypes.IncomingEvent:
        return <IncomingEventElement element={element as IncomingEventElementModel} />;
      case InitiatingHistoryElementTypes.IncomingActionCard:
        return (
          <IncomingActionCardElement
            element={element as IncomingActionCardElementModel}
          />
        );
      default:
        break;
    }
  };

  const _createNodeHistoryElement = (element: NodeHistoryElementModel) => {
    switch (element.type) {
      case NodeHistoryElementTypes.MessageNode:
        return <MessageNodeElement element={element as MessageNodeElementModel} />;
      case NodeHistoryElementTypes.PromptNode:
        return <PromptNodeElement element={element as PromptNodeElementModel} />;
      case NodeHistoryElementTypes.KnowledgebaseNode:
        return (
          <KnowledgebaseNodeElement element={element as KnowledgebaseNodeElementModel} />
        );
      case NodeHistoryElementTypes.EmailActionNode:
        return (
          <EmailActionNodeElement element={element as EmailActionNodeElementModel} />
        );
      case NodeHistoryElementTypes.ContextActionNode:
        return (
          <ContextActionNodeElement element={element as ContextActionNodeElementModel} />
        );
      case NodeHistoryElementTypes.ApiNode:
        return <ApiNodeElement element={element as ApiNodeElementModel} />;
      case NodeHistoryElementTypes.AdaptiveCardNode:
        return (
          <AdaptiveCardNodeElement element={element as AdaptiveCardNodeElementModel} />
        );
      default:
        break;
    }
  };

  const _createTriggerHistoryElement = (element: TriggerHistoryElementModel) => {
    switch (element.type) {
      case TriggerHistoryElementTypes.ListTrigger:
        return <ListTriggerElement element={element as ListTriggerElementModel} />;
      case TriggerHistoryElementTypes.RegexTrigger:
        return <RegexTriggerElement element={element as RegexTriggerElementModel} />;
      case TriggerHistoryElementTypes.KnowledgebaseTrigger:
        return (
          <KnowledgebaseTriggerElement
            element={element as KnowledgebaseTriggerElementModel}
          />
        );
      case TriggerHistoryElementTypes.LuisIntentTrigger:
        return (
          <LuisIntentTriggerElement element={element as LuisIntentTriggerElementModel} />
        );
      case TriggerHistoryElementTypes.EventTrigger:
        return <EventTriggerElement element={element as EventTriggerElementModel} />;
      case TriggerHistoryElementTypes.DefaultTrigger:
        return <DefaultTriggerElement element={element as DefaultTriggerElementModel} />;
      case TriggerHistoryElementTypes.AiTrigger:
        return <AiTriggerElement element={element as AiTriggerElementModel} />;
      default:
        break;
    }
  };

  const createHistoryElement = (element: BaseHistoryElementModel) => {
    const component = (() => {
      if (element instanceof AuxiliaryHistoryElementModel)
        return _createAuxiliaryHistoryElement(element as AuxiliaryHistoryElementModel);
      if (element instanceof InitiatingHistoryElementModel)
        return _createInitiatingHistoryElement(element as InitiatingHistoryElementModel);
      if (element instanceof NodeHistoryElementModel)
        return _createNodeHistoryElement(element as NodeHistoryElementModel);
      if (element instanceof TriggerHistoryElementModel)
        return _createTriggerHistoryElement(element as TriggerHistoryElementModel);

      throw new Error('Unknown history element instance.');
    })();

    return <div key={Math.random()}>{component}</div>;
  };

  return {
    createHistoryElement,
  };
}
