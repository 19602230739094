import { observer } from 'mobx-react-lite';
import React, { useEffect, useContext } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { HttpAgent } from '../../../utility/HttpAgent';
import HistoryElementList from '../../conversationHistory/historyElement/HistoryElementList';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';

interface IParamsProps {
  dialogId?: string;
  conversationThreadId?: string;
}

const ConversationThreadOverview = () => {
  const location = useLocation();
  const version = queryString.parse(location.search).version;
  const { dialogStore, conversationHistoryStore, canvasStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();

  const load = async () => {
    // await dialogStore.loadBots();
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    if (!dialogStore.currentlyEditedDialog && params.dialogId) {
      dialogStore.setDialogById(params?.dialogId, Number(version));
    }
  }, [dialogStore.bots, params]);

  return <HistoryElementList />;
};

export default observer(ConversationThreadOverview);
