import { Expose, Type } from 'class-transformer';
import { action, makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import {
  ContextVariableMap,
  ISerializedContextVariableMap,
} from '../../EntityNode/ContextVariableMap';
import { Entity } from '../../EntityNode/Entity';
import {
  EntityDefinition,
  ISerializedEntityDefinition,
} from '../../EntityNode/EntityDefinition';
import { ISerializedQuestionDialogNode, QuestionDialogNode } from '../QuestionDialogNode';

export class EntityQuestionNode extends QuestionDialogNode {
  readonly type = DialogNodeTypes.EntityQuestionNode;

  @Expose()
  @Type(() => EntityDefinition)
  entityDefinitions: EntityDefinition[];

  @Expose()
  @Type(() => ContextVariableMap)
  contextVariableMapping: ContextVariableMap[];

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Entity Question Node';
    this.entityDefinitions = [new EntityDefinition()];
    this.contextVariableMapping = [];

    makeObservable(this, {
      entityDefinitions: observable,
      contextVariableMapping: observable,
      addEntityDefinition: action,
      removeEntityDefinition: action,
    });
  }

  get isValid() {
    return (
      super.isValid &&
      this.entityDefinitions.every((entityDefinition) => entityDefinition.isValid)
    );
  }

  addEntityDefinition() {
    this.entityDefinitions.push(new EntityDefinition());
  }

  removeEntityDefinition(entity: EntityDefinition): void {
    this.entityDefinitions = this.entityDefinitions.filter((e) => e.id !== entity.id);
  }

  addContextVariableMap(previousEntityName: string, ctxVariableMap: ContextVariableMap) {
    if (
      !this.contextVariableMapping.find(
        (ctxVariable) => ctxVariable.entityName === ctxVariableMap.entityName
      )
    ) {
      this.contextVariableMapping.push(ctxVariableMap);
    }

    this.removeContextVariableMap(previousEntityName);
  }

  removeContextVariableMap(name: string, entity?: Entity) {
    let entityNameExistInOtherEntities = false;
    this.entityDefinitions.forEach((entityDefinition) => {
      const ifExist = entityDefinition.entities.find((entity) => entity.name === name);
      if (ifExist) {
        entityNameExistInOtherEntities = true;
        return;
      }
    });

    if (!entityNameExistInOtherEntities) {
      this.contextVariableMapping = this.contextVariableMapping.filter(
        (ctx) => ctx.entityName !== name
      );
    }
  }

  serialize(): ISerializedEntityDialogNode {
    return {
      ...super.serialize(),
      entityDefinitions: this.entityDefinitions?.map((item) => item.serialize()),
      contextVariableMapping: this.contextVariableMapping?.map((ctx) => ctx.serialize()),
    };
  }
}

export interface ISerializedEntityDialogNode extends ISerializedQuestionDialogNode {
  entityDefinitions: ISerializedEntityDefinition[];
  contextVariableMapping: ISerializedContextVariableMap[];
}
