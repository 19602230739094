import { JsonContainer } from './JsonContainer';
import { ListElement } from './PathElements/ListElement';
import { PathElement } from './PathElements/PathElement';

export class RootContainer extends JsonContainer {
  constructor() {
    super(null, 'null', 1, undefined);
  }

  get paths() {
    return {
      stringified: this.name,
      pathTree: this.getListPathTree(),
    };
  }

  get name() {
    return 'Root';
  }

  get isArrayContainer() {
    return true;
  }

  protected getListPathTree(): PathElement[] {
    return [new ListElement(null)];
  }

  serializeToPathElements(forKey?: boolean): PathElement[] {
    return [new ListElement(null)];
  }
}
