import React, { Fragment, useContext } from 'react';
import rootStore from '../../../stores/rootStore';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Overlay from '../../layout/Overlay';
import Button from '../Button';

interface IManualModalProps {
  hidden?: boolean;
  closeHandler: () => void;
  hideCloseButton?: boolean;
  children: React.ReactNode;
  preventCloseOnOverlayClick?: boolean;
}

const ModalWithChildren: React.FC<IManualModalProps> = ({
  hidden = false,
  hideCloseButton,
  closeHandler,
  children,
  preventCloseOnOverlayClick = false,
}) => {
  const { uiStore } = useContext(rootStore);
  const closeIcon = useGetIcon()('close');

  return (
    <Fragment>
      <Overlay
        clickHandler={closeHandler}
        inactive={preventCloseOnOverlayClick || uiStore.modalState.preventClosing}
      />
      <div className='modal' style={{ visibility: hidden ? 'hidden' : 'visible' }}>
        {!hideCloseButton && (
          <Button
            className={'btn-circle btn-absolute-right-top btn-borderless'}
            icon={closeIcon}
            tooltip={uiStore.modalState.tooltip}
            tooltipPosition='top'
            disabled={uiStore.modalState.preventClosing}
            clickHandler={closeHandler}
          />
        )}
        {children}
      </div>
    </Fragment>
  );
};

export default ModalWithChildren;
