export enum EventTargetPlatforms {
  MicrosoftTeams = 'Microsoft Teams',
  Telegram = 'Telegram',
}

export enum EventTargetReceiverTypes {
  User = 'User',
  Channel = 'Channel',
  Group = 'Group',
}
