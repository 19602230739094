import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { LuisIntentTriggerElement as LuisIntentTriggerElementModel } from '../../../../models/ConversationHistory/HistoryElement/TriggerHistoryElement/LuisIntentTriggerElement';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: LuisIntentTriggerElementModel;
}

const LuisIntentTriggerElement: React.FC<IProps> = ({ element }: IProps) => {
  const getIcon = useGetIcon();
  const { blockStore } = useContext(rootStore);
  const block = blockStore?.allBlocks?.find(
    (block) => block?.trigger?.id === element.triggerId
  );
  const score = element && element.score ? (element.score * 100).toFixed(0) : 0;

  return (
    <BaseHistoryElement
      element={element}
      title='Intent Trigger evaluated'
      icon={getIcon('intent_trigger')}
    >
      {element.triggered ? (
        <div className='card-paragraph'>
          <i className={getIcon('check')}> </i>
          <span>
            {' '}
            Triggered block "{block?.title}" with a probability of {score}%
          </span>
        </div>
      ) : (
        <div className='card-paragraph'>
          <i className={getIcon('exclamation')}> </i>
          <span>
            {' '}
            Not triggered block "{block?.title}" with a probability of {score}%
          </span>
        </div>
      )}
    </BaseHistoryElement>
  );
};

export default observer(LuisIntentTriggerElement);
