import React from 'react';

interface IProps {
  inactive?: boolean;
  transparent?: boolean;
  clickHandler: () => void;
}

const Overlay: React.FC<IProps> = ({ inactive, transparent, clickHandler }) => {
  const handleClick = () => {
    if (inactive) return;

    clickHandler();
  };

  return (
    <div className='overlay' data-transparent={transparent} onClick={handleClick}></div>
  );
};

export default Overlay;
