import { makeObservable, observable } from 'mobx';
import { InitiatingHistoryElement } from './InitiatingHistoryElement';

export class IncomingEventElement extends InitiatingHistoryElement {
  eventId: string;

  constructor() {
    super();
    this.eventId = '';

    makeObservable(this, {
      eventId: observable,
    });
  }
}
