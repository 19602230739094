import { observer } from 'mobx-react-lite';
import React from 'react';
import { TriggerTypes } from '../../../../architecture/enums/TriggerTypes';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';

interface ChooseTriggerStageProps {
  block: DialogBlockModel;
  chooseTriggerHandler: (type: TriggerTypes) => void;
}
const ChooseTriggerStage: React.FC<ChooseTriggerStageProps> = ({
  block,
  chooseTriggerHandler,
}) => {
  return (
    <div className='trigger-type-selector'>
      <div className='trigger-type-selector-header'>
        <h2>
          Create A New Trigger For{' '}
          <strong style={{ textDecoration: 'underline' }}>{block.title}</strong>
        </h2>
        <p className='trigger-type-selector-header-sub-title'>
          Creating a new Trigger for <strong>{block.title}</strong> means that if the
          user's answers or behaviour fulfils the condition the dialog will continue in{' '}
          <strong>{block.title}</strong>.
        </p>
      </div>
      <div className='trigger-info-container'>
        {/* <TriggerInfoGroup
          title='Intent Trigger'
          index={0}
          description='Train conversational language understanding based on a set of example phrases. The trigger learns to recognize similar user inputs which results in a natural conversation'
          chooseTriggerHandler={() => chooseTriggerHandler(TriggerTypes.Intent)}
        /> */}
        <TriggerInfoGroup
          index={4}
          title='AI Trigger'
          description="Use Artificial Intelligence to understand the user's intention and trigger a conversation."
          chooseTriggerHandler={() => chooseTriggerHandler(TriggerTypes.AI)}
        />
        <TriggerInfoGroup
          index={1}
          title='RegExp Trigger'
          description="Insert a Regular Expression pattern. The user's input will be evaluated against the provided pattern."
          chooseTriggerHandler={() => chooseTriggerHandler(TriggerTypes.RegexInput)}
        />
        <TriggerInfoGroup
          index={2}
          title='List Trigger'
          description="Create a list of items. The items will be evaluated if any of them matches the user's input."
          chooseTriggerHandler={() => chooseTriggerHandler(TriggerTypes.UserInput)}
        />
        <TriggerInfoGroup
          index={3}
          title='Event Trigger'
          description='Start or continue a conversation from an external event.'
          chooseTriggerHandler={() => chooseTriggerHandler(TriggerTypes.Event)}
        />
      </div>
    </div>
  );
};

export default observer(ChooseTriggerStage);

interface ITriggerInfoGroupProps {
  title: string;
  index: number;
  description: string;
  chooseTriggerHandler: () => void;
}
const TriggerInfoGroup: React.FC<ITriggerInfoGroupProps> = ({
  title,
  index,
  description,
  chooseTriggerHandler,
}) => {
  return (
    <div
      className='trigger-info-group'
      onClick={chooseTriggerHandler}
      style={{ animationDelay: `${index * 50}ms` }}
    >
      <h4 className='trigger-info-group-title'>{title}</h4>
      <p className='trigger-info-group-description'>{description}</p>
      <p className='trigger-info-group-footer'>Create your new Trigger!</p>
    </div>
  );
};
