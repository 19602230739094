import { observer } from 'mobx-react-lite';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { SingleQuestionNode } from '../../../../../models/DialogNodes/QuestionNodes/SingleQuestionNode';
import rootStore from '../../../../../stores/rootStore';
import ContextVariableSelector from '../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';

interface IProps {
  dialogNode: SingleQuestionNode;
}
const SingleQuestionContextVariableHandler: React.FC<IProps> = ({ dialogNode }) => {
  const { ctxVarStore } = useContext(rootStore);

  const [error, setError] = useState(false);

  const forbiddenNames = useRef<string[]>([
    ...ctxVarStore.systemVariables.map((item) => item.name),
    ...ctxVarStore.listContextVariables.map((item) => item.name),
  ]);

  const validateInput = (input: string) => {
    const containsForbiddenName = forbiddenNames.current.includes(input);
    setError(containsForbiddenName);
    return containsForbiddenName;
  };

  const handleCtxSelection = (value: string) => {
    if (error) {
      return;
    }

    dialogNode.contextVariable = value ? ctxVarStore.addIfNotExists(value)! : undefined;
  };

  return (
    <Fragment>
      <h4>Where would you like to save the answer?</h4>
      <ContextVariableSelector
        items={ctxVarStore.singleValueUserContextVariables}
        triggerLabel={dialogNode.contextVariable?.name ?? 'Add or select'}
        selectHandler={handleCtxSelection}
        inputValidator={validateInput}
        replaceSpecialCharacters
        creator
        closeOnSelect
      />
    </Fragment>
  );
};

export default observer(SingleQuestionContextVariableHandler);
