import { observer } from 'mobx-react-lite';
import React, { useContext, useRef, useState } from 'react';
import { ProxyConnector } from '../../models/Connectors/ProxyConnector';
import rootStore from '../../stores/rootStore';
import { useGetIcon } from '../customHooks/useGetIcon';
import Button from './Button';

interface IProps {
  text: string | null;
  language: string;
  usePronunciation?: boolean;
  disabled?: boolean;
}

enum PlaybackState {
  Idle,
  Loading,
  Playback,
  Stop,
}

const AudioPlaybackIcon: React.FC<IProps> = ({
  text,
  language,
  usePronunciation = true,
  disabled = false,
}) => {
  const { subscriptionStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  const [playbackState, setPlaybackState] = useState<PlaybackState>(PlaybackState.Idle);

  const clickHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    switch (playbackState) {
      case PlaybackState.Idle:
        await audioPlayback(event);
        break;
      case PlaybackState.Loading:
        break;
      case PlaybackState.Playback:
        if (audioRef.current !== null) {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
          setPlaybackState(PlaybackState.Idle);
          break;
        }
    }
  };

  const audioPlayback = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!text) return;

    setPlaybackState(PlaybackState.Loading);

    const subscriptionId = subscriptionStore.selectedSubscription?.id;
    if (!subscriptionId) return;

    let audioFile;
    if (usePronunciation) {
      audioFile = await ProxyConnector.getAudio(subscriptionId, text, language);
    } else {
      audioFile = await ProxyConnector.getAudio(subscriptionId, text, language, false);
    }

    setPlaybackState(PlaybackState.Playback);

    if (audioFile) {
      const audio = document.createElement('audio');
      audio.src = audioFile.audio;
      audioRef.current = audio;

      audio.addEventListener('ended', () => {
        setPlaybackState(PlaybackState.Idle);
      });

      audio.play();
    }
  };

  let icon = getIcon('play');
  if (playbackState === PlaybackState.Loading) {
    icon = `${getIcon('loading')} active-icon spin`;
  } else if (playbackState === PlaybackState.Playback) {
    icon = `${getIcon('stop')} active-icon`;
  }

  return (
    <Button
      tooltipPosition='top'
      disabled={!text || disabled}
      tooltip='Play back text'
      className='btn-borderless'
      icon={icon}
      clickHandler={clickHandler}
    />
  );
};

export default observer(AudioPlaybackIcon);
