import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { DatasetListAction } from '../../../../../../architecture/enums/Dataset';
import { PropertyValue } from '../../../../../../models/Dataset/PropertyValue';
import rootStore from '../../../../../../stores/rootStore';
import Button from '../../../../../common/Button';
import ContextVariableSelector from '../../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';

interface IProps {
  property: PropertyValue;
  updating?: boolean;
}

const DatasetListPropertiesValues: React.FC<IProps> = ({ property, updating }) => {
  const { ctxVarStore } = useContext(rootStore);

  return (
    <div className='properties-values-list'>
      {updating && (
        <>
          {property.datasetListAction === DatasetListAction.Add ? (
            <Button
              content='Add'
              className='btn-round action-button'
              clickHandler={() => {
                property.datasetListAction = DatasetListAction.Set;
              }}
            />
          ) : (
            <Button
              content='Set'
              className='btn-round action-button'
              clickHandler={() => {
                property.datasetListAction = DatasetListAction.Add;
              }}
            />
          )}
        </>
      )}
      {property.createdVariables?.map((variable, index) => {
        return (
          <div className='flex-gap' key={index}>
            <ContextVariableSelector
              items={ctxVarStore.datasetVariables.filter(
                (ctx) => ctx.type === property.datasetType
              )}
              triggerLabel={variable.name}
              selectHandler={(value) => {
                if (value === '') {
                  property.removeCtxVariableInCtxVariablesList(variable);
                }
                const ctxVariable = ctxVarStore.datasetVariables.find(
                  (v) => v.name === value
                );

                if (
                  ctxVariable &&
                  !property.createdVariables?.find(
                    (variable) => variable.name === ctxVariable.name
                  )
                )
                  property.replaceCtxVariableInCtxVariablesList(ctxVariable, index);
              }}
              clear
              replaceSpecialCharacters
              closeOnSelect
            />
          </div>
        );
      })}
      <div className='flex-gap'>
        <ContextVariableSelector
          items={ctxVarStore.datasetVariables.filter(
            (ctx) =>
              ctx.type === property.datasetType &&
              !property.createdVariables?.find((variable) => variable.name === ctx.name)
          )}
          triggerLabel='Add or select'
          selectHandler={(value) => {
            const ctxVariable = ctxVarStore.datasetVariables.find(
              (v) => v.name === value
            );
            if (ctxVariable) property.addCtxVariableToCtxVariablesList(ctxVariable);
          }}
          replaceSpecialCharacters
          closeOnSelect
        />
      </div>
    </div>
  );
};

export default observer(DatasetListPropertiesValues);
