import React from 'react';

type infoBody = { title: string; body: string };
interface ITypeInfo {
  [title: string]: infoBody;
}

interface IProps {
  activeInfo: infoBody;
  title: string;
  btnGroup: JSX.Element;
}

const NodeTypeSelector: React.FC<IProps> = ({ title, activeInfo, btnGroup }) => {
  return (
    <div className='node-type-selector'>
      <h2 className='node-type-selector-title'>{title}</h2>
      <div className='node-type-selector-body'>
        <div className='node-type-selector-text-container'>
          <h4 className='active-info-title'>{activeInfo?.title}</h4>
          <p className='active-info-body'>{activeInfo?.body}</p>
        </div>
        <div className='btn-group'>{btnGroup}</div>
      </div>
    </div>
  );
};

export default NodeTypeSelector;
