export enum FacialExpressions {
  Happy = 'happy',
  Surprised = 'surprised',
  Sad = 'sad',
  Anxious = 'anxious',
  Disgusted = 'disgusted',
  Dignified = 'dignified',
  Appelled = 'appelled',
  Hate = 'hate',
  Furious = 'furious',
}
