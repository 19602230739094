import { observer } from 'mobx-react-lite';
import React from 'react';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../customHooks/useReadOnly';

interface IProps {
  entry: KnowledgebaseEntry;
  setOpenAttachments: (value: boolean) => void;
}

const AttachmentButton: React.FC<IProps> = ({ entry, setOpenAttachments }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();

  return (
    <div className='attachment-action'>
      {entry.attachmentsExist && (
        <span className='badge'>{entry.numberOfAttachments}</span>
      )}
      <Button
        className='btn-borderless p-5'
        icon={getIcon('link')}
        disabled={knowledgebaseReadOnlyMode}
        clickHandler={() => setOpenAttachments(true)}
      />
    </div>
  );
};

export default observer(AttachmentButton);
