export enum Color {
  TitleTextColor = 'titleTextColor',
  BackgroundColor = 'backgroundColor',
  PrimaryColor = 'primaryColor',
  PrimaryTextColor = 'primaryTextColor',
  PrimaryButtonTextColor = 'primaryButtonTextColor',
  SecondaryColor = 'secondaryColor',
}

export enum Image {
  BackgroundImage = 'backgroundImageId',
  LogoImage = 'logoId',
}
