import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { Pronunciation } from '../../../../models/Subscription/Pronunciation';
import AudioPlaybackIcon from '../../../common/AudioPlaybackIcon';
import Textarea from '../../../common/inputElements/Textarea';

interface IProps {
  pronunciation: Pronunciation;
  language: string;
}

const PronuncationSsml: React.FC<IProps> = ({ pronunciation: item, language }) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  }, [item.text]);

  const getClassName = () => {
    if (item.isDeleted) {
      return 'deleted';
    } else if (item.ssmlIsModified() || item.isAdded) {
      return 'highlighted';
    }
    return '';
  };

  return (
    <div className='pronunciation-text-wrapper'>
      <Textarea
        ref={textareaRef}
        value={item.ssml ?? ''}
        changeHandler={(value) => {
          item.ssml = value;
        }}
        disabled={item.isDeleted}
        placeholder='Type in the correct pronunciation...'
        className={`text-cell ${getClassName()}`}
      />
      <AudioPlaybackIcon
        text={item.ssml}
        language={language}
        disabled={item.isDeleted}
        usePronunciation={false}
      />
    </div>
  );
};

export default observer(PronuncationSsml);
