import { Exclude } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { EventTargetPlatforms } from '../../../../architecture/enums/EventTrigger';

export abstract class EventTarget {
  @Exclude()
  abstract platform: EventTargetPlatforms;

  @Exclude()
  abstract platformKey: string;

  constructor() {
    makeObservable(this, {
      platform: observable,
      platformKey: observable,
      isValid: computed,
    });
  }

  get isValid() {
    return true;
  }

  get isChanged() {
    return false;
  }

  serialize(): ISerializedEventTarget {
    return {
      platform: this.platformKey as EventTargetPlatforms,
    };
  }
}

export interface ISerializedEventTarget {
  platform: EventTargetPlatforms;
}
