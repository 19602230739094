import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { PAGE_SIZE } from '../../architecture/constants/Knowledgebase';
import { KnowledgebaseState } from '../../architecture/enums/KnowledgebaseState';
import { MediaState } from '../../architecture/enums/MediaState';
import rootStore from '../../stores/rootStore';
import Pagination from '../common/pagination/Pagination';
import LoadingPage from '../layout/LoadingPage';
import KnowledgebaseList from './KnowledgebaseList';
import Header from './header/Header';

const KnowledgebaseContainer: React.FC = () => {
  const { knowledgebaseStore, mediaStore } = useContext(rootStore);
  const [page, setPage] = useState(0);
  const [knowledgebaseEntries, setKnowledgebaseEntries] = useState(
    knowledgebaseStore.filteredKnowledgebaseEntries.slice(0, PAGE_SIZE)
  );

  useEffect(() => {
    setPage(0);
    setKnowledgebaseEntries(
      knowledgebaseStore.filteredKnowledgebaseEntries.slice(0, PAGE_SIZE)
    );
  }, [
    JSON.stringify(
      knowledgebaseStore.filteredKnowledgebaseEntries.map((entry) => entry.embeddingId)
    ),
  ]);

  const pageChange = (page: number) => {
    setPage(page);

    setKnowledgebaseEntries(
      knowledgebaseStore.filteredKnowledgebaseEntries.slice(
        page * PAGE_SIZE,
        (page + 1) * PAGE_SIZE
      )
    );
  };

  return (
    <div>
      {knowledgebaseStore.getState(KnowledgebaseState.QuestionsLoading) ||
      mediaStore.getState(MediaState.ItemsLoading) ? (
        <LoadingPage />
      ) : (
        <>
          <div className='container-wrapper knowledgebase-container-wrapper'>
            <Header />
            {knowledgebaseStore.getState(KnowledgebaseState.Initialized) && (
              <>
                {knowledgebaseStore.allKnowledgebaseEntries.length === 0 ? (
                  <div className='empty-wrapper'>Knowledge Base is empty</div>
                ) : (
                  <KnowledgebaseList knowledgebaseEntries={knowledgebaseEntries} />
                )}

                {knowledgebaseStore.filteredKnowledgebaseEntries.length > PAGE_SIZE && (
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(
                      knowledgebaseStore.filteredKnowledgebaseEntries.length / PAGE_SIZE
                    )}
                    pageChange={pageChange}
                  />
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(KnowledgebaseContainer);
