import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { PronunciationState } from '../../../architecture/enums/PronunciationState';
import { DialogLanguages } from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import rootStore from '../../../stores/rootStore';
import { useQuery } from '../../customHooks/useQuery';
import LoadingPage from '../LoadingPage';
import PronunciationTable from './pronunciation/PronunciationTable';

const PronunciationOverview = () => {
  const { pronunciationStore } = useContext(rootStore);
  const searchParams = useQuery();

  const getValueFromKey = (key: keyof typeof DialogLanguages): string => {
    return DialogLanguages[key];
  };

  const languageParam = searchParams['language'];

  const languageCode = getValueFromKey(languageParam as keyof typeof DialogLanguages);

  const loadPronunciations = async () => {
    if (languageCode) {
      await pronunciationStore.load(languageCode);
    }
  };

  useEffect(() => {
    loadPronunciations();
  }, [languageCode, pronunciationStore.getState(PronunciationState.Initialized)]);

  return (
    <div className='pronunciation-overview'>
      {pronunciationStore.getState(PronunciationState.Initialized) ? (
        <PronunciationTable language={languageCode} />
      ) : (
        <LoadingPage fullScreen={true} />
      )}
    </div>
  );
};

export default observer(PronunciationOverview);
