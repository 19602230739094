import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Subscription } from '../../../models/Admin/Subscription';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Switch from '../../common/inputElements/Switch';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';

const validationSchema = Yup.object({
  subscriptionName: Yup.string().required('This field is required'),
});

const SubscriptionDetails: React.FC = () => {
  const getIcon = useGetIcon();
  const { subscriptionAdminStore } = useAdminStores();

  const initialSubscription = subscriptionAdminStore.getSelectedSubscription;

  const initialSubscriptionValues = {
    subscriptionName: initialSubscription?.name || '',
    id: initialSubscription?.id || '',
    firstName: initialSubscription?.contact?.firstName || '',
    lastName: initialSubscription?.contact?.lastName || '',
    phoneNumber: initialSubscription?.contact?.phoneNumber || '',
    companyName: initialSubscription?.billingAddress?.companyName || '',
    receiverName: initialSubscription?.billingAddress?.receiverName || '',
    street: initialSubscription?.billingAddress?.street || '',
    postalCode: initialSubscription?.billingAddress?.postalCode || '',
    city: initialSubscription?.billingAddress?.city || '',
    country: initialSubscription?.billingAddress?.country || '',
    isReseller: initialSubscription?.isReseller || false,
  };

  const formik = useFormik({
    initialValues: initialSubscriptionValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      subscriptionAdminStore.update(Subscription.fromForm(values));
    },
  });

  const cancel = () => {
    subscriptionAdminStore.setSelectedSubscription(undefined);
  };

  useEffect(() => {
    formik.setValues(initialSubscriptionValues);
  }, [subscriptionAdminStore.getSelectedSubscription]);

  return (
    <div className='details-wrapper'>
      <div className='header'>
        <h4>{subscriptionAdminStore.getSelectedSubscription?.name}</h4>
      </div>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='subscription-name'>Subscription Name</label>
            <Input
              id='subscription-name'
              placeholder='Name your Subscription'
              value={formik.values.subscriptionName}
              changeHandler={(value) => formik.setFieldValue('subscriptionName', value)}
            />
            {formik.touched.subscriptionName && formik.errors.subscriptionName ? (
              <div className='validation-error'>{formik.errors.subscriptionName}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <label htmlFor='subscription-id'>SubscriptionID</label>
            <Input
              id='subscription-id'
              disabled={true}
              value={formik.values.id}
              changeHandler={(text: string) => {}}
              actionIcon={getIcon('lock')}
            />
          </div>
          <div className='input-group'>
            <label className='subtitle'>Contact Person</label>
            <div className='input-grid'>
              <div>
                <label htmlFor='first-name'>First Name</label>
                <Input
                  id='first-name'
                  value={formik.values.firstName}
                  placeholder='First Name'
                  changeHandler={(value) => formik.setFieldValue('firstName', value)}
                />
              </div>
              <div>
                <label htmlFor='last-name'>Last Name</label>
                <Input
                  id='last-name'
                  value={formik.values.lastName}
                  placeholder='Last Name'
                  changeHandler={(value) => formik.setFieldValue('lastName', value)}
                />
              </div>
              <div>
                <label htmlFor='phone-number'>Phone Number</label>
                <Input
                  id='phone-number'
                  value={formik.values.phoneNumber}
                  placeholder='Phone Number'
                  changeHandler={(value) => formik.setFieldValue('phoneNumber', value)}
                />
              </div>
              <div className='switch'>
                <Switch
                  value={formik.values.isReseller}
                  clickHandler={() =>
                    formik.setFieldValue('isReseller', !formik.values.isReseller)
                  }
                />
                <div>Subscription is reseller</div>
              </div>
            </div>
          </div>
          <div className='input-group'>
            <label className='subtitle'>Billing Adress</label>
            <div className='input-grid'>
              <div>
                <label htmlFor='company-name'>Company Name</label>
                <Input
                  id='company-name'
                  value={formik.values.companyName}
                  placeholder='Company Name'
                  changeHandler={(value) => formik.setFieldValue('companyName', value)}
                />
              </div>
              <div>
                <label htmlFor='receiver-name'>Receiver Name</label>
                <Input
                  id='receiver-name'
                  value={formik.values.receiverName}
                  placeholder='Receiver Name'
                  changeHandler={(value) => formik.setFieldValue('receiverName', value)}
                />
              </div>
            </div>
            <div className='input-grid'>
              <div>
                <label htmlFor='street'>Street</label>
                <Input
                  id='street'
                  value={formik.values.street}
                  placeholder='Street'
                  changeHandler={(value) => formik.setFieldValue('street', value)}
                />
              </div>
            </div>
            <div className='input-grid'>
              <div>
                <label htmlFor='postal-code'>Postal Code</label>
                <Input
                  id='postal-code'
                  value={formik.values.postalCode}
                  placeholder='Postal Code'
                  changeHandler={(value) => formik.setFieldValue('postalCode', value)}
                />
              </div>
              <div>
                <label htmlFor='city'>City</label>
                <Input
                  id='city'
                  value={formik.values.city}
                  placeholder='City'
                  changeHandler={(value) => formik.setFieldValue('city', value)}
                />
              </div>
              <div>
                <label htmlFor='country'>Country</label>
                <Input
                  id='country'
                  value={formik.values.country}
                  placeholder='Country'
                  changeHandler={(value) => formik.setFieldValue('country', value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <Button
            clickHandler={() => formik.handleSubmit()}
            content='Save changes'
            className='btn-primary btn-round details-button'
          />
          <Button
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={cancel}
          />
        </div>
      </form>
    </div>
  );
};

export default observer(SubscriptionDetails);
