import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { IContextVariable } from '../../architecture/interfaces/contextVariables/IContextVariable';
import rootStore from '../../stores/rootStore';

interface ContextVariablProps {
  ctx: IContextVariable;
  clickHandler: () => void;
}

const ContextVariable: React.FC<ContextVariablProps> = ({ ctx, clickHandler }) => {
  const { ctxVarStore } = useContext(rootStore);
  return (
    <span
      onClick={clickHandler}
      className={'context-variable'}
      data-type={ctx.instanceType}
      contentEditable={false}
      data-ctx-id={ctx.id}
      data-ctx-name={`${ctxVarStore.trigger}${ctx.name}`}
    >{`${ctxVarStore.trigger}${ctx.name}`}</span>
  );
};

export default observer(ContextVariable);
