import { HttpAgent } from '../../utility/HttpAgent';

export class UserConnector {
  static async updateProfile(givenName: string, surname: string): Promise<boolean> {
    try {
      await HttpAgent.requests.put(`/Api/V2/User/Profile`, {
        givenName,
        surname,
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  static async updatePassword(newPassword: string): Promise<boolean> {
    try {
      await HttpAgent.requests.put(`/Api/V2/User/Password`, {
        newPassword,
      });
      return true;
    } catch (error) {
      return false;
    }
  }
}
