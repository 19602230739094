import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Utilities } from '../../models/Utilities/Utilities';
import Textarea from './inputElements/Textarea';

interface IProps {
  value: any;
  staticHeight?: boolean;
  disabled?: boolean;
  onChange?: (text: string) => void;
  onPaste?: (text: string) => void;
  darkMode?: boolean;
}

const TextareaJson: React.FC<IProps> = ({
  value,
  staticHeight,
  disabled,
  onChange,
  onPaste,
  darkMode = false,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const prevValue = useRef(value);

  useEffect(() => {
    if (!disabled && !onPaste && !onChange)
      throw new Error(
        'If your textarea is not disabled you must provide either a change- or a paste handler!'
      );
  }, []);

  const COLLAPSED_TEXTAREA_HEIGHT = 40;

  const [textareaConfig, setTextareaConfig] = useState({
    open: true,
    height: COLLAPSED_TEXTAREA_HEIGHT,
  });

  const textAreaStyles: CSSProperties = React.useMemo(() => {
    const obj = {
      height: staticHeight ? '100%' : textareaConfig.height + 'px',
      minHeight: staticHeight ? '200px' : 'unset',
    };

    return obj;
  }, [textareaConfig]);

  const adjustTextArea = () => {
    if (staticHeight) return;

    setTextareaConfig({
      open: true,
      height: textAreaRef.current!.scrollHeight,
    });
  };

  const handleBlur = () => {
    if (staticHeight) return;

    setTextareaConfig({
      open: false,
      height: COLLAPSED_TEXTAREA_HEIGHT,
    });
  };

  const handleChange = (text: string) => {
    if (disabled) return;

    onChange?.(text);
    adjustTextArea();
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    if (disabled) return;

    event.preventDefault();
    const text = event.clipboardData.getData('text/plain');

    const el = document.getElementById('json-textarea') as HTMLTextAreaElement;
    if (el) {
      el.innerHTML = text;
      setTimeout(() => {
        prevValue.current = el.innerHTML;
        onPaste?.(el.innerHTML);
        adjustTextArea();
      }, 5);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (disabled) return;

    if (event.ctrlKey && event.key === 'z') {
      onPaste?.(_getCorrectValue(prevValue.current));
    }
  };

  const _getCorrectValue = (value: any) => {
    return typeof value === 'string' ? value : JSON.stringify(value, undefined, 2);
  };

  return (
    <Textarea
      id='json-textarea'
      ref={textAreaRef}
      allowInlineTab
      inError={value && !Utilities.isJsonValid(_getCorrectValue(value))}
      value={_getCorrectValue(value)}
      darkMode={darkMode}
      focusHandler={adjustTextArea}
      blurHandler={handleBlur}
      changeHandler={handleChange}
      pasteHandler={handlePaste}
      keyDownHandler={handleKeyDown}
      style={textAreaStyles}
    />
  );
};

export default TextareaJson;
