import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  content: string;
  clikHandler: () => void;
}

const VerticalNavigationButton: React.FC<IProps> = ({ content, clikHandler }) => {
  const getIcon = useGetIcon();

  return (
    <div className='vertical-navigation-button' onClick={clikHandler}>
      <div className='navigation-icon'>
        <i className={getIcon('right_circle')} />
      </div>
      <div className='vertical-text'>{content}</div>
    </div>
  );
};

export default observer(VerticalNavigationButton);
