import { observer } from 'mobx-react-lite';
import React from 'react';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useAdminStores from '../../customHooks/useAdminStores';
import Button from '../../common/Button';

interface IProps {
  resetModal: () => void;
}

const ConfirmSecretRefresh: React.FC<IProps> = ({ resetModal }) => {
  const { botAdminStore } = useAdminStores();
  const getIcon = useGetIcon();

  const handleConfirm = async () => {
    if (botAdminStore.getSelectedBot) {
      await botAdminStore.refreshBotSecret(botAdminStore.getSelectedBot.id);
      resetModal();
    }
  };

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>Are you sure you want to refresh a bot secret?</h3>
      </div>
      <div className='btn-container'>
        <Button
          content='Refresh'
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleConfirm}
        />
        <Button
          content='Cancel'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={resetModal}
        />
      </div>
    </div>
  );
};

export default observer(ConfirmSecretRefresh);
