import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { v4 } from 'uuid';
import { ApiActionNode } from '../../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import Button from '../../../../common/Button';
import Input from '../../../../common/Input';
import { useGetIcon } from '../../../../customHooks/useGetIcon';

interface IProps {
  dialogNode: ApiActionNode;
}

const PlaceholderTab: React.FC<IProps> = ({ dialogNode }) => {
  return (
    <div className='request-dependency-config'>
      <h4>Request Placeholders</h4>
      <ul className='request-dependency-list'>
        {dialogNode.requestDependencies.map((ctx) => (
          <div key={ctx.id} className='request-dependendy-unit'>
            <h4>{ctx.name}</h4>
            <Input
              controlled
              value={dialogNode.requestShadowValues.get(ctx.id) ?? ''}
              blurHandler={(text) => dialogNode.requestShadowValues.set(ctx.id, text)}
            />
          </div>
        ))}
      </ul>
    </div>
  );
};

export default observer(PlaceholderTab);
