import { Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';

export class Payment {
  @Expose()
  @Type(() => Date)
  created: Date;

  @Expose()
  credits: number;

  @Expose()
  id: string;

  @Expose()
  @Type(() => Date)
  outdated?: Date;

  @Expose()
  remarks: string;

  constructor(
    created?: Date,
    credits?: number,
    id?: string,
    outdated?: Date,
    remarks?: string
  ) {
    this.created = created ?? new Date();
    this.credits = credits ?? -1;
    this.id = id ?? '';
    this.outdated = outdated;
    this.remarks = remarks ?? '';

    makeObservable(this, {
      created: observable,
      credits: observable,
      id: observable,
      outdated: observable,
      remarks: observable,
    });
  }
}
