import { observer } from 'mobx-react-lite';
import React from 'react';
import { DateTimeQuestionNode as DateTimeQuestionNodeModel } from '../../../../../models/DialogNodes/QuestionNodes/QuestionNodeTypes/DateTimeQuestionNode';
import GroupedMultiQuestionNode from '../MultiQuestionNode/GroupedMultiQuestionNode';
interface IProps {
  dialogNode: DateTimeQuestionNodeModel;
}

const DateTimeQuestionNode: React.FC<IProps> = ({ dialogNode }) => {
  return <GroupedMultiQuestionNode dialogNode={dialogNode} />;
};

export default observer(DateTimeQuestionNode);
