import React, { CSSProperties } from 'react';

interface IProps {
  attachedTo: HTMLDivElement;
  children: React.ReactNode;
}

const Dropdown = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      attachedTo, children
    },
    ref
  ) => {
    const DROPDOWN_HEIGHT = 250;

    const bottom =
      (attachedTo?.getBoundingClientRect().bottom ?? 0) + DROPDOWN_HEIGHT >=
      window.innerHeight
        ? 0
        : 'unset';

    const style: CSSProperties = {
      maxHeight: DROPDOWN_HEIGHT + 'px',
      bottom,
    };

    return (
      <div className='ctx-dropdown-container' style={style} ref={ref}>
        {children}
      </div>
    );
  }
);

export default Dropdown;
