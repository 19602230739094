import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { ModalTypes } from '../../../architecture/enums/ModalTypes';
import rootStore from '../../../stores/rootStore';
import Button from '../../common/Button';
import { useGetIcon } from '../../customHooks/useGetIcon';
import useModal from '../../customHooks/useModal';
import { useReadOnly } from '../../customHooks/useReadOnly';

const Actions: React.FC = () => {
  const getIcon = useGetIcon();
  const { showModal } = useModal();
  const { knowledgebaseReadOnlyMode } = useReadOnly();
  const { knowledgebaseStore } = useContext(rootStore);

  return (
    <div className='actions-wrapper'>
      <Button
        content='Discard changes'
        disabled={knowledgebaseReadOnlyMode || !knowledgebaseStore.isEdited()}
        className='btn-round btn-inactive btn-action'
        icon={getIcon('history')}
        reverseContent={true}
        clickHandler={() => showModal(ModalTypes.DiscardKnowledgebaseChanges)}
      ></Button>
      <Button
        content='Add a new row'
        disabled={knowledgebaseReadOnlyMode}
        className='btn-round btn-primary btn-action'
        icon={getIcon('add_alt')}
        reverseContent={true}
        clickHandler={() => knowledgebaseStore.addRow()}
      ></Button>
    </div>
  );
};

export default observer(Actions);
