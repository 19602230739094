import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import ManageDataset from '../../dataset/manage/ManageDataset';

const Manage = () => {
  const { dialogStore, datasetStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);

  const load = async () => {
    await Promise.all([dialogStore.loadBots(), datasetStore.load()]);
  };

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  return <ManageDataset />;
};

export default observer(Manage);
