import { observer } from 'mobx-react-lite';
import React from 'react';
import { Utilities } from '../../../models/Utilities/Utilities';
import Button from '../../common/Button';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  items: { id: string; name: string }[];
  activeItemId: string | undefined;
  children?: React.ReactNode;
  setActiveItemId: (id: string) => void;
  removeItemHandler?: (id: string) => void;
  darkMode?: boolean;
}
const SideBar: React.FC<IProps> = ({
  darkMode = false,
  items,
  activeItemId,
  children,
  setActiveItemId,
  removeItemHandler,
}) => {
  const getIcon = useGetIcon();

  return (
    <div className='side-bar'>
      {children}
      <ul className='side-bar-items'>
        {items.map((item) => (
          <li
            key={item.id}
            className='side-bar-item'
            data-dark-mode={darkMode.toString()}
            data-selected={activeItemId === item.id}
          >
            <span style={{ width: '100%' }} onClick={() => setActiveItemId(item.id)}>
              {item.name.length > 0 ? Utilities.truncateText(item.name, 16) : '...'}
            </span>
            {removeItemHandler && (
              <Button
                icon={getIcon('remove')}
                className={`btn-borderless btn-remove ${darkMode ? 'dark' : ''}`}
                clickHandler={() => removeItemHandler(item.id)}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(SideBar);
