import { Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { ActionAttachment } from '../ActionAttachment';
import {
  CommunicationDialogNode,
  ISerializedCommunicationDialogNode,
} from '../CommunicationDialogNode';

export abstract class QuestionDialogNode extends CommunicationDialogNode {
  @Expose()
  autoRecognitionEnabled: boolean = true;

  @Expose()
  skipIfKnown: boolean = false;

  constructor(block: DialogBlock) {
    super(block);

    makeObservable(this, {
      type: observable,
      skipIfKnown: observable,
      autoRecognitionEnabled: observable,
      forceTextInput: computed,
      handleForceTextInputChange: action,
    });
  }

  get forceTextInput() {
    return this.actions.find((item) => item.type === 'forceTextInput');
  }

  addAction(action: ActionAttachment) {
    super.addAction(action);

    if (action.type === 'forceTextInput') {
      this.handleForceTextInputChange();
    }
  }

  handleForceTextInputChange() {
    if (this.activateMicrophone) {
      this.removeAction(this.activateMicrophone!);
    }
  }

  serialize(): ISerializedQuestionDialogNode {
    return {
      ...super.serialize(),
      skipIfKnown: this.skipIfKnown,
      autoRecognitionEnabled: this.autoRecognitionEnabled,
    };
  }
}

export interface ISerializedQuestionDialogNode
  extends ISerializedCommunicationDialogNode {
  skipIfKnown: boolean;
  autoRecognitionEnabled: boolean;
}
