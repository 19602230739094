import { Expose, Transform, TransformFnParams } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { PropertyState, PropertyType } from '../../../architecture/enums/Dataset';
import DatasetStore from '../../../stores/DatasetStore';
import { Property } from './Property';

const TransformIntoDatasetFromId: PropertyDecorator = Transform(
  ({ obj }: TransformFnParams) => DatasetStore.getInstance()?.getById(obj.datasetId)?.name
);

export class DatasetProperty extends Property {
  @Expose()
  @TransformIntoDatasetFromId
  dataset: string;

  @Expose()
  datasetId: string;

  constructor(
    key: string,
    annotation: string,
    dataset: string,
    datasetId?: string,
    id?: string | null,
    state?: PropertyState
  ) {
    super(PropertyType.Dataset, key, annotation, id, state);
    this.dataset = dataset;
    this.datasetId = datasetId || '';

    makeObservable(this, {
      dataset: observable,
      datasetId: observable,
    });
  }

  serialize() {
    return {
      ...super.serialize(),
      datasetId: this.datasetId,
      isList: false,
    };
  }
}
