import { Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

export class DailyBillingSummary {
  @Expose()
  id: string;

  @Expose()
  communicationActivities: number;

  @Expose()
  conversationThreads: number;

  @Expose()
  conversations: number;

  @Expose()
  @Type(() => Date)
  date: Date;

  @Expose()
  costs: number;

  @Expose()
  credits: number;

  constructor() {
    this.id = v4();
    this.date = new Date();
    this.costs = 0;
    this.credits = 0;
    this.communicationActivities = 0;
    this.conversationThreads = 0;
    this.conversations = 0;

    makeObservable(this, {
      id: observable,
      date: observable,
      costs: observable,
      credits: observable,
      communicationActivities: observable,
      conversationThreads: observable,
      conversations: observable,
    });
  }
}
