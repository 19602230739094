import { useMemo } from 'react';
import { useLocation } from 'react-router';
export function useQuery() {
  const { search } = useLocation();

  const queryParams = useMemo(() => {
    const params = new URLSearchParams(search);
    const queryObject: { [key: string]: string } = {};

    for (const [key, value] of params.entries()) {
      queryObject[key] = value;
    }

    return queryObject;
  }, [search]);

  return queryParams;
}
