import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect } from 'react';
import { DialogBlock as DialogBlockModel } from '../../../models/DialogBlocks/DialogBlock';
import { RegexInputTrigger as RegexInputTriggerModel } from '../../../models/Triggers/RegexInputTrigger';
import Button from '../../common/Button';
import Input from '../../common/Input';
import { Utilities } from '../../../models/Utilities/Utilities';
import { useGetIcon } from '../../customHooks/useGetIcon';
import { useReadOnly } from '../../customHooks/useReadOnly';

interface IProps {
  block: DialogBlockModel;
}

const RegexInputTrigger: React.FC<IProps> = ({ block }) => {
  const closeIcon = useGetIcon()('close');
  const trigger = block.trigger as RegexInputTriggerModel;
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  useEffect(() => {
    return () => {
      if (!trigger.isValid) {
        block.removeTrigger();
      }
    };
  }, []);

  const handleDelete = () => {
    trigger.pattern = '';
  };

  const updateTriggerValue = (value: string) => {
    trigger.pattern = value;
  };

  return (
    <Fragment>
      <div className='input-field-with-btn'>
        <Input
          disabled={readOnly}
          name='user-input-trigger-values'
          value={trigger.pattern}
          textAlign='center'
          autoFocus
          changeHandler={updateTriggerValue}
        />
        <Button
          disabled={readOnly}
          icon={closeIcon}
          className={'btn-remove'}
          clickHandler={handleDelete}
        />
      </div>
    </Fragment>
  );
};

export default observer(RegexInputTrigger);
