import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { HEADERS } from '../../architecture/constants/Knowledgebase';
import { TableData } from '../../architecture/enums/TableData';
import { KnowledgebaseEntry } from '../../models/Knowledgebase/KnowledgebaseEntry';
import Table from '../common/table/Table';
import Actions from './cells/Actions';
import Answer from './cells/Answer';
import Questions from './cells/Questions';

interface IProps {
  knowledgebaseEntries: KnowledgebaseEntry[];
}

const KnowledgebaseList: React.FC<IProps> = ({ knowledgebaseEntries }) => {
  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: HEADERS.questions.id,
        Header: HEADERS.questions.label,
        accessor: (entry: TableData) => (entry as KnowledgebaseEntry).questions,
        minWidth: 200,
        Cell: (item: Cell<KnowledgebaseEntry>) => <Questions entry={item.row.original} />,
      },
      {
        id: HEADERS.answer.id,
        Header: HEADERS.answer.label,
        accessor: (entry: TableData) => (entry as KnowledgebaseEntry).answer,
        minWidth: 200,
        Cell: (item: Cell<KnowledgebaseEntry>) => <Answer entry={item.row.original} />,
      },
      {
        id: HEADERS.actions.id,
        Header: HEADERS.actions.label,
        width: 35,
        Cell: (item: Cell<KnowledgebaseEntry>) => <Actions entry={item.row.original} />,
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    if (!knowledgebaseEntries) {
      return [];
    }

    return knowledgebaseEntries.map((knowledgebaseEntry: KnowledgebaseEntry) => {
      return knowledgebaseEntry;
    });
  }, [JSON.stringify(knowledgebaseEntries)]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  return (
    <Table
      getTableProps={getTableProps}
      getTableBodyProps={getTableBodyProps}
      headerGroups={headerGroups}
      rows={rows}
      prepareRow={prepareRow}
      classRow='bordered-tr'
    />
  );
};

export default observer(KnowledgebaseList);
