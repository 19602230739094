import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { PropertyType } from '../../../../architecture/enums/Dataset';
import { IProperty } from '../../../../architecture/interfaces/dataset/IProperty';
import { DatasetProperty } from '../../../../models/Dataset/Property/DatasetProperty';
import { StorageEntry } from '../../../../models/Dataset/StorageEntry';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  propertyKey: string;
  item: StorageEntry;
}

const DatasetCell: React.FC<IProps> = ({ propertyKey, item }) => {
  const { datasetStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  // Definition contains e.g. annotation or type (Simple, Dataset, ListDataset) of this property.
  const propertyDefinition = datasetStore.getSelectedDataset?.properties.find(
    (prop) => prop.key === propertyKey
  );

  if (!propertyDefinition) {
    throw Error('No defintion for this dataset.');
  }

  // Value can either be a simple value, an object or array.
  const deserializedValue = JSON.parse(item.data);
  if (!deserializedValue.hasOwnProperty(propertyKey)) {
    return <></>;
  }

  const propertyValue = deserializedValue[propertyKey];
  if (!propertyValue) {
    return <></>;
  }

  const renderObjectCell = () => {
    // An icon and the dataset's name is shown for more complex properties (i.e. arrays and objects).
    return (
      <div className='link'>
        <i className={`${getIcon('link')}`}></i>{' '}
        {(propertyDefinition as DatasetProperty).dataset}
        {renderTooltip()}
      </div>
    );
  };

  const renderTooltip = () => {
    // Get the complete definition of the dataset, which also contains the nested properties.
    const datasetDefinition = datasetStore.getById(
      (propertyDefinition as DatasetProperty).datasetId
    );

    if (!datasetDefinition?.properties) {
      return <></>;
    }

    if (propertyDefinition.type === PropertyType.Dataset) {
      return (
        <div className='link-tooltip tooltip-bottom'>
          {renderObjectInTooltip(datasetDefinition?.properties, propertyValue)}
        </div>
      );
    }

    if (propertyDefinition.type === PropertyType.ListDataset) {
      if (propertyValue.length === 0) return <></>;

      return (
        <div className='link-tooltip tooltip-bottom'>
          {propertyValue.map((element: any, index: number) => {
            return (
              <div key={index}>
                {index !== 0 && propertyValue.length > 1 && (
                  <div key={index} className={'border'}></div>
                )}
                {renderObjectInTooltip(datasetDefinition?.properties, element)}
              </div>
            );
          })}
        </div>
      );
    }
  };

  const renderObjectInTooltip = (properties: IProperty[], values: any) => {
    return (
      <>
        {properties.map((property, index) => {
          return (
            <div key={index} className='property'>
              <span className='property-name'>{property.annotation}:</span>
              <span className='ml-5'>
                {property.type === PropertyType.Simple && values[property.key]}
                {property.type !== PropertyType.Simple && (
                  <>
                    <i className={`${getIcon('link')}`}></i>
                    {property.dataset}
                  </>
                )}
              </span>
            </div>
          );
        })}
      </>
    );
  };

  switch (propertyDefinition.type) {
    case PropertyType.Simple:
      return <>{propertyValue}</>;
    case PropertyType.Dataset:
    case PropertyType.ListDataset:
      return renderObjectCell();
  }
};

export default observer(DatasetCell);
