import { observer } from 'mobx-react-lite';
import React from 'react';
import { MessageDialogNode as MessageDialogNodeModel } from '../../../../models/DialogNodes/MessageNodes/MessageDialogNode';
import CommunicationDialogNode from '../communicationNode/CommunicationDialogNode';

interface IProps {
  dialogNode: MessageDialogNodeModel;
}

const MessageDialogNode: React.FC<IProps> = ({ dialogNode }) => {
  return <CommunicationDialogNode dialogNode={dialogNode} />;
};

export default observer(MessageDialogNode);
