import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { NameKey } from '../../../../architecture/enums/SubQuestionKey';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { MultiQuestionNode } from '../MultiQuestionNode/MultiQuestionNode';
export class NameQuestionNode extends MultiQuestionNode {
  type = DialogNodeTypes.NameQuestionNode;
  constructor(block: DialogBlock) {
    super(block, Object.values(NameKey));

    this.title = 'Name Question Node';
  }
}
