import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import ConversationHistoryList from '../../conversationHistory/ConversationHistoryList';
import useModal from '../../customHooks/useModal';

interface IParamsProps {
  dialogId?: string;
}

const ConversationHistory = () => {
  const { dialogStore, conversationHistoryStore, uiStore } = useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();
  const { closeModal } = useModal();

  const load = async () => {
    await dialogStore.loadBots();
  };

  useEffect(() => {
    if (uiStore.showModal) {
      closeModal();
    }
    return () => {
      conversationHistoryStore.clearHistorySelection();
    };
  }, []);

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    if (!dialogStore.currentlyEditedDialog && params.dialogId) {
      dialogStore.setDialogById(params?.dialogId);
    }
  }, [dialogStore.bots, params]);

  return <ConversationHistoryList />;
};

export default observer(ConversationHistory);
