import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import Input from '../../../common/Input';
import TextareaJson from '../../../common/TextareaJson';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IActionProps {
  darkMode?: boolean;
  action: ActionAttachment;
}
const Action: React.FC<IActionProps> = ({ darkMode = false, action }) => {
  const getIcon = useGetIcon();

  const setData = (text: string) => {
    action.data = text;
  };

  return (
    <div className='action-body'>
      <div className='type-input'>
        <h4>
          <i className={getIcon('marker')}></i>Type
        </h4>
        <p>Insert a type that helps to identify the attachment</p>
        <Input
          name=''
          isValid={action.isTypeValid}
          value={action.type}
          changeHandler={(text: string) => (action.type = text)}
          autoFocus={action.type === ''}
          darkMode={darkMode}
        />
      </div>
      <div className='data-input'>
        <h4>
          <i className={getIcon('code')}></i>Additional data
        </h4>
        <p>Customize the action by inserting a valid JSON-Object below</p>
        <TextareaJson
          value={action.data}
          onChange={setData}
          onPaste={setData}
          staticHeight
          darkMode={darkMode}
        />
      </div>
    </div>
  );
};

export default observer(Action);
