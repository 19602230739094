import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Input from '../../common/Input';
import { FILTERS } from '../../../architecture/constants/Media';

interface IProps {
  handleParamsChange: (key: string, value: string) => void;
  clearParamsChange: (key: string) => void;
}

const SearchInput: React.FC<IProps> = ({ handleParamsChange, clearParamsChange }) => {
  const getIcon = useGetIcon();
  const location = useLocation();
  const params = queryString.parse(location.search);

  const [search, setSearch] = useState(params.name ? params.name.toString() : '');

  const keyDownHandler = (event: React.KeyboardEvent<Element>) => {
    if (event.key === 'Enter' && search) {
      handleParamsChange(FILTERS.NAME.NAME, search);
    } else if (event.key === 'Enter' && !search) {
      clearParamsChange(FILTERS.NAME.NAME);
    }
  };

  const blurHandler = () => {
    if (search) {
      handleParamsChange(FILTERS.NAME.NAME, search);
    } else {
      if (params[FILTERS.NAME.NAME]) {
        clearParamsChange(FILTERS.NAME.NAME);
      }
    }
  };

  return (
    <div className='search-input'>
      <i className={getIcon('filter')}></i>
      <Input
        value={search}
        autoFocus={true}
        className='filter-input'
        placeholder={`Filter by ${FILTERS.NAME.TITLE}`}
        changeHandler={setSearch}
        blurHandler={blurHandler}
        keyDownHandler={keyDownHandler}
      />
    </div>
  );
};

export default observer(SearchInput);
