import { useEffect } from 'react';

export default (condition: boolean | undefined = undefined) => {
  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      if (condition === undefined || condition) {
        e.returnValue = '';
      }
    };
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
    };
  }, [condition]);
};
