import { observer } from 'mobx-react-lite';
import React from 'react';
import { Route, Switch } from 'react-router';
import ChangePassword from './ChangePassword';
import PronunciationOverview from './PronunciationOverview';
import SettingsSelector from './SettingsSelector';
import SubscriptionSettings from './SubscriptionSettings';
import UpdateProfile from './UpdateProfile';

const SettingsPage = () => (
  <div className='settings-page content-with-subnavigation'>
    <Switch>
      <Route
        exact
        path='/settings'
        render={() => {
          return <SettingsSelector />;
        }}
      />
      <Route path='/settings/subscriptions' component={SubscriptionSettings} />
      <Route path={'/settings/pronunciation'} component={PronunciationOverview} />
      <Route path='/settings/profile' component={UpdateProfile} />
      <Route path='/settings/password' component={ChangePassword} />
    </Switch>
  </div>
);

export default observer(SettingsPage);
