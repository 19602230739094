import React from 'react';
import { observer } from 'mobx-react-lite';
import { Media } from '../../../../../models/Media/Media';
import { useGetDocumentMediaFileIcon } from '../../../../customHooks/useGetDocumentMediaFileIcon';
import {
  VIDEOS_CONTENT_TYPE,
  IMAGES_CONTENT_TYPE,
} from '../../../../../architecture/constants/Media';
import Tooltip from './Tooltip';

interface IProps {
  media: Media;
  index: number;
}

const MediaFile: React.FC<IProps> = ({ media, index }) => {
  const getDocumentMediaFileIcon = useGetDocumentMediaFileIcon();

  return (
    <div className='library-item'>
      {' '}
      {VIDEOS_CONTENT_TYPE.includes(media.contentType) ? (
        <video src={`${media.url}#t=1`} width='100%' height='100%'></video>
      ) : IMAGES_CONTENT_TYPE.includes(media.contentType) ? (
        <img src={media.url} width='100%' height='100%' style={{ objectFit: 'cover' }} />
      ) : (
        <div className='document-item'>
          <i className={`${getDocumentMediaFileIcon(media)} document-icon`}> </i>
        </div>
      )}
      <Tooltip media={media} index={index} />
    </div>
  );
};

export default observer(MediaFile);
