import React, { CSSProperties } from 'react';
import InfoIcon from './InfoIcon';

interface IProps {
  message: string;
  type: 'warning' | 'error' | 'info';
  style?: {
    textStyle: CSSProperties;
    iconStyle: CSSProperties;
  };
}
const InfoMessage: React.FC<IProps> = ({ message, type, style }) => {
  return (
    <div className={`info-message ${type}`} style={style?.textStyle}>
      <InfoIcon type={type} style={style?.iconStyle} />
      <p className='info-message-text'>{message}</p>
    </div>
  );
};

export default InfoMessage;
