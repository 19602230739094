import { makeObservable, observable } from 'mobx';

export class Dialog {
  constructor(
    public created: Date,
    public creator: string,
    public dialogId: string,
    public editorStructure: string,
    public lastEditor: string,
    public locale: string,
    public modified: Date | null,
    public outdated: Date | null,
    public published: Date | null,
    public version: string,
    public versionDescription: string,
    public versionName: string
  ) {
    makeObservable(this, {
      editorStructure: observable,
      lastEditor: observable,
      modified: observable,
      outdated: observable,
      versionDescription: observable,
      versionName: observable,
    });
  }

  get isActive() {
    return this.outdated === null;
  }
}
