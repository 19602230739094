import { format } from 'date-fns';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { useLocalStorage } from 'use-hooks';

import { observer } from 'mobx-react-lite';
import {
  CHANNEL_OPTIONS,
  FILTERS,
} from '../../architecture/constants/ConversationHistory';
import { CONVERSATION_HISTORY_SCROLL } from '../../architecture/constants/LocalStorage';
import { ConversationThread } from '../../models/ConversationHistory/ConversationThread';
import { Notification } from '../../models/Utilities/Notification';
import rootStore from '../../stores/rootStore';
import Button from '../common/Button';
import { useGetIcon } from '../customHooks/useGetIcon';
interface IProps {
  thread: ConversationThread;
  load: () => void;
  handleParamsChange: (key: string, value: string) => void;
}

const ConversationThreadRow: React.FC<IProps> = ({
  thread,
  load,
  handleParamsChange,
}) => {
  const { conversationHistoryStore, dialogStore } = useContext(rootStore);
  const [scrollYStorage, setScrollYStorage] = useLocalStorage(
    CONVERSATION_HISTORY_SCROLL,
    0
  );
  const history = useHistory();
  const getIcon = useGetIcon();

  const updateConversationReviewState = async (reviewed: boolean) => {
    await conversationHistoryStore.updateConversationReviewState(
      thread.conversationThreadId,
      reviewed
    );
  };

  const setConversationAsReviewed = (reviewed: boolean) => {
    updateConversationReviewState(reviewed);
  };

  const getTriggerTypeIcon = () => {
    let icon;
    switch (thread.triggerType) {
      case 'ListTrigger':
        icon = getIcon('list_trigger');
        break;
      case 'RegexTrigger':
        icon = getIcon('regex_trigger');
        break;
      case 'EventTrigger':
        icon = getIcon('event_trigger');
        break;
      case 'LuisIntentTrigger':
        icon = getIcon('intent_trigger');
        break;
      case 'KnowledgebaseTrigger':
        icon = getIcon('robot');
        break;
      case 'DefaultTrigger':
        icon = getIcon('default_trigger');
        break;
      case 'AiTrigger':
        icon = getIcon('brain');
        break;
      default:
        icon = getIcon('list_trigger');
        break;
    }

    return <i className={`${icon} corner-icon`}> </i>;
  };

  const openConversationThread = async () => {
    if (dialogStore?.currentlyEditedDialog && thread) {
      history.push(
        `/dialogs/${dialogStore.currentlyEditedDialog.dialogId}/history/${thread.conversationThreadId}?version=${thread.dialogVersion}`
      );
      await dialogStore.setDialogToEdit(
        dialogStore.currentlyEditedDialog,
        thread.dialogVersion
      );
      window.scrollTo({ top: 0, left: 0 });
    }
  };

  const openBlockOverviewPage = async () => {
    if (dialogStore?.currentlyEditedDialog && thread) {
      history.push(
        `/dialogs/${dialogStore.currentlyEditedDialog.dialogId}/history/${thread.conversationThreadId}/designer?version=${thread.dialogVersion}`
      );
      await dialogStore.setDialogToEdit(
        dialogStore.currentlyEditedDialog,
        thread.dialogVersion
      );
      window.scrollTo({ top: 0, left: 0 });
    }
  };

  const saveToClipboard = (value: string | null) => {
    if (value) {
      navigator.clipboard.writeText(value);
      new Notification({
        text: 'Copied',
        type: 'success',
      });
    }
  };

  return (
    <div
      id='thread-container'
      className={thread.reviewed ? 'reviewed-thread' : ''}
      onClick={openConversationThread}
    >
      {getTriggerTypeIcon()}
      <div className='thread-heading'>
        <h4 className='thread-datetime'>
          {thread.created ? format(new Date(thread.created), 'dd.MM.yyyy HH:mm') : ''}
        </h4>
        <p className='thread-channel-text'>
          <span>via </span>
          <span className='thread-channel'>
            {CHANNEL_OPTIONS.find((option) => option.label === thread.channel)?.title}
          </span>
        </p>
        <span>
          <Button
            className='btn-borderless thread-channel-icon'
            tooltip={'Click to search for all conversations\nin this session.'}
            tooltipPosition='top'
            icon={getIcon('message')}
            clickHandler={(e) => {
              e.stopPropagation();
              handleParamsChange(FILTERS.SESSION_ID.NAME, thread.sessionId);
            }}
          />
          {!!thread.credits && (
            <p className='billing-info'>
              <span className='amount'>{thread.credits.toString().slice(0, 8)}</span>
              <i className={getIcon('coins')}></i>
            </p>
          )}
          {!!thread.costs && (
            <p className='billing-info'>
              <span className='amount'>{thread.costs.toString().slice(0, 8)}</span>
              <i className={getIcon('euro')}></i>
            </p>
          )}
        </span>
        <div className='redirect-buttons'>
          <Button
            icon={getIcon('history')}
            tooltip='Click to show conversation thread details'
            tooltipPosition='top'
            className={'btn-borderless btn-history-header'}
            clickHandler={(e) => {
              e.stopPropagation();
              openConversationThread();
              setScrollYStorage(window.scrollY);
            }}
          />
          <Button
            icon={getIcon('network')}
            tooltip='Click to show block overview'
            tooltipPosition='top'
            className={'btn-borderless btn-history-header'}
            clickHandler={(e) => {
              e.stopPropagation();
              openBlockOverviewPage();
            }}
          />
          <Button
            className='btn-borderless btn-history-header'
            data-reviewed={!!thread.reviewed ? 'true' : 'false'}
            tooltip={
              !!thread.reviewed
                ? 'Click to mark this conversation\nas not reviewed'
                : 'Click to mark this conversation\nas reviewed'
            }
            tooltipPosition='top'
            icon={thread.reviewed !== null ? getIcon('eye') : getIcon('eye_closed')}
            clickHandler={(e) => {
              e.stopPropagation();
              if (!!thread.reviewed) {
                setConversationAsReviewed(false);
              } else {
                setConversationAsReviewed(true);
              }
            }}
          />
        </div>
      </div>
      <div className='thread-content'>
        <div className='first-row'>
          <div className='message'>
            <i
              className={getIcon('comment')}
              onClick={(e) => {
                e.stopPropagation();
                saveToClipboard(thread.userMessage ?? thread.eventId);
              }}
            />
            {thread.eventId ? (
              <span className='incoming-message'>{thread.eventId}</span>
            ) : (
              <span className='incoming-message'>{thread.userMessage}</span>
            )}
          </div>
          <div className='triggered-block-name'>
            <i className={getIcon('caret_right')} />
            {thread.triggeredBlockName}
            <span>{thread.score ? ` (${(thread.score * 100).toFixed(2)}%)` : ''}</span>
          </div>
        </div>
        {thread.botMessage ? (
          <div className='message'>
            <i
              className={getIcon('comments')}
              onClick={(e) => {
                e.stopPropagation();
                saveToClipboard(thread.botMessage);
              }}
            />
            <span className='bot-message'>{thread.botMessage}</span>
          </div>
        ) : (
          <span></span>
        )}
      </div>
    </div>
  );
};

export default observer(ConversationThreadRow);
