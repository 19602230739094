import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ActionAttachment } from '../../../../models/DialogNodes/ActionAttachment';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import Action from '../../../dialogElements/nodes/communicationNode/Action';
import { SideBarEditor } from '../../../layout/sideBarEditor/SideBarEditor';

interface IActionAttachmentsProps {
  entry: KnowledgebaseEntry;
}

const Actions: React.FC<IActionAttachmentsProps> = ({ entry }) => {
  const [activeActionId, setActiveActionId] = useState<string | undefined>(
    entry.genericActions.length > 0 ? entry.actions[0].id : undefined
  );

  const getIcon = useGetIcon();

  const activeAction = entry.genericActions.find(
    (action) => action.id === activeActionId
  );

  const setFirstActionAsActive = () => {
    if (entry.genericActions.length > 0) {
      setActiveActionId(entry.genericActions[0].id);
    }
  };

  const handleAddNewAction = () => {
    entry.addAction(new ActionAttachment());
    setFirstActionAsActive();
  };

  const handleRemoveAction = (id: string) => {
    if (id === activeActionId) {
      setActiveActionId(undefined);
    }

    const action = entry.genericActions.find((action) => action.id === id);
    if (!action) return;

    entry.removeAction(action);
  };

  return (
    <div className='action-attachments'>
      <SideBarEditor.Base>
        <SideBarEditor.SideBar
          items={entry.genericActions.map((action) => ({
            id: action.id,
            name: action.type,
          }))}
          darkMode={true}
          activeItemId={activeActionId}
          setActiveItemId={setActiveActionId}
          removeItemHandler={handleRemoveAction}
        >
          <Button
            icon={getIcon('add')}
            content='Add New Action'
            className='btn-borderless add-action-btn'
            clickHandler={handleAddNewAction}
            disabled={false}
          />
        </SideBarEditor.SideBar>
        <SideBarEditor.Editor darkMode={true}>
          {activeAction ? (
            <Action key={activeAction?.id} action={activeAction} darkMode={true} />
          ) : (
            <div className='action-attachments-title'>
              Select or create an Action Attachment
            </div>
          )}
        </SideBarEditor.Editor>
      </SideBarEditor.Base>
    </div>
  );
};

export default observer(Actions);
