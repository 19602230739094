import React, { useContext } from 'react';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

// Currently not handled properly. If we want to use this feature, we should
// restore at least the default block and must also take care of the
// knowledgebase. If the knowledgebase is deleted, all entries must be set
// to the 'Deleted' state.
const PurgeBlocks = () => {
  const { serializationStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const { closeModal } = useModal();

  const handleDelete = () => {
    serializationStore.clearStores();
    closeModal();
  };

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>Are you sure you want to remove all the blocks?</h3>
        <p className='header-description'>
          This is practically a hard reset of your dialog. All the blocks, nodes, context
          variables and triggers will be removed.
        </p>
      </div>
      <div className='btn-container'>
        <Button
          content='Delete'
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleDelete}
        />
        <Button
          content='Cancel'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={() => closeModal()}
        />
      </div>
    </div>
  );
};

export default PurgeBlocks;
