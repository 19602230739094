import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Route, Switch } from 'react-router';
import rootStore from '../../../stores/rootStore';
import BlockOverview from './BlockOverview';
import BotSettingsOverview from './BotSettingsOverview';
import ConversationHistory from './ConversationHistory';
import ConversationHistoryBlockOverview from './ConversationHistoryBlockOverview';
import ConversationThreadOverview from './ConversationThreadOverview';
import CreditsOverview from './CreditsOverview';
import Designer from './Designer';
import DialogDetails from './DialogDetails';
import KnowledgebaseOverview from './KnowledgebaseOverview';

const Dashboard = () => {
  // Context | Stores
  const { appStore } = useContext(rootStore);

  useEffect(() => {
    return () => {
      appStore.resetDialogSelection();
    };
  }, []);

  return (
    <div id='dashboard' className='content-with-subnavigation'>
      <Switch>
        <Route exact path='/dialogs/:dialogId/designer'>
          <Designer>
            <BlockOverview />
          </Designer>
        </Route>
        <Route exact path='/dialogs/:dialogId/blocks/:blockId'>
          <Designer>
            <DialogDetails />
          </Designer>
        </Route>
        <Route exact path='/dialogs/:dialogId/history' component={ConversationHistory} />
        <Route
          exact
          path='/dialogs/:dialogId/history/:conversationThreadId/designer'
          component={ConversationHistoryBlockOverview}
        />
        <Route
          exact
          path='/dialogs/:dialogId/history/:conversationThreadId'
          component={ConversationThreadOverview}
        />
        <Route
          exact
          path={[
            '/dialogs/:dialogId/knowledgebase',
            '/dialogs/:dialogId/history/:conversationThreadId/knowledgebase',
          ]}
          component={KnowledgebaseOverview}
        />
        <Route path='/dialogs/:dialogId/bot-settings' component={BotSettingsOverview} />
        <Route exact path='/dialogs/:dialogId/credits' component={CreditsOverview} />
      </Switch>
    </div>
  );
};

export default observer(Dashboard);
