import { Exclude, Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { ConditionTypes } from '../../architecture/enums/Condition';

export abstract class Condition {
  @Expose()
  id: string;

  @Exclude()
  type: ConditionTypes = ConditionTypes.Undefined;
  constructor(id?: string) {
    this.id = id ? id : v4();

    makeObservable(this, {
      id: observable,
      isValid: computed,
    });
  }
  get isValid(): boolean {
    return true;
  }
  serialize(): ISerializedCondition {
    return {
      id: this.id,
      type: this.type,
    };
  }
}

export interface ISerializedCondition {
  id: string;
  type: ConditionTypes;
}
