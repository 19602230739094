import { observer } from 'mobx-react-lite';
import React from 'react';
import { UserReceiver as UserReceiverModel } from '../../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/UserReceiver';
import TextBoxWithCtx from '../../../../../../common/inputElements/TextBoxWithCtx';
import { useReadOnly } from '../../../../../../customHooks/useReadOnly';

interface IProps {
  receiver: UserReceiverModel;
}

const UserReceiver: React.FC<IProps> = ({ receiver }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  return (
    <TextBoxWithCtx
      disabled={readOnly}
      title='User Name'
      value={receiver.username}
      saveHandler={(text: string) => (receiver.username = text)}
    />
  );
};

export default observer(UserReceiver);
