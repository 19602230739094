import { Expose, Transform } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { ContextVariable } from '../../../ContextVariables/ContextVariable';
import { TransformIntoTextArray } from '../../../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../../../Utilities/Text';
import { Utilities } from '../../../Utilities/Utilities';

export interface IGroupedSubQuestionGroup {
  key: string;
  contextVariable: ContextVariable;
}

export class GroupedSubQuestion {
  @Expose()
  groupKey: string;

  @Expose()
  @TransformIntoTextArray
  questions: Text[];

  @Expose({ name: 'groups' })
  @Transform(({ value }) =>
    Utilities.isEmpty(value)
      ? []
      : value.map((item: ISerializedGroupedSubQuestionContextVariables) => ({
          key: item.key,
          contextVariable: ContextVariable.getFromStore(item.ctxId),
        }))
  )
  contextVariables: IGroupedSubQuestionGroup[];

  constructor(groupKey: string) {
    this.groupKey = groupKey;
    this.questions = [new Text('', '')];
    this.contextVariables = [];

    makeObservable(this, {
      groupKey: observable,
      questions: observable,
      contextVariables: observable,
      areQuestionsValid: computed,
      areContextVariablesValid: computed,
    });
  }

  get isValid() {
    return (
      !Utilities.isEmpty(this.groupKey) &&
      this.areQuestionsValid &&
      this.areContextVariablesValid
    );
  }

  get areQuestionsValid() {
    return (
      this.questions.length > 0 &&
      this.questions.every((question) => question.value.length > 0)
    );
  }

  get areContextVariablesValid() {
    return (
      !Utilities.isEmpty(this.contextVariables) &&
      this.contextVariables.every((item) => item.contextVariable != undefined)
    );
  }

  addQuestion(text: Text | string) {
    if (typeof text === 'string') {
      text = new Text(text);
    }

    this.questions.push(text);
  }

  removeQuestion(text: Text) {
    this.questions = this.questions.filter((item) => item !== text);
  }

  serialize(): ISerializedGroupedSubQuestion {
    return {
      groupKey: this.groupKey,
      questions: this.questions.map((question) => question.serialize()),
      groups: this.contextVariables.map((item) => ({
        key: item.key,
        ctxId: item.contextVariable.id,
      })),
    };
  }
}

export interface ISerializedGroupedSubQuestion {
  groupKey: string;
  questions: ISerializedText[];
  groups: ISerializedGroupedSubQuestionContextVariables[];
}

export interface ISerializedGroupedSubQuestionContextVariables {
  key: string;
  ctxId: string;
}
