import { DialogBlockTypes } from '../../architecture/enums/DialogComponentType';
import NodeStore from '../../stores/NodeStore';
import { KnowledgebaseNode } from '../DialogNodes/KnowledgebaseNode';
import { RootNode } from '../DialogNodes/RootNode';
import { Position } from '../Utilities/Position';
import { DialogBlock } from './DialogBlock';

export class KnowledgebaseBlock extends DialogBlock {
  type = DialogBlockTypes.KnowledgebaseBlock;
  constructor() {
    super();

    this.title = 'Knowledgebase Block';
    this.position = new Position(window.innerWidth - this.geometry.width - 20, 215);
  }

  createDefaultNodes() {
    const rootNode = new RootNode(this);
    NodeStore.getInstance().append(rootNode);

    const knowledgebaseNode = new KnowledgebaseNode(this);
    NodeStore.getInstance().append(knowledgebaseNode);
  }
}
