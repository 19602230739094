import { Exclude, Expose } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../architecture/enums/DialogComponentType';
import { BaseDialogComponent, ISerializedDialogComponent } from '../BaseDialogComponent';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { InvalidArgumentError } from '../errors/InvalidArgumentError';
import { InvalidOperationError } from '../errors/InvalidOperationError';
import { TransformIntoDialogBlock } from '../Utilities/Deserialization/Decorators';
import { RootNode } from './RootNode';

export abstract class BaseDialogNode extends BaseDialogComponent {
  @Exclude()
  type: DialogNodeTypes = DialogNodeTypes.Undefined;

  @Expose({ name: 'dialogBlockId' })
  @TransformIntoDialogBlock
  dialogBlock: DialogBlock;
  constructor(dialogBlock: DialogBlock) {
    super();

    this.dialogBlock = dialogBlock;

    makeObservable(this, {
      dialogBlock: observable,
      isValid: computed,
      isBlockRoot: computed,
      moveDown: action,
      moveUp: action,
      setBlockParent: action,
      isBlockLeaf: action,
      isInSameDialogBlockAs: action,
      setBlockChild: action,
    });
  }

  get isRedirectable() {
    return false;
  }
  get isValid(): boolean {
    return true;
  }

  get isBlockRoot(): boolean {
    return this.type === DialogNodeTypes.RootNode;
  }

  /**
   * Returns that child of the current DialogNode which is part of the same DialogBlock.
   */
  getBlockChild() {
    const children = super.getChildren() as BaseDialogNode[];
    return children.find(
      (x) => x.isInSameDialogBlockAs(this) && !(x instanceof RootNode)
    );
  }

  /**
   * Returns that parent of the current DialogNode which is part of the same DialogBlock.
   */
  getBlockParent() {
    const parents = super.getParents() as BaseDialogNode[];
    return parents.find((x) => x.isInSameDialogBlockAs(this));
  }

  /**
   * Returns all those children fo the current DialogNode which are not in the same DialogBlock.
   */
  getNonBlockChildren() {
    if (!this.dialogBlock) {
      throw new InvalidOperationError(
        'Dialog Block must be set for this node to call this method.'
      );
    }

    return (this.getChildren() as BaseDialogNode[]).filter(
      (x) => !x.isInSameDialogBlockAs(this)
    );
  }

  /**
   * Returns all those parents of the current DialogNode which are not in the same DialogBlock.
   */
  getNonBlockParents() {
    if (!this.dialogBlock) {
      throw new InvalidOperationError(
        'Dialog Block must be set for this node to call this method.'
      );
    }

    return (this.getParents() as BaseDialogNode[]).filter(
      (x) => !x.isInSameDialogBlockAs(this)
    );
  }

  /**
   * Returns true if the current DialogNode hasn't any child that is part of the same DialogBlock, else false.
   */
  isBlockLeaf() {
    const children = super.getChildren() as BaseDialogNode[];
    return (
      this.isLeaf ||
      children
        .filter((child) => child !== this.dialogBlock.rootNode)
        .every((x) => !x.isInSameDialogBlockAs(this))
    );
  }

  /**
   * Returns true if the current node is in the same Dialog block as the DialogNode given.
   * @param dialogNode The dialog node whose dialog block shall be compared with the one of the current node.
   */
  isInSameDialogBlockAs(dialogNode: BaseDialogNode) {
    return this.dialogBlock === dialogNode.dialogBlock;
  }

  /**
   * Not implemented so far
   */
  manageAttachements() {
    throw new Error();
  }

  /**
   * Moves down the current DialogNode one step within the Dialog Block.
   *
   * PLEASE NOTE: Moving down one step within the Dialog Block means that
   *      1.) from all available children that particular node C is seached which is part of the same Dialog Block as the current DialogNode N (if any is availiable)
   *      2.) the current node N becomes the child of C
   *      3.) all children and parents of N that are part of another Dialog Blocks than that one of N remain attached to N as they were before
   *      4.) the parent of N that is part of the same Dilaog Block as N (if any) is attached as new parent to C
   */
  moveDown() {
    if (this.isBlockLeaf() || this.type === DialogNodeTypes.RootNode) {
      return;
    }

    // Moving down means removing all references to the current BaseDialogeNode and afterwards
    // adding it as child to its current block child
    const blockParent = this.getBlockParent();
    const blockChild = this.getBlockChild()!;

    if (blockParent) {
      blockParent.removeChild(this);
      blockChild.removeParent(this);
      blockParent.addChild(blockChild);
    } else {
      blockChild.removeParent(this);
    }

    blockChild.setBlockChild(this);
  }

  /**
   * Moves up the current DialogNode one step within the Dialog Block.
   *
   * PLEASE NOTE: Moving up one step within the Dialog Block means that
   *      1.) from all available parents that particular node P is seached which is part of the same Dialog Block as the current DialogNode (if any is availiable)
   *      2.) the current node N becomes the parent of P
   *      3.) all children and parents of N that are part of another Dialog Blocks than that one of N remain attached to N as they were before
   *      4.) the child of N that is part of the same Dilaog Block as N (if any) is attached as new child to P
   */
  moveUp() {
    if (this.isBlockRoot || this.getBlockParent()?.type === DialogNodeTypes.RootNode) {
      return;
    }

    // Moving up means removing all references to the current BaseDialogeNode and afterwards
    // adding it as parent to its current block parent
    const blockParent = this.getBlockParent()!;
    const blockChild = this.getBlockChild();

    if (blockChild) {
      blockParent.removeChild(this);
      blockChild.removeParent(this);
      blockParent.addChild(blockChild);
    } else {
      blockParent.removeChild(this);
    }

    blockParent.setBlockParent(this);

    if (this.isBlockRoot) {
      // Update inter block references
      this.getBlockChild()!.replaceNonBlockParentsChild(this);
    }
  }

  /**
   * Removes the current node.
   */
  remove() {
    const parents = [...this.getParents()];
    const children = [...this.getChildren()];

    // If the node has no parents, nothing special has to be handled. The parent-relationship
    // for the node's children can be deleted.
    if (parents.length === 0) {
      children.forEach((child) => {
        child.removeParent(this);
      });
    } else {
      parents.forEach((parent) => {
        // If this node is the only node in the block, thus the block will be empty, remove the block connection.
        if (this.isBlockRoot && this.getChildren().length === 0) {
          (parent as BaseDialogNode).dialogBlock?.removeChild(this.dialogBlock!);
        }

        parent.removeChild(this);
        children.forEach((child) => {
          child.removeParent(this);

          if ((child as BaseDialogNode).isInSameDialogBlockAs(this)) {
            child.addParent(parent);
          }
        });
      });
    }
  }

  /***
   * During deserialization of BaseDialogNodes it is not possible to directly restore the children references from the ids found in the serialisation JSON string.
   * The reason for this is that at the time of deseialization it might happen that the referenced child node has not been deserialized so far. Additionally,
   * circular references also make it impossible to find a deserialization order. Thus, the only option is to add a subsequent step which restores the
   * children references after all BaseDialogNodes were deserialized. This is what this function does here.
   * In order to make this work, during the initial deserialization references are stored as string ids which in this function here are resolved to BaseDialogNode
   * objects.
   */
  restoreChildren() {
    // super.restoreChildren(NodeStore.getInstance());
  }

  /***
   * During deserialization of BaseDialogNodes it is not possible to directly restore the parent references from the ids found in the serialisation JSON string.
   * The reason for this is that at the time of deseialization it might happen that the referenced parent node has not been deserialized so far. Additionally,
   * circular references also make it impossible to find a deserialization order. Thus, the only option is to add a subsequent step which restores the
   * parent references after all BaseDialogNodes were deserialized. This is what this function does here.
   * In order to make this work, during the initial deserialization references are stored as string ids which in this function here are resolved to BaseDialogNode
   * objects.
   */
  restoreParents() {
    // super.restoreParents(NodeStore.getInstance());
  }

  /**
   * Sets the child of the current node N to the node C given if both are part of the same dialog block.
   *
   * PLEASE NOTE: The current node N and the child node C must be part of the same Dialog Block.
   * PLEASE NOTE: setting the given node C as child of the current node N means
   *      1.) Removing all references to or from nodes with the same Dialog Block as C that start or end at C
   *      2.) Removing that one child OC of N (if any) that has the same Dialog Block as N
   *      3.) Adding C as child to N
   *      4.) Adding OC as child of C
   * By doing so the linear structure of dialog nodes within the same dialog block is maintained.
   *
   * @param childNode The node that chall be added as child to the current node N. It must be part of the same dialog block as N or undefined.
   */

  setBlockChild(childNode: BaseDialogNode | undefined) {
    if (childNode && !childNode.isInSameDialogBlockAs(this)) {
      throw new InvalidArgumentError(
        'The DialogBlock of the given DialogNode does not match the one of the current DialogNode'
      );
    }

    this.addBlockChild(childNode);

    if (!childNode || this.dialogBlock !== childNode.dialogBlock) {
      return;
    }
  }

  /**
   * Sets the parent of the current node N to the node P given if both are part of the same dialog block.
   *
   * PLEASE NOTE: The current node N and the parent node P must be part of the same Dialog Block.
   * PLEASE NOTE: setting the given node P as parent of the current node N means
   *      1.) Removing all references to or from nodes with the same Dialog Block as P that start or end at P
   *      2.) Removing that one parent OP of N (if any) that has the same Dialog Block as N
   *      3.) Adding P as parent to N
   *      4.) Adding OP as parent of P
   * By doing so the linear structure of dialog nodes within the same dialog block is maintained.
   *
   * @param parentNode The node that shall be added as parent to the current node N. It must be part of the same dialog block as N or undefined.
   */

  setBlockParent(parentNode: BaseDialogNode | undefined) {
    if (parentNode && !parentNode.isInSameDialogBlockAs(this)) {
      throw new InvalidArgumentError(
        'The DialogBlock of the given DialogNode does not match the one of the current DialogNode'
      );
    }

    this.addBlockParent(parentNode);

    if (!parentNode || this.dialogBlock !== parentNode.dialogBlock) {
      return;
    }
  }

  serialize(): ISerializedDialogNode {
    const parentISerialized = super.serialize();
    return {
      ...parentISerialized,
      dialogBlockId: this.dialogBlock!.id,
      type: this.type,
    };
  }

  /**
   * Adds a given Dialog Node as child to the current node that is part of the same Dialog Block. For more information on how this works see the public method setChild (which makes use of this
   * private mehtod here).
   *
   * ATTENTION: Do really only use this method if you intend to lineary connect two Dialog Nodes of the same Dialog Block. If you generally want to add an arbitrary child to a given
   * Dialog Node possibly the method addChild is the one you are looking for.
   * @param blockChildNode The Dialog Node that shall be added as child to the current node N. It must be part of the same Dialog Block as N.
   */
  private addBlockChild(blockChildNode: BaseDialogNode | undefined) {
    // Remove any possible block references of the new block before inserting it
    if (blockChildNode && blockChildNode.getBlockParent()) {
      blockChildNode.removeParent(blockChildNode.getBlockParent()!);
    }
    if (blockChildNode && blockChildNode.getBlockChild()) {
      blockChildNode.removeChild(blockChildNode.getBlockChild()!);
    }

    if (!this.isBlockLeaf()) {
      if (blockChildNode) {
        const oldBlockChild = this.getBlockChild()!;
        super.removeChild(this.getBlockChild()!);
        super.addChild(blockChildNode);
        blockChildNode.addChild(oldBlockChild);
      } else {
        super.removeChild(this.getBlockChild()!);
      }
    } else {
      if (blockChildNode) {
        super.addChild(blockChildNode);
      }
    }
  }

  /**
   * Adds a given Dialog Node as parent to the current node that is part of the same Dialog Block. For more information on how this works see the public method setParent (which makes use of this
   * private mehtod here).
   *
   * ATTENTION: Do really only use this method if you intend to lineary connect two Dialog Nodes of the same Dialog Block. If you generally want to add an arbitrary parent to a given
   * Dialog Node possibly the method addParent is the one you are looking for.
   * @param blockParentNode The Dialog Node that shall be added as parent to the current node N. It must be part of the same Dialog Block as N.
   */

  private addBlockParent(blockParentNode: BaseDialogNode | undefined) {
    // Remove any possible block references of the new block before inserting it
    if (blockParentNode && blockParentNode.getBlockChild()) {
      blockParentNode.removeChild(blockParentNode.getBlockChild()!);
    }
    if (blockParentNode && blockParentNode.getBlockParent()) {
      blockParentNode.removeParent(blockParentNode.getBlockParent()!);
    }

    if (!this.isBlockRoot) {
      if (blockParentNode) {
        const oldBlockParent = this.getBlockParent()!;
        super.removeParent(this.getBlockParent()!);
        super.addParent(blockParentNode);
        blockParentNode.addParent(oldBlockParent);
      } else {
        super.removeParent(this.getBlockParent()!);
      }
    } else {
      if (blockParentNode) {
        super.addParent(blockParentNode);
      }
    }
  }

  /**
   * Replaces the child reference to the current DialogNode of all those parents which are not in the same block as this DialogNode by a reference to the given
   * DialogNode.
   * @param newChildDialogNode The new DialogNode to which all parents of the current DialogNode shall reference instead of the current DialogNode
   */
  private replaceNonBlockParentsChild(newChildDialogNode: BaseDialogNode) {
    for (let parent of this.getNonBlockParents()) {
      parent.replaceChild(this, newChildDialogNode);
    }
  }

  /**
   * Returns that particular child of the current Dialog Node N that is part of the same Dialog Node as N or undefined if none such child exists.
   * @returns The child node or undefined.
   */
  get blockChild(): BaseDialogNode | undefined {
    if (this.isBlockLeaf()) {
      return undefined;
    } else {
      return this.getBlockChild() as BaseDialogNode;
    }
  }

  /**
   * Returns that particular parent of the current Dialog Node N that is part of the same Dialog Node as N or undefined if none such parent exists.
   * @returns The parent node or undefined.
   */
  get blockParent(): BaseDialogNode | undefined {
    if (this.isBlockRoot) {
      return undefined;
    } else {
      return this.getBlockParent() as BaseDialogNode;
    }
  }
}

export interface ISerializedDialogNode extends ISerializedDialogComponent {
  dialogBlockId: string;
  type: DialogNodeTypes;
}
