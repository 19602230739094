import { makeObservable, observable } from 'mobx';

export class BaseHistoryElement {
  id: string;
  created: string;
  type: number;
  typeDescription: string;

  constructor() {
    this.id = '';
    this.created = '';
    this.type = 0;
    this.typeDescription = '';

    makeObservable(this, {
      id: observable,
      created: observable,
      type: observable,
      typeDescription: observable,
    });
  }
}
