import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import * as Yup from 'yup';
import { ICreateBotForm } from '../../../architecture/interfaces/admin/IBotForm';
import { ISubscriptionSettings } from '../../../architecture/interfaces/admin/ISubscriptionSettings';
import { DialogLanguages } from '../../../architecture/interfaces/dialogs/IDialogFormConfiguration';
import Button from '../../common/Button';
import Input from '../../common/Input';
import Switch from '../../common/inputElements/Switch';
import Select from '../../common/inputElements/select/Select';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  saveBot: (bot: ICreateBotForm) => void;
  resetModal?: () => void;
  subscriptionSettings?: ISubscriptionSettings;
}

const validationSchema = Yup.object({
  botName: Yup.string().required('This field is required'),
  startDateTime: Yup.string().optional(),
  expiryDate: Yup.string().optional(),
  language: Yup.string().required('This field is required'),
  initialCredits: Yup.number().optional(),
  billingPlanId: Yup.number().required('This field is required'),
  createTexture: Yup.boolean(),
  texture: Yup.string().when('createTexture', {
    is: true,
    then: Yup.string().required('This field is required'),
  }),
});

const CreateBot: React.FC<IProps> = ({ saveBot, resetModal, subscriptionSettings }) => {
  const { subscriptionAdminStore, botAdminStore } = useAdminStores();
  const getIcon = useGetIcon();

  if (!subscriptionSettings?.billingPlans || !subscriptionSettings.avatarTextures) {
    throw new Error('Subscription settings unknown');
  }

  const billingPlans = subscriptionSettings?.billingPlans;
  const textures = subscriptionSettings?.avatarTextures;

  const avatarTextureRepositorEmpty =
    botAdminStore.subscriptionSettings?.avatarTextures === undefined ||
    botAdminStore.subscriptionSettings?.avatarTextures.length === 0;

  const formik = useFormik({
    initialValues: {
      botName: '',
      startDateTime: new Date(),
      expiryDate: undefined,
      language: 'Deutsch',
      initialCredits: 0,
      billingPlanId: billingPlans[0].id,
      createTexture: false,
      avatarTexture: textures[0]?.name ?? '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: ICreateBotForm) => {
      const language = DialogLanguages[values.language as keyof typeof DialogLanguages];
      let adjustedValues = { ...values, language };
      if (values.expiryDate) {
        const expiryDate = new Date(values.expiryDate.setUTCHours(23, 59, 59, 999));
        adjustedValues = { ...adjustedValues, expiryDate };
      }

      if (values.startDateTime) {
        const startDateTime = new Date(values.startDateTime.setUTCHours(0, 0, 0, 0));
        adjustedValues = { ...adjustedValues, startDateTime };
      }

      saveBot(adjustedValues);
    },
  });

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Create Bot </h4>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          <div className='input-group'>
            <label htmlFor='subscription-name'>Subscription Name:</label>
            <Input
              id='subscription-name'
              disabled={true}
              value={subscriptionAdminStore.getSelectedSubscription?.name || ''}
              changeHandler={() => {}}
              actionIcon={getIcon('lock')}
            />
          </div>
          <div className='input-group'>
            <label htmlFor='bot-name'>Bot Name:</label>
            <Input
              id='bot-name'
              value={formik.values.botName}
              placeholder='Type bot name here …'
              changeHandler={(value) => formik.setFieldValue('botName', value)}
            />
            {formik.touched.botName && formik.errors.botName ? (
              <div className='validation-error'>{formik.errors.botName}</div>
            ) : null}
          </div>
          <div className='date-group'>
            <div className='input-group'>
              <label htmlFor='activation'>Activation:</label>
              <DateTimePicker
                calendarIcon={<i className={`${getIcon('caret_down')} date-icon`}> </i>}
                minDate={new Date()}
                calendarClassName='date-filter'
                value={formik.values.startDateTime}
                disableClock={true}
                format='dd.MM.yyyy'
                onChange={(date) => formik.setFieldValue('startDateTime', date)}
                required={false}
              />
              {formik.touched.startDateTime && formik.errors.startDateTime ? (
                <div className='validation-error'>{formik.errors.startDateTime}</div>
              ) : null}
            </div>
            <div className='input-group'>
              <label htmlFor='expiry-date'>Expiry Date:</label>
              <DateTimePicker
                calendarIcon={<i className={`${getIcon('caret_down')} date-icon`}> </i>}
                minDate={
                  formik.values.startDateTime ? formik.values.startDateTime : new Date()
                }
                calendarClassName='date-filter'
                value={formik.values.expiryDate}
                disableClock={true}
                format='dd.MM.yyyy'
                onChange={(date) => formik.setFieldValue('expiryDate', date)}
                required={false}
              />
              {formik.touched.expiryDate && formik.errors.expiryDate ? (
                <div className='validation-error'>{formik.errors.expiryDate}</div>
              ) : null}
            </div>
          </div>
          <div className='input-group'>
            <label htmlFor='bot-language'>Bot Language:</label>
            <Select
              id='bot-language'
              triggerLabel={formik.values.language}
              items={Object.entries(DialogLanguages).map(
                ([lang, code]: [string, string]) => ({
                  title: lang,
                  meta: code,
                })
              )}
              className='select-input'
              selectHandler={(value) => formik.setFieldValue('language', value)}
              closeOnSelect
              fullWidth
            />
            {formik.touched.language && formik.errors.language ? (
              <div className='validation-error'>{formik.errors.language}</div>
            ) : null}
          </div>
          <div className='input-group'>
            <div className='input-grid'>
              <div>
                <label htmlFor='initial-credits'>Initial credits:</label>
                <Input
                  inputType='number'
                  id='initial-credits'
                  value={formik.values.initialCredits.toString()}
                  placeholder='Type initial credits here...'
                  changeHandler={(value) => formik.setFieldValue('initialCredits', value)}
                />
                {formik.touched.initialCredits && formik.errors.initialCredits ? (
                  <div className='validation-error'>{formik.errors.initialCredits}</div>
                ) : null}
              </div>
              <div>
                <label htmlFor='billing-plan-id'>Billing Plan Id:</label>
                <Select
                  id='billing-plan-id'
                  triggerLabel={
                    billingPlans.find((plan) => plan.id === formik.values.billingPlanId)
                      ?.name!
                  }
                  items={billingPlans.map((plan) => ({
                    title: plan.name,
                  }))}
                  className='select-input'
                  selectHandler={(value) => {
                    const plan = billingPlans.find((plan) => plan.name === value);
                    formik.setFieldValue('billingPlanId', plan?.id);
                  }}
                  closeOnSelect
                  fullWidth
                />

                {formik.touched.billingPlanId && formik.errors.billingPlanId ? (
                  <div className='validation-error'>{formik.errors.billingPlanId}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='input-group'>
            <div className='select-input-grid'>
              <div>
                <label>Texture:</label>
                <Switch
                  value={
                    !avatarTextureRepositorEmpty ? formik.values.createTexture : false
                  }
                  clickHandler={() =>
                    formik.setFieldValue('createTexture', !formik.values.createTexture)
                  }
                />
              </div>
              <div>
                <label htmlFor='texture'></label>
                <Select
                  id='texture'
                  triggerLabel={formik.values.avatarTexture ?? ''}
                  items={subscriptionSettings.avatarTextures.map((texture) => ({
                    title: texture.name,
                  }))}
                  className='select-input'
                  selectHandler={(value) => formik.setFieldValue('texture', value)}
                  closeOnSelect
                  disabled={!formik.values.createTexture || avatarTextureRepositorEmpty}
                  fullWidth
                />
                {formik.touched.avatarTexture && formik.errors.avatarTexture ? (
                  <div className='validation-error'>{formik.errors.avatarTexture}</div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className='btn-container'>
          <Button
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={resetModal}
          />
          <Button type='submit' content='Add Bot' className='btn-primary btn-round' />
        </div>
      </form>
    </div>
  );
};

export default observer(CreateBot);
