import axios, { AxiosRequestConfig } from 'axios';
import { Expose, Transform, Type } from 'class-transformer';
import { action, computed, makeObservable, observable, reaction } from 'mobx';
import { ContextVariableInstanceTypes } from '../../../../architecture/enums/ContextVariableInstanceTypes';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { HttpRequestType } from '../../../../architecture/enums/RequestType';
import { PathElementTypes } from '../../../../architecture/interfaces/IPathElement';
import ContextVariableStore from '../../../../stores/ContextVariableStore';
import { ContextVariable } from '../../../ContextVariables/ContextVariable';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { JsonContainer } from '../../../Json/JsonContainer';
import { JsonElement } from '../../../Json/JsonElement';
import { JsonProcessor } from '../../../Json/JsonProcessor';
import { JsonValue } from '../../../Json/JsonValue';
import { PathElement } from '../../../Json/PathElements/PathElement';
import { ISerializedKeyValuePair, KeyValuePair } from '../../../Utilities/KeyValuePair';
import { Utilities } from '../../../Utilities/Utilities';
import { ISerializedDialogNode } from '../../BaseDialogNode';
import { Redirectable } from '../../RedirectableNode';
import { ActionDialogNode } from '../ActionDialogNode';

export type ApiContextVariableType = {
  element: JsonElement;
  ctx: ContextVariable;
  storedProperty: 'key' | 'value';
};

class BaseApiActionNode extends ActionDialogNode {
  public static restoreApiContextVariables(
    stringifiedResponse: string,
    serializedCtxVars: ISerializedApiContextVariables[]
  ) {
    const map = new Map<string, ApiContextVariableType>();

    if (
      Utilities.isEmpty(serializedCtxVars) ||
      Utilities.isEmpty(stringifiedResponse) ||
      !Utilities.isJsonValid(stringifiedResponse)
    ) {
      return map;
    }

    // We create a new FlatJsonList from the stringified response to look for the JsonElements that belong to an ApiContextVariable
    const response = JsonProcessor.createFlatList(JSON.parse(stringifiedResponse));

    const mapSettings = serializedCtxVars.map((item) => {
      const containsListElement = item.path.some(
        (elem) => elem.type === PathElementTypes.ListElement
      );

      const element = JsonProcessor.getElementByPath(response, item.path)!;

      return {
        ctx: ContextVariable.getFromStore(item.ctxId) as ContextVariable,
        element,
        // Ternary Operator is only necessary because we don't have a sanitizedPathString attribute
        // on the deserialized objects yet. Keyword: Backwards-Compatibility
        sanitizedPathString: item.sanitizedPathString
          ? item.sanitizedPathString
          : JsonElement.getStringifiedPathOf(element, containsListElement),
        storedProperty: containsListElement ? 'key' : 'value',
      };
    });

    mapSettings.forEach((item) => {
      const { sanitizedPathString, ctx, element, storedProperty } = item;

      map.set(sanitizedPathString, {
        ctx,
        element,
        // @ts-ignore
        storedProperty,
      });
    });

    return map;
  }

  public static restoreShadowValues(value: [string, string][]) {
    const map = new Map<string, string>();

    value.forEach(([ctxId, value]: [string, string]) => {
      map.set(ctxId, value);
    });

    return map;
  }

  type: DialogNodeTypes = DialogNodeTypes.ApiActionNode;

  @Expose()
  url: string;

  @Expose()
  @Type(() => KeyValuePair)
  headers: KeyValuePair[];

  @Expose()
  body: string;

  @Expose()
  @Transform(({ value }) => (value ? JSON.parse(value) : undefined))
  response?: any;

  @Expose()
  httpRequestType: HttpRequestType;

  @Expose()
  @Type(() => Date)
  requestTimestamp?: Date;

  @Expose()
  @Transform(({ value, obj }) =>
    BaseApiActionNode.restoreApiContextVariables(obj.response, value)
  )
  apiContextVariables: Map<string, ApiContextVariableType>;

  @Expose()
  @Transform(({ value }) => BaseApiActionNode.restoreShadowValues(value))
  requestShadowValues = new Map<string, string>();

  constructor(block: DialogBlock) {
    super(block);

    this.title = 'API Action Node';
    this.url = '';
    this.headers = [];
    this.body = '';
    this.httpRequestType = HttpRequestType.GET;
    this.apiContextVariables = new Map<string, ApiContextVariableType>();
    this.requestTimestamp = new Date();

    reaction(
      () => this.response,
      () => {
        this.requestTimestamp = new Date();
      }
    );

    makeObservable(this, {
      type: observable,
      url: observable,
      headers: observable,
      body: observable,
      response: observable,
      httpRequestType: observable,
      apiContextVariables: observable,
      requestTimestamp: observable,
      requestShadowValues: observable,
      flatJsonList: computed,
      bodyDependencies: computed,
      urlDependencies: computed,
      headerDependencies: computed,
      requestDependencies: computed,
      areShadowValuesProvided: computed,
      dependsOnContextVariables: computed,
      pasteResponse: action,
      clearResponse: action,
      addApiContextVariable: action,
      removeApiContextVariable: action,
      addHeader: action,
      removeHeader: action,
    });
  }

  get flatJsonList() {
    if (!this.response) return;

    return new JsonProcessor(this.response);
  }

  get isValid() {
    if (Utilities.isEmpty(this.url)) return false;

    // return Utilities.isEmpty(this.body) || Utilities.isJsonValid(this.body);
    return true;
  }

  get areShadowValuesProvided() {
    return this.requestDependencies.every(
      (ctx) => !!this.requestShadowValues.get(ctx.id)
    );
  }

  get dependsOnContextVariables() {
    return this.requestDependencies.length > 0;
  }
  get urlDependencies() {
    return this.url
      ? [...new Set(ContextVariableStore.getInstance().extractCtxVarsFromText(this.url))]
      : [];
  }

  get bodyDependencies() {
    return this.body
      ? [...new Set(ContextVariableStore.getInstance().extractCtxVarsFromText(this.body))]
      : [];
  }

  get headerDependencies() {
    if (this.headers.length === 0) return [];

    const dependencies = this.headers
      .map((header) => header.value)
      .reduce((acc: ContextVariable[], value: string) => {
        const ctxs = ContextVariableStore.getInstance().extractCtxVarsFromText(
          value
        ) as ContextVariable[];
        if (ctxs.length) {
          return [...acc, ...ctxs];
        } else {
          return acc;
        }
      }, []);

    return [...new Set(dependencies)];
  }

  get requestDependencies() {
    const urlCtx = this.urlDependencies;

    const bodyCtx = this.bodyDependencies;

    const headerCtx = this.headerDependencies;

    return [...new Set([...urlCtx, ...bodyCtx, ...headerCtx])];
  }

  get hasStoredJsonKey() {
    return [...this.apiContextVariables.values()].some(
      (item) => item.element instanceof JsonValue && item.storedProperty === 'key'
    );
  }

  addHeader(header: KeyValuePair) {
    this.headers.unshift(header);
  }

  removeHeader = (header: KeyValuePair) => {
    this.headers = this.headers.filter((h) => h !== header);
  };

  /**
   * Adds a new ApiContextVariable to the apiContextVariables array if there is no other ctx with the same name.
   * @param ctx A new context variable
   * @param path Path to reach the JsonProp
   * @returns The newly created Context Variable
   */
  addApiContextVariable(name: string, element: JsonElement, willStoreList: boolean) {
    let path;
    let storedProperty: 'key' | 'value';

    if (element instanceof JsonContainer) {
      const casted = element as JsonContainer;
      path = casted.paths.stringified;
      storedProperty = 'key';
    } else {
      const casted = element as JsonValue;

      if (willStoreList) {
        path = casted.paths.key.stringified;
        storedProperty = 'key';
      } else {
        path = casted.paths.value.stringified;
        storedProperty = 'value';
      }
    }

    const entryExists = this.apiContextVariables.get(path);

    if (entryExists) return entryExists.ctx;

    const ctx = ContextVariableStore.getInstance().addIfNotExists(
      name,
      willStoreList
        ? ContextVariableInstanceTypes.ListContextVariable
        : ContextVariableInstanceTypes.ContextVariable
    )!;

    this.apiContextVariables.set(path, {
      element,
      ctx,
      storedProperty,
    });

    return ctx;
  }

  /**
   * Finds and returns an ApiContextVariable if the paths are identical
   * @param path Path to reach a JsonProp
   */
  getApiContextVariableByPath(path: string) {
    return this.apiContextVariables.get(path)?.ctx;
  }

  getApiContextVariableById(contextVariable: ContextVariable) {
    return [...this.apiContextVariables.values()].find(
      (item) => item.ctx === contextVariable
    );
  }

  /**
   * Checks if there is an entry in the Map
   * @param path Stringified path of a JsonElement
   */
  isPathAlreadyStored(path: string) {
    return !!this.apiContextVariables.get(path);
  }

  /**
   * Checks if a JsonContainer element is part of the tree of a JsonValue element that is being stored in the Map. The JsonValue Element's value is being stored!
   * @param jsonContainer A JSON Container element
   * @returns Boolean
   */
  isPartOfStoredJsonValuesPath(jsonContainer: JsonContainer) {
    const jsonValueElementsInMap = [...this.apiContextVariables.values()]
      .filter((item) => item.storedProperty === 'value')
      .map((item) => item.element) as JsonValue[];

    const childFoundInMap = jsonValueElementsInMap.find((elem) =>
      elem.isChildOf(jsonContainer)
    );

    return !!childFoundInMap;
  }

  /**
   * Checks if a JsonContainer element is part of the tree of a JsonContainer element whose children are primitive values and is stored in the Map.
   * @param jsonContainer A JSON Container Element
   * @returns Boolean
   */
  isPartOfStoredPrimitiveArrayContainerPath(jsonContainer: JsonContainer) {
    const jsonContainersInMap = [...this.apiContextVariables.values()]
      .map((item) => item.element)
      .filter((elem) => elem instanceof JsonContainer) as JsonContainer[];

    const childFoundInMap = jsonContainersInMap.find((elem) =>
      elem.isChildOf(jsonContainer)
    );

    return !!childFoundInMap;
  }

  /**
   * Removes an ApiContextVariable with the provided ID
   * @param id ID of the to-be-removed context variable
   */
  removeApiContextVariable(path: string) {
    this.apiContextVariables.delete(path);
  }

  /**
   * Creates an asynchronous Axios Request with the chosen RequestType and returns the received data
   */
  async createRequest() {
    this.removeApiContextVariables();

    const config: AxiosRequestConfig = this.createRequestConfig();

    const proxyConfig = this.createProxyConfig(config);
    try {
      const response = await axios(proxyConfig);
      const { data } = response;
      this.response = data;
      return data;
    } catch (error) {
      this.clearResponse();
      return { error };
    }
  }

  pasteResponse(text: string) {
    if (!Utilities.isJsonValid(text)) return;

    this.removeApiContextVariables();
    this.response = JSON.parse(text);
  }

  clearResponse() {
    this.removeApiContextVariables();
    this.response = undefined;
  }

  private createProxyConfig(config: AxiosRequestConfig): AxiosRequestConfig {
    return {
      url: '/api/v2/proxy',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        url: config.url,
        httpMethod: config.method,
        headers: config.headers,
        body: config.data,
      },
    };
  }

  private createRequestConfigHeaders() {
    const headers = this.headers.reduce((acc, header) => {
      let headerValue = header.value;
      const headerCtx =
        ContextVariableStore.getInstance().extractCtxVarsFromText(headerValue);

      if (headerCtx.length > 0) {
        headerCtx.forEach(
          (ctx) =>
            (headerValue = headerValue.replace(
              `{{${ctx.id}}}`,
              this.requestShadowValues.get(ctx.id)!
            ))
        );
      }

      return { ...acc, [header.key]: headerValue };
    }, {});

    return headers;
  }

  private createRequestConfigUrl() {
    if (this.urlDependencies.length > 0) {
      let urlCopy = this.url;
      this.urlDependencies.forEach(
        (ctx) =>
          (urlCopy = urlCopy.replace(
            `{{${ctx.id}}}`,
            this.requestShadowValues.get(ctx.id)!
          ))
      );
      return urlCopy;
    }

    return this.url;
  }

  private createRequestConfigBody() {
    if (this.bodyDependencies.length > 0) {
      let bodyCopy = this.body;
      this.bodyDependencies.forEach(
        (ctx) =>
          (bodyCopy = bodyCopy.replace(
            `{{${ctx.id}}}`,
            this.requestShadowValues.get(ctx.id)!
          ))
      );
      return bodyCopy;
    }

    return this.body;
  }

  /**
   * Creates an Axios Request Config object that contains the method, headers and (if necessary) the body
   */
  private createRequestConfig() {
    const config: AxiosRequestConfig = {
      url: this.createRequestConfigUrl(),
      headers: this.createRequestConfigHeaders(),
      method: this.httpRequestType,
    };

    if (
      this.httpRequestType !== HttpRequestType.GET &&
      this.httpRequestType !== HttpRequestType.DELETE
    ) {
      config.data = this.createRequestConfigBody();
    }

    return config;
  }

  private removeApiContextVariables() {
    this.apiContextVariables.clear();
    this.flatJsonList?.clear();
  }

  private serializeRequestShadowValues() {
    return this.requestDependencies.map((ctx) => [
      ctx.id,
      this.requestShadowValues.get(ctx.id),
    ]);
  }

  serializeApiContextVariables(): ISerializedApiContextVariables[] {
    return [...this.apiContextVariables.entries()].map(([key, item]) => ({
      sanitizedPathString: key,
      path: item.element.serializeToPathElements(item.storedProperty === 'key'),
      ctxId: item.ctx.id,
    }));
  }

  serialize(): ISerializedApiActionNode {
    return {
      ...super.serialize(),
      httpRequestType: this.httpRequestType,
      url: this.url,
      headers: this.headers?.map((header) => header.serialize()),
      body: this.body,
      response: JSON.stringify(this.response),
      requestTimestamp: this.requestTimestamp?.toISOString(),
      requestShadowValues: this.serializeRequestShadowValues(),
      apiContextVariables: this.serializeApiContextVariables(),
    };
  }
}

export class ApiActionNode extends Redirectable(BaseApiActionNode) {}

export interface ISerializedApiActionNode extends ISerializedDialogNode {
  httpRequestType: HttpRequestType;
  url: string;
  headers: ISerializedKeyValuePair[];
  body: string;
  response: string;
  requestTimestamp?: string;
  requestShadowValues: ISerializedShadowValue[];
  apiContextVariables: ISerializedApiContextVariables[];
}

export interface ISerializedApiContextVariables {
  path: PathElement[];
  ctxId: string;
  sanitizedPathString: string;
}

export type ISerializedShadowValue = (string | undefined)[];
