import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import rootStore from '../../../stores/rootStore';
import { HttpAgent } from '../../../utility/HttpAgent';
import DialogBlockList from '../DialogBlocks/DialogBlockList';
import LoadingPage from '../LoadingPage';

interface IParamsProps {
  dialogId?: string;
  conversationThreadId?: string;
}

const ConversationHistoryBlockOverview = () => {
  const location = useLocation();
  const { dialogStore, conversationHistoryStore, canvasStore, blockStore } =
    useContext(rootStore);
  const { authenticated, loading: userLoading } = useContext(AuthContext);
  const params: IParamsProps = useParams();
  const version = queryString.parse(location.search).version;

  const [isBeingDragged, setIsBeingDragged] = useState(false);

  const load = async () => {
    await dialogStore.loadBots();
  };

  useLayoutEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  useEffect(() => {
    if (!userLoading && authenticated) load();

    return HttpAgent.cancelRequest;
  }, [authenticated, userLoading]);

  useEffect(() => {
    canvasStore.refresh();
  }, [canvasStore]);

  useEffect(() => {
    return () => {
      conversationHistoryStore.clearHistorySelection();
    };
  }, []);

  useEffect(() => {
    if (!dialogStore.currentlyEditedDialog && params.dialogId) {
      dialogStore.setDialogById(params?.dialogId, Number(version));
    }
  }, [dialogStore.bots, params]);

  const loadHistoryElements = async () => {
    if (params.conversationThreadId) {
      await conversationHistoryStore.getHistoryElements(params.conversationThreadId);
    }
  };

  useEffect(() => {
    loadHistoryElements();
  }, [params, dialogStore.currentlyEditedDialog]);

  return !dialogStore.currentlyEditedDialog ||
    conversationHistoryStore.historyElements.length === 0 ? (
    <LoadingPage />
  ) : (
    <div
      id='block-overview'
      style={{ cursor: isBeingDragged ? 'grabbing' : 'grab' }}
      onMouseDown={() => setIsBeingDragged(true)}
      onMouseUp={() => setIsBeingDragged(false)}
    >
      <DialogBlockList blocks={blockStore.allBlocks} />
    </div>
  );
};

export default observer(ConversationHistoryBlockOverview);
