import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Condition as ConditionModel } from '../../../../models/Conditions/Condition';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import Condition from './Condition';
interface IProps {
  condition: ConditionModel;
  removeHandler: (condition: ConditionModel) => void;
}
const ConditionWrapper: React.FC<IProps> = ({ condition, removeHandler }) => {
  const [isAboutToDelete, setIsAboutToDelete] = useState(false);

  const getIcon = useGetIcon();

  const handleRemoveCondition = () => {
    removeHandler(condition);
    setIsAboutToDelete(false);
  };

  return (
    <div
      key={condition.id}
      className={`condition-container`}
      data-is-about-to-delete={isAboutToDelete}
    >
      <Condition condition={condition} />
      <Button
        icon={getIcon('close')}
        className='btn-remove btn-borderless'
        mouseEnterHandler={() => setIsAboutToDelete(true)}
        mouseLeaveHandler={() => setIsAboutToDelete(false)}
        clickHandler={handleRemoveCondition}
      />
    </div>
  );
};

export default observer(ConditionWrapper);
