import { action, computed, makeAutoObservable, observable, runInAction } from 'mobx';
import { history } from '..';
import { SettingsState as BotSettingsState } from '../architecture/enums/ConfirmationSettingsState';
import { Settings } from '../models/Bot/Settings';
import { BotSettingsConnector } from '../models/Connectors/BotSettingsConnector';
import { RootStore } from './rootStore';

class BotSettingsStore {
  private static instance: BotSettingsStore;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
    BotSettingsStore.instance = this;
  }

  @observable
  state: BotSettingsState = BotSettingsState.None;

  @observable
  settings: Settings = {
    confirmationSettings: {
      primaryColor: null,
      secondaryColor: null,
      backgroundColor: null,
      primaryTextColor: null,
      primaryButtonTextColor: null,
      titleTextColor: null,
      backgroundImageId: null,
      logoId: null,
      privacyUrl: null,
      impressUrl: null,
    },
    botSettings: {
      botSecret: null,
    },
    defaultPrompts: {
      generativeAnswerPrompt: null,
    },
  };

  @computed
  getState(state: BotSettingsState): boolean {
    return (this.state & state) === state;
  }

  @computed
  setState(state: BotSettingsState) {
    this.state |= state;
  }

  @computed
  removeState(state: BotSettingsState) {
    this.state &= ~state;
  }

  @action
  async getSettings() {
    try {
      if (!this.rootStore.subscriptionStore.selectedSubscription) {
        history.push('/settings');
        return;
      }

      this.setState(BotSettingsState.GetSettingsLoading);

      this.settings = (await BotSettingsConnector.getSettings(
        this.currentlyEditedBotId
      )) as Settings;

      this.removeState(BotSettingsState.GetSettingsLoading);
      this.setState(BotSettingsState.Initialized);
    } catch (error) {
      this.removeState(BotSettingsState.GetSettingsLoading);
    }
  }

  @action
  async updateConfirmationSettings() {
    try {
      if (!this.rootStore.subscriptionStore.selectedSubscription) {
        history.push('/settings');
        return;
      }

      this.setState(BotSettingsState.UpdatingLoading);
      await BotSettingsConnector.updateConfirmationSettings(
        this.currentlyEditedBotId,
        this.settings.confirmationSettings
      );

      this.removeState(BotSettingsState.UpdatingLoading);
    } catch (error) {
      this.removeState(BotSettingsState.UpdatingLoading);
    }
  }

  @action
  resetColorsToDefault() {
    if (!this.settings) return;

    this.settings.confirmationSettings.primaryColor = '#32637D';
    this.settings.confirmationSettings.primaryTextColor = '#19323F';
    this.settings.confirmationSettings.primaryButtonTextColor = '#FFFFFF';
    this.settings.confirmationSettings.secondaryColor = '#FA9986';
    this.settings.confirmationSettings.backgroundColor = '#FFFFFF';
    this.settings.confirmationSettings.titleTextColor = '#FFFFFF';
  }

  @action
  async resetBotSecret() {
    const response = await BotSettingsConnector.resetBotSecret(this.currentlyEditedBotId);

    runInAction(() => {
      if (response !== undefined) {
        this.settings.botSettings.botSecret = response.botSecret;
      }
    });
  }

  private get currentlyEditedBotId() {
    return this.rootStore.dialogStore.currentlyEditedBot?.botId;
  }

  static getInstance() {
    if (!this.instance) {
      throw new Error('BotSettingsStore instance has not been initialized.');
    }

    return this.instance;
  }
}

export default BotSettingsStore;
