import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { PropertyType } from '../../../../architecture/enums/Dataset';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  resetModal: () => void;
  selectedRows: string[];
}

const ExportData: React.FC<IProps> = ({ resetModal, selectedRows }) => {
  const { datasetStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const exportAsCsv = async () => {
    const data = await datasetStore.export(selectedRows, 'csv');
    if (!data) return;

    downloadFile(data, 'csv');
    resetModal();
  };

  const exportAsJson = async () => {
    const data = await datasetStore.export(selectedRows, 'json');
    if (!data) return;

    downloadFile(data, 'json');
    resetModal();
  };

  const downloadFile = (data: ArrayBuffer, format: 'json' | 'csv') => {
    const datasetName = datasetStore.getSelectedDataset?.name;
    const filename = `${
      new Date().toISOString().split('.')[0] // ISO string without milliseconds and timezone
    }-Lailo-Dataset-${datasetName}.${format}`;

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  };

  const isCsvExportAllowed = () => {
    const properties = datasetStore.getSelectedDataset?.properties;
    if (!properties) return false;

    return properties.every((prop) => prop.type === PropertyType.Simple);
  };

  return (
    <div className='modal-default dataset-modal'>
      <div className='header'>
        <h3>Export Data</h3>
      </div>
      <div className='attachments-container'>
        <div className='title'>
          Download the selected entries for this dataset either as a CSV or JSON file.
          Please note that it is not possible to download a nested data structure as a
          CSV.
        </div>
        <div className='export-buttons'>
          <Button
            content='Export as CSV file'
            icon={getIcon('csv')}
            className='btn-inactive btn-round'
            clickHandler={exportAsCsv}
            disabled={!isCsvExportAllowed()}
          />
          <Button
            content='Export as JSON file'
            icon={getIcon('spreadsheet')}
            className='btn-inactive btn-round'
            clickHandler={exportAsJson}
          />
        </div>
      </div>
      <div className='btn-container'>
        <Button
          content='Done'
          className='btn-primary btn-round'
          clickHandler={resetModal}
        />
      </div>
    </div>
  );
};

export default observer(ExportData);
