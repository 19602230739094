import React, { useContext } from 'react';
import { DialogBlock } from '../../../../models/DialogBlocks/DialogBlock';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

const PurgeNodes = () => {
  const { nodeStore, uiStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const { closeModal } = useModal();

  const handleDelete = () => {
    nodeStore.removeAllNodesOfBlock(uiStore.modalState.element as DialogBlock);
    closeModal();
  };

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>Are you sure you want to remove all the nodes?</h3>
        <p className='header-description'>
          This will remove all the nodes in this block.
        </p>
      </div>
      <div className='btn-container'>
        <Button
          content='Delete'
          icon={getIcon('remove')}
          className='btn-dark btn-round'
          clickHandler={handleDelete}
        />
        <Button
          content='Cancel'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={() => closeModal()}
        />
      </div>
    </div>
  );
};

export default PurgeNodes;
