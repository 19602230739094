export default interface IPathElement {
  key: string | number | null;
  type: PathElementTypes;
}

export enum PathElementTypes {
  PropertyElement = 'PropertyElement',
  ArrayElement = 'ArrayElement',
  ListElement = 'ListElement',
}
