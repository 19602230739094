import { observer } from 'mobx-react-lite';
import React from 'react';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import { Question } from '../../../../models/Knowledgebase/Question';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import { useReadOnly } from '../../../customHooks/useReadOnly';

interface IProps {
  entry: KnowledgebaseEntry;
  question: Question;
  setEdit: (value: boolean) => void;
}

const QuestionLabel: React.FC<IProps> = ({ entry, question, setEdit }) => {
  const getIcon = useGetIcon();
  const { knowledgebaseReadOnlyMode } = useReadOnly();

  const discardChange = () => {
    question.reset();
  };

  return (
    <div
      style={{ height: 'fit-content' }}
      className={
        question.state === 'Deleted'
          ? 'tag deleted'
          : question.state === 'Added' || question.state === 'Modified'
          ? 'tag highlighted'
          : 'tag'
      }
    >
      <div
        className='tag-name'
        onClick={() =>
          entry.state !== 'Deleted' && !knowledgebaseReadOnlyMode && setEdit(true)
        }
      >
        {question.text.value}
      </div>
      {!knowledgebaseReadOnlyMode ? (
        question.state === 'Deleted' || question.state === 'Modified' ? (
          <i
            className={`${getIcon('undo')} tag-icon`}
            onClick={() => {
              discardChange();
            }}
          />
        ) : (
          <i
            className={`${getIcon('minus_circle')} tag-icon`}
            onClick={() => {
              entry.removeQuestion(question);
            }}
          />
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default observer(QuestionLabel);
