import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { UserAdminState } from '../../../architecture/enums/Admin';
import { IExistingUser } from '../../../architecture/interfaces/HTTP/AdminParams';
import Button from '../../common/Button';
import Select from '../../common/inputElements/select/Select';
import useAdminStores from '../../customHooks/useAdminStores';
import LoadingPage from '../../layout/LoadingPage';
import Roles from './components/Roles';

interface IProps {
  resetModal: () => void;
}

const validationSchema = Yup.object({
  selectedUser: Yup.object().required('This field is required').nullable(true),
  roles: Yup.array().min(1, 'You must select at least one role'),
});

const AddExistingUser: React.FC<IProps> = ({ resetModal }) => {
  const { userAdminStore } = useAdminStores();

  const formik = useFormik({
    initialValues: {
      selectedUser: null as IExistingUser | null,
      roles: [] as string[],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.selectedUser?.Id) {
        userAdminStore.update(
          values.selectedUser.Id,
          {
            roles: values.roles || [],
          },
          true
        );
      }
      resetModal();
    },
  });

  useEffect(() => {
    userAdminStore.getExistingUsers();
  }, []);

  return (
    <div className='modal-default admin-modal'>
      <div className='header'>
        <h4>Add existing User</h4>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className='body'>
          {userAdminStore.getState(UserAdminState.ExistingUsersLoading) ? (
            <div className='loading mt-2'>
              <LoadingPage fullScreen={false} showText={false} />
            </div>
          ) : (
            <>
              <div className='input-group'>
                <label htmlFor='user'>Choose user:</label>
                <Select
                  id='user'
                  triggerLabel={formik.values.selectedUser?.DisplayName || ''}
                  items={userAdminStore.existingUsers.map((user) => ({
                    title: user.DisplayName || '',
                  }))}
                  placeholder='Choose user'
                  selectHandler={(value) =>
                    formik.setFieldValue(
                      'selectedUser',
                      userAdminStore.existingUsers.find(
                        (user) => user.DisplayName === value
                      ) || null
                    )
                  }
                  dropdownHeight={185}
                  className='select-input'
                  search={false}
                  closeOnSelect
                />
                {formik.touched.selectedUser && formik.errors.selectedUser ? (
                  <div className='validation-error'>{formik.errors.selectedUser}</div>
                ) : null}
              </div>
              <div className='input-group'>
                <label htmlFor='user'>Roles:</label>
                <Roles
                  selectedRoles={formik.values.roles || []}
                  setSelectedRoles={(roles) =>
                    formik.setFieldValue('roles', roles as string[])
                  }
                />
                {formik.touched.roles && formik.errors.roles ? (
                  <div className='validation-error'>{formik.errors.roles}</div>
                ) : null}
              </div>
            </>
          )}
        </div>
        <div className='btn-container'>
          <Button
            content='Cancel'
            className='btn-inactive btn-round'
            clickHandler={resetModal}
          />
          <Button type='submit' content='Add User' className='btn-primary btn-round' />
        </div>
      </form>
    </div>
  );
};

export default observer(AddExistingUser);
