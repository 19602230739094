import { observer } from 'mobx-react-lite';
import React from 'react';
import { ApiActionNode } from '../../../../../models/DialogNodes/ActionNodes/ApiActionNode/ApiActionNode';
import { KeyValuePair } from '../../../../../models/Utilities/KeyValuePair';
import Button from '../../../../common/Button';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import Header from './Header';

interface IProps {
  dialogNode: ApiActionNode;
}
const HeaderTab: React.FC<IProps> = ({ dialogNode }) => {
  const addIcon = useGetIcon()('add');

  return (
    <div className='api-node-headers-container'>
      <Button
        icon={addIcon}
        content='Add new header'
        className='btn-primary btn-round btn-smaller'
        clickHandler={() => dialogNode.addHeader(new KeyValuePair())}
      />
      {dialogNode.headers.length > 0 &&
        <div className='header-row'>
          <h4>Key</h4>
          <h4>Value</h4>
        </div>
      }
      {dialogNode.headers.map((header) => (
        <Header key={header.id} header={header} deleteHandler={dialogNode.removeHeader} />
      ))}
    </div>
  );
};

export default observer(HeaderTab);
