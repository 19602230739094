import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import ButtonDropdown from '../../../common/ButtonDropdown';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  disabled?: boolean;
  label: string;
  routeTo?: string;
  onClick?: () => void;
  active?: boolean;
  icon?: string;
  className?: string;
  checkForUnsavedChanges?: boolean;
}

const NavigationLink: React.FC<IProps> = ({
  disabled = false,
  label,
  icon,
  className,
  routeTo,
  onClick,
  active = false,
  checkForUnsavedChanges = false,
}) => {
  if (routeTo && onClick) {
    throw Error('Either a direct route or an onClick event can be defined.');
  }

  const { nodeStore, appStore, dialogStore } = useContext(rootStore);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const getIcon = useGetIcon();
  const history = useHistory();

  const onNavLinkClick = () => {
    if (routeTo && routeTo === history.location.pathname) return;

    if (
      checkForUnsavedChanges &&
      appStore.hasEditorStructureChanged &&
      dialogStore.currentlyEditedDialog
    ) {
      setDropdownOpen(true);
    } else {
      actionHandler();
    }
  };

  const actionHandler = () => {
    if (routeTo) {
      history.push(routeTo);
    } else if (onClick) {
      onClick();
    }
  };

  const handleSave = async () => {
    const savedSuccesfully = await appStore.save();

    if (!savedSuccesfully) {
      history.push('/error');
    }
  };

  const handleDiscard = () => {
    appStore.discardChanges();
  };

  return (
    <ButtonDropdown
      title='Do you want to save your changes?'
      open={dropdownOpen}
      moveLeft={true}
      setOpen={setDropdownOpen}
      config={{
        triggerBtn: (
          <Button
            className={`subnavbar-menu-item control-button ${className} ${
              active ? 'subnavbar-menu-item-active' : ''
            }`}
            icon={icon}
            content={label}
            clickHandler={onNavLinkClick}
            disabled={disabled}
          />
        ),
        controlBtns: [
          <Button
            key='Save'
            className='btn-primary btn-round mx-2'
            content='Save'
            disabled={appStore.isInErrorState || !nodeStore.areNodesValid}
            icon={getIcon('save')}
            name='btn-save-on-overview-switch'
            clickHandler={() => {
              handleSave();
              setDropdownOpen(false);
              actionHandler();
            }}
          />,
          <Button
            key='Discard'
            className='btn-dark btn-round mx-2'
            content='Discard'
            icon={getIcon('close')}
            clickHandler={() => {
              handleDiscard();
              setDropdownOpen(false);
              actionHandler();
            }}
          />,
        ],
      }}
    />
  );
};

export default observer(NavigationLink);
