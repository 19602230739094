import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Intent as IntentModel } from '../../models/Intents/Intent';
import InfoMessage from '../common/InfoMessage';
import Input from '../common/Input';
import Label from './Label';
import { useReadOnly } from '../customHooks/useReadOnly';

interface IProps {
  intent: IntentModel;
}
const Intent: React.FC<IProps> = ({ intent }) => {
  const [newLabel, setNewLabel] = useState('');
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();

  const createNewLabel = () => {
    if (newLabel) {
      intent.addLabel(newLabel);
      setNewLabel('');
    }
  };

  return (
    <div className='intent-group'>
      <div className='delete-intent-group-wrapper'></div>
      <div className='new-intent-wrapper'>
        {intent.activeLabels.length < 3 && (
          <InfoMessage type='warning' message='Please add more example sentences.' />
        )}
        <h5>Example sentences</h5>
        {!readOnly && (
          <Input
            name=''
            value={newLabel}
            changeHandler={(input) => setNewLabel(input)}
            blurHandler={createNewLabel}
            autoFocus
            inputFactory
          />
        )}
      </div>
      {intent.activeLabels.map((label) => (
        <Label
          key={label.id}
          disabled={readOnly}
          label={label}
          deleteHandler={() => intent.removeLabel(label)}
        />
      ))}
    </div>
  );
};

export default observer(Intent);
