import { observer } from 'mobx-react-lite';
import React from 'react';
import { RedirectDialogNode as RedirectNodeModel } from '../../../models/DialogNodes/RedirectDialogNode';
import DialogNode from './DialogNode';
import RedirectContainer from './redirects/RedirectContainer';

interface IProps {
  dialogNode: RedirectNodeModel;
}
const RedirectDialogNode: React.FC<IProps> = ({ dialogNode }) => {
  // TODO: Implement condition handling for multiple conditions later
  return (
    <DialogNode dialogNode={dialogNode}>
      <RedirectContainer dialogNode={dialogNode} />
    </DialogNode>
  );
};

export default observer(RedirectDialogNode);
