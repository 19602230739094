import { Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';

export class Media {
  @Expose()
  contentType: string;

  @Expose()
  created: string;

  @Expose()
  creator: string;

  @Expose()
  description: string;

  @Expose()
  extension: string;

  @Expose()
  fileName: string;

  @Expose()
  mediaId: string;

  @Expose()
  url: string;

  constructor() {
    this.contentType = '';
    this.created = '';
    this.creator = '';
    this.description = '';
    this.extension = '';
    this.fileName = '';
    this.mediaId = '';
    this.url = '';

    makeObservable(this, {
      contentType: observable,
      created: observable,
      creator: observable,
      description: observable,
      extension: observable,
      fileName: observable,
      mediaId: observable,
      url: observable,
    });
  }

  serialize() {
    return {
      mediaId: this.mediaId,
    };
  }
}

export interface ISerializedMedia {
  mediaId: string;
}
