export const PAGE_SIZE = 50;
export const DATASET_HEADERS = {
  name: {
    label: 'Dataset',
    id: 'name',
  },
  status: {
    label: 'Status',
    id: 'status',
  },
  creationDate: {
    label: 'Creation Date',
    id: 'created',
  },
  entries: {
    label: 'Entries',
    id: 'entries',
  },
};

export const PROPERTY_HEADERS = {
  key: {
    label: 'Property',
    id: 'key',
  },
  type: {
    label: 'Type',
    id: 'type',
  },
  annotation: {
    label: 'Annotation',
    id: 'annotation',
  },
  actions: {
    label: '',
    id: 'actions',
  },
};

export const DEFAULT_DATA_HEADERS = {
  select: {
    label: '',
    id: 'select',
  },
  createdDate: {
    label: 'Date and Time',
    id: 'created',
  },
};
