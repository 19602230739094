import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { StorageSubmenu } from '../../../../architecture/enums/Navbar';
import rootStore from '../../../../stores/rootStore';
import NavLink from '../components/NavLink';

const StorageSubNavbar: React.FC = () => {
  const location = useLocation();
  const { dialogStore } = useContext(rootStore);
  const [activeSubmenu, setActiveSubmenu] = useState(StorageSubmenu.Manage);

  useEffect(() => {
    if (location.pathname.includes('manage')) {
      setActiveSubmenu(StorageSubmenu.Manage);
    } else if (location.pathname.includes('data')) {
      setActiveSubmenu(StorageSubmenu.Data);
    } else if (location.pathname.includes('integrate')) {
      setActiveSubmenu(StorageSubmenu.Integrate);
    }
  }, [location.pathname, dialogStore.currentlyEditedDialog]);

  return (
    <div className='subnavbar-menu'>
      <div className='flex'>
        <NavLink
          label={StorageSubmenu.Manage}
          active={activeSubmenu === StorageSubmenu.Manage}
          routeTo='/datasets/manage'
        />
        <NavLink
          label={StorageSubmenu.Data}
          active={activeSubmenu === StorageSubmenu.Data}
          routeTo='/datasets/data'
        />
        <NavLink
          label={StorageSubmenu.Integrate}
          active={activeSubmenu === StorageSubmenu.Integrate}
          routeTo='/datasets/integrate'
          disabled
        />
      </div>
    </div>
  );
};

export default observer(StorageSubNavbar);
