import { Exclude, Expose } from 'class-transformer';
import { makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';

export class KeyValuePair {
  @Exclude()
  id: string;

  @Expose()
  key: string;

  @Expose()
  value: string;

  constructor(key: string = '', value: string = '') {
    this.id = v4();
    this.key = key;
    this.value = value;
    makeObservable(this, {
      key: observable,
      value: observable,
    });
  }
  serialize() {
    return {
      key: this.key,
      value: this.value,
    };
  }
}

export interface ISerializedKeyValuePair {
  key: string;
  value: string;
}
