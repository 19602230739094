import React, { Fragment, useContext, useEffect, useState } from 'react';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { BaseDialogNode } from '../../../../models/DialogNodes/BaseDialogNode';
import { InfoAdaptiveCardNode } from '../../../../models/DialogNodes/MessageNodes/InfoAdaptiveCardNode';
import { MessageDialogNode } from '../../../../models/DialogNodes/MessageNodes/MessageDialogNode';
import { InvalidArgumentError } from '../../../../models/errors/InvalidArgumentError';
import { InvalidOperationError } from '../../../../models/errors/InvalidOperationError';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import NodeTypeSelector from '../../../dialogElements/nodes/typeSelectors/NodeTypeSelector';
import Button from '../../Button';

const MessageNodeTypeSelector = () => {
  const { blockStore, nodeStore, uiStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const nodeInfo = {
    message: {
      title: 'A Plain Text',
      body: 'Send a plain text message to the user.',
    },
    card: {
      title: 'An Adaptive Card',
      body: 'Create a template on https://adaptivecards.io and use it to send a universal card to the user.',
    },
  };

  const [activeInfo, setActiveInfo] = useState<{ title: string; body: string }>();

  const { closeModal } = useModal();

  useEffect(() => {
    setActiveInfo(nodeInfo['message']);
    // eslint-disable-next-line
  }, []);

  // const handleClick = (nodeType: keyof typeof QuestionNodeTypes) => {
  const handleClick = (nodeType: DialogNodeTypes) => {
    if (!blockStore.selectedBlock) {
      throw new InvalidOperationError('Block must be set.');
    }

    let node: BaseDialogNode;
    switch (nodeType) {
      case DialogNodeTypes.MessageDialogNode:
        node = new MessageDialogNode(blockStore.selectedBlock!);
        break;
      case DialogNodeTypes.InfoAdaptiveCardNode:
        node = new InfoAdaptiveCardNode(blockStore.selectedBlock!);
        break;
      default:
        throw new InvalidArgumentError('Node type unkonwn: ' + nodeType);
    }

    if (nodeStore.selectedParentNode) {
      nodeStore.insertAfter(node, nodeStore.selectedParentNode);
    } else {
      // If there is no selected parent node in the first place, the node will be inserted at the bottom of the block
      // Which means that auto scrolling is desired
      uiStore.scrollToElement(uiStore.nodeListContainer!, 'bottom');
      nodeStore.append(node);
    }

    closeModal();
  };

  const btnGroup = () => (
    <Fragment>
      <Button
        icon={getIcon('message')}
        content='Plain Text'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(nodeInfo['message'])}
        clickHandler={() => handleClick(DialogNodeTypes.MessageDialogNode)}
      />
      <Button
        icon={getIcon('card')}
        content='Message Card'
        className={'btn-round btn-primary'}
        mouseEnterHandler={() => setActiveInfo(nodeInfo['card'])}
        clickHandler={() => handleClick(DialogNodeTypes.InfoAdaptiveCardNode)}
      />
    </Fragment>
  );

  return (
    <NodeTypeSelector
      activeInfo={activeInfo!}
      title='I would like to send...'
      btnGroup={btnGroup()}
    />
  );
};

export default MessageNodeTypeSelector;
