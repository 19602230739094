import { Exclude, Expose, Transform } from 'class-transformer';
import { action, computed, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import type { SubQuestionKey } from '../../../../architecture/enums/SubQuestionKey';
import { ContextVariable } from '../../../ContextVariables/ContextVariable';
import { TransformIntoTextArray } from '../../../Utilities/Deserialization/Decorators';
import { ISerializedText, Text } from '../../../Utilities/Text';

export class SubQuestion {
  @Expose()
  key: SubQuestionKey;

  @Expose()
  @TransformIntoTextArray
  questions: Text[];

  @Expose({ name: 'ctxId' })
  @Transform(({ value }) => ContextVariable.getFromStore(value))
  ctx: ContextVariable;

  @Exclude()
  id: string;
  constructor(subQuestions: SubQuestionKey, ctx: ContextVariable) {
    this.key = subQuestions;
    this.ctx = ctx;
    this.questions = [new Text('', '')];
    this.id = v4();

    makeObservable(this, {
      id: observable,
      key: observable,
      questions: observable,
      ctx: observable,
      isValid: computed,
      addQuestion: action,
      removeQuestion: action,
    });
  }

  /** A subQuestion is only valid if:
   * 1. Has at least one question
   * 2. None of the question's are empty strings
   */
  get isValid() {
    return (
      this.questions.length > 0 &&
      this.questions.every((question) => question.value.length > 0)
    );
  }

  addQuestion(text: Text | string) {
    if (typeof text === 'string') {
      text = new Text(text);
    }

    this.questions.push(text);
  }

  removeQuestion(text: Text) {
    this.questions = this.questions.filter((item) => item !== text);
  }

  serialize(): ISerializedSubQuestion {
    return {
      key: this.key,
      ctxId: this.ctx.id,
      questions: this.questions.map((question) =>
        question.serialize()
      ) as ISerializedText[],
    };
  }
}

export interface ISerializedSubQuestion {
  questions: ISerializedText[];
  key: SubQuestionKey;
  ctxId: string;
}
