import { Expose } from 'class-transformer';
import { computed, makeObservable, observable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import ContextVariableStore from '../../../stores/ContextVariableStore';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { Utilities } from '../../Utilities/Utilities';
import { ISerializedDialogNode } from '../BaseDialogNode';
import { ActionDialogNode } from './ActionDialogNode';

export class EmailActionNode extends ActionDialogNode {
  readonly type: DialogNodeTypes = DialogNodeTypes.EmailActionNode;

  @Expose()
  address?: string;

  @Expose()
  subject?: string;

  @Expose()
  body?: string;

  constructor(block: DialogBlock) {
    super(block);
    this.title = 'Email Action Node';
    this.address = '';
    this.subject = '';
    this.body = '';

    makeObservable(this, {
      type: observable,
      address: observable,
      subject: observable,
      body: observable,
      isEmailValid: computed,
    });
  }

  get isValid() {
    return (
      !Utilities.isEmpty(this.address) &&
      !Utilities.isEmpty(this.body) &&
      this.isEmailValid
    );
  }

  get isEmailValid() {
    if (!this.address) return false;

    const regex = new RegExp(
      `^(${
        ContextVariableStore.getInstance().openingTag
      }[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}${
        ContextVariableStore.getInstance().closingTag
      })$`
    );

    return Utilities.isEmailValid(this.address) || regex.test(this.address);
  }

  serialize(): ISerializedEmailActionNode {
    return {
      ...super.serialize(),
      address: this.address,
      subject: this.subject,
      body: this.body,
    };
  }
}

export interface ISerializedEmailActionNode extends ISerializedDialogNode {
  address?: string;
  subject?: string;
  body?: string;
}
