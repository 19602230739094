import { v4 } from 'uuid';
import { DialogNodeTypes } from '../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../DialogBlocks/DialogBlock';
import { BaseDialogNode } from './BaseDialogNode';

export class RootNode extends BaseDialogNode {
  type: DialogNodeTypes = DialogNodeTypes.RootNode;
  constructor(dialogBlock: DialogBlock) {
    super(dialogBlock);
  }
}
