import { observer } from 'mobx-react-lite';
import React from 'react';
import { MediaTypes } from '../../../../architecture/enums/MediaType';
import { CommunicationDialogNode } from '../../../../models/DialogNodes/CommunicationDialogNode';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import SelectMediaList from '../../../media/selectMedia/SelectMediaList';

interface IProps {
  mediaType?: MediaTypes;
  item: CommunicationDialogNode | KnowledgebaseEntry;
  resetModal: () => void;
}

const MediaFileSelector: React.FC<IProps> = ({ mediaType, item, resetModal }) => {
  return (
    <div className='modal-default'>
      <SelectMediaList mediaType={mediaType} item={item} resetModal={resetModal} />
    </div>
  );
};

export default observer(MediaFileSelector);
