import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { TableData } from '../../../../architecture/enums/TableData';
import { Pronunciation } from '../../../../models/Subscription/Pronunciation';
import rootStore from '../../../../stores/rootStore';
import Pagination from '../../../common/pagination/Pagination';
import Table from '../../../common/table/Table';
import PronuncationSsml from './PronuncationSsml';
import PronunciationTableHeader from './PronunciationTableHeader';
import PronunciationText from './PronunciationText';
import RemoveIcon from './RemoveIcon';

interface IProps {
  language: string;
}

const PronunciationTable: React.FC<IProps> = ({ language }) => {
  const { pronunciationStore } = useContext(rootStore);

  useEffect(() => {
    return () => {
      pronunciationStore.unloadStore();
    };
  }, []);

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: 'text',
        Header: 'Text',
        accessor: (property: TableData) => (property as Pronunciation).text,
        Cell: (item: Cell<Pronunciation>) => (
          <PronunciationText pronunciation={item.row.original} language={language} />
        ),
      },
      {
        id: 'ssml',
        Header: 'Pronunciation',
        accessor: (property: TableData) => (property as Pronunciation).ssml,
        Cell: (item: Cell<Pronunciation>) => (
          <PronuncationSsml pronunciation={item.row.original} language={language} />
        ),
      },
      {
        id: 'actions',
        Header: '',
        width: 10,
        Cell: (item: Cell<Pronunciation>) => <RemoveIcon item={item.row.original} />,
      },
    ],
    []
  );

  // Removed usage of useMemo because it caused an issue when switching between languages.
  // It's unlikely that we run into performance issues here, but if we do, we should
  // investigate this further.
  const data: TableData[] = pronunciationStore.currentPageEntries;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  return (
    <div className='container-wrapper'>
      <PronunciationTableHeader />
      <Table
        id='pronunciation-table'
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        rows={rows}
        prepareRow={prepareRow}
      />

      {pronunciationStore.pagination.totalPages > 1 && (
        <Pagination
          page={pronunciationStore.pagination.page}
          pageCount={pronunciationStore.pagination.totalPages}
          pageChange={(page) => {
            pronunciationStore.pagination.page = page;
          }}
        />
      )}
    </div>
  );
};

export default observer(PronunciationTable);
