import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';
import { DatasetActionNode as DatasetActionNodeModel } from '../../../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import rootStore from '../../../../../../stores/rootStore';
import TextBoxWithCtx from '../../../../../common/inputElements/TextBoxWithCtx';
import { PropertyType } from '../../../../../../architecture/enums/Dataset';
import ContextVariableSelector from '../../../../../common/inputElements/select/ContextVariable/ContextVariableSelector';
import { PropertyValue } from '../../../../../../models/Dataset/PropertyValue';
import DatasetListPropertiesValues from './DatasetListPropertiesValues';

interface IProps {
  dialogNode: DatasetActionNodeModel;
  updating?: boolean;
}

const PropertiesValues: React.FC<IProps> = ({ dialogNode, updating }) => {
  const { ctxVarStore } = useContext(rootStore);
  const getIcon = useGetIcon();

  const removePropertyValue = (property: PropertyValue) => {
    dialogNode.properties = dialogNode.properties.filter(
      (prop) => prop.annotation !== property.annotation
    );
  };

  return (
    <div className='mt-2'>
      {dialogNode.properties.map((property: PropertyValue, key) => (
        <div key={key} className='grid ctx-wrapper'>
          {property.annotation}
          {property.type === PropertyType.Simple && (
            <TextBoxWithCtx
              id={property.annotation}
              className='dataset-ctx-variable'
              value={property.value}
              saveHandler={(value) => (property.value = value)}
              placeholder=''
            />
          )}
          {property.type === PropertyType.Dataset && (
            <ContextVariableSelector
              items={ctxVarStore.datasetVariables.filter(
                (variable) => variable.type === property.datasetType
              )}
              triggerLabel={property.contextVariable?.name ?? 'Add or select'}
              selectHandler={(value) =>
                (property.contextVariable = ctxVarStore.datasetVariables.find(
                  (variable) => variable.name === value
                ))
              }
              clear
              replaceSpecialCharacters
              closeOnSelect
            />
          )}
          {property.type === PropertyType.ListDataset && (
            <DatasetListPropertiesValues property={property} updating={updating} />
          )}
          {updating && (
            <i
              className={getIcon('close')}
              onClick={() => removePropertyValue(property)}
            ></i>
          )}
        </div>
      ))}
    </div>
  );
};

export default observer(PropertiesValues);
