import { observer } from 'mobx-react-lite';
import React from 'react';
import ReactPaginate from 'react-paginate';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  page?: number;
  pageCount: number;
  pageChange: (page: number) => void;
}

const Pagination: React.FC<IProps> = ({ page, pageCount, pageChange }) => {
  const getIcon = useGetIcon();

  const handlePageChange = (nextPage: any) => {
    if (nextPage.nextSelectedPage || nextPage.nextSelectedPage === 0) {
      pageChange(nextPage.nextSelectedPage);
    }
  };

  return (
    <ReactPaginate
      previousLabel={<i className={getIcon('left')}></i>}
      nextLabel={<i className={getIcon('right')}></i>}
      forcePage={page}
      breakLabel='...'
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={1}
      onClick={handlePageChange}
      containerClassName='pagination'
      activeClassName='active'
    />
  );
};

export default observer(Pagination);
