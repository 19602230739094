import { Exclude, Expose, Type } from 'class-transformer';
import { action, makeObservable, observable } from 'mobx';
import { v4 } from 'uuid';
import { Entity, ISerializedEntity } from './Entity';

export class EntityDefinition {
  @Exclude()
  id: string;

  @Expose()
  text: string;

  @Expose()
  @Type(() => Entity)
  entities: Entity[];

  constructor(text: string = '', entities: Entity[] = [new Entity()]) {
    this.id = v4();
    this.text = text;
    this.entities = entities;

    makeObservable(this, {
      text: observable,
      entities: observable,
      addEntity: action,
      removeEntity: action,
    });
  }

  get isValid(): boolean {
    return (
      this.text !== '' &&
      this.entities.every((entity) => {
          if(entity.name !== '' && entity.value === '') return false;
          if(entity.name !== '' && entity.value !== '' && !this.text.toLowerCase().includes(entity.value.toLowerCase())) return false;
          return true;
        }
      )
    );
  }

  addEntity() {
    this.entities.push(new Entity());
  }

  removeEntity(entity: Entity) {
    this.entities = this.entities.filter((e) => e.id !== entity.id);
  }

  serialize(): ISerializedEntityDefinition {
    return {
      text: this.text,
      entities: this.entities
        .filter((entity) => entity.isValid)
        .map((entity) => entity.serialize()),
    };
  }
}

export interface ISerializedEntityDefinition {
  text: string;
  entities: ISerializedEntity[];
}
