import React from 'react';
import { Fragment } from 'react';
import Button from '../../../../../common/Button';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';

interface IProps {
  name: string;
  isManuallyAdded: boolean;
}

const Deleted: React.FC<IProps> = ({ name, isManuallyAdded }) => {
  const getIcon = useGetIcon();
  return (
    <Fragment>
      <div className='item-title-group'>
        <Button
          icon={getIcon('ban')}
          className='ctx-list-item-icon btn-borderless'
          data-state='toBeDeleted'
        />
        <span>
          <strong>{name}</strong>
          {isManuallyAdded && (
            <Button
              className='manually-added-ctx-icon'
              icon={getIcon('add_alt')}
              tooltip='This context variable was created manually'
              tooltipPosition='top'
            />
          )}
        </span>
      </div>
      <div className='ctx-list-item-details'>
        <p>
          The value of <strong>{name}</strong> will be erased.
        </p>
      </div>
    </Fragment>
  );
};

export default Deleted;
