import { observer } from 'mobx-react-lite';
import React from 'react';
import { DatasetNodeType } from '../../../../../architecture/enums/DialogComponentType';
import { DatasetActionNode as DatasetActionNodeModel } from '../../../../../models/DialogNodes/ActionNodes/DatasetActionNode';
import Button from '../../../../common/Button';
import { useGetIcon } from '../../../../customHooks/useGetIcon';
import DialogNode from '../../DialogNode';
import CreateNewDatasetVariable from './CreateNewDatasetVariable';
import UpdateExistingDatasetVariable from './UpdateExistingDatasetVariable';

interface IProps {
  dialogNode: DatasetActionNodeModel;
}

const DatasetActionNode: React.FC<IProps> = ({ dialogNode }) => {
  const getIcon = useGetIcon();

  return (
    <DialogNode dialogNode={dialogNode as DatasetActionNodeModel}>
      {dialogNode.nodeType === DatasetNodeType.Undefined && (
        <div className='grid repeat-2 mt-4'>
          <Button
            icon={getIcon('add')}
            content='Create new Dataset Variable'
            className='btn-round'
            clickHandler={() => (dialogNode.nodeType = DatasetNodeType.Create)}
          />
          <Button
            icon={getIcon('add')}
            content='Update existing Dataset Variable'
            className='btn-round'
            clickHandler={() => (dialogNode.nodeType = DatasetNodeType.Update)}
          />
        </div>
      )}
      {dialogNode.nodeType === DatasetNodeType.Create && (
        <CreateNewDatasetVariable dialogNode={dialogNode} />
      )}
      {dialogNode.nodeType === DatasetNodeType.Update && (
        <UpdateExistingDatasetVariable dialogNode={dialogNode} />
      )}
    </DialogNode>
  );
};

export default observer(DatasetActionNode);
