import { observer } from 'mobx-react-lite';
import React from 'react';
import { TriggerTypes } from '../../../../architecture/enums/TriggerTypes';
import { DialogBlock as DialogBlockModel } from '../../../../models/DialogBlocks/DialogBlock';
import { AiTrigger as AiTriggerModel } from '../../../../models/Triggers/AiTrigger';
import { EventTrigger as EventTriggerModel } from '../../../../models/Triggers/EventTrigger/EventTrigger';
import { RegexInputTrigger as RegexInputTriggerModel } from '../../../../models/Triggers/RegexInputTrigger';
import { Trigger as TriggerModel } from '../../../../models/Triggers/Trigger';
import { UserInputTrigger as UserInputTriggerModel } from '../../../../models/Triggers/UserInputTrigger';
import { useReadOnly } from '../../../customHooks/useReadOnly';
import ChooseTriggerStage from './ChooseTriggerStage';
import TriggerConfigStage from './TriggerConfigStage';

interface IProps {
  block: DialogBlockModel;
}
const TriggerTypeSelector: React.FC<IProps> = ({ block }) => {
  const { getReadOnly } = useReadOnly();
  const readOnly = getReadOnly();
  const handleChooseTrigger = (type: TriggerTypes) => {
    let localTrigger: TriggerModel | undefined;
    switch (type) {
      case TriggerTypes.RegexInput:
        localTrigger =
          block.trigger?.type === TriggerTypes.RegexInput
            ? block.trigger
            : new RegexInputTriggerModel();
        break;
      case TriggerTypes.UserInput:
        localTrigger =
          block.trigger?.type === TriggerTypes.UserInput
            ? block.trigger
            : new UserInputTriggerModel();
        break;
      case TriggerTypes.Event:
        localTrigger =
          block.trigger?.type === TriggerTypes.Event
            ? block.trigger
            : new EventTriggerModel();
        break;
      case TriggerTypes.AI:
        localTrigger =
          block.trigger?.type === TriggerTypes.AI ? block.trigger : new AiTriggerModel();
        break;
      default:
        break;
    }
    block.trigger = localTrigger;
  };
  return (
    <div
      className={`trigger-config-modal  ${readOnly ? 'blocked-trigger-overview' : ''}`}
      data-type={block.trigger?.type ?? 'not-specified'}
    >
      {block.trigger ? (
        <TriggerConfigStage block={block} />
      ) : (
        <ChooseTriggerStage block={block} chooseTriggerHandler={handleChooseTrigger} />
      )}
    </div>
  );
};

export default observer(TriggerTypeSelector);
