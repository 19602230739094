import { observer } from 'mobx-react-lite';
import React from 'react';
import { Role } from '../../../../architecture/enums/Roles';
import Checkbox from '../../../common/Checkbox';

interface IProps {
  disabled?: boolean;
  selectedRoles: string[];
  setSelectedRoles: (roles: string[]) => void;
}

const Roles: React.FC<IProps> = ({
  selectedRoles,
  setSelectedRoles,
  disabled = false,
}) => {
  const toggleRole = (value: boolean, role: string) => {
    if (value) {
      setSelectedRoles(selectedRoles.concat([role]));
    } else {
      setSelectedRoles(selectedRoles.filter((r) => r !== role));
    }
  };

  const renderRoles = () => {
    return Object.keys(Role).map((role: string, index) => {
      return (
        <Checkbox
          key={index}
          disabled={disabled}
          content={Object.values(Role)[index]}
          value={selectedRoles?.includes(role)}
          clickHandler={(value: boolean) => toggleRole(value, role)}
        />
      );
    });
  };

  return <div className='roles-container'>{renderRoles()}</div>;
};

export default observer(Roles);
