import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { KnowledgebaseEntry } from '../../../../models/Knowledgebase/KnowledgebaseEntry';
import { Question } from '../../../../models/Knowledgebase/Question';
import EditableTag from './EditableTag';
import QuestionLabel from './QuestionLabel';

interface IProps {
  entry: KnowledgebaseEntry;
  questionRef: React.RefObject<HTMLDivElement>;
  question: Question;
  questionWidth?: number | null;
}

const QuestionTag: React.FC<IProps> = ({ entry, questionRef, question }) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      {edit ? (
        <EditableTag
          entry={entry}
          edit={edit}
          setEdit={setEdit}
          question={question}
          questionRef={questionRef}
        />
      ) : (
        <QuestionLabel entry={entry} question={question} setEdit={setEdit} />
      )}
    </>
  );
};

export default observer(QuestionTag);
