import {
  AuxiliaryHistoryElementDescriptionTypes,
  InitiatingHistoryElementDescriptionTypes,
  NodeHistoryElementDescriptionTypes,
  TriggerHistoryElementDescriptionTypes,
} from '../../../architecture/enums/HistoryElementType';

import { ContextVariableUpdateElement } from '../../ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ContextVariableUpdateElement';
import { SystemMessageElement } from '../../ConversationHistory/HistoryElement/AuxiliaryHistoryElement/SystemMessageElement';
import { ValidationResultElement } from '../../ConversationHistory/HistoryElement/AuxiliaryHistoryElement/ValidationResultElement';
import { BaseHistoryElement } from '../../ConversationHistory/HistoryElement/BaseHistoryElement';

import { IncomingActionCardElement } from '../../ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingActionCardElement';
import { IncomingEventElement } from '../../ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingEventElement';
import { IncomingMessageElement } from '../../ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingMessageElement';

import { AdaptiveCardNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/AdaptiveCardNodeElement';
import { ApiNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/ApiNodeElement';
import { ContextActionNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/ContextActionNodeElement';
import { EmailActionNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/EmailActionNodeElement';
import { KnowledgebaseNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/KnowledgebaseNodeElement';
import { MessageNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/MessageNodeElement';
import { PromptNodeElement } from '../../ConversationHistory/HistoryElement/NodeHistoryElement/PromptNodeElement';
import { AiTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/AiTriggerElement';

import { DefaultTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/DefaultTriggerElement';
import { EventTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/EventTriggerElement';
import { KnowledgebaseTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/KnowledgebaseTriggerElement';
import { ListTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/ListTriggerElement';
import { LuisIntentTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/LuisIntentTriggerElement';
import { RegexTriggerElement } from '../../ConversationHistory/HistoryElement/TriggerHistoryElement/RegexTriggerElement';

interface Map {
  [key: string]: typeof BaseHistoryElement;
}

export const ELEMENT_TYPE_CLASS_MAPPING: Map = {
  [InitiatingHistoryElementDescriptionTypes.IncomingMessage]: IncomingMessageElement,
  [InitiatingHistoryElementDescriptionTypes.IncomingEvent]: IncomingEventElement,
  [InitiatingHistoryElementDescriptionTypes.IncomingActionCard]:
    IncomingActionCardElement,
  [TriggerHistoryElementDescriptionTypes.ListTrigger]: ListTriggerElement,
  [TriggerHistoryElementDescriptionTypes.RegexTrigger]: RegexTriggerElement,
  [TriggerHistoryElementDescriptionTypes.KnowledgebaseTrigger]:
    KnowledgebaseTriggerElement,
  [TriggerHistoryElementDescriptionTypes.LuisIntentTrigger]: LuisIntentTriggerElement,
  [TriggerHistoryElementDescriptionTypes.EventTrigger]: EventTriggerElement,
  [TriggerHistoryElementDescriptionTypes.DefaultTrigger]: DefaultTriggerElement,
  [TriggerHistoryElementDescriptionTypes.AiTrigger]: AiTriggerElement,
  [NodeHistoryElementDescriptionTypes.MessageNode]: MessageNodeElement,
  [NodeHistoryElementDescriptionTypes.PromptNode]: PromptNodeElement,
  [NodeHistoryElementDescriptionTypes.KnowledgebaseNode]: KnowledgebaseNodeElement,
  [NodeHistoryElementDescriptionTypes.EmailActionNode]: EmailActionNodeElement,
  [NodeHistoryElementDescriptionTypes.ContextActionNode]: ContextActionNodeElement,
  [NodeHistoryElementDescriptionTypes.ApiNode]: ApiNodeElement,
  [NodeHistoryElementDescriptionTypes.AdaptiveCardNode]: AdaptiveCardNodeElement,
  [AuxiliaryHistoryElementDescriptionTypes.ValidationResult]: ValidationResultElement,
  [AuxiliaryHistoryElementDescriptionTypes.ContextVariableUpdate]:
    ContextVariableUpdateElement,
  [AuxiliaryHistoryElementDescriptionTypes.SystemMessage]: SystemMessageElement,
};
