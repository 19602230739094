import {
  IGetHistoryElementResponse,
  ISearchConversationsBody,
  ISearchConversationsResponse,
} from '../../architecture/interfaces/HTTP/ConversationHistoryParams';
import { HttpAgent } from '../../utility/HttpAgent';

export class ConversationHistoryConnector {
  static async search(
    botId: string | undefined,
    dialogId: string,
    body: ISearchConversationsBody
  ): Promise<ISearchConversationsResponse> {
    return await HttpAgent.requests.post(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/ConversationHistory/Search`,
      body
    );
  }

  static async updateConversationReviewState(
    botId: string | undefined,
    dialogId: string,
    conversationThreadId: string,
    reviewed: boolean
  ): Promise<void> {
    return await HttpAgent.requests.put(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/ConversationHistory/${conversationThreadId}?reviewed=${reviewed}`,
      {}
    );
  }

  static async getHistoryElements(
    botId: string | undefined,
    dialogId: string,
    conversationThreadId: string
  ): Promise<IGetHistoryElementResponse> {
    return await HttpAgent.requests.get(
      `/Api/V2/Bots/${botId}/Dialogs/${dialogId}/ConversationHistory/${conversationThreadId}`
    );
  }
}
