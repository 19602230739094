import { observer } from 'mobx-react-lite';
import React from 'react';
import { HeaderGroup, Row as RowType, TableBodyProps, TableProps } from 'react-table';
import { TableData } from '../../../architecture/enums/TableData';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Row from './Row';

interface ITableProps {
  id?: string;
  selectedRow?: TableData;
  onRowClick?: (data: TableData, row?: RowType) => void;
  getTableProps: () => TableProps;
  getTableBodyProps: () => TableBodyProps;
  headerGroups: HeaderGroup<TableData>[];
  rows: RowType<TableData>[];
  prepareRow: (row: RowType<TableData>) => void;
  classRow?: string;
}

const Table: React.FC<ITableProps> = ({
  id,
  rows,
  onRowClick,
  classRow,
  headerGroups,
  selectedRow,
  getTableProps,
  prepareRow,
  getTableBodyProps,
}: ITableProps) => {
  const getIcon = useGetIcon();

  const renderHeaders = () => {
    return headerGroups.map((headerGroup, index) => {
      return (
        <div {...headerGroup.getHeaderGroupProps()} className='tr' key={index}>
          {headerGroup.headers.map((column) => {
            return (
              <div
                {...column.getHeaderProps({
                  style: { minWidth: column.minWidth, width: column.width },
                })}
                className='th'
              >
                <div className='flex' {...column?.getSortByToggleProps?.()}>
                  <span>{column.render('Header')} </span>
                  {column.canSort && (
                    <>
                      {column.isSorted ? (
                        <div className='ml-5'>
                          {column.isSortedDesc ? (
                            <i className={getIcon('sort_down')}></i>
                          ) : (
                            <i className={getIcon('sort_up')}></i>
                          )}
                        </div>
                      ) : (
                        <div className='ml-5'>
                          <i className={getIcon('sort_up_down')}></i>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderRows = () => {
    return rows.map((row: RowType, index: number) => {
      prepareRow(row);
      return (
        <Row
          key={index}
          row={row}
          selectedRow={selectedRow}
          onRowClick={onRowClick}
          classRow={classRow}
        />
      );
    });
  };

  return (
    <div id={id} className='table-container' {...getTableProps()}>
      <div className='table-head'>{renderHeaders()}</div>
      <div className='table-body' {...getTableBodyProps()}>
        {renderRows()}
      </div>
    </div>
  );
};

export default observer(Table);
