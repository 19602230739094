import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import Button from '../../common/Button';
import useAdminStores from '../../customHooks/useAdminStores';
import { useGetIcon } from '../../customHooks/useGetIcon';

const BillingTimespanSelector: React.FC = () => {
  const { billingAdminStore } = useAdminStores();
  const getIcon = useGetIcon();

  const [from, setFrom] = useState<Date | undefined>(undefined);
  const [to, setTo] = useState<Date | undefined>(undefined);

  const setCustomTimespan = () => {
    billingAdminStore.setTimespan(from, to);
  };

  const setFixedTimespan = (days: number) => {
    let now = new Date();

    const from = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate() - days + 1,
        0,
        0,
        0
      )
    );

    now = new Date();
    const to = new Date(
      Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 23, 59, 59)
    );

    setFrom(from);
    setTo(to);

    billingAdminStore.setTimespan(from, to);
  };

  const setLastMonth = () => {
    const now = new Date();
    const from = new Date(
      new Date(now.getUTCFullYear(), now.getUTCMonth() - 1, 2).setUTCHours(0, 0, 0)
    );
    const to = new Date(
      new Date(now.getUTCFullYear(), now.getUTCMonth()).setUTCHours(23, 59, 59)
    );

    setFrom(from);
    setTo(to);

    billingAdminStore.setTimespan(from, to);
  };

  return (
    <div className='select-billing-timespan'>
      <p className='introduction'>
        To view an overview of the service usage, you can enter a 'from' and 'to' date to
        be used as the basis for the calculation. <br />
        Simply select the desired dates in the input fields provided or click on one of
        the predefined timespans below.
      </p>
      <div className='timespan-picker-group'>
        <div className='timespan-picker'>
          <p>From</p>
          <DateTimePicker
            calendarIcon={<i className={`${getIcon('caret_down')} date-icon`}> </i>}
            calendarClassName='date-filter'
            value={from}
            maxDate={to}
            disableClock={true}
            format='dd.MM.yyyy'
            onChange={(date) => setFrom(date)}
          />
        </div>
        <div className='timespan-picker'>
          <p>To</p>
          <DateTimePicker
            calendarIcon={<i className={`${getIcon('caret_down')} date-icon`}> </i>}
            calendarClassName='date-filter'
            value={to}
            maxDate={new Date()}
            disableClock={true}
            format='dd.MM.yyyy'
            onChange={(date) => setTo(date)}
          />
        </div>
        <div>
          <Button
            content='Select'
            className='btn-primary btn-round'
            clickHandler={() => setCustomTimespan()}
          />
        </div>
      </div>

      <div className='button-wrapper'>
        <div className='button-group'>
          <Button
            content='Last 7 days'
            className='btn-primary btn-round'
            clickHandler={() => setFixedTimespan(7)}
          />
          <Button
            content='Last 30 days'
            className='btn-primary btn-round'
            clickHandler={() => setFixedTimespan(30)}
          />
          <Button
            content='Last month'
            className='btn-primary btn-round'
            clickHandler={() => setLastMonth()}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(BillingTimespanSelector);
