import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React from 'react';
import { useLocation } from 'react-router';
import { FILTERS, PARAMS } from '../../../architecture/constants/ConversationHistory';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface IProps {
  clearParamsChange: (key: string) => void;
}

const FilterBoxes: React.FC<IProps> = ({ clearParamsChange }) => {
  const location = useLocation();
  const getIcon = useGetIcon();
  const params = queryString.parse(location.search);

  const getParamsDescription = (key: string) => {
    switch (key) {
      case FILTERS.USER_MESSAGE.NAME:
        return PARAMS.USER_MESSAGE;
      case FILTERS.FROM_SCORE.NAME:
        return PARAMS.FROM_SCORE;
      case FILTERS.TO_SCORE.NAME:
        return PARAMS.TO_SCORE;
      case FILTERS.CHANNEL.NAME:
        return PARAMS.CHANNEL;
      case FILTERS.TRIGGER_BLOCK_NAME.NAME:
        return PARAMS.TRIGGER_BLOCK_NAME;
      case FILTERS.EVENT_ID.NAME:
        return PARAMS.EVENT_ID;
      case FILTERS.TRIGGER_TYPE.NAME:
        return PARAMS.TRIGGER_TYPE;
      case FILTERS.SESSION_ID.NAME:
        return PARAMS.SESSION_ID;
      case FILTERS.REVIEWED.NAME:
        return PARAMS.REVIEWED;
      case FILTERS.FROM_DATE.NAME:
        return PARAMS.FROM_DATE;
      case FILTERS.TO_DATE.NAME:
        return PARAMS.TO_DATE;
      default:
        break;
    }
  };

  const getFilters = () => {
    return Object.entries(params)
      .filter(([key, value]) => key !== FILTERS.PAGE.NAME && key !== FILTERS.SORT.NAME)
      .map(([key, value]) => {
        return (
          <div key={key} className='filter-box'>
            <div className='filter-title'> {getParamsDescription(key)} </div>
            {key === FILTERS.FROM_DATE.NAME || key === FILTERS.TO_DATE.NAME ? (
              <i> {value && format(new Date(value.toString()), 'dd.MM.yyyy HH:mm')} </i>
            ) : (
              <i> {value} </i>
            )}
            <div className='clear'>
              <span onClick={() => clearParamsChange(key)}>
                <i className={getIcon('close')}> </i>
              </span>
            </div>
          </div>
        );
      });
  };

  return <div className='filter-boxes'>{getFilters()}</div>;
};

export default observer(FilterBoxes);
