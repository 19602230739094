import { computed, makeObservable } from 'mobx';
import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { Utilities } from '../../../Utilities/Utilities';
import { AdaptiveCardNode } from '../../AdaptiveCardNode/AdaptiveCardNode';

export class QuestionAdaptiveCardNode extends AdaptiveCardNode {
  type = DialogNodeTypes.QuestionAdaptiveCardNode;
  constructor(block: DialogBlock) {
    super(block);
    this.title = 'Question Adaptive Card Node';

    makeObservable(this, {
      areInputsValid: computed,
    });
  }

  get isValid() {
    return (
      super.isValid &&
      !Utilities.isEmpty(this.inputMapping) &&
      this.areInputsValid &&
      this.submitMapping.length === 1 &&
      this.submitMapping.every((item) => item.isValid)
    );
  }

  get areInputsValid() {
    return this.inputMapping.every((item) => item.isValid);
  }
}
