import React, { useContext } from 'react';
import lailo_logo from '../../../assets/lailo_logo_white_without_claim-4.png';
import lailo_pic from '../../../assets/lailo_station.jpg';
import AuthContext from '../../../ms_authentication/context/AuthContext';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { useGetIcon } from '../../customHooks/useGetIcon';

interface ICard {
  number: number;
  icon: string;
  title: string;
  description: string;
}

const WelcomePage = () => {
  const getIcon = useGetIcon();

  const { login, signUp } = useContext(AuthContext);

  const CARDS = [
    {
      number: 1,
      icon: getIcon('body'),
      title: 'Create Dialog Blocks',
      description:
        'Create dialog blocks to group parts of the conversation and define when blocks are triggered.',
    },
    {
      number: 2,
      icon: getIcon('message'),
      title: 'Create Dialog Nodes',
      description:
        'Create dialog nodes within a block and fill your conversation with life.',
    },
    {
      number: 3,
      icon: getIcon('link'),
      title: 'Connect The Blocks',
      description: 'Connect dialog blocks and determine the direction of the dialog flow',
    },
    {
      number: 4,
      icon: getIcon('check'),
      title: 'Done!',
      description:
        'Congratulations! Your dialog structure is finished and you can interact with your customers.',
    },
  ];

  return (
    <div id='welcome-page-container'>
      <div
        className='top'
        style={{ background: `url(${lailo_pic}) no-repeat center center/cover` }}
      >
        <div className='showcase'>
          <h1 className='main-title'>Welcome to the Lailo Dialog Editor</h1>
          <h3 className='meta-description'>
            Let your customers interact with an artificial emotional intelligence. The
            digital assistant reflects your brand identity and makes your service an
            experience.
          </h3>
          <div className='btn-container'>
            <Button
              icon={getIcon('sign_in')}
              className='btn-primary btn-round weight-600'
              content='Login'
              clickHandler={login}
              name={'sign-in-btn'}
            />
            <Button
              icon={getIcon('user_circle')}
              className='btn-primary btn-round weight-400 sign-up-btn'
              content='Register'
              clickHandler={signUp}
              name={'sign-up-btn'}
            />
          </div>
        </div>
      </div>
      <div className='middle'>
        {CARDS.map((item) => (
          <Card key={item.number} item={item} />
        ))}
      </div>
      <div className='bottom'>
        <h2 className='bottom-title'>Don't you have an account yet?</h2>
        <p className='bottom-description'>
          You can reach out to us on multiple platforms and we can help you to set up your
          first Lailo Intelligent Avatar!
        </p>
        <div className='contact-container'>
          <a
            data-target='facebook'
            href='https://www.facebook.com/LorentITLoesungen'
            rel='noopener noreferrer'
            target='_blank'
            className='contact-link'
          >
            <Icon icon='fab fa-facebook' />
          </a>
          <a data-target='mail' href='mailto:info@lailo.ai' className='contact-link'>
            <Icon icon={getIcon('envelope_full')} />
          </a>
          <a
            data-target='lailo-website'
            href='https://lailo.ai'
            rel='noopener noreferrer'
            target='_blank'
            className='contact-link'
            style={{ background: `url(${lailo_logo}) no-repeat center center/contain` }}
          ></a>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;

interface ICardProps {
  item: ICard;
}
const Card: React.FC<ICardProps> = ({ item }) => {
  return (
    <div className='welcome-card'>
      <h3 className='title'>
        <Icon icon={item.icon} />
        {item.title}
      </h3>
      <div className='description'>{item.description}</div>
    </div>
  );
};
