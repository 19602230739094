import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Cell, Column, useFlexLayout, useTable } from 'react-table';
import { DATASET_HEADERS } from '../../../architecture/constants/Dataset';
import { DatasetState } from '../../../architecture/enums/DatasetState';
import { TableData } from '../../../architecture/enums/TableData';
import { Dataset } from '../../../models/Dataset/Dataset';
import { Utilities } from '../../../models/Utilities/Utilities';
import rootStore from '../../../stores/rootStore';
import Table from '../../common/table/Table';
import LoadingPage from '../../layout/LoadingPage';
import DatasetListHeader from './components/DatasetListHeader';
import Status from './components/Status';

interface IProps {
  className?: string;
  editMode?: boolean;
  setEditMode?: (value: boolean) => void;
  setAddDataset?: (value: boolean) => void;
}

const DatasetList: React.FC<IProps> = ({
  className,
  editMode,
  setEditMode,
  setAddDataset,
}) => {
  const { datasetStore } = useContext(rootStore);
  const [datasets, setDatasets] = useState(datasetStore.allDatasets);

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        id: DATASET_HEADERS.name.id,
        Header: DATASET_HEADERS.name.label,
        accessor: (dataset: TableData) => (dataset as Dataset).name,
        minWidth: 150,
      },
      {
        id: DATASET_HEADERS.status.id,
        Header: DATASET_HEADERS.status.label,
        accessor: (dataset: TableData) => (dataset as Dataset).status,
        Cell: (item: Cell<Dataset>) => <Status dataset={item.row.original} />,
      },
      {
        id: DATASET_HEADERS.creationDate.id,
        Header: DATASET_HEADERS.creationDate.label,
        accessor: (dataset: TableData) => {
          return (dataset as Dataset)?.created
            ? Utilities.getLocalDate((dataset as Dataset).created!, 'dateOnly')
            : 'n/a';
        },
      },
      {
        id: DATASET_HEADERS.entries.id,
        Header: DATASET_HEADERS.entries.label,
        accessor: (dataset: TableData) => (dataset as Dataset).entries,
      },
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    if (!datasets) {
      return [];
    }

    return datasets.map((dataset: Dataset) => {
      return {
        id: dataset.id,
        status: dataset.status || '',
        name: dataset.name || '',
        created: dataset.created || '',
        entries: dataset.entries,
        properties: dataset.properties || [],
      };
    });
  }, [JSON.stringify(datasets)]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useFlexLayout
  );

  const handleRowClick = (dataset: TableData) => {
    if (setEditMode) setEditMode(false);
    if (setAddDataset) setAddDataset(false);
    datasetStore.setSelectedDataset((dataset as Dataset).id);
  };

  return (
    <div className={`container-wrapper ${className ? className : ''}`}>
      {datasetStore.getState(DatasetState.DatasetsLoading) ||
      datasetStore.getState(DatasetState.DatasetCreating) ? (
        <LoadingPage />
      ) : (
        <>
          {datasetStore.getState(DatasetState.Initialized) && (
            <>
              <DatasetListHeader
                setDatasets={setDatasets}
                setAddDataset={setAddDataset && setAddDataset}
                editMode={editMode && editMode}
              />
              <Table
                getTableProps={getTableProps}
                getTableBodyProps={getTableBodyProps}
                headerGroups={headerGroups}
                rows={rows}
                selectedRow={datasetStore.getSelectedDataset as TableData}
                prepareRow={prepareRow}
                onRowClick={handleRowClick}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default observer(DatasetList);
