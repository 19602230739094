import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { ApiNodeElement as ApiNodeElementModel } from '../../../../models/ConversationHistory/HistoryElement/NodeHistoryElement/ApiNodeElement';
import { Utilities } from '../../../../models/Utilities/Utilities';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import BaseHistoryElement from '../BaseHistoryElement';

interface IProps {
  element: ApiNodeElementModel;
}

const ApiNodeElement: React.FC<IProps> = ({ element }: IProps) => {
  const [openAdditionalInfo, setOpenAdditionalInfo] = useState(false);
  const getIcon = useGetIcon();

  const getHttpCode = () => {
    const code = element.statusCode.toString();
    if (code.startsWith('2')) {
      return <div className='success'> {element.statusCode} </div>;
    } else {
      return <div className='error'> {element.statusCode} </div>;
    }
  };

  const showParagraph = !!element?.body || !!element?.responseBody;

  return (
    <BaseHistoryElement
      element={element}
      openAdditionalInfo={openAdditionalInfo}
      setOpenAdditionalInfo={showParagraph ? setOpenAdditionalInfo : undefined}
      icon={getIcon('robot')}
      title='Api Node was executed'
    >
      <div className='card-paragraph'>
        <i className={getIcon('network')}></i>
        <span className='message-wrapper'>{element.httpMethod}</span>
        <span className='message-wrapper'>{element.url}</span>
        <span className='message-wrapper'>Result: {getHttpCode()}</span>
      </div>
      {openAdditionalInfo && showParagraph && (
        <div className='card-paragraph'>
          {element?.body && (
            <>
              <span className='card-paragraph-headline'>
                <span className='card-paragraph-headline-icon'>&#8599;</span>
                Request
              </span>
              {Utilities.isJsonValid(element.body) ? (
                <pre>{JSON.stringify(JSON.parse(element.body), null, 2)}</pre>
              ) : (
                <>
                  <p className='invalid-json-warning'>
                    Warning: This is not a valid JSON format.
                  </p>
                  <pre>{element.body}</pre>
                </>
              )}
            </>
          )}
          {element?.responseBody && (
            <>
              <span className='card-paragraph-headline'>
                <span className='card-paragraph-headline-icon'>&#8600;</span>
                Response
              </span>
              {Utilities.isJsonValid(element.responseBody) ? (
                <pre>{JSON.stringify(JSON.parse(element.responseBody), null, 2)}</pre>
              ) : (
                <>
                  <p className='invalid-json-warning'>
                    Warning: This is not a valid JSON format.
                  </p>
                  <pre>{element.responseBody}</pre>
                </>
              )}
            </>
          )}
        </div>
      )}
    </BaseHistoryElement>
  );
};

export default observer(ApiNodeElement);
