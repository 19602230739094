import { DialogNodeTypes } from '../../../../architecture/enums/DialogComponentType';
import {
  DateTimeGroup,
  DateTimeKeyMapping,
} from '../../../../architecture/enums/SubQuestionKey';
import { DialogBlock } from '../../../DialogBlocks/DialogBlock';
import { GroupedMultiQuestionNode } from '../MultiQuestionNode/GroupedMultiQuestionNode';

export class DateTimeQuestionNode extends GroupedMultiQuestionNode {
  type = DialogNodeTypes.DateTimeQuestionNode;
  constructor(block: DialogBlock) {
    super(block, Object.values(DateTimeGroup), DateTimeKeyMapping);

    this.title = 'DateTime Question Node';
  }
}
