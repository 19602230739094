import React, { Fragment } from 'react';
import { JsonContainer as JsonContainerModel } from '../../../../../../models/Json/JsonContainer';
import { JsonElement } from '../../../../../../models/Json/JsonElement';
import { JsonValue } from '../../../../../../models/Json/JsonValue';
import Button from '../../../../../common/Button';
import { useGetIcon } from '../../../../../customHooks/useGetIcon';

interface IProps {
  element: JsonContainerModel;
  index: number;
  isOpen: boolean;
  isKeyStored: boolean;
  isChildValueStored: boolean;
  isJsonKeyStored: boolean;
  isChildPrimitiveArrayKeyStored: boolean;
  storeKey: () => void;
  toggleHideChildren: () => void;
}
const FoldableContainer: React.FC<IProps> = ({
  element,
  index,
  isOpen,
  isKeyStored,
  isChildValueStored,
  isJsonKeyStored: isChildKeyStored,
  isChildPrimitiveArrayKeyStored,
  storeKey,
  toggleHideChildren,
}) => {
  const getIcon = useGetIcon();

  const canBeStored =
    element.isArrayContainer &&
    element.children.every(
      (child) => child instanceof JsonValue && child.index !== undefined
    );

  return (
    <div
      className='json-container-row'
      data-stored={isKeyStored}
      data-can-be-stored={canBeStored}
      onClick={canBeStored ? undefined : toggleHideChildren}
    >
      <Button
        className='btn-borderless open-close-btn'
        icon={getIcon(isOpen ? 'minus' : 'add')}
        clickHandler={toggleHideChildren}
      />
      <Button
        content={element.name as string}
        className='btn-borderless key-name-btn'
        clickHandler={canBeStored ? storeKey : toggleHideChildren}
      />
      <div className='highlight-container'>
        {isChildKeyStored && index == 0 && (
          <InlineHighlight
            type='key'
            icon={getIcon('star')}
            tooltip='A list context variable was created from one of the attributes'
          />
        )}
        {!isOpen && (
          <Fragment>
            {isChildValueStored && (
              <InlineHighlight
                type='value'
                icon={getIcon('star')}
                tooltip='This element contains a stored value'
              />
            )}
            {isChildPrimitiveArrayKeyStored && (
              <InlineHighlight
                type='array'
                icon={getIcon('star')}
                tooltip='A list is stored within this container'
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

// Preventing re-render of all FoldableContainer components if one container gets opened. It immensely increases performance!
export default React.memo(FoldableContainer, (prevProps, nextProps) => {
  if (prevProps.isOpen !== nextProps.isOpen) return false;
  if (prevProps.isKeyStored !== nextProps.isKeyStored) return false;
  if (prevProps.isChildValueStored !== nextProps.isChildValueStored) return false;
  if (prevProps.isJsonKeyStored !== nextProps.isJsonKeyStored) return false;
  if (
    prevProps.isChildPrimitiveArrayKeyStored !== nextProps.isChildPrimitiveArrayKeyStored
  )
    return false;

  return true;
});

interface IHighlightProps {
  tooltip: string;
  icon: string;
  type: 'value' | 'array' | 'key';
}
export const InlineHighlight: React.FC<IHighlightProps> = ({ tooltip, icon, type }) => {
  return (
    <div className='inline-highlight' data-type={type}>
      <i className={icon}></i>
      <span className='tooltip'>{tooltip}</span>
    </div>
  );
};
