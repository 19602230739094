import React, { useContext } from 'react';
import NodeConfigurationContext from '../../../contexts/communicationConfig/NodeConfigurationContext';

const TabContainer: React.FC = ({ children }) => {
  const { showDetails } = useContext(NodeConfigurationContext);

  return showDetails ? (
    <div className='tab-container'>{children}</div>
  ) : null;
};

export default TabContainer;
