import { computed, makeObservable } from 'mobx';
import { DialogNodeTypes } from '../../../architecture/enums/DialogComponentType';
import { ActionCardCondition } from '../../Conditions/ActionCardCondition';
import { DialogBlock } from '../../DialogBlocks/DialogBlock';
import { Redirect } from '../../Redirects/Redirect';
import { Utilities } from '../../Utilities/Utilities';
import { AdaptiveCardNode } from '../AdaptiveCardNode/AdaptiveCardNode';
import { AdaptiveCardSubmit } from '../AdaptiveCardNode/Misc/AdaptiveCardSubmit';
import { Redirectable } from '../RedirectableNode';

class BaseActionAdaptiveCardNode extends AdaptiveCardNode {
  type = DialogNodeTypes.ActionAdaptiveCardNode;
  constructor(block: DialogBlock) {
    super(block);

    this.title = 'Action Adaptive Card Node';

    makeObservable(this, {
      areInputsValid: computed,
    });
  }

  get isValid() {
    return (
      super.isValid &&
      !Utilities.isEmpty(this.submitMapping) &&
      this.submitMapping.every((item) => item.isValid) &&
      this.areInputsValid
    );
  }

  get areInputsValid() {
    if (!this.inputMapping) return true;

    return this.inputMapping.every((item) => item.isValid);
  }
}

export class ActionAdaptiveCardNode extends Redirectable(BaseActionAdaptiveCardNode) {
  extractSubmits() {
    super.extractSubmits();

    this.removeUnmappedRedirects();

    this.removeInvalidSubmitRedirects();

    this.mapSubmitsToRedirects();
  }

  clearTemplate() {
    super.clearTemplate();

    [...this.redirects].forEach((redirect) => this.removeRedirect(redirect));
  }

  getRedirectOfSubmit(submit: AdaptiveCardSubmit): Redirect {
    return this.redirects.find((redirect) =>
      redirect.conditions.some(
        // A redirect is being mapped to a submit by its condition. A condition's action ID is the submit.data's action ID.
        (cond) => (cond as ActionCardCondition).actionId === submit.data._actionId
      )
    )!;
  }

  private removeUnmappedRedirects() {
    this.redirects.forEach((redirect) => {
      const doesSubmitStillExist = !!redirect.conditions.find((cond) =>
        this.submitMapping.some(
          (submit) => submit.data._actionId === (cond as ActionCardCondition).actionId
        )
      );
      if (!doesSubmitStillExist) {
        this.removeRedirect(redirect);
      }
    });
  }

  private removeInvalidSubmitRedirects() {
    const invalidSubmits = this.submitMapping.filter((submit) => !submit.isValid);

    invalidSubmits.forEach((submit) => {
      const redirect = this.redirects.find((redirect) =>
        redirect.conditions.find(
          (cond) => (cond as ActionCardCondition).actionId === submit.data._actionId
        )
      );
      redirect && this.removeRedirect(redirect);
    });
  }

  private mapSubmitsToRedirects() {
    const validSubmits = this.submitMapping.filter((submit) => submit.isValid);

    this.redirects = validSubmits.map((submit) => {
      const existingRedirect = this.redirects.find((redirect) =>
        redirect.conditions.find(
          (cond) => (cond as ActionCardCondition).actionId === submit.data._actionId
        )
      );

      if (existingRedirect) return existingRedirect;

      return new Redirect(undefined, [new ActionCardCondition(submit.data._actionId)]);
    });
  }

  serialize(): any {
    const validRedirects = this.redirects.filter((redirect) => redirect.isValid);

    return {
      ...super.serialize(),
      redirects: Utilities.isEmpty(validRedirects)
        ? undefined
        : validRedirects.map((redirect) => redirect.serialize()),
    };
  }
}
