import { makeObservable, observable } from 'mobx';
import { PathElementTypes } from '../../architecture/interfaces/IPathElement';
import { JsonContainer } from './JsonContainer';
import { JsonElement } from './JsonElement';
import { JsonValue } from './JsonValue';
import { ListElement } from './PathElements/ListElement';
import { PathElement } from './PathElements/PathElement';
import { RootContainer } from './RootContainer';

export class JsonProcessor {
  elements: JsonElement[] = [];

  constructor(json: any) {
    this.elements = JsonProcessor.createFlatList(json);

    makeObservable(this, {
      elements: observable,
    });
  }

  /** Processes an entire HTTP Response (JSON) and returns a new Array that contains elements that track their nested level and their parents. */
  public static createFlatList(data: any): JsonElement[] {
    let objectContainerArray: JsonElement[] = [];

    const create = (
      parent: JsonContainer | null,
      key: string | number,
      value: any,
      index?: number
    ) => {
      // setting depth to the parent's depth or to null if we are on the top-level
      let depth = parent?.depth ?? 0;

      // if we have a simple key-value pair
      if (typeof value !== 'object' && !Array.isArray(value)) {
        if (typeof value === 'boolean') value = value.toString();

        objectContainerArray.push(
          new JsonValue(parent, key as string, depth + 1, value, index)
        );

        // if the value is an Array instance:
        // - Depth must be incremented
        // - We iterate through the values as long as we don't have a simple key-value pair
        // - Parent objects are pushed into the array and will be tracked by their children
      } else if (Array.isArray(value)) {
        depth++;

        const container = new JsonContainer(parent, key, depth, index);
        objectContainerArray.push(container);

        value.forEach((val, i) => create(container, i, val, i));

        // If the value is a simple object we can create another container and this function will be called recursively for each of its blocks
      } else if (typeof value === 'object') {
        depth++;

        // "null" is type of "object" in JavaScript!
        // We cannot know if the property whose value is "null" is supposed to be a simple row (JsonValue) or a JsonContainer
        // That's why we create a JsonValue
        if (value == null) {
          objectContainerArray.push(
            new JsonValue(parent, key as string, depth, null, index)
          );
        } else {
          let container = new JsonContainer(parent, key, depth, index);
          objectContainerArray.push(container);

          Object.entries(value).forEach(([key, val]) => create(container, key, val));
        }
      }
    };

    // The data is a simple string (like a GUID) or a number
    if (typeof data !== 'object' && !Array.isArray(data)) {
      create(null, '', data);
    } else if (Array.isArray(data)) {
      // If data is a simple array of primitive types
      if (data.every((item) => typeof item !== 'object')) {
        const root = new RootContainer();
        objectContainerArray.push(root);

        Object.entries(data).forEach(([key, value], index) => {
          create(root, key, value, index);
        });
      } else {
        // Data is an array of objects
        Object.entries(data).forEach(([key, value], index) => {
          create(null, key, value, index);
        });
      }
    } else {
      // Data is a nested object
      Object.entries(data).forEach(([key, value]) => {
        create(null, key, value);
      });
    }

    return objectContainerArray;
  }

  /**
   * Looks for a JsonElement in the elements array whose serialized path equals the provided path parameter
   * @param elements An array of JsonElement instances
   * @param path An array of IPathElements
   * @returns A JsonElement or undefined
   */
  public static getElementByPath(elements: JsonElement[], path: PathElement[]) {
    // If the path parameter contains a ListElement it is
    // - either a JsonContainer which is an ArrayContainer of primitive types
    // - or a JsonValue whose Key has been stored
    // - or a RootContainer where the ListElement has a key of null and a nestedProperties of undefined
    const listElement = path.find(
      (item) => item.type === PathElementTypes.ListElement
    ) as ListElement;

    const containsListElement = !!listElement;

    if (
      containsListElement &&
      listElement?.key == null &&
      typeof listElement?.nestedProperties == 'undefined'
    ) {
      // It is the RootContainer which is only present once in the structure
      return elements.find((elem) => elem instanceof RootContainer);
    }

    // Else we have to search...
    return elements.find(
      (elem) =>
        PathElement.getStringifiedPathOf(path) ===
        JsonElement.getStringifiedPathOf(elem, containsListElement)
    );
  }

  public static getElementByStringifiedPathTree(
    elements: JsonElement[],
    path: string,
    containsListElement: boolean
  ) {
    return elements.find(
      (elem) => JsonElement.getStringifiedPathOf(elem, containsListElement) === path
    );
  }

  clear() {
    this.elements = [];
  }
}
