import * as actions from '../actionTypes';

export const nodeConfigurationReducer = (state: any, action: any) => {
  switch (action.type) {
    case actions.SET_SHOW_DETAILS:
      return {
        ...state,
        showDetails: action.payload,
      };
    default:
      break;
  }
};
