export const FILTERS = {
  TYPE: {
    NAME: 'type',
    TITLE: 'Media type',
  },
  NAME: {
    NAME: 'name',
    TITLE: 'Name',
  },
};

export const TYPE_OPTIONS = [
  {
    label: '1',
    title: 'Document',
  },
  {
    label: '2',
    title: 'Image',
  },
  {
    label: '3',
    title: 'Video',
  },
];

export const IMAGES_CONTENT_TYPE = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/tiff',
  'image/webp',
];
export const VIDEOS_CONTENT_TYPE = [
  'video/mp4',
  'video/mpeg',
  'video/x-msvideo',
  'video/ogg',
  'video/webm',
];
export const DOCUMENTS_CONTENT_TYPE = [
  'application/pdf',
  'application/msword',
  'application/json',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.ms-powerpoint',
  'application/vnd.ms-excel',
  'application/rtf',
];
export const PDF_CONTENT_TYPE = ['application/pdf'];
export const PRESENTATION_CONTENT_TYPE = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const SPREADSHEET_CONTENT_TYPE = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const LINE_CONTENT_TYPE = [
  'application/rtf',
  'application/msword',
  'application/json',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.oasis.opendocument.text',
];
