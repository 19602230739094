import { observer } from 'mobx-react-lite';
import React from 'react';
import { IReceiver } from '../../../../../../architecture/interfaces/trigger/IEventTrigger';
import { GroupReceiver as GroupReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/GroupReceiver';
import { UserReceiver as UserReceiverModel } from '../../../../../../models/Triggers/EventTrigger/EventTarget/Telegram/Receivers/UserReceiver';
import GroupReceiver from './receivers/GroupReceiver';
import UserReceiver from './receivers/UserReceiver';

interface IProps {
  receiver: IReceiver;
}

const Receiver: React.FC<IProps> = ({ receiver }) => {
  const component = (() => {
    if (receiver instanceof UserReceiverModel)
      return <UserReceiver receiver={receiver} />;

    if (receiver instanceof GroupReceiverModel)
      return <GroupReceiver receiver={receiver} />;

    throw new Error('Receiver Instance is not defined or not implemented.');
  })();

  return <div className='receiver-details'>{component}</div>;
};

export default observer(Receiver);
