import { observer } from 'mobx-react-lite';
import React from 'react';
import { Notification } from '../../../../models/Utilities/Notification';
import { useGetIcon } from '../../../customHooks/useGetIcon';
import useModal from '../../../customHooks/useModal';
import Button from '../../Button';

interface IProps {
  confirmationText?: string;
  onConfirmed: () => void;
  onCancel: () => void;
  confirmedNotification?: string;
  cancelledNotification?: string;
}

const Confirm: React.FC<IProps> = ({
  onCancel,
  onConfirmed,
  confirmationText,
  confirmedNotification,
  cancelledNotification,
}) => {
  const { closeModal } = useModal();
  const getIcon = useGetIcon();

  const confirm = async () => {
    new Notification({
      text: confirmedNotification ?? 'Confirmed',
      type: 'success',
    });

    onConfirmed();
    closeModal();
  };

  const cancel = async () => {
    new Notification({
      text: cancelledNotification ?? 'Cancelled',
      type: 'error',
    });

    onCancel();
    closeModal();
  };

  const text = confirmationText ?? 'Are you sure you want to proceed?';

  return (
    <div className='modal-default'>
      <div className='header'>
        <h3 className='header-title'>{text}</h3>
      </div>
      <div className='btn-container'>
        <Button
          content='Yes'
          icon={getIcon('check_simple')}
          className='btn-dark btn-round'
          clickHandler={confirm}
        />
        <Button
          content='No'
          icon={getIcon('close')}
          className='btn-primary btn-round'
          clickHandler={() => cancel()}
        />
      </div>
    </div>
  );
};

export default observer(Confirm);
