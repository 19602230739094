import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { IncomingEventElement as IncomingEventElementModel } from '../../../../models/ConversationHistory/HistoryElement/InitiatingHistoryElement/IncomingEventElement';
import BaseHistoryElement from '../BaseHistoryElement';
import { EventTrigger } from '../../../../models/Triggers/EventTrigger/EventTrigger';
import rootStore from '../../../../stores/rootStore';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IProps {
  element: IncomingEventElementModel;
}

const IncomingEventElement: React.FC<IProps> = ({ element }: IProps) => {
  const { blockStore } = useContext(rootStore);
  const getIcon = useGetIcon();
  const block = blockStore?.allBlocks?.find(block => (block?.trigger as EventTrigger)?.eventId === element.eventId);

  return (
    <BaseHistoryElement element={element} title='Incoming Event' icon={getIcon('event_trigger')}>
      <div className='card-paragraph'>
        <i className={getIcon('send')}></i>
        <span className='card-paragraph-text'> <i> Event Id: {element.eventId} </i> </span>
      </div>
    </BaseHistoryElement>
  );
};

export default observer(IncomingEventElement);
