import React from 'react';
import { Utilities } from '../../../models/Utilities/Utilities';
import { useGetIcon } from '../../customHooks/useGetIcon';
import Input from '../Input';

interface IProps {
  value: string;
  placeholder?: string;
  replaceSpecialCharacters?: boolean;
  changeHandler: (value: string) => void;
  keyDownHandler: (event: React.KeyboardEvent) => void;
}
const SearchField: React.FC<IProps> = ({
  value,
  replaceSpecialCharacters,
  changeHandler,
  keyDownHandler,
  placeholder = 'Search for an item...',
  children,
}) => {
  const getIcon = useGetIcon();

  const interceptHandleChange = (value: string) => {
    if (replaceSpecialCharacters) {
      value = Utilities.replaceAllSpecialCharactersInText({ text: value });
    }
    changeHandler(value);
  };

  return (
    <div className='search-field'>
      {children ? children : <i className={getIcon('search')}></i>}
      <Input
        name='filter-input'
        autoFocus
        value={value}
        changeHandler={interceptHandleChange}
        keyDownHandler={keyDownHandler}
        placeholder={placeholder}
        inputFactory
      />
    </div>
  );
};

export default SearchField;
