import { observer } from 'mobx-react-lite';
import React from 'react';
import { SubQuestionKey } from '../../../../../../../architecture/enums/SubQuestionKey';
import { SubQuestion } from '../../../../../../../models/DialogNodes/QuestionNodes/MultiQuestionNode/SubQuestion';
import Button from '../../../../../../common/Button';
import { useGetIcon } from '../../../../../../customHooks/useGetIcon';

interface IProps {
  keys: SubQuestionKey[];
  canAddSubQuestions: boolean;
  isKeyStored: (key: SubQuestionKey) => boolean;
  addSubQuestionHandler: (key: SubQuestionKey) => SubQuestion;
  removeSubQuestionHandler: (key: SubQuestionKey) => void;
}

const MultiQuestionSubQuestionInserter: React.FC<IProps> = ({
  keys,
  canAddSubQuestions,
  isKeyStored,
  addSubQuestionHandler,
  removeSubQuestionHandler,
}) => {
  const getIcon = useGetIcon();

  const renderButton = (key: SubQuestionKey) => {
    const settings = getButtonSettings(key);

    return (
      <Button
        key={key}
        icon={settings.icon}
        className={`btn-round ${settings.theme}`}
        disabled={!canAddSubQuestions}
        content={`${key}`}
        clickHandler={settings.clickHandler}
      />
    );
  };

  const getButtonSettings = (key: SubQuestionKey) => {
    if (isKeyStored(key)) {
      return {
        icon: getIcon('minus'),
        theme: 'btn-dark',
        clickHandler: () => {
          removeSubQuestionHandler(key);
        },
      };
    }
    return {
      icon: getIcon('add'),
      theme: 'btn-primary',
      clickHandler: () => {
        addSubQuestionHandler(key);
      },
    };
  };
  return (
    <div className='multi-ctx-button-container'>
      {keys.map((key) => renderButton(key))}
    </div>
  );
};

export default observer(MultiQuestionSubQuestionInserter);
