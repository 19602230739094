/* eslint-disable import/order */

import { configure } from 'mobx';
import { createContext } from 'react';
/**
 * ATTENTION: Quite unfortunate but the import order matters.
 * Do NOT use auto-cleanup at Save because it re-orders the imports and a Reference Exception will be thrown.
 * ContextVariableStore
 * BlockStore
 * CanvasStore
 * NodeStore
 * UIStore
 * IntentStore
 * AppStore
 * DialogStore
 * ConversationHistoryStore
 * MediaStore
 * LocalStorageStore
 * SerializationStore
 */
import PronunciationStore from './PronunciationStore';
import ContextVariableStore from './ContextVariableStore';
import BlockStore from './BlockStore';
import CanvasStore from './CanvasStore';
import NodeStore from './NodeStore';
import UIStore from './UIStore';
import IntentStore from './IntentStore';
import AppStore from './AppStore';
import DialogStore from './DialogStore';
import ConversationHistoryStore from './ConversationHistoryStore';
import MediaStore from './MediaStore';
import KnowledgebaseStore from './KnowledgebaseStore';
import AdminStore from './AdminStore';
import LocalStorageStore from './LocalStorageStore';
import DatasetStore from './DatasetStore';
import { SerializationStore } from './SerializationStore';
import SubscriptionStore from './SubscriptionStore';
import BotSettingsStore from './BotSettingsStore';

/**
 * Due to the internal architecture of the observables MobX Strict Mode
 * has been turned off to not spam the console with warnings.
 * The warnings are being caused by the direct mutation of properties in observables within the React Components.
 * Please make sure to run asynchronous operations within the "runInAction" MobX function.
 */
configure({
  enforceActions: 'never',
});

export class RootStore {
  appStore: AppStore;
  pronunciationStore: PronunciationStore;
  dialogStore: DialogStore;
  uiStore: UIStore;
  blockStore: BlockStore;
  nodeStore: NodeStore;
  serializationStore: SerializationStore;
  canvasStore: CanvasStore;
  ctxVarStore: ContextVariableStore;
  intentStore: IntentStore;
  conversationHistoryStore: ConversationHistoryStore;
  mediaStore: MediaStore;
  knowledgebaseStore: KnowledgebaseStore;
  adminStore: AdminStore;
  localStorageStore: LocalStorageStore;
  datasetStore: DatasetStore;
  subscriptionStore: SubscriptionStore;
  botSettingsStore: BotSettingsStore;

  constructor() {
	this.appStore = new AppStore(this);
    this.pronunciationStore = new PronunciationStore(this);
	this.dialogStore = new DialogStore(this);
    this.nodeStore = new NodeStore(this);
    this.ctxVarStore = new ContextVariableStore(this);
    this.blockStore = new BlockStore(this);
    this.serializationStore = new SerializationStore(this);
    this.uiStore = new UIStore(this);
    this.canvasStore = new CanvasStore(this);
    this.intentStore = new IntentStore(this);
    this.conversationHistoryStore = new ConversationHistoryStore(this);
    this.mediaStore = new MediaStore(this);
    this.knowledgebaseStore = new KnowledgebaseStore(this);
    this.adminStore = new AdminStore(this);
    this.localStorageStore = new LocalStorageStore(this);
    this.datasetStore = new DatasetStore(this);
    this.subscriptionStore = new SubscriptionStore(this);
    this.botSettingsStore = new BotSettingsStore(this);
  }
}

export default createContext(new RootStore());
