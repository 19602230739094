import { observable, makeObservable } from 'mobx';
import { BaseHistoryElement } from '../BaseHistoryElement';

export class TriggerHistoryElement extends BaseHistoryElement {
  triggered: boolean;
  triggerId: string;

  constructor() {
    super();
    this.triggered = false;
    this.triggerId = '';

    makeObservable(this, {
      triggered: observable,
      triggerId: observable,
    });
  }
}
