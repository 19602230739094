import React, { useContext } from 'react';
import { Notification } from '../../../../models/Utilities/Notification';
import { Text } from '../../../../models/Utilities/Text';
import { Utilities } from '../../../../models/Utilities/Utilities';
import rootStore from '../../../../stores/rootStore';
import Button from '../../../common/Button';
import ParagraphWithCtx from '../../../common/ParagraphWithCtx';
import { useGetIcon } from '../../../customHooks/useGetIcon';

interface IParagraphProps {
  message: Text;
  placeholder: string;
  removeHandler: () => void;
}

const MessageParagraph: React.FC<IParagraphProps> = ({
  message,
  placeholder,
  removeHandler,
}) => {
  const transformText = Utilities.truncateText(message.value, 80);
  const getIcon = useGetIcon();
  const { dialogStore } = useContext(rootStore);

  const handleRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    removeHandler();
  };

  const audioPlayback = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    const dialogId = dialogStore.currentlyEditedDialog?.dialogId;
    if (!dialogId) {
      new Notification({ text: 'Audio playback is not possible', type: 'error' });
      return;
    }

    if (!dialogStore.currentlyEditedDialog?.locale) {
      new Notification({ text: 'Audio playback is not possible', type: 'error' });
      return;
    }

    const audioFile = await message.getAudio(dialogStore.currentlyEditedDialog.locale);

    if (audioFile) {
      const audio = document.createElement('audio');
      audio.src = audioFile;
      audio.play();
    }
  };

  const messageEmpty = Utilities.isEmpty(message.value);

  return (
    <div className='message-paragraph-container'>
      <>
        {!message.value ? (
          <span className='placeholder-span' style={{ fontStyle: 'italic' }}>
            {placeholder}
          </span>
        ) : (
          <ParagraphWithCtx text={transformText} />
        )}
      </>

      <div className='message-paragraph-container-buttons'>
        <Button
          tooltipPosition='top'
          disabled={messageEmpty}
          tooltip='Play back text'
          className='btn-borderless p-5'
          icon={getIcon('play')}
          clickHandler={audioPlayback}
        />
        <Button
          className='btn-remove btn-borderless p-5'
          icon={getIcon('remove')}
          clickHandler={handleRemove}
        />
      </div>
    </div>
  );
};

export default MessageParagraph;
