import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import rootStore from '../../../stores/rootStore';
import Input from '../../common/Input';

interface IProps {
  formik: any;
}

const Links: React.FC<IProps> = ({ formik }) => {
  const { botSettingsStore } = useContext(rootStore);

  return (
    <div className='properties-wrapper'>
      <h3 className='title'>Links</h3>
      <div className='links-wrapper'>
        <div className='input-group'>
          <label htmlFor='privacy-url'>Privacy Link</label>
          <Input
            id='privacy-url'
            placeholder='Enter privacy url'
            value={formik.values.confirmationSettings.privacyUrl || ''}
            changeHandler={(value) =>
              (botSettingsStore.settings.confirmationSettings.privacyUrl = value)
            }
          />
          {formik.touched.privacyUrl && formik.errors.privacyUrl ? (
            <div className='validation-error'>{formik.errors.privacyUrl}</div>
          ) : null}
        </div>
        <div className='input-group'>
          <label htmlFor='impress-url'>Impress Link</label>
          <Input
            id='impress-url'
            placeholder='Enter impress url'
            value={formik.values.confirmationSettings.impressUrl || ''}
            changeHandler={(value) =>
              (botSettingsStore.settings.confirmationSettings.impressUrl = value)
            }
          />
          {formik.touched.impressUrl && formik.errors.impressUrl ? (
            <div className='validation-error'>{formik.errors.impressUrl}</div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default observer(Links);
