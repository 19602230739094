import { Expose, Type } from 'class-transformer';
import { makeObservable, observable } from 'mobx';

export class User {
  @Expose()
  surname: string;

  @Expose()
  givenName: string;

  @Expose()
  displayName?: string;

  @Expose()
  userRole?: string;

  @Expose()
  roles?: string[];

  @Expose()
  id?: string;

  @Expose()
  email: string;

  @Expose()
  lastLogin?: string;

  @Expose()
  @Type(() => Date)
  created?: Date;

  constructor(id?: string, email?: string, displayName?: string) {
    this.displayName = displayName || '';
    this.roles = [];
    this.surname = '';
    this.givenName = '';
    this.userRole = '';
    this.id = id || '';
    this.lastLogin = '';
    this.email = email || '';

    makeObservable(this, {
      displayName: observable,
      id: observable,
      userRole: observable,
      lastLogin: observable,
    });
  }
}
