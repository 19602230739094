import * as Yup from 'yup';
import { Utilities } from '../../../models/Utilities/Utilities';

export const validationSchema = Yup.object({
  titleTextColor: Yup.string()
    .nullable(true)
    .test(
      'is-title-text-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  backgroundColor: Yup.string()
    .nullable(true)
    .test(
      'is-background-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  primaryColor: Yup.string()
    .nullable(true)
    .test(
      'is-primary-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  primaryTextColor: Yup.string()
    .nullable(true)
    .test(
      'is-primary-text-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  primaryButtonTextColor: Yup.string()
    .nullable(true)
    .test(
      'is-primary-button-text-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  secondaryColor: Yup.string()
    .nullable(true)
    .test(
      'is-secondary-color-hex-valid',
      'The hex code should be in format #xxxxxxx',
      (value) => (value ? Utilities.isHexCodeValid(value) : true)
    ),
  privacyUrl: Yup.string()
    .nullable()
    .test('is-privacy-url-valid', 'Privacy url should be a valid URL', (value) =>
      value ? Utilities.isUrlValid(value) : true
    ),
  impressUrl: Yup.string()
    .nullable()
    .test('is-impress-url-valid', 'Impress url should be a valid URL', (value) =>
      value ? Utilities.isUrlValid(value) : true
    ),
});
